<template>
  <div ref="steppers" :class="classes" :style="steppersStyle">
    <div
      v-for="(step, index) in steps"
      :key="index"
      :class="stepClasses(index)"
    >
      <div v-if="!isFirst(index)" class="line -left" />

      <div class="flag-wrapper">
        <transition name="step-fade">
          <c-flag
            v-if="index < active"

            icon="done-check-3"
            class="check"

            success

            :size="stepSize"
            :icon-size="isMobile ? 20 : 24"
          />
        </transition>

        <c-flag
          class="icon"

          circled-border

          :grey="index > computedAvailable || index > active"
          :primary="index <= computedAvailable && index === active"

          :size="stepSize"
          :icon-size="isMobile ? 20 : 24"
          :icon="index < active ? 'done-check-3' : step.icon"
        />
      </div>

      <div v-if="!isLast(index)" class="line -right" />

      <span v-if="!isMobile" class="label"> {{ step.label }} </span>
    </div>

  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CSteppers',

  iconGap: '5px',

  mixins: [ MediaQuery ],

  props: {
    /**
     * Data of each step (label and icon)
     */
    steps: {
      type: Array,
      default: () => ([
        { icon: 'user', label: 'Usuário' }
      ])
    },

    /**
     * Defines which step is active by its index
     */
    active: { type: Number, default: 0 },

    /**
     * Defines which step is available to be accessed
     * not necessarily being the one that is active
     */
    available: {
      type: Number,
      default: null,
    },

    /**
     * Size of the step flag in pixels
     */
    stepSize: { type: Number, required: true },

    /**
     * Adaptable layout for mobile
     */
    mobileAdaptable: Boolean,

    /**
     * Makes the stepper's layout vertical instead of horizontal
     */
    vertical: Boolean,

    /**
     * The distance in pixels between each step when in vertical mode
     */
    verticalGap: {
      type: [ Number, String ],
      default: 30
    }
  },

  computed: {
    iconSize () {
      return `${this.stepSize + 10}px`
    },

    classes () {
      return [ 'c-steppers', { '-vertical': this.vertical } ]
    },

    steppersStyle () {
      const templateSections = this.steps
        .map(() => '1fr')
        .join(' ')

      return {
        ...(this.vertical
          ? { 'grid-template-rows': templateSections }
          : { 'grid-template-columns': templateSections }),
        '--vertical-gap': `${this.verticalGap}px`,
        '--icon-size': `${this.stepSize}px`,
        '--icon-gap': this.$options.iconGap
      }
    },

    labels () {
      return this.isMobile
        ? []
        : this.steps.map(({ label }) => label)
    },

    computedAvailable () {
      const { available, active } = this

      if (available === undefined || available === null)
        return active

      return available
    }
  },

  methods: {
    isFirst (index) {
      return index === 0
    },

    isLast (index) {
      return index === this.steps.length - 1
    },

    stepClasses (index) {
      return [
        'step',
        {
          '--complete': index < this.computedAvailable,
          '--current': index === this.active,
          '--incomplete': index > this.computedAvailable || index > this.active,
          '--is-last': this.isLast(index),
          '--is-first': this.isFirst(index),
          '--mobile-adaptable': this.isMobile && this.mobileAdaptable
        }
      ]
    },
  },
}
</script>

<style lang="scss">
%line {
  position: relative;

  &:after {
    $height: 1px;
    height: $height;
    content: "";
    top: calc(50% - #{$height} - var(--icon-gap));
    position: absolute;
    width: 100%;
    background: $base-border-color;
  }
}

.c-steppers {
  --icon-area: calc(var(--icon-size) + (var(--icon-gap) * 2));
  width: 100%;
  display: grid;

  & > .step {
    display: grid;
    grid-template-columns: 1fr var(--icon-area) 1fr;
    grid-template-areas: "left icon right" "label label label";
    grid-template-rows: var(--icon-area) auto;

    @include responsive(xs-mobile, mobile) {

      &:not(.--mobile-adaptable) {
        height: 0;
      }

      &.--mobile-adaptable.--is-first {
        grid-template-columns: var(--icon-area) 1fr;
        grid-template-areas: "icon right"
      }

      &.--mobile-adaptable.--is-last {
        grid-template-columns: 1fr var(--icon-area);
        grid-template-areas: "left icon"
      }
    }

    & > .line {
      @extend %line;
      &.-left { grid-area: left; }
      &.-right { grid-area: right; }
    }

    & > .flag-wrapper {
      position: relative;
      grid-area: icon;

      & > .check, & > .icon {
        position: absolute;
        left: var(--icon-gap);
      }

      & > .check {
        z-index: $z-index-1;
      }
    }

    & > .label {
      grid-area: label;
      text-align: center;
      font-size: 11px;
      margin-top: var(--icon-gap);
      line-height: 14px;
      letter-spacing: 0;
      text-transform: uppercase;
      font-weight: var(--title-font-weight);
      font-family: var(--title-font-family);
      -webkit-font-smoothing: antialiased;
    }

    &.--available > .label,
    &.--available.--complete > .label {
      color: color-var(text, base-30);
    }

    &.--complete > .label {
      color: color-var(text, base-50);
    }

    &.--complete:not(.--current):not(.--incomplete) > .flag-wrapper > .c-flag.-circled-border,
    &.--complete:not(.--current):not(.--incomplete) > .flag-wrapper > .c-flag.-primary {
      box-shadow: unset;
      & > .c-icon { display: none; }
    }

    &.--complete:not(.--current):not(.--available):not(.--incomplete) {
      & .flag-wrapper > .icon:last-child {
        background: transparent;
      }
    }

    &.--current > .label {
      color: color-var(text, base-80);
    }

    &.--incomplete {
      & > .label { color: color-var(text, base-30); }
      // This is the HEX equivalent of color-var(text, base-10)
      // which unfortunately can't be converted to RGB/HEX because
      // it is a css native var
      & > .flag-wrapper > .icon { background: #E7E9ED; }
    }

    &.--available:not(.--current):not(.--incomplete) {
      & > .flag-wrapper > .icon { opacity: 0.5; }
    }
  }

  &.-vertical {
    width: unset;
    grid-template-columns: 1fr;
    height: fit-content;

    & > .step {
      grid-template-areas: "icon label" "line blank";
      grid-template-columns: var(--icon-area) 1fr;
      grid-template-rows: var(--icon-area) var(--vertical-gap);

      &:last-child > .line { display: none; }

      & > .line {
        grid-area: line;

        &::after {
          height: var(--vertical-gap);
          width: 1px;
          left: 50%;
          top: -5px;
        }
      }

      & > .label {
        margin-top: 0;
        margin-bottom: 10px;
        margin-left: 20px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.step-fade {
  &-enter, &-leave-to { opacity: 0; transform: scale(1.5); }
  &-enter-active, &-leave-active { transition: opacity .3s, transform .3s; }
}
</style>
