<template>
  <c-modal
    class="c-form-group-modal"
    :class="{ '-no-header': !title }"
    :no-header="!title"
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot name="header-actions" />

    <c-shadowed class="shadowed" shadow-color="#FEFEFE">
      <c-form-group
        ref="formGroup"
        class="form-group"
        :label-left="!isMobile"
        :schema="schema"
        :fields-options="fieldsOptions"
        :disabled="loading"
        v-bind="$attrs"
        v-on="$listeners"
      >

        <template slot="actions">
          <slot name="actions">
            <c-button
              v-if="!isMobile"
              flat
              class="action"
              type="button"
              :disabled="loading"
              @click="$emit('close', $refs.formGroup)"
            >
              Cancelar
            </c-button>

            <c-button
              primary
              class="action btn-send"
              :full-width="isMobile"
              :size="isMobile ? 60 : 40"
              :loading="loading"
              @click="onSubmit"
            >
              Enviar
            </c-button>
          </slot>
        </template>

        // forwards scoped slots to the CFormGroup component
        <template
          v-for="(_, slot) of $scopedSlots"
          #[slot]="scope"
        >
          <slot :name="slot" v-bind="scope" />
        </template>
      </c-form-group>
    </c-shadowed>
  </c-modal>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

import CModal from '../CModal'
import CFormGroup from '../CFormGroup'
import CShadowed from '../CShadowed'

export default {
  name: 'CFormGroupModal',

  components: { CModal, CFormGroup, CShadowed },

  mixins: [ MediaQuery ],

  props: {
    schema: {
      type: Object,
      default: () => ({})
    },
    fieldsOptions: {
      type: Object,
      default: () => ({})
    },
    labelLeft: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    mainSlots () {
      const { main } = this.schema || {}

      if (!main) return []

      return Object.entries((main || {}).fields || {})
        .filter(([ , field ]) => field.type === 'slot')
        .map(([ fieldName ]) => fieldName + '-slot')
    },

    groupSlots () {
      const groups = (this.schema || {}).groups || []

      return groups.flatMap(group => Object
        .entries((group || {}).fields)
        .filter(([ , field ]) => field.type === 'slot')
        .map(([ fieldName ]) => fieldName + '-slot'))
    },
  },

  methods: {
    closeModal () {
      this.$emit('close', this.$refs.formGroup)
    },

    onSubmit () {
      const { formGroup: el } = this.$refs
      if (!el) return

      el.onSubmit()
    }
  }
}
</script>

<style lang="scss">
.c-modal.c-form-group-modal > .wrapper > .modal {
  max-width: 960px;

  &.-no-header > .header { padding: 0; }

  & > .header .actions > .c-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  & > .content {
    position: relative;
    display: flex;
    flex: 1;

    min-height: 0;
    height: 100%;
    width: 100%;

    padding: 0;

    & > .shadowed {
      width: 100%;

      @include responsive (xs-mobile, mobile) {
        overflow: visible;
        margin-bottom: 60px;
      }
    }

    & > .shadowed > .wrapper {
      padding: 0 20px;

      @include responsive (xs-mobile, mobile) {
        padding: 0 20px;
      }
    }

    & > .shadowed > .wrapper > .form-group {
      width: 100%;

      @include responsive (xs-mobile, mobile) {
        & > .actions {
          padding: 0; width: 100%;
          height: 160px;
        }

        & > .actions > .btn-send {
          position: absolute;
          width: 100%;
          height: 60px;
          max-width: 100%;

          bottom: -60px;
        }
      }

      & > .main,
      & > .groups > .group > .forms > .form-wrapper {
        // We don't need this padding here since the modal
        // around it already has it.
        @include form-fields { padding: 0; }

        @include form-field (file) {
          margin-top: 30px;

          // Make it so that file fields occupy the two columns
          // of the form, making it take up the width that is
          // visually occuppied by the form
          & > .input-wrapper { grid-column-start: 1; }
        }
      }

      & > .groups > .group > .c-add {
        // This is the width in pixels that is visually occupied
        // by the form component
        max-width: 570px;
      }

      & > .actions > .action:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
</style>
