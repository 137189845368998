import { ADDRESSES_CONTACTS_AREA } from '@content/consts/AddressesContacts'
import { masker, mapToNumeric } from '@modules/formatters'
import { getAdditionalFields, getEmployeeInfoSchema } from '@services/systemField'
import { mapFiles, getUploadEndpoint } from '@services/upload'
import * as get from '@graphql/middleware/queries'
import * as set from '@graphql/mutations'
import middleware from '@graphql/middleware'

const addressMapper = (address, employeeId) => ({
  ...address,
  address: address.address || '',
  zipCode: masker('#####-###', address.zipCode),
  phone: address.telephone || '',
  number: address.number || '',
  complement: address.complement || '',
  state: address.state?.id ? +address.state.id : '',
  city: address.city?.id ? +address.city.id : '',
  files: mapFiles(address.files || [], employeeId)
})

const contactMapper = contact => ({
  id: contact.id || null,
  personalTelephone: contact.telephone || '',
  personalCellphone: contact.cellphone || '',
  personalEmail: contact.email || ''
})

const emergencyMapper = contact => ({
  ...contact,
  relation: contact.relation?.id ? +contact.relation.id : null
})

export async function getOptions () {
  const [ err, { options: data = {} } = {} ] = await middleware(get.GetAddressContactsOptions)
  const options = {
    address: {},
    emergency: {
      emergencyRelations: data.emergencyRelations
    }
  }

  return [ err, options ]
}

export async function getAddressContacts (getters) {
  const employeeId = getters.getEmployeeUuid
  const [ err, data ] =
    await middleware(get.GetAddressContacts, { employeeId })

  const address = addressMapper(data?.address || {}, employeeId)
  const contact = contactMapper(data?.contact || {})
  const emergency = (data?.emergencyContacts || [ {} ])
    .map(contact => emergencyMapper(contact))

  return [ err, { address, contact, emergency } ]
}

export async function setAddress (getters, variables) {
  const employeeId = getters.getEmployeeUuid
  const isCreating = !variables.id
  const mutation = !isCreating ? 'UpdateAddress' : 'CreateAddress'

  const [ err, data ] = await middleware(set[mutation], {
    input: {
      ...(!isCreating ? { id: variables.id } : {}),
      employee_id: employeeId,
      number: variables.number,
      address: variables.address,
      zip_code: mapToNumeric(variables.zipCode),
      district: variables.district,
      complement: variables.complement,
      city_id: +variables.city || null,
      state_id: +variables.state || null,
      additional: {
        sectionId: ADDRESSES_CONTACTS_AREA.ADDRESS,
        fields: getAdditionalFields(variables.customFields, variables)
      }
    }
  })

  const address = !isCreating ? variables :
    addressMapper(data.address || {}, employeeId)
  const endpoint = getUploadEndpoint(employeeId, address.id, 'address')

  return [ err, address, endpoint ]
}

export async function setContact (getters, variables) {
  const employeeId = getters.getEmployeeUuid
  const mutation = variables.id ? 'UpdateContact' : 'CreateContact'
  const [ err, data ] = await middleware(set[mutation], {
    input: {
      ...(variables.id ? { id: variables.id } : {}),
      employee_id: employeeId,
      residential_phone: mapToNumeric(variables.personalTelephone),
      personal_cellphone: mapToNumeric(variables.personalCellphone),
      personal_email: variables.personalEmail,
      additional: {
        sectionId: ADDRESSES_CONTACTS_AREA.CONTACTS,
        fields: getAdditionalFields(variables.customFields, variables)
      }
    }
  })

  const contact = contactMapper(data.contact || {})

  return [ err, contact ]
}

export async function setEmergencyContact (getters, variables) {
  const employeeId = getters.getEmployeeUuid
  const mutation = typeof variables.id === 'string' ? 'UpdateEmergencyContact' : 'CreateEmergencyContact'
  const [ err, data ] = await middleware(set[mutation], {
    input: {
      ...(typeof variables.id === 'string' ? { id: variables.id } : {}),
      employee_id: employeeId,
      name: variables.name,
      email: variables.email || null,
      phone: variables.telephone,
      cellphone: variables.cellphone,
      work_phone: variables.work_phone,
      emergency_contact_relation_id: +variables.relation || null,
      additional: {
        sectionId: ADDRESSES_CONTACTS_AREA.EMERGENCY_CONTACTS,
        fields: getAdditionalFields(variables.customFields, variables)
      }
    }
  })

  const contact = (data.emergencyContact || {}).id ? data.emergencyContact : variables

  return [ err, contact ]
}

export async function deleteEmergencyContact (getters, variables) {
  if (typeof variables.id !== 'string') return [ false, null ]

  const employeeId = getters.getEmployeeUuid
  const [ err ] = await middleware(set.DeleteEmergencyContact, {
    input: {
      id: variables.id,
      employee_id: employeeId
    }
  })

  return [ err, null ]
}

export const mapSection = data => ({
  schema: data?.schema || {},
  values: data?.multipleValues || {}
})

export const mapCustomFields = data => ({
  address: mapSection(data.find(({ id }) => id === ADDRESSES_CONTACTS_AREA.ADDRESS)),
  contact: mapSection(data.find(({ id }) => id === ADDRESSES_CONTACTS_AREA.CONTACTS)),
  emergencyContact: mapSection(data
    .find(({ id }) => id === ADDRESSES_CONTACTS_AREA.EMERGENCY_CONTACTS)),
})

export const getCustomFields = async (getters) => {
  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await getEmployeeInfoSchema({
    employeeId,
    area: ADDRESSES_CONTACTS_AREA.LABEL
  })

  const customFields = mapCustomFields(data)

  return [ err, customFields ]
}
