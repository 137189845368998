import * as types from '@types'
import * as services from '@services/admission'
import { deleteFile } from '@services/upload'
import stepsData from './steps'

export default {
  state: {
    logo: '',
    admissionId: '',
    employeeId: '',
    employeeUuid: '',
    companyId: '',
    companyUuid: '',
    admissionSingleId: '',
    admissionStepId: '',
    requiredFields: [],
    requiredDocuments: [],
    currentStep: 0,
    steps: [],
    submiting: false,
  },

  getters: {
    getAdmissionId: state => state.admissionId,
    getEmployeeId: state => state.employeeId,
    getEmployeeUuid: state => state.employeeUuid,
    getCompanyId: state => state.companyId,
    getCompanyUuid: state => state.companyUuid,
    getAdmissionProcessId: state => state.admissionSingleId,
    getAdmissionStepId: state => state.admissionStepId,
    getAdmissionEmployeeStepId: state => state.admissionEmployeeStepId,
    getRequiredFields: state => state.requiredFields,
    getRequiredDocuments: state => [ ...new Set(state.requiredDocuments) ],
    getStep: state => index => state.steps[index]
  },

  mutations: {
    [types.ADMISSION_INFO]: (state, data) => {
      state.logo = data.logo
      state.admissionId = data.admissionId
      state.employeeId = data.employeeId
      state.employeeUuid = data.employeeUuid
      state.companyId = data.companyId
      state.companyUuid = data.companyUuid
      state.requiredFields = [ ...state.requiredFields, ...data.requiredFields ]
      state.requiredDocuments = [ ...state.requiredDocuments, ...data.requiredDocuments ]
    },

    [types.ADMISSION_SINGLE]: (state, data) => {
      state.admissionSingleId = data?.id
      state.admissionEmployeeStepId = data?.employeeAdmissionStep?.id
    },

    [types.ADMISSION_STEPS]: (state, steps) => {
      state.steps = steps.map(step => ({
        ...step,
        ...stepsData[step.slug]
      }))
    },

    [types.ADMISSION_HIDE_STEP]: (state, slug) => {
      const index = state.steps.findIndex(state => state.slug === slug)

      if (index >= 0) state.steps.splice(index, 1)
    },

    [types.ADMISSION_SUBMITING]: (state, value) => {
      state.submiting = value
    },

    [types.ADMISSION_SET_STEP]: (state, value) => {
      state.currentStep = value
    }
  },

  actions: {
    [types.ADMISSION_INFO]: async ({ commit, getters }) => {
      const [ infoErr, infoData ] = await services.getAdmissionInfo()
      if (!infoErr) commit(types.ADMISSION_INFO, infoData)

      const [ singleErr, singleData ] = await services.getAdmissionProcess(getters)
      if (!singleErr) commit(types.ADMISSION_SINGLE, singleData)

      return [ infoErr, infoData ]
    },

    [types.ADMISSION_STEPS]: async ({ commit, state }) => {
      if (state.steps.length) return state.steps

      const [ err, steps ] = await services.getSteps()

      if (!err) {
        commit(types.ADMISSION_STEPS, steps)
        commit(types.ADMISSION_SET_STEP, state.admissionEmployeeStepId)
      }

      return steps
    },

    [types.ADMISSION_PREV_STEP]: async ({ commit, getters, state }) => {
      if (state.currentStep < 0) return

      const index = state.steps.findIndex(({ id }) => id === state.currentStep)
      const step = state.steps[index - 1] || state.steps[0]

      const [ err ] = await services.setCurrentStep(getters, step.id)

      if (!err) commit(types.ADMISSION_SET_STEP, step.id)
    },

    [types.ADMISSION_NEXT_STEP]: async ({ commit, getters, state }) => {
      const index = state.steps.findIndex(({ id }) => id === state.currentStep)
      const step = state.steps[index + 1]

      if (step) {
        const [ err ] = await services.setCurrentStep(getters, step.id)

        if (!err) commit(types.ADMISSION_SET_STEP, step.id)
      }
    },

    [types.ADMISSION_CLOSE]: async ({ state, getters }) => {
      const [ err ] = await services.closeAdmission(getters, state.admissionId)

      return [ err ]
    },

    [types.ADMISSION_DELETE_FILE]: async (_, variables) => {
      const [ err, status ] = await deleteFile(variables)

      return [ err, status ]
    },
  }
}
