<template>
  <transition name="c-tooltip-show">
    <div
      v-if="hide ? false : show"
      class="c-tooltip"
    >
      <c-popover
        ref="popover"
        :target="target"
        :arrow-spacing="arrowSpacing"
        :element-spacing="elementSpacing"
        :position="position"
        :align="align"
        :class="[ 'popover', { '-dark': dark } ]"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <div
          v-if="isHTML"
          class="card"
          :style="cardSytles"
          v-html="value"
        />

        <div v-else class="card" :style="cardSytles">
          <template v-if="value">
            {{ value }}
          </template>

          <slot v-else />
        </div>
      </c-popover>
    </div>
  </transition>
</template>

<script>
const checkPlacement = (types, p) => typeof p === 'string' && types.indexOf(p) > -1

/**
 * Tooltip component used by the v-tooltip directive.
 */
export default {
  name: 'CTooltip',

  props: {
    /**
     * Whether to show or hide the toolip component.
     */
    show: Boolean,

    /**
     * Makes the toolip bg-color dark.
     */
    dark: Boolean,

    /**
     * The tooltip's font-size
     */
    fontSize: {
      type: Number,
      default: 14
    },

    /**
     * The tooltip's max-width
     */
    maxWidth: {
      type: Number,
      default: 200
    },

    /**
     * Determines the tooltip side padding
     */
    horizontalPadding: {
      type: Number,
      default: 10
    },

    /**
     * The target of the tooltip, the tooltip will be positioned relative
     * to the target when open.
     * When the tooltip has no explicit target, it will be
     * positioned at the bottom of the screen.
     */
    target: {
      type: [ Object, Element ],
      default: null,
    },

    /**
     * Fine tuning of the tooltip's arrow horizontal position.
     */
    arrowSpacing: {
      type: [ Number, String ],
      default: 15
    },

    /**
     * Determines the distance between the target element and the tooltip arrow.
     */
    elementSpacing: {
      type: [ Number, String ],
      default: 0
    },

    /**
     * Whether to show the tooltip below or above the target element.
     */
    position: {
      type: String,
      default: 'bottom',
      validator: p => checkPlacement([ 'bottom', 'top' ], p)
    },

    /**
     * The horizontal alignment of the tooltip element relative to the target.
     */
    align: {
      type: String,
      default: 'center',
      validator: v => checkPlacement([ 'right', 'center', 'left' ], v)
    },

    /**
     * If provided, the content of the value will be displayed inside
     * the tooltip instead of the default slot.
     */
    value: {
      type: String,
      default: '',
    },

    /**
     * I know... this kinda sucks since we already have a 'show' prop,
     * but this prop is useful to force the tooltip to hide when needed.
     * The 'hide' prop takes precedence over 'show' in the v-if dynamics.
     */
    hide: Boolean,

    /**
     * This prop is used to insert the value as HTML
     * using the v-html directive
     */
    isHTML: Boolean
  },

  computed: {
    cardSytles () {
      return {
        '--tooltip-font-size': `${this.fontSize}px`,
        '--tooltip-max-width': `${this.maxWidth}px`,
        '--tooltip-horizontal-padding': `${this.horizontalPadding}px`
      }
    }
  }
}
</script>

<style lang="scss">
.c-tooltip {
  opacity: 0.85;
  position: fixed;
  z-index: var(--z-index-4);

  & > .popover {
    pointer-events: none;
    white-space: normal;

    &.c-tooltip::before { z-index: inherit; }

    & > .card {
      padding: 10px var(--tooltip-horizontal-padding);
      color: color-var(text, base-80);
      font-family: var(--base-font-family);
      font-weight: 400;
      line-height: 15px;
      overflow-wrap: break-word;
      font-size: var(--tooltip-font-size);
      max-width: var(--tooltip-max-width);

      box-shadow: var(--box-shadow);

      border-radius: 5px;
      position: relative;
      overflow: hidden;
      background-color: #FFF;
      -webkit-font-smoothing: antialiased;
    }

    &.-dark {
      &::before { border-color: color-var(text, base); }

      & > .card {
        color: rgba(#FFF, 0.85);
        background-color: color-var(text, base);
        text-align: center;
      }
    }
  }
}

.c-tooltip-show {
  &-enter-active,
  &-leave-active { transition: opacity .3s; }

  &-enter,
  &-leave-to { opacity: 0; }

  &-enter-to,
  &-leave { opacity: 0.85; }
}
</style>
