import md5 from 'js-md5'

export async function authenticate (code) {
  const secret = import.meta.env.VITE_BYPASS_SECRET
  const body = new FormData()

  body.append('secret', md5(code + secret))
  body.append('token', code)

  return fetch(import.meta.env.VITE_REST_URL + '/users/bypass/new-admission', {
    method: 'POST',
    body
  })
    .then(response => { if (!response.ok) throw response; return response.json() })
    .then(({ data }) => [ false, data.token ])
    .catch(err => [ err.status === 302 ? 'done' : 'unauthorized', null ])
}
