<template>
  <div :class="[ 'c-multi-select-raw', { '-validation': !!validation, '-label-left': labelLeft } ]">
    <c-select-raw
      class="select"
      :value="computedValue"
      :validation="validation"
      :options="availableOptions"
      :track-by="trackBy"
      :display-by="displayBy"
      :label-left="labelLeft"
      v-bind="getAttrs($attrs)"
      @input="onInput"
    />

    <c-multi-select-item-list
      v-if="(computedValue || []).length"
      class="selected"
      :items="computedValue"
      :display-by="displayBy"
      v-bind="$attrs"
      @remove="onRemove"
    />
  </div>
</template>

<script>
// There was a dependency cycle happening here, so we had to use an absolut import here ¯\_(ツ)_/¯
import { CSelectRaw } from '../../CSelect'
import CommonMixin from './CommonMixin'

export default {
  components: { CSelectRaw },

  mixins: [ CommonMixin ],

  methods: {
    getAttrs (attrs) {
      return {
        ...attrs,
        disabled: attrs.disabled || !this.availableOptions.length
      }
    }
  }
}
</script>

<style lang="scss">
.c-multi-select {
  display: flex;
  flex-direction: column;
}
</style>
