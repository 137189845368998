export const formatToBRL = value => {
  const formattedNumber = Number(value)
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

  return `R$ ${formattedNumber}`
}

export const floatToCents = value => {
  if (!value) return 0

  const strCents = value
    .toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    .replace(/[^0-9]+/g, '')

  return Number(strCents)
}

export const centsToFloat = cents => {
  if (!cents) return 0

  const strFloat = (cents / 100).toFixed(2)

  return parseFloat(strFloat)
}

export const realToFloat = value => {
  if (!value) return false

  const strFloat =
    value
      .replace(/^R\$ ?\s?/, '')
      .replace(/\./g, '')
      .replace(',', '.')

  return parseFloat(strFloat)
}

export const toMoney = (value) => {
  const options = { style: 'currency', currency: 'BRL' }
  return (value || 0).toLocaleString('pt-BR', options)
}

export const toPrice = (price) => {
  const value = price.toString().replace(/[^\d,-]/g, '').replace(/,/g, '.')
  return parseFloat(value).toFixed(2)
}

export const toRomanNumerals = (num) => {
  let number = Number(num)

  const romanNumerals = [
    { value: 1000, numeral: 'M' },
    { value: 900, numeral: 'CM' },
    { value: 500, numeral: 'D' },
    { value: 400, numeral: 'CD' },
    { value: 100, numeral: 'C' },
    { value: 90, numeral: 'XC' },
    { value: 50, numeral: 'L' },
    { value: 40, numeral: 'XL' },
    { value: 10, numeral: 'X' },
    { value: 9, numeral: 'IX' },
    { value: 5, numeral: 'V' },
    { value: 4, numeral: 'IV' },
    { value: 1, numeral: 'I' }
  ]

  let result = ''

  romanNumerals.forEach(({ value, numeral }) => {
    while (number >= value) {
      result += numeral
      number -= value
    }
  })

  return result
}
