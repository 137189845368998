<template>
  <c-card
    no-hover
    no-border
    class="c-graph-card"
    :class="classes"
  >
    <c-loader v-if="isLoading" />

    <div class="header">
      <c-title dark-text primary truncate>
        {{ title }}
      </c-title>

      <div class="actions">
        <c-filter-button
          v-if="showFilters"
          :width="160"
          :counter="filters"
          @click="$emit('filter')"
        />

        <c-button
          v-if="download"
          flat
          icon="download-arrow"
          icon-size="24"
          :disabled="isDownloading"
          @click="$emit('download')"
        />
      </div>
    </div>

    <c-graph
      ref="graph"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </c-card>
</template>

<script>
import CGraph from '../CGraph'

export default {
  name: 'CGraphCard',

  components: {
    CGraph
  },

  props: {
    title: {
      default: '',
      type: String
    },

    download: {
      type: Boolean,
      default: true
    },

    filters: {
      type: Number,
      default: 1
    },

    showFilters: {
      type: Boolean,
      default: true
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    isDownloading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    graph () {
      return this.$refs.graph
    },

    classes () {
      return [
        {
          '-is-loading': this.isLoading
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.c-graph-card {
  position: relative;

  & > .c-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > .header {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;

    & > .c-title { flex: 1; }

    & > .actions {
      display: flex;

      & > * { margin-left: 10px; }
    }
  }

  &.-is-loading {
    & > .c-graph { opacity: 0; }
  }

  & > .c-graph {
    transition: opacity .2s ease-out;
    margin: -20px 10px 0;
  }
}
</style>
