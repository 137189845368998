<template>
  <grid-stepper
    class="historical-changes-list"
    :elements="historicalChanges"
    :selected="selected"
    :additional-info-width="sideInfoWidth"
  >
    <template #additional-info="{ content }">
      <slot name="additional-info" :content="content" />
    </template>

    <template #target-content="{ content }">
      <BaseHeader
        from-list
        :active="content.isSelected"
        :header-data="headerTemplates[content.action] || {}"
        v-bind="{ ...content }"
        @toggle="$emit('toggle', content.index, groupIndex)"
        @edit="$emit('edit', content.index, groupIndex)"
        @remove="$emit('remove', content.index, groupIndex)"
      >
        <template #header-content="{ headerContent }">
          <slot name="header-content" :header-content="headerContent" />
        </template>

        <template #header-before-actions="{ headerContent }">
          <slot name="header-before-actions" :header-content="headerContent" />
        </template>
      </BaseHeader>
    </template>

    <template v-if="!isMobile" #bottom-content="{ content }">
      <info
        class="info"
        :margin="infoMargin"
        :format-field="formatField"
        v-bind="{ ...content }"
      />
    </template>
  </grid-stepper>
</template>

<script>
import { MediaQueryRoot } from '@convenia/mixins'
import Info from './Info'
import GridStepper from './GridStepper'

import BaseHeader from './Headers/Base'

export default {
  mixins: [ MediaQueryRoot ],

  components: {
    Info,
    GridStepper,
    BaseHeader
  },

  props: {
    historicalChanges: {
      type: Array,
      default: () => [],
    },

    mobileSelected: {
      type: Number,
      default: -1,
    },

    sideInfoWidth: {
      type: Number,
      default: 0,
    },

    groupIndex: {
      type: Number,
      default: 0,
    },

    selected: {
      type: Array,
      default: () => [],
    },

    headerTemplates: {
      type: Object,
      default: () => ({}),
    },

    infoMargin: {
      type: Number,
      default: 230,
    },

    formatField: {
      type: Function,
      default: null,
    },
  },
}
</script>

<style lang="scss">
.historical-changes-list > .element {

  & > .gap { height: 20px; }

  & > .all-content {
    border-radius: 5px;
    border: 1px solid color-var(text, base-10);
  }

  & > .bottom-content, & .target-content, & .all-content {
    margin-left: 30px;
    overflow: hidden;

    @include responsive (xs-mobile, mobile) {
      margin-left: 10px;
    }
  }

  & > .bottom-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

    &.-selected {
      max-height: 2000px;
      transition: max-height 1s ease-in-out;
    }

    & > .info {
      padding-top: 15px;
    }
  }

  & > .additional-info { position: relative; }
}
</style>
