import ProfilePhoto from '@containers/ProfilePhoto'
import PersonalInfo from '@containers/PersonalInfo'
import Formations from '@containers/Formations'
import AddressContacts from '@containers/AddressContacts'
import Dependents from '@containers/Dependents'
import BankAccount from '@containers/BankAccount'
import OtherInfo from '@containers/OtherInfo'
import Documents from '@containers/Documents'

export default {
  'photo': {
    icon: 'camera-photo',
    label: 'Foto de perfil',
    description: 'Cadastre as suas <b>Informações Pessoais</b>. Se você precisar parar o processo no meio, não se preocupe, suas informações serão salvas. Para continuar a preencher de onde parou, basta acessar o mesmo link do e-mail que recebeu! :)',
    component: ProfilePhoto
  },

  'personal-information': {
    slug: 'personal-information',
    icon: 'user-profile-3',
    label: 'Dados pessoais',
    description: 'Cadastre as suas <b>Informações Pessoais</b>. Se você precisar parar o processo no meio, não se preocupe, suas informações serão salvas. Para continuar a preencher de onde parou, basta acessar o mesmo link do e-mail que recebeu! :)',
    component: PersonalInfo
  },

  'employee-education': {
    icon: 'graduate-hat',
    label: 'Formação acadêmica',
    description: 'Preencha as informações referentes à sua <b>Formação Acadêmica</b>. Se houver necessidade, clique no botão para incluir mais formações.',
    component: Formations
  },

  'addresses-and-contacts': {
    icon: 'user-profile-cards-1',
    label: 'Endereços e contatos',
    description: 'Esta etapa é destinada a <b>Endereços</b> e <b>Contatos Pessoais</b>. Se houver necessidade, clique no botão adicionar para incluir mais <b>Contatos de Emergência</b>.',
    component: AddressContacts
  },

  'dependents': {
    icon: 'user-profile-checkmark',
    label: 'Dependentes',
    description: 'Preencha as informações referentes a <b>Dependentes</b>. Se não houver, basta avançar para o próximo passo.',
    component: Dependents
  },

  'bank-account': {
    icon: 'piggy-bank-coin',
    label: 'Dados bancários',
    description: 'Atente-se ao preenchimento correto das informações de acordo com o seu <b>Banco</b> e o <b>Tipo de Conta</b>.',
    component: BankAccount
  },

  'other-information': {
    icon: 'documents-files-copy',
    label: 'Outras informações',
    description: 'Preencha as <b>Informações Adicionais</b>.',
    component: OtherInfo
  },

  'documents': {
    icon: 'clip-attachment',
    label: 'Documentos',
    description: 'Esta etapa é destinada ao anexo dos <b>Documentos</b>. Atente-se aos anexos solicitados, formatos suportados e a conferência dos dados! :)',
    component: Documents
  }
}
