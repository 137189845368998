<template>
  <div :class="[ 'c-employee-info', { '-highlight': highlight } ]">
    <c-flag
      v-if="icon"
      class="icon"
      :icon="icon"
      :shadow-blur="flagShadowBlur"
      v-bind="$attrs"
    />

    <c-avatar
      v-else-if="!noImage"
      class="avatar"
      :placeholder="placeholder"
      :name-placeholder="name"
      :hide-tooltip="hideAvatarTooltip"
      v-bind="$attrs"
      v-on="$listeners"
    />

    <div class="info">
      <!-- @slot Content slot, contains the parsed user name by default,
          alongside a default slot for extra content.
      -->
      <slot name="content">
        <span
          v-tooltip.ellipsis.dark="{
            hide: hideTooltip,
            parentEl: nameTooltipParentEl,
            value: name
          }"
          :ref-cy="`employee-info-name-${name}`"
          class="name"
          :style="{ fontSize: `${nameSize}px` }"
        >
          <template v-if="parseText">{{ name | parseName }}</template>

          <template v-else>{{ name }}</template>
        </span>

        <!-- @slot The extra content slot. -->
        <slot />
      </slot>
    </div>
  </div>
</template>

<script>
import { parseName } from '@convenia/filters'
import { tooltip } from '@convenia/components/directives'

/**
 * A component to display an employee's info, mainly used in the SAAS project.
 */
export default {
  name: 'CEmployeeInfo',

  directives: { tooltip },

  filters: { parseName },

  props: {
    /**
     * The name of the employee (will be capitalized).
     */
    name: {
      type: String,
      required: true
    },

    nameSize: {
      type: Number,
      default: null,
    },

    placeholder: {
      type: String,
      default: 'initials'
    },

    /**
     * The name of the flag icon
     */
    icon: {
      type: String,
      default: '',
    },

    /**
     * For components that do not have an icon or avatar
     */
    noImage: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether to hide the employee name tooltip
     */
    hideTooltip: Boolean,

    /**
     * Whether to hide the employee avatar tooltip
     */
    hideAvatarTooltip: Boolean,

    /**
     * Parses name to capitalize
     */
    parseText: {
      type: Boolean,
      default: true
    },

    /**
     * The Element parent of the name tooltip
     */
    nameTooltipParentEl: {
      type: Element,
      default: null,
    },

    /**
     * Highlights the content
     */
    highlight: {
      type: Boolean,
      default: false
    },

    /**
     * Flag shadow blur level
     */
    flagShadowBlur: {
      type: String,
      default: '4px'
    }
  }
}
</script>

<style lang="scss">
.c-employee-info {
  display: flex;
  min-width: 0;

  & > .avatar { flex-shrink: 0; }

  & > .c-flag {
    position: relative;

    & > .c-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & > .info {
    display: flex;
    align-self: center;
    flex-direction: column;
    padding-left: 10px;
    min-width: 0;

    & > .name {
      line-height: 19px;
      padding-bottom: 2px;
      z-index: 2;
      @include typo(h2);
    }

    & > :not(.name):not(.c-label) {
      line-height: 16px;
      color: color-var(text, base-50);
      font-size: 14px; // please don't use !important :(
      font-weight: 400;
    }
  }

  &.-highlight > .info > .name {
    position: relative;
    z-index: 1;

    &:before {
      content: "";
      z-index: -1;
      height: 100%;

      position: absolute;
      top: -2px;
      left: -4px;
      right: -4px;
      bottom: 10px;

      border-radius: 4px;
      background: rgba(color-var(alert, base-rgb), .2);
    }
  }

}
</style>
