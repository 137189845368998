<template>
  <div :class="classes">
    <c-input-raw
      placeholder="Mínimo"
      :error="error"
      v-bind="$attrs"
      v-model="start"
      @input="onChange"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    />

    <span class="text">e</span>

    <c-input-raw
      placeholder="Máximo"
      :error="error"
      v-bind="$attrs"
      v-model="end"
      @input="onChange"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'CInputRange',

  props: {
    value: {
      type: Array,
      default: () => ([ 0, 10 ])
    },

    error: Boolean
  },

  computed: {
    classes () {
      return [
        'c-input-range',
        {
          '-validation': this.error,
        }
      ]
    },
  },

  data: (vm) => ({
    start: vm.value[0],
    end: vm.value[1],
  }),

  methods: {
    onChange () {
      this.$emit('input', [ this.start, this.end ])
    },

    focus () {
      try {
        this.$refs.input.$el.focus()
      } catch (e) { return e }
    }
  },

  mounted () {
    this.$nextTick(() => {
      const { input } = this.$refs
      if (input) this.handleInput(input.$el.value)
    })
  }
}
</script>

<style lang="scss">
.c-input-range {
  display: flex;
  align-items: center;

  & > .c-input-raw { width: 105px; }

  & > .text {
    margin: 0 15px;
    @include typo(h5, base-50);
  }
}
</style>
