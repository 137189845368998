/**
 * Checks whether an array has less length than the maximum value.
 * @param {Array} value - The input value
 * @param {Array} params - The validation params
 */
export const maxLength = (value, params) => {
  const [ max ] = params

  return value.length <= max || `Atingido limite de ${max} ${max === 1 ? 'item' : 'itens'}`
}
