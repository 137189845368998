<template>
  <transition name="c-dropdown-slide" appear>
    <div
      v-click-outside="{ ev: 'mouseup', fn: () => $emit('close') }"
      class="c-dropdown"
    >
      <c-popover
        align="left"
        class="popover"
        :element-spacing="elementSpacing"
        :selector="scrollSelector"
        :target="target"
        :scroll-target="scrollTarget"
        :position="clickPosition ? calculatedPosition : position"
        v-bind="$attrs"
        @handler="$emit('close')"
        v-on="$listeners"
      >
        <c-card class="actions" no-border no-hover>
          <slot>
            <div
              v-for="(item, index) in items"
              :key="index"
              :ref-cy="`logged-action-${item.key}`"
              :class="getClasses(item, index)"
              @click.prevent.stop="action(item)"
            >
              <template v-if="item.icon">
                <c-image
                  v-if="item.icon.includes('.svg')"
                  class="img"
                  :src="item.icon"
                  :no-loader="item.noLoader"
                />

                <c-icon
                  v-else
                  class="icon"
                  :size="20"
                  :icon="item.icon"
                />
              </template>

              <span
                class="text"
                :ref-cy="'popover-' + item.label"
              >
                {{ item.label }}
              </span>

            </div>
          </slot>
        </c-card>
      </c-popover>

      <c-overlay
        class="overlay"

        :show="isMobile"
        :transparent="!isMobile"

        @close.prevent.stop="$emit('close')"
      />
    </div>
  </transition>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CHistoricalPopover',

  mixins: [ MediaQuery ],

  props: {
    target: {
      type: [ Object, Element ],
      required: true
    },

    scrollSelector: {
      type: String,
      default: 'body'
    },

    scrollTarget: {
      type: [ Object, Element ],
      default: null,
    },

    elementSpacing: {
      type: [ String, Number ],
      default: 5
    },

    items: {
      type: Array,
      default: () => [],
    },

    clickPosition: {
      type: Number,
      default: null,
    },

    height: {
      type: [ String, Number ],
      default: 0
    },

    position: {
      type: String,
      default: 'bottom'
    }
  },

  methods: {
    action (item) {
      item.action()
      this.$emit('close')
    },

    getClasses (item, index) {
      return [ 'action', {
        [`-border-${item.border}`]: item.border,
        [`-highlight-${item.highlight}`]: item.highlight,
        '-first': this.calculatedPosition === 'bottom' && index === 0,
        '-last': this.calculatedPosition === 'bottom' && index === this.items.length - 1,
        '-bottom': this.calculatedPosition === 'bottom',
        '-top': this.calculatedPosition === 'top'
      } ]
    }
  },

  computed: {
    calculatedPosition () {
      if (!this.clickPosition || window.innerHeight - this.clickPosition > parseInt(this.height)) return 'bottom'
      return 'top'
    }
  }
}
</script>

<style lang="scss">
$border-color-hover: rgb(250, 250, 251);

%hover-arrow {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 4px 0 4px 0;
  border-color: transparent;
  background-color: transparent;

  transform: rotate(45deg);

  right: 15px;
  border-width: 5px;
  z-index: var(--z-index-4);
  border-style: solid;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;

  transition: border-color .15s, background-color .15s;
}

.c-dropdown {
  & > .overlay {
    display: unset;
    cursor: default;
  }

  @include responsive (xs-mobile, mobile) {
    position: fixed;
    width: 100vw;
    height: 100vh;

    z-index: var(--z-index-5);
  }

  & > .c-popover.popover {
    @include responsive (xs-mobile, mobile) {
      bottom: 10px;
      z-index: var(--z-index-4);
      left: 50% !important;
      top: unset !important;
      transform: translateX(-50%);

      width: calc(100vw - 20px);

      &::before { display: none; }
    }

    & > .actions {
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      min-width: 240px;

      overflow: visible;

      background-color: transparent !important;
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0.95) 100%) !important;
      box-shadow: unset;

      & > .action {
        display: flex;
        flex-basis: 50%;
        align-items: center;

        cursor: pointer;
        padding: 10px;
        border-bottom: none;
        background-color: transparent;
        color: color-var(text, base-80);

        transition: background-color .15s;

        &::before { @extend %hover-arrow; }
        &:hover { background-color: color-var(text, base-02); }
        &:only-child { border-radius: 5px; }

        @include responsive (xs-mobile, mobile) { &::before { display: none; } }

        &.-first {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          &::before { top: -5px; }
        }

        &.-bottom.-first:hover::before {
          border-color: $border-color-hover;
          background-color: $border-color-hover;
          border-left-color: $border-color-hover;
          border-top-color: $border-color-hover;
        }

        &.-last {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;

          &::before { bottom: -5px; }
        }

        &.-top.-last:hover::before {
          border-color: color-var(text, base-02);
          background-color: color-var(text, base-02);

          border-bottom-color: $border-color-hover;
          border-right-color: $border-color-hover;
        }

        &.-highlight-red { color: color-var(negative); }
        &.-highlight-red > .icon { @include icon-color(negative); }
        &.-highlight-red:hover {
          background: rgba(255, 121, 135, 0.2);

          &.-first::before {
           border-color: rgba_to_rgb(rgba(255, 121, 135, 0.2));
          }
        }

        &.-border-top { border-top: var(--base-border) }
        &.-border-bottom { border-bottom: var(--base-border) }

        & > .text { padding-left: 10px; font-size: 14px; }
        & > .icon { @include icon-color(text, base-30); }
        & > .img { width: 20px; height: 20px; }

        @include responsive (xs-mobile, mobile) {
          padding: 20px;

          & > .text { padding-left: 20px }
        }
      }
    }
  }
}

@include responsive (xs-mobile, mobile) {
  .c-dropdown-slide {
    &-enter-active,
    &-leave-active {
      transition: opacity .5s;

      & > .popover { transition: bottom .4s; }
    }

    &-enter,
    &-leave-to {
      opacity: 0;

      & > .popover { bottom: -100% !important; }
    }
  }
}
</style>
