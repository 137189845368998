import * as types from '@types'
import * as services from '@services/formation'
import { getCustomFields } from '@services/personalInfo'

export default {
  state: {
    formation: [
      { id: new Date().getUTCMilliseconds(), files: [] }
    ],
    options: {
      formation: {
        educationTypes: []
      }
    },
    customFields: {}
  },

  getters: {},

  mutations: {
    [types.EMPLOYEE_EDUCATION]: (state, formations) => {
      state.formation = [ ...formations ]
    },

    [types.EMPLOYEE_ADD_EDUCATION]: (state) => {
      state.formation = [
        ...state.formation,
        { id: new Date().getUTCMilliseconds(), files: [] },
      ]
    },

    [types.EMPLOYEE_SET_EDUCATION]: (state, { data, id }) => {
      const index = state.formation.findIndex(formation => formation.id === id)

      if (index >= 0)
        state.formation.splice(index, 1, { files: [], ...data })
      else
        state.formation.push({ ...data })
    },

    [types.EMPLOYEE_DELETE_EDUCATION]: (state, index) => {
      state.formation.splice(index, 1)
    },

    [types.EMPLOYEE_EDUCATION_OPTIONS]: (state, options) => {
      state.options = { ...options }
    },

    [types.EMPLOYEE_EDUCATION_CUSTOM_FIELDS]: (state, data) => {
      state.customFields = { ...data }

      state.formation = state.formation.map(education => ({
        ...education,
        ...(state.customFields.educations.values[education.id] || {})
      }))
    }
  },

  actions: {
    [types.EMPLOYEE_EDUCATION_OPTIONS]: async ({ state, commit }) => {
      if (state.options.formation.educationTypes.length) return [ false, state.options ]

      const [ err, options ] = await services.getOptions()

      if (!err) commit(types.EMPLOYEE_EDUCATION_OPTIONS, options)

      return [ err, options ]
    },

    [types.EMPLOYEE_EDUCATION_CUSTOM_FIELDS]: async ({ commit, getters }) => {
      const [ err, data ] = await getCustomFields(getters)

      if (!err) commit(types.EMPLOYEE_EDUCATION_CUSTOM_FIELDS, data)

      return [ err, data ]
    },

    [types.EMPLOYEE_EDUCATION]: async ({ getters, commit }) => {
      const [ err, data ] = await services.getFormations(getters)

      if (!err && data.length) commit(types.EMPLOYEE_EDUCATION, data)

      return [ err, data ]
    },

    [types.EMPLOYEE_SET_EDUCATION]: async ({ getters }, variables) => {
      const [ err, formation, endpoint ] = await services.setFormation(getters, variables)

      return [ err, formation, endpoint ]
    },

    [types.EMPLOYEE_SET_EDUCATIONS]: async ({ commit, getters }, formations) => {
      const submited = []
      const errors = []

      await Promise.all((formations || []).map(form => services.setFormation(getters, form)
        .then(([ err, formation ]) => err ? errors.push(err) : submited.push(formation))
        .catch(err => errors.push(err))))

      if (!errors.length) commit(types.EMPLOYEE_EDUCATION, submited)

      return [ errors.length, submited ]
    },

    [types.EMPLOYEE_DELETE_EDUCATION]: async ({ state, getters, commit }, formation) => {
      const index = state.formation.indexOf(formation)
      const [ err ] = await services.deleteFormation(getters, formation)

      if (!err) commit(types.EMPLOYEE_DELETE_EDUCATION, index)

      return [ err, null ]
    },
  }
}
