<template>
  <div
    :class="cardClasses"
    :style="style"
  >
    <slot />

    <div
      v-if="!noHeader"
      class="header-wrapper"
      @click="$emit('click', $event)"
      @mouseover="overHeader = true"
      @mouseleave="overHeader = false"
    >
      <div class="content">
        <slot name="header" />
      </div>

      <collapse-button
        v-if="defaultButton"
        flat
        :class="[ 'collapse', { '--opened': opened } ]"
        icon="arrow-left"
        icon-size="17"
        size="30"
        type="button"
        @pointerdown.native.stop
        @click.native="$emit('button:click', $event)"
      />
    </div>

    <div
      ref="body"
      class="body-wrapper"
    >
      <slot name="body" />
    </div>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import CollapseButton from './fragments/CollapseButton'

export default {
  name: 'CCollapsibleCard',

  mixins: [ MediaQuery ],

  components: {
    CollapseButton
  },

  props: {
    opened: Boolean,
    blockHeaderHover: Boolean,
    defaultButton: {
      type: Boolean,
      default: true
    },
    noBorder: Boolean,
    boxShadow: Boolean,
    transparent: Boolean,
    noHeader: Boolean,
    transitionTime: {
      type: Number,
      default: 700
    },
    faded: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    overHeader: false,
    disableTransition: true
  }),

  watch: {
    opened () {
      this.resize()
    },
    isMobile: {
      handler: 'resize'
    }
  },

  computed: {
    cardClasses () {
      return [
        'c-collapsible-card',
        {
          '--over-header': !this.blockHeaderHover && this.overHeader,
          '--border-style': !this.noBorder,
          '--disable-transition': this.disableTransition,
          '--transparent': this.transparent,
          '--box-shadow': this.boxShadow,
          '--faded': this.faded
        }
      ]
    },

    style () {
      return {
        '--transition-time': `${this.transitionTime}ms`
      }
    }
  },

  methods: {
    resize () {
      this.$nextTick(() => {
        const { body } = this.$refs || {}
        if (!body) return

        const { opened } = this
        body.setAttribute('style', `height: ${opened
          ? ((body.children || [])[0] || {}).clientHeight
          : 0}px`
        )
      })
    },

    onTransitionEnd (e) {
      if (e?.propertyName === 'height') this.$emit('height:changed')
    }
  },

  mounted () {
    this.$nextTick(() => {
      const { body } = this.$refs || {}
      if (!body) return
      body.addEventListener('transitionend', this.onTransitionEnd)
    })

    setTimeout(() => {
      this.disableTransition = false
    }, 400)

    if (this.opened)
      this.resize()
  },

  beforeDestroy () {
    const { body } = this.$refs || {}
    if (!body) return
    body.removeEventListener('transitionend', this.onTransitionEnd)
  }
}
</script>

<style lang="scss">
.c-collapsible-card {
  border-radius: 5px;
  background: #fff;
  position: relative;

  &.--transparent {
    background: unset;
  }

  &.--border-style {
    border: 1px solid color-var(text, base-10);
  }

  &.--over-header {
    @include responsive(tablet, desktop) {
      background-color: color-var(text, base-02);
    }
  }

  &.--box-shadow {
    box-shadow: var(--box-shadow);
    border-radius: var(--base-border-radius);
    transition: all .3s ease;
    position: relative;
  }

  &.--faded {
    opacity: .3;
  }

  & > .header-wrapper {
    padding: 20px 20px;
    display: flex;
    align-items: center;

    & > .content {
      min-width: 0;
      flex: 1;
      display: flex;
      overflow: hidden;
      padding-right: 10px;
    }
  }

  & > .body-wrapper {
    height: 0;
    transition: height var(--transition-time) cubic-bezier(0, 1, 0, 1);
    will-change: height;
    overflow: hidden;
  }

  &.--disable-transition > .body-wrapper {
    transition: none;
  }
}
</style>
