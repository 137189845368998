import * as format from './formatters'

import { createDataLoader } from './wrappers'

const handleErrors = (config, errors, context) => (errors || [])
  .forEach(error => {
    const fullContext = { url: config.resource, ...context }
    if (config.errorHandler) config.errorHandler(error, fullContext)
  })

export const interceptedFetch = config => {
  const {
    resource,
    responseInterceptor,
    genericErrorInterceptor,
    genericErrorHandler,
    fetchImplementation: fetch = window.fetch,
    ...fetchConfig
  } = config || {}

  return fetch(resource, { ...fetchConfig, method: 'POST' })
    .then(response => response.json())
    .then(responseInterceptor)
    .catch(error => {
      if (genericErrorHandler) genericErrorHandler(error)
      return genericErrorInterceptor(error)
    })
}

export const createSingleRequest = (config, fetch = interceptedFetch) => {
  return async (query, variables, requestConfig = {}) => {
    const body = format.singleBody(query, variables)
    const headers = {
      ...config.headers || {},
      ...requestConfig.headers || {}
    }

    const [ errors, data ] = await fetch({
      ...config,
      body,
      headers,
      signal: (requestConfig || {}).signal,
      responseInterceptor: format.singleResponse,
      genericErrorInterceptor: format.singleGenericError,
    })

    handleErrors(config, errors, { query, variables })

    return [ errors, data ]
  }
}

const createSingleItemBatchLoad = async (
  request,
  config,
  fetch,
  createSingleRequest
) => {
  const { query, variables, requestConfig } = request || {}
  const singleRequest = createSingleRequest(config, fetch)
  return [ await singleRequest(query, variables, requestConfig) ]
}

export const createBatchLoad = (
  config,
  fetch,
  createSRequest = createSingleRequest
) => async requests => {
  if ((requests || []).length < 1) throw new TypeError('No valid requests array has been provided')

  if (requests.length === 1) {
    const [ request ] = requests
    return createSingleItemBatchLoad(request, config, fetch, createSRequest)
  }

  const body = format.batchBody(requests)
  const genericErrorInterceptor = format.batchGenericError(requests.length)

  const responses = await fetch({
    body,
    genericErrorInterceptor,
    responseInterceptor: format.batchResponse,
    ...config,
  })

  responses.forEach((response, i) => {
    const [ errors ] = response || []
    handleErrors(config, errors, requests[i] || {})
  })

  return responses
}

export const createBatchRequest = (
  config,
  fetch = interceptedFetch,
  createLoad = createBatchLoad
) => {
  const batchLoad = createLoad(config, fetch)
  const dataLoader = createDataLoader(batchLoad)

  return (query, variables, requestConfig) => dataLoader.load({ query, variables, requestConfig })
}
