export const normalize = (str) => {
  return str
    .normalize('NFKD')
    .replace(/[\u0080-\uF8FF]/g, '')
    .replace(/ /g, '-')
    .replace('?', '')
    .toLowerCase()
}

export default normalize
