import {
  createBatchRequest,
  createSingleRequest
} from './helpers'

import * as format from './formatters'

/**
 * Create a GraphQL request function
 * @param {Object}  config
 * @param {Object<string, string>}  config.headers - Additional Headers for the GraphQL request
 * @param {boolean} config.batch - Allow multiple GraphQL operations to be batched
 * on a single request
 * @param {string}  config.endpoint - The url for the GraphQL request
 * @param { (error: Error) => void } config.genericErrorHandler - Callback for handle fetch errors
 * which aren't from HTTP responses
 * @param { (error: GraphQLError, context: Objectj) => void } config.errorHandler- Callback for
 * handle each error returned in the errors array of the response
 * @param {function} config.fetchImplementation - The implementation of the fetch
 * function (you probably don't want to change that unless you are trying to use this
 * function in a node enviroment or an ancient browser)
 * @returns { (query: string, variables: object) =>
 *  Promise<[error:Error, data:Object<string, Object>]> }
 * - The graphql request function which returns an array with the error and the data from query
 * (if they exist)
 */
export default config => {
  const {
    batch = false,
    genericErrorHandler = () => {},
    errorHandler = () => {},
    fetchImplementation = window.fetch,
    endpoint = '',
    headers = {},
  } = config || {}

  if (!endpoint) throw new TypeError('No endpoint provided')

  const createRequest = batch ? createBatchRequest : createSingleRequest
  const formattedHeaders = format.headers(headers)

  return createRequest({
    resource: endpoint,
    headers: formattedHeaders,
    genericErrorHandler,
    errorHandler,
    fetchImplementation
  })
}
