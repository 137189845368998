const themes = [
  'primary',
  'positive',
  'negative',
  'support',
  'alert',
  'complementary',
  'disabled',
  'white',
  'canceled'
]

export const theme = {
  props: {
    /**
     * The type of theme
     */
    theme: {
      type: [ String ],
      validator: theme => {
        return themes.includes(theme)
      }
    }
  }
}
