/**
 * 'Shadowed' Vue mixin is suposed to be used together with Sass 'shadowed' sass mixin.
 *
 * Import the Vue mixin in your component and place a ref="shadowed" in the element
 * that you would like to have a shadowed scroll behavior.
 *
 * The element must have a wrapper div (father element) for the mixin to work properly.
 *
 * It is very important that the $ref element be present at the mounted lifecycle hook.
 * If that doesn't happen, the mixin won't work properly.
 * e.g. - when there is a loading spinner for the page content usinga v-if conditional redering
 * (use v-show instead).
 *
 * Place a @include shadowed($shadow-size) in the wrapper element (style section)
 * @mixin
 */
import { debounce } from '@convenia/helpers'

export const Shadowed = ({
  refName = 'shadowed',
  upperShadow = true,
  bottomShadow = true,
  leftShadow = false,
  rightShadow = false
} = {}) => ({
  data () {
    return {
      upperShadow: false,
      bottomShadow: false,
      leftShadow: false,
      rightShadow: false,
      observer: null,
      shadowedElement: null,
      binderTimeoutId: null,
      binderTimeoutTries: 0
    }
  },

  watch: {
    upperShadow (val) {
      if (val) this.shadowedElement.parentElement.classList.add('-upper-shadow')
      else this.shadowedElement.parentElement.classList.remove('-upper-shadow')
    },
    bottomShadow (val) {
      if (val) this.shadowedElement.parentElement.classList.add('-bottom-shadow')
      else this.shadowedElement.parentElement.classList.remove('-bottom-shadow')
    },
    leftShadow (val) {
      if (val) this.shadowedElement.parentElement.classList.add('-left-shadow')
      else this.shadowedElement.parentElement.classList.remove('-left-shadow')
    },
    rightShadow (val) {
      if (val) this.shadowedElement.parentElement.classList.add('-right-shadow')
      else this.shadowedElement.parentElement.classList.remove('-right-shadow')
    }
  },

  methods: {
    setShadows () {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
        scrollLeft,
        scrollWidth,
        clientWidth
      } = this.shadowedElement

      this.upperShadow = scrollTop > 0 && upperShadow
      this.bottomShadow = scrollHeight > (clientHeight + scrollTop) && bottomShadow
      this.leftShadow = scrollLeft > 0 && leftShadow
      this.rightShadow = scrollWidth > (clientWidth + scrollLeft) && rightShadow
    },

    // debounce is used to avoid triggering the function several times
    toggleScrollShadow: debounce(function debounced () {
      // timeout is used to wait for any animations to end
      setTimeout(this.setShadows, 500)
    }, 500),

    setMixinObservers () {
      // set observer for shadows reactivity
      this.observer = new MutationObserver(this.toggleScrollShadow)
      this.observer.observe(this.shadowedElement, { childList: true })

      window.addEventListener('resize', this.toggleScrollShadow)
      this.shadowedElement.addEventListener('scroll', this.setShadows)
    },

    bindObservers () {
      const ref = this.$refs[refName]
      if (!ref) {
        clearTimeout(this.binderTimeoutId)
        this.binderTimeoutTries += 1

        if (this.binderTimeoutTries < 10)
          this.binderTimeoutId = setTimeout(this.bindObservers, 1000)
        else
          console.warn('Shadowed.js: Binding reference observers failed.')

        return
      }

      this.shadowedElement = (ref || {}).$el || this.$refs[refName]

      this.setMixinObservers()
      this.toggleScrollShadow()
    }
  },

  mounted () {
    this.binderTimeoutId = setTimeout(this.bindObservers, 200)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.toggleScrollShadow)
    if (this.shadowedElement) this.shadowedElement.removeEventListener('scroll', this.setShadows)

    if (this.observer) this.observer.disconnect()
  }
})

export default Shadowed
