<template>
  <div
    :class="[ 'c-form-builder-list-field',
              { '-is-row': fieldSchema.itemSchema().type === 'row' }
    ]">
    <component
      v-for="(itemValue, index) in usableValue"
      :is="getComponent(index)"
      ref="items"
      :key="index"
      :field-schema="itemsSchemas[index]"
      :value="itemValue"
      :list-actions="defineItemActions(value, index)"
      :class="getFormFieldClasses(itemsSchemas[index])"
      :field-options="getOptions(index)"
      :row-field-options="fieldsOptions"
      :field-name="`${fieldName}-${index}`"
      :validation-field-name="`${fieldName}-${index}`"
      :form-value="formValue"
      :has-row-labels="index === 0"
      v-bind="$attrs"
      @input="onInput($event, value, index)"
      @list:add="onAddField(value, index, fieldSchema)"
      @list:remove="onRemoveField(value, index, fieldSchema)"
      @pre-validate="$emit('pre-validate')"
    />
  </div>
</template>

<script>
import { is } from '@convenia/helpers'
import FormField from './FormField.vue'
import FormFieldRow from './FormFieldRow.vue'
import SubmitFormat from '../mixins/SubmitFormat'

/**
 * Only these input types works currently on item schema. That's because they are the ones
 * which follow the v-model structure
 */
const SUPPORTED_TYPES = [
  'text', 'select', 'color', 'check', 'radio', 'date', 'list-check', 'row'
]

export default {
  mixins: [ SubmitFormat({ schemaRef: 'fieldSchema', childRefsName: 'items' }) ],

  components: {
    FormField,
    FormFieldRow
  },

  props: {
    value: {
      required: true,
      type: Array
    },

    fieldSchema: {
      type: Object,
      required: true,
      validator (value) {
        return typeof (value || {}).itemSchema === 'function'
      }
    },

    fieldOptions: {
      type: Array,
      default: () => ([])
    },

    fieldsOptions: {
      type: Object,
      default: () => ({})
    },

    fieldName: {
      type: String,
      default: '',
    },

    formValue: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    isRow () {
      const { fieldSchema } = this
      const hasItemSchema = fieldSchema.hasOwnProperty('itemSchema')
      const isFunction = hasItemSchema && is(fieldSchema.itemSchema, 'Function')

      return isFunction && this.fieldSchema.itemSchema().type === 'row'
    },

    itemsSchemas () {
      return (this.value || []).map((_, index) => {
        const itemSchema = this.fieldSchema.itemSchema(index)
        const { type } = itemSchema || {}
        if (!SUPPORTED_TYPES.includes(type)) console.warn(`
          [Form Builder list Input]: Unsupported item schema of type '${type}'
          Supported types: ${SUPPORTED_TYPES.map(v => `'${v}'`).join(', ')}.
          (field-index: ${index})
        `)

        return itemSchema
      })
    },

    usableValue () {
      return this.value.slice(0, this.fieldSchema.maxLength || this.value.length)
    }
  },

  methods: {
    getComponent (index) {
      return this.itemsSchemas?.[index]?.type === 'row'
        ? 'form-field-row'
        : 'form-field'
    },

    defineItemActions (value, index) {
      const itemSchema = this.itemsSchemas[index]
      const { noRemove, noAdd, hideActions } = itemSchema || {}
      if (hideActions) return { add: false, remove: false }

      const hasOptionsCount = this.fieldSchema.selectOnce
        && this.value.length === this.fieldOptions.length

      return value.length > 1
        ? { add: hasOptionsCount ? false : !noAdd, remove: !noRemove }
        : { add: !noAdd }
    },

    getFormFieldClasses (itemSchema) {
      return [
        'form-field',
        {
          '-no-label': !itemSchema.label,
          '-hide-list-actions': itemSchema.hideActions
        }
      ]
    },

    getOptions (index) {
      const { trackBy, type } = this.itemsSchemas[index]
      const { selectOnce } = this.fieldSchema || {}
      const value = this.value[index]
      const listTypes = [ 'select', 'check', 'radio', 'list-check' ]

      if (!listTypes.includes(type)) return []

      return selectOnce
        ? this.fieldOptions.filter(
          option => !this.value.includes(option[trackBy]) || option[trackBy] === value)
        : this.fieldOptions
    },

    onInput (itemValue, value, index) {
      const newValue = [ ...(value || []) ]

      newValue[index] = itemValue
      this.$emit('input', newValue)
    },

    onAddField (value, index, fieldSchema) {
      const newIndex = index + 1
      const newValue = [ ...(value || []) ]
      const newItemValue = this.getNewItemValue(newIndex)

      newValue.splice(newIndex, 0, newItemValue)

      const handler = () => {
        this.$emit('input', newValue)
        this.$nextTick(() => this.focusItem(newIndex))
      }

      if (((fieldSchema || {}).manualCall || {}).add)
        return this.$emit('list:add', {
          index: newIndex,
          newItemValue,
          handler
        })

      handler()
    },

    onRemoveField (value, index, fieldSchema) {
      const newValue = [ ...(value || []) ]
      const [ removedItemValue ] = newValue.splice(index, 1)

      const handler = () => this.$emit('input', newValue)

      if (((fieldSchema || {}).manualCall || {}).remove)
        return this.$emit('list:remove', {
          value: removedItemValue,
          index,
          handler
        })

      handler()
    },

    focusItem (index) {
      try {
        const field = this.$refs.items[index]
        if (field.fieldSchema.type !== 'select') this.$refs.items[index].focusField()
      } catch (e) { return e }
    },

    getRemainingOptions (index) {
      const { trackBy } = this.fieldSchema.itemSchema(index)
      const valueSet = new Set(this.value)

      return this.fieldOptions
        .map(option => (option || {})[trackBy])
        .filter(option => !valueSet.has(option))
    },

    getNewItemValue (newIndex) {
      const remainingOptions = this.fieldSchema.selectOnce
        ? this.getRemainingOptions(newIndex)
        : []

      // for now row type doesn't support c-select rows
      if (this.isRow) {
        const { length } = this.fieldSchema.itemSchema().schemas
        return Array(length).fill('')
      }

      return remainingOptions.length === 1
        ? remainingOptions[0]
        : this.fieldSchema.itemSchema(newIndex).value || null
    }
  },
}
</script>

<style lang="scss">
.c-form-builder-list-field {
  & > .form-field.-no-label {
    margin: 10px 0;
  }

  & > .form-field > .input-wrapper {
    min-width: 100%;
  }

  &.-is-row {
    margin: 20px 0;
  }

  @include responsive (xs-mobile, mobile) {
     > .form-field:not(.-no-label) { margin: 20px 0;}
  }
}
</style>
