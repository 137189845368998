<template>
  <div :class="classes" :style="{ '--shadow-color': shadowColor }">
    <div
      ref="shadowed"
      class="wrapper"
      @scroll="onScroll"
      @wheel="onVerifyScroll"
      @touchstart="onVerifyScroll"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { debounce } from '@convenia/helpers'

export default {
  name: 'CShadowed',

  props: {
    hasUpperShadow: {
      type: Boolean,
      default: true
    },
    hasBottomShadow: {
      type: Boolean,
      default: true
    },
    shadowColor: {
      type: String,
      default: '#F3F4F6'
    },
    forceUpperShadow: {
      type: Boolean,
      default: false
    },
    forceBottomShadow: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      scroll: 0,
      observer: null,
      upperShadow: false,
      bottomShadow: false,
      hasScrollbar: false,
    }
  },

  mounted () {
    this.initObservers()
  },

  computed: {
    classes () {
      return [ 'c-shadowed', {
        '-upper-shadow': this.forceUpperShadow || (this.hasUpperShadow && this.upperShadow),
        '-bottom-shadow': this.forceBottomShadow || (this.hasBottomShadow && this.bottomShadow)
      } ]
    }
  },

  methods: {
    onScroll () {
      if (!this.$refs.shadowed) return
      const { scrollTop, scrollHeight, clientHeight } = this.$refs.shadowed

      // gap const prevents the header and scroll disappearing
      // making it impossible for the header to return
      const gap = 70

      const direction = (scrollTop > this.scroll) && (scrollTop > gap) ? 'down' : 'up'

      if (this.hasUpperShadow) this.upperShadow = !!scrollTop
      if (this.hasBottomShadow) this.bottomShadow = scrollHeight > (clientHeight + scrollTop) + 20

      if (scrollTop + clientHeight >= scrollHeight) return

      this.$emit('scroll', direction)
      this.scroll = scrollTop
    },

    onVerifyScroll () {
      if (!this.$refs.shadowed) return
      const { scrollHeight, clientHeight } = this.$refs.shadowed || {}

      if (scrollHeight === clientHeight)
        this.$emit('scroll', 'up')
    },

    checkScrollbar () {
      const element = this.$refs.shadowed
      if (!element) return
      const has = element.scrollWidth < element.offsetWidth

      if (this.hasScrollbar !== has) {
        this.hasScrollbar = has
        this.$emit('has-scrollbar', has)
      }
    },

    observerCallback: debounce(function debounced () {
      this.onScroll()
      this.checkScrollbar()
    }, 200),

    initObservers () {
      this.onScroll()
      this.checkScrollbar()

      this.observer = new MutationObserver(this.observerCallback)
      this.observer.observe(this.$refs.shadowed, { childList: true })

      window.addEventListener('resize', this.observerCallback)
    }
  },

  beforeDestroy () {
    this.observer.disconnect()

    window.removeEventListener('resize', this.observerCallback)
  }
}
</script>

<style lang="scss">
$shadow-size: 100px;

%shadow {
  opacity: 0;
  left: 0;
  content: "";
  display: block;
  position: absolute;
  z-index: var(--z-index-1);
  height: $shadow-size;
  pointer-events: none;
  background: linear-gradient(0deg, rgba(243, 244, 246, 0) 0%, var(--shadow-color) 100%);
  transition: opacity 0.3s;
  width: 100%;
}

.c-shadowed {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  max-height: 100%;
  min-height: 0;

  & > .wrapper {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &::before, &::after { @extend %shadow; }
  &::after { bottom: 0; transform: scaleY(-1); }

  &.-upper-shadow::before,
  &.-bottom-shadow::after { opacity: 1; }
}
</style>
