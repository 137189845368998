const ALL_TRACKING = 'ALL_TRACKING'

export default {
  name: 'CMultiSelect',

  data: () => ({
    selectAll: false
  }),

  props: {
    validation: String,

    options: {
      type: Array,
      required: true
    },

    value: Array,

    trackBy: String,

    displayBy: String,

    labelLeft: Boolean,

    fieldRules: Object,

    /**
     * The select component will $emit just the trackBy value when true
     */
    emitTrackedValue: Boolean,

    /**
     * A special option to select all other options
     */
    allOption: String,

    /**
     * Defines whether a single option can be auto selected
     * (fieldRules.required === true also enables this behavior)
     */
    autoSelectSingle: Boolean,

    /**
     * Defines whether the initial value can be converted
     * to the all option
     */
    autoSelectAll: {
      type: Boolean,
    }
  },

  computed: {
    selectAllItem () {
      return { [this.trackBy]: ALL_TRACKING, [this.displayBy]: this.allOption }
    },

    availableOptions () {
      if (this.selectAll) return []

      if (!this.trackBy)
        return (this.withSpecialOptions || [])
          .filter(op => !(this.computedValue || []).includes(op))

      const getOption = op => this.emitTrackedValue ? op : op[this.trackBy]

      const selected = (this.computedValue || []).map(getOption)
      return (this.withSpecialOptions || []).filter(op => !selected.includes(getOption(op)))
    },

    isSingleOption () {
      return (this.options || []).length === 1
    },

    canAutoSelectSingle () {
      return this.autoSelectSingle || (this.fieldRules || {}).required
    },

    withSpecialOptions () {
      return [
        ...(this.allOption && !this.isSingleOption ? [ this.selectAllItem ] : []),
        ...this.options
      ]
    },

    computedValue () {
      if (this.selectAll) return [ this.selectAllItem ]

      if (this.emitTrackedValue) {
        // This reduce avoids breaking the component render when the value does
        // not correspond to any of the options available
        return (this.value || []).reduce((acc, cur) => {
          const found = (this.options || []).find(option => option[this.trackBy] === cur)
          return found ? [ ...acc, found ] : acc
        }, [])
      }

      return this.value
    }
  },

  methods: {
    onInput (input) {
      if (this.isAllOption(input)) return this.onSelectAllInput()

      this.$emit('add', this.emitTrackedValue ? input[this.trackBy] : input)
    },

    onSelectAllInput () {
      const { options, emitTrackedValue, trackBy } = this
      const sendOptions = options.map(option => emitTrackedValue ? option[trackBy] : option)

      this.selectAll = true
      return this.$emit('input', sendOptions)
    },

    onRemove ({ index, event }) {
      if (this.isAllOption(event)) return this.onSelectAllRemove()

      this.$emit('remove', this.emitTrackedValue ? index : event)
    },

    onSelectAllRemove () {
      this.selectAll = false
      this.$emit('input', [])
    },

    isAllOption (item) {
      return item[this.trackBy] === ALL_TRACKING
    },

    selectSingleOption (canAutoSelectSingle) {
      const { value, options, emitTrackedValue, trackBy, isSingleOption } = this

      if (
        !canAutoSelectSingle
        || !isSingleOption
        || (value || []).length
      ) return

      const [ item ] = options
      this.$emit('add', emitTrackedValue ? (item || {})[trackBy] : item)
    }
  },

  mounted () {
    if (
      this.autoSelectAll
      && (this.options || []).length === (this.value || []).length
    ) this.onSelectAllInput()
  },

  watch: {
    canAutoSelectSingle: {
      immediate: true,
      handler: 'selectSingleOption'
    },
  }
}
