/**
 * Returns the constructor of a given value
 * @param {*} value
 * @returns {string} constructor
 */

export const getType = value => Object
  .prototype.toString.call(value)
  .replace(/(\[|\]|object )/g, '')

/**
 * Checks if a value belong has a given constructor
 *
 * @param {*} value - The value to be tested
 * @param {string|Array<string>} constructor - Can either be
 * a string or an array of strings containing the constructors
 * to check for
 * @returns {boolean} - Whether or not the value is of the
 * constructors specified
 */
export const is = (value, constructor) => Array
  .isArray(constructor)
  ? constructor.includes(getType(value))
  : [ constructor ].includes(getType(value))

export const isEmpty = val => {
  if (val === null || val === undefined) return true
  if (is(val, 'Object')) return !Object.keys(val).length
  if (is(val, 'Array')) return !val.length

  return !`${val}`.length
}

export default is
