import { PERSONAL_AREA } from '@content/consts/Personal'
import { getAdditionalFields } from '@services/systemField'
import { mapFiles, getUploadEndpoint } from '@services/upload'
import * as get from '@graphql/queries'
import * as set from '@graphql/mutations'

import * as middlewareGet from '@graphql/middleware/queries'
import middleware from '@graphql/middleware'

const mapEducation = (data, employeeId) => ({
  ...data,
  educationType: data.educationTypeId || '',
  files: mapFiles(data.files || [], employeeId)
})

export async function getOptions () {
  const [ err, data ] = await middleware(get.GetFormationOptions)
  const options = {
    formation: {
      educationTypes: data.educationTypes
    }
  }

  return [ err, options ]
}

export async function getFormations (getters) {
  const employeeId = getters.getEmployeeUuid
  const [ err, employee ] = await middleware(middlewareGet.GetFormations, { employeeId })

  const formations = (employee?.educations || [])
    .map(formation => mapEducation(formation, employeeId))

  return [ err, formations ]
}

export async function setFormation (getters, variables) {
  const employeeId = getters.getEmployeeUuid
  const isCreating = typeof variables.id !== 'string'
  const mutation = !isCreating ? 'UpdateFormation' : 'CreateFormation'
  const formFields = {
    education_type_id: variables.educationType || undefined,
    course: variables.course,
    institution: variables.institution,
    graduation_year: +variables.graduationYear || undefined,
    additional: {
      sectionId: PERSONAL_AREA.EDUCATIONS,
      fields: getAdditionalFields(variables.customFields, variables)
    }
  }
  const input = {
    ...(!isCreating ? { id: variables.id } : {}),
    employee_id: employeeId,
    ...formFields
  }
  const isNotEmpty = Object.values(formFields).some(val => !!val)

  // check if fields are empty to avoid creating an empty Formation
  if (isNotEmpty) {
    const [ err, data ] = await middleware(set[mutation], { input })

    const education = isCreating ? mapEducation(data?.education || {}, employeeId) : variables
    const endpoint = getUploadEndpoint(employeeId, education.id, 'education')

    return [ err, education, endpoint ]
  }

  return [ null, {}, null ]
}

export async function deleteFormation (getters, variables) {
  if (typeof variables.id !== 'string') return [ false, null ]

  const employeeId = getters.getEmployeeUuid
  const [ err ] = await middleware(set.DeleteFormation, {
    input: {
      id: variables.id,
      employee_id: employeeId
    }
  })

  return [ err, null ]
}
