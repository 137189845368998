import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'
import decode from 'jwt-decode'

export const initSentry = () => {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'Non-Error promise rejection captured',
      'Navigation cancelled from',
      'Avoided redundant navigation to current location'
    ],
    tracesSampleRate: 1.0,
  })
}

export const setSentryContext = (token, companyId) => {
  if (!token || !companyId) return

  const { user_uuid: id, user_name: username } = decode(token) || {}

  Sentry.setUser({ id, username })
  Sentry.setContext('active company', { ID: companyId })
}
