<template>
  <div class="c-scale-controller">
    <c-button
      translucent
      class="zoom-out"
      icon="minus"
      icon-size="16"
      size="30"
      width="30"
      :disabled="zoomLevel <= 1"
      :alternativeDisabled="zoomLevel <= 1"
      @click="zoomOut"
    />

    <c-typo
      type="body-2"
      color="white"
      weight="bold"
    >
      {{ zoomPercentage }} %
    </c-typo>

    <c-button
      translucent
      class="zoom-in"
      icon="plus"
      icon-size="16"
      width="30"
      size="30"
      @click="zoomIn"
    />
  </div>
</template>

<script>
export default {
  name: 'CScaleController',

  props: {
    maxZoomClicks: {
      type: Number,
      default: 2
    },
  },

  data: () => ({
    zoomLevel: 1,
    currentZoomClicks: 0,
    baseZoom: 100
  }),

  computed: {
    zoomPercentage () {
      const zoomFactor = 1 + this.currentZoomClicks * 0.25
      return Math.round(this.baseZoom * zoomFactor)
    }
  },

  methods: {
    zoomIn () {
      if (this.currentZoomClicks < this.maxZoomClicks) {
        this.zoomLevel += 0.1
        this.currentZoomClicks += 1
        this.$emit('zoom-in', { zoomLevel: this.zoomLevel })
      } else {
        console.warn('Nível máximo de zoom alcançado')
      }
    },
    zoomOut () {
      if (this.currentZoomClicks > 0) {
        this.zoomLevel -= 0.1
        this.currentZoomClicks -= 1
        this.$emit('zoom-out', { zoomLevel: this.zoomLevel })
      } else {
        console.warn('Nível mínimo de zoom alcançado')
      }
    },
    resetZoom () {
      this.zoomLevel = 1
      this.currentZoomClicks = 0
    },
  }
}
</script>

<style lang="scss">
.c-scale-controller {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 123px;
  height: 40px;

  padding: 5px;

  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;

  background-color: $base-text-color;
}
</style>
