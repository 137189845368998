<template>
  <apex-charts
    ref="apex"
    type="radialBar"
    class="c-graph-radial"
    :width="width"
    :height="height"
    :options="options"
    :series="series"
  />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'CGraphRadial',

  components: {
    ApexCharts: VueApexCharts
  },

  props: {
    height: { type: Number, default: null },

    width: { type: Number, default: null },

    series: { type: Array, default: () => [] },

    labels: { type: Array, default: () => [] },

    color: { type: String, default: '' },

    formatter: {
      type: Function,
      default: (val) => {
        return parseInt(val)
      }
    }
  },

  computed: {
    options () {
      return {
        chart: {
          height: this.height,
          width: this.width,
          type: 'radialBar',
          toolbar: {
            show: false
          },
        },
        colors: [ this.color ],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 220,
            hollow: {
              margin: 0,
              size: '74%',
              background: '#fff',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: false,
              }
            },
            track: {
              background: 'rgba(18,30,72,0.03)',
              strokeWidth: '100%',
              margin: 0,
              dropShadow: {
                enabled: false,
              }
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: 40,
                show: true,
                color: 'rgba(18,30,72,0.5)',
                fontFamily: 'Nunito Sans',
                fontWeight: 600,
                fontSize: '14px',
              },
              value: {
                formatter: this.formatter,
                offsetY: -1,
                color: '#121E48',
                show: true,
                fontFamily: 'Ubuntu',
                fontWeight: 500,
                fontSize: '55px',
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'vertical',
            shadeIntensity: 0.5,
            gradientToColors: [ this.color ],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [ 0, 100 ]
          }
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            }
          },
        },
        stroke: {
          lineCap: 'round'
        },
        labels: this.labels
      }
    }
  },
}
</script>

<style lang="scss">
.c-graph-radial {
  .apexcharts-canvas > .apexcharts-svg > .apexcharts-graphical > .apexcharts-radialbar {
    .apexcharts-datalabels-group {
      text-transform: uppercase;
    }
  }
}
</style>
