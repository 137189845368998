<template>
  <c-input-container ref-cy="search-box" v-bind="$attrs" :class="classes">
    <c-icon icon="search-loupe" class="icon" size="20" />

    <input
      ref="input"
      ref-cy="search-box-input"

      :value="value"
      :class="[ 'input', { '--is-focused': focused } ]"
      :style="{ '--height': `${$options.height}px` }"

      v-bind="$attrs"
      v-on="computedListeners"

      @blur="onBlur"
      @keyup.esc="onEsc"
      @focus="focused = true"
      @input="v => $emit('input', v.target.value)"
    />

    <c-button
      v-if="value"
      grey
      icon="delete-disabled"
      class="cancel"
      type="button"
      :size="20"
      :icon-size="12"
      @click.stop="resetValue"
    />
  </c-input-container>
</template>

<script>
/**
 * Search Field component
 */
const height = 40

export default {
  name: 'CSearchBox',

  height,

  props: {
    /**
     * The value of the field, durr.
     * @model
     */
    value: {
      type: String,
      default: '',
    },

    /**
     * Does not transition the color nor the shadow of the field when this
     * prop is set to true.
     */
    alternativeFocus: Boolean,

    /**
     * Does not respond to esc keyup event
     */
    noEscListener: Boolean
  },

  data () {
    return {
      focused: false
    }
  },

  computed: {
    classes () {
      return [
        'c-search-box', {
          '-focused': this.focused || !!this.value,
          '-alternative-focus': this.alternativeFocus
        }
      ]
    },

    // We need this to avoid emiting the 'input' event twice
    computedListeners () {
      const { input, ...remaining } = this.$listeners

      return remaining
    }
  },

  methods: {
    resetValue () {
      this.$emit('input', '')
      this.focus()
    },

    onBlur () {
      this.focused = false
    },

    onEsc () {
      if (!this.noEscListener) {
        this.onBlur()

        this.resetValue()
      }
    },

    focus () {
      const { input } = this.$refs || {}
      if (input.focus) input.focus()
    },

    blur () {
      const { input } = this.$refs || {}
      if (input.blur) input.blur()
    },

    getHeight () {
      return height
    }
  }
}
</script>

<style lang="scss">
%typing {
  padding-left: 40px;
  background: #fff;
  box-shadow: 2px 8px 16px 0 rgba(24, 50, 115, 0.06);
}

.c-search-box {
  display: flex;
  min-height: var(--height);
  position: relative;

  & > .icon {
    top: 50%;
    right: 10px;
    position: absolute;
    box-sizing: content-box;

    transition: right .3s;
    transform: translate(0, -50%);

    @include icon-color(text, base-30);
  }

  & > .cancel {
    top: 50%;
    right: 10px;
    opacity: 0;
    position: absolute;
    transition: opacity 0.3s;
    transform: translateY(-50%);
  }

  & > .input {
    flex: 1;
    width: 100%;
    outline: 0;
    border: none;
    height: var(--height);
    font-size: 14px;
    padding-left: 15px;
    border-radius: 20px;
    padding-right: 40px;
    transition: all .3s;
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
    color: color-var(text, base-80);
    background: color-var(text, base-05);

    &:focus { @extend %typing }
    &::placeholder { color: color-var(text, base-30); }
  }

  &.-alternative-focus > .input {
    box-shadow: unset;
    background: color-var(text, base-05);
  }

  &.-focused > .icon { right: calc(100% - 30px); }
  &.-focused > .cancel { opacity: 1; }
  &.-focused > .input { @extend %typing; }
}
</style>
