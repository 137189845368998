<template>
  <div
    ref="tabberEl"
    :class="classes"
    :style="{
      '--tabber-padding': `${padding}px`,
      '--tabber-icon-size': `${iconSize}px`,
    }"
  >
    <div
      ref="wrapperEl"
      :class="[ 'wrapper', { '-resizable': resizable } ]"
    >
      <component
        v-for="tab in tabs"
        :is="getComponentType(tab)"
        :ref="tab.value"
        :key="`${tab.value}-key`"
        :class="getClasses(tab)"
        :name="tab.name"
        v-bind="getTabAnchorProps(tab)"
        @click="changeTab(tab, $event)"
      >
        <span class="content">
          <div v-if="tab.icon" class="icon">
            <c-image
              v-if="tab.icon.includes('.svg')"
              :src="tab.icon"
              :no-loader="tab.noLoader"
            />

            <c-icon v-else :icon="tab.icon" :size="iconSize" />
          </div>

          <span v-if="tab.name" class="text">
            {{ tab.name }}
          </span>
        </span>
      </component>

      <div
        :style="[ activeStyle ]"
        :class="borderClasses"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CTabber',

  props: {
    /**
     * An array of tab object items.
     */
    tabs: {
      type: Array,
      required: true
    },

    /**
     * The value of the current active tab, must match of the values in the
     * tabs Array.
     */
    activeTab: {
      type: [ String, Function ],
      default: ''
    },

    /**
     * DEPRECATED
     * Makes the tabber take up the full width of the container, and adds a
     * listener to the window `resize` event, so that it recalculates it's
     * size and its tabs size whever the screen's width/height changes.
     */
    resizable: Boolean,

    /**
     * Displays only the tab's icons.
     */
    iconOnly: Boolean,

    /**
     * Hide the tab's icons.
     */
    hideIcon: {
      type: Boolean,
      default: false
    },

    iconSize: {
      type: [ String, Number ],
      default: '20'
    },

    /**
     * Disables the tabber's swipe feature.
     */
    noSwipe: Boolean,

    /**
     * Changes the style of the border to look kind of like a pill
     */
    pillBorder: Boolean,

    /**
     * Whether the tabber should take full width.
     */
    fullWidth: Boolean,

    /**
     * Alternative style for the current active tab highlight.
     */
    alternative: Boolean,

    // delay in milliseconds until the setPositon fn is triggered
    // made to correct sidebar transition delay
    positionDelay: {
      type: Number,
      default: 0
    },

    /**
     * Set a lateral padding to the tabber using pseudo-elements
     */
    padding: {
      type: [ Number, String ],
      default: 0
    },

    /**
     * Set a custom url resolver for the tabber to use an anchor instead
     * of depend on emit change-tab event
     */
    urlResolver: {
      type: Function,
      default: () => ''
    }
  },

  data () {
    return {
      mutation: undefined,
      activeStyle: {},
      scrollLeftOffset: 0,
      previousPosition: 0,
      lastClickPosition: 0,
      lastScrollPosition: 0,
      binderTimeoutId: null,
      binderTimeoutTries: 0,
      borderTransition: false
    }
  },

  mounted () {
    this.$nextTick(this.initObservers)
  },

  computed: {
    classes () {
      return [ 'c-tabber',
        {
          '-pill-border': this.pillBorder,
          '-full-width': this.fullWidth,
          '-alternative': this.alternative,
          '-padding': this.padding,
          '-hide-icon': this.hideIcon
        } ]
    },

    borderClasses () {
      return [
        'border',
        {
          '-alternative': this.alternative,
          '-pill-border': this.pillBorder,
          '-transition': this.borderTransition
        }
      ]
    },

    hasUrlResolver () {
      return typeof this.urlResolver === 'function'
    },

    resolvedUrl () {
      return this.hasUrlResolver && this.urlResolver(this.activeTab)
    }
  },

  watch: {
    activeTab: {
      immediate: true,
      handler (tab, oldTab) {
        this.setPosition(tab)
        if (!this.borderTransition
          && (tab && oldTab)
          && (tab !== oldTab)
        ) this.borderTransition = true
      }
    }
  },

  methods: {
    initObservers () {
      this.setMutationObserver()
      window.addEventListener('resize', this.onResize)
      this.$el.addEventListener('wheel', this.onMouseScroll)
      this.setPosition()
    },

    getClasses (tab) {
      return [ 'tab', {
        '-active': this.getActiveTab(tab),
        '-icon-only': tab.iconOnly || this.iconOnly,
        '-alternative': this.alternative,
        '-disabled': tab.disabled,
        '-has-icon': tab.icon,
        '-beta': tab.beta,
      } ]
    },

    getActiveTab (tab) {
      return tab.subTabs
        ? tab.subTabs.includes(this.activeTab) || tab.value === this.activeTab
        : tab.value === this.activeTab
    },

    getActiveStyle (tab) {
      const { wrapperEl } = this.$refs
      const tabberPosition = (wrapperEl && wrapperEl.getBoundingClientRect().left) || 0
      const left = tab ? (tab.getBoundingClientRect().left - tabberPosition) + 'px' : 0
      const width = tab ? tab.offsetWidth + 'px' : 0
      const activeStyle = { left, width }

      this.activeStyle = activeStyle
    },

    centralizeActiveTab (tab) {
      const offsetLeft = tab.offsetLeft + 10
      const index = this.tabs.findIndex(tab => tab.value === this.activeTab)

      let scrollLeft = offsetLeft - (this.$el.clientWidth - tab.clientWidth) / 2

      if (scrollLeft > offsetLeft) scrollLeft = offsetLeft
      else if (index === this.tabs.length - 1) scrollLeft = this.$el.scrollWidth

      this.$el.scrollTo({ left: scrollLeft, behavior: 'smooth' })
    },

    /**
     * Emitted when the user has changed tabs. Contains the value
     * of the tab selected by the user.
     *
     * @event change-tab
     * @type {string}
     */
    changeTab (tab) {
      if (!tab.disabled) return this.$emit('change-tab', tab.value)
    },

    onMouseScroll (event) {
      event.preventDefault()
      this.$el.scrollLeft += event.deltaY
    },

    onResize () {
      setTimeout(this.setPosition, this.positionDelay)
    },

    setPosition () {
      const hasSubTabs = this.tabs.some(tab => tab.subTabs)
      const activeSubTab = (this.tabs.find(tab => {
        return tab.subTabs && tab.subTabs.includes(this.activeTab)
      }) || {}).value
      const activeTab = hasSubTabs && activeSubTab ? activeSubTab : this.activeTab
      const tab = this.$refs[activeTab]
        && (this.$refs?.[activeTab]?.[0]?.$el || this.$refs[activeTab]?.[0])

      if (tab) {
        this.getActiveStyle(tab)
        this.centralizeActiveTab(tab)
        return
      }

      this.activeStyle = {}
    },

    setMutationObserver () {
      const config = { characterData: true, subtree: true, childList: true }
      const callback = mutations => mutations.forEach(() => this.setPosition())
      this.mutation = new MutationObserver(callback)
      this.mutation.observe(this.$el, config)
    },

    getResolvedUrl (tab) {
      return this.hasUrlResolver
        && this.urlResolver(tab)
    },

    getTabAnchorProps (tab) {
      const to = this.getResolvedUrl(tab)

      return {
        ...(to ? { to, external: !!tab?.external, target: '_self' } : {})
      }
    },

    getComponentType (tab) {
      const to = this.getResolvedUrl(tab)

      return to ? 'c-link' : 'div'
    }
  },

  beforeDestroy () {
    if (this.mutation) this.mutation.disconnect()
    window.removeEventListener('resize', this.onResize)
    this.$el.removeEventListener('wheel', this.onMouseScroll)
  }
}
</script>

<style lang="scss">
.c-tabber {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  @include responsive (xs-mobile, mobile) {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.-pill-border {
    background: color-var(text, base-05);
    border-radius: 20px;
    height: 40px;

    & > .wrapper > .tab {
      padding: 10px 20px;
      height: 40px;

      &.-active > .content > .text {
        color: color-var(text, base-50)
      }
    }

    & > .wrapper > .-has-icon > .content {
      padding-left: 21px;

      & > .text {
        display: flex;
        align-items: center;
      }
    }

    & > .wrapper >.border {
      background: #FFF;
      border-radius: 20px;
      height: 40px;
      box-shadow: 2px 8px 16px 0 color-var(text, base-05);
    }
  }

  &.-padding > .wrapper {
    &::before, &::after {
      content: '';
      display: block;
      width: var(--tabber-padding);
      pointer-events: none;
    }
  }

  &.-hide-icon > .wrapper > .-has-icon {
    > .content {
      padding-left: 0;

      > .icon { display: none; }
    }
  }

  // This is so the 'overflow: hidden' doesn't conflict with the
  // alternative style's box-shadow
  &.-alternative {
    padding: 23px 13px;
    margin: -23px -13px;
  }

  &.-full-width, &.-full-width > .wrapper { width: 100%; }

  & > .wrapper {
    display: flex;
    position: relative;
    flex-shrink: 0;
  }

  & > .wrapper > .tab {
    display: flex;
    cursor: pointer;
    align-items: center;
    z-index: 1;

    // Review that later
    flex-shrink: 0;

    transition: color .3s ease;
    padding: 0px 20px 12px 20px;
    @include responsive (xs-mobile, mobile) { padding-bottom: 10px; }

    & > .content > .icon {
      display: flex;

      & > .c-image {
        transition: opacity .3 ease;
        opacity: .8;
      }
      & > .c-icon {
        transition: stroke .3s ease, opacity .3s ease;
        stroke: color-var(text);
        opacity: 0.5;
        will-change: opacity;
      }
    }

    & > .content > .text {
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      font-family: var(--title-font-family);
      color: color-var(text, base-50);
      transition: color .3s ease;
      user-select: none;
      -moz-osx-font-smoothing: grayscale;
    }

    &.-beta > .content {
      &:after {
        display: inline-block;
        align-self: center;
        content: "Beta";
        background: color-var(negative);
        color: white;
        font-size: 9px;
        font-family: Ubuntu;
        font-weight: 500;
        text-transform: uppercase;
        padding: 3px 5px 3px;
        margin-left: 5px;
        border-radius: 20px;
        transform: translateY(-1px);
        box-shadow: 0 1px 4px 1px rgba($negative-color, .3);
      }
    }

    &.-active > .content > .icon > .c-image { opacity: 1; }
    &.-active > .content > .icon > .c-icon { stroke: color-var(text); opacity: 0.5; }
    &.-active > .content > .text { color: color-var(text, base-80); }
    &.-disabled > .content > .icon > .c-image { opacity: .6; }
    &.-disabled > .content > .icon > .c-icon { stroke: color-var(text, base-10); }
    &.-disabled > .content > .text { color: color-var(text, base-30); }

    @include responsive (tablet, desktop) {
      &:not(.-active):not(.-disabled):hover > .content {
        & > .icon > .c-image { opacity: 1; }
        & > .icon > .c-icon { stroke: color-var(text); opacity: 0.5; }
        & > .text { color: color-var(text, base-80); }
      }
    }

    &.-has-icon.-icon-only {
      flex: 1;

      & > .content {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0;

        & > .text { display: none; }
        & > .icon { position: initial; transform: none; }
      }
    }

    &.-alternative {
      padding: 4px 20px;
      &.-active > .content {
        display: flex;
        align-items: center;
      }
      &.-active:not(.-disabled) > .content > .icon { stroke: rgba(#FFF, .9); }
      &.-active:not(.-disabled) > .content > .text {
        font-weight: 500;
        color: #FFF;
        -webkit-font-smoothing: antialiased;
      }
    }
  }

  & > .wrapper.-resizable {
    width: 100%;

    & > .tab { flex-grow: 1; justify-content: center; }
  }

  & > .wrapper > .-disabled { cursor: default; }

  & > .wrapper > .-has-icon > .content {
    position: relative;
    padding-left: calc(var(--tabber-icon-size) + 8px);

    & > .icon {
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0px;
    }
  }

  & > .wrapper > .border {
    position: absolute;
    left: 50%;
    bottom: -4px;
    width: 0;
    height: 4px;
    margin-bottom: 4px;
    border-radius: 4px;
    background: color-var('primary');

    &.-transition {
      transition: left .3s ease, width .3s ease;
    }
    &.-alternative {
      $color-light: color-var('primary', 'base-rgb');
      $color-dark: color-var('primary', 'base-rgb');

      height: 100%;
      border-radius: 20px;
      box-shadow: -2px 9px 16px 0px rgba($color-light, 0.2), -2px 3px 9px rgba($color-dark, 0.36);
    }
  }
}
</style>
