<template>
  <div :class="[ 'c-multi-input-raw', { '-validation': !!validation, '-label-left': labelLeft } ]">
    <c-input-raw
      class="select"
      v-bind="$attrs"
      :validation="validation"
      :label-left="labelLeft"
      v-model="internalValue"
      @keydown.enter.prevent="onInputEnter"
      @keydown.tab="onInputEnter"
      @blur="onInputEnter"
    />

    <item-list
      v-if="(value || []).length"
      class="selected"
      :items="value"
      :display-by="displayBy"
      v-bind="$attrs"
      @remove="onRemove"
    />
  </div>
</template>

<script>
// There was a dependency cycle happening here, so we had to use an absolut import here ¯\_(ツ)_/¯
import { CInputRaw } from '../../CInput'
import CommonMixin from './CommonMixin'
import ItemList from './ItemList'

export default {
  components: { ItemList, CInputRaw },

  mixins: [ CommonMixin ]
}
</script>

<style lang="scss">
.c-multi-input {
  display: flex;
  flex-direction: column;
}
</style>
