<template>
  <transition name="c-overlay-fade">
    <div
      v-if="show"
      :class="classes"
      @click="e => $emit('close', e)"
    />
  </transition>
</template>

<script>
/**
 * Overlay component, used to give focus to a fixed element that appears
 * on the screen.
 */
export default {
  name: 'COverlay',
  props: {
    /**
     * Whether to show or not the overlay
     */
    show: {
      type: Boolean,
      default: false
    },

    /**
     * Makes the overlay completely transparent.
     */
    transparent: {
      type: Boolean,
      default: false
    },

    /**
     * Makes the background slightly darker.
     */
    dark: {
      type: Boolean,
      default: false
    },

    /**
     * Applies a blur effect to the overlay
     */
    blur: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return [ 'c-overlay',
        {
          '-transparent': this.transparent,
          '-dark': this.dark,
          '-blur': this.blur,
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.c-overlay {
  position: fixed;
  z-index: var(--z-index-3);
  left: 0;
  top: 0;

  height: 100vh;
  width: 100vw;
  opacity: 1;

  &:not(.-transparent) {
    opacity: 0.5;
    box-shadow: -1px -1px 4px 0 rgba(0,0,0,0.2);
    background: linear-gradient(135deg,
      color-var(text, base-90) 0%,
      color-var(text, base) 100%);
  }

  &.-dark {
    opacity: .9;
    background: linear-gradient(135deg,
      color-var(text, base-90),
      color-var(text, base));
  }

  &.-blur { backdrop-filter: blur(7px); }
}

.c-overlay-fade {
  @at-root {
    #{&}-enter-active,
    #{&}-leave-active {
      transition: opacity .3s ease !important;
    }

    #{&}-enter,
    #{&}-leave-to {
      opacity: 0 !important;
    }
  }
}
</style>
