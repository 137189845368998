import * as set from '@graphql/mutations'
import * as get from '@graphql/middleware/queries'
import middleware from '@graphql/middleware'

export async function getAdditionalSection (employeeId) {
  const input = { employeeId, area: 'additional.additional', entityForVisualization: 'ADMISSION' }
  const [ err, { employeeInfoSchemas } ] = await middleware(get.GetEmployeeInfoSchema, input)

  if (!employeeInfoSchemas?.length)
    return [ err, {} ]

  const section = employeeInfoSchemas[0]

  const data = {
    ...section,
    schema: JSON.parse(section.schema)
  }

  return [ err, data ]
}

export async function updateAdditionalInfo (employeeId, sectionId, params) {
  const input = {
    employeeId,
    sectionId,
    customFields: Object.entries(params)
      .map(([ customFieldId, value ]) => ({ customFieldId, value }))
  }

  const [ err, data ] = await middleware(set.UpdateAdditionalInfo, { input })

  return [ err, data?.employee?.personalInfo ]
}
