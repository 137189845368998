<template>
  <apex-charts
    ref="apex"
    class="c-graph-column"
    type="bar"
    :width="width"
    :height="height"
    :options="options"
    :series="series"
  />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { getScrollParent } from '@convenia/helpers'

import CommonOptions from './CommonOptions'

const getPercent = (w, n, x) => {
  const percentWidth = Math.round((x / (w / n)) * 100)
  return `${percentWidth}%`
}

export default {
  name: 'CGraphColumn',

  mixins: [ CommonOptions ],

  components: {
    ApexCharts: VueApexCharts
  },

  props: {
    width: {
      type: Number,
      default: undefined
    },

    height: {
      type: Number,
      default: undefined
    },

    value: {
      type: Array,
      default: () => ([])
    },

    categories: {
      type: Array,
      default: () => ([])
    },

    tooltip: {
      type: Function,
      default: ({ name, data, dataPointIndex }) => `${name}: ${data[dataPointIndex]}`
    },

    xOptions: {
      type: Object,
      default: () => ({
        title: undefined,
        min: undefined,
        max: undefined,
        tickAmount: undefined,
        formatter: undefined,
        trimLabels: undefined,
      })
    },

    yOptions: {
      type: Object,
      default: () => ({
        title: undefined,
        min: undefined,
        max: undefined,
        width: undefined,
        tickAmount: undefined,
        formatter: undefined,
        trimLabels: undefined,
      })
    },

    colors: {
      type: Array,
      default: () => ([ '#4BA9FF' ])
    },

    stacked: Boolean,
  },

  watch: {
    value (value) {
      this.series = value
    },
  },

  computed: {
    options () {
      return {
        chart: {
          stacked: this.stacked,
          foreColor: 'rgba(18,30,72,0.8)',
          dropShadow: {
            enabled: true,
            color: this.colors,
            opacity: 0.2,
            top: 0,
            left: 0,
            blur: 3,
          },
          animations: { enabled: false },
          toolbar: { show: false },
        },
        fill: { opacity: 1.0, },
        states: {
          normal: { filter: { type: 'none' } },
          hover: { filter: this.hoverFilter },
          active: { filter: { type: 'none' } },
        },
        grid: { borderColor: 'rgba(18,30,72,0.1)' },
        colors: this.colors,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: this.gridWidth
              ? getPercent(this.gridWidth, this.categories.length, this.barWidth)
              : 5,
            borderRadius: 2
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          showForSingleSeries: true,
          fontSize: '10px',
          fontWeight: 400,
          fontFamily: 'Ubuntu',
          markers: {
            width: 8,
            height: 8,
            radius: 8,
          },
          itemMargin: {
            vertical: 5
          },
          onItemClick: {
            toggleDataSeries: false
          }
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          custom: ({ seriesIndex, dataPointIndex, w }) => {
            const { data, name } = w.globals.initialSeries[seriesIndex]
            const category = this.categories[dataPointIndex]

            const content = this.tooltip({ data, name, category, seriesIndex, dataPointIndex, w })
            const scrollParent = getScrollParent(this.$el) || {}
            const scrollTop = scrollParent.scrollTop || 0

            return `<div class="c-tooltip">
            <div class="c-popover popover -dark" style="transform: translate(-50%, calc(-120% - ${scrollTop}px)); --left: calc(50% - 5px);">
              <div class="card" style="--tooltip-font-size: 11px; --tooltip-max-width: 200px; --tooltip-horizontal-padding: 10px; text-align: left">
                ${content}
              </div>
            </div>
          </div>`
          }
        },
        xaxis: {
          categories: this.categories,
          min: this.xOptions.min,
          max: this.xOptions.max,
          tickPlacement: 'on',
          tickAmount: this.xOptions.tickAmount,
          axisBorder: { show: false },
          crosshairs: { show: false },
          title: {
            text: this.xOptions.title, // Define title so it "ocuppies" space
            style: { color: 'transparent' }
          },
          labels: {
            rotate: 0,
            trim: this.xOptions.trimLabels,
            hideOverlappingLabels: false,
            formatter: this.xOptions.formatter || (value => `${value}`),
            style: {
              fontSize: '11px',
              fontWeight: 500,
              fontFamily: 'Nunito Sans',
              colors: 'rgba(55,61,63,0.5)'
            }
          }
        },
        yaxis: {
          min: this.yOptions.min,
          max: this.yOptions.max,
          tickAmount: this.yOptions.tickAmount,
          labels: {
            minWidth: this.yOptions.width || 70,
            maxWidth: this.yOptions.width || 70,
            formatter: this.yOptions.formatter || (value => `${value}`),
            style: {
              fontSize: '11px',
              fontWeight: 500,
              fontFamily: 'Nunito Sans',
              colors: 'rgba(55,61,63,0.5)'
            }
          }
        }
      }
    },

    barWidth () {
      return this.stacked ? 20 : 9
    }
  },

  data: (vm) => ({
    series: vm.value,
    gridWidth: vm.width
  }),

  mounted () {
    this.observeGridWidthChange()
    this.$nextTick(this.updateGridWidth)
  },

  beforeDestroy () {
    this._gridWidthObserver.disconnect()
  },

  methods: {
    updateGridWidth () {
      const el = this.$el.querySelector('.apexcharts-inner')
      if (!el) return

      const { width } = el.getBoundingClientRect()

      this.gridWidth = width
    },

    observeGridWidthChange () {
      this._gridWidthObserver = new MutationObserver(this.updateGridWidth)
      this._gridWidthObserver.observe(this.$el, { childList: true, attributeFilter: [ 'style' ] })
    }
  }
}
</script>

<style lang="scss">
.c-graph-column {
  .apexcharts-legend {
    .apexcharts-legend-marker { margin-right: 5px; }
    .apexcharts-legend-text { text-transform: uppercase; }
    .apexcharts-legend-series { display: inline-flex !important; }
  }
  .c-tooltip > .popover::before {
    top: initial;
    bottom: -5px;
  }
}
</style>
