<template>
  <component
    :is="isCard ? 'c-card' : 'div'"
    class="c-table-container"
    no-border
    no-hover
  >
    <!-- @slot The container header slot. Contains the title and the
         actions slot by default. -->
    <slot name="header">
      <div class="header">
        <c-title v-if="title" v-bind="titleProps">
          {{ title }}
        </c-title>

        <!-- The header section's actions (buttons, inputs, etc) slot. -->
        <slot name="actions" />
      </div>
    </slot>

    <!-- @slot You can even replace the entire table and use your own! Yay! :D -->
    <slot name="table">
      <c-table
        v-bind="$attrs"
        class="table"
        v-on="$listeners"
      >
        <template v-if="$slots.tableHeader">
          <!-- @slot The CTable's header slot. -->
          <slot slot="header" name="tableHeader" />
        </template>

        <template v-if="$slots.default">
          <!-- @slot The CTable's row slot. -->
          <slot slot="row" />
        </template>
        <!-- @slot The CTable's footer slot. -->
        <slot slot="footer" name="footer" />
      </c-table>
    </slot>
  </component>
</template>

<script>
/**
 * A convinient container component for the CTable.
 */
export default {
  name: 'CTableContainer',

  props: {
    /**
     * The props to the CTitle component in the container's header slot.
     */
    titleProps: {
      type: Object,
      default: () => ({})
    },

    /**
     * The title that foes in the container's header slot.
     */
    title: {
      type: String,
      default: '',
    },

    /**
     * Whether to put the table inside a card component.
     */
    isCard: Boolean
  }
}
</script>

<style lang="scss">
.c-table-container {
  padding: 20px;

  & > .header {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    margin-bottom: 40px;

    & > .c-title {
      flex-shrink: 0;
      & > .text { color: color-var(text, base-80); }
    }

    & > .actions {
      display: flex;
      justify-content: space-between;

      & > .action:not(:last-child) { margin-right: 20px; }
    }
  }
}
</style>
