<template>
  <div class="c-input-color">
    <c-input
      v-bind="$attrs"
      type="text"
      :maxlength="6"
      :value="inputValue"
      :label-left="labelLeft"
      @input="onHexChange"
      @blur="onFieldBlur"
    />

    <c-color-picker
      ref="picker"
      :value="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { isHex } from '@convenia/helpers'

export default {
  name: 'CInputColor',

  props: {
    validation: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      default: '',
    },

    labelLeft: Boolean
  },

  data: () => ({
    inputValue: ''
  }),

  mounted () {
    this.updateInputValue()
  },

  watch: {
    value: { handler: 'updateInputValue' }
  },

  methods: {
    onHexChange (value) {
      const color = '#' + value

      if (isHex(color)) this.$refs.picker.setColor(color)
    },

    onFieldBlur ({ target }) {
      const { value } = target

      if (!isHex('#' + value)) {
        target.value = this.inputValue
        return
      }

      // If hex is short, translate to full
      // Ex: ff0 to ffff00
      if (isHex('#' + value) && value.length === 3) {
        const fullHex = '#' + value.split('')
          .map(v => v + v)
          .join('')

        return this.$refs.picker.setColor(fullHex)
      }
    },

    updateInputValue () {
      const { value } = this
      this.inputValue = value.replace('#', '')
    }
  }
}
</script>

<style lang="scss">
.c-input-color {
  width: 100%;

  & > .c-input {
    width: 110px;

    &:before {
      content: "#";
      position: absolute;
      top: 13px;
      left: 18px;
      font-family: Nunito;
      font-size: 14px;
      opacity: .5;
      font-weight: 600;
    }

    & > .input { text-indent: 28px; }
  }

  @include responsive (xs-mobile, mobile) {
    & > .c-input { margin-bottom: 10px; }
  }

  @include responsive (tablet, desktop) {
    display: flex;
    flex-direction: row-reverse;

    & > .c-color-picker { margin-right: 10px; }
  }
}
</style>
