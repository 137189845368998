<template>
  <apex-charts
    ref="apex"
    class="c-graph-bar"
    type="bar"
    :width="width"
    :height="height"
    :options="options"
    :series="series"
  />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { getScrollParent } from '@convenia/helpers'

import CommonOptions from './CommonOptions'

const getPercent = (w, n, x) => {
  const percentWidth = Math.round((x / (w / n)) * 100)
  return `${percentWidth}%`
}

export default {
  name: 'CGraphBar',

  mixins: [ CommonOptions ],

  components: {
    ApexCharts: VueApexCharts
  },

  props: {
    width: {
      type: Number,
      default: undefined
    },

    height: {
      type: Number,
      default: undefined
    },

    value: {
      type: Array,
      default: () => ([])
    },

    categories: {
      type: Array,
      default: () => ([])
    },

    tooltip: {
      type: Function,
      default: ({ name, data, dataPointIndex }) => `${name}: ${data[dataPointIndex]}`
    },

    xOptions: {
      type: Object,
      default: () => ({
        title: undefined,
        min: undefined,
        max: undefined,
        tickAmount: undefined,
        formatter: undefined,
        trimLabels: undefined,
      })
    },

    yOptions: {
      type: Object,
      default: () => ({
        title: undefined,
        min: undefined,
        max: undefined,
        width: undefined,
        tickAmount: undefined,
        formatter: undefined,
        trimLabels: undefined,
      })
    },

    colors: {
      type: Array,
      default: () => ([ '#4BA9FF' ])
    }
  },

  watch: {
    value (value) {
      this.series = value
    },
  },

  computed: {
    options () {
      return {
        chart: {
          foreColor: 'rgba(18,30,72,0.8)',
          dropShadow: {
            enabled: true,
            color: this.colors,
            opacity: 0.2,
            top: 0,
            left: 0,
            blur: 3,
          },
          fontFamily: 'Nunito Sans',
          animations: { enabled: false },
          toolbar: { show: false },
          selection: { enabled: false }
        },
        fill: { opacity: 1.0, },
        states: {
          normal: { filter: { type: 'none' } },
          hover: { filter: this.hoverFilter },
          active: { filter: { type: 'none' } },
        },
        grid: { borderColor: 'rgba(18,30,72,0.1)' },
        colors: this.colors,
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          showForSingleSeries: true,
          fontSize: '10px',
          fontWeight: 400,
          fontFamily: 'Ubuntu',
          markers: {
            width: 8,
            height: 8,
            radius: 8,
          },
          itemMargin: {
            vertical: 5
          },
          onItemClick: {
            toggleDataSeries: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: getPercent(this.gridHeight, this.categories.length, this.value.length * 11),
            borderRadius: 2,
            borderRadiusApplication: 'end'
          }
        },
        stroke: {
          width: 2,
          colors: [ 'transparent' ],
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          custom: ({ seriesIndex, dataPointIndex, w }) => {
            const { data, name } = w.globals.initialSeries[seriesIndex]
            const category = this.categories[dataPointIndex]

            const content = this.tooltip({ data, name, category, seriesIndex, dataPointIndex, w })
            const scrollParent = getScrollParent(this.$el) || {}
            const scrollTop = scrollParent.scrollTop || 0

            return `<div class="c-tooltip">
            <div class="c-popover popover -dark" style="transform: translate(-50%, ${20 - scrollTop}px); --left: calc(50% - 7.5px); --top: -5px;">
              <div class="card" style="--tooltip-font-size:11px; --tooltip-max-width:200px; --tooltip-horizontal-padding:10px; text-align: left">
                ${content}
              </div>
            </div>
          </div>`
          }
        },
        xaxis: {
          categories: this.categories,
          min: this.xOptions.min,
          max: this.xOptions.max,
          tickAmount: this.xOptions.tickAmount,
          crosshairs: { show: false },
          title: {
            text: this.xOptions.title, // Define title so it "ocuppies" space
            style: { color: 'transparent' }
          },
          labels: {
            rotate: 0,
            trim: this.xOptions.trimLabels,
            hideOverlappingLabels: false,
            formatter: this.xOptions.formatter || (value => `${value}`),
            style: {
              fontSize: '11px',
              fontWeight: 500,
              colors: 'rgba(55,61,63,0.5)'
            }
          }
        },
        yaxis: {
          min: this.yOptions.min,
          max: this.yOptions.max,
          tickAmount: this.yOptions.tickAmount,
          title: {
            style: { color: 'transparent' }
          },
          labels: {
            formatter: this.yOptions.formatter || (value => `${value}`),
            minWidth: this.yOptions.width || 150,
            maxWidth: this.yOptions.width || 150,
            style: {
              fontSize: '11px',
              fontWeight: 500,
              colors: 'rgba(55,61,63,0.5)'
            }
          }
        }
      }
    }
  },

  data: (vm) => ({
    series: vm.value,
    gridHeight: vm.height
  }),

  mounted () {
    this.updateHeight()
  },

  methods: {
    updateHeight () {
      const el = this.$el.querySelector('.apexcharts-grid') || this.$el
      const { height } = el.getBoundingClientRect()

      this.gridHeight = height
    }
  }
}
</script>

<style lang="scss">
.c-graph-bar {
  .apexcharts-legend {
    .apexcharts-legend-marker { margin-right: 5px; }
    .apexcharts-legend-text { text-transform: uppercase; }
    .apexcharts-legend-series { display: inline-flex !important; }
  }
  .apexcharts-xaxis > line { display: none; }
}
</style>
