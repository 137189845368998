import middleware from '@graphql/middleware'
import { headers } from '@modules/rest'
import * as get from '@graphql/queries'
import * as set from '@graphql/mutations'

export async function getAdmissionInfo () {
  return fetch(import.meta.env.VITE_REST_URL + '/employee/admission-information', {
    headers: headers()
  })
    .then(response => response.ok ? response.json() : Promise.reject(response))
    .then(({ data }) =>
      [ false,
        {
          logo: data.logo,
          admissionId: data.admission_id,
          employeeId: data.employee_id,
          employeeUuid: data.employee_uuid,
          companyId: data.company_id,
          companyUuid: data.company_uuid,
          requiredFields: [],
          requiredDocuments: []
        }
      ]
    )
    .catch(err => [ err, null ])
}

export async function getAdmissionProcess (getters) {
  const employeeId = getters.getEmployeeUuid
  const [ err, { admissionProcess } ] = await middleware(get.GetAdmissionProcess, { employeeId })

  return [ err, admissionProcess ]
}

export async function getSteps () {
  const admissionEmployeeStepsList = [
    { id: 1, name: 'Foto de Perfil', slug: 'photo' },
    { id: 2, name: 'Dados Pessoais', slug: 'personal-information' },
    { id: 3, name: 'Formação Academica', slug: 'employee-education' },
    { id: 4, name: 'Endereços e Contatos', slug: 'addresses-and-contacts' },
    { id: 5, name: 'Dependentes', slug: 'dependents' },
    { id: 6, name: 'Dados Bancários', slug: 'bank-account' },
    { id: 7, name: 'Outras Informações', slug: 'other-information' },
    { id: 8, name: 'Documentos', slug: 'documents' }
  ]

  return [ false, admissionEmployeeStepsList ]
}

export async function setCurrentStep (getters, stepId) {
  const employeeId = getters.getEmployeeUuid
  const admissionProcessId = getters.getAdmissionProcessId

  const [ err, data ] = await middleware(set.SetCurrentStep, {
    input: {
      id: admissionProcessId,
      employeeId,
      employeeAdmissionStepId: stepId + ''
    }
  })

  return [ err, data ]
}

export async function closeAdmission (getters, admissionId) {
  const companyId = getters.getCompanyId
  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await middleware(set.CloseEmployeeAdmission,
    { input: { employeeId, companyId, admissionId } }
  )

  const success = data.closeEmployeeAdmission

  return [ err || !success ]
}
