import * as types from '@types'
import * as services from '@services/contract'

export default {
  state: {
    contracts: []
  },

  getters: {},

  mutations: {
    [types.EMPLOYEE_GET_CONTRACTS]: (state, contracts) => {
      state.contracts = [ ...contracts ]
    }
  },

  actions: {
    [types.EMPLOYEE_GET_CONTRACTS]: async ({ getters, commit }) => {
      const [ err, contracts ] = await services.getContracts(getters)

      if (!err) commit(types.EMPLOYEE_GET_CONTRACTS, contracts)

      return [ err, contracts ]
    },

    [types.EMPLOYEE_GET_CONTRACTS_DOWNLOAD_URL]: async ({ getters }) => {
      const url = services.getContractsDownloadURL(getters)

      return url
    }
  }
}
