<template>
  <div class="c-input-scale">
    <c-radio-button
      track-by="value"
      display-by="label"
      :options="options"
      :value="value"
      :disabled="disabled"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'CInputScale',

  props: {
    min: { type: Number, default: 0 },

    max: { type: Number, default: 10 },

    value: { type: Number, default: 0 },

    disabled: { type: Boolean },
  },

  computed: {
    options () {
      const values = Array.from({ length: (this.max - this.min) + 1 },
        (_, i) => ({ label: this.min + i, value: this.min + i }))

      return values
    }
  }
}
</script>

<style lang="scss">
.c-input-scale > .c-radio-button > .c-radio-raw {
  margin-top: 40px;
  margin-bottom: 40px;

  & > .inner {
    display: grid;
    grid-template-columns: repeat(11, 25px);
    grid-gap: 20px;
    justify-content: center;

    & > .radio-option {
      display: flex;
      align-items: center;
      flex-direction: column;

      & > .label {
        align-self: center;
        margin-left: 0;
        min-height: 10px;
        margin-top: 10px;
      }
    }
  }

  @include responsive (xs-mobile, mobile) {
    margin-top: 20px;
    margin-bottom: 20px;

    & > .inner {
      grid-gap: 5px;
    }
  }
}
</style>
