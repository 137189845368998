<template>
  <div class="address-contacts">
    <transition name="view" mode="out-in">
      <c-loader v-if="loading" :size="isMobile ? 79 : 99" />

      <form-group
        v-else
        ref="formGroup"
        :label-left="!isMobile"
        :schemas="schemas"
        :data="data"
        :options="options"
        :disabled="submiting"
        :required-fields="requiredFields"
        @add:emergency="onAddEmergency"
        @remove:emergency="onRemoveEmergency"
        @file:add="onFileModalOpen"
        @file:remove="onRemoveFile"
      />
    </transition>

    <dialog-modal
      v-if="removing"
      theme="error"
      title="Deseja excluir este contato de emergência?"
      message="As informações inseridas serão excluídas do formulário.
      Tem certeza que deseja excluir este registro?"
      :loading="submiting"
      @cancel="onRemoveEmergencyCancel"
      @confirm="onRemoveEmergencySubmit"
    />

    <c-form-builder-modal
      v-if="showFileModal"
      label-left
      submit-button-text="Salvar"
      name="address"
      title="Anexar Arquivos"
      v-model="filesFormData"
      :required-fields="requiredFields"
      :is-loading="submiting"
      :is-disabled="submiting"
      :schema="filesSchema"
      @close="onFileModalClose"
      @submit="onFileModalSubmit"
    />

    <dialog-modal
      v-if="showRemoveFileModal"
      theme="error"
      title="Deseja prosseguir?"
      message="Ao prosseguir os arquivos apagados serão permanentemente excluídos,
        esta ação não pode ser desfeita."
      confirm="Excluir"
      cancel="Não, mudei de ideia"
      @cancel="showRemoveFileModal = false"
      @confirm="onFileModalSubmit(filesFormData)"
    />

    <dialog-modal
      v-if="isRemovingFile"
      theme="error"
      title="Deseja excluir o arquivo selecionado?"
      message="O arquivo será permanentemente excluído, esta ação não pode ser desfeita."
      confirm="Excluir"
      cancel="Não, mudei de ideia"
      :loading="submiting"
      @cancel="isRemovingFile = false"
      @confirm="onRemoveFileSubmit"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { MediaQuery } from '@convenia/mixins'
import * as types from '@types'

import FormGroup from '@components/FormGroup'
import DialogModal from '@components/DialogModal'
import contactSchemas from '@forms/contact'
import upload from '@modules/upload'

export default {
  name: 'AddressContacts',

  mixins: [ MediaQuery ],

  components: {
    FormGroup,
    DialogModal
  },

  data: () => ({
    loading: true,
    removing: false,
    activeEmergency: null,
    cities: [],
    showFileModal: false,
    showRemoveFileModal: false,
    isRemovingFile: false,
    removingFile: null,
    filesFormData: {},
  }),

  computed: {
    ...mapState({
      address: state => state.contact.address,
      contact: state => state.contact.contact,
      emergency: state => state.contact.emergency,
      states: state => state.location.states,
      customFields: state => state.contact.customFields,
      employeeId: state => state.admission.employeeUuid,
    }),

    ...mapGetters({
      getRequiredFields: types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS
    }),

    options () {
      const { options } = this.$store.state.contact || {}

      return {
        ...options,
        address: {
          ...(options.address || {}),
          state: this.states,
          city: this.cities
        }
      }
    },

    data () {
      return {
        address: this.address,
        contact: this.contact,
        emergency: this.emergency,
      }
    },

    schemas () {
      const { contact, address, emergency } = contactSchemas(this)

      return {
        address: {
          ...address,
          fields: {
            ...address.fields,
            ...this.customFields.address.schema,
            files: {
              ...address.fields.files,
              hide: true
            }
          }
        },
        contact: {
          ...contact,
          fields: {
            ...contact.fields,
            ...this.customFields.contact.schema
          }
        },
        emergency: {
          ...emergency,
          fields: {
            ...emergency.fields,
            ...this.customFields.emergencyContact.schema
          }
        }
      }
    },

    filesSchema () {
      const { address } = contactSchemas(this)
      const { files } = address.fields

      return { files }
    },

    selectedEmergency () {
      return this.emergency.find(({ id }) => id === this.activeEmergency)
    },

    submiting: {
      get () { return this.$store.state.admission.submiting },
      set (value) { this.$store.commit(types.ADMISSION_SUBMITING, value) }
    },

    requiredFields () {
      const address = [ ...this.getRequiredFields('address') ]

      if (address.includes('addressAttachment'))
        address.push('files')

      return [
        ...address,

        // There are field names conflicts between contacts & emergencyContacts
        // Renaming them fix this issue
        ...this.getRequiredFields('contacts')
          .map(field =>
            field === 'telephone' ? 'personalTelephone' :
              field === 'cellphone' ? 'personalCellphone' :
                field === 'email' ? 'personalEmail' :
                  field
          ),

        ...this.getRequiredFields('emergencyContacts'),
      ]
    }
  },

  mounted () {
    this.$root.$on('next', this.onSubmit)
    this.$root.$on('previous', this.onSave)
    this.init()
  },

  beforeDestroy () {
    this.$root.$off('next', this.onSubmit)
    this.$root.$off('previous', this.onSave)
  },

  methods: {
    ...mapMutations({
      saveAddress: types.EMPLOYEE_ADDRESS_SET,
      saveContact: types.EMPLOYEE_CONTACT_SET,
      saveEmergency: types.EMPLOYEE_EMERGENCY_CONTACT_SET,
      addEmergency: types.EMPLOYEE_EMERGENCY_CONTACT_ADD
    }),

    ...mapActions({
      getCustomFields: types.EMPLOYEE_ADDRESS_CUSTOM_FIELDS,
      getAddress: types.EMPLOYEE_ADDRESS,
      getOptions: types.EMPLOYEE_ADDRESS_CONTACTS_OPTIONS,
      getStates: types.LOCATION_STATES,
      getCities: types.LOCATION_CITIES,
      setAddress: types.EMPLOYEE_ADDRESS_SET,
      setContact: types.EMPLOYEE_CONTACT_SET,
      setEmergency: types.EMPLOYEE_EMERGENCY_CONTACT_SET,
      deleteEmergencyContact: types.EMPLOYEE_EMERGENCY_CONTACT_DELETE,
      deleteFile: types.ADMISSION_DELETE_FILE,
      getRequiredSystemFields: types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS,
    }),

    async init () {
      await this.getAddress()
      await this.getOptions()
      await this.getStates()
      await this.getCustomFields()
      await this.getRequiredSystemFields()

      if (this.data.address.state)
        this.cities = await this.getCities(this.data.address.state)

      this.loading = false
    },

    onAddEmergency () {
      this.addEmergency()
      this.$refs.formGroup.formsData.emergency.push(
        this.data.emergency[this.data.emergency.length - 1]
      )
    },

    onRemoveEmergency (id) {
      this.activeEmergency = id

      const data = this.$refs.formGroup.formsData
      const emergency = data.emergency.find(({ id }) => id === this.activeEmergency)
      const hasContent = Object
        .entries(emergency)
        .filter(([ key ]) => key !== 'id')
        .some(([ , value ]) => !!value)

      if (!hasContent)
        return this.onRemoveEmergencySubmit()

      this.removing = true
    },

    onRemoveEmergencyCancel () {
      this.activeEmergency = null
      this.removing = false
    },

    async onRemoveEmergencySubmit () {
      this.submiting = true
      await this.deleteEmergencyContact(this.selectedEmergency)
      this.submiting = false

      this.$feedback.add({
        type: 'success',
        message: 'Contato de emergência',
        highlighted: 'excluído com sucesso'
      })

      this.activeEmergency = null
      this.removing = false
    },

    async validate () {
      await this.getRequiredSystemFields()

      if (!this.$refs.formGroup)
        return false

      if (!await this.$refs.formGroup.validate())
        return false

      return true
    },

    async onSubmit () {
      if (!await this.validate()) return

      const data = this.$refs.formGroup.formsData

      const isNotEmpty = data => Object.values(data)
        .some(value => `${value}`.length > 0)

      const emergencyContacts = (data.emergency || [])
        .filter(({ id, ...data }) => isNotEmpty(data))
        .map(emergency => ({
          ...emergency,
          customFields: Object.keys(this.customFields.emergencyContact.schema)
        }))

      this.submiting = true

      const [ addressErr ] = await this.setAddress({
        ...this.address,
        ...data.address,
        customFields: Object.keys(this.customFields.address.schema)
      })
      const [ contactErr ] = await this.setContact({
        ...this.contact,
        ...data.contact,
        customFields: Object.keys(this.customFields.contact.schema)
      })
      const [ emergencyErr ] = await this.setEmergency(emergencyContacts)

      this.submiting = false

      if (addressErr || contactErr || emergencyErr)
        return this.$feedback.add({
          type: 'error',
          message: 'Erro',
          highlighted: 'ao salvar Endereços e Contatos. Tente novamente.'
        })

      this.$feedback.add({
        type: 'success',
        message: 'Endereços e contatos',
        highlighted: 'salvos com sucesso.'
      })

      this.$emit('next')
    },

    onSave () {
      if (!this.$refs.formGroup) return
      const data = this.$refs.formGroup.formsData

      this.saveAddress(data.address)
      this.saveContact(data.contact)
      this.saveEmergency(data.emergency || [])

      this.$emit('previous')
    },

    async uploadFiles (files, endpoint) {
      const uploads = await upload({ files, endpoint })

      return Promise.all(uploads.map(({ request }) => request))
    },

    async deleteFiles (files) {
      const { employeeId } = this

      await Promise.all(files.map(
        file => this.deleteFile({ file, employeeId }))
      )
    },

    onFileModalOpen (index) {
      const data = this.$refs.formGroup?.formsData.address

      if (!data) return

      this.active = index

      this.filesFormData.files = [ ...data.files || [] ]
      this.showFileModal = true
    },

    onFileModalClose () {
      this.filesFormData = {}
      this.showFileModal = false
    },

    onRemoveFile (file) {
      this.isRemovingFile = true
      this.removingFile = file
    },

    async onRemoveFileSubmit () {
      const { employeeId } = this

      this.submiting = true

      const [ err ] = await this.deleteFile({
        employeeId,
        file: this.removingFile
      })

      this.submiting = false

      if (err)
        return this.$feedback.add({
          type: 'error',
          message: 'Arquivo',
          highlighted: 'não foi excluído, tente novamente'
        })

      this.$feedback.add({
        type: 'success',
        message: 'Arquivo',
        highlighted: 'excluído com sucesso'
      })

      const data = this.$refs.formGroup?.formsData.address || {}
      const fileIndex = data.files?.findIndex(file => file.id === this.removingFile.id)

      if (fileIndex >= 0)
        data.files.splice(fileIndex, 1)

      this.isRemovingFile = false
    },

    async onFileModalSubmit ({ files }) {
      const customFields = Object.keys(this.customFields.address.schema || {})
      const data = this.$refs.formGroup?.formsData.address || {}
      const { files: initialFiles = [] } = data

      const addedFiles = files
        .filter(file => !file.done)
        .filter(file => !initialFiles.includes(file))

      const removedFiles = initialFiles
        .filter(file => !files.includes(file))

      if (removedFiles.length > 0 && !this.showRemoveFileModal) {
        this.filesFormData = { files }
        this.showRemoveFileModal = true

        return
      }

      this.showRemoveFileModal = false
      this.submiting = true

      const [ err, , endpoint ] = await this.setAddress({
        ...this.address,
        ...data,
        customFields
      })

      if (err) {
        this.submiting = false
        return
      }

      await this.deleteFiles(removedFiles)

      this.uploadFiles(addedFiles, endpoint)
        .then(async () => {
          const updatedFiles = [ ...initialFiles, ...addedFiles ]
            .filter(file => !removedFiles.includes(file))
            .map(file => ({ ...file, progress: 0 }))

          data.files = updatedFiles

          this.filesFormData = {}
          this.showFileModal = false
          this.submiting = false
        })
        .catch(() => {
          this.submiting = false
        })
    },
  }
}
</script>

<style lang="scss">
.address-contacts {
  position: relative;

  @include responsive (tablet, desktop) {
    margin-top: 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 2px 8px 16px 0px rgba(0, 0, 0, 0.06);
  }
}
</style>
