export const fullNameValidation = (val) => {
  const value = typeof val === 'object' ? val?.value : val
  if (!value) return true

  if (!(value.trim().split(' ').length > 1)) return false

  return !(/\d/).test(val)
}

export const fullNameValidationMsg = ({ fullName }) => {
  if (!fullName) return 'Campo nome inválido'

  return (/\d/).test(fullName)
    ? 'Nome não deve conter números'
    : 'Nome e sobrenome obrigatórios'
}
