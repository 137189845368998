<template>
  <div class="c-grid-matrix" :style="styles">
    <div
      v-for="(cell, index) in Array.from({ length: rows * columns })"
      :key="`cell-${index}`"
      class="cell"
    >
      <slot :name="`cell-${index}`" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'CGridMatrix',

  props: {
    rows: {
      type: [ Number, String ],
      required: true
    },

    columns: {
      type: [ Number, String ],
      required: true
    },

    cellWidth: {
      type: [ Number, String ],
      default: 0
    },

    cellHeight: {
      type: [ Number, String ],
      default: 0
    }
  },

  computed: {
    styles () {
      const getLength = (val) => val === 'auto' || !val
        ? '1fr'
        : `${val}px`

      return {
        '--rows': this.rows,
        '--columns': this.columns,
        '--cell-width': getLength(this.cellWidth),
        '--cell-height': getLength(this.cellHeight)
      }
    }
  }
}
</script>

<style lang="scss">

.c-grid-matrix {
  display: grid;
  grid-template-columns: repeat(
    var(--columns),
    minmax(0, var(--cell-width))
  );
  grid-template-rows: repeat(
    var(--rows),
    minmax(0, var(--cell-height))
  );

  $border: 1px solid color-var(text, base-10);
  border-top: $border;
  border-left: $border;

  & > .cell:hover { background-color: color-var(text, base-02); }

  & > .cell {
    width: var(--cell-width);
    height: var(--cell-height);

    border-right: $border;
    border-bottom: $border;
  }
}
</style>
