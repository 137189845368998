<template>
  <c-card
    no-hover
    no-border
    class="c-info-card"
    :class="classes"
  >
    <div class="wrapper">
      <c-info-header
        v-bind="$attrs"
        :theme="theme"
        :title="title"
        :opened="opened"
        :loading="loading"
        :highlight="isHighlight"
        :margin="alignInfo && margin + (isDense && 10)"
        :action-size="actionSize || (isMobile ? 'sm' : 'md')"
        :collapse="collapse && (grouped ? !isMobile : isMobile)"

        @toggle="onToggle"
        @add="$emit('add')"
        @edit="$emit('edit')"
        @extra="$emit('extra')"
        @remove="$emit('remove')"
      >
        <slot name="before-actions" slot="before-actions" />

        <slot name="actions" slot="actions" />
      </c-info-header>

      <div v-if="hasDescription" class="description">
        <slot name="description" />
      </div>

      <div v-if="hasContent" class="body">
        <slot>
          <c-info
            :fields="fields"
            :margin="margin"
            :info-key="infoKey"
            :loading="loading"
            :highlighted-field="highlightedField"
          >
            <template v-for="fieldType in Object.keys($scopedSlots)" v-slot:[fieldType]="{ field }">
              <slot :name="fieldType" :field="field" />
            </template>

            <template v-slot:file>
              <div
                v-for="(file, index) in files"
                :key="index"
                class="file-wrapper"
              >
                <c-info-tag
                  v-tooltip.ellipsis.dark
                  :icon-size="18"
                  icon="clip-attachment"
                  @click.native="onPreviewOpen(index)"
                >
                  {{ file.name }}
                </c-info-tag>
              </div>
            </template>

          </c-info>
        </slot>

        <slot name="footer" />
      </div>
    </div>

    <div v-if="showAside && !isMobile" ref="aside" class="aside">
      <slot name="aside" />
    </div>

    <c-modal
      v-if="modal && isMobile"
      is-opened
      position="bottom"
      lock-el=".wrapper > .modal"
      :title="title"
      @close="modal = false"
      @click.native.stop="modal = false"
    >
      <slot>
        <c-info :fields="data" :margin="margin" />
      </slot>
    </c-modal>

    <c-preview
      :is-opened="showPreview"
      :images="images"
      :remove="false"
      :current-image="activeFile"
      @next="onPreviewNext"
      @previous="onPreviewPrevious"
      @close="onPreviewClose"
      @download="onDownload(currentFile)"
    />
  </c-card>
</template>

<script>
import { normalize } from '@convenia/helpers'
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CInfoCard',

  mixins: [ MediaQuery ],

  data: () => ({
    opened: true,
    modal: false,
    showPreview: false,
    activeFile: 0,
  }),

  props: {
    /**
     * The card title.
     */
    title: {
      type: String,
      default: '',
    },

    /**
     * Whether the card is part of a list or grid. Changes shadows & collapse behavior.
     */
    grouped: Boolean,

    /**
     * If true the card will be opened by default.
     */
    active: Boolean,

    /**
     * Enables collapse feature. If set to false, the card will never be collapsed.
     */
    collapse: Boolean,

    /**
     * Displays the loading spinner.
     */
    loading: Boolean,

    /**
     * Change card style to borderless.
     */
    borderless: Boolean,

    /**
     * Align title with the fields.
     */
    alignInfo: Boolean,

    /**
     * The action buttons size. 'sm' for small; 'md' for medium.
     */
    actionSize: {
      type: String,
      default: null,
    },

    /**
     * Margin used to align the title.
     */
    margin: {
      type: Number,
      default: 230
    },

    /**
     * the fields object. used to display data into the card body.
     */
    data: {
      type: [ Array, Object ],
      default: () => ([])
    },

    /**
     * The files, which will be displayed into the card footer.
     */
    files: {
      type: [ Array ],
      default: () => ([])
    },

    showAside: {
      type: Boolean,
      default: false
    },

    /**
     * Highlights the card title
     */
    isHighlight: Boolean,

    /**
     * Remove paddings & shadows
     */
    isDense: Boolean,

    theme: {
      type: String,
      default: 'primary'
    },

    highlightedField: {
      type: Object,
      default: () => ({}),
    },

    /**
     * A string used to identify the set of fields
     * displayed in the component, used for global search
     * porpuses
     */
    infoKey: {
      type: String,
      default: ''
    },

    minCustomHeight: Boolean
  },

  mounted () {
    if (this.collapse) this.opened = this.active
  },

  watch: {
    active (data) {
      if (this.collapse) this.opened = data
    }
  },

  computed: {
    fields () {
      const values = this.data

      if (!this.files.length) return values

      const files = { label: 'Anexos', type: 'file', value: this.files }

      if (Array.isArray(values))
        return [ ...values, files ]

      return { ...values, attachment: files }
    },

    classes () {
      return [
        `--anchor-${normalize(this.title).replace(/\(|\)/g, '')}`,
        {
          '-opened': this.opened || (!this.grouped && !this.isMobile),
          '-borderless': this.borderless || this.isDense,
          '-grouped': this.grouped,
          '-has-aside': this.showAside && !this.isMobile,
          '-is-dense': this.isDense,
          '-min-custom-height': (this.opened || !this.isMobile) && this.minCustomHeight
        }
      ]
    },

    images () {
      return this.files.map(file => ({
        ...file,
        label: file.name || file.label,
        src: file.url || file.preview,
      }))
    },

    hasContent () {
      return !!this.$slots.default || Object.keys(this.data).length > 0
    },

    hasDescription () {
      return !!this.$slots.description
    },

    currentFile () {
      return this.images[this.activeFile]
    }
  },

  methods: {
    onToggle () {
      if (!this.collapse) return
      if (!this.hasContent || (!this.grouped && !this.isMobile)) return
      if (this.isMobile && this.grouped) { this.modal = !this.modal; return }

      this.opened = !this.opened
      this.$emit('toggle', this.opened)
    },

    onPreviewOpen (index) {
      this.activeFile = index
      this.showPreview = true
    },

    onPreviewNext () {
      this.activeFile += 1
    },

    onPreviewPrevious () {
      this.activeFile -= 1
    },

    onPreviewClose () {
      this.activeFile = 0
      this.showPreview = false
    },

    async onDownload (file = {}, { nested = false } = {}) {
      const currentFile = nested ? file.nestedFile : file
      const url = await this.resolve(currentFile)
      url && this.open({ ...currentFile, url })
    },

    async resolve ({ url }) {
      try {
        return typeof url === 'function'
          ? await url() : url
      } catch (e) { this.$emit('file-error') }
    },

    async open ({ openMethod, ...file }) {
      try {
        openMethod
          ? openMethod(file)
          : window.open(file.url, '_blank')
        this.$emit('download', file)
      } catch (e) { this.$emit('file-error') }
    },
  }
}
</script>

<style lang="scss">
.c-info-card {
  position: relative;
  transition: initial;

  & > .wrapper {
    flex: 1;
    min-width: 0;

    & > .c-info-header {
      padding: 10px;
      @include responsive (tablet, desktop) { padding: 20px; }
    }

    & > .description {
      color: map-get($text-color, base-80);
      font-family: $base-font-family;
      font-size: 14px;
      line-height: 20px;

      margin-bottom: 20px;
      max-width: 900px;
    }

    & > .body {
      display: none;
      padding: 10px 20px 40px;
      @include responsive (tablet, desktop) { padding: 10px 20px 50px; }

      & > .c-info {
        .file-wrapper {
          cursor: pointer;
          display: inline-block;
          margin: 0 10px 10px 0;
          max-width: 200px;
          min-width: 100px;
          transform: translateY(5px);

          & > .c-info-card > .c-icon { margin: 0 2px; }
        }
      }
    }
  }

  & > .aside {
    width: 35%;
  }

  & > .c-modal {
    & > .wrapper > .modal {
      position: absolute;
      width: auto;
      max-width: 100%;
      top: initial;
      right: 10px;
      bottom: 10px;
      left: 10px;
      border-radius: 4px;

      & > .header > .actions {
        position: relative;
        top: -10px;
        right: -10px;
      }
    }
  }

  &.-min-custom-height {
    min-height: 250px;
  }

  &.-grouped {
    border: var(--base-border);
    box-shadow: none;

    & > .wrapper {
      & > .c-info-header {
        padding: 15px 20px;
        @include responsive (tablet, desktop) { padding: 15px 20px; }
      }
    }
  }

  &.-opened {
    & > .aside,
    & > .wrapper > .body {
      display: block;
    }
  }

  &.-borderless {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid color-var(text, base-10);

    & > .wrapper > .body,
    & > .wrapper > .c-info-header{
      padding-left: 0;
      padding-right: 0;
    }

    @include responsive (xs-mobile, mobile) {
      & > .wrapper > .c-info-header {
        padding-right: 0;
        padding-left: 10px;
      }

      & > .wrapper > .body {
        padding-top: 20px;
        padding-right: 10px;
        padding-bottom: 30px;
        padding-left: 10px;
      }
    }

    &:last-child {
      border-bottom: 0;
      & > .wrapper > .body { padding-bottom: 0; }
    }
  }

  &.-has-aside {
    @include responsive (tablet, desktop) {
      display: flex;
      & > .aside { margin-left: 20px; max-height: 350px; }
    }
  }

  &.-is-dense {
    border: 0;

    &:not(:last-child) {
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid color-var(text, base-10);
    }

    & > .wrapper {
      & > .c-info-header {
        padding: { top: 0; right: 0; left: 0; };
      }
      & > .body { padding: 0 10px; }
    }
  }
}
</style>
