<template>
  <c-card
    no-hover
    no-border
    class="c-info-group"
    :class="classes"
  >
    <slot name="header">
      <c-info-header
        v-bind="$attrs"
        :add="add && hasContent"
        :opened="alwaysOpen || opened"
        :collapse="collapse && isMobile"
        :action-size="isMobile ? 'sm' : 'md'"
        :highlight="isHighlight"
        @toggle="onToggle"
        @add="$emit('add')"
        @close="$emit('close')"
        @edit="$emit('group:edit')"
        @remove="$emit('group:remove')"
      />
    </slot>

    <div v-if="$slots['extra-header']" class="extra-header">
      <slot name="extra-header" />
    </div>

    <div v-if="(alwaysOpen || opened || !isMobile) && (add || hasContent)" class="body">
      <c-list
        v-if="view == 'list'"
        v-bind="$attrs"
        align-info
        :data="data"
        :highlighted-field="highlightedField"
        @add="$emit('add')"
        @edit="$emit('card:edit', $event)"
        @extra="$emit('card:extra', $event)"
        @remove="$emit('card:remove', $event)"
        @file:add="$emit('file:add', $event)"
        @file:remove="$emit('file:remove', $event)"

      >
        <template v-for="fieldType in Object.keys($scopedSlots)" v-slot:[fieldType]="{ field }">
          <slot :name="fieldType" :field="field" />
        </template>
      </c-list>

      <c-grid
        v-if="view == 'grid'"
        v-bind="$attrs"
        :min-width="300"
        :cols="2"
      >
        <c-info-card
          v-for="(item, index) in data"
          v-bind="item"
          :key="index"
          grouped
          title-component="c-employee-info"
          action-size="sm"
          min-custom-height
          :margin="120"
          :highlighted-field="highlightedField"
          @add="$emit('card:add', item)"
          @edit="$emit('card:edit', item)"
          @extra="$emit('card:extra', item)"
          @remove="$emit('card:remove', item)"
        >
          <template v-for="fieldType in Object.keys($scopedSlots)" v-slot:[fieldType]="{ field }">
            <slot :name="fieldType" :field="field" />
          </template>
        </c-info-card>
      </c-grid>
    </div>

    <div v-if="addButton" class="add-btn">
      <c-add v-bind="$attrs" v-on="$listeners" />
    </div>
  </c-card>
</template>

<script>
import { normalize } from '@convenia/helpers'
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CInfoGroup',

  mixins: [ MediaQuery ],

  props: {
    /**
     * Displays the add button.
     */
    add: Boolean,

    /*
    * always displays cards
    */
    alwaysOpen: Boolean,

    addButton: {
      type: Boolean,
      default: true
    },

    /**
     * Displays the collapse action button.
     */
    collapse: Boolean,

    /**
     * Change card style to borderless.
     */
    isDense: {
      type: Boolean,
      default: false
    },

    /**
     * The card view type. Can be 'list' or 'grid'.
     */
    view: {
      type: String,
      default: 'list' // list | grid
    },

    /**
     * An array of object. Each object has props from the CInfoCard component.
     */
    data: {
      type: Array,
      default: () => ([])
    },

    /**
     * Highlights the card title
     */
    isHighlight: Boolean,

    highlightedField: {
      type: Object,
      default: null,
    },

    minCustomHeight: Boolean
  },

  data: () => ({
    active: 0,
    opened: false
  }),

  computed: {
    hasContent () {
      return !!this.$slots.default || Object.keys(this.data).length > 0
    },

    classes () {
      return [
        `--anchor-${normalize(this.$attrs.title || '')}`,
        {
          '--is-dense': this.isDense,
          '-hide-bullets': Object.keys(this.data).length === 1,
          '-opened': this.opened || !this.isMobile,
          '-min-custom-height': (this.opened || !this.isMobile) && this.minCustomHeight
        }
      ]
    }
  },

  methods: {
    onToggle () {
      this.opened = !this.opened
      this.$emit('info-group:toggle', this.opened)
    }
  }
}
</script>

<style lang="scss">
.c-info-group{
  &.--is-dense {
    box-shadow: unset;
    &:hover { box-shadow: unset; }
  }

  & > .c-info-header {
    padding: 10px;
    @include responsive (tablet, desktop) { padding: 20px; }
  }

  & > .extra-header {
    padding: 10px;
    @include responsive (tablet, desktop) { padding: 10px 20px; }
  }

  & > .body {
    padding: 10px 10px 40px;
    @include responsive (tablet, desktop) { padding: 0px 20px 50px; }
  }

  & > .add-btn {
    padding: 10px 20px 50px;

    & > .c-add > .c-button {
      padding: 0 10px;
      @include responsive (tablet, desktop) { padding: 0 20px; }
    }
  }

  &.-min-custom-height {
    min-height: 250px;
  }

  &.-hide-bullets {
    & > .body > .c-list > .c-stepper > .bar {
      display: none;
    }
  }
}
</style>
