import { is } from '@convenia/helpers'

/**
 * Checks if given value is a valid CPF number.
 *
 * @param {String} value - The CPF number to be validated, with or without mask.
 * @returns {Boolean} - whether it is valid or not, derp
 *
 * @author Vitor Luiz Cavalcanti (https://github.com/VitorLuizC)
 */
export const cpf = {
  validate: (data) => {
    const value = data.value || data

    if (!value) return true

    const invalidCpfs = Array.from({ length: 10 }, (_, n) => n.toString().repeat(11))

    const isInvalid = (cpf, rest, pos) => rest !== parseInt(cpf.substring(pos, pos + 1))

    const getRest = sum => sum > 9 ? 0 : sum

    const sumDigit = (cpf, digit) => 11 - (cpf.substring(0, digit).split('')
      .reduce((acc, curr, index) => acc + (parseInt(curr) * ((digit + 1) - index))
        , 0) % 11)

    if (!is(value, 'String')) return false

    const cleanVal = value.replace(/[\D]/gi, '')

    if (!cleanVal.match(/^\d+$/)) return false
    if (cleanVal.length !== 11) return false

    if (invalidCpfs.includes(cleanVal)) return false

    if (isInvalid(cleanVal, getRest(sumDigit(cleanVal, 9)), 9)) return false

    if (isInvalid(cleanVal, getRest(sumDigit(cleanVal, 10)), 10)) return false

    return true
  },
  message: 'CPF inválido'
}
