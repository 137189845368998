import request from '@graphql/middleware'
import { GetCepAddress } from '@graphql/queries'

/**
 * Creates an onInput callback for cep fields which autofill address data.
 *
 * @param {Object} addressMap- Object containing keys of returned cep service
 * to reference on formData fields
 * @returns {Function} - The onInput function which will make the autofill
 */
export default (addressMap, formBuilder) => async function cepFillListener (formData, rawCep) {
  if ((rawCep || '').length !== 9) return

  const cep = rawCep.replace('-', '')
  const [ err, { cepAddress: cepData } ] =
    await request(GetCepAddress, { cep })

  if (err || !cepData?.success) return

  formData[addressMap.neighborhood || 'neighborhood'] = cepData.neighborhood
  formData[addressMap.street || 'street'] = cepData.street
  formData.state = !formBuilder ? (cepData.state || {}) : cepData.state.id
  formData.city = !formBuilder ? (cepData.city || {}) : cepData.city.id
  formData.number = ''
  formData.complement = ''
}
