<template>
  <div :class="classes">
    <c-loader v-if="loading" class="loader" />

    <div v-show="!loading" ref="container" class="viewer-container">
      <resize-sensor initial @resize="resizeScale" />
    </div>
  </div>
</template>

<script>
import ResizeSensor from 'vue-resize-sensor'

import * as pdfJs from 'pdfjs-dist/build/pdf'

import {
  DefaultAnnotationLayerFactory,
  DefaultTextLayerFactory,
  PDFLinkService,
  PDFPageView,
  EventBus
} from 'pdfjs-dist/web/pdf_viewer'

const initWorker = () => {
  try {
    if (typeof window === 'undefined' || !('Worker' in window))
      throw new Error('Web Workers not supported in this environment.')

    const workerCdn = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfJs.version}/pdf.worker.js`

    pdfJs.GlobalWorkerOptions.workerSrc = workerCdn
  } catch (error) {
    console.error(`Unable to load pdfjs: ${error}`)
  }
}

const isPDFDocumentLoadingTask = obj => typeof obj === 'object' && obj !== null && obj.__PDFDocumentLoadingTask === true

const createLoadingTask = async src => {
  const source = { url: '' }

  if (typeof src === 'function')
    source.url = await src()
  if (typeof src === 'string')
    source.url = src

  const loadingTask = pdfJs.getDocument(source).promise
  loadingTask.__PDFDocumentLoadingTask = true

  return loadingTask
}

export default {
  name: 'CPdfViewer',

  components: { ResizeSensor },

  props: {
    src: {
      type: [ String, Function ],
      required: true
    },
    page: {
      type: Number,
      default: 1
    },
    annotations: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    resize: {
      type: Boolean,
      default: true
    },
    rotate: {
      type: Boolean,
      default: false
    },
    scale: {
      type: String,
      default: 'page-width',
      validator: v => [ 'page-width', 'page-height' ].includes(v)
    }
  },

  data () {
    return {
      internalSrc: this.src,
      pdfViewer: null,
      loading: true,
      scaleSet: false
    }
  },

  computed: {
    classes () {
      return [ 'c-pdf-viewer', { '-loading': this.loading } ]
    }
  },

  methods: {
    initViewer () {
      if (!isPDFDocumentLoadingTask(this.internalSrc)) {
        this.internalSrc = createLoadingTask(this.internalSrc)
        this.$emit('loading', true)
      }

      const eventBus = new EventBus()
      const { container } = this.$refs
      const pdfLinkService = new PDFLinkService({ eventBus })

      const annotationLayer = this.annotation && new DefaultAnnotationLayerFactory({ eventBus })
      const textLayer = this.text && new DefaultTextLayerFactory({ eventBus })

      // Bind callbacks to internalSrc's loadingTask, this
      // will basically instantiate PDF.js's PDFViewer
      this.internalSrc
        .then(pdfDoc => {
          this.pdfData = pdfDoc
          this.$emit('meta:num-pages', pdfDoc.numPages)
          return pdfDoc.getPage(this.page)
        })
        .then(pdfPage => {
          this.pdfViewer = new PDFPageView({
            container,
            eventBus,
            scale: 1,
            id: this.page,
            textLayerFactory: textLayer,
            annotationLayerFactory: annotationLayer,
            defaultViewport: pdfPage.getViewport({ scale: 1 })
          })

          this.loading = false
          this.$emit('loading', false)

          this.pdfViewer.setPdfPage(pdfPage)
          pdfLinkService.setViewer(this.pdfViewer)
          this.drawScaled(this.scale)
        })
        .catch(err => console.error('initViewer.error: ', err))
    },

    destroyViewer () {
      if (!this.pdfViewer) return

      this.pdfViewer.destroy()
      this.pdfViewer = null
    },

    calculateScale () {
      const { width = -1, height = -1 } = this.pdfViewer?.viewport || {}

      if (!this.$refs.container) return

      this.pdfViewer.update(1, this.rotate)

      const calcWidth = width === -1 && height === -1
        ? this.$refs.container.offsetWidth
        : width

      return calcWidth / this.pdfViewer.viewport.width
    },

    calculateScaleHeight () {
      if (!this.$refs.container) return

      this.pdfViewer.update(1, this.rotate)
      const height = this.$refs.container.offsetHeight
      const parentEl = this.$refs.container.parentElement.parentElement

      return parentEl.offsetHeight / height
    },

    drawScaled (newScale) {
      if (!this.pdfViewer || this.scaleSet) return

      let calcNewScale

      if (newScale === 'page-width') {
        calcNewScale = this.calculateScale()
        this.$emit('update:scale', newScale)
      } else if (newScale === 'page-height') {
        calcNewScale = this.calculateScaleHeight()
        this.$emit('update:scale', newScale)
      }

      this.scaleSet = true
      this.pdfViewer.update(calcNewScale || newScale, this.rotate)
      this.pdfViewer.draw()
      this.loading = false
      this.$emit('loading', false)
    },

    resizeScale () {
      if (this.resize)
        this.drawScaled('page-width')
    }
  },

  beforeDestroy () {
    this.destroyViewer()
  },

  mounted () {
    this.$nextTick(() => {
      initWorker()
      this.initViewer()
    })
  }
}
</script>

<style lang="scss">
.c-pdf-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.-loading {
    width: 524px !important;
    // Why? Honestly, I'm not sure.
    height: calc(100vh - 300px) !important;

    @include xs-mobile { width: 100px !important; }
  }

  & > .loader {
    transform: none !important;
    opacity: 0.3;
  }

  & > .viewer-container {
    height: 100%;
    max-height: 100%;
    min-width: 554px;

    @include xs-mobile { min-width: 200px; }

    & > .page,
    & > .page > .textLayer,
    & > .page > .canvasWrapper,
    & > .page > .canvasWrapper canvas {
      width: 100% !important;
      height: 100% !important;
      max-height: 100% !important;
    }

    & > .viewer {
      display: none;
      height: 100%;
      max-height: 100%;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~pdfjs-dist/web/pdf_viewer.css';
</style>
