<template>
  <validation-provider
    v-slot="providerScope"
    v-show="!isHidden"
    v-bind="providerProps"
    ref="validator"
  >
    <div
      v-show="!isHidden"
      class="c-form-field"
      :class="getClasses(providerScope)"
      :style="styleVars"
      :field-name="fieldName"
    >
      <label
        v-if="showLabel"
        :class="[
          'label',
          { 'textarea-label': fieldSchema.textArea, '-top-align-label': fieldSchema.topAlignLabel }
        ]"
      >
        <c-icon
          v-if="fieldSchema.labelIcon"
          :size="fieldSchema.labelIconSize"
          :icon="fieldSchema.labelIcon"
          class="icon"
        />

        <span v-else class="text">
          <span v-html="fieldSchema.label" />

          <span v-if="fieldSchema.required" class="required">*</span>

          <span class="info-information" v-if="hasHintMobile" ref="actionHint" @click="showPopover = true">
            <c-icon icon="info-information-circle" :size="16" />
          </span>
        </span>
      </label>

      <div
        v-if="fieldSchema.type === 'slot'"
        class="input-slot"
      >
        <slot
          v-bind="getAttrs(providerScope)"
          v-on="$listeners"
        />
      </div>

      <div v-else class="input-wrapper">
        <component
          :is="fieldComponent"
          ref="fieldComponent"
          v-bind="getAttrs(providerScope)"
          @focus="focusing = true"
          @blur="focusing = false"
          @mouseover="hovering = true"
          @mouseout="hovering = false"
          @flash-validation="onFlashValidation"
          @hint:show="optionsHint = $event"
          @hint:hide="optionsHint = null"
          v-on="$listeners"
        />

        <c-transition duration="900" mode="out-in">
          <div v-show="hint && !isMobile && canShowHint" class="hint" :class="hintClasses">
            <span class="text" v-html="hint" />
          </div>
        </c-transition>

        <form-popover-hint
          v-if="hasHintMobile && showPopover"
          :target="$refs['actionHint']"
          :hint="hint"
          :name="fieldSchema.label"
          @close="showPopover = false"
        />
      </div>

      <div
        v-if="listActions"
        class="list-actions"
      >
        <c-button
          v-show="listActions.add"
          flat
          icon="plus"
          type="button"
          class="add"
          :size="30"
          :icon-size="18"
          @click="$emit('list:add')"
        />

        <c-button
          v-show="listActions.remove"
          flat
          icon="minus"
          type="button"
          class="remove"
          :size="30"
          :icon-size="18"
          @click="$emit('list:remove')"
        />
      </div>

      <form-field-validation
        v-if="showValidation || !!flashValidation"
        :data-testid="`form-field-validation-${fieldName}`"
        class="validation"
        :temporary="!!flashValidation"
        :message="getValidationMsg(providerScope) || flashValidation"
      />
    </div>
  </validation-provider>
</template>

<script>
import * as rules from 'vee-validate/dist/rules'
import { ValidationProvider, extend, validate } from 'vee-validate'
import { messages } from 'vee-validate/dist/locale/pt_BR'
import { is } from '@convenia/helpers'
import { MediaQuery } from '@convenia/mixins'

import './registerRules'

import CInputRaw from '../../CInput/fragments/CInputRaw.vue'
import CSelectRaw from '../../CSelect/fragments/CSelectRaw.vue'
import CRadioRaw from '../../CRadioButton/fragments/CRadioRaw.vue'
import CMultiSelectRaw from '../../CMultiSelect/fragments/CMultiSelectRaw.vue'
import CMultiInputRaw from '../../CMultiInput/fragments/CMultiInputRaw.vue'
import CStars from '../../CStars/index.vue'
import FormFieldValidation from './FormFieldValidation.vue'
import FormPopoverHint from './FormPopoverHint.vue'
import SubmitFormat from '../mixins/SubmitFormat'

/**
 * TODO:
 * - Polish and reconsider the use of the getValidationMsg() method
 * - Add multi-checkbox support
 */
(messages || {}).required = 'O campo é obrigatório.'
Object.keys(rules).forEach(rule => extend(rule, { ...rules[rule], message: messages[rule] }))

export default {
  name: 'CFormBuilderField',

  mixins: [ MediaQuery, SubmitFormat({ schemaRef: 'fieldSchema' }) ],

  components: {
    CInputRaw,
    CSelectRaw,
    CRadioRaw,
    CMultiSelectRaw,
    CMultiInputRaw,
    CStars,
    ValidationProvider,
    FormFieldValidation,
    FormPopoverHint
  },

  props: {
    /**
     * Any value type in here is actually valid,
     * thus the validator fn that always return true
     * ¯\_(ツ)_/¯
     */
    value: {
      required: true,
      validator: () => true
    },

    /**
     * The field name, used to identify the field
     * in the form and to emit the value to the parent
     * form component.
     */
    fieldName: {
      type: String,
      default: ''
    },

    /**
     * The validationFieldName has the function of correctly mapping the field.
     * For example: a field of type row has an array of inputs so it must be
     * mapped by the name passed and consequently if it has validations they
     * will be displayed in the correct input.
     */
    validationFieldName: {
      type: String,
      default: '',
    },

    /**
     * The field schema, that's basically the defition of the
     * field with all of the props that will be passed down
     * to the respective component plus some extra information
     * that will be used by the FormBuilder and FormField component
     * to add extra functionality (such as validation, for example)
     */
    fieldSchema: {
      type: Object,
      required: true
    },

    /**
     * The schema of the entire form, contains the definitions of
     * all other fields in the form, which may be useful in custom
     * validation functions (which have access to all of the FormField context)
     */
    formSchema: {
      type: Object,
      required: true
    },

    /**
     * The options list of the field, in case the field requires
     * it (e.g. CSelect)
     */
    fieldOptions: {
      type: Array,
      default: () => []
    },

    /**
     * The whole context of the form of tha containing form,
     * that is basically the whole model of the parent form
     * containing the immutable values of other fields, this
     * information is necessary for some field logic.
     */
    formValue: {
      type: Object,
      default: () => ({})
    },

    /**
     * Whether to disable the field, access to this prop
     * is necessary here in the FormField level instead of
     * directly passing it to the field component in order
     * to do a few checks.
     */
    disabled: {
      type: Boolean,
      default: false
    },

    /**
     * Whether to always emit the trackBy value of an option for
     * components that take a list of options by default, instead
     * of emitting the whole option object on input
     */
    emitTrackedValue: {
      type: Boolean,
      default: true,
    },

    /**
     * Displays the label on the left-side instead of in the top.
     */
    labelLeft: Boolean,

    /**
     * Defines Whether the field should be validated
     */
    hasValidation: Boolean,

    /**
     * Has list actions (add and remove)
     */
    listActions: {
      type: Object,
      default: null,
    },

    /**
     * Indicates that the FormField belongs to a FormFieldRow
     */
    isRow: Boolean,

    /**
     * When a FormField belongs to a FormFieldRow AND it should display
     * display its labels in the desktop version
     * (usually the first row of the list)
     */
    hasRowLabels: Boolean,

    /**
     * Field errors
     *
     */
    errors: {
      type: Array,
      default: () => []
    },
  },

  data: () => ({
    // An array containing the IDs of the custom rules registered
    // in this field, which we'll need later when destroying the
    // component so we can unregister them.
    customFnIds: [],
    flashValidation: '',
    optionsHint: '',
    focusing: false,
    hovering: false,
    showPopover: false,
  }),

  /**
   * Vee-validate does not sync the value unless the underlying
   * form component uses a `v-model`, which just wouldn't work
   * for many of our custom form components, so we sync it
   * manually, on first render and on subsequent ones we sync
   * and validate.
   */
  watch: {
    value: {
      immediate: true,
      async handler (newValue, oldValue) {
        this.$nextTick(() => this.$refs.validator.syncValue(newValue))

        // I'm assuming that an undefined value here means first render
        if (oldValue !== undefined && this.hasValidation)
          this.$nextTick(() => this.$refs.validator.validate())

        if (oldValue !== undefined && this.emitPreValidation) {
          const validation = await validate(newValue, this.fieldRules) || {}
          this.$emit('pre-validate', { validation, value: newValue })
        }
      }
    },
    errors: {
      immediate: true,
      handler: 'setErrors'
    },
    isMobile (newValue) {
      if (!newValue) this.showPopover = false
    }
  },

  /**
   * Here we look for the presence of custom validation rules
   * in our validation schema object, if it is present, we have
   * to check the format being used in the schema to register
   * the rule, which can be one of:
   *
   *  - A single object in the format <{ validate: fn, message?: fn || string }>
   *  - An array of (non-arrow) validation functions
   *  - A function (cannot be an arrow fn, sorry, and it has to return it's own message)
   *
   * We then bind these validator functions to this component's context,
   * register the rule(s) accordingly, and them (sort of) deregister them
   * before destroying the component.
   */
  created () {
    const rules = this.fieldSchema.validation
    const custom = is(rules, 'Object') ? rules.custom || [] : ''
    if (!custom) return

    const bindFn = fn => fn.bind(this, this)
    const genId = () => Math.random().toString(36).substr(2, 9)

    const registerRule = ({ validate, message }) => {
      const ruleId = `${genId()}-${Date.now()}`
      const validateFn = bindFn(validate)
      const ruleMsg = is(message, 'Function') ? bindFn(message) : message

      extend(ruleId, { validate: validateFn, message: ruleMsg })
      this.customFnIds.push(ruleId)
    }

    if (is(custom, 'Function'))
      registerRule({ validate: custom })
    if (is(custom, 'Object'))
      registerRule(custom)
    if (is(custom, 'Array'))
      custom.forEach(rule => is(rule, 'Function')
        ? registerRule({ validate: rule })
        : registerRule(rule))
  },

  beforeDestroy () {
    this.customFnIds.forEach(ruleId => extend(ruleId, () => ({})))
  },

  computed: {
    fieldComponent () {
      const typeToComponent = {
        'file': 'c-upload',
        'text': 'c-input-raw',
        'password': 'c-input-raw',
        'select': 'c-select-raw',
        'check': 'c-checkbox',
        'radio': 'c-radio-raw',
        'multi-select': 'c-multi-select-raw',
        'multi-input': 'c-multi-input-raw',
        'multi-check': 'c-multi-checkbox',
        'color': 'c-color-picker',
        'date': 'c-date-picker',
        'range-input': 'c-input-range',
        'rate': 'c-stars',
      }

      return typeToComponent[this.fieldSchema.type] || 'c-input'
    },

    /* If there are any custom rules registered, we
     * map them into the rules prop of the ValidationProvider
     * component, otherwise they simply won't be used.
     *
     * And if there are not custom rules registered, we simply
     * return the validation object from the Schema as it is.
     */
    fieldRules () {
      const rules = this.fieldSchema.validation
      if (!is(rules, 'Object') || !(this.customFnIds || []).length)
        return rules || ''

      const mappedCustomRules = (this.customFnIds || [])
        .reduce((acc, value) => ({ ...acc, [value]: true }), {})

      return {
        ...rules,
        ...mappedCustomRules,
        custom: false
      }
    },

    validFieldName () {
      if (this.validationFieldName) return this.validationFieldName

      const { name, label } = this.fieldSchema
      return name || (label || '').toLowerCase() || ''
    },

    fieldValue () {
      if (is(this.fieldSchema.value, 'Function')) {
        const value = this.fieldSchema.value(this.formValue)
        this.$emit('input', value)
        return value
      }

      return this.value
    },

    isRequired () {
      const { validation } = this.fieldSchema || {}
      if (is(validation, 'String')) return validation.includes('required')

      return validation?.required
    },

    /**
     * Returns whether or not the field should be disabled, in order
     * to determine that, we check the following conditions:
     *
     * 1 - Whether or not we have the disabled prop (duh) which is
     *     also used to disable all fields in CFormBuilder
     *
     * 2 - Whether our field schema has a isDisabled function with
     *     some custom logic to determine that, we then delegate
     *     this responsability to said function.
     *
     * 3 - Whether the schema has a 'disabled' property, if it does,
     *     return that.
     *
     * 4 - Check whether the field has options, if it does, it is
     *     most likely a select field, in which case it should be
     *     disabled if its options list is empty.
     */
    isDisabled () {
      if (this.disabled) return this.disabled

      const fieldOptions = this.fieldSchema.options && this.fieldOptions

      if (is(this.fieldSchema.isDisabled, 'Function'))
        return this.fieldSchema.isDisabled(this.formValue)

      const noSelectableOptions = !!this.fieldSchema.options && !(fieldOptions || []).length
        && this.fieldSchema?.nullable
        && !!this.isRequired

      return this.fieldSchema.disabled
        || noSelectableOptions
    },

    /**
     * Here we just check for the `hide` property on our schema,
     * if it is a function with custom logic we call that, otherwise
     * we just return the `hide` property from the schema
     * (which is obviously false if it isn't present there)
     */
    isHidden () {
      if (is(this.fieldSchema.hide, 'Function'))
        return this.fieldSchema.hide(this.formValue)

      return this.fieldSchema.hide
    },

    showLabel () {
      return this.fieldSchema.type !== 'check'
        && (this.fieldSchema.label || this.fieldSchema.labelIcon)
    },

    showValidation () {
      if (this.fieldSchema.type === 'file' || this.fieldSchema.hideValidation)
        return false

      return this.hasValidation && !this.isDisabled
    },

    /**
     * Whether to force validation of disabled
     * fields via 'forceValidation' property of the
     * field schema
     */
    forceValidation () {
      return !!this.fieldSchema.forceValidation
    },

    providerProps () {
      const providerPropNames = [
        'bails',
        'customMessages',
        'debounce',
        'detectInput',
        'immediate',
        'rules',
        'names',
        'skipIfEmpty',
        'tag',
        'vid'
      ]

      return {
        slim: true,
        name: this.validFieldName,
        rules: this.fieldRules,
        disabled: this.isHidden || (!this.forceValidation && this.isDisabled),
        ...providerPropNames
          .filter(p => Object.keys(this.fieldSchema).includes(p))
          .reduce((acc, propName) => ({ ...acc, [propName]: this.fieldSchema[propName] }), {})
      }
    },

    /**
     * Whether to emit the field current value validation
     * without generate validation side-effects
     */
    emitPreValidation () {
      return (this.fieldSchema || {}).emitPreValidation
    },

    hint () {
      if (this.optionsHint)
        return this.optionsHint

      const { hint } = this.fieldSchema || {}

      return is(hint, 'Function')
        ? hint(this.formValue)
        : hint
    },

    canShowHint () {
      const { hintConfig } = this.fieldSchema || {}

      if (!hintConfig)
        return true

      const { hideOnFocus } = hintConfig

      if (hideOnFocus && this.focusing && !this.hovering)
        return false

      return true
    },

    hintClasses () {
      const { hintConfig } = this.fieldSchema || {}

      if (!hintConfig)
        return

      const { alignment } = hintConfig

      return [
        alignment ? `-hint-alignment-${alignment}` : null
      ]
    },

    hasHintMobile () {
      return this.isMobile && this.hint
    },

    styleVars () {
      return {
        '--label-left-padding-right': this.fieldSchema.labelLeftPaddingRight || '20px'
      }
    }
  },

  methods: {
    onFlashValidation (validationMsg) {
      const { validationMsg: schemaValidation } = this.fieldSchema || {}
      this.flashValidation = validationMsg || schemaValidation

      setTimeout(() => { this.flashValidation = '' }, 5000)
    },

    validate () {
      this.$nextTick(() => this.$refs.validator.validate())
    },

    setErrors (errors) {
      this.$nextTick(() => this.$refs.validator.setErrors(errors))
    },

    getAttrs ({ errors = [], validate, failedRules = {} }) {
      const { fieldRules } = this
      const { onInput, ...schemaAttrs } = this.fieldSchema

      return {
        ...schemaAttrs,
        validation: this.getValidationMsg(errors, failedRules),
        value: this.fieldValue,
        options: this.fieldOptions || [],
        emitTrackedValue: Object.keys(schemaAttrs).includes('emitTrackedValue')
          ? schemaAttrs.emitTrackedValue
          : this.emitTrackedValue,
        showHint: this.hovering && !this.isDisabled ? this.hovering : this.focusing,
        error: this.hasValidation && errors.length > 0 && !this.isDisabled,
        errors,
        validate,
        fieldRules,
        failedRules,
        fieldName: this.fieldName,
        hasValidation: this.hasValidation,
        disabled: this.isDisabled,
        // MultiCheck has a little bug with the label, since it doesn't
        // have a "raw" version yet, we must omit it.
        ...(schemaAttrs.type === 'multi-check' ? { label: '' } : {})
      }
    },

    getClasses ({ errors }) {
      return {
        '-label-top': !this.labelLeft,
        '-label-left': this.labelLeft,
        '-focusing': this.focusing,
        '-has-errors': errors.length,
        '-has-slot': this.fieldSchema.type === 'slot',
        '-full-width': this.fieldSchema.fullWidth,
        '-has-list-actions': this.listActions,
        '-has-row-labels': this.hasRowLabels,
        '-is-row': this.isRow,
        '-center-label-top': this.fieldSchema.centerLabelTop,
        '-hide-list-actions': this.hideListActions
      }
    },

    getValidationMsg ({ errors = [], failedRules = {} }) {
      const showRequiredMsg = 'required' in failedRules

      const callValidationMsg = validationMsg => is(validationMsg, 'Function')
        ? validationMsg(this.formValue)
        : validationMsg

      const callFirstMsg = defaultMsg => {
        if (!showRequiredMsg) return defaultMsg

        const { required } = ((this.fieldSchema || {}).validation || {})
        return is(required, 'String') ? required : defaultMsg
      }

      const validationMsg = errors.length && !showRequiredMsg && this.fieldSchema.validationMsg
        ? callValidationMsg(this.fieldSchema.validationMsg)
        : callFirstMsg(errors[0])

      return errors.length || this.fieldSchema.forceError
        ? validationMsg || errors[0] || ''
        : ''
    },

    focusField () {
      try {
        this.$refs.fieldComponent.focus()
      } catch (e) { return e }
    }
  }
}
</script>

<style lang="scss">

.c-form-field {
  position: relative;
  display: grid;

  @include responsive(xs-mobile, mobile) {
    grid-template-columns: 100%;

    &.-has-list-actions { grid-template-columns: 1fr 80px; }
  }

  & > .label {
    grid-area: label;
    display: block;
    padding-right: 10px;
    word-break: break-word;
    @include typo(h5, base-50);

    & > .text {
      position: relative;
      display: flex;
      align-items: center;

      & > .required {
        margin-left: 2px;
        font-size: 10px;
        color: color-var(negative);
      }

      & > .info-information > .c-icon {
        @include icon-color(color-var(text, base-30));
      }
    }

    & > .icon { fill: color-var(text, base-30); }
  }

  & > .input-wrapper {
    grid-area: input;

    & > .hint {
      position: absolute;
      // 210px + 360px = The width of the two sections of the field grid
      left: 570px;
      width: calc(100% - 590px);
      min-width: 275px;
      top: 50%;
      transform: translate(22px, -50%);
      padding: 0 0 0 12px;

      &.-hint-alignment-top {
        top: 0%;
        transform: translate(22px, 0);

        &:after {
          top: 20px;
        }
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        transition: .3s ease-out;
      }

      &:before {
        left: 0;
        width: 2px;
        height: 0%;
        background: color-raw(text, base-10);
        transform: translate(-100%, -50%);
      }

      &:after {
        left: -1px;
        width: 6px;
        height: 6px;
        transform: translate(-50%, -50%);
        border-radius: 6px;
        opacity: 0;
        // There's a little issue in here, for whatever reason
        // we cannot access variaveis declared in the root
        // scope of the document inside pseudo-elements depending
        // on how deep they are
        background-color: $base-text-color;
      }

      > .text {
        line-height: 19px;
        display: block;
        transform: translateX(-4px);
        opacity: 0;
        transition: .3s ease-out;
        word-break: break-word;
        @include typo(body-1, base);
      }
    }

  }

  & > .list-actions {
    grid-area: actions;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding: { top: 5px; bottom: 0px; }

    & > .add { margin-right: 10px; }

    @include responsive (tablet, desktop) {
      align-self: flex-start;
      visibility: hidden;
      padding-left: 20px;
    }
  }

  &:focus-within > .list-actions {
    visibility: visible;
  }

  &:hover > .list-actions { visibility: visible; }

  & > .input-slot {
    min-width: 0;
    grid-area: slot;
  }

  & > .validation { grid-area: validation; }

  &.-has-errors { margin-bottom: 10px; }

  &.-focusing,
  &:hover {
    & > .input-wrapper > .hint {
      &:before { height: 100% }

      &:after { opacity: .5 }

      & > .text {
        opacity: .5;
        transform: translateX(0px);
      }
    }
  }

  &.-label-top {
    grid-template-areas:
      "label ."
      "input actions"
      "validation .";
    max-width: 400px;
    margin: 20px 0;

    & > .label { margin-bottom: 10px; }

    &.check { margin: 10px 0; }

    & > .input-wrapper > .hint { left: 370px; }

    &.-has-list-actions {
      max-width: 500px;

      &.-hide-list-actions {
        max-width: 400px;
        grid-template-areas:
          "label ."
          "input input"
          "validation .";
        &.-is-row {
          grid-template-areas:
            "label ."
            "slot slot"
            "validation .";
        }

        & > .list-actions { display: none; }
      }
    }

    &.-center-label-top > .label {
      text-align: center;
      padding-right: 0;
    }
  }

  &.-label-left {
    grid-template-areas:
      "label input actions"
      ". validation actions";
    grid-template-columns: 210px 360px;
    margin: 10px 0;
    align-items: flex-start;

    & > .label {
      min-height: 40px;
      padding-right: var(--label-left-padding-right);
      display: flex;
      text-align: right;
      align-items: center;
      justify-self: right;

      &.textarea-label {
        @include responsive(tablet, desktop) {
          align-items: flex-start;
          padding-top: 18px;
        }
      }

      &.-top-align-label {
        @include responsive(tablet, desktop) {
          align-items: flex-start;
          padding-top: 4px;
        }
      }
    }

    &.radio,
    &[type="radio"] {
      margin: 20px 0 20px;

      & > .label {
        align-items: flex-start;
        padding-top: 4px;
        height: 25px;
      }
    }

    &.check {
      margin-top: 20px;

      // quite the hack
      + .divider + .check { margin-top: -10px; }
      + :not(.check) { margin-top: 20px; }
    }

    &.multi-check {
      margin: 20px 0 20px;

      & > .label {
        align-items: flex-start;
        padding-top: 4px;
        height: 25px;
      }
    }
  }

  &.file {
    & > .input-wrapper > .c-input-container {
      & > .label{ display: none; }
      &.-empty { width: 100%; }
    }

    &.-label-left > .label {
      align-items: flex-start;
      margin-top: 10px;
    }

    @include responsive(tablet, desktop) {
      .c-file-list { width: 570px; max-width: 100%; }

      &.-full-width {
        grid-template-columns: calc(100% + 20px);
        max-width: initial;
        margin-left: -20px;
      }
    }
  }

  &.-has-slot {
    grid-template-areas:
      "label ."
      "slot slot"
      "validation validation";

    &.-full-width {
      grid-template-areas:
        "label . ."
        "slot slot slot"
        "validation validation validation";
    }

    &.-has-list-actions {
      grid-template-areas:
        "label ."
        "slot actions"
        "validation validation";

      & > .list-actions {
        align-self: flex-end;
        padding: { top: 0; bottom: 35px; }
      }

      &.-is-row > .input-slot {
        margin-bottom: 10px;
      }

      @include responsive (xs-mobile, mobile) {
        & > .label {
          @include typo(h5);
          font-size: var(--title-font-size);
          margin-bottom: 0;
          text-transform: initial;
          &:first-letter { text-transform: capitalize; }
        }
      }

      @include responsive(tablet, desktop) {
        & > .list-actions {
          align-self: flex-start;
          padding: { top: 5px; bottom: 0; }
        }

        &.-has-row-labels > .list-actions {
          padding: { top: 27px; bottom: 0px; }
        }

        &.-is-row {
          grid-template-columns: 210px minmax(0, 1fr) minmax(70px, 100px);
          grid-template-areas: "label slot actions";

          & > .input-slot { margin-bottom: 0; }

          &:not(.-has-row-labels) > .input-slot > .c-form-field > .label {
            display: none;
          }

          & > .label {
            position: absolute;
          }
        }

        &.-has-row-labels > .label {
          top: 22px;
        }
      }
    }
  }

  &.-is-row {
    grid-template-areas:
      "label slot slot"
      ". validation validation"
    ;
    align-items: end;

  }
}
</style>
