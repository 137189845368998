import * as types from '@types'
import * as services from '@services/photo'

export default {
  state: {
    photo: ''
  },

  getters: {},

  mutations: {
    [types.EMPLOYEE_PHOTO_SET]: (state, photo) => {
      state.photo = photo
    }
  },

  actions: {
    [types.EMPLOYEE_PHOTO]: async ({ getters, commit }) => {
      const [ err, photo ] = await services.getPhoto(getters)

      if (!err) commit(types.EMPLOYEE_PHOTO_SET, photo)

      return [ err, photo ]
    },

    [types.EMPLOYEE_PHOTO_SET]: async ({ getters, commit }, file) => {
      const [ err ] = await services.uploadPhoto(getters, file)

      if (!err) commit(types.EMPLOYEE_PHOTO_SET, file)

      return [ err, file ]
    }
  }
}
