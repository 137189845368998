import Vue from 'vue'
import Vuex from 'vuex'
import photo from './photo'
import personalInfo from './personalInfo'
import contact from './contact'
import formation from './formation'
import dependent from './dependent'
import bankAccount from './bankAccount'
import otherInfo from './otherInfo'
import document from './document'
import location from './location'
import auth from './auth'
import admission from './admission'
import contract from './contract'
import systemField from './systemField'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    photo,
    personalInfo,
    contact,
    formation,
    dependent,
    bankAccount,
    otherInfo,
    document,
    location,
    auth,
    admission,
    contract,
    systemField
  }
})
