import { MediaQuery } from '@convenia/mixins'

export default {
  mixins: [ MediaQuery ],

  computed: {
    hoverFilter () {
      /* The Apexchart lib has a bug which make the bars disappear when
      they are hovered with the enabled lighten filter if drop shadow
      is enabled on graph option. */
      return this.isSafari
        ? { type: 'none' }
        : { type: 'lighten', value: 0.1 }
    }
  }
}
