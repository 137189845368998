import isValid from './isValid'

const getDay = date => date && date.split('/')[0]

const getMonth = date => date && date.split('/')[1]

const getYear = date => date && date.split('/')[2]

const getDate = date => +new Date(getYear(date), (getMonth(date) - 1), getDay(date))

function isAfter ({ start, end }) {
  if (!start || !isValid(start)) return true

  const startDate = getDate(start)
  const endDate = (end && isValid(end)) ? getDate(end) : +new Date()

  return startDate >= endDate
}

export default isAfter
