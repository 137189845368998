<template>
  <c-button
    flat
    :class="[ 'c-collapsible-card-button', { '--opened': opened } ]"
    icon="arrow-left"
    icon-size="17"
    size="30"
    type="button"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  props: {
    opened: Boolean
  }
}
</script>

<style lang="scss">
.c-collapsible-card-button.c-button {
  padding: 0 2px 2px 0;
  transform: rotate(270deg);
  transition: .2s ease-out;

  &.--opened { transform: rotate(90deg); }

  & > .icon { margin-bottom: -1px; }
}
</style>
