<template>
  <div class='c-pdf-wrapper'>
    <div class="page-list">
      <div v-for="page in pages" :key="page" class="page-item">
        <c-pdf-viewer
          class="page-view"
          :src="file.src"
          :page="page"
          @meta:num-pages.once="updateTotalPages"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CPdfViewer from './CPdfViewer'

export default {
  name: 'c-pdf-wrapper',

  components: { CPdfViewer },

  props: {
    file: {
      type: Object,
      required: true
    },
    pageLimit: {
      type: Number,
      default: 5
    }
  },

  data: () => ({ totalPages: 1 }),

  methods: {
    updateTotalPages (numPages) {
      if (this.totalPages > 1) return
      this.totalPages = numPages
    }
  },

  computed: {
    pages () {
      const length = Math.min(this.totalPages, this.pageLimit)
      return Array.from({ length }).map((_, index) => index + 1)
    }
  }
}
</script>

<style lang="scss">
.c-pdf-wrapper {
  display: flex;

  &,
  & > .page-list,
  & > .page-list > .page-item {
    max-width: 100%;
  }

  & >.page-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > .page-item {
      display: flex;
      justify-content: center;
      align-items: center;

      max-width: 670px;
      width: 100%;
      max-height: 754px;

      &:not(:last-child) { margin-bottom: 30px; }
      &:last-child:not(:only-child) { margin-bottom: 50px; }

      & > .page-view {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
