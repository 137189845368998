<template>
  <transition v-if="show" name="popover-transition" :duration="500">
    <div class="sidebar-popover">
      <c-overlay
        v-if="show"
        class="overlay"
        dark
        show
        v-on="$listeners"
      />

      <c-popover class="popover">
        <c-card
          class="card"
          no-hover
          no-border
          :background-color="backgroundColor"
        >
          <ul class="list">
            <li
              v-for="{ label, key, icon, highlight } in items"
              :key="label"
              :class="[ 'item', highlight && `-highlight-${highlight}` ]"
              @click="emit(key)"
            >
              <c-icon class="icon" :icon="icon" :size="20" />

              <span class="text">{{ label }}</span>
            </li>
          </ul>
        </c-card>
      </c-popover>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CSidebarPopover',

  props: {
    /**
     * Whether to show or not the popover.
     */
    show: {
      type: Boolean,
      required: true
    },

    /**
     * The items that will appear on the popover list
     */
    items: {
      type: [ Array, Object ],
      default: () => ({})
    }
  },

  data () {
    return {
      backgroundColor: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0.83) 100%)'
    }
  },

  methods: {
    emit (key) {
      this.$emit('sidebar-popover-select', key)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.sidebar-popover {
  & > .overlay { opacity: 0.5; }

  & > .popover.c-popover {
    z-index: var(--z-index-3);
    width: calc(100% - 20px);
    max-width: none;

    & > .card > .list > .item {
      padding: 20px;
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;

      &:last-child { border-bottom: none; }

      & > .icon { @include icon-color(text, base-30); }

      & > .text {
        padding-left: 20px;
        font-family: var(--base-font-family);
        font-size: 14px;
        color: color-var(text, base-80);
      }

      &.-highlight-red {
        & > .text { color: color-var(negative); }
        & > .icon { @include icon-color(negative); }
      }
    }
  }
}

.popover-transition {
  &-enter-active,
  &-leave-active {
    & > .overlay { transition: opacity .5s; }
    & > .popover { transition: opacity .5s, bottom .5s; }
  }

  &-enter,
  &-leave-to {
    & > .overlay { opacity: 0; }
    & > .popover { opacity: 0; bottom: -320px; }
  }
}
</style>
