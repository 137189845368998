<template>
  <div
    v-click-outside="close"
    class="c-select-raw"
    :class="containerClasses"
    :style="{ '--searchbox-height': `${searchBoxHeight}px` }"
  >
    <select
      v-if="mobileNative"
      ref="nativeSelect"
      :key="options.length"
      :name="name"
      class="native-select"
      @change="onSelectChange"
    >
      <option v-if="options.length" value="">Selecione</option>

      <option
        v-for="(option, index) in nullableOptions"
        :key="`opt-${index}`"
        :value="getTrackByValue(option)"
      >
        {{ getItem(option) }}
      </option>
    </select>

    <div :style="{ height: borderHeight }" class="border" />

    <div
      ref="selected"
      :data-testid="`select-${fieldName}`"
      :class="selectedClasses"
      tabindex="0"
      @keydown="keyboardHandler"
      @click="opened = !opened"
    >
      <slot name="icon">
        <c-icon v-if="icon" :size="iconSize" :icon="icon" class="icon" />
      </slot>

      <slot :selected="value">
        <c-transition mode="out-in" duration="200">
          <span
            :key="selected"
            :class="[ 'text',
                      { '-placeholder': activePlaceholder, '-custom-text-color': customColor } ]"
            :style="setColorDot(customColor)"
          >
            {{ selected }}
          </span>
        </c-transition>

        <slot name="select-icon">
          <c-icon v-if="selectIcon" icon="arrow-right-2" class="select-icon" size="24" />
        </slot>
      </slot>
    </div>

    <div ref="options-wrapper" class="options-wrapper">
      <c-search-box
        v-show="opened && searchable"
        ref="searchBox"
        no-esc-listener
        class="search-box"
        alternative-focus
        v-model="searchString"
        @keydown.up.down.enter="keyboardHandler"
      />

      <transition name="options">
        <section
          v-show="opened"
          ref="optionsList"
          :class="optionsClasses"
          @scroll="checkPagination"
        >
          <slot :options="nullableOptions" name="options">
            <div
              v-for="(option, index) in computedOptions"
              :key="index"
              :class="optionClasses(index, option)"
              :data-testid="`option-${fieldName}-${getTrackByValue(option)}`"
              @click.stop="onOptionClick(option)"
              @mouseover="focusedItem = index"
            >
              <slot :option="option" name="option">
                <p :class="[ 'text', { '-line-through': setLinethrough(option) } ]">
                  {{ getItem(option) }}
                  <span
                    v-if="showDotIndicative(option)"
                    class="color-dot"
                    :style="setColorDot(option.color)"
                  />
                </p>

                <span :class="[ 'dot', { '-selected': isSelected === index } ]" />
              </slot>
            </div>
          </slot>
        </section>
      </transition>
    </div>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import { normalize, isEmpty, debounce, is } from '@convenia/helpers'
import { clickOutside } from '@convenia/components/directives'

const OPTION_BASE_CLASS = 'option'
const MIN_BORDER_HEIGHT = 40
const MAX_BORDER_HEIGHT = 240

const trackDisplayValidator = value => value !== 'disabled'

const COLORS_DOT = {
  green: '#63E1A5',
  yellow: '#FFC24B',
  red: '#FF4B8C'
}

export default {
  name: 'CSelectRaw',

  mixins: [ MediaQuery ],

  directives: { clickOutside },

  props: {
    /**
     * The options array, it can either be an array of objects, in which
     * case the trackBy/displayBy props are necessary, or an array of any
     * other primitive type.
     */
    options: {
      type: Array,
      required: true
    },

    /**
     * The input name.
     */
    name: {
      type: String,
      default: 'select'
    },

    /**
     * If `options` is an array of objects, CSelect will use this prop
     * to compare the currently select item.
     */
    trackBy: {
      type: String,
      validator: trackDisplayValidator,
      default: '',
    },

    /**
     * If `options` is an array of objects, CSelect will use this prop
     * to get the label of the option/value to be displayed for the user.
     */
    displayBy: {
      type: String,
      validator: trackDisplayValidator,
      default: '',
    },

    /**
     * The icon to show field.
     */
    icon: {
      type: String,
      default: '',
    },

    /**
     * The size of said icon
     */
    iconSize: {
      type: [ String, Number ],
      default: 20
    },

    /**
     * The icon of the dropdown indicator (the little arrow on the left).
     */
    selectIcon: {
      type: String,
      default: 'chevron-down'
    },

    /**
     * The placeholder of the field.
     */
    placeholder: {
      type: String,
      default: 'Selecione uma opção'
    },

    /**
     * The value of the field.
     */
    value: {
      type: [ Array, Object, String, Number, Boolean ],
      default: null,
    },

    /**
     * The field validation rules
     */
    fieldRules: {
      type: Object,
      default: () => ({}),
    },

    /**
     * The field name
     */
    fieldName: {
      type: String,
      default: ''
    },

    /**
     * Disables the field
     */
    disabled: Boolean,

    /**
     * Paginates the option list.
     */
    paginated: Boolean,

    /**
     * Toggle the alternative style.
     */
    alternative: Boolean,

    /**
     * Uses native input on mobile devices.
     */
    mobileNative: {
      type: Boolean,
      default: true
    },

    /**
     * Determines how many items to show at a time, only relevant
     * when the `paginated` prop is set to `true`.
     */
    paginationThreshold: {
      type: [ Number, String ],
      default: 10
    },

    /**
     * Allows the user to deselect the currently selected option
     */
    nullable: Boolean,

    error: Boolean,

    required: Boolean,

    /**
     * The select component will $emit just the trackBy value when true
     */
    emitTrackedValue: Boolean,

    /**
     * Defines whether a single option can be auto selected
     * (fieldRules.required === true also enables this behavior)
     */
    autoSelectSingle: Boolean,

    /**
     * Prevents the dropdown from closing when an option is selected
     */
    preventCloseOnSelect: Boolean,
  },

  data () {
    return {
      opened: false,
      borderHeight: '40px',
      focusedItem: -1,
      paginationPosition: +this.paginationThreshold,
      searchBoxHeight: 0,
      searchString: '',
      searchOptions: [],
      customColor: null
    }
  },

  watch: {
    searchString () { this.debouncedSetSearchOptions() },

    nullableOptions: {
      immediate: true,
      handler () { if (this.searchable) this.setSearchOptions() }
    },

    searchOptions () {
      this.$refs.optionsList.scrollTo(0, 0)
    },

    opened (value) {
      this.$nextTick(() => {
        this.calcBorderHeight(value)
        this.focusSearchBox()
      })

      this.$emit(value ? 'focus' : 'blur')
    },

    canAutoSelectSingle: {
      immediate: true,
      handler: 'selectSingleOption'
    },

    computedOptions (value) {
      !value.length && this.close()
    }
  },

  computed: {
    selected: {
      get () {
        const removeInvisibleChars = (value) => {
          return typeof value === 'string' ? value.replace(/[\u200B-\u200D\uFEFF]/g, '') : value
        }

        if (this.isMultiSelect)
          return this.placeholder || ''

        if (isEmpty(this.value))
          return this.placeholder || null

        const value = (this.nullableOptions || []).find(option => {
          return this.trackBy && option instanceof Object
            ? removeInvisibleChars((option || {})[this.trackBy])
              === removeInvisibleChars(this.computedValue)
            : removeInvisibleChars(option) === removeInvisibleChars(this.value)
        })

        if (!value) return 'Opção inválida'

        if (this.displayBy && (value || {})[this.displayBy]) {
          return (value || {})[this.displayBy]
            ? (value || {})[this.displayBy]
            : process.env.NODE_ENV === 'development' ? 'error: displayBy prop does not exist' : ''
        } return this.value
      },
      set (item) {
        const value = this.emitTrackedValue ? (item || {})[this.trackBy] ?? null : item
        this.focusedItem = -1
        this.existColor(item)
        !this.preventCloseOnSelect && this.close()
        this.$emit('input', value)
      }
    },

    searchable () {
      return this.preventCloseOnSelect || this.options.length >= 7
    },

    nullableOptions () {
      if (!this.nullable || this.required) return this.options

      const nullOption = null

      return [ nullOption, ...this.options ]
    },

    containerClasses () {
      return [ 'c-select', {
        '-opened': this.opened,
        '-mobile-native': this.mobileNative,
        '-alternative': this.alternative,
        '-selected': this.value !== '',
        '-disabled': this.isDisabled,
        '-has-icon': this.icon,
        '-validation': this.error
      } ]
    },

    selectedClasses () {
      return [ 'selected', {
        '-slot': this.$slots.default || this.$scopedSlots.default
      } ]
    },

    optionsClasses () {
      return [ 'options', {
        '-slot': this.$slots.options || this.$scopedSlots.options
      } ]
    },

    isSelected () {
      if (this.isMultiSelect) return -1

      return this.nullableOptions.findIndex(option => {
        return this.computedValue === (option || {})[this.trackBy]
      })
    },

    computedOptions () {
      const options = this.searchable
        ? this.searchOptions
        : this.nullableOptions

      return this.paginated
        ? options.slice(0, this.paginationPosition)
        : options
    },

    computedValue () {
      return this.emitTrackedValue || !this.trackBy
        ? this.value
        : (this.value || {})[this.trackBy]
    },

    activePlaceholder () {
      const isBoolean = typeof this.computedValue === 'boolean'
      const isNumber = typeof this.computedValue === 'number'

      return isBoolean || isNumber
        ? false
        : !this.computedValue
    },

    isMultiSelect () {
      return Array.isArray(this.value)
    },

    isDisabled () {
      const options = this.nullableOptions || []

      return this.disabled || !options.length
    },

    canAutoSelectSingle () {
      return this.autoSelectSingle || (this.fieldRules || {}).required
    },
  },

  methods: {
    calcBorderHeight (opened) {
      const optionsWrapper = this.$refs['options-wrapper']

      if (opened) {
        if (optionsWrapper) return this.setBorderHeightObserver(true)

        const optionsHeight = this.calcOptionsHeight()
        return this.calcTotalBorderHeight(optionsHeight)
      }

      if (optionsWrapper) this.setBorderHeightObserver(false)
      this.borderHeight = `${MIN_BORDER_HEIGHT}px`
    },

    calcTotalBorderHeight (optionsHeight) {
      requestAnimationFrame(() => {
        const searchBoxHeight = this.calcSearchBoxHeight()
        const totalHeight = optionsHeight + (searchBoxHeight || MIN_BORDER_HEIGHT)
        const limitedTotalHeight = Math.min(MAX_BORDER_HEIGHT, totalHeight)

        this.borderHeight = `${limitedTotalHeight}px`
      })
    },

    setBorderHeightObserver (observe) {
      if (!this._borderHeightObserver) return

      const optionsWrapper = this.$refs['options-wrapper']
      if (observe) return this._borderHeightObserver.observe(optionsWrapper, {
        attributes: true,
        childList: true,
        characterData: true
      })

      this._borderHeightObserver.unobserve(optionsWrapper)
    },

    calcOptionsHeight () {
      const optionHeight = this.$refs.optionsList.firstElementChild.clientHeight || 0
      return optionHeight * this.computedOptions.length
    },

    calcSearchBoxHeight () {
      const height = this.searchable
        ? this.$refs?.searchBox?.getHeight()
        : 0

      this.searchBoxHeight = height
      return height
    },

    focusSearchBox () {
      if (this.searchable) {
        const { searchBox } = this.$refs || {}

        if (searchBox.resetValue) searchBox.resetValue()
        if (searchBox.focus) searchBox.focus()
      }
    },

    getItem (option) {
      if (option === null) return 'Nenhum'

      if (this.displayBy) {
        return (option || {})[this.displayBy]
          ? (option || {})[this.displayBy]
          : process.env.NODE_ENV === 'development' ? 'error: displayBy prop does not exist' : ''
      }
      return typeof option === 'string' ? option : ''
    },

    paginate () {
      if (this.nullableOptions.length !== this.computedOptions.length)
        this.paginationPosition += +this.paginationThreshold
    },

    checkPagination () {
      const el = this.$refs.optionsList

      const { scrollTop } = el
      const { scrollHeight } = el
      const { clientHeight } = el
      const scrollHeightThreshold = scrollHeight - 60

      if ((scrollTop + clientHeight) >= scrollHeightThreshold) this.paginate()
    },

    close () {
      this.focusedItem = -1
      this.opened = false
    },

    open () {
      this.opened = true
    },

    optionClasses (index, option) {
      const { disabled } = option || {}

      return [ OPTION_BASE_CLASS, {
        '-slot': this.$slots.option || this.$scopedSlots.option,
        '-focused': !disabled && this.focusedItem === index,
        '-disabled': disabled
      } ]
    },

    existColor (selected) {
      const { color, lineThrough } = selected || {}
      if (!color) return

      this.customColor = !lineThrough ? color : null
    },

    setColorDot (color) {
      if (!color) return

      return {
        '--color': COLORS_DOT[color]
      }
    },

    setLinethrough (option) {
      const { lineThrough } = option || {}
      if (!lineThrough) return

      return lineThrough
    },

    showDotIndicative (option) {
      const { color, lineThrough } = option || {}
      if (!color) return

      return color && !lineThrough
    },

    keyboardHandler (ev) {
      if (ev.key === 'Enter') !this.onEnter() && ev.preventDefault()
      else if (ev.key === 'ArrowUp') !this.onArrowUp() && ev.preventDefault()
      else if (ev.key === 'ArrowDown') !this.onArrowDown() && ev.preventDefault()
      else if (ev.key === 'Tab') this.close()

      this.onKeyDown(ev)
      this.keyScrollHandler()
    },

    keyScrollHandler () {
      const { scrollTop, clientHeight } = this.$refs.optionsList

      const currentEl = this.$refs.optionsList.childNodes[this.focusedItem] || {}
      const elHeights = ([ ...this.$refs.optionsList.childNodes ])
        .reduce((acc, el, i) => i > this.focusedItem ? acc : acc + el.offsetHeight, 0)

      const scrollValue = elHeights - clientHeight

      if (this.focusedItem === 0)
        this.$refs.optionsList.scrollTop = 0
      if ((scrollValue + clientHeight) <= scrollTop)
        this.$refs.optionsList.scrollTop = currentEl.offsetTop || 0
      if ((scrollTop + clientHeight) <= (scrollValue + clientHeight))
        this.$refs.optionsList.scrollTop = scrollValue
    },

    onEnter () {
      if (!this.opened) this.open()
      else if (this.focusedItem > -1) { this.selected = this.computedOptions[this.focusedItem] }
    },

    onArrowUp () {
      if (this.focusedItem > -1) this.focusedItem -= 1
      if (this.focusedItem === -1) this.close()
    },

    onArrowDown () {
      const nextFocus = this.focusedItem + 1
      const nextOption = this.computedOptions[nextFocus]
      if ((nextOption || {}).disabled) return

      if (!this.opened) this.open()
      if (this.focusedItem < this.options.length - 1) this.focusedItem = nextFocus
    },

    onKeyDown (ev) {
      const { keyCode } = ev

      // Prevent any key than alphanumeric
      if (keyCode > 31 && (keyCode < 65 || keyCode > 90) && (keyCode < 97 || keyCode > 122))
        return ev.preventDefault()

      const index = this.nullableOptions.findIndex(option => {
        return (typeof option === 'string' ? option[0] : (option || {})[this.displayBy][0])
          .toLowerCase() === ev.key.toLowerCase()
      })

      this.focusedItem = index
    },

    onSelectChange (event) {
      if (!event || !event.target) return

      const item = this.trackBy
        ? this.nullableOptions.find(option => is((option || {})[this.trackBy], 'Number')
          ? (option || {})[this.trackBy] === +event.target.value
          : (option || {})[this.trackBy] === event.target.value)
        : event.target.value

      const value = this.emitTrackedValue ? (item || {})[this.trackBy] : item

      this.$emit('input', value)
    },

    onOptionClick (option) {
      const { disabled } = option || {}
      if (!disabled)
        this.selected = option
    },

    setSearchOptions () {
      const { nullableOptions, searchString, displayBy } = this
      const normalizedSearchString = normalize(searchString)

      const searchGroups = nullableOptions.reduce((curr, option) => {
        const [ startMatched, inclusionMatched, unmatched ] = curr
        const value = (option || {})[displayBy] || ''
        const normalizedValue = normalize(`${value}`)

        if (normalizedValue.startsWith(normalizedSearchString))
          return [ [ ...startMatched, option ], inclusionMatched, unmatched ]

        if (normalizedValue.includes(normalizedSearchString))
          return [ startMatched, [ ...inclusionMatched, option ], unmatched ]

        return [ startMatched, inclusionMatched, [ ...unmatched, { ...option, disabled: true } ] ]
      }, [ [], [], [] ])

      this.searchOptions = Array.prototype.concat.apply([], searchGroups)
    },

    focus () {
      try {
        if (this.isMobile) return

        this.$refs.selected.focus()
      } catch (e) { return e }
    },

    selectSingleOption (canAutoSelectSingle) {
      const isSingleOption = (this.options || []).length === 1
      const hasValue = (this.value || []).length

      if (!canAutoSelectSingle || !isSingleOption || hasValue) return

      const [ firstOption ] = this.options
      this.selected = firstOption
    },

    getTrackByValue (option) {
      return this.trackBy ? (option || {})[this.trackBy] : option
    }
  },

  created () {
    this.debouncedSetSearchOptions = debounce(this.setSearchOptions, 300)
  },

  mounted () {
    this.$nextTick(() => {
      const optionsWrapper = this.$refs['options-wrapper']
      if (optionsWrapper) this._borderHeightObserver = new ResizeObserver(() => {
        this.calcTotalBorderHeight(optionsWrapper.offsetHeight)
      })
    })
  }
}
</script>

<style lang="scss">
.c-select-raw {
  position: relative;
  overflow: visible;
  z-index: 0;
  // margin-bottom transition to support
  // CInputContainer margin-bottom transition
  transition: z-index .3s, margin-bottom .3s;

  & > .native-select.-hidden {
    position: absolute;
    visibility: hidden;
  }

  &.-disabled {
    user-select: none;
    pointer-events: none;

    & > .selected {
      font-size: 14px;
      border-radius: 5px;
      color: color-var(text, base-30);
      background-color: color-var(text, base-05);

      & > .text { color: unset; }
      & > .c-icon { @include icon-color(text, base-30); }
    }

    & > .border {
      border: unset;
      background-color: unset;
    }
  }

  & > .border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #FFF;
    border-radius: 5px;
    border: 1px solid color-var(text, base-10);
    transition: height .3s, box-shadow .3s;
    z-index: 1;
  }

  & > .selected {
    position: relative;
    border: 1px solid transparent;
    height: 40px;
    display: flex;
    padding-left: 20px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    outline: none;
    z-index: 1;

    & > .icon {
      box-sizing: content-box;
      @include icon-color(text, base-30);
    }

    & > .text {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: color-var(text, base-80);
      font-size: 14px;
      font-family: var(--base-font-family);
      text-align: initial;
      &.-placeholder { color: color-var(text, base-30); }
      &.-custom-text-color { color: var(--color); }
    }

    & > .select-icon {
      flex-shrink: 0;
      margin-left: 10px;
      margin-right: 8px;
      transform: rotate(90deg);
      transition: transform .3s, fill .3s, stroke .3s;

      @include icon-color(text, base-50);
    }
  }

  & > .options-wrapper {
    $max-height: 200px;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    border: none;
    background: transparent;
    pointer-events: none;
    z-index: 1;
    max-height: $max-height;

    & > .options { max-height: calc(#{$max-height} - var(--searchbox-height)); }
    & > .options { overflow-y: auto; position: relative; }

    & > .search-box {
      margin: 0 10px;
    }

    & > .options > .option {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 9.5px 15px;

      border-bottom: 1px solid color-var(text, base-10);
      &:last-child { border-bottom: none; }

      &.-focused { background-color: color-var(text, base-02); }

      &.-disabled {
        & > .text {
          opacity: .2;
        }
        cursor: default;
      }

      &.-disabled {
        & > .text {
          opacity: .2;
        }
        cursor: default;
      }

      & > .text {
        position: relative;
        color: color-var(text, base-80);
        font-family: var(--base-font-family);
        font-size: 14px;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;

        & > .color-dot {
          background: var(--color);
          display: inline-block;
          margin-left: 5px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }

        &.-line-through {
          text-decoration: line-through;
        }
      }
      & > .dot {
        opacity: 0;
        width: 8px;
        height: 8px;
        flex-shrink: 0;
        border-radius: 50%;
        transition: opacity 1s;
        background: color-var();

        &.-selected { opacity: 1; }
      }
    }

    // transitions
    .options-enter-active, .options-leave-active {
      transition:
        transform .3s cubic-bezier(0, .6, .4, 1),
        opacity .2s .1s;
    }
    .options-enter, .options-leave-to {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  &.-has-icon > .selected { padding-left: 12px; }
  &.-has-icon > .selected > .text { padding-left: 10px; }

  &.-opened {
    z-index: var(--z-index-1);
    & > .border {
      box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.2);
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0.9) 100%);
    }
    & > .options-wrapper { pointer-events: all; }

    & > .selected {
      border-radius: 5px 5px 0 0;
      border-bottom: 1px solid transparent;
      cursor: default;

      & > .select-icon {
        @include responsive (tablet, desktop) {
          @include icon-color(text, base-50);
          transform: rotate(270deg);
        }
      }
    }
  }

  & > .native-select {
    display: none;
  }

  &.-alternative {
    &.-opened > .border,
    & > .border {
      background: rgba(255,255,255,0.1);
      border-radius: 20px;
    }

    & > .selected > .icon {
      @include icon-color(rgba(255, 255, 255, 0.8));
    }

    & > .selected > .text {
      color: #FFF;
      opacity: 0.8;
      font-family: var(--title-font-family);
      font-size: 11px;
      font-weight: var(--title-font-weight);
      text-transform: uppercase;
    }

    & > .selected > .select-icon {
      @include icon-color(rgba(255, 255, 255, 0.5));
    }
  }

  @include responsive (xs-mobile, mobile) {
    &.-mobile-native {
      & > .options-wrapper {
        display: none;
      }

      & > .native-select {
        @include typo;
        width: 100%;
        height: 40px;
        z-index: var(--z-index-1);
        position: absolute;
        opacity: 0;
        display: block;
      }

      &.-opened {
        & > .border {
          box-shadow: none;
        }
      }
    }
  }

  @include responsive(xs-mobile, mobile) {
    & > .selected {
      & > .text {
        font-size: 14px;
      }
    }
  }

  &:focus-within > .border {
    @include responsive (tablet, desktop) {
      border-color: rgba(color-var(primary, base-rgb), .35);
      @include hover();
    }
  }

  &.-validation:not(.-opened) > .border {
    border-color: rgba(color-var(negative, base-rgb), .35);
    @include hover(color-var(negative, base-rgb));
  }
}
</style>
