<template>
  <c-input-container
    class="c-radio-button"
    v-bind="$attrs"
  >
    <c-radio-raw
      v-bind="$attrs"
      v-on="$listeners"
    />
  </c-input-container>
</template>

<script>
import CRadioRaw from './fragments/CRadioRaw'

export default {
  name: 'CRadioButton',

  components: { CRadioRaw },
}
</script>
