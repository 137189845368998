<template>
  <c-modal
    is-opened
    fullscreen
    no-bottom-shadow
    position='right'
    title="Filtrar"
    class="c-filter-modal"
    @close="$emit('close')"
  >
    <div class="clear-button-wrapper">
      <c-button
        ref="clear-filters"
        flat
        class="clear-filters"
        :disabled="isLoading || hasFilters"
        @click="onClear"
      >
        Limpar Filtros
      </c-button>
    </div>

    <c-form-builder
      v-if="!isLoading"
      name="filter-modal-form"
      no-actions
      show-overflow
      no-bottom-shadow
      no-upper-shadow
      :schema="filterSchema"
      :fields-options="optionsSchema"
      :value="value"
      @input="onChange"
    />

    <c-loader v-else />
  </c-modal>
</template>

<script>
export default {
  name: 'CFilterModal',

  props: {
    filterSchema: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Object,
      default: () => ({})
    },
    optionsSchema: {
      type: Object,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    hasFilters () {
      return Object.keys(this.value).length === 0
    }
  },

  methods: {
    onClear () {
      this.$emit('input', {})
      this.$emit('filter', {})
      this.$emit('clear')
    },

    onChange (formData) {
      this.$emit('input', formData)
      this.$emit('filter', formData)
    }
  },
}
</script>

<style lang="scss">
.c-filter-modal {
  &.c-modal > .wrapper > .modal {
    height: 100vh;
    width: 100%;
    border-radius: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: unset;

    & > .content {
      display: flex;
      flex-direction: column;
      overflow-x: auto;
      height: 100%;

      & > .clear-button-wrapper {
        width: 100%;

        & > .clear-filters {
          width: 100%;
          margin-bottom: 20px;
        }
      }

      & > .c-loader {
        padding-top:50px;
        align-self: center;
      }

      & > .c-form-builder
      > .c-shadowed > .wrapper > .form-fields {
        padding: 0 ;
        height: calc(100% - 155px);
        display: flex;
        flex-direction: column;

        & > .c-form-field {
          grid-template-columns: calc(100%);

          &.multi-select { margin-bottom: 20px; }
        }
      }
    }
  }

  @include responsive (xs-mobile, mobile) {
    &.c-modal > .wrapper.-fullscreen.-right > .modal {
      width: 100%;
      position: absolute;

      & > .header {
        padding: 10px;

        & > .title {
          margin-left: 10px;
          margin-top: 5px;
        }
      }

      & > .content > .clear-button-wrapper > .clear-filters {
        margin-top: 0;
      }

      & > .content > .item > .label { margin-left: 10px; }
    }
  }

  @include responsive (tablet, desktop) {
    &.c-modal > .wrapper.-fullscreen.-right {
      left: initial;
      width: fit-content;

      & > .modal {
        width: 400px;
        max-width: unset;
      }
    }
  }
}
</style>
