<template>
  <div class="c-calendar-grid">
    <div class="top">
      <div class="controls">
        <c-button
          flat
          icon="arrow-left"
          icon-size="24"
          class="btn"
          size="40"
          :loading="loading === 'back'"
          :disabled="blockBackBtn"
          @click="emitChangeMonth('back')"
        />

        <div class="title-container">
          <transition name="fade">
            <p :key="calendarTitle" class="title">
              {{ calendarTitle }}
            </p>
          </transition>
        </div>

        <c-button
          flat
          icon="arrow-right"
          icon-size="24"
          class="btn"
          size="40"
          :loading="loading === 'forward'"
          :disabled="blockForwardBtn"
          @click="emitChangeMonth('forward')"
        />

        <c-button
          flat
          class="btn-today"
          @click="emitChangeMonth('today')"
        >
          Hoje
        </c-button>
      </div>

      <div v-if="$slots.header" class="right">
        <slot name="header" />
      </div>
    </div>

    <div class="calendar-container" :class="{ '-is-loading': loading === 'default' }">
      <c-loader v-if="loading === 'default'" class="loader" />

      <c-transition :carousel="transitionDirection" duration="300">
        <div :key="currentMonth" class="calendar">
          <div class="heading">
            <p
              v-for="weekDay in $options.weekDays"
              :key="weekDay"
              class="week-day"
            >
              {{ weekDay }}
            </p>
          </div>

          <c-grid-matrix
            class="days"
            :rows="hasExtraRow ? 6 : 5"
            columns="7"
            cell-width="auto"
            cell-height="117"
          >
            <div
              v-for="(_, idx) in cells"
              ref="cells"
              :key="idx"
              :class="cellClasses(idx)"
              :style="cellStyles"
              :slot="`cell-${idx}`"
              @click="onCellClick(idx)"
            >
              <template v-if="!skipCell(idx)">
                <span :key="`date-${idx}`" class="day">
                  {{ getMonthDate(idx) }}
                </span>

                <cell-events
                  :key="`events-${idx}`"
                  :events="getEvents(idx)"
                  :cell-width="cellWidth"
                />
              </template>
            </div>
          </c-grid-matrix>
        </div>
      </c-transition>
    </div>
  </div>
</template>

<script>
import { capitalize } from '@convenia/helpers'
import CGridMatrix from './GridMatrix.vue'

import CButton from '../CButton'

const hasProps = (obj = {}, props = []) => props
  .every(prop => Object.hasOwn(obj, prop))

// -> Constants
// ------------
const CELL_INNER_PADDING = 15

export default {
  name: 'CCalendarGrid',

  components: {
    CellEvents: () => import('./CellEvents'),
    CGridMatrix,
    CButton,
  },

  weekDays: [
    'segunda-feira',
    'terça-feira',
    'quarta-feira',
    'quinta-feira',
    'sexta-feira',
    'sábado',
    'domingo'
  ],

  props: {
    /**
     * An object containing start and end date. Start and
     * end dates must be a string in valid ISO 8601 format,
     * an unix timestamp, or a native Date instance
     */
    range: {
      type: Object,
      default: () => ({ start: '', end: '' }),
      validator: val => hasProps(val, [ 'start', 'end' ])
    },

    /**
     * Must be a valid date in between the given ranges,
     * follows the same format as the dates in the range
     * object as well (ISO 8601 date string, unix timestamp
     * or native Date instance)
     */
    currentMonth: {
      type: String,
      required: true
    },

    /**
     *
     */
    loading: {
      type: String,
      validator: val => [ '', 'back', 'forward', 'default' ]
        .includes(val),
      default: '',
    },

    /**
     * This is a list of calendar event objects that must
     * contain, at minimum, the following properties:

     * {String} id - The uuid of the event
     * {String} name - The event's name
     * {String} type - The event's type, determines how
     * the tag for the event is rendered, the type must
     * be mapped and present in the list of event types
     * as defined by the backend API
     * {String} date - ISO 8601 Date string indicating
     * when the event takes place
     */
    events: {
      type: Array,
      default: () => ([]),
      validator: val => val.every(item => hasProps(item,
        [ 'id', 'name', 'type', 'date' ]))
    },
  },

  data: () => ({
    cellWidth: 0,
    observer: null,
    changeType: ''
  }),

  mounted () {
    this.$nextTick(this.bindObserver)
  },

  beforeDestroy () {
    this.observer?.disconnect()
  },

  methods: {
    /**
     * Receives the cellNumber of a calendar cell and
     * returns all events in the events prop matching that
     * date

     * @param {Number} cellNumber - The the number of the
     * calendar cell in question.
     */
    getEvents (cellNumber) {
      const monthdate = this.getMonthDate(cellNumber)
      const cellDate = this.$date(this.currentMonth).date(monthdate)
      const formattedCellDate = cellDate.format('YYYY-MM-DD')

      return this.groupedEvents?.[formattedCellDate] || []
    },

    /**
     * Returns a boolean representing whether or not to skip
     * painting content for a given calendar cell

     * @param {Number} cellNumber - The the number of the
     * calendar cell in question.
     * @returns {Boolean} - True if the cell should be left
     * empty
     */
    skipCell (cellNumber) {
      return cellNumber < this.startsAt
        || this.getMonthDate(cellNumber) > this.maxDays
    },

    /**
     * Returns the actual date for a given calendar cell
     * taking the day of the week in which the month starts
     * at into consideration

     * @param {Number} cellNumber - The the number of the
     * calendar cell in question.
     * @returns {Number} - A number representing the
     * correct date for the calendar cell
     */
    getMonthDate (cellNumber) {
      return (cellNumber - this.startsAt) + 1
    },

    /**
     * Returns true the if the date for the given calendar
     * cell number falls in a weekend.

     * @param {Number} cellNumber - The the number of the
     * calendar cell in question.
     * @return {Boolean} * True the if the date for the
     * given calendar cell number happens in a weekend.
     */
    isWeekend (cellNumber) {
      const weekendDays = [ 0, 6 ]
      const cellDate = this.getMonthDate(cellNumber)
      const cellDayOfWeek = this.$date(this.currentMonth)
        .date(cellDate)
        .day()

      return weekendDays.includes(cellDayOfWeek)
    },

    /**
     * Returns true the if the date for the given calendar
     * cell number is the same as the current date.

     * @param {Number} cellNumber - The the number of the
     * calendar cell in question.
     * @return {Boolean} -  True the if the date for
     * the given calendar cell number is the same as
     * the current date.
     */
    isToday (cellNumber) {
      const cellDate = this.getMonthDate(cellNumber)
      return this.$date(this.currentMonth)
        .date(cellDate)
        .isSame(this.$date(), 'day')
    },

    /**
     * Returns an array of classes to be applied to the
     * calendar cell in question.

     * @param {Number} cellNumber - The the number of the
     * calendar cell in question.
     * @return {Array<string|object>}
     */
    cellClasses (cellNumber) {
      return [
        'inner', {
          '-weekend': this.isWeekend(cellNumber),
          '-today': this.isToday(cellNumber),
          '-clickable': (this.getEvents(cellNumber) || []).length
        }
      ]
    },

    /**
     * Emits a change-date event with the date of the
     * previous or next month, depending on the type received.

     * @param {String} type - The type of change to be emitted
     * can be either 'back', 'forward' or 'today'
     * @emits change-date - The date of the previous or next,
     * in ISO 8601 format
     * @return {void}
     */
    emitChangeMonth (type) {
      this.changeType = type

      if (type === 'today')
        this.$emit('change-date', this.$date().format('YYYY-MM'))

      if (type === 'back' && !this.blockBackBtn)
        this.$emit('change-date', this.$date(this.currentMonth)
          .subtract(1, 'month')
          .format('YYYY-MM'))

      if (type === 'forward' && !this.blockForwardBtn)
        this.$emit('change-date', this.$date(this.currentMonth)
          .add(1, 'month')
          .format('YYYY-MM'))
    },

    onCellClick (idx) {
      const events = this.getEvents(idx)
      const cellMonthDate = this.getMonthDate(idx)
      const cellDate = this.$date(this.currentMonth)
        .date(cellMonthDate)

      this.$emit('date-click', {
        events,
        date: cellDate.format('D [de] MMMM [de] YYYY'),
        dateISO: cellDate.format('YYYY-MM-DD')
      })
    },

    bindObserver () {
      const callback = () => {
        const firstCell = this.$refs.cells?.[0]
        if (!firstCell) return

        const { width = 0 } = firstCell?.getBoundingClientRect() || {}
        this.cellWidth = width - (2 * CELL_INNER_PADDING)
      }

      this.observer = new ResizeObserver(callback)
      this.observer.observe(this.$el)
    }
  },

  computed: {
    cells () {
      return Array.from({ length: 37 })
    },

    groupedEvents () {
      return this.events.reduce((acc, event) => {
        const eventDate = this.$date(event.date).format('YYYY-MM-DD')

        if (!acc[eventDate]) acc[eventDate] = []

        acc[eventDate].push(event)

        return acc
      }, {})
    },

    blockBackBtn () {
      if (!this.range.start) return false

      const startLimit = this.$date(this.range.start)
      return this.$date(this.currentMonth)
        .isSame(startLimit, 'month')
    },

    blockForwardBtn () {
      if (!this.range.end) return false

      const endLimit = this.$date(this.range.end)

      return this.$date(this.currentMonth)
        .isSame(endLimit, 'month')
    },

    hasExtraRow () {
      return ((this.maxDays % 7) + this.startsAt) > 7
    },

    calendarTitle () {
      const monthName = this.$date(this.currentMonth)
        .format('MMMM')
      const year = this.$date(this.currentMonth).year()

      return `${capitalize(monthName)} de ${year}`
    },

    startsAt () {
      const dayOfWeek = this.$date(this.currentMonth)
        .date(1)
        .day()

      return (dayOfWeek + 6) % 7
    },

    maxDays () {
      const currentDate = this.$date(this.currentMonth)

      // dayjs().month() will return a number starting from 0
      // representing the month of the instantiated date

      // dayjs().month(number) returns a new day.js instance
      // with the month set to <number>

      // dayjs().date(0) will return the last day of the
      // last month

      // So here we create a new Dayjs instance set to the
      // currentDate variable plus one month ahead, and then
      // we get the last day of the month before that (which
      // would be the last day of the current's date month)

      return currentDate.month(currentDate.month() + 1)
        .date(0)
        .date()
    },

    cellStyles () {
      return {
        '--cell-inner-padding': `${CELL_INNER_PADDING}px`
      }
    },

    transitionDirection () {
      const changeMap = { back: 'left', forward: 'right' }

      return changeMap?.[this.changeType]
    }
  }
}
</script>

<style lang="scss">
.c-calendar-grid {
  display: flex;
  flex-direction: column;

  width: 100%;
  position: relative;

  .fade-enter-active, .fade-leave-active {
    transition: opacity 250ms;
  }

  .fade-leave-active {
    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;
    height: fit-content;
  }

  .fade-enter, .fade-leave-to { opacity: 0; }

  & > .top {
    margin-bottom: 40px;
    justify-content: space-between;

    &, & > .controls, & > .right {
      display: flex;
      align-items: center;
    }

    & > .controls {
      & > .title-container {
        position: relative;
        display: flex;
        width: 200px;
      }

      & > .title-container > .title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;

        // Prototype doesn't follow the style-guide's
        // type standards
        @include typo(h2);
        font-size: 18px;
      }

      & > .btn-today {
        margin-left: 20px;
        max-width: 80px;
      }
    }

    & > .right {
      justify-content: flex-end;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }

  & > .calendar-container {
    position: relative;

    & > .loader {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.-is-loading > .calendar { opacity: 0.5; }
  }

  & > .calendar-container > .calendar {
    display: flex;
    flex-direction: column;
  }

  & > .calendar-container > .calendar > .heading {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: auto;
    justify-items: center;
    margin-bottom: 15px;

    & > .week-day { @include typo(h5, base-50); }
  }

  & > .calendar-container > .calendar > .days > .cell > .inner {
    padding: var(--cell-inner-padding);

    &:not(.-clickable) {
      user-select: none;
      pointer-events: none;
    }

    &.-clickable { cursor: pointer; }

    & > .day {
      @include typo(body-1, base-80);
      user-select: none;
    }

    &.-weekend > .day { color: color-var(text, base-50); }
    &.-today > .day { font-weight: bold; }
  }
}
</style>
