<template>
  <div class="steppers">
    <component
      v-for="(step, index) in steps"
      class="step"
      :class="{
        '-checked': isPrevious(step.id),
        '-current': isCurrent(step.id)
      }"
      :is="stepComponent"
      :key="step.id || index"
      :green="isPrevious(step.id)"
      @click="onChange(step.id)"
    >
      <transition name="stamp">
        <c-flag
          v-if="isPrevious(step.id)"
          success
          class="check"
          icon="done-check-3"
        />
      </transition>

      <component
        class="icon"
        :is="iconComponent"
        :icon="step.icon"
        :icon-size="22"
        :primary="isCurrent(step.id)"
        :flat="!isCurrent(step.id)"
        :grey="!isMobile && step.id > value"
      />

      <span class="label">
        {{ step.label }}
      </span>

      <c-button
        v-if="isMobile"
        flat
        class="arrow"
        icon="arrow-right"
        :icon-size="14"
        :size="30"
      />
    </component>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'MenuSteppers',

  mixins: [ MediaQuery ],

  props: {
    value: {
      type: Number,
      default: 0
    },

    steps: {
      type: Array,
      default: () => ([
        {
          icon: 'user',
          label: 'Foto de perfil',
        }
      ])
    }
  },

  computed: {
    stepComponent () {
      if (this.isMobile) return 'c-card'
      return 'button'
    },

    iconComponent () {
      if (this.isMobile) return 'c-button'
      return 'c-flag'
    }
  },

  methods: {
    onChange (id) {
      this.$emit('input', id)
    },

    isPrevious (id) {
      const currIndex = this.steps.findIndex(step => step.id === this.value)
      const index = this.steps.findIndex(step => step.id === id)

      return index < currIndex
    },

    isCurrent (id) {
      const currIndex = this.steps.findIndex(step => step.id === this.value)
      const index = this.steps.findIndex(step => step.id === id)

      return index === currIndex
    }
  }
}
</script>

<style lang="scss">
.steppers {
  display: flex;
  flex-flow: column;

  & > .step {
    position: relative;
    background: transparent;
    border: 0;
    outline: none;
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 10px;

    & > .check {
      position: absolute;
      z-index: $z-index-2;
    }

    & > .icon {
      margin-right: 15px;
      pointer-events: none;
      transition: .0s;
      box-shadow: none;
    }

    & > .label {
      flex: 1;
      font-family: "Ubuntu", sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 14px;
      text-transform: uppercase;
      color: #121E48;
      opacity: .3;
      -webkit-font-smoothing: antialiased;
    }

    & > .arrow {
      flex-shrink: 0;
      pointer-events: none;
    }

    &.-checked {
      & > .label { opacity: .5; }
    }

    &.-current {
      & > .label { opacity: .8 }
    }

    @include responsive (xs-mobile, mobile) {
      padding: 15px;

      & > .icon.-flat > .c-icon.icon { fill: rgba(#121E48, 0.3); }
    }

    @include responsive (tablet, desktop) {
      margin-bottom: 30px;

      &:not(:last-child):after {
        content: "";
        position: absolute;
        width: 1px;
        height: 30px;
        top: 100%;
        left: 24px;
        background: rgba(18,30,72, .1);;
      }

      & > .check {
        margin-left: 5px;
      }

      & > .icon {
        margin: 5px 25px 5px 5px;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          top: -5px;
          right: -5px;
          bottom: -5px;
          left: -5px;
          border-radius: 50px;
          border: 1px solid rgba(18,30,72, .1);
        }
      }
    }
  }
}
</style>
