<template>
  <div :class="classes" @click="$emit('input', !value)">
    <div class="value-indicator" />
  </div>
</template>

<script>
export default {
  name: 'CToggle',

  props: {
    value: {
      type: Boolean,
      default: false
    },
    threeState: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return [ 'c-toggle', {
        '-disabled': this.disabled,
        '-active': this.threeState
          ? this.value === true
          : !!this.value,
        '-neutral': this.value !== false && this.value !== true,
        '-negated': this.value === false,
        '-three-state': this.threeState
      } ]
    }
  }
}
</script>

<style lang="scss">

.c-toggle {
  position: relative;
  display: flex;
  background: color-var(text, base-05);

  height: 30px;
  width: 60px;
  border-radius: 20px;
  cursor: pointer;

  &.-active {
    box-shadow: 1px 1px 26px
      rgba(color-var(positive, base-rgb), .2);
  }

  &.-three-state {
    &:not(.-disabled) {
      &.-negated {
        box-shadow: 1px 1px 26px
          rgba(color-var(negative, base-rgb), .2);
      }

      &.-negated::before {
        opacity: 1;
        background: color-var(negative);
      }
    }

    &.-neutral > .value-indicator {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  // We can't transition a gradient background, so instead
  // I'm using a before element in here with a transition on
  // the opacity.
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: color-var(positive);
    border-radius: 20px;
    opacity: 0;
    transition: opacity 600ms ease;
  }

  & > .value-indicator {
    background: #FFF;
    position: absolute;
    left: 3px;
    top: 3px;
    border-radius: 50%;

    width: 24px;
    height: 24px;
    transition: left 300ms ease;
    box-shadow: 2px 8px 16px color-var(text, base-05);
  }

  &.-active {
    &::before { opacity: 1; }

    & > .value-indicator {
      // 27px = element width (24px) + 3px of spacing
      left: calc(100% - 27px);
    }
  }

  &.-disabled {
    pointer-events: none;
    user-select: none;
    box-shadow: none;

    &::before { opacity: 0; }
  }

}
</style>
