<template>
  <div class="c-avatar" :class="classes" :style="{ '--size': `${size}px` }">
    <div class="background" :class=" isDarker ? '-darker' : '-lighter'">
      <c-image
        v-tooltip.dark="tooltipParams"
        class="image"
        :src="src"
        :style="styles"
        :placeholder="placeholder"
        is-background
        spinner-loader
        v-bind="$attrs"
        @error="imageError = true"
        @loaded="onLoaded"
      >
        <div v-if="showInitials">
          <span :class="initialsClasses" :style="{ 'font-size': initialsSize + 'px' }">
            {{ initials }}
          </span>
        </div>
      </c-image>
    </div>
  </div>
</template>

<script>
import { tooltip } from '@convenia/components/directives'

/**
 * A component for the user's avatar
 */
export default {
  name: 'CAvatar',

  directives: { tooltip },

  props: {
    /**
     * The URL of the user's picture, if any.
     */
    src: {
      type: String,
      default: '',
    },

    /**
     * The size of the avatar.
     */
    size: {
      type: [ String, Number ],
      default: 40,
      validator: size => typeof +size === 'number' && +size > 0
    },

    /**
     * The size of the initials font.
     */
    initialsSize: {
      type: [ String, Number ],
      default: 18,
      validator: size => typeof +size === 'number' && +size > 0
    },

    /**
     * Makes the avatar's background darker (map-get($text-color, base-20))
     */
    isDarker: Boolean,

    /**
     * The placeholder image to be used when either `src` is not provided
     * or the link is broken.
     */
    placeholder: {
      type: String,
      default: '/img/profile_placeholder.svg'
    },

    /**
     * The user name. When provided, CAvatar will display the user's
     * initials if the src is inaccessible/inexistent.
     */
    namePlaceholder: {
      type: String,
      default: null,
    },

    bypassedNamePlaceholder: {
      type: String,
      default: null
    },

    /**
     * Determines whether the tooltip will appear at the bottom or the top
     * of the avatar.
     */
    tooltipPos: {
      type: String,
      default: 'top'
    },

    /**
     * The vertical alignment of the tooltip.
     */
    tooltipAlign: {
      type: String,
      default: 'center'
    },

    /*
     * The distance between the Avatar el and the tooltip arrow
     */
    tooltipElementSpacing: {
      type: [ Number, String ],
      default: null,
    },

    /**
     * Hides the tooltip when set to true
     */
    hideTooltip: Boolean
  },

  data: () => ({
    imageError: false,
    imageLoaded: false
  }),

  computed: {
    styles () {
      const size = this.size + 'px'

      return {
        width: size,
        height: size,
        minWidth: size,
        minHeight: size
      }
    },

    classes () {
      return {
        '-small': this.size < 40,
        '-initials': this.showInitials
      }
    },

    initials () {
      if (this.bypassedNamePlaceholder) return this.bypassedNamePlaceholder
      if (!this.namePlaceholder) return
      const names = this.namePlaceholder.split(' ')
      const first = names[0].charAt(0)
      const last = names[names.length - 1].charAt(0)
      return names.length > 1 ? first + last : first
    },

    initialsClasses () {
      return [ 'initials', {
        '-top-tooltip': this.tooltipPos.includes('top'),
        '-bottom-tooltip': this.tooltipPos.includes('bottom')
      } ]
    },

    activePlaceholder () {
      return !(this.namePlaceholder || this.bypassedNamePlaceholder) && this.placeholder
    },

    tooltipParams () {
      return {
        elementSpacing: this.tooltipElementSpacing,
        value: this.namePlaceholder,
        position: this.tooltipPos,
        maxWidth: 500,
        align: this.tooltipAlign,
        hide: this.hideTooltip
      }
    },

    showInitials () {
      return !!(this.namePlaceholder || this.bypassedNamePlaceholder)
        && (!this.src || this.imageError)
    }
  },

  methods: {
    onLoaded () {
      Object.assign(this.$data, {
        imageError: false,
        imageLoaded: true
      })
    }
  }
}
</script>

<style lang="scss">
.c-avatar {
  position: relative;
  line-height: 0;
  border-radius: 50%;
  z-index: 0;
  width: var(--size);
  height: var(--size);

  & > .-darker {
    background-color: rgba-to-rgb(map-get($text-color, base-20));
  }

  & > .-lighter {
    background-color: rgba-to-rgb(map-get($text-color, base-10));
  }

  & > .background {
    border-radius: inherit;
    overflow: hidden;

    & > .image {
      display: flex;
      justify-content: center;
      align-items: center;

      @include responsive (xs-mobile, mobile) { border-radius: 50%; }

      & > .initials {
        font-family: var(--title-font-family);
        font-weight: var(--title-font-weight);
        color: #fff;
        font-size: 18px;
        user-select: none;
        text-transform: uppercase;
        z-index: var(--z-index-1);

        &.-top-tooltip > .c-tooltip > .popover { transform: translateY(-20px); }
        &.-bottom-tooltip > .c-tooltip > .popover { transform: translateY(20px); }

        & > .c-tooltip > .popover > .card { text-transform: capitalize; }
      }

      &.c-image-loader > img {
        z-index: var(--z-index-1);
        padding: 10px;
        filter: brightness(150%);
      }
    }
  }

  &.-small > .image > .initials { font-size: 12px; }

  // avatar without image inner shade
  &.-initials {
    @include add-shadow(before) {
      opacity: 0.1;
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
    }
  }

  &:not(.-initials)::before { opacity: 0; }

  // back shadow for avatars with image
  &:not(.-initials) {
    @include add-shadow(after) {
      opacity: 0.3;
      z-index: -$z-index-1;
    }
  }

  &::after { opacity: 0; }
}
</style>
