import { isEmptyObject } from './object'

export const mapRequiredFields = (schema, requiredFields) => {
  const formatValidation = (key, validation) => {
    if (!validation) {
      const required = requiredFields.includes(key)
      return { ...(required ? { required } : {}) }
    }

    if (typeof validation === 'function') return validation

    if (typeof validation === 'string') {
      const rules = validation.split('|')
      const required = requiredFields.includes(key) || validation.includes('required')
      const reducer = (acc, rule) => {
        const [ key, value ] = rule.split(':')

        return { ...acc, [key]: value || true }
      }

      return {
        ...rules.reduce(reducer, {}),
        ...(required ? { required } : {})
      }
    }

    if (typeof validation === 'object') {
      const required = requiredFields.includes(key) || validation.required

      return {
        ...validation,
        ...(required ? { required } : {})
      }
    }
  }

  const reducer = (acc, [ key, value ]) => ({
    ...acc,
    [key]: {
      ...value,
      required: requiredFields.includes(key) || value.required || false,
      validation: formatValidation(key, value.validation)
    }
  })

  return Object.entries(schema).reduce(reducer, {})
}

export const bindErrors = (formFields, errors) => {
  const formFieldsKeys = Object.keys(formFields)
  const unreferencedFormFields = formFieldsKeys
    .reduce((acc, field) => ({
      ...acc, [field]: { ...formFields[field] }
    }), {})

  if (isEmptyObject(errors)) return unreferencedFormFields

  Object.keys(errors).forEach(field => {
    const validationMsg = (errors[field] || [])[0]

    unreferencedFormFields[field].forceError = true
    unreferencedFormFields[field].validationMsg = validationMsg
  })

  return unreferencedFormFields
}

export const convertBase64ToFile = image => {
  const byteString = atob(image.split(',')[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i += 1) ia[i] = byteString.charCodeAt(i)

  return new Blob([ ab ], { type: 'image/jpeg' })
}

/**
 * Transforms a nested objects/arrays structure into FormData
 */

export const toFormData = (obj, form, namespace) => {
  const fd = form || new FormData()
  let formKey

  Object.keys(obj).forEach(property => {
    if (obj.hasOwnProperty(property) && obj[property]) {
      if (namespace) formKey = namespace + '[' + property + ']'
      else formKey = property

      //  if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date)
        fd.append(formKey, obj[property].toISOString())
      else if (typeof obj[property] === 'object' && !(obj[property] instanceof File))
        toFormData(obj[property], fd, formKey)
      else // if it's a string or a File object
        fd.append(formKey, obj[property])
    }
  })

  return fd
}
