import * as filters from '@convenia/filters'
import { ConveniaFeedbacks } from '@convenia/utils'
import * as components from './index'
import * as directives from './directives'

export const install = (Vue, store, options = {}) => {
  Object.entries(components).forEach(([ name, component ]) => {
    Vue.component(name, component)
  })

  Object.entries(directives).forEach(([ name, directive ]) => {
    Vue.directive(name, directive)
  })

  Object.entries(filters).forEach(([ name, filter ]) => {
    Vue.filter(name, filter)
  })

  Vue.prototype.$css = {
    get (varName, target) {
      return getComputedStyle(target || document.documentElement).getPropertyValue(varName)
    },
    set (varName, val, target) {
      const el = target || document.documentElement
      el.style.setProperty(varName, val)
    }
  }

  if (store) Vue.use(ConveniaFeedbacks, store, (options || {}).feedbacks || {})
}

export * from './index.js'
