import { get } from './get'

export const normalizeString = (value) => {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function normalizeDiacritics (str) {
  if (!str) return ''

  return str
    .normalize('NFKD')
    .replace(/[\u0080-\uF8FF]/g, '')
    .toLowerCase()
}

export const getEntityQuery = (entity, props) => {
  const entityObj = entity || {}
  return props.map((prop) => {
    const value = (get(entityObj, prop) || '').toLowerCase()
    return value
  }).join(' ')
}

export const matches = (word, words) => {
  if (word) {
    const noAccentsWords = normalizeString(words)
    const regularSplit = words.split(word).length - 1
    const noAccentsSplit = noAccentsWords.split(word).length - 1
    return regularSplit || noAccentsSplit
  }

  return 0
}

// Very simple string formating function, for now
// works mainly to format numbers or string of numbers
// to a given format, we're using the pound (#) sign here
// to define the placeholders in the format string.
export const format = (string, format) => (string || '').split('')
  .reduce((acc, char) => acc.replace(/#/, char), format)
  .replace(/\D?#/g, '')

export const capitalize = (string) => {
  return string.replace(/(?:^|\s)\S/g, a => a.toUpperCase())
}

// Removes HTML tags from string
export const sanitize = string => (string || '')
  .replace(/<\/?[^>]+(>|$)/g, '')
  .trim()

export const getMatches = (word = '', words = '') => {
  const normalizedWord = normalizeString(word)
  const normalizedWords = normalizeString(words)

  return normalizedWords
    .toLowerCase()
    .split(normalizedWord.toLowerCase())
}

export const getRemaining = (word, words) => {
  const matches = getMatches(word, words)
  const atBeginning = !(matches[0] || []).length
  const remaining = (matches.filter(Boolean)[0] || []).length

  return {
    atBeginning,
    remaining: atBeginning ? remaining * -1 : remaining
  }
}

export const getDiacritic = (obj, key, word, words) => {
  const { atBeginning, remaining } = getRemaining(word, words)
  const untilEnd = remaining + word.length

  if (word === words) return obj[key]

  return atBeginning
    ? obj[key].slice(0, remaining)
    : obj[key].slice(remaining, untilEnd)
}

export const setDiacritic = (xs, normalizeProp, searchProp) => xs.map(item => ({
  ...item,
  [normalizeProp]: normalizeString(item[searchProp])
}))

/**
 * Receives an array of strings and outputs it in a single string
 * with the following pattern:
 *
 * ['A', 'B', 'C', 'D' ] => A, B, C e D
 * @param {Array<string>} arr
 * @returns {string}
 */
export const stringifyList = (arr = []) => {
  if (arr.length === 1) return arr[0]

  const firsts = arr.slice(0, arr.length - 1)
  const last = arr[arr.length - 1]

  return firsts.join(', ') + ' e ' + last
}

// transpiles a snake_case property name to camelCase
// (including default mongodb '_id' property to 'id')
export const snakeToCamel = s =>
  s.replace(/([-_][a-z])/ig, v => v.toUpperCase().replace('-', '').replace('_', ''))

// transpiles snakeCase  object properties to  camel_case (it goes deep into objects and arrays)
export const snakeToCamelKeys = o => Object.entries(o).reduce((a, [ p, v ]) => ({ ...a,
  [snakeToCamel(p)]: (v && typeof v === 'object')
    ? Array.isArray(v)
      ? v.map((v) => (v && typeof v === 'object') ? snakeToCamelKeys(v) : v)
      : snakeToCamelKeys(v) : v }), {})

export const camelToSnake = (s) => {
  return s.replace(/[A-Z]/g, (match) => '_' + match.toLowerCase())
}

export const camelToSnakeKeys = o => Object.entries(o).reduce((a, [ p, v ]) => ({
  ...a,
  [camelToSnake(p)]: (v && typeof v === 'object')
    ? Array.isArray(v)
      ? v.map(item => typeof item === 'object' ? camelToSnakeKeys(item) : item)
      : camelToSnakeKeys(v)
    : v
}), {})
