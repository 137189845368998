<template>
  <div class="c-stepper">
    <div
      v-if="top"
      class="bar"
      :style="{ height: `${height}px`, top: `${top}px` }"
    >
      <div
        v-for="i in elements"
        :key="i"
        :class="[ 'marker', { '-selected': isSelectedMarker(i - 1) } ]"
        :style="styles[i - 1]"
        @click="$emit('item-selected', i - 1)"
      >
        <div
          v-if="isSelectedMarker(i - 1)"
          class="point"
          :style="{ height: `${markerSize}px`, width: `${markerSize}px` }"
        />
      </div>
    </div>

    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CStepper',

  props: {
    /**
     * Index of the currently selected item
     */
    selected: {
      type: Number,
      default: 0,
    },

    /**
     * The element that contains the list items, starting from div.content. If nothing is passed,
     * the list is considered to be div.content
     */
    listEl: {
      type: String,
      default: '',
    },

    /**
     * The target of each item marker, starting from listEl. The marker will be positioned at the
     * horizontal level of the target center
     */
    targetEl: {
      type: String,
      default: '',
    },

    /**
     * The marker size
     */
    markerSize: {
      type: Number,
      default: 8
    },

    /**
     * The selected marker size
     */
    selectedSize: {
      type: Number,
      default: 16
    },

    /**
     * Whether the selected marker is always the first
     */
    selectFirst: Boolean
  },

  data () {
    return {
      elements: 0,
      height: 0,
      top: 0,
      styles: [],
      mutation: null,
      timer: 0
    }
  },

  computed: {
    listElement () {
      return this.listEl ? '.content > ' + this.listEl : '.content'
    },

    targetElement () {
      return this.targetEl ? this.listElement + ' > ' + this.targetEl : this.listElement + ' > *'
    }
  },

  methods: {
    isSelectedMarker (index) {
      if (this.selectFirst) return !index

      return this.selected === index
    },

    getElementCount () {
      const el = this.$el.querySelector(this.listElement)

      if (el) this.elements = el.childElementCount
    },

    getBarConfig () {
      const markers = this.$el.querySelectorAll(this.targetElement)

      if (markers.length) {
        const firstItem = this.$el.querySelector(this.listElement + ' > *').getBoundingClientRect()
        const firstMarker = markers[0].getBoundingClientRect()
        const lastMarker = markers[markers.length - 1].getBoundingClientRect()

        // Since div.bar's position is set to 'relative',
        // when top = 0, its top is aligned with div.content's top
        this.top = firstMarker.top - firstItem.top + (firstMarker.height / 2)
        this.height = lastMarker.top + (lastMarker.height / 2)
          - firstMarker.top - (firstMarker.height / 2)
      }
    },

    getMarkerSize (index, selected) {
      if (this.selectFirst) return !index ? this.selectedSize : this.markerSize

      return selected === index ? this.selectedSize : this.markerSize
    },

    getMarkersConfig (selected) {
      const markers = this.$el.querySelectorAll(this.targetElement)

      if (markers.length) {
        const first = markers[0].getBoundingClientRect()

        this.styles = []

        markers.forEach((m, i) => {
          const marker = m.getBoundingClientRect()
          const size = this.getMarkerSize(i, selected)
          // Since div.bar's position is set to 'relative' and div.marker's position is set
          // to 'absolute', when top = 0, its top is aligned with div.bar's top
          const top = marker.top + marker.height / 2 - first.top - first.height / 2 - size / 2

          this.styles.push({
            top: `${top}px`,
            right: `-${Math.ceil(size / 2) - 1}px`,
            height: `${size}px`,
            width: `${size}px`,
            padding: size === this.selectedSize ? `${(this.selectedSize - this.markerSize) / 2}px` : ''
          })
        })
      }
    },

    update (selected) {
      this.getElementCount()
      this.getBarConfig()
      this.getMarkersConfig(selected || this.selected)
    },

    onResize () {
      clearTimeout(this.timer)
      this.timer = setTimeout(this.update, 60)
    }
  },

  watch: {
    selected (val) {
      this.$nextTick(() => this.update(val))
    }
  },

  mounted () {
    this.$nextTick(this.update)

    const el = this.$el.querySelector(this.listElement)

    this.mutation = new MutationObserver(this.update)
    this.mutation.observe(el, { childList: true })

    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy () {
    this.mutation.disconnect()
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss">
.c-stepper {
  display: flex;

  & > .bar {
    position: relative;
    width: 2px;
    margin-right: 20px;
    padding-left: 2px;
    background: color-var(text, base-10);
    transition: .2s ease-in-out;

    & > .marker {
      position: absolute;
      border-radius: 20px;
      background: #B6BAC6;
      transition: .2s ease-in-out;

      &.-selected {
        background: color-var(primary);
        box-shadow: 0 4px 8px rgba(color-var(primary, base-rgb), 0.5);

        & > .point {
          border-radius: 20px;
          background: #FFFFFF;
        }
      }
    }
  }

  & > .content { width: 100%; }
}
</style>
