export const TYPE_OPTIONS = [
  'body-1',
  'body-2',
  'display-1',
  'display-2',
  'h1',
  'h2',
  'h3',
  'h4'
]

export const OPACITY_OPTIONS = [
  'base',
  'base-90',
  'base-80',
  'base-70',
  'base-60',
  'base-50',
  'base-40',
  'base-30',
  'base-20',
  'base-10',
  'base-05',
  'base-02',
  'base-01',
  'dark',
  'light'
]

export const WEIGHT_OPTIONS = [ 'regular', 'medium', 'semibold', 'bold' ]

export const COLOR_OPTIONS = [
  'background',
  'white',
  'text',
  'neutral',
  'primary',
  'positive',
  'negative',
  'alert',
  'info',
  'support',
  'disabled',
  'dark',
  'compl-1',
  'compl-2'
]
