import { DEPENDENTS_AREA } from '@content/consts/Dependents'
import { formatDate, formatDateUS } from '@modules/dateHelpers'
import { getAdditionalFields, getEmployeeInfoSchema } from '@services/systemField'
import { masker, mapToNumeric } from '@modules/formatters'
import { mapFiles, getUploadEndpoint } from '@services/upload'

import request from '@graphql/middleware'
import * as get from '@graphql/queries'
import * as set from '@graphql/mutations'

const dependentMapper = (dependent, employeeId) => ({
  ...dependent,
  fullName: dependent.name && dependent.last_name ? `${dependent.name} ${dependent.last_name}` : '',
  birthDate: formatDate(dependent.birth_date),
  cpf: masker('###.###.###-##', dependent.cpf),
  phone: masker('(##) #####-####', dependent.phone),
  relationship: dependent.relationship.id || null,
  familySalary: dependent.family_salary,
  hasDisability: dependent.has_disability,
  files: mapFiles(dependent.documentFiles || [], employeeId)
})

export async function getOptions () {
  const [ err, data ] = await request(get.GetDependentOptions)
  const { relations, educationTypes } = data || {}

  return [ err, { dependent: { dependentRelations: relations, educationTypes } } ]
}

export async function getDependents (getters) {
  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await request(get.GetDependents, { employeeId })
  const dependents = (data.dependents || [])
    .map(dependent => dependentMapper(dependent, employeeId))

  return [ err, dependents ]
}

export async function setDependent (getters, variables) {
  const employeeId = getters.getEmployeeUuid

  const creating = typeof variables.id === 'number'
  const mutation = creating ? 'CreateDependent' : 'UpdateDependent'
  const [ name, ...lastName ] = variables.fullName.split(' ')

  const input = {
    ...(!creating ? { id: variables.id } : {}),
    employeeId,
    name,
    lastName: lastName.join(' '),
    foreigner: variables.foreigner,
    familySalary: variables.familySalary,
    ir: variables.ir,
    cpf: mapToNumeric(variables.cpf),
    phone: mapToNumeric(variables.phone),
    email: variables.email,
    description: variables.description,
    birthDate: formatDateUS(variables.birthDate),
    dependentRelationId: +variables.relationship || null,
    genderId: +variables.genderId || null,
    educationTypeId: +variables.educationTypeId || null,
    motherName: variables.motherName,
    additional: {
      sectionId: DEPENDENTS_AREA.DEPENDENTS,
      fields: getAdditionalFields(variables.customFields, variables)
    },
    hasDisability: variables.hasDisability
  }

  const [ err, data ] = await request(set[mutation], { input })

  if (err) return [ err, null, null ]

  const dependent = creating ? dependentMapper(data.dependent, employeeId) : variables
  const endpoint = getUploadEndpoint(employeeId, dependent.id, 'dependent')

  return [ null, dependent, endpoint ]
}

export async function deleteDependent (getters, id) {
  if (typeof id === 'number') return [ false, null ]

  const employeeId = getters.getEmployeeUuid

  const input = { employeeId, id }
  const [ err ] = await request(set.DeleteDependent, { input })

  return [ err, null ]
}

export const mapSection = data => ({
  schema: data?.schema || {},
  values: data?.multipleValues || {}
})

export const mapCustomFields = data => ({
  dependent: mapSection(data.find(({ id }) => id === DEPENDENTS_AREA.DEPENDENTS)),
})

export const getCustomFields = async (getters) => {
  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await getEmployeeInfoSchema({
    employeeId,
    area: DEPENDENTS_AREA.LABEL
  })

  const customFields = mapCustomFields(data)

  return [ err, customFields ]
}
