const singleQueryFormat = (query, variables) => {
  if (!query) throw new TypeError('no query provided')

  return ({
    query,
    ...(variables ? { variables } : {})
  })
}

export const headers = headers => {
  return {
    ...(headers || {}),
    'Content-Type': 'application/json'
  }
}

export const singleBody = (query, variables) => JSON.stringify(singleQueryFormat(query, variables))

export const singleResponse = response => {
  const { data, errors } = response || {}

  return [ errors || null, data || {} ]
}

export const singleGenericError = error => [ [ error ], {} ]

export const batchBody = requests => {
  const queries = requests.map(({ query, variables }) => singleQueryFormat(query, variables)
  )

  return JSON.stringify(queries)
}

export const batchResponse = responses => {
  if (Array.isArray(responses)) return responses.map(singleResponse)

  throw TypeError(`Batch response format error: ${responses} response data is not a valid array`, responses)
}

export const batchGenericError = requestsCount => error => Array(requestsCount)
  .fill([ error, {} ])
