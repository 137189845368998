<template>
  <div class="c-divider">
    <div v-if="tag" class="tag" :style="tagStyle">
      <span class="text">
        {{ tag }}
      </span>
    </div>
  </div>
</template>

<script>

/**
 * A hr-like component that allows to use a tag.
 */
export default {
  name: 'CDivider',

  props: {
    tag: {
      type: String,
      default: ''
    },

    tagWidth: {
      type: String,
      default: 'auto'
    }
  },

  computed: {
    tagStyle () {
      return {
        '--tag-width': `${this.tagWidth || 'auto'}`
      }
    }
  }
}
</script>

<style lang="scss">
.c-divider {
  position: relative;
  border-top: 1px solid color-var(text, base-10);

  & > .tag {
    height: 25px;
    min-width: var(--tag-width);
    padding: 0 20px;
    border-radius: 12px;
    background-color: var(--base-background);

    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > .tag > .text {
    @include typo(h5, base-50);
    text-transform: uppercase;
    line-height: 11px;
  }
}
</style>
