import { fullNameValidation, fullNameValidationMsg } from '@modules/validate/fullName'
import cepFill from '@modules/input/cepFill'

export default (ctx) => ({
  address: {
    title: 'Endereço Residencial',
    divider: true,
    files: true,
    filesText: 'Deve ser anexado o <b>comprovante de residência</b>.',
    fields: {
      id: { type: 'text', hide: true },
      zipCode: {
        name: 'zipCode',
        type: 'text',
        inputmode: 'numeric',
        label: 'CEP',
        placeholder: 'CEP',
        mask: '#####-###',
        validation: 'min:9',
        validationMsg: 'Informe um CEP válido.',
        onInput: async (formData, value) => {
          await cepFill({
            neighborhood: 'district',
            street: 'address',
            state: 'state',
            city: 'city',
          }, true)(formData, value)

          if (value.length !== 9) return

          ctx.cities = await ctx.getCities(formData.state)
        },
      },
      address: {
        name: 'streetAddress',
        type: 'text',
        label: 'Endereço Residencial',
        placeholder: 'Insira o endereço',
      },
      number: {
        name: 'number',
        type: 'text',
        inputmode: 'numeric',
        label: 'Número',
        placeholder: 'Insira o número',
        mask: '#######',
      },
      complement: {
        name: 'complement',
        type: 'text',
        label: 'Complemento',
        placeholder: 'Complemento'
      },
      district: {
        name: 'district',
        type: 'text',
        label: 'Bairro',
        placeholder: 'Bairro',
      },
      state: {
        name: 'state',
        type: 'select',
        label: 'UF',
        placeholder: 'Selecione o UF',
        options: [],
        onInput: async formData => {
          if (formData.city) formData.city = undefined

          ctx.cities = await ctx.getCities(formData.state)
        },
        trackBy: 'id',
        displayBy: 'name',
        nullable: true,
      },
      city: {
        name: 'city',
        type: 'select',
        label: 'Cidade',
        placeholder: 'Selecione a cidade',
        options: [],
        trackBy: 'id',
        displayBy: 'name',
        nullable: true,
      },
      files: {
        type: 'file',
        label: '',
        showLabel: false,
        multiple: true,
        labelLeft: false,
        fullWidth: true,
        extensions: [ 'jpg', 'jpeg', 'gif', 'bmp', 'png', 'pdf' ],
        infoCardProps: { hide: true },
        fileLimit: 4,
        validation: { maxLength: 4 },
        sizeLimit: 8
      }
    }
  },

  contact: {
    title: 'Contatos Pessoais',
    divider: true,
    fields: {
      id: { type: 'text', hide: true },
      personalTelephone: {
        name: 'telephone',
        type: 'text',
        inputmode: 'numeric',
        label: 'Telefone Residencial',
        placeholder: '(00) 0000-0000',
        mask: '(##) #####-####',
        validationMsg: 'Número inválido',
        validation: { min: 14 },
      },
      personalCellphone: {
        name: 'cellphone',
        type: 'text',
        inputmode: 'numeric',
        label: 'Telefone Celular',
        placeholder: '(00) 00000-0000',
        mask: '(##) #####-####',
        validationMsg: 'Número inválido',
        validation: { min: 14 },
      },
      personalEmail: {
        type: 'text',
        name: 'email',
        placeholder: 'E-mail Pessoal',
        label: 'E-mail Pessoal',
        validation: {
          max: 106,
          email: true
        }
      }
    }
  },

  emergency: {
    title: 'Contatos de Emergência',
    singleTitle: 'Contato de Emergência',
    singleIcon: 'phone-call-circle',
    addLabel: 'Adicionar novo contato',
    multiple: true,
    fields: {
      id: { type: 'text', hide: true },
      name: {
        name: 'name',
        type: 'text',
        label: 'Nome completo',
        placeholder: 'Nome completo',
        validationMsg: fullNameValidationMsg,
        validation: { custom: fullNameValidation },
      },
      relation: {
        name: 'relation',
        type: 'select',
        label: 'Relação',
        displayBy: 'name',
        trackBy: 'id',
        nullable: true,
        options: [],
        optionsSrc: 'emergencyRelations',
        placeholder: 'Relação',
      },
      telephone: {
        name: 'telephone',
        type: 'text',
        inputmode: 'numeric',
        label: 'Telefone',
        placeholder: '(00) 00000-0000',
        mask: '(##) #####-####',
      },
      work_phone: {
        type: 'text',
        inputmode: 'numeric',
        placeholder: 'Telefone de trabalho',
        label: 'Telefone de trabalho',
        mask: '(##) #####-####',
        validationMsg: 'Número inválido',
        validation: { min: 14 },
      },
      cellphone: {
        type: 'text',
        inputmode: 'numeric',
        placeholder: 'Celular',
        label: 'Celular',
        mask: '(##) #####-####',
        validationMsg: 'Número inválido',
        validation: { min: 14 },
      },
      email: {
        type: 'text',
        name: 'email',
        placeholder: 'E-mail Pessoal',
        label: 'E-mail Pessoal',
        validation: {
          max: 106,
          email: true
        }
      }
    }
  }
})
