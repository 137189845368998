<template>
  <svg
    class="c-step"
    width="65px"
    height="65px"
    viewBox="0 0 65 65"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <text font-family="Ubuntu" font-size="16" font-weight="normal" fill="#FFFFFF" id="c-step-text-1">
        <tspan x="0.988" y="15">{{ step }}</tspan>
      </text>

      <filter
        x="-35.0%"
        y="-13.9%"
        width="170.0%"
        height="138.9%"
        filterUnits="objectBoundingBox"
        id="c-step-filter-2"
      >
        <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />

        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>

    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" id="c-step-Symbols">
      <g transform="translate(-1.000000, 0.000000)" id="c-step-Group-7-Copy">
        <g id="c-step-Group-5" />

        <g
          transform="translate(1.000000, 0.000000)"
          fill="#FFFFFF"
          fill-opacity="1"
          opacity="0.8"
          id="c-step-Group-5"
        >
          <g transform="translate(27.000000, 23.000000)" id="c-step-Group-6">
            <g id="c-step-1">
              <use filter="url(#c-step-filter-2)" xlink:href="#c-step-text-1" />

              <use xlink:href="#c-step-text-1" />
            </g>
          </g>
        </g>

        <circle
          :stroke="ringColor"
          opacity="0.5"
          cx="33.5"
          cy="32.5"
          r="21"
          id="c-step-Oval-6-Copy" />

        <circle
          :stroke="ringColor"
          opacity="0.2"
          cx="33.5"
          cy="32.5"
          r="25"
          id="c-step-Oval-6-Copy" />

        <circle
          :stroke="ringColor"
          opacity="0.05"
          cx="33.5"
          cy="32.5"
          r="30"
          id="c-step-Oval-6-Copy" />

        <circle
          :stroke="ringColor"
          cx="33.5"
          cy="32.5"
          r="17"
          id="c-step-Oval-6-Copy" />

        <circle
          :stroke="ringColor"
          opacity="0.5"
          cx="33.5"
          cy="32.5"
          r="21"
          id="c-step-Oval-6-Copy" />

        <circle
          :stroke="ringColor"
          opacity="0.2"
          cx="33.5"
          cy="32.5"
          r="25"
          id="c-step-Oval-6-Copy" />

        <circle
          :stroke="ringColor"
          opacity="0.05"
          cx="33.5"
          cy="32.5"
          r="30"
          id="c-step-Oval-6-Copy" />

        <circle
          :stroke="ringColor"
          cx="33.5"
          cy="32.5"
          r="17"
          id="c-step-Oval-6-Copy" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CStep',
  props: {
    /**
     * The number of the step.
     */
    step: {
      type: [ String, Number ],
      required: true
    },

    color: {
      type: String,
      default: 'primary',
      validator: color => [ 'primary', 'negative', 'alert', 'positive', 'info', 'dark', 'disabled', 'text' ]
        .includes(color)
    }
  },

  computed: {
    ringColor () {
      const colorName = `--color-${this.color}-base`
      return this.$css.get(colorName)
    }
  }
}
</script>

<style lang="scss">
.c-step { margin-left: -5px; }
</style>
