<template>
  <form class="c-form" novalidate :name="name" @submit.prevent="submitForm">
    <c-shadowed v-bind="$attrs">
      <slot name="top" />

      <div class="fields">
        <component
          v-show="!isHiddenField(fieldName)"
          v-for="(field, fieldName) in mutatedFields"
          :is="getFieldComponent(field.type)"
          :key="fieldName"
          :ref-cy="fieldName"
          :class="[ 'field', field.type, { '-error': submitErrorAnim } ]"
          :name="fieldName"
          :label-left="labelLeft"
          :disabled="isFieldDisabled(fieldName)"
          :validation="getValidationMsg(fieldName)"
          :options="getFieldOptions(fieldName)"
          :value="getFieldValue(fieldName)"
          :error="hasError(fieldName)"
          :show-hint="showHint(fieldName)"
          v-bind="getFieldAttrs(field)"
          @add="addMultiItem(fieldName, $event)"
          @remove="removeMultiItem(fieldName, $event)"
          @input="updateField(fieldName, $event)"
          @focus="focusing = fieldName"
          @blur="focusing = null"
          @file:add="onAddFiles(fieldName, $event)"
          @file:remove="onRemoveFile(fieldName, $event)"
          @mouseenter.native="hovering = field.hint && fieldName"
          @mouseleave.native="hovering = null"
        />

        <slot />
      </div>

      <slot name="footer" />
    </c-shadowed>

    <div v-show="!noActions" class="actions">
      <slot name="actions">
        <c-button primary class="action">Salvar</c-button>
      </slot>
    </div>
  </form>
</template>

<script>
import { is, getScrollParent, equals, mapRequiredFields } from '@convenia/helpers'
import FormValidator from '@convenia/validator'

// OBS: using c-select in text area and jumbo mode breaks the component
export default {
  name: 'CForm',

  mixins: [ FormValidator ],

  props: {
    /**
     * The form fields.
     */
    fields: {
      type: [ Object ],
      required: true
    },

    /**
     * The form name.
     */
    name: {
      type: String,
      default: 'formData'
    },

    /**
     * Whether the form is loading.
     */
    loading: Boolean,

    /**
     * Disables the form.
     */
    disabled: Boolean,

    /**
     * Moves all of the input's labels to the left side.
     */
    labelLeft: Boolean,

    /**
     * Removes input and blur listeners from all inputs, making so that
     * form validation only happens on submit.
     */
    noListeners: Boolean,

    /**
     * Fires an event with updated formData
     */
    syncData: Boolean,

    /*
     * Remove save button
     */
    noActions: Boolean,

    /**
     * Another way to setup the form required fields.
     * Should be an array of string with the field keys.
     */
    requiredFields: {
      type: Array,
      default: () => ([])
    }
  },

  validatorOptions: vm => ({
    noListeners: vm.noListeners
  }),

  computed: {
    mutatedFields () {
      return mapRequiredFields(this.fields || {}, this.requiredFields || [])
    }
  },

  data () {
    return {
      [this.name]: {},
      dynamicFields: [],
      submitErrorAnim: false,
      focusing: null,
      hovering: null,
      attempts: 0
    }
  },

  watch: {
    fields: {
      handler: 'initFactory',
      deep: true,
      immediate: true
    }
  },

  methods: {
    initFactory (a, b) {
      if (equals(a, b)) return

      const reduceToValue = (entity, key, ignoreEmpty) => Object.keys(entity)
        .reduce((acc, propName) => ({
          ...acc,
          ...(!(entity[propName] || {})[key] && ignoreEmpty
            ? {}
            : { [propName]: (entity[propName] || {})[key] })
        }), {})

      const validations = reduceToValue(this.mutatedFields, 'validation', true)
      this[this.name] = reduceToValue(this.mutatedFields, 'value')
      this.$validator.init({ [this.name]: validations })

      this.setDynamicFields()
    },

    isHiddenField (fieldName) {
      const field = this.fields[fieldName]

      if (is(field.hide, 'Function')) return field.hide(this[this.name])

      return field.hide
    },

    hasError (fieldName) {
      const fieldValidations = (this.$validations || {})[fieldName]
      const { errors = [] } = fieldValidations || {}

      return !!errors.length
    },

    getFieldComponent (type) {
      const typeToComponent = {
        'file': 'c-upload',
        'text': 'c-input',
        'password': 'c-input',
        'select': 'c-select',
        'check': 'c-checkbox',
        'radio': 'c-radio-button',
        'multi-select': 'c-multi-select',
        'multi-input': 'c-multi-input',
        'multi-check': 'c-multi-checkbox',
        'color': 'c-input-color'
      }

      return typeToComponent[type] || 'c-input'
    },

    onAddFiles (fieldName, files) {
      const field = (this.fields[fieldName] || {})
      const value = Array.from(files).map(file => ({
        ...file,
        done: false,
        progress: 0,
        aborted: false,
        uploading: false,
        request: null
      }))

      if (!field.multiple) {
        this[this.name][fieldName] = value
        return
      }

      this[this.name][fieldName] = [
        ...value,
        ...this[this.name][fieldName]
      ]
    },

    onRemoveFile (fieldName, file) {
      const index = this[this.name][fieldName].indexOf(file)

      if (index !== -1)
        this[this.name][fieldName].splice(index, 1)
    },

    addMultiItem (fieldName, item) {
      const fieldValues = [ ...(this[this.name][fieldName] || []) ]
      fieldValues.push(item)

      this[this.name][fieldName] = fieldValues
    },

    removeMultiItem (fieldName, item) {
      const field = this.fields[fieldName]
      const trackBy = field.type === 'multi-input' ? 'id' : field.trackBy

      const fieldValues = [ ...(this[this.name][fieldName] || []) ]
      const itemIndex = fieldValues.findIndex((fieldItem) => {
        return item[trackBy] === fieldItem[trackBy]
      })

      fieldValues.splice(itemIndex, 1)

      this[this.name][fieldName] = fieldValues
    },

    getValidationMsg (fieldName) {
      const fieldDef = this.fields[fieldName]
      const fieldFlags = this.$validations[fieldName] || {}
      const fieldErrors = (fieldFlags.errors || [])

      const callValidationMsg = validationMsg => typeof validationMsg === 'function'
        ? validationMsg(this[this.name])
        : validationMsg

      const msg = fieldErrors.length && fieldErrors[0] !== 'Campo obrigatório.' && fieldDef.validationMsg
        ? callValidationMsg(fieldDef.validationMsg)
        : fieldErrors[0] || callValidationMsg(fieldDef.validationMsg)

      const validationMsg = msg === 'Campo obrigatório.' ? 'O campo é obrigatório.' : msg

      return fieldErrors.length || fieldDef.forceError
        ? validationMsg || fieldErrors[0] || ''
        : ''
    },

    getFieldValue (fieldName) {
      const field = this.fields[fieldName]

      if (is(field.value, 'Function')) {
        const value = field.value(this[this.name])
        this[this.name][fieldName] = value
        return value
      }

      return this[this.name][fieldName]
    },

    getFieldOptions (fieldName) {
      const field = this.fields[fieldName] || {}

      return field.optionsFilter
        ? field.optionsFilter(this[this.name], field.options)
        : field.options
    },

    isFieldDisabled (fieldName) {
      if (this.disabled) return this.disabled

      const field = this.fields[fieldName] || {}
      const fieldOptions = field.options && this.getFieldOptions(fieldName)

      if (is(field.isDisabled, 'Function')) {
        return field.isDisabled(this[this.name])
      }

      return field.disabled || (!!field.options && !(fieldOptions || []).length)
    },

    updateField (fieldName, value) {
      if (value instanceof Event) return

      const field = this.fields[fieldName] || {}

      this.$set(this[this.name], fieldName, value)

      if (field.onInput) field.onInput.call(this, this[this.name], value, this)
    },

    focusFirstError () {
      const fields = this.$validator.fields.all()
      const labelMargin = 40

      fields.forEach(field => {
        if (field.errors.length === 0) return

        const fieldEl = field.el.closest('.c-input-container') || field.el
        const parentElement = getScrollParent(fieldEl)
        const parentRect = parentElement.getBoundingClientRect()
        const fieldRect = fieldEl.getBoundingClientRect()

        const offsetTop = parentElement !== document.scrollingElement
          ? fieldRect.top - parentRect.top + parentElement.scrollTop
          : fieldRect.top - parentRect.top

        const top = (this.labelLeft ? offsetTop : offsetTop - labelMargin)
            - (parentElement.clientHeight - fieldEl.clientHeight) / 2

        parentElement.scrollTo({ top, behavior: 'smooth' })
      })
    },

    submitForm () {
      if (this.noListeners && this.attempts === 0) {
        this.bindDynamicFieldListeners()
      }

      const isValid = this.$validator.validateAll()

      if (isValid) this.$emit('submit', this[this.name])
      else {
        this.$emit('invalid-submit')
        this.attempts += 1
        this.focusFirstError()
        this.submitErrorAnim = true
        setTimeout(() => { this.submitErrorAnim = false }, 1000)
      }
    },

    setDynamicFields () {
      this.dynamicFields = Object.keys(this.fields)
        .filter(fieldName => is(this.fields[fieldName].validation, 'Function'))
        .map(fieldName => ({ ...this.fields[fieldName], name: fieldName }))

      if (this.syncData) {
        const emitSync = data => this.$emit('sync:form-data', data)

        this.$watch(this.name, emitSync, { deep: true })
      }

      if (this.noListeners) return

      this.bindDynamicFieldListeners()
    },

    bindDynamicFieldListeners () {
      const reEvaluator = data => {
        this.dynamicFields.forEach(({ name, ...field }) => {
          const newRule = field.validation(data, this.$validations)

          this.$validator.setFieldRule({ name, scope: this.name }, newRule)
        })
      }

      if (this.dynamicFields.length) {
        this.$watch(this.name, reEvaluator, { deep: true, immediate: true })
      }
    },

    getFieldAttrs (field) {
      const { onInput, ...attrs } = field
      return attrs
    },

    showHint (fieldName) {
      const { hovering, focusing, isFieldDisabled } = this

      return hovering && !isFieldDisabled(fieldName)
        ? hovering === fieldName
        : focusing === fieldName
    }
  }
}
</script>

<style lang="scss">
.c-form {
  display: flex;
  flex-direction: column;

  & > .c-shadowed {
    height: 100%;
    @include responsive(tablet, desktop) {
      & > .wrapper { overflow: initial !important; }
    }
  }

  & > .c-shadowed {
    & > .wrapper {
      &  > .fields {
        width: 100%;
        max-width: 400px;
        padding: { top: 0; right: 20px; bottom: 0; left: 20px; }

        & > .field {
          &[type="hidden"] { display: none; }

          &:not(:last-child) {
            transition: padding-bottom .3s .1s;
            &:not(.check), &:not(.radio) { padding-bottom: 20px; }

            &.-label-left,
            &[label-left] {
              &.check, &.radio { margin: { top: 10px }; }

              @include responsive (tablet, desktop) {
                &:not(.check), &:not(.radio) { padding-bottom: 10px; }
              }

              &.-validation { padding-bottom: 20px; }
            }
          }

          &.-jumbo {
            &:not(:last-child) { padding-bottom: 0; margin-bottom: 20px; }
            &.-validation.select:not(:last-child) { margin-bottom: 42px; }
            &:not(.radio) { margin-top: 0; }
          }

          @include responsive (tablet, desktop) {
            &.-label-left:not(.control) + .-label-left.control {
              margin-top: 10px;
            }
          }
        }

        & > .c-radio-button { padding-bottom: 20px !important; }

        & > .multi-select.-validation { padding-bottom: 30px; }

        & > .multi-check, & > .radio > .label { align-items: flex-start; }

        & > .field:last-child { padding-bottom: 0 !important; }
      }
    }
  }

  & > .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    flex-shrink: 0;

    & > .action {
      flex: 1 1;
      max-width: 180px;

      &:not(:last-child) { margin-right: 10px; }
    }
  }
}
</style>
