<script>
import { mapActions } from 'vuex'
import { MediaQuery } from '@convenia/mixins'
import * as types from '@types'
import * as middlewareRequests from '@graphql/middleware'

export default {
  name: 'AuthenticationScript',

  mixins: [ MediaQuery ],

  beforeRouteEnter (to, from, next) {
    const { token } = to.params

    next(vm => vm.init(token))
  },

  render: () => ({}),

  methods: {
    ...mapActions({
      authenticate: types.AUTH_AUTHENTICATE
    }),

    async init (token) {
      const [ err, authenticationToken ] = await this.authenticate(token)

      if (err === 'done')
        return this.$router.push({ path: '/done' })

      if (err === 'unauthorized')
        return this.$router.push({ path: '/unauthorized' })

      middlewareRequests.setToken(authenticationToken)
      this.$router.push({ path: '/' })
    }
  }
}
</script>
