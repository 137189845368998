import { is } from '@convenia/helpers'

/**
 * This mixin allows to format the submit data using
 * the 'submitFormat' prop on CFormBuilder and the
 * 'submitFormat' option on the fields schemas.
 *
 * Beware that you can only format the values of the non-group
 * fields (those who come from the FormField component). You can only change the
 * the references structure when formatting the grouped fields.
 *
 * Example: you can transform a FormFieldList value [ firstField, secondField ]
 * on a object like { firstField, secondField } using the function
 * '([ firstField, secondField ]) => ({ firstField, secondField })' on the 'submitFormat'
 * schema option which doesn't change the values of the individual fields, just how they
 * are grouped. Use the 'submitFormat' option of each field schema to change their values instead.
 */

const formatChildRefsArg = (childRefs, value, schema) => {
  if (is(value, 'Array')) return childRefs

  if (is(value, 'Object')) return Object.fromEntries(
    Object.keys(schema)
      .map((key, index) => [ key, childRefs[index] ])
  )
}

const callRefsFunctions = (refData) => {
  if (is((refData || {}).getFormattedFields, 'Function')) return refData.getFormattedFields()

  if (is(refData, 'Array')) return refData.map(data => callRefsFunctions(data))

  if (is(refData, 'Object')) return Object.fromEntries(
    Object.entries(refData)
      .map(([ key, value ]) => [ key, callRefsFunctions(value) ])
  )

  return refData
}

const callGroupSubmitFormatFunctions = (submitFormatFunction, childRefsArg) => {
  const refsFormattedGroup = submitFormatFunction(childRefsArg)

  return callRefsFunctions(refsFormattedGroup)
}

export default ({ schemaRef, childRefsName, root, isProp }) => ({
  ...(root || isProp
    ? { props: { submitFormat: Function } }
    : {}
  ),

  ...(root ? {
    data: () => ({ submitFormatEnabled: false }),

    provide () {
      return {
        _enableSubmitFormat: this._enableSubmitFormat
      }
    },
  } : {
    inject: [ '_enableSubmitFormat' ],
  }),

  methods: {
    getFormattedFields () {
      const assignedSubmitFormatFunction = root || isProp
        ? this.submitFormat
        : (this[schemaRef] || {}).submitFormat

      const submitFormatFunction = assignedSubmitFormatFunction || (v => v)
      const childRefs = this.$refs[childRefsName]

      if (!childRefs) return submitFormatFunction(this.value)

      const childRefsArg = formatChildRefsArg(childRefs, this.value, this[schemaRef])
      return callGroupSubmitFormatFunctions(submitFormatFunction, childRefsArg)
    },

    ...(root ? {
      _enableSubmitFormat () {
        this.submitFormatEnabled = true
      }
    } : {})
  },

  beforeMount () {
    const submitFormatRef = root ? 'submitFormat' : `${schemaRef}.submitFormat`

    this.$watch(submitFormatRef, (hasSubmitFormat) => {
      return hasSubmitFormat && this._enableSubmitFormat()
    }, { immediate: true })
  }
})
