import { headers } from '@modules/rest'

// Constants
// ---------

const SUCCESS_CODES = [ 200, 201, 202, 204 ]

export const LISTENERS = {
  abort: ({ files, reject, req: { status, statusText } = {} }) => {
    files.forEach(file => {
      file.done = true
      file.uploading = false
    })

    reject({ status, statusText })
  },
  error: ({ files, reject, req: { status } }) => {
    const errorMsg = 'Houve um erro ao realizar o upload'

    files.forEach(file => {
      file.done = true
      file.uploading = false
      file.error = errorMsg
    })

    reject({ status, statusText: errorMsg })
  },
  load: ({ files, resolve, req }) => {
    files.forEach(file => {
      file.done = true
      file.uploading = false
    })

    resolve(req.response)
  },
  progress: ({ files }, event) => {
    if (!event.lengthComputable) return

    files.forEach(file => {
      file.progress = ((event.loaded / event.total) * 100).toFixed(0)
    })
  },
  loadstart: ({ files }) => {
    files.forEach(file => {
      file.uploading = true
    })
  }
}

// Helpers
// -------

export const setHeaders = (req, headers) => {
  Object
    .entries(headers)
    .forEach(([ key, value ]) => req.setRequestHeader(key, value))
}

export const captureErrors = (req, files, { error: errorFn } = {}, ctx) => {
  req.onreadystatechange = () => {
    if (req.readyState !== 4 || SUCCESS_CODES.includes(req.status)) return

    errorFn({ files, req, ...ctx }, req.readyState)
  }
}

const registerListeners = ({ req, file = {}, listeners = {}, ...ctx }) => {
  Object
    .entries(listeners)
    .forEach(([ evName, fn ]) => {
      const once = evName !== 'progress'
      const target = evName === 'progress' ? req.upload : req
      const handler = fn.bind(null, { files: [ file ], req, ...ctx })

      if (fn) target.addEventListener(evName, handler, { once })
    })

  if (listeners.error) captureErrors(req, [ file ], listeners, ctx)
}

const openRequest = ({ file, endpoint, listeners, headers, additionalPayload } = {}) =>
  new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()
    const formData = new FormData()

    registerListeners({
      req: request,
      file,
      listeners,
      resolve,
      reject
    })

    formData.append('file', file.data)
    Object.entries(additionalPayload || {}).forEach(args =>
      formData.append(...args))

    request.open('post', endpoint, true)
    setHeaders(request, headers)
    request.send(formData)
  })

const getXHR = (file, endpoint, listeners, additionalPayload) => {
  return openRequest({
    endpoint,
    file,
    headers: headers([ 'Authorization' ]),
    additionalPayload,
    listeners: { ...LISTENERS, ...listeners }
  })
}

// Function export
// -------

/**
 * This serialized helper version is yet to be tested properly.
 * It is based on Erik's FileUpload mixin
 */

export default function upload ({
  files = [],
  endpoint = '',
  listeners = {},
  additionalPayload = {}
}) {
  if (!files || !endpoint) return

  return Promise.all((files || []).map(async file => {
    const rawRequest = await getXHR(file, endpoint, listeners, additionalPayload)
    try {
      const request = JSON.parse(rawRequest)

      file.id = request.id || file.id

      return {
        ...file,
        request
      }
    } catch (e) {
      return { ...file }
    }
  }))
}
