import { extend } from 'vee-validate'
import { isDateValid } from '@convenia/helpers'
import { cpf, pis, fullName, minLength, maxLength } from '../rules'

extend('date', {
  validate: isDateValid,
  message: 'Data inválida'
})

extend('cpf', cpf)
extend('pis', pis)
extend('fullName', fullName)
extend('minLength', minLength)
extend('maxLength', maxLength)
