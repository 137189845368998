<template>
  <div :class="classes">
    <popover
      class="popover"
      :show="showPopover"
      :items="popoverItems"
      @close="showPopover = !showPopover"
      v-on="$listeners"
    />

    <div class="content" @keyup.esc="toggle">
      <div class="actions">
        <c-button
          v-if="(popoverItems || []).length"
          ref-cy="ellipsis-action-button"
          class="collapse"
          icon="dots-menu"
          icon-size="24"
          :translucent="!alternativeMobileStyle"
          :flat="alternativeMobileStyle"
          @click="showPopover = !showPopover"
        />

        <c-button
          class="options close"
          icon="delete-disabled"
          icon-size="24"
          :translucent="!alternativeMobileStyle"
          :flat="alternativeMobileStyle"
          @click="close"
        />
      </div>

      <div class="header">
        <template v-if="sidebarLogo || symbolSrc">
          <div v-if="collapse" class="logo-mask">
            <img
              key="logo-flat"
              :src="symbolSrc ? symbolSrc : sidebarLogo"
              class="logo"
              no-loader
            />
          </div>

          <img
            v-else
            key="logo-full"
            :src="sidebarLogo"
            class="logo"
            no-loader
          />

        </template>

        <c-avatar
          v-else-if="!sidebarLogo && !symbolSrc && !isMobile"
          shadow
          :name-placeholder="companyName"
          :initials-size="collapse ? 18 : 36"
          :size="collapse ? 40 : 80"
          hide-tooltip
        />

        <slot name="header">
          <div class="info">
            <c-avatar
              size="80"
              shadow
              :name-placeholder="employee.name"
              :initials-size="isMobile ? 36 : 18"
              :src="employee.src || ''"
              hide-tooltip
            />

            <span class="name">
              {{ employee.name }}
            </span>

            <span class="role">
              {{ employee.role }}
            </span>
          </div>

          <c-select
            v-if="!noSelect"
            :options="options"
            track-by="id"
            display-by="name"
            icon="graph-google-analitycs"
            alternative
            class="select"
            v-model="internalValue"
          />
        </slot>
      </div>

      <div class="items">
        <c-sidebar-item
          v-for="item in items"
          :key="item.name"
          :item="item"
          :persist-mobile="persistMobile"
          :collapse="collapse"
          v-bind="$attrs"
          class="item"
          @click="!item.separator && close()"
        />
      </div>

      <div class="footer">
        <slot name="footer">
          <c-button
            class="exit"
            ghost
            icon="exit-door-log-out-2"
            icon-size="22"
            @click="$emit('select', 'exit')"
          >
            Sair
          </c-button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import Popover from './Popover'

/**
 * A sidebar component.
 */
export default {
  name: 'CSidebar',

  mixins: [ MediaQuery ],

  components: { Popover },

  props: {
    /**
     * Whether to show or hide the sidebar.
     */
    show: {
      type: Boolean,
      required: true
    },

    collapse: {
      type: Boolean,
      default: false
    },

    /**
     * The sidebar items.
     */
    items: {
      type: [ Array, Object ],
      default: () => ([])
    },

    /**
     * The sidebar popover items.
     */
    popoverItems: {
      type: [ Array, Object ],
      default: () => ({})
    },

    /**
     * The options available in the select field.
     * e.g. the companies list
     */
    options: {
      type: Array,
      default: () => []
    },

    /**
     * The value of the select field
     * e.g. the current company, by default.
     */
    value: {
      type: [ Object, String ],
      default: '',
    },

    /**
     * An object with the employee information.
     * among the employee properties are:
     * name, role and src (the avatar url)
     */
    employee: {
      type: Object,
      default: () => ({ name: '', role: '' }),
      validator: x => 'name' in x
    },

    /**
     * The sidebar color theme
    */
    theme: {
      type: String,
      default: 'default',
      validator: x => [ 'default', 'dark' ].includes(x)
    },

    /**
     * The logo image src
    */
    logoSrc: {
      type: String,
      default: '/img/logo_convenia_white.svg'
    },

    /**
     * The flatlogo image src
    */
    symbolSrc: {
      type: String,
      default: '/img/logo-flat.svg'
    },

    /**
     * The companyName to be used when company have no logo and flatlogo (symbol)
    */
    companyName: {
      type: String,
      default: ''
    },

    /**
     * Persists the mobile styles until desktop breakpoint
    */
    persistMobile: Boolean,

    /**
     * Removes the select field on mobile screens
     */
    noSelect: Boolean,

    /**
     * Changes the background to a neutral white tone in tablet screens
     */
    alternativeTabletStyle: Boolean,

    /**
     * Changes the background to a neutral white tone in mobile screens
     */
    alternativeMobileStyle: Boolean,

    /**
     * Logo to be used in tablet screen when using the alternet tablet style
     */
    alternativeTabletLogo: {
      type: String,
      default: '/img/logo_dark.svg'
    },
  },

  data () { return { showPopover: false } },

  computed: {
    classes () {
      return [
        'c-sidebar', {
          '-collapse': this.collapse,
          '-hidden': !this.show,
          '-dark': this.theme === 'dark',
          '-mobile-only': this.persistMobile,
          '-alternative-tablet-style': this.alternativeTabletStyle,
          '-alternative-mobile-style': this.alternativeMobileStyle
        }
      ]
    },

    internalValue: {
      get () { return this.value },
      set (v) { this.$emit('sidebar-input', v) }
    },

    logoArcColor () {
      if (this.theme === 'dark') return 'dark'
      return 'white'
    },

    sidebarLogo () {
      return this.alternativeTabletStyle && !this.isXsMobile && this.isTablet
        ? this.alternativeTabletLogo || this.logoSrc
        : this.logoSrc
    }
  },

  methods: {
    close () {
      /**
       * Emitted when the user opens or closes the sidebar.
       * @event toggle-sidebar
       * @type {undefined}
       */
      if (this.show) this.$emit('toggle-sidebar')
    }
  }
}
</script>

<style lang="scss">
$c-sidebar-max-width: 260px;
$c-sidebar-width: 260px;

@mixin alternative-mobile-style {
  background: url('/img/sidebar-mobile-bg.svg'),
              linear-gradient(180deg, #FFF 50%, rgba(#FFF, 0.8) 100%);
  background-color: color-var(text);
  background-size: cover;

  & > .content > .header > .info {
    & > .name { color: color-var(text, base-80); }
    & > .role { color: color-var(text, base-50); }
  }

  & > .content > .footer > .exit {
    & > .icon, &:hover > .icon { stroke: color-var(text, base-30); }
    & > .text, &:hover > .text { color: color-var(text, base-50); }
  }

  & > .content > .actions {
    & > .collapse, & > .close {
      & > .icon { stroke: color-var(text, base-30); }
    }
  }

  & > .content > .items > .item {
    &:not(.-active) {
      background-color: color-var(text, base-05);
      backdrop-filter: blur(3px);
    }

    & > .icon {
      stroke: color-var(text, base-30);
      filter: none;
    }

    & > .full-name, > .short-name {
      color: color-var(text, base-50);
      text-shadow: none;
    }

    &.-active {
      background-color: color-var(primary);
      box-shadow: 0px 4px 7px 0px rgba(color-var(primary, base-rgb), .25);

      & > .icon { stroke: #FFF; }
      & > .full-name, > .short-name { color: #FFF; }
    }
  }
}

@mixin add-mobile-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-6);

  width: 100vw;
  background: url('/img/sidebar-mobile-bg.svg'), color-var(primary);
  background-blend-mode: hard-light;
  background-size: cover;

  &.-alternative-mobile-style { @include alternative-mobile-style; }

  .content {
    padding: 15px;
    position: relative;

    & > .actions {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 10px;

      display: flex;
      justify-content: space-between;

      & > .close { margin-left: auto; }
    }

    & > .header {
      padding-top: 30px;
      padding-bottom: 50px;

      & > .logo { display: none; }

      & > .info {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;

        & > .c-avatar > .initials { font-size: 36px; }

        & > .name {
          font-family: var(--title-font-family);
          font-weight: var(--title-font-weight);
          font-size: var(--title-font-size);
          line-height: 22px;
          padding-top: 10px;
        }

        & > .role {
          font-family: var(--base-font-family);
          font-weight: var(--base-font-weight);
          font-size: 14px;
          line-height: 19px;
          opacity: 0.8;
        }
      }

      & > .select {
        display: block;
        margin-top: 40px;
        width: 100%;
      }
    }

    & > .items {
      display: grid;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;
    }

    & > .footer {
      margin: 25px auto 12px;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;

      & > .exit > .icon {
        stroke: rgba(#FFF, 0.5);
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
        margin-top: 2px;
        transition: stroke ease 300ms;
      }

      & > .exit > .text {
        color: rgba(#FFF, .8);
        font-size: 11px;
        font-weight: var(--title-font-weight);
        font-family: var(--title-font-family);
        transition: color ease 300ms;
      }

      & > .exit:hover {
        & > .text { color: rgba(#FFF, 1); }
        & > .icon { stroke: rgba(#FFF, .8); }
      }
    }
  }

  &.-hidden {
    transform: translateX(-100%);
    box-shadow: none;
    z-index: -20;
    transition: transform .5s, box-shadow .5s, z-index 1s linear;
  }
}

@mixin tablet-sidebar-alternative {
  background-image: url('/img/sidebar-bg.svg'),
                  linear-gradient(180deg, #FFF 0%, rgba(255,255,255,0.83) 100%);
  background-color: unset;

  & > .content > .items > .item {
    & > .icon {
      @include icon-color(text, base-30);
      filter: none;
    }

    & > .full-name, > .short-name {
      color: color-var(text, base-50);
      text-shadow: none;
    }

    &.-active {
      background-color: color-var(primary);
      box-shadow: 0px 4px 7px 0px rgba(color-var(primary, base-rgb), .25);

      & > .icon { stroke: #FFF; }
      & > .full-name, > .short-name { color: #FFF; }
    }
  }
}

.c-sidebar {
  z-index: var(--z-index-4);
  position: relative;
  &, & * { box-sizing: border-box; }

  display: flex;
  flex-direction: column;

  width: $c-sidebar-width;
  height: 100%;

  background-repeat: no-repeat;
  background-image: url('/img/sidebar-bg.svg');
  background-color: color-var(primary);

  &.-dark {
    background-blend-mode: overlay;
    background-image: url('/img/sidebar-bg-dark.svg');
    background-color: color-var(dark, dark)
  }

  &.-collapse {
    width: 70px;
    overflow: visible;
    background-image: unset;

    & > .content {
      padding: 0px;
      overflow: visible;

      & > .header {
        padding: 15px 0 39px 0;
      }

      & > .header > .logo-mask {
        width: 40px;
        height: 40px;
        padding: 0;
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @include add-shadow(before) {
          opacity: 0.1;
          transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
        }
      }

      & > .header > .logo-mask > .logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

    }
  }

  transition: width 400ms, transform .5s, box-shadow .5s, z-index 0s linear;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.-alternative-tablet-style {
    @include responsive (mobile, tablet) { @include tablet-sidebar-alternative; }
  }

  & > .content {
    padding: 20px;

    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > .actions { display: none; }

    & > .header {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      align-items: center;

      padding: 45px 0 35px 0;
      transition: padding 200ms;

      & > .info,
      & > .select { display: none; }

      & > .logo {
        width: 125px;
        padding-right: 10px;
      }
    }

    & > .items {
      display: flex;
      flex-direction: column;

      flex-grow: 1;
      width: 100%;
    }

    & > .footer { display: none; }
  }

  &:not(.-mobile-only) {
    @include responsive (mobile, tablet) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: var(--z-index-6);

      &.-hidden {
        transform: translateX(-$c-sidebar-width);
        box-shadow: none;
      }
    }

    @include xs-mobile { @include add-mobile-sidebar; }
  }

  &.-mobile-only { @include add-mobile-sidebar; }
}
</style>
