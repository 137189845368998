<template>
  <div class="c-multi-select-item-list">
    <c-tag
      v-for="(item, index) in items"
      :key="item[displayBy]"
      class="item"
      :item="item"
      :display-by="displayBy"
      :disabled="item.disabled"
      @remove="$emit('remove', { index, event: $event })"
    />
  </div>
</template>

<script>
export default {
  name: 'CMultiSelectItemList',

  props: {
    items: {
      type: Array,
      required: true
    },

    displayBy: {
      type: String,
      default: 'label'
    }
  }
}
</script>

<style lang="scss">
.c-multi-select-item-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -5px 10px;

  & > .item { margin: 10px 5px 0; }
}
</style>
