// eslint-disable-next-line import/no-cycle
import { isEqualObject } from './object'
import { is } from './is'

/**
 * Creates a map out of an array be choosing what property to key by
 * @param {object[]} array Array that will be converted into a map
 * @param {string} prop Name of property to key by
 * @return {object} The mapped array. Example:
 *     mapFromArray([{a:1,b:2}, {a:3,b:4}], 'a')
 *     returns {1: {a:1,b:2}, 3: {a:3,b:4}}
 */
export function mapFromArray (array, prop) {
  const map = {}

  for (let i = 0; i < array.length; i++) map[prop ? array[i][prop] : array[i]] = array[i]

  return map
}

/**
 * @param {object[]} o old array of objects
 * @param {object[]} n new array of objects
 * @param {function} comparator the function that compares both arrays
 * @param {string} oProp the property of the old array of objects to be mapped
 * @param {string} nProp the property of the new array objects to be mapped
 * obs: when oProp or nProp are set to null, it will assume that it is an array of primitives
 *
 * @return {object} An object with changes
 */
export function getDelta (o, n, comparator, oProp = 'id', nProp = 'id') {
  const delta = { added: [], deleted: [], changed: [] }
  const mapO = mapFromArray(o, oProp)
  const mapN = mapFromArray(n, nProp)

  Object.keys(mapO).forEach(id => {
    if (!mapN.hasOwnProperty(id)) delta.deleted.push(mapO[id])
    else if (!comparator(mapN[id], mapO[id])) delta.changed.push(mapN[id])
  })

  Object.keys(mapN).forEach(id => {
    if (!mapO.hasOwnProperty(id)) delta.added.push(mapN[id])
  })

  return delta
}

/**
 * Performs a deep comparison between two arrays to check whether
 * they're actually equal.
 *
 * @param {Array<any>} arrayOne - First array
 * @param {Array<any>} arrayTwo - Second array (duh)
 * @returns {Boolean} - Whether the two arrays are equal or not.
 */
export const isEqualArray = (arrayOne, arrayTwo) => {
  if (!Array.isArray(arrayOne) || !Array.isArray(arrayTwo)) return false
  if ((arrayOne || []).length !== (arrayTwo || []).length) return false

  // Checks not only the values of each item but also the order
  // of the arrays.
  return arrayOne.every((item, idx) => {
    if (is(item, 'Array')) return isEqualArray(item, arrayTwo[idx])
    if (is(item, 'Object')) return isEqualObject(item, arrayTwo[idx])
    return item === arrayTwo[idx]
  })
}

/**
 * Moves item at fromIndex to the one on toIndex, moving all other items to adjust
 * their positions
 * Example: arrayMove([ 1, 2, 3, 4 ], 1, 3) -> [ 1, 3, 4, 2 ]
 * @param {Array<any>} arr - Array to move
 * @param {Number} fromIndex
 * @param {Number} toIndex
 * @returns {Array<any>}
 */
export const arrayMove = (arr, fromIndex, toIndex) => {
  const newArr = [ ...arr ]
  const element = arr[fromIndex]
  newArr.splice(fromIndex, 1)
  newArr.splice(toIndex, 0, element)
  return newArr
}
