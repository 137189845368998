<template>
  <div :class="classes" />
</template>

<script>
/**
 * A very simple component meant to be used in bullet lists or notification badges.
 */
export default {
  name: 'CBullet',
  props: {
    /**
     * Changes the bg-color to our success color (usually green).
     */
    success: Boolean,
    /**
     * Changes the bg-color to our error color (usually red).
     */
    error: Boolean,
    /**
     * Changes the bg-color our alert color (usually yellow)
     */
    alert: Boolean,
    /**
     * Changes the bg-color our info color (usually blue)
     */
    info: Boolean,
    /**
     * Changes the bg-color our info color (usually blue)
     */
    hasBorder: Boolean
  },
  computed: {
    classes () {
      return [ 'c-bullet',
        {
          '-success': this.success,
          '-error': this.error,
          '-alert': this.alert,
          '-info': this.info,
          '-has-border': this.hasBorder
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.c-bullet {
  display: inline-block;
  height: 8px;
  width: 8px;
  min-height: 8px;
  min-width: 8px;
  border-radius: 100%;
  box-sizing: content-box;
  background: color-var(primary);

  &.-success {
    background: color-var(positive);
  }

  &.-error {
    background: color-var(negative);
  }

  &.-alert {
    background: color-var(alert);
  }

  &.-info {
    background: color-var(info);
  }

  &.-has-border {
    border: 2px solid #FBFCFB;
  }
}
</style>
