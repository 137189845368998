<template>
  <c-actions-popover
    class="c-form-popover"
    :target="target"
    @close="$emit('close')"
  >
    <header class="c-card-header">
      <div class="icon">
        <c-icon icon="info-information-circle" :size="16" />
      </div>

      <h4>
        <c-typo type="h4" opacity="base-50" weight="medium">{{ name }}</c-typo>
      </h4>

      <div class="actions">
        <c-button
          icon="delete-disabled"
          flat
          :size="30"
          @click.prevent="$emit('close')"
        />
      </div>
    </header>

    <div class="c-card-content">
      <c-typo opacity="base-80">{{ hint }}</c-typo>
    </div>
  </c-actions-popover>
</template>

<script>
import CActionsPopover from '../../CActionsPopover'

export default {
  name: 'FormPopoverHint',

  props: {
    target: {
      type: [ Object, Element ],
      required: true,
    },

    hint: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },
  },

  components: {
    CActionsPopover,
  },
}
</script>

<style lang="scss">
.c-form-popover {
  position: fixed;
  z-index: 99999;

  & > .c-popover > .c-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 20px 40px;

    & > .c-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;

      & > .icon {
        display: flex;
        align-items: center;

        & > .c-icon {
          @include icon-color(color-var(text, base-50));
        }
      }

      & > .actions {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>
