<template>
  <div class="admission-document-file" :class="classes">
    <div
      v-if="!(value || {}).name"
      class="empty"
      @click="onChange"
      @drop.prevent="onDragFile"
      @dragover.prevent.stop="dragging = true"
      @dragenter.prevent.stop="dragging = true"
      @dragleave.prevent.stop="dragging = false"
    >
      <span class="placeholder">
        {{ getPlaceholder }}
      </span>

      <span class="extensions">
        Nós aceitamos os arquivos que estão no formato {{ getExtensions }}
      </span>

      <transition name="fade">
        <form-field-validation
          v-if="error"
          class="error"
          message="Campo obrigatório"
        />
      </transition>
    </div>

    <c-file
      v-else
      :remove="true"
      v-bind="value"
      @replace="onChange"
      @click="$emit('click', $event)"
      @remove="onRemove"
    />
  </div>
</template>

<script>
import FormFieldValidation from '@convenia/components/CFormBuilder/fragments/FormFieldValidation'
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'DocumentFile',

  mixins: [ MediaQuery ],

  components: { FormFieldValidation },

  props: {
    value: {
      type: Object,
      default: undefined
    },

    placeholder: {
      type: String,
      default: undefined
    },

    extensions: {
      type: Array,
      default: () => ([])
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    error: false,
    dragging: false
  }),

  computed: {
    getPlaceholder () {
      if (this.placeholder) return this.placeholder

      return !this.isMobile ?
        'Arraste aqui o seu documento' :
        'Toque aqui e insira seu documento'
    },

    getExtensions () {
      return this.extensions
        .map(ext => ext.toUpperCase())
        .join(', ')
    },

    classes () {
      return {
        '-error': this.error,
        '-dragging': this.dragging,
        '-disabled': this.disabled
      }
    }
  },

  methods: {
    onRemove () {
      this.$emit('remove', this.value)
    },

    onChange () {
      this.$emit('change', this.value)
    },

    onDragFile ({ dataTransfer }) {
      this.$emit('drag', dataTransfer.files)
    },

    validate () {
      this.error = !(this.value || {}).name

      return !this.error
    }
  }
}
</script>

<style lang="scss">
.admission-document-file {
  & > .empty {
    position: relative;
    background: rgba(18,30,72,.02);
    border: 1px dashed rgba(18,30,72,.2);
    text-align: center;
    border-radius: 5px;
    padding: 10px;

    & > .placeholder {
      display: block;
      max-width: 290px;
      margin: 0 auto 5px;
      font-size: 14px;
      font-weight: 500;
      font-family: Ubuntu;
      color: rgba(#121E48, .8);
    }

    & > .extensions {
      display: block;
      max-width: 260px;
      margin: auto;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -.14px;
      color: rgba(#121E48, .4);
    }

    & > .error {
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      display: flex;
      align-items: center;
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 5px;
      width: 100%;
      justify-content: center;
      font-weight: 600;
      color: $negative-color;
      white-space: nowrap;
      -webkit-font-smoothing: antialiased;
    }
  }

  &.-error {
    & > .empty {
      margin-bottom: 40px;
    }
  }

  &.-dragging {
    & > .empty {
      border-color: #aa57f5;
      background: rgba(#aa57f5, .05);
    }
  }

  &.-disabled {
    & > .c-file {
      > .actions {
        opacity: 0;
      }
    }
  }

  @include responsive (tablet, desktop) {
    cursor: pointer;
  }
}
</style>
