<template>
  <div class="c-empty">
    <c-character
      v-if="character"
      :type="character"
      class="character"
      :size="isMobile ? 130 : 150"
    />

    <!-- @slot The default slot allows you to replace everything. -->
    <slot>
      <h2 class="title">{{ title }}</h2>

      <p
        :class="[ 'message', { '-not-last-item': $slots.content } ]"
        v-html="message"
      />

      <!-- @slot Content slot allows you to insert extra content alongside
           title, message and character.
      -->
      <slot name="content" />
    </slot>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

/**
 * A component used for empty-state.
 */
export default {
  name: 'CEmpty',

  mixins: [ MediaQuery ],

  props: {
    /**
     * The main title to show.
     */
    title: {
      type: String,
      default: '',
    },

    /**
     * The empty-state message.
    */
    message: {
      type: String,
      default: '',
    },

    /**
     * The illustration to show in the empty-state.
     */
    character: {
      type: String,
      default: null,
    },
  }
}
</script>

<style lang="scss">
.c-empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 50px 0;

  & > .title {
    margin: 30px 30px 0px;
    font-size: var(--title-font-size);
    -webkit-font-smoothing: antialiased;

    @include responsive (xs-mobile, mobile) { margin-top: 20px; }
  }

  & > .message {
    font-size: 14px;
    color: color-var(text, base-50);
    max-width: 434px;
    margin: 20px 30px 0px;

    &.-not-last-item {
      margin-bottom: 30px;

      @include responsive (xs-mobile, mobile) { margin-bottom: 20px; }
    }
  }
}
</style>
