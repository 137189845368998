<template>
  <div
    :class="classes"
    :style="{ '--select-padding': padding + 'px' }"
    @click="onClick"
  >
    <div class="selectable" :style="colorStyle">
      <div class="inactive-border" />

      <border v-if="!disabled" :selected="selected" />

      <icon
        :selected="false"
        :cross="cross"
      />

      <icon
        class="c-selectable-icon-active"
        :selected="true"
        :cross="cross"
      />

      <div class="shadow" />
    </div>

    <slot />
  </div>
</template>

<script>
import Border from './Border'
import Icon from './Icon'

export default {
  name: 'CSelectable',

  components: {
    Border,
    Icon
  },

  props: {
    /**
     * Sets the state of the selectable's checkbox.
     */
    selected: Boolean,

    /**
     * Disables the component's actions.
     */
    disabled: Boolean,

    /**
     * Use an X (cross) as the select icon
     */
    cross: Boolean,

    /**
     * Disables the click animation
     */
    disableAnimation: Boolean,

    /**
     * Determines the color to be used for the component, according to our
     * custom pallete
     */
    color: {
      type: String,
      default: 'positive',
      validator: v => [ 'primary', 'negative', 'alert', 'info', 'positive' ].includes(v)
    },

    /**
     * The position of the checkbox
     */
    position: {
      type: String,
      default: 'right',
      validator: v => [ 'left', 'right' ].includes(v)
    },

    /**
     * Internal padding of the element.
     */
    padding: {
      type: [ String, Number ],
      default: 0
    }
  },

  data: () => ({ hasAnimation: false }),

  computed: {
    classes () {
      return [ 'c-selectable', {
        '-left': this.position === 'left',
        '-right': this.position === 'right',
        '-has-slot': !!this.$slots.default,
        '-is-active': this.selected,
        '-is-inactive': !this.selected,
        '-disabled': this.disabled,
        '-has-animation': !this.disableAnimation && this.hasAnimation
      } ]
    },

    colorStyle () {
      return { '--selectable-color': this.$css.get(`--color-${this.color || 'positive'}-base`) }
    }
  },

  methods: {
    onClick () {
      this.hasAnimation = true
      /**
       * Emitted when the user clicks the element or it's children.
       * @event
       */
      this.$emit('toggle', this.selected)
      setTimeout(() => { this.hasAnimation = false }, 300)
    }
  }
}
</script>

<style lang="scss">
.c-selectable {
  --icon-size: 25px;
  @include responsive (xs-mobile, mobile) {
    --icon-size: 20px;
    height: var(--icon-size);
  }

  position: relative;
  display: inline-block;
  cursor: pointer;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > .selectable {
    position: relative;
    width: var(--icon-size);
    height: var(--icon-size);
    border-radius: 50%;
    flex-shrink: 0;

    & > .inactive-border {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-sizing: border-box;
      border-style: solid;
      border-width: 1px;
      border-color: color-var(text, base-10);
      transition: opacity .3s;
    }

    & > .c-selectable-border, & > .c-selectable-icon { transition: opacity .3s; }
  }

  &.-is-inactive {
    & > .selectable {
      background: rgba(#FFF, .5);

      & > .c-selectable-icon-active {
        opacity: 1;
        border-radius: 50%;
        background: var(--selectable-color);
      }

      & > .c-selectable-border,
      & > .c-selectable-icon,
      & > .c-selectable-icon-active { opacity: 0; }
    }

    &.-has-animation > .selectable > .c-selectable-icon-active { animation: bounce-inactive .2s; }

    @include responsive (tablet, desktop) {
      &:hover > .selectable {
        & > .c-selectable-border, & > .c-selectable-icon { opacity: 1; }
        & > .c-selectable-icon-active { display: none;}
      }
    }
  }

  &.-is-active > .selectable {
    background-repeat: no-repeat;
    background: var(--selectable-color);

    & > .c-selectable-border,
    & > .inactive-border { opacity: 0; }
    & > .c-selectable-icon-active, & > .c-selectable-border { opacity: 1; }
  }

  &.-has-animation.-is-active > .selectable {
    & > .c-selectable-icon-active, & > .c-selectable-border { animation: bounce-active .3s; }
  }

  &:not(.-has-slot) {
    min-width: var(--icon-size);
    min-height: var(--icon-size);
    display: inline-block;
  }

  &.-has-slot {
    &.-left > .selectable { order: 0; }
    &.-right > .selectable { order: 1; }
  }

  &.-disabled {
    cursor: default;
    pointer-events: none;
  }

  &.-is-active:not(.-disabled) > .selectable > .shadow {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    width: 75%;
    height: 75%;
    border-radius: 50px;
    background: var(--selectable-color);
    transform: translateX(-50%);
    z-index: -$z-index-1;
    opacity: 0.5;
    filter: blur(7px);
  }
}

.c-selectable.-disabled {
  & > .selectable {
    user-select: none;
    pointer-events: none;

    background: color-var(text, base-10);
    border-color: transparent;

    & > .inactive-border { border: unset; }
  }

  &.-is-active > .selectable {
    & > .c-selectable-icon { display: none; }

    & > .c-selectable-icon-active {
      fill: color-var(text);
      opacity: .3;
      display: initial;
    }
  }
}

@keyframes bounce-active {
  0% { transform: scale(1.5); }
  100% { transform: scale(1); }
}

@keyframes bounce-inactive {
  0% { transform: scale(1); opacity: 1; }
  100% { transform: scale(1.7); opacity: 0; }
}
</style>
