<template>
  <div class="c-auto-complete">
    <c-modal
      v-if="isMobile && hasFocus"
      no-close
      is-opened
      no-header
      fullscreen
      :has-transition="false"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <div class="auto-complete-wrapper">
        <c-button
          flat
          type="button"
          icon="arrow-left"
          @click.native="onClose"
        />

        <auto-complete
          :options="options"
          :selected="selected"
          :has-focus="hasFocus"
          :show-list="hasFocus && isMobile"
          :style="{ 'z-index': hasFocus ? 30 : 10 }"

          v-bind="$attrs"
          @input="onInput"
          @select="onSelect"
          @toggle-focus="toggleFocus"

          @autocomplete:items="items => selectedItems = items"
          v-on="inputListeners"
        >
          <slot
            slot="before"
            slot-scope="{ item }"
            name="before"
            :item="item"
          />

          <slot
            slot="after"
            slot-scope="{ item }"
            name="after"
            :item="item"
          />
        </auto-complete>
      </div>

      <c-empty
        v-show="!selectedItems.length"
        character="lou"
        class="empty-state"
        :title="emptyTitle"
        :message="emptyMessage"
      />
    </c-modal>

    <auto-complete
      v-else
      :options="options"
      :selected="selected"
      :loading="loading"
      :has-focus="hasFocus"
      :alternative-focus="alternativeFocus"
      v-bind="$attrs"
      @input="onInput"
      @select="onSelect"
      @toggle-focus="toggleFocus"
      v-on="inputListeners"
    >
      <slot slot="before" slot-scope="{ item }" name="before" :item="item" />

      <slot slot="after" slot-scope="{ item }" name="after" :item="item" />
    </auto-complete>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import AutoComplete from './fragments/AutoComplete'

export default {
  name: 'CAutoComplete',

  mixins: [ MediaQuery ],

  components: { AutoComplete },

  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },

    options: {
      type: Array,
      default: () => []
    },

    emptyTitle: {
      type: String,
      default: 'O que está procurando?'
    },

    emptyMessage: {
      type: String,
      default: 'Vou te ajudar a encontrar a informação que você precisa sobre este colaborador.'
    },

    alternativeFocus: Boolean,

    loading: Boolean
  },

  data: () => ({ hasFocus: false, selectedItems: [] }),

  computed: {
    inputListeners () {
      // We have to remove the parent's select event listener
      // here to avoid binding it twice

      const { select, ...listeners } = this.$listeners
      return listeners
    }
  },

  methods: {
    toggleFocus (focused) {
      if (this.isMobile) {
        this.hasFocus = true
        return
      }

      this.hasFocus = focused
    },

    onClose () {
      this.$emit('close')
      this.hasFocus = false
    },

    onInput (value) {
      this.hasFocus = true
      this.$emit('input', value)
    },

    onSelect (item) {
      this.hasFocus = false
      this.$emit('select', item)
    }
  }
}
</script>

<style lang="scss">
.c-auto-complete {
  & > .c-modal > .wrapper > .modal {
    & > .header { display: none; }

    & > .content {
      position: relative;

      display: flex;
      flex-direction: column;

      width: 100%;
      height: 100%;

      padding: 10px;
      background: white;

      & > .auto-complete-wrapper {
        display: flex;

        &::after {
          content: '';

          position: absolute;
          top: 60px;
          left: 10px;
          right: 10px;

          height: 1px;
          background-color: var(--base-border-color);
        }

        & > .c-auto-complete-input {
          width: 100%;
          margin-left: 10px;

          position: unset;
          box-shadow: unset;

          & > .search-wrapper > .search {
            &::after { content: unset; }

            & > .input { background: color-var(text, base-05); }
          }

          & > .c-auto-complete-list-wrapper {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 70px;

            width: unset;
            max-height: unset;
            box-shadow: unset;
            border-radius: unset;
          }
        }
      }

      & > .empty-state {
        padding: 60px 0;
        position: relative;
        z-index: var(--z-index-3);
      }
    }
  }
}
</style>
