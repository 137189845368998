<template>
  <div class="c-form-group">
    <div
      v-for="(form, key) in schemas"
      class="form"
      :class="{ '-has-files': form.files }"
      :key="key"
    >
      <c-title v-if="form.title" dark-text>
        {{ form.title }}
      </c-title>

      <template v-if="!form.multiple">
        <c-form-builder
          ref="forms"
          sync-data
          :no-listeners="!formListeners"
          :key="key"
          :name="key"
          :label-left="labelLeft"
          :schema="schemas[key].fields"
          :fields-options="options[key]"
          :disabled="disabled"
          :required-fields="requiredFields"
          :value="formsData[key]"
          @input="onSingleInput($event, key)"
          @sync:form-data="$emit(`sync:${key}`, $event)"
        />

        <form-files
          v-if="form.files"
          ref="attachments"
          class="files"
          :required="requiredFields.includes('files')"
          :files="formsData[key].files"
          :text="form.filesText"
          :schema="form.fields.files"
          @add="$emit('file:add')"
          @remove="$emit('file:remove', $event)"
        />

        <c-divider v-if="schemas[key].divider" />
      </template>

      <div v-else class="multiple">
        <template v-for="(value, index) in data[key]">
          <c-info-header
            actions-size="sm"
            title-component="c-employee-info"
            :key="`header-${(value || {}).id || index}`"
            :remove="index > 0 && !disabled"
            :icon="form.singleIcon"
            :title="`${index + 1}° ${form.singleTitle}`"
            @remove="$emit(`remove:${key}`, (value || {}).id || index)"
          />

          <c-radio-button
            v-if="form.required === false && index === 0"
            required
            class="form-toggle"
            trackBy="value"
            displayBy="name"
            :key="`button-${index}`"
            :label="`Possui ${form.singleTitle}?`"
            :label-left="!isMobile"
            :value="isFormActive(key)"
            :options="[
              { name: 'Sim', value: true },
              { name: 'Não', value: false },
            ]"
            @input="onFormToggle(key, $event)"
          />

          <c-form-builder
            ref="forms"
            :no-listeners="!formListeners"
            :key="(value || {}).id || index"
            :name="key"
            :label-left="labelLeft"
            :schema="schemas[key].fields"
            :fields-options="options[key]"
            :disabled="!isFormActive(key) || disabled"
            :required-fields="requiredFields"
            v-model="formsData[key][index]"
          />

          <form-files
            v-if="schemas[key].files"
            ref="attachments"
            class="files"
            :required="requiredFields.includes('files')"
            :key="`files-${index}`"
            :files="formsData[key][index].files"
            :text="schemas[key].filesText"
            :schema="schemas[key].fields.files"
            @add="$emit('file:add', index)"
            @remove="$emit('file:remove', { index, file: $event })"
          />

          <c-divider
            v-if="(index < data[key].length - 1) || schemas[key].files"
            :key="`divider-${index}`"
          />
        </template>

        <c-add
          :text="form.addLabel || `Adicionar ${form.singleTitle}`"
          :squared="isMobile"
          :disabled="!isFormActive(key) || disabled"
          @add="$emit(`add:${key}`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import FormFiles from './FormFiles'

export default {
  name: 'CFormGroup',

  mixins: [ MediaQuery ],

  components: {
    FormFiles
  },

  props: {
    schemas: {
      type: Object,
      default: () => ({})
    },

    data: {
      type: Object,
      default: () => ({})
    },

    options: {
      type: Object,
      default: () => ({})
    },

    labelLeft: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    disabledForms: {
      type: Array,
      default: () => ([])
    },

    requiredFields: {
      type: Array,
      default: () => ([])
    },

    divider: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    classes () {
      return {
        '-has-files': this.files
      }
    }
  },

  data: () => ({
    formsData: {},
    formListeners: false,
    activeForms: []
  }),

  created () {
    // Set forms to active by default
    this.activeForms = Object.keys(this.schemas)
      .filter(key => !this.disabledForms.includes(key))

    const singleReducer = (acc, key) => ({ ...acc, [key]: this.data[key] })

    this.formsData = Object.keys(this.data)
      .reduce(singleReducer, {})
  },

  methods: {
    onFormToggle (key, value) {
      const index = this.activeForms.indexOf(key)

      if (value) this.activeForms.push(key)
      if (!value && index !== -1) this.activeForms.splice(index, 1)
    },

    isFormActive (key) {
      return this.activeForms.includes(key)
    },

    async validate () {
      const { forms } = this.$refs
      const attachments = this.$refs.attachments || []

      for (let i = 0; i < forms.length; i++) {
        let valid = true
        const form = forms[i]

        // eslint-disable-next-line
        if (!await this.validateForm(form))
          valid = false

        if (attachments[i]?.validate() === false)
          valid = false

        if (!valid) return false
      }

      return true
    },

    async validateForm (form) {
      form.hasValidation = true

      const isValid = await form.$refs.veeObserver.validate()

      if (!isValid) form.focusFirstError()

      return isValid
    },

    submit () {
      this.$emit('submit', this.formsData)
    },

    onSingleInput (newValue, key) {
      this.formsData[key] = newValue
      this.$emit(`sync:${key}`, this.formsData[key])
    }
  }
}
</script>

<style lang="scss">
.c-form-group {
  padding-bottom: 50px;

  .c-form-builder {
    margin-bottom: 10px;

    & > .c-shadowed, & > .c-shadowed > .wrapper { overflow: initial; }
    & > .actions { display: none; }
    .c-form-field.-label-left {
      grid-template-columns: 200px 360px;

      & > .input-wrapper > .hint {
        @include tablet { display: none; }
      }
    }
  }

  & > .form {
    & > .c-title { padding: 20px; padding-top: 0; }
    &:first-child > .c-title { padding-top: 20px; }

    & > .c-form-builder,
    & > .multiple > .c-form-builder {
      position: relative;

      & > .c-shadowed,
      & > .c-shadowed > .wrapper { overflow: initial; min-height: initial; }
      & > .actions { display: none }
      & > .c-shadowed {
        &:before, &:after { content: none; }
      }
    }
  }

  @include responsive (tablet, desktop) {
    & > .form {
      & > .multiple {
        & > .c-shadowed > .wrapper > .fields > .-label-left > .label > .text {
          white-space: nowrap;
        }
        & > .c-shadowed > .wrapper > .fields > * > .hint {
          width: 300px;
        }
        & > .c-info-header {
          max-width: 540px;
          margin-left: 30px;
          margin-bottom: 20px;
        }
        & > .form-toggle {
          margin-left: 210px;
          margin-bottom: 20px;
          & > .label { align-items: flex-start; }
        }
        & > .c-add {
          height: 100px;
          max-width: 540px;
          margin-top: 30px;
          margin-left: 30px;
        }
      }

      & > .multiple > .files { max-width: 540px; margin-left: 30px; }
      & > .files { max-width: 550px; margin-left: 20px; }

      & > .multiple > .c-divider { margin: 40px 30px; max-width: 530px; }
      & > .c-divider { margin: 30px 20px; max-width: 550px; }

      &.-has-files {
        & > .multiple > .c-divider { margin: 40px 30px; max-width: 540px; }
        & > .c-divider { margin: 40px 20px; max-width: 550px; }
      }
    }
  }

  @include responsive (xs-mobile, mobile) {
    & > .form {
      .admission-form-files { margin: 0px 20px; max-width: 400px; }
      .c-divider { margin: 30px 20px; max-width: 400px; }

      & > .multiple {
        & > .c-info-header {
          width: auto;
          margin-left: 20px;
          margin-right: 20px;
          max-width: 360px;
        }

        & > .form-toggle {
          display: block;
          margin-left: 20px;
          &:before {
            content: "";
            display: block;
            margin-top: 40px;
          }
        }

        & > .c-form-builder {
          margin-bottom: 30px;
        }

        & > .c-add {
          max-width: 400px;
          margin: 10px 20px 20px;
          width: auto;

          & > .c-button { padding: 0; }
        }
      }
    }
  }
}
</style>
