<template>
  <div
    ref="progressBar"
    :class="classes"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <span
      :class="customBar"
      :style="{ width: `${percent}%` }"
    />

    <span v-if="showPercent" class="percent">
      {{ percent }}%
    </span>

    <span
      v-if="average"
      ref="averageTarget"
      :class="[ 'average', { '-error': error } ]"
      :style="{ left: `${averagePercent}%` }"
      @mouseenter="showTooltip = true"
      @mouseleave="showTooltip = false"
    />

    <c-tooltip
      :show="showTooltipType"
      dark
      position="top"
      :target="$refs[ (average && hasAverageTooltip) ? 'averageTarget' : 'progressBar' ]"
    >
      <slot />
    </c-tooltip>
  </div>
</template>

<script>
export default {
  name: 'CIndicativeProgress',

  props: {
    /**
     * Enables the tooltip option for the average
     */
    hasAverageTooltip: Boolean,

    /**
     * Enables the tooltip option for bar
     */
    hasBarTooltip: Boolean,

    /**
    * Current value of progress
    */
    value: {
      type: [ Number, String ],
      default: 0
    },

    /**
    * Current value of progress
    */
    average: {
      type: [ Number, String ],
      default: 0
    },

    maxProgress: {
      type: [ Number, String ],
      default: 100
    },

    /**
    * Indicates whether the value should be on alongside
    */
    inline: {
      type: Boolean,
      default: false
    },

    showPercent: {
      type: Boolean,
      default: false
    },

    /**
     * Uses error color for the background.
     */
    error: Boolean,

    /**
     * Uses alert color for the background.
     */
    alert: Boolean,

    /**
     * Uses info color for the background.
     */
    info: Boolean,

    showInTooltip: Boolean
  },

  data: () => ({
    showTooltip: false
  }),

  computed: {
    classes () {
      return [ 'c-indicative-progress',
        {
          '-inline': this.inline,
          '-in-tooltip': this.showInTooltip
        }
      ]
    },

    customBar () {
      return [ 'bar',
        {
          '-error': this.error,
          '-alert': this.alert,
          '-info': this.info,
        }
      ]
    },

    percent () {
      return (this.value / this.maxProgress) * 100
    },

    averagePercent () {
      return (this.average / this.maxProgress) * 100
    },

    showTooltipType () {
      return (this.hasAverageTooltip || this.hasBarTooltip) && this.showTooltip
    }
  }
}
</script>

<style lang="scss">
.c-indicative-progress {
  position: relative;
  display: flex;
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background-color: color-var(text, base-10);
  overflow: hidden;

  &.-in-tooltip {
    background-color: rgb(230, 228, 228, 0.1);
  }

  & > .bar {
    background: color-var(positive);
    transition: width .3s ease-out;
    box-shadow: 0 0 15px -2px rgba(#37CFCC, .5);
    border-radius: 10px;

    &.-error {
      background: color-var(negative);
      box-shadow: 0 4px 20px 0 rgba(color-var(negative, base-rgb), .3);
    }
    &.-alert {
      background: color-var(alert);
      box-shadow: 0 4px 20px 0 rgba(color-var(alert, base-rgb), .3);
    }
    &.-info {
      background: color-var(info);
      box-shadow: 0 4px 20px 0 rgba(color-var(info, base-rgb), .3);
    }
  }

  & > .percent {
    font-size: 12px;
    line-height: 29px;
    color: color-var(text, base-80);
    position: absolute;
    top: 100%;
    right: 0;
    color: color-var(text, base-30);
    -webkit-font-smoothing: antialiased;
  }

  & > .average {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: color-var(text, base-10);
    border-radius: 50%;
    transform: translateX(-100%);

    &.-error {
      background-color: color-var(text, base-20);
    }
  }

  &.-inline {
    width: calc(100% - 40px);

    & > .percent {
      top: 50%;
      left: 100%;
      transform: translate(10px, -50%);
    }
  }
}
</style>
