// eslint-disable-next-line import/no-cycle
import { isEqualArray } from './array'
import { is } from './is'

/**
 * Compares two objects to check if they are equal, by default it will
 * also check if both of them contain the exact same keys.
 *
 * @param {Object} obj1 - First object
 * @param {Object} obj2 - Second object
 * @param {Object} options - Function options, for now you can disable
 * key parity checking
 * @returns {Boolean} - Whether the two objects are equal or not.
 */
export const isEqualObject = (obj1, obj2, options = { ignoreKeys: false }) => {
  if (!is(obj1, 'Object') || !is(obj2, 'Object')) return false

  const sameKeys = options.ignoreKeys
    || (Object.keys(obj1).length === Object.keys(obj2).length
    && Object.keys(obj1).every(key => Object.keys(obj2).includes(key)))

  const sameValues = Object.entries(obj1).every(([ key, value ]) => {
    if (is(value, 'Array')) return isEqualArray(value, obj2[key])
    if (is(value, 'Object')) return isEqualObject(value, obj2[key])
    return value === obj2[key]
  })

  return sameKeys && sameValues
}

export const length = data => Object.keys(data).length

export const isObject = data => typeof data === 'object'

export const isEmptyObject = obj => !Object.keys(obj).length

export const isSameLength = (obj1, obj2) => length(obj1) === length(obj2)

export const hasOwnProperty = (obj1, obj2, key) => {
  return obj1.hasOwnProperty(key) === obj2.hasOwnProperty(key)
}

export const equal = (key) => (obj1, obj2) => {
  if (!hasOwnProperty(obj1, obj2, key)) return false

  return isObject(obj1[key])
    ? isEqualObject(obj1[key], obj2[key])
    : obj1[key] === obj2[key]
}

export const equals = (obj1, obj2) => {
  if (!isObject(obj1) || !isObject(obj2)) return false
  if (!isSameLength(obj1, obj2)) return false

  return Object.keys(obj1).every(equal(obj1, obj2))
}

export const clearRefs = obj => JSON.parse(JSON.stringify(obj))

/**
 * Returns a copy of the object param, filtered to only include
 * the values for the allowed keys, given as the second argument
 * as an array of strings.
 *
 * @param {Object} object - The respective object
 * @param {Array<String>} keys - The list of keys to be included in the final object
 * @returns {Object} - The final object containing only the allowed keys.
 */
export const pick = (object = {}, keys = []) => {
  return Object.entries(object).reduce((acc, [ key, value ]) => ({
    ...acc,
    ...(keys.includes(key) ? { [key]: value } : {})
  }), {})
}

/**
 * Checks wether a given object contains all of the keys
 * in the 'keys' array param
 *
 * @param {Object} object - The object to verify
 * @param {Array<string>} keys - The list of keys that must
 * be present in the object
 * @returns {Boolean} - True if all of the given keys are
 * present in the object, false otherwise.
 */
export const hasKeys = (object = {}, keys = []) => (keys || [])
  .every(key => Object.keys(object || {}).includes(key))

/**
* Returns a copy of the object param, filtered to not include
* the values for the given keys, given as the second argument
* as an array of strings.
*
* @param {Object} object - The respective object
* @param {Array<String>} keys - The list of keys to be excluded in the final object
* @returns {Object} - The final object containing only the allowed keys.
*/
export const exclude = (object = {}, keys = []) => {
  return Object.entries(object).reduce((acc, [ key, value ]) => ({
    ...acc,
    ...(keys.includes(key) ? {} : { [key]: value })
  }), {})
}

/**
 * Sets an given object's nested property specified by an
 * property path array or string delimited by dot ('.')
 * characters to a given value.
 *
 * Ex:
 *   myObj = { deepObj: { a: 4 } }
 *   set(myObj, 'deepObj.a', 5)
 *   // The next line should output: { deepObj: { a: 5 } }
 *   console.log(myObj)
 *
 * @param {object} obj - The object to be mutated
 * @param {Array<string>|string} propPath -
 * @param {any} value - The value to assign to the property
 * @returns {object} - The provided object with the property
 * mutated
 */
export const setObjProp = (obj = {}, propPath = [], value) => {
  const keys = Array.isArray(propPath)
    ? propPath
    : propPath.split('.')

  const lastKey = keys.pop()
  let currentObj = obj || {}

  keys.forEach(key => {
    if (!Object.hasOwn(currentObj, key))
      currentObj[key] = {}

    currentObj = currentObj[key]
  })

  currentObj[lastKey] = value
  return obj
}

/**
 * Compares two values for equality, including nested objects.
 *
 * @param {*} value1 - The first value to compare.
 * @param {*} value2 - The second value to compare.
 * @returns {boolean} - True if the values are equal, false otherwise.
 */
export const objectIsEqual = (value1, value2) => {
  if (typeof value1 !== 'object' || typeof value2 !== 'object' || value1 === null || value2 === null) {
    return value1 === value2;
  }

  const keys1 = Object.keys(value1)
  const keys2 = Object.keys(value2)

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every(key => objectIsEqual(value1[key], value2[key]))
}

/**
 * Finds the differences between two objects and returns an object
 * containing only the values that have changed from the first object
 * to the second object.
 *
 * @param {object} fromObject - The initial object
 * @param {object} toObject - The object to compare against
 * @returns {object} - An object containing only the values that have changed
 * from `fromObject` to `toObject`, preserving the structure of `toObject`
 */
export const objectDiffValues = (fromObject, toObject) => {
  const walk = (fromObj, toObj) => {
    const nestedChanges = {}

    Object.keys(toObj).forEach(key => {
      if (fromObj[key] !== null && typeof fromObj[key] === 'object' && toObj[key] !== null && typeof toObj[key] === 'object') {
        const innerChanges = walk(fromObj[key], toObj[key])

        if (Object.keys(innerChanges).length > 0) {
          nestedChanges[key] = innerChanges
        }
      } else if (!objectIsEqual(fromObj[key], toObj[key])) {
        nestedChanges[key] = toObj[key]
      }
    })

    return nestedChanges
  }

  return walk(fromObject, toObject)
}
