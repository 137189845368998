<template>
  <div class="dependents-container">
    <transition name="view" mode="out-in">
      <c-loader v-if="loading" :size="isMobile ? 79 : 99" />

      <form-group
        v-else
        ref="formGroup"
        :label-left="!isMobile"
        :schemas="schemas"
        :data="data"
        :options="options"
        :disabled="submiting"
        :disabled-forms="disabledForms"
        :required-fields="requiredFields"
        @add:dependent="onAddDependent"
        @remove:dependent="onRemoveDependent"
        @file:add="onFileModalOpen"
        @file:remove="onRemoveFile"
      />
    </transition>

    <c-form-builder-modal
      v-if="showFileModal"
      label-left
      submit-button-text="Salvar"
      name="dependent"
      title="Anexar Arquivos"
      v-model="filesFormData"
      :required-fields="requiredFields"
      :is-loading="submiting"
      :is-disabled="submiting"
      :schema="filesSchema"
      @close="onFileModalClose"
      @submit="onFileModalSubmit"
    />

    <dialog-modal
      v-if="removing"
      theme="error"
      title="Deseja excluir esse Dependente?"
      message="As informações inseridas serão excluídas do formulário.
      Tem certeza que deseja excluir este registro?"
      @cancel="onRemoveDependentCancel"
      @confirm="onRemoveDependentSubmit"
    />

    <dialog-modal
      v-if="showRemoveFileModal"
      theme="error"
      title="Deseja prosseguir?"
      message="Ao prosseguir os arquivos apagados serão permanentemente excluídos,
        esta ação não pode ser desfeita."
      confirm="Excluir"
      cancel="Não, mudei de ideia"
      @cancel="showRemoveFileModal = false"
      @confirm="onFileModalSubmit(filesFormData)"
    />

    <dialog-modal
      v-if="showEmptyNameAlert"
      theme="primary"
      title="Nome do dependente obrigatório"
      message="Antes de inserir um documento, é necessário especificar o nome do dependente."
      confirm="Confirmar"
      character="warning"
      :show-cancel-button="false"
      @confirm="showEmptyNameAlert = false"
    />

    <dialog-modal
      v-if="isRemovingFile"
      theme="error"
      title="Deseja excluir o arquivo selecionado?"
      message="O arquivo será permanentemente excluído, esta ação não pode ser desfeita."
      confirm="Excluir"
      cancel="Não, mudei de ideia"
      :loading="submiting"
      @cancel="isRemovingFile = false"
      @confirm="onRemoveFileSubmit"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import * as types from '@types'
import upload from '@modules/upload'

import { MediaQuery } from '@convenia/mixins'
import FormGroup from '@components/FormGroup'
import DialogModal from '@components/DialogModal'
import dependentSchemas from '@forms/dependent'

export default {
  name: 'DependentsStepper',

  mixins: [ MediaQuery ],

  components: {
    FormGroup,
    DialogModal
  },

  computed: {
    ...mapState({
      dependents: state => state.dependent.dependents,
      options: state => state.dependent.options,
      customFields: state => state.dependent.customFields,
      employeeId: state => state.admission.employeeUuid,
    }),

    ...mapGetters({
      getRequiredFields: types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS
    }),

    data () {
      return {
        dependent: this.dependents
      }
    },

    schemas () {
      const { dependent } = dependentSchemas
      const { ir, familySalary, foreigner, ...fields } = dependent.fields

      return {
        dependent: {
          ...dependent,
          fields: {
            ...fields,
            ...this.customFields?.dependent.schema,
            ir,
            familySalary,
            foreigner,
            files: {
              ...fields.files,
              hide: true
            }
          }
        }
      }
    },

    filesSchema () {
      const { dependent } = dependentSchemas
      const { files } = dependent.fields

      return { files }
    },

    selected () {
      if (!this.active) return {}

      return this.dependents.find(({ id }) => id === this.active)
    },

    submiting: {
      get () { return this.$store.state.admission.submiting },
      set (value) { this.$store.commit(types.ADMISSION_SUBMITING, value) }
    },

    disabledForms () {
      return this.$store.getters.hasDependent ? [] : [ 'dependent' ]
    },

    requiredFields () {
      return this.getRequiredFields('dependent')
        .map(key => {
          if (key === 'birth_date') return 'birthDate'

          return key
        })
    }
  },

  data: () => ({
    loading: true,
    removing: false,
    active: null,
    showFileModal: false,
    showRemoveFileModal: false,
    showEmptyNameAlert: false,
    isRemovingFile: false,
    removingFile: null,
    filesFormData: {},
  }),

  mounted () {
    this.$root.$on('next', this.onSubmit)
    this.$root.$on('previous', this.onSave)
    this.init()
  },

  beforeDestroy () {
    this.$root.$off('next', this.onSubmit)
    this.$root.$off('previous', this.onSave)
  },

  methods: {
    ...mapMutations({
      addDependent: types.EMPLOYEE_ADD_DEPENDENT,
      saveDependents: types.EMPLOYEE_SET_DEPENDENTS,
    }),

    ...mapActions({
      getCustomFields: types.EMPLOYEE_DEPENDENT_CUSTOM_FIELDS,
      getOptions: types.DEPENDENT_RELATIONS_OPTIONS,
      getDependents: types.EMPLOYEE_DEPENDENTS,
      setDependent: types.EMPLOYEE_SET_DEPENDENT,
      setDependents: types.EMPLOYEE_SET_DEPENDENTS,
      deleteDependent: types.EMPLOYEE_DELETE_DEPENDENT,
      deleteFile: types.ADMISSION_DELETE_FILE,
      getRequiredSystemFields: types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS,
    }),

    async init () {
      await this.getDependents()
      await this.getOptions()
      await this.getCustomFields()

      this.loading = false
    },

    onAddDependent () {
      this.addDependent()
      this.$refs.formGroup.formsData.dependent.push(
        this.data.dependent[this.data.dependent.length - 1]
      )
    },

    onRemoveDependent (id) {
      this.active = id

      const data = this.$refs.formGroup.formsData
      const dependent = data.dependent.find(({ id }) => id === this.active)
      const hasContent = Object
        .entries(dependent)
        .filter(([ key ]) => key !== 'id')
        .some(([ , value ]) => !!value)

      if (!hasContent)
        return this.onRemoveDependentSubmit()

      this.removing = true
    },

    onRemoveDependentCancel () {
      this.active = null
      this.removing = false
    },

    async onRemoveDependentSubmit () {
      this.submiting = true
      const [ err ] = await this.deleteDependent(this.selected)
      this.submiting = false

      if (err)
        return this.$feedback.add({
          type: 'error',
          message: 'Erro',
          highlighted: 'ao tentar excluir Dependente. Tente novamente.'
        })

      this.$feedback.add({
        type: 'success',
        message: 'Dependente',
        highlighted: 'excluído com sucesso.'
      })

      this.active = null
      this.removing = false
    },

    async validate () {
      await this.getRequiredSystemFields()

      if (!this.$refs.formGroup)
        return false

      if (!await this.$refs.formGroup.validate())
        return false

      return true
    },

    async onSubmit () {
      if (!await this.validate()) return

      const data = this.$refs.formGroup.formsData
      const hasDependents = this.$refs.formGroup.isFormActive('dependent')

      this.submiting = true

      if (hasDependents) {
        // If employee has dependents update them
        const [ err ] = await this.setDependents([
          ...data.dependent.map(dependent => ({
            ...dependent,
            customFields: Object.keys(this.customFields.dependent.schema)
          }))
        ])

        if (err)
          return this.$feedback.add({
            type: 'error',
            message: 'Erro',
            highlighted: 'ao tentar salvar Dependentes. Tente novamente.'
          })
      } else {
        // Otherwise delete all existing dependents
        this.loading = true
        await Promise.all((this.dependents || []).map(dep =>
          this.deleteDependent(dep)))

        this.loading = false
      }

      this.$feedback.add({
        type: 'success',
        message: 'Dependentes',
        highlighted: 'atualizados com sucesso'
      })

      this.submiting = false
      this.$emit('next')
    },

    onSave () {
      if (!this.$refs.formGroup) return
      const data = this.$refs.formGroup.formsData

      if (data.dependent.length)
        this.saveDependents(data.dependent)

      this.$emit('previous')
    },

    async uploadFiles (files, endpoint) {
      const uploads = await upload({ files, endpoint })

      return Promise.all(uploads.map(({ request }) => request))
    },

    async deleteFiles (files) {
      const { employeeId } = this

      await Promise.all(files.map(
        file => this.deleteFile({ file, employeeId }))
      )
    },

    onFileModalOpen (index) {
      const data = this.$refs.formGroup?.formsData.dependent[index]

      if (!data) return

      if (!data.fullName) {
        this.showEmptyNameAlert = true
        return
      }

      this.active = index

      this.filesFormData.files = [ ...data.files || [] ]
      this.showFileModal = true
    },

    onFileModalClose () {
      this.filesFormData = {}
      this.showFileModal = false
      this.active = null
    },

    onRemoveFile ({ file, index }) {
      this.isRemovingFile = true
      this.active = index
      this.removingFile = file
    },

    async onRemoveFileSubmit () {
      const { employeeId } = this

      this.submiting = true

      const [ err ] = await this.deleteFile({
        employeeId,
        file: this.removingFile
      })

      this.submiting = false

      if (err)
        return this.$feedback.add({
          type: 'error',
          message: 'Arquivo',
          highlighted: 'não foi excluído, tente novamente'
        })

      this.$feedback.add({
        type: 'success',
        message: 'Arquivo',
        highlighted: 'excluído com sucesso'
      })

      const data = this.$refs.formGroup?.formsData.dependent[this.active] || {}
      const fileIndex = data.files?.findIndex(file => file.id === this.removingFile.id)

      if (fileIndex >= 0)
        data.files.splice(fileIndex, 1)

      this.isRemovingFile = false
    },

    async onFileModalSubmit ({ files }) {
      const customFields = Object.keys(this.customFields.dependent?.schema || {})
      const data = this.$refs.formGroup?.formsData.dependent[this.active] || {}
      const { id } = this.dependents[this.active] || {}
      const { files: initialFiles = [] } = data

      const addedFiles = files
        .filter(file => !file.done)
        .filter(file => !initialFiles.includes(file))

      const removedFiles = initialFiles
        .filter(file => !files.includes(file))

      if (removedFiles.length > 0 && !this.showRemoveFileModal) {
        this.filesFormData = { files }
        this.showRemoveFileModal = true

        return
      }

      this.showRemoveFileModal = false
      this.submiting = true

      const [ err, dependent, endpoint ] = await this.setDependent({
        id,
        ...data,
        customFields,
      })

      if (err) {
        this.submiting = false
        return
      }

      await this.deleteFiles(removedFiles)

      this.uploadFiles(addedFiles, endpoint)
        .then(async () => {
          const updatedFiles = [ ...initialFiles, ...addedFiles ]
            .filter(file => !removedFiles.includes(file))
            .map(file => ({ ...file, progress: 0 }))

          data.id = dependent.id
          data.files = updatedFiles

          this.filesFormData = {}
          this.showFileModal = false
          this.submiting = false
          this.active = null
        })
        .catch(() => {
          this.submiting = false
        })
    },
  }
}
</script>

<style lang="scss">
.dependents-container {
  position: relative;

  @include responsive (tablet, desktop) {
    margin-top: 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 2px 8px 16px 0px rgba(0, 0, 0, 0.06);
  }

  & > .c-form-group .c-form-field {
    &[field-name="ir"] { margin-top: 40px; }
  }

  @include responsive (xs-mobile, mobile) {
    & > .c-form-group {
      & > .form:first-child {
        padding-top: 30px;
        & > .c-title { display: none; }
      }
    }
  }
}
</style>
