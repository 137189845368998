import * as get from '@graphql/middleware/queries'
import middleware from '@graphql/middleware'

export async function getEmployeeInfoSchema ({ employeeId, area }) {
  const input = { employeeId, area, entityForVisualization: 'ADMISSION' }
  const [ err, { employeeInfoSchemas } ] = await middleware(get.GetEmployeeInfoSchema, input)

  if (!employeeInfoSchemas?.length)
    return [ err, [], [] ]

  const sections = employeeInfoSchemas.map(section => ({
    id: section.id,
    label: section.label,
    area: section.area,
    schema: JSON.parse(section.schema),
    multipleValues: JSON.parse(section.values)
  }))

  const values = sections.reduce((acc, section) => {
    const vals = Object.fromEntries(
      Object.keys(section.schema).map(key => ([
        key,
        section.schema[key].value
      ]))
    )

    return [
      ...acc,
      {
        sectionId: section.id,
        values: vals
      }
    ]
  }, [])

  return [ err, sections, values ]
}
export const getRequiredSystemFields = async (employeeId, companyId, areas) => {
  const input = { employeeId, company: companyId, areas }

  const [ err, data ] = await middleware(get.GetSystemFieldsRequirement, input)
  const { systemFieldsRequirement } = data || {}

  if (!systemFieldsRequirement?.length)
    return [ err, [] ]

  return [ err, systemFieldsRequirement ]
}

// Extracts customFields from data
export const getAdditionalFields = (customFields, data) =>
  JSON.stringify(
    Object.entries(data)
      .reduce((acc, [ key, value ]) => ({
        ...acc,
        ...(customFields?.includes(key) ? { [key]: value } : {})
      }), [])
  )
