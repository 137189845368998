<template>
  <div v-if="!loading" class="application" :class="{ '-show-sidenav': showSidenav }">
    <header class="header">
      <img class="brand" :src="logo">

      <div v-if="!isMobile" class="disclaimer">
        <p class="text">{{ disclaimer }}</p>
      </div>
    </header>

    <c-shadowed ref="scroll" class="scroller" color="#F3F4F6" :has-bottom-shadow="false">
      <div v-if="isMobile" class="greetings">
        <c-title dark-text>
          Olá, {{ fullName }}
        </c-title>

        <p class="text">
          Preencha os dados abaixo para seguir com a sua contratação. É super rápido :)
        </p>
      </div>

      <aside class="sidenav">
        <steppers
          :value="current"
          :steps="steps"
          @input="onChange"
        />

        <div v-if="isMobile" class="disclaimer">
          <p class="text">{{ disclaimer }}</p>
        </div>
      </aside>

      <div :class="bodyClasses">
        <subhead
          :title="selected.label"
          :icon="selected.icon"
          :current-step="current"
          :steps="steps.length"
          @menu="onOpenMenu"
        />

        <c-shadowed :has-bottom-shadow="false">
          <transition name="view" mode="out-in">
            <component
              :is="selected.component"
              @previous="onPreviousStep"
              @next="onNextStep"
            />
          </transition>
        </c-shadowed>

        <c-bottom-bar
          v-if="!isMobile || isMobile && current > 1"
          :step="current"
        >
          <p
            v-if="!isMobile"
            slot="description"
            class="description"
            v-html="selected.description"
          />

          <template slot="actions">
            <c-button
              v-if="!isMobile && current > 0"
              flat
              white
              @click="previous"
            >
              Voltar
            </c-button>

            <c-button
              primary
              :success="isLastStep"
              :loading="submiting"
              @click="next"
            >
              {{ isLastStep ? 'Enviar' : !isMobile ? 'Continuar' : 'Salvar' }}
            </c-button>
          </template>
        </c-bottom-bar>
      </div>
    </c-shadowed>

    <dialog-modal
      v-if="showCompleteDialog"
      title="Vamos finalizar a sua admissão?"
      character="convite"
      message="Conferiu todos os dados? :) Lembrando que futuramente, após o seu processo
      finalizado,todas as informações poderão ser alteradas no seu perfil de colaborador
      na plataforma."
      confirm="Finalizar"
      @cancel="onCompleteCancel"
      @confirm="onCompleteConfirm"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { MediaQuery } from '@convenia/mixins'
import * as types from '@types'
import Steppers from '@components/Steppers'
import Subhead from '@components/Subhead'
import DialogModal from '@components/DialogModal'
import { setSentryContext } from '@src/sentry.js'

const disclaimer = 'Procedimentos preliminares para obtenção de dados e geração de contratos com a empresa.'

export default {
  name: 'ApplicationView',

  mixins: [ MediaQuery ],

  components: {
    Steppers,
    Subhead,
    DialogModal
  },

  data: () => ({
    loading: true,
    showSidenav: true,
    showCompleteDialog: false,
    disclaimer,
  }),

  mounted () {
    this.init()
  },

  computed: {
    ...mapState({
      token: state => state.auth.token,
      companyUuid: state => state.admission.companyUuid,
      fullName: state => state.personalInfo.personal.fullName,
      logo: state => state.admission.logo,
      otherInfoSchema: state => state.otherInfo.schema,
      submiting: state => state.admission.submiting,
      steps: state => state.admission.steps,
      current: state => +state.admission.currentStep
    }),

    selected () {
      return this.steps.find(({ id }) => +id === this.current) || {}
    },

    isLastStep () {
      return +this.steps[this.steps.length - 1].id === this.current
    },

    submiting: {
      get () { return this.$store.state.admission.submiting },
      set (value) { this.$store.commit(types.ADMISSION_SUBMITING, value) }
    },

    bodyClasses () {
      return [
        'body',
        { '-no-actions': this.current === 1 }
      ]
    }
  },

  watch: {
    current () {
      const scroller = this.$refs.scroll

      if (!scroller) return

      const wrapper = scroller.$el.querySelector('.wrapper')

      wrapper.scrollTo({ top: 0, behavior: 'smooth' })
    }
  },

  methods: {
    ...mapMutations({
      setStep: types.ADMISSION_SET_STEP,
      hideStep: types.ADMISSION_HIDE_STEP
    }),

    ...mapActions({
      getSteps: types.ADMISSION_STEPS,
      getAdmission: types.ADMISSION_INFO,
      getOtherInfo: types.EMPLOYEE_OTHER_INFO,
      getEmployeeInfo: types.EMPLOYEE_PERSONAL_INFO,
      getRequiredSystemFields: types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS,
      prevStep: types.ADMISSION_PREV_STEP,
      nextStep: types.ADMISSION_NEXT_STEP,
      closeAdmission: types.ADMISSION_CLOSE
    }),

    previous () {
      if (!this.selected.id) this.prevStep()

      this.$root.$emit('previous')
    },

    next () { this.$root.$emit('next') },

    initSentry () {
      if (!!import.meta.env.VITE_SENTRY_DSN && this.token && this.companyUuid)
        setSentryContext(this.token, this.companyUuid)
    },

    async init () {
      const [ err ] = await this.getAdmission()
      this.$nextTick(() => this.initSentry())

      if (err) return this.$router.push({ path: '/unauthorized' })

      await this.getSteps()
      await this.getOtherInfo()
      await this.getEmployeeInfo()
      await this.getRequiredSystemFields()

      if (!Object.keys(this.otherInfoSchema || {}).length)
        this.hideStep('other-information')

      this.loading = false
    },

    onChange (index) {
      if (!this.isMobile) return
      if (index > this.current) return

      this.setStep(index)
      this.showSidenav = false
    },

    onOpenMenu () {
      this.showSidenav = true
    },

    onNextStep () {
      if (this.isLastStep)
        return this.onComplete()

      this.nextStep()
    },

    onPreviousStep () {
      this.prevStep()
    },

    onComplete () {
      this.showCompleteDialog = true
    },

    onCompleteCancel () {
      this.showCompleteDialog = false
    },

    async onCompleteConfirm () {
      this.showCompleteDialog = false
      this.submiting = true

      const [ err ] = await this.closeAdmission()
      this.submiting = false

      if (err)
        return this.$feedback.add({
          type: 'error',
          message: 'Erro',
          highlighted: 'ao finalizar a admissão. Tente novamente mais tarde.'
        })

      this.$router.push({ path: '/complete' })
    }
  }
}
</script>

<style lang="scss">
.application {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;

  & > .header {
    flex-shrink: 0;
    padding: 20px;
    height: 100px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: $z-index-2;
    justify-content: space-between;

    & > .brand {
      max-width: 175px;
      height: auto;
    }

    & > .disclaimer {
      margin-left: 40px;

      & > .text {
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: rgba(#121E48, .5);
      }
    }
  }

  & > .scroller {
    flex: 1;
    display: flex;

    & > .wrapper {
      overflow-y: scroll;
      display: flex;

      & > .greetings {
        padding: 20px;
        & > .c-title { margin-bottom: 10px; }
        & > .text {
          font-size: 14px;
          line-height: 19px;
          color: rgba(#121E48, .8);
        }
      }

      & > .sidenav {
        width: 100%;
        padding: 0 10px;
      }

      & > .body {
        flex: 1;

        @include responsive (xs-mobile, mobile) {
          & > .c-shadowed > .wrapper > * > .c-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        @include responsive (tablet, desktop) {
          & > .c-shadowed > .wrapper > * { display: flex; }
          & > .c-shadowed > .wrapper > * > *:not(.c-loader) { flex-grow: 1; }
          & > .c-shadowed > .wrapper > * > .c-loader { margin: auto; }
        }

        & > .c-bottom-bar {
          flex-shrink: 0;

          & > .bottom-bar-content {
            justify-content: space-between;

            & > .information {
              .description > p {
                font-family: Nunito Sans,sans-serif;
                font-size: 14px;
                max-width: 550px;
                color: hsla(0,0%,100%,.8);
              }
            }

            & > .actions {
              & > .c-button {
                width: 180px;

                &.-flat {
                  border-color: rgba(white, .5);
                  & > .text { color: rgba(white, .5) }

                  &:after { content: none; }

                  &:hover {
                    border-color: rgba(#d8d8d8, .3);
                    box-shadow:
                      0px 0px 3px 0px rgba(white, 0.2),
                      inset 0px 0px 3px 0px rgba(white, .2);

                    & > .text { color: rgba(#d8d8d8, .8) }
                  }
                }
              }

              & > * + * { margin-left: 20px; }
            }
          }
        }
      }
    }
  }

  @include responsive (xs-mobile, mobile) {
    & > .header {
      text-align: center;
      padding: 0 20px;
      height: 60px;

      & > .brand { margin: auto; max-width: 120px; }
    }

    & > .scroller > .wrapper {
      flex-flow: column;

      & > .body {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0%;
        background: #F3F4F6;
        box-shadow: 0 0 80px rgba(black, .2);
        opacity: 1;
        transition: left .3s ease-out, opacity .3s ease-out;
        z-index: $z-index-3;

        & > .subhead { flex-shrink: 0; z-index: $z-index-3; }

        & > .c-shadowed {
          height: calc(100% - 120px);
          & > .wrapper > * { min-height: 100%; }
        }

        & > .c-bottom-bar {
          position: relative;
          & > .bottom-bar-content {
            padding: 0px;
            height: 60px;
            & > .information {

              .description > p {
                font-family: Nunito Sans,sans-serif;
                font-size: 14px;
                max-width: 550px;
                color: hsla(0,0%,100%,.8);
              }
            }
            & > .actions {
              width: 100%;
              padding-left: 0;
              margin-left: 0;
              & > .c-button {
                width: 100%;
                border-radius: 0 !important;
                min-height: 60px !important;
              }
            }

            > .information { display: none; }
          }
        }

        &.-no-actions > .c-shadowed { height: 100%; }
      }

      & > .sidenav > .disclaimer {
        margin: 40px 10px;

        & > .text {
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: rgba(#121E48, .5);
        }
      }
    }

    &.-show-sidenav {
      & > .scroller > .wrapper > .body {
        left: 100%;
        opacity: 0;
        transition: left .3s ease-out, opacity .3s ease-out .2s;
      }
    }
  }

  @include responsive (tablet, desktop) {
    & > .header {
      padding: 20px 50px;
      width: 100%;
      max-width: 1310px;
      margin: auto;
    }

    & > .scroller {
      & > .wrapper {
        justify-content: center;
        padding: { top: 40px; left: 40px; right: 40px; }

        & > .sidenav {
          width: 225px;
          margin-right: 40px;
        }

        & > .body {
          max-width: 960px;
          & > .c-shadowed {
            overflow: initial;
            > .wrapper {
              padding-bottom: 150px;
              overflow: initial;
              > * {
                padding-bottom: 30px;

                &:not(.other-info-container) { min-height: 508px; }
                &.other-info-container { padding-bottom: 60px;; }
                &.bank-account-container { min-height: 340px; }
              }
            }
          }
        }
      }
    }
  }
}
</style>
