export default {
  formation: {
    title: 'Formações Acadêmicas',
    singleTitle: 'Formação Acadêmica',
    singleIcon: 'graduate-hat',
    addLabel: 'Adicionar nova formação',
    files: true,
    filesText: 'Deve ser anexado o <b>diploma/certificado</b>.',
    multiple: true,
    fields: {
      id: { type: 'text', hide: true },
      educationType: {
        type: 'select',
        label: 'Escolaridade',
        placeholder: 'Escolaridade',
        options: [],
        optionsSrc: 'educationTypes',
        displayBy: 'name',
        trackBy: 'id',
        validation: '',
        value: '',
      },
      institution: {
        type: 'text',
        label: 'Instituição de ensino',
        placeholder: 'Instituição de ensino',
        validation: '',
        value: ''
      },
      course: {
        type: 'text',
        label: 'Curso',
        placeholder: 'Curso',
        validation: '',
        value: ''
      },
      graduationYear: {
        type: 'text',
        inputmode: 'numeric',
        label: 'Ano de conclusão',
        placeholder: 'Ano de conclusão',
        validation: {
          min: 4,
          custom: value => {
            if ((value || '').length)
              return Number(value) > 1900
            return true
          }
        },
        validationMsg: 'Ano de conclusão deve ter 4 dígitos e ser um ano válido',
        mask: '####',
        value: ''
      },
      files: {
        type: 'file',
        label: '',
        showLabel: false,
        multiple: true,
        labelLeft: false,
        fullWidth: true,
        extensions: [ 'jpg', 'jpeg', 'gif', 'bmp', 'png', 'pdf' ],
        infoCardProps: { hide: true },
        fileLimit: 4,
        validation: { maxLength: 4 },
        sizeLimit: 8,
      }
    }
  }
}
