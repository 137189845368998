function HSLtoRGB (h, s = 1, l = 1) {
  const c = l * s
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1))
  const m = l - c
  let r = 0
  let g = 0
  let b = 0

  if (h >= 0 && h < 60) {
    r = c; g = x; b = 0
  } else if (h >= 60 && h < 120) {
    r = x; g = c; b = 0
  } else if (h >= 120 && h < 180) {
    r = 0; g = c; b = x
  } else if (h >= 180 && h < 240) {
    r = 0; g = x; b = c
  } else if (h >= 240 && h < 300) {
    r = x; g = 0; b = c
  } else if (h >= 300 && h <= 360) {
    r = c; g = 0; b = x
  }

  r = Math.round((r + m) * 255)
  g = Math.round((g + m) * 255)
  b = Math.round((b + m) * 255)

  return `rgb(${r}, ${g}, ${b})`
}

function RGBtoHex (rgb) {
  const sep = rgb.replace(' ', '').indexOf(',') > -1 ? ',' : ' '
  const splitRgb = rgb.substr(4).split(')')[0].split(sep)

  let r = (+splitRgb[0]).toString(16)
  let g = (+splitRgb[1]).toString(16)
  let b = (+splitRgb[2]).toString(16)

  if (r.length === 1) r = '0' + r
  if (g.length === 1) g = '0' + g
  if (b.length === 1) b = '0' + b

  return '#' + r + g + b
}

function HextoRGB (h, alpha) {
  let r = 0; let g = 0; let
    b = 0

  if (h.length === 4) {
    r = '0x' + h[1] + h[1]
    g = '0x' + h[2] + h[2]
    b = '0x' + h[3] + h[3]
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2]
    g = '0x' + h[3] + h[4]
    b = '0x' + h[5] + h[6]
  }

  return typeof alpha === 'number'
    ? 'rgba(' + +r + ',' + +g + ',' + +b + ',' + alpha + ')'
    : 'rgb(' + +r + ',' + +g + ',' + +b + ')'
}

function HextoHSL (H) {
  let r = 0; let g = 0; let
    b = 0

  if (H.length === 4) {
    r = '0x' + H[1] + H[1]
    g = '0x' + H[2] + H[2]
    b = '0x' + H[3] + H[3]
  } else if (H.length === 7) {
    r = '0x' + H[1] + H[2]
    g = '0x' + H[3] + H[4]
    b = '0x' + H[5] + H[6]
  }

  r /= 255
  g /= 255
  b /= 255

  const cmin = Math.min(r, g, b)
  const cmax = Math.max(r, g, b)
  const delta = cmax - cmin
  let h = 0
  let s = 0
  let l = 0

  if (delta === 0) h = 0
  else if (cmax === r) h = ((g - b) / delta) % 6
  else if (cmax === g) h = (b - r) / delta + 2
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)

  if (h < 0) h += 360

  l = (cmax + cmin) / 2
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return [ h, s, l ]
}

function isHex (value) {
  return !!value.match(/^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$/)
}

export {
  HSLtoRGB,
  RGBtoHex,
  HextoRGB,
  HextoHSL,
  isHex
}
