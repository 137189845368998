<template>
  <c-button
    ref="filter"
    flat
    icon-size="20"
    icon="filter-sort-menu"
    :class="[ 'c-filter-button', { '-icon-only': isMobile } ]"
    :style="{ '--width': pxWidth, '--icon-margin': pxIconMargin }"
    v-bind="$attrs"
    @click="$emit('click', $event)"
  >
    <template v-if="!isMobile">
      <div class="content">
        <span class="filter-text">
          <slot>filtrar</slot>
        </span>
      </div>
    </template>

    <template
      v-if="!isMobile"
      slot="raw"
    >
      <span class="counter" :class="noFilter ? '' : 'has-filter'">
        {{counter}}
      </span>
    </template>
  </c-button>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import { is } from '@convenia/helpers'

export default {
  name: 'CFilterButton',

  mixins: [ MediaQuery ],

  props: {
    counter: {
      type: Number,
      default: 0,
    },

    iconMargin: {
      type: [ String, Number ],
      default: '35px'
    },

    width: {
      type: [ String, Number ],
      required: true
    }
  },

  computed: {
    noFilter () {
      return this.counter <= 0
    },

    pxWidth () {
      const { width } = this
      return is(width, 'Number')
        ? `${width}px`
        : width
    },

    pxIconMargin () {
      const { iconMargin } = this
      return is(iconMargin, 'Number')
        ? `${iconMargin}px`
        : iconMargin
    }
  }
}
</script>

<style lang="scss">
.c-filter-button.c-button {
  & > .counter {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);

    background-color: color-var(text, base-10);
    height: 25px;
    width: 32px;

    @include typo(h3);
    color: color-var(text, base-30);

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;

    &.has-filter {
      background-color: color-var(positive);
      color: #FFF;
      opacity: 1;

      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 50%;
        width: 68.75%;
        height: 60%;
        border-radius: 50px;
        background: $positive-color;
        transform: translateX(-50%);
        z-index: -$z-index-1;
        opacity: 0.5;
        filter: blur(7px) brightness(1);
      }
    }
  }

  @include responsive (tablet, desktop) {
    justify-content: flex-start;
    width: var(--width);
    padding-left: var(--icon-margin);
  }
}
</style>
