<template>
  <div :class="[ 'c-add', { '-grey': grey, '-thin': thin } ]">
    <c-button
      v-if="squared"
      :key="squared"
      :beta="beta"
      icon="plus"
      type="button"
      :icon-size="iconSize"
      :grey="grey"
      :disabled="disabled"
      @click.stop="$emit('add')"
    />

    <c-button
      v-else
      :key="`${+squared}-${+isMobile}`"
      :beta="beta"
      icon="plus"
      type="button"
      :size="(isMobile & mobileIconOnly) ? 30 : 40"
      :icon-size="(isMobile && mobileIconOnly) ? 14 : iconSize"
      :disabled="disabled"
      @click.stop="$emit('add')"
    >
      <template v-if="(!isMobile && !iconOnly) || (isMobile && !mobileIconOnly)">
        {{ text }}
      </template>
    </c-button>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CAdd',

  mixins: [ MediaQuery ],

  emits: [
    /**
   * Triggered on click.
   */
    'add'
  ],
  props: {
    /**
     * Makes the component's colors scales of gray.
     */
    grey: Boolean,

    /**
     * Button text
     */
    text: {
      type: String,
      default: 'Adicionar'
    },

    squared: Boolean,

    thin: Boolean,

    iconSize: {
      type: [ String, Number ],
      default: '22'
    },

    /**
     * Disables the field.
     */
    disabled: {
      type: Boolean,
      default: false
    },

    preventDefaultEvent: {
      type: Boolean,
      default: true
    },

    iconOnly: Boolean,

    mobileIconOnly: {
      type: Boolean,
      default: true
    },

    beta: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick (e) {
      if (this.preventDefaultEvent)
        e.preventDefault()

      this.$emit('add')
    }
  }
}
</script>

<style lang="scss">
.c-add {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px dashed color-var(text, base-20);

  @include responsive(tablet, desktop) { height: 256px; }

  &.-grey {
    height: 120px;
    width: 120px;
    background: color-var(text, base-02);

    & > .c-button {
      box-shadow: none;
      position: relative;
      background: color-var(text, base-10);

      @include add-shadow(before) {
        border-radius: 50%;
        opacity: 0.1;
      }

      & > .c-icon { position: relative; }
    }

    @include responsive (xs-mobile, mobile) {
      height: 90px;
      width: 90px;

      & > .c-button { width: 30px; }
    }
  }

  &.-thin {
    width: unset;
    height: unset;
    padding: 15px 0;

    @include responsive(tablet, desktop) { margin: 0 10px; }
  }
}
</style>
