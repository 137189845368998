import { sentry as sentryUtils } from '@convenia/utils'
import { captureException } from '@sentry/browser'
import graphql from '@modules/graphql'

const errorHandler = sentryUtils.captureGqlClientError(captureException)

const { request, setToken } = graphql({
  endpoint: import.meta.env.VITE_BFF_MIDDLEWARE,
  batch: import.meta.env.NODE_ENV === 'production',
  errorHandler
})

export { setToken }

export default request
