import { is } from './is'
import { defaultValidator } from './validators'

const DELIMITER = '.'

/**
 * Spreads path into fields list.
 * @param {string|Array<string>} path
 * @returns {Array.<string>}
 */
const getProperties = (path) => is(path, 'String')
  ? path.split(DELIMITER)
  : is(path, 'Array')
    ? path
    : []

/**
 * Deep object.
 * @typedef {Object.<string, (number|boolean|string|Deep)>} Deep
 */

/**
 * Checks if a propety is reachable.
 * @param {Deep} object
 * @param {string} property
 * @returns {(number|boolean|string|null)}
 */
const getValue = (object, property) => {
  const isKeyed = is(object, 'Object') || is(object, 'Module')
  const isReachable = (isKeyed && object.hasOwnProperty(property))
    || (Array.isArray(object) && object[property] != null)

  const value = isReachable ? object[property] : null
  return value
}

/**
 * Get value from object path.
 * @param {Deep} object
 * @param {(string|Array<string>)} path
 * @returns {(number|boolean|string|null)}
 */
export const get = (object, path) => {
  const properties = getProperties(path)
  const value = properties.reduce(getValue, object)
  return value
}

/**
 * Get value from first object.
 * @param {string} name
 * @param {Deep[]} objects
 * @param {function(*):boolean} [validate]
 * @returns {*}
 */
export const getProperty = (name, objects, validate = defaultValidator) => {
  const properties = objects.map((object) => get(object, name))
  const property = properties.find((property) => validate(property))
  return property
}

export default get
