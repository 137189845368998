<template>
  <div class="photo-upload">
    <p v-if="!value && isMobile" class="message">
      Faça o upload da sua foto de perfil direto da sua Biblioteca de Imagens.
    </p>

    <c-upload
      v-show="!value"
      ref="upload"
      character="empty_upload"
      :value="files"
      :extensions="extensions"
      :validation="error"
      @file:add="onAddFile"
    />

    <transition name="fade">
      <div v-if="!value && !!validation" class="validation">
        <c-flag
          error
          size="15"
          icon="exclamation"
        />

        <span class="text">
          {{ validation }}
        </span>
      </div>
    </transition>

    <div v-if="value" class="preview">
      <c-title v-if="isMobile" dark-text>
        Deseja utilizar esta foto?
      </c-title>

      <div
        class="photo"
        :style="{ backgroundImage: `url('${value}')` }"
      />

      <div v-if="isMobile" class="actions">
        <c-button primary @click="onSubmit">
          Gostei, quero usar
        </c-button>

        <c-button flat @click="onSelectFile">
          Escolher outra
        </c-button>
      </div>

      <div v-else class="actions">
        <c-button error flat @click="onRemove">
          Excluir Foto
        </c-button>

        <c-button primary @click="onSelectFile">
          Procure um arquivo
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  mixins: [ MediaQuery ],

  props: {
    value: {
      type: String,
      default: null
    },

    extensions: {
      type: Array,
      default: () => ([ 'jpg', 'jpeg', 'png' ])
    },

    validation: {
      type: String,
      default: undefined
    }
  },

  data: () => ({
    files: [],
    error: null
  }),

  methods: {
    onAddFile (files) {
      if (!files.length) return

      const { data: file } = files[0]
      const reader = new FileReader()

      if (![ 'image/jpg', 'image/jpeg', 'image/png' ].includes(file.type)) {
        this.error = 'Formato não suportado.'
        return
      }

      reader.onloadend = () => this.$emit('upload', reader.result)
      reader.readAsDataURL(file)

      this.error = null
    },

    validate () {
    },

    onSelectFile () {
      this.$refs.upload.onSelectFiles()
    },

    onRemove () {
      this.$emit('remove')
    },

    onSubmit () {
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss">
.photo-upload {
  padding: 0 20px;

  & > .message {
    max-width: 290px;
    margin: auto;
    padding-top: 20px;
    font-size: 14px;
    line-height: 19px;
    color: rgba(#121E48, .8);
  }

  & > .c-upload {
    width: auto;

    & > .inner > .c-upload-empty-list > .bottom > .c-button { max-width: 180px; }

  }

  & > .validation {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 35px;
    pointer-events: none;
    & > .c-flag {
      padding: 2px;
      margin-right: 5px;
    }
    & > .text {
      font-size: 14px;
      font-weight: 600;
      color: #FF7EB3;
      -webkit-font-smoothing: antialiased;
    }
  }

  & > .preview {
    height: 418px;
    display: flex;
    flex-flow: column;

    & > .c-title {
      display: flex;
      justify-content: column;
      align-items: center;
    }

    & > .photo {
      width: 240px;
      height: 240px;
      margin: auto;
      border-radius: 300px;
      background-position: center;
      background-size: cover;
      flex-shrink: 0;
    }
  }

  @include responsive (xs-mobile, mobile) {
    & > .preview{
      & > .c-title {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      & > .actions {
        margin-top: 20px;
        display: flex;
        flex-flow: column;

        & > .c-button {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }

  @include responsive (tablet, desktop) {
    & > .preview {
      border: 1px dashed rgba(18,30,72, .2);
      border-radius: 5px;

      & > .actions {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        & > .c-button {
          margin: 0 5px;
          &.-error {
            & > .text { color: #FF7EB3; }
          }
        }
      }
    }
  }
}
</style>
