import { is } from '@convenia/helpers'

const isArgObj = val => is(val, 'Object')
  && [ 'ev', 'fn' ].every(key => Object.keys(val || {}).includes(key) && !!val[key])

export default {
  bind (el, { value }, { context }) {
    const handler = e => {
      if (!el.contains(e.target) && el !== e.target) el.__vueClickOutsideFn__(e)
    }

    el.__vueClickOutside__ = handler
    el.__vueClickOutsideFn__ = isArgObj(value) ? value.fn.bind(context) : value.bind(context)
    el.__vueClickOutsideEv__ = isArgObj(value) ? value.ev : 'click'

    document.addEventListener(el.__vueClickOutsideEv__, handler)
  },

  unbind (el) {
    document.removeEventListener(el.__vueClickOutsideEv__, el.__vueClickOutside__)

    el.__vueClickOutside__ = null
    el.__vueClickOutsideFn__ = null
    el.__vueClickOutsideEv__ = null
  }
}
