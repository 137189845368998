import { fullNameValidation, fullNameValidationMsg } from '@modules/validate/fullName'

export default {
  dependent: {
    title: 'Dependentes',
    singleTitle: 'Dependente',
    singleIcon: 'user-profile-checkmark',
    addLabel: 'Adicionar novo dependente',
    files: true,
    filesText: 'Documentos relacionados ao dependente.',
    icon: 'group-user',
    multiple: true,
    required: false,
    fields: {
      id: { type: 'text', hide: true },
      fullName: {
        type: 'text',
        label: 'Nome completo',
        placeholder: 'Nome completo',
        validationMsg: fullNameValidationMsg,
        validation: { custom: fullNameValidation },
        value: '',
        required: true,
      },
      relationship: {
        type: 'select',
        label: 'Relação',
        placeholder: 'Relação',
        value: '',
        displayBy: 'title',
        trackBy: 'id',
        options: [],
        optionsSrc: 'dependentRelations',
      },
      birthDate: {
        type: 'text',
        inputmode: 'numeric',
        label: 'Data de nascimento',
        placeholder: 'Data de nascimento',
        mask: '##/##/####',
        validation: {
          date: true
        },
        value: ''
      },
      genderId: {
        type: 'select',
        label: 'Gênero no documento',
        displayBy: 'label',
        trackBy: 'value',
        nullable: true,
        options: [
          { label: 'Masculino', value: 1 },
          { label: 'Feminino', value: 2 }
        ]
      },
      educationTypeId: {
        type: 'select',
        label: 'Escolaridade',
        placeholder: 'Escolaridade',
        nullable: true,
        options: [],
        optionsSrc: 'educationTypes',
        displayBy: 'name',
        trackBy: 'id',
      },
      cpf: {
        type: 'text',
        inputmode: 'numeric',
        label: 'CPF da pessoa dependente',
        mask: '###.###.###-##',
        placeholder: 'CPF da pessoa dependente',
        value: ''
      },
      phone: {
        type: 'text',
        inputmode: 'numeric',
        label: 'Contato',
        placeholder: '(00) 00000-0000',
        mask: '(##) #####-####',
        validationMsg: 'Número inválido',
        validation: 'min:14',
        value: ''
      },
      email: {
        type: 'text',
        label: 'E-mail',
        placeholder: 'E-mail',
        validation: {
          max: 106,
          email: true
        },
        value: ''
      },
      hasDisability: {
        type: 'radio',
        label: 'Dependente possui incapacidade física ou mental para trabalho?',
        value: false,
        displayBy: 'label',
        trackBy: 'value',
        options: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false },
        ],
      },
      motherName: {
        type: 'text',
        label: 'Nome completo da mãe',
        placeholder: 'Nome completo da mãe',
        validation: { custom: fullNameValidation },
        validationMsg: fullNameValidationMsg,
        value: ''
      },
      ir: {
        type: 'check',
        label: 'Incluir para fins de <b>imposto de renda.</b>',
        value: false,
        cardProps: { hide: true }
      },
      familySalary: {
        type: 'check',
        label: 'Salário família',
        value: false,
      },
      foreigner: {
        type: 'check',
        label: 'Estrangeiro',
        value: false,
      },
      description: {
        type: 'text',
        textArea: true,
        maxLength: 220,
        label: 'Observações',
        placeholder: 'Observações',
        value: ''
      },
      files: {
        type: 'file',
        label: '',
        showLabel: false,
        multiple: true,
        labelLeft: false,
        fullWidth: true,
        extensions: [ 'jpg', 'jpeg', 'gif', 'bmp', 'png', 'pdf' ],
        infoCardProps: { hide: true },
        fileLimit: 4,
        validation: { maxLength: 4 },
        sizeLimit: 8,
      }
    }
  }
}
