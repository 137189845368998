/*
  The tooltip custom directive can be used with the following options:

  - No modifiers, no binding value ( <component v-tooltip.ellipsis /> )
    [ The tooltip will appear when the content is being hidden with its default
    styles, displaying the full content. ]

  - Dark modifier ( <component v-tooltip.dark /> )
    [ The tooltip will appear with its dark theme style ]

  - Binding value ( <component v-tooltip="'Some content'" /> )
    [ The tooltip will appear with the content set in the binding ]

  - Tooltip distance as argument ( <component v-tooltip:10.dark /> )
    [ The tooltip will appear distance of 10px from the element ]

  - Object as binding value ( <component v-tooltip=" {
      value: [String] The text to be displayed in the tooltip,
      position: [String] 'top' of 'bottom',
      align: [String] 'left', 'right' or 'center',
      fontSize: [Number] default 11,
      maxWidth: [Number] default 200,
      horiziontalPadding: [Number] default 10
      hide: [Boolean] Whether to hide the tooltip,
      parentEl: [Element] The parent of the tooltip element other than the one
      which has the declared directive
    }" /> )

    =====================================================================

    This directive, when called with the '.ellipsis' modifier,
    is meant to be used in situations that content is being hidden
    by the css attribute 'text-overflow: ellipsis'.

    The html element must use the following css attributes for the desired effect:

    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
*/
import Vue from 'vue'
import CTooltip from '../../CTooltip/index.vue'

const directive = (() => {
  const state = new WeakMap()

  return {
    bind (el, binding) {
      const bindingValue = (binding || {}).value || {}
      const isObject = typeof binding.value === 'object'
      const align = (isObject && binding.value.align) || 'center'
      const value = binding.modifiers.ellipsis
        ? el.innerText
        : isObject ? binding.value.value : binding.value

      const TooltipConstructor = Vue.extend(CTooltip)

      const tooltip = new TooltipConstructor({
        propsData: {
          show: false,
          hide: (bindingValue || {}).hide,
          dark: binding.modifiers.dark,
          fontSize: (isObject && binding.value.fontSize) || 14,
          maxWidth: (isObject && binding.value.maxWidth) || 200,
          horizontalPadding: (isObject && binding.value.horizontalPadding) || 10,
          target: el,
          arrowSpacing: align === 'center' ? 10 : 15,
          elementSpacing: (isObject && binding.value.elementSpacing) || binding.arg,
          position: (isObject && binding.value.position) || 'top',
          isHTML: (isObject && binding.value.isHTML),
          value,
          align
        }
      }).$mount()

      const parentEl = isObject
        ? bindingValue.parentEl || document.body
        : document.body

      parentEl.appendChild(tooltip.$el)

      const handleMouseLeave = () => {
        tooltip.show = false
      }

      const handleScroll = handleMouseLeave
      const showTooltip = () => {
        tooltip.show = true
        document.addEventListener(
          'scroll',
          handleScroll,
          { capture: true, once: true }
        )
      }

      const truncateStyles = {
        overflow: 'hidden',
        'max-width': (bindingValue || {}).truncateWidth || '100%',
        'white-space': 'nowrap',
        'text-overflow': 'ellipsis'
      }

      if (((binding || {}).modifiers || {}).ellipsis && el) {
        el.style.cssText += Object.entries(truncateStyles)
          .map(([ key, value ]) => `${key}:${value}`)
          .join(';')
      }

      const handleMouseEnter = () => {
        const isEllipsis = binding.modifiers.ellipsis
        const hasHiddenContent = el.clientWidth < el.scrollWidth
        if (isEllipsis) hasHiddenContent && showTooltip()
        else showTooltip()
      }

      el.addEventListener('mouseenter', handleMouseEnter)
      el.addEventListener('mouseleave', handleMouseLeave)
      state.set(el, { tooltip, handleMouseEnter, handleMouseLeave })
    },

    componentUpdated (el, binding) {
      const isObject = typeof binding.value === 'object'
      const value = isObject ? binding.value.value : binding.value
      const oldValue = isObject ? binding.oldValue.value : binding.oldValue
      const { tooltip } = state.get(el) || {}

      if (!tooltip) return

      if (value !== oldValue) tooltip.value = value
      tooltip.hide = (binding.value || {}).hide
      // If hide is set to true, reset the show value
      if (tooltip.hide) tooltip.show = false
    },

    unbind (el) {
      const { tooltip, handleMouseEnter, handleMouseLeave } = state.get(el) || {}
      el.removeEventListener('mouseenter', handleMouseEnter)
      el.removeEventListener('mouseleave', handleMouseLeave)
      document.removeEventListener('scroll', handleMouseLeave, { capture: true })

      const tooltipEl = (tooltip || {}).$el
      if (!tooltipEl) return

      tooltipEl.remove()
      state.delete(el)
    }
  }
})()

export default directive
