<template>
  <c-input-container v-bind="$attrs" class="c-multi-checkbox control">
    <div class="inner" :style="rows">
      <c-checkbox
        v-for="(option, index) in options"
        :key="index"
        class="option"
        :label="option[displayBy]"
        :value="isSelected(option)"
        :disabled="!!option.disabled || disabled"
        :tooltip="option.tooltip"
        @input="handleInput(option, $event)"
        @mouseenter.native="onMouseEnter(option)"
        @mouseleave.native="onMouseLeave(option)"
      />
    </div>

    <div v-if="nativeCompatible" class="native">
      <input
        v-for="(option, index) in options"
        :key="index"
        type="checkbox"
        :name="name"
        :value="trackedOption(option)"
        :checked="isSelected(option)"
        @mouseenter="onMouseEnter(option)"
        @mouseleave="onMouseLeave(option)"
      >
    </div>

    <c-multi-select-item-list
      v-if="showSelectList"
      :items="itemsList"
      :display-by="displayBy"
      @remove="removeOption($event.event)"
    />
  </c-input-container>
</template>

<script>
/**
 * A multi checkbox component. When some option(s) is (are) selected, it emits an 'input'
 * event with the chosen option(s).
 */
export default {
  name: 'CMultiCheckbox',

  props: {
    /**
     * Array of strings or objects
     */
    options: {
      type: Array,
      default: () => ([])
    },

    /**
     * The input name.
     */
    name: {
      type: String,
      default: 'radioGroup'
    },

    trackBy: {
      type: String,
      required: true
    },

    displayBy: {
      type: String,
      required: true
    },

    /**
     * Number of columns
     */
    columns: {
      type: Number,
      default: 1
    },

    /**
     * Selected option's value
     */
    value: {
      type: Array,
      default: () => []
    },

    /**
     * Set hidden native checkboxes inputs for native forms compatibility.
     */
    nativeCompatible: Boolean,

    /**
     * Bind values only by track-by fields on options items
     */
    bindTrackBy: Boolean,

    /**
     * Show selected list component
     */
    showSelectList: Boolean,

    /**
     * Disables all options
     */
    disabled: Boolean
  },

  computed: {
    rows () {
      return 'grid-template-rows: repeat(' + Math.ceil(this.options.length / this.columns) + ', 1fr);'
    },

    optionsReference () {
      return this.options.reduce((optionsReference, option) => ({
        ...optionsReference,
        [option[this.trackBy]]: option
      }), {})
    },

    selectionSet () {
      const trackedValues = this.bindTrackBy
        ? this.value
        : this.value.reduce((validValues, selection) => {
          const trackedValue = (selection || {})[this.trackBy]
          return [ ...validValues, ...(trackedValue ? [ trackedValue ] : []) ]
        }, [])

      return new Set(trackedValues)
    },

    itemsList () {
      return [ ...this.selectionSet ].map(selection => this.optionsReference[selection])
    },
  },

  methods: {
    isSelected (option) {
      return this.selectionSet.has(option[this.trackBy])
    },

    removeOption (option) {
      const toRemoveTracking = item => this.bindTrackBy
        ? item
        : item[this.trackBy]

      const newValue = this.value.filter(item => toRemoveTracking(item) !== option[this.trackBy])
      this.$emit('input', newValue)
    },

    addOption (option) {
      const newItem = this.bindTrackBy ? option[this.trackBy] : option
      const newValue = [ ...this.value, newItem ]
      this.$emit('input', newValue)
    },

    handleInput (option, event) {
      if (event) return this.addOption(option)
      this.removeOption(option)
    },

    onMouseEnter (option) {
      if (!option.hint) return

      this.$emit('hint:show', option.hint)
    },

    onMouseLeave (option) {
      if (!option.hint) return

      this.$emit('hint:hide')
    }
  },

  watch: {
    bindTrackBy () {
      this.$emit('input', [])
    }
  }
}
</script>

<style lang="scss">
.c-multi-checkbox {
  & > .inner {
    justify-content: space-around;

    display: grid;
    grid-column-gap: 30px;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;

    grid-row-gap: 10px;

    & > .option {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: flex-start;

      & > .label {
        margin-left: 15px;
        @include responsive (tablet, desktop) { margin-left: 20px; }

        font-size: 14px;
      }
    }
  }

  & > .native {
    position: absolute;
    visibility: hidden;
  }

  & > .jumbo-validation { display: none; }
}
</style>
