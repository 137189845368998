<template>
  <c-card
    class="document-card"
    :green="success"
    :class="classes"
    @click="$emit('click', $event)"
  >
    <div class="icon">
      <transition name="stamp">
        <c-flag
          v-if="success"
          success
          class="check"
          icon="done-check-3"
        />
      </transition>

      <c-flag
        :grey="!error && !success"
        :icon="error ? 'question-circle' : icon"
      />
    </div>

    <div class="content">
      <span class="title">
        {{ title }}
      </span>

      <span class="message">
        {{ message }}
      </span>
    </div>

    <transition name="fade">
      <div class="footer">
        <div v-if="files" class="attachment">
          <c-icon icon="clip-attachment" size="18" fill="rgba(18, 30, 72, 0.3)" />

          <span class="text">
            {{ files }} Anexos
          </span>
        </div>

        <span class="more">
          <span v-if="isMobile" class="text">
            Ver Mais
          </span>

          <c-icon
            class="arrow"
            icon="arrow-right"
            :size="isMobile ? 11 : 18"
          />
        </span>
      </div>
    </transition>
  </c-card>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'DocumentCard',

  mixins: [ MediaQuery ],

  props: {
    title: {
      type: String,
      default: 'Título'
    },
    icon: {
      type: String,
      default: 'user-profile-8'
    },
    error: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    files: {
      type: Number,
      default: 0
    },
    message: {
      type: String,
      default: 'Documento obrigatório'
    }
  },

  computed: {
    classes () {
      return {
        '-has-error': this.error
      }
    }
  }
}
</script>

<style lang="scss">
.document-card {
  padding: 15px 15px 20px 20px;
  display: flex;
  align-items: center;
  box-shadow: 2px 8px 16px rgba(24,50,115,0.06) !important;

  & > .icon {
    flex-shrink: 0;
    margin-right: 10px;
    position: relative;
    & > .check {
      position: absolute;
      z-index: $z-index-2;
    }
  }

  & > .content {
    flex: 1;
    min-width: 0;
    & > .title {
      display: block;
      font-size: 16px;
      font-family: Ubuntu;
      font-weight: 500;
      line-height: 22px;
      color: rgba(#121E48, .8);
      -webkit-font-smoothing: antialiased;
      @include truncate(100%);
    }
    & > .message {
      display: block;
      font-size: 11px;
      line-height: 15px;
      height: 0px;
      opacity: 0;
      visibility: hidden;
      transition: .3s ease-out;
      color: #FF7EB3;
    }
  }

  & > .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > .attachment {
      background: rgba(#121E48, .05);
      border-radius: 20px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      height: 25px;
      & > .text {
        padding: 0 5px;
        font-size: 11px;
        font-family: Ubuntu;
        text-transform: uppercase;
        font-weight: 500;
        color: rgba(#121E48, 0.5);
      }
    }

    & > .more {
      display: flex;
      align-items: center;
      & > .text {
        display: block;
        font-size: 11px;
        font-weight: 500;
        font-family: Ubuntu;
        text-transform: uppercase;
        line-height: 14px;
        margin-right: 5px;
        color: rgba(18,30,72,0.5);
      }
      & > .arrow { opacity: .3; }
    }

    .c-icon { @include icon-color(text, base-30); }
  }

  &.-has-error {
    background: rgba(color-var(negative, base-rgb), .2) !important;
    box-shadow: 0 0 0 transparent !important;

    &:before {
      width: 0;
      min-width: 0;
    }

    & > .icon > .c-flag {
      background: rgba(color-var(negative, base-rgb), .1);

      > .c-icon {
        @include icon-color(negative);
        filter: none;
      }
    }

    & > .content {
      & > .title { color: color-var(negative); }

      & > .message {
        height: 15px;
        opacity: 1;
        visibility: visible;
      }
    }

    & > .footer > .more {
      & > .arrow {
        @include icon-color(negative);
        opacity: .5;
      }
    }
 }

  @include responsive (xs-mobile, mobile) {
    flex-flow: row wrap;
    & > .footer {
      display: flex;
      margin-top: 20px;
      flex: 100%;
      align-items: flex-end;
      min-height: 25px;
      & > .more {
        flex: 1;
        justify-content: flex-end;
      }
    }
  }

  @include responsive (tablet, desktop) {
    cursor: pointer;
    padding: 30px;
    & > .content {
      & > .icon { margin-right: 20px; }
    }
    & > .footer {
      & > .attachment, & > .more { margin-left: 10px }
    }
  }
}
</style>
