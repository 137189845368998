<template>
  <c-info :class="[ 'historical-change-info' ]" :fields="formattedFields" :margin="margin">
    <template v-slot:[fieldType]="{ field }" v-for="fieldType in $options.fieldTypes">
      <component :is="fieldType" :key="fieldType" v-bind="field" />
    </template>
  </c-info>
</template>

<script>
import { is } from '@convenia/helpers'
import Deleted from './Values/Exclusion'
import Created from './Values/Inclusion'
import Updated from './Values/Update'

export default {
  name: 'HistoricalChangeInfo',

  props: {
    fields: {
      type: Array,
      default: () => [],
    },

    margin: {
      type: Number,
      default: 230,
    },

    formatField: {
      type: Function,
      default: null,
    },
  },

  computed: {
    formattedFields () {
      const { formatField, fields } = this
      return is(formatField, 'Function')
        ? fields.map(formatField)
        : fields
    }
  },

  components: {
    Deleted,
    Created,
    Updated
  },

  fieldTypes: [ 'deleted', 'created', 'updated' ],
}
</script>

<style lang="scss">
.historical-change-info {
  padding: 15px 20px 20px 20px;

  & > .info > .--label- {
    display: none;
  }

  @include responsive (tablet, desktop) {
    padding-top: 30px;
    padding-bottom: 50px;

    & > .info > .--label- {
      display: block;
    }
  }
}
</style>
