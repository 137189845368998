<template>
  <div class="formations-container">
    <transition name="view" mode="out-in">
      <c-loader v-if="loading" :size="isMobile ? 79 : 99" />

      <form-group
        v-else
        ref="formGroup"
        :label-left="!isMobile"
        :schemas="schemas"
        :data="data"
        :options="options"
        :disabled="submiting"
        :required-fields="requiredFields"
        @add:formation="onAddFormation"
        @remove:formation="onRemoveFormation"
        @file:add="onFileModalOpen"
        @file:remove="onRemoveFile"
      />
    </transition>

    <c-form-builder-modal
      v-if="showFileModal"
      label-left
      submit-button-text="Salvar"
      name="formation"
      title="Anexar Arquivos"
      v-model="filesFormData"
      :required-fields="requiredFields"
      :is-loading="submiting"
      :is-disabled="submiting"
      :schema="filesSchema"
      @close="onFileModalClose"
      @submit="onFileModalSubmit"
    />

    <dialog-modal
      v-if="removing"
      theme="error"
      title="Deseja excluir essa Formação Acadêmica?"
      message="As informações inseridas serão excluídas do formulário.
      Tem certeza que deseja excluir este registro?"
      :loading="submiting"
      @cancel="onRemoveFormationCancel"
      @confirm="onRemoveFormationSubmit"
    />

    <dialog-modal
      v-if="showRemoveFileModal"
      theme="error"
      title="Deseja prosseguir?"
      message="Ao prosseguir os arquivos apagados serão permanentemente excluídos,
        esta ação não pode ser desfeita."
      confirm="Excluir"
      cancel="Não, mudei de ideia"
      @cancel="showRemoveFileModal = false"
      @confirm="onFileModalSubmit(filesFormData)"
    />

    <dialog-modal
      v-if="isRemovingFile"
      theme="error"
      title="Deseja excluir o arquivo selecionado?"
      message="O arquivo será permanentemente excluído, esta ação não pode ser desfeita."
      confirm="Excluir"
      cancel="Não, mudei de ideia"
      :loading="submiting"
      @cancel="isRemovingFile = false"
      @confirm="onRemoveFileSubmit"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { MediaQuery } from '@convenia/mixins'
import * as types from '@types'
import FormGroup from '@components/FormGroup'
import DialogModal from '@components/DialogModal'
import formationSchemas from '@forms/formation'
import upload from '@modules/upload'

export default {
  name: 'FormationsStepper',

  mixins: [ MediaQuery ],

  components: {
    FormGroup,
    DialogModal
  },

  computed: {
    ...mapState({
      formation: state => state.formation.formation,
      options: state => state.formation.options,
      customFields: state => state.formation.customFields,
      employeeId: state => state.admission.employeeUuid,
    }),

    ...mapGetters({
      getRequiredFields: types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS,
    }),

    data () {
      return {
        formation: this.formation
      }
    },

    schemas () {
      const { formation } = formationSchemas

      return {
        formation: {
          ...formation,
          fields: {
            ...formation.fields,
            ...this.customFields.educations.schema,
            files: {
              ...formation.fields.files,
              hide: true
            }
          }
        }
      }
    },

    filesSchema () {
      const { formation } = formationSchemas
      const { files } = formation.fields

      return { files }
    },

    selected () {
      if (!this.active) return {}

      return this.formation.find(({ id }) => id === this.active)
    },

    submiting: {
      get () { return this.$store.state.admission.submiting },
      set (value) { this.$store.commit(types.ADMISSION_SUBMITING, value) }
    },

    requiredFields () {
      const requiredFields = [
        ...this.getRequiredFields('academicFormation')
      ]

      if (requiredFields.includes('academicFormationAttachment'))
        requiredFields.push('files')

      return requiredFields
    }
  },

  data: () => ({
    loading: true,
    removing: false,
    active: null,
    showFileModal: false,
    showRemoveFileModal: false,
    isRemovingFile: false,
    removingFile: null,
    filesFormData: {},
  }),

  mounted () {
    this.$root.$on('next', this.onSubmit)
    this.$root.$on('previous', this.onSave)
    this.init()
  },

  beforeDestroy () {
    this.$root.$off('next', this.onSubmit)
    this.$root.$off('previous', this.onSave)
  },

  methods: {
    ...mapMutations({
      addFormation: types.EMPLOYEE_ADD_EDUCATION,
      saveFormation: types.EMPLOYEE_EDUCATION,
    }),

    ...mapActions({
      getCustomFields: types.EMPLOYEE_EDUCATION_CUSTOM_FIELDS,
      getOptions: types.EMPLOYEE_EDUCATION_OPTIONS,
      getFormations: types.EMPLOYEE_EDUCATION,
      setFormation: types.EMPLOYEE_SET_EDUCATION,
      setFormations: types.EMPLOYEE_SET_EDUCATIONS,
      deleteFormation: types.EMPLOYEE_DELETE_EDUCATION,
      deleteFile: types.ADMISSION_DELETE_FILE,
      getRequiredSystemFields: types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS,
    }),

    async init () {
      await this.getFormations()
      await this.getOptions()
      await this.getCustomFields()

      this.loading = false
    },

    onAddFormation () {
      this.addFormation()
      this.$refs.formGroup.formsData.formation.push(
        this.data.formation[this.data.formation.length - 1]
      )
    },

    onRemoveFormation (id) {
      this.active = id

      const formation = this.data.formation.find(({ id }) => id === this.active)
      const hasContent = Object
        .entries(formation)
        .filter(([ key ]) => key !== 'id')
        .some(([ , value ]) => !!value)

      if (!hasContent)
        return this.onRemoveFormationSubmit()

      this.removing = true
    },

    onRemoveFormationCancel () {
      this.active = null
      this.removing = false
    },

    async onRemoveFormationSubmit () {
      this.submiting = true
      await this.deleteFormation(this.selected)
      this.submiting = false

      this.$feedback.add({
        type: 'success',
        message: 'Formação acadêmica',
        highlighted: 'excluída com sucesso'
      })

      this.active = null
      this.removing = false
    },

    async validate () {
      await this.getRequiredSystemFields()

      if (!this.$refs.formGroup)
        return false

      if (!await this.$refs.formGroup.validate())
        return false

      return true
    },

    async onSubmit () {
      if (!await this.validate()) return

      this.submiting = true

      const data = this.$refs.formGroup.formsData
      const [ err ] = await this.setFormations([
        ...data.formation.map(formation => ({
          ...formation,
          customFields: Object.keys(this.customFields.educations.schema)
        })),
      ])

      this.submiting = false

      if (err)
        return this.$feedback.add({
          type: 'error',
          message: 'Erro',
          highlighted: 'ao salvar Formações Acadêmicas. Tente novamente.'
        })

      this.$feedback.add({
        type: 'success',
        message: 'Formação acadêmica',
        highlighted: 'atualizada com sucesso'
      })

      this.$emit('next')
    },

    onSave () {
      if (!this.$refs.formGroup) return
      const data = this.$refs.formGroup.formsData

      this.saveFormation(data.formation)

      this.$emit('previous')
    },

    async uploadFiles (files, endpoint) {
      const uploads = await upload({ files, endpoint })

      return Promise.all(uploads.map(({ request }) => request))
    },

    async deleteFiles (files) {
      const { employeeId } = this

      await Promise.all(files.map(
        file => this.deleteFile({ file, employeeId }))
      )
    },

    onFileModalOpen (index) {
      const data = this.$refs.formGroup?.formsData.formation[index]

      if (!data) return

      this.active = index

      this.filesFormData.files = [ ...data.files || [] ]
      this.showFileModal = true
    },

    onFileModalClose () {
      this.filesFormData = {}
      this.showFileModal = false
      this.active = null
    },

    onRemoveFile ({ file, index }) {
      this.isRemovingFile = true
      this.active = index
      this.removingFile = file
    },

    async onRemoveFileSubmit () {
      const { employeeId } = this

      this.submiting = true

      const [ err ] = await this.deleteFile({
        employeeId,
        file: this.removingFile
      })

      this.submiting = false

      if (err)
        return this.$feedback.add({
          type: 'error',
          message: 'Arquivo',
          highlighted: 'não foi excluído, tente novamente'
        })

      this.$feedback.add({
        type: 'success',
        message: 'Arquivo',
        highlighted: 'excluído com sucesso'
      })

      const data = this.$refs.formGroup?.formsData.formation[this.active] || {}
      const fileIndex = data.files?.findIndex(file => file.id === this.removingFile.id)

      if (fileIndex >= 0)
        data.files.splice(fileIndex, 1)

      this.isRemovingFile = false
    },

    async onFileModalSubmit ({ files }) {
      const customFields = Object.keys(this.customFields.educations?.schema || {})
      const data = this.$refs.formGroup?.formsData.formation[this.active] || {}
      const { id } = this.formation[this.active] || {}
      const { files: initialFiles = [] } = data

      const addedFiles = files
        .filter(file => !file.done)
        .filter(file => !initialFiles.includes(file))

      const removedFiles = initialFiles
        .filter(file => !files.includes(file))

      if (removedFiles.length > 0 && !this.showRemoveFileModal) {
        this.filesFormData = { files }
        this.showRemoveFileModal = true

        return
      }

      this.showRemoveFileModal = false
      this.submiting = true

      const [ err, formation, endpoint ] = await this.setFormation({
        id,
        ...data,
        customFields,
      })

      if (err) {
        this.submiting = false
        return
      }

      await this.deleteFiles(removedFiles)

      this.uploadFiles(addedFiles, endpoint)
        .then(async () => {
          const updatedFiles = [ ...initialFiles, ...addedFiles ]
            .filter(file => !removedFiles.includes(file))
            .map(file => ({ ...file, progress: 0 }))

          data.id = formation.id
          data.files = updatedFiles

          this.filesFormData = {}
          this.showFileModal = false
          this.submiting = false
          this.active = null
        })
        .catch(() => {
          this.submiting = false
        })
    },
  }
}
</script>

<style lang="scss">
.formations-container {
  position: relative;

  @include responsive (tablet, desktop) {
    margin-top: 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 2px 8px 16px 0px rgba(0, 0, 0, 0.06);
  }

  @include responsive (xs-mobile, mobile) {
    & > .c-form-group > .form:first-child {
      padding-top: 30px;
      > .c-title { display: none; }
    }
  }
}
</style>
