<template>
  <div
    class="form-field-validation"
    :style="{ '--height': `${height}px`, '--transition-duration': `${transitionDuration}ms` }"
  >
    <div ref="text" :class="[ 'wrapper', { '-show': show } ]">
      <c-flag class="flag" validation />

      <span class="text" v-html="viewMessage" />
    </div>
  </div>
</template>

<script>
const delay = 300

export default {
  props: {
    /**
     * The validation message
     */
    message: {
      type: String,
      default: '',
    },

    /**
     * If true, the validation message will automatically fade after 3s, instead
     * of persisting.
     */
    temporary: Boolean,

    /**
     * The duration of the fade appear/disappear transition, in
     * miliseconds.
     */
    transitionDuration: {
      type: Number,
      default: 300
    }
  },

  data: () => ({
    height: 0,
    show: false,
    /**
     * By setting a default message, we hot load
     * the font of the validation on mounting, avoiding a wrong
     * scrollHeight calculation when the message
     * is going to be displayed for real
     */
    viewMessage: 'message',
  }),

  watch: {
    message: {
      immediate: true,
      handler (val, oldVal) {
        clearTimeout(this.__actionId)
        this.__actionId = this.addAction(val, oldVal)
      }
    }
  },

  methods: {
    calcHeight () {
      this.$nextTick(() => {
        this.height = Math.max(24, this.$refs.text.scrollHeight)
      })
    },

    addTemporaryMessageAction () {
      return setTimeout(() => {
        this.height = 0
        this.show = false
      }, 3000)
    },

    addAction (message, oldMessage) {
      this.show = false

      if (message && !oldMessage) return this.addCreateMessageAction(message)
      if (message) return this.addUpdateMessageAction(message)
      return this.addClearMessageAction()
    },

    addCreateMessageAction (message) {
      this.viewMessage = message
      this.calcHeight()
      const timeoutId = setTimeout(() => { this.show = true }, delay)

      if (this.temporary)
        this.addTemporaryMessageAction()

      return timeoutId
    },

    addUpdateMessageAction (message) {
      const timeoutId = setTimeout(() => {
        this.viewMessage = message
        this.show = true
        this.calcHeight()
      }, delay)

      if (this.temporary)
        this.addTemporaryMessageAction()

      return timeoutId
    },

    addClearMessageAction () {
      return setTimeout(() => {
        this.height = 0
      }, delay)
    }
  },
}
</script>

<style lang="scss">
.form-field-validation {
  $margin-top: 5px;

  transition: height var(--transition-duration) ease-in;

  height: var(--height);

  & > .wrapper {
    display: flex;
    align-items: center;
    opacity: 0;
    transition: opacity var(--transition-duration);
    margin-top: $margin-top;

    &.-show {
      opacity: 1;
    }

    & > .flag {
      margin-right: 6px;
      margin-bottom: auto;
      margin-top: 3px;
    }

    & > .text {
      @include typo(body-2);
      font-size: 14px;
      font-weight: 600;
      color: color-var(negative);
    }
  }
}
</style>
