import * as types from '@types'
import * as services from '@services/location'

export default {
  state: {
    countries: [],
    states: [],
    cities: {}
  },

  getters: {
    getState: (state) => stateId =>
      state.states.find(({ id }) => id === stateId) || {},

    getCities: (state) => stateId => {
      const { abbreviation } = state.states.find(({ id }) => id === stateId) || {}

      return state.cities[abbreviation] || []
    }
  },

  mutations: {
    [types.LOCATION_COUNTRIES]: (state, countries) => {
      state.countries = [ ...countries ]
    },

    [types.LOCATION_STATES]: (state, states) => {
      state.states = [
        ...states,
        ...state.states
      ]
    },

    [types.LOCATION_CITIES]: (state, data) => {
      const { abbreviation } = state.states.find(({ id }) => id === data.state) || {}

      if (!abbreviation) return

      state.cities = {
        ...state.cities,
        [abbreviation]: data.cities
      }
    }
  },

  actions: {
    [types.LOCATION_COUNTRIES]: async ({ commit, state }) => {
      if (state.countries.length) return state.countries

      const [ err, countries ] = await services.getCountries()

      if (!err) commit(types.LOCATION_COUNTRIES, countries)

      return countries
    },

    [types.LOCATION_STATES]: async ({ commit, state }) => {
      if (state.states.length) return state.states

      const [ err, states ] = await services.getStates()

      if (!err) commit(types.LOCATION_STATES, states)

      return states
    },

    [types.LOCATION_CITIES]: async ({ commit, getters }, stateId) => {
      const cities = getters.getCities(stateId)

      if (cities.length) return cities

      const [ err, data ] = await services.getCities(stateId)

      if (!err) commit(types.LOCATION_CITIES, { state: stateId, cities: data })

      return data
    }
  }
}
