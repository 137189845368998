export default (gradientId, gradientArray) => {
  return `
  <defs>
    <linearGradient
      x1="107.425318%"
      y1="-15.3444871%"
      x2="0%"
      y2="63.0195012%"
      id="${gradientId}"
    >
      <stop stop-color="${gradientArray[0]}" offset="0%"></stop>
      <stop stop-color="${gradientArray[1]}" offset="100%"></stop>
    </linearGradient>
  </defs>`
}
