import { generateUUID, fileOpenMethod } from '@convenia/helpers'

const filePreview = file => {
  return () => new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => resolve(reader.result)
  })
}

export const getFileExtension = file => {
  const index = file.name.lastIndexOf('.')
  return (index < 1) ? '' : file.name.substr(index + 1).toLowerCase()
}

export default class CUploadFile {
  constructor (file) {
    this.id = file.id || generateUUID()
    this.data = file
    this.name = file.name
    this.size = file.size
    this.error = ''
    this.extension = getFileExtension(file)
    this.preview = filePreview(file)
    this.url = filePreview(file)
    this.openMethod = file.openMethod || fileOpenMethod
    this.progress = 0
    this.done = false
    this.request = null
    this.aborted = false
    this.upload = false
    this.nestable = false
    this.nestedFile = null
  }
}
