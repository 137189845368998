<template>
  <div class="grid-stepper" :style="styles">
    <div
      v-for="(content, i) in indexedElements"
      :key="i"
      class="element"
    >
      <div class="all-content" />

      <div :class="[ 'top-bar', { '-first': i === 0 } ]" />

      <div :class="[ 'top-content', { '-selected': content.isSelected } ]">
        <slot name="top-content" :content="content" />
      </div>

      <div class="additional-info">
        <slot name="additional-info" :content="content" />
      </div>

      <div :class="markerClasses(content)">
        <div class="bar" />

        <div class="circle"><div class="point" /></div>
      </div>

      <div class="target-content">
        <slot name="target-content" :content="content" />
      </div>

      <div :class="[ 'bottom-bar', { '-last': i === (elements.length - 1) } ]" />

      <div :class="[ 'bottom-content', { '-selected': content.isSelected } ]">
        <slot name="bottom-content" :content="content" />
      </div>

      <div class="gap">
        <slot name="gap" :content="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Array of Objects containing the content data of grid for the slot usage
     */
    elements: {
      type: Array,
      default: () => ([])
    },

    selected: {
      type: [ Array, Number ],
      default: () => ([])
    },

    markerWidth: {
      type: Number,
      default: 16
    },

    barSize: {
      type: Number,
      default: 2
    },

    additionalInfoWidth: {
      type: Number,
      default: 0
    }
  },

  computed: {
    styles () {
      return {
        '--additional-info-width': `${this.additionalInfoWidth}px`,
        '--bar-size': `${this.barSize}px`,
        '--marker-size': `${this.markerWidth}px`,
        '--dot-size': `${this.markerWidth / 2}px`
      }
    },

    indexedElements () {
      const selected = (Array.isArray(this.selected) ? this.selected : [ this.selected ])
      return this.elements.map((element, i) => ({
        ...element,
        index: i,
        isSelected: selected.includes(i)
      }))
    }
  },

  methods: {
    markerClasses ({ isSelected, highlight, index }) {
      return [
        'marker', {
          '-selected': isSelected,
          '-highlight': highlight,
          '-first': index === 0,
          '-last': index === (this.elements.length - 1)
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.grid-stepper > .element {
  display: grid;
  width: 100%;

  grid-template-columns: var(--additional-info-width) var(--marker-size) auto;

  & > .all-content { grid-row: 1 / 4; }

  & > .top-bar, & > .bottom-bar { grid-column: 1 / 3; }

  & > .top-content, & > .bottom-content, & > .target-content, & > .all-content, & > .gap {
    grid-column: 3 / 4;
  }

  & > .top-bar, & > .top-content { grid-row: 1 / 2; }

  & > .bottom-content { grid-row: 3 / 4; }

  & > .bottom-bar { grid-row: 3 / 5; }

  & > .additional-info, & > .marker, & > .target-content { grid-row: 2 / 3; }

  & > .gap { grid-row: 4 / 5; }

  & > .top-bar:not(.-first), & > .bottom-bar:not(.-last) {
    margin-left: calc(var(--additional-info-width) + (var(--marker-size) - var(--bar-size))/2);
    width: var(--bar-size);
    background: color-var(text, base-10);
    height: 100%
  }

  & > .marker {
    position: relative;

    min-height: var(--marker-width);

    $size: var(--dot-size);

    & > .bar {
      width: var(--bar-size);
      background: color-var(text, base-10);
      margin: auto;
      height: 100%
    }

    &.-first > .bar {
      height: 50%;
      transform: translateY(100%);
    }

    &.-last > .bar {
      height: 50%;
    }

    &.-last.-first > .bar {
      visibility: hidden;
    }

    & > .circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      border-radius: 20px;
      width: 100%;
      height: $size;
      width: $size;
      background: #B6BAC6;
      transition: .2s ease-in-out;
    }

    &.-highlight > .circle,
    &.-selected > .circle {
      background: color-var();
      box-shadow: 0 calc(#{$size}/2) #{$size} rgba(color-var(primary, base-rgb), 0.5);
      width: var(--marker-size);
      height: var(--marker-size);

      & > .point {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: $size;
        height: $size;
        border-radius: 20px;
        background: #FFFFFF;
      }
    }
  }
}

</style>
