<template>
  <svg
    :class="[ 'c-icon', `-${iconPkg}` ]"
    :style="style"
    :viewBox="viewBox"
    :fill="iconPkg === 'lindua'
      && gradient && `url(#${gradientId})`"
    v-bind="$attrs"
  />
</template>

<script>
import IconGradient from './IconGradient.js'

const loadedIcons = {}
const gradients = {
  primary: [ '#BC4CF7', '#7873EE' ],
  positive: [ '#3CE797', '#37CECD' ],
  negative: [ '#FF7EB3', '#FF7987' ],
  alert: [ '#F6D365', '#FDA085' ],
  disabled: [ '#E7E9ED', '#404A6C' ]
}

export default {
  name: 'CIcon',

  props: {
    size: {
      type: [ String, Number ],
      default: 24
    },
    icon: {
      type: String,
      default: 'bill'
    },
    gradient: {
      type: [ String, Array ],
      validator: gradient => {
        return typeof gradient === 'string'
          ? Object.keys(gradients).includes(gradient)
          : gradient.length === 2
      },
      default: null,
    },
    iconPkg: {
      type: String,
      default: 'convenia',
      validator: v => [ 'convenia', 'lindua' ].includes(v)
    }
  },

  computed: {
    style () {
      const stroke = this.iconColor && this.iconPkg === 'convenia'
        ? { stroke: this.iconColor }
        : {}

      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        ...stroke
      }
    },

    iconColor () {
      if (!this.gradient) return

      return typeof this.gradient === 'string'
        ? gradients[this.gradient][0]
        : this.gradient[0]
    }
  },

  data: () => ({
    viewBox: '0 0 0 0',
    gradientId: ''
  }),

  watch: {
    icon (newIcon, oldIcon) {
      if (newIcon !== oldIcon)
        this.loadIcon()
    }
  },

  mounted () {
    this.loadIcon()
  },

  methods: {
    loadIcon () {
      if (loadedIcons[this.icon])
        return loadedIcons[this.icon].then(this.addToDom)

      loadedIcons[this.icon] = this.requestIcon(this.icon)
        .then(this.addToDom)
    },

    requestIcon (icon) {
      return new Promise((resolve, reject) => {
        try {
          fetch(`/img/icons/${this.iconPkg}/${icon}.svg`)
            .then(res => res.text())
            .then(resolve)
        } catch (e) {
          reject(new Error(`Could not find icon ${icon}`))
        }
      })
    },

    addToDom (html) {
      const template = document.createElement('template')

      template.innerHTML = html
        .replace(/<!--.*?-->/g, '')
        .replace(/<\?.*?\?>/g, '')
        .trim()

      const svg = template.content.firstChild

      this.$el.innerHTML = this.createGradient(svg).innerHTML
      this.updateViewBox(svg.viewBox)

      return html
    },

    updateViewBox (viewBox) {
      if (!viewBox) return

      const value = viewBox.baseVal
      this.viewBox = `${value.x} ${value.y} ${value.width} ${value.height}`
    },

    createGradient (el) {
      if (!this.gradient) return el

      this.gradientId = 'a' + Math.random().toString(36).substring(2)

      const gradientArray = typeof this.gradient === 'string'
        ? gradients[this.gradient]
        : this.gradient

      el.insertAdjacentHTML(
        'afterbegin',
        IconGradient(this.gradientId, gradientArray)
      )

      return el
    }
  }
}
</script>

<style lang="scss">
.c-icon {
  outline: none;
  pointer-events: none;
  display: inline-block;
}
</style>
