const currencyFilter = (
  value,
  locale = 'pt-BR',
  format = 'BRL',
) => {
  if (Number.isNaN(+value) || !locale || !format)
    return '-'

  const formatter = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: format
  })

  return formatter.format(+value)
}

export default currencyFilter
