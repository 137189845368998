export const MediaQueryRoot = {
  data () {
    return {
      xsMobileMedia: window.matchMedia('(max-width: 576px)'),
      mobileMedia: window.matchMedia('(max-width: 1023px)'),
      tabletMedia: window.matchMedia('(max-width: 1279px)'),
      isXsMobile: false,
      isMobile: false,
      isTablet: false
    }
  },

  methods: {
    setBreakpoint () {
      this.isMobile = this.mobileMedia && this.mobileMedia.matches
      this.isXsMobile = this.xsMobileMedia && this.xsMobileMedia.matches
      this.isTablet = this.tabletMedia && this.tabletMedia.matches
    },

    getIsXsMobile () {
      return this.isXsMobile
    },

    getIsMobile () {
      return this.isMobile
    },

    getIsTablet () {
      return this.isTablet
    },

    getIsSafari () {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    },

    getIsIOS () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    }
  },

  beforeDestroy () {
    this.tabletMedia.removeListener(this.setBreakpoint)
    this.mobileMedia.removeListener(this.setBreakpoint)
    this.xsMobileMedia.removeListener(this.setBreakpoint)
  },

  mounted () {
    this.tabletMedia.addListener(this.setBreakpoint)
    this.mobileMedia.addListener(this.setBreakpoint)
    this.xsMobileMedia.addListener(this.setBreakpoint)
    this.setBreakpoint()
  },

  provide () {
    return {
      getIsXsMobile: this.getIsXsMobile,
      getIsMobile: this.getIsMobile,
      getIsTablet: this.getIsTablet,
      getIsSafari: this.getIsSafari,
      getIsIOS: this.getIsIOS
    }
  }
}
