<template>
  <c-modal
    is-opened
    class="crop-modal"
    title="Adicionar foto de perfil"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <transition name="fade" mode="out-in">
      <c-loader v-if="loading" :size="isMobile ? 79 : 99" />

      <c-cropper
        v-else
        ref="cropper"
        :src="value"
        @cropped="onCrop"
      />
    </transition>

    <div v-if="!isMobile" class="actions">
      <c-button flat @click="onClose">
        Voltar
      </c-button>

      <c-button primary @click="onSubmit">
        Salvar
      </c-button>
    </div>

    <c-button
      v-if="isMobile"
      primary
      class="submit"
      @click="onSubmit"
    >
      Salvar
    </c-button>
  </c-modal>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CropModal',

  mixins: [ MediaQuery ],

  props: {
    value: {
      type: String,
      default: null
    }
  },

  data: () => ({
    loading: true
  }),

  mounted () {
    setTimeout(this.onLoad, 1000)
  },

  methods: {
    onLoad () {
      this.loading = false
    },

    onRemove () {
      this.$emit('remove')
    },

    onClose () {
      this.$emit('close')
    },

    onSubmit () {
      if (!this.$refs.cropper) return

      this.$refs.cropper.crop()
    },

    onCrop (image) {
      this.$emit('submit', image)
    }
  }
}
</script>

<style lang="scss">
.crop-modal {
  & > .wrapper > .modal > .content {
    flex: 1;
    display: flex;
    flex-flow: column;

    & > .c-loader {
      margin: auto;
    }
  }

  @include responsive (xs-mobile, mobile) {
    & > .wrapper > .modal {
      & > .header {
        padding: 10px 10px 20px;
      }

      & > .content {
        overflow: auto;
        padding-bottom: 100px;

        & > .submit {
          width: 100%;
          border-radius: 0 !important;
          min-height: 60px !important;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  @include responsive (tablet, desktop) {
    & > .wrapper > .modal {
      max-width: 960px;
      min-height: 620px;

      & > .content {
        & > .actions {
          display: flex;
          margin-top: 40px;
          justify-content: center;

          .c-button {
            margin: 0 10px; width: 180px;
            &.-error {
              & > .text { color: #FF7EB3; }
            }
          }
        }
      }
    }
  }
}
</style>
