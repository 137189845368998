<template>
  <div class="c-grid-table" :style="styles">
    <div
      v-if="!emptyTable"
      :class="[ 'header', { '-show-mobile-header': showMobileHeader } ]"
    >
      <div
        v-for="(title, key, index) in columns"
        :key="`column-${key}`"
        :class="[ 'column', columnStyles[index] ]"
        :style="{ flex: getColumnSize(key) }"
      >
        <slot :name="`header-${key}`">
          <span class="text">{{ title }}</span>
        </slot>
      </div>

      <div
        v-if="!noActions"
        class="column -actions"
        :style="{ width: `${actionsWidth}px` }"
      >
        <span class="text">{{ actionsLabel }}</span>
      </div>
    </div>

    <div class="add">
      <slot name="add" />
    </div>

    <c-transition
      v-if="!emptyTable"
      group
      tag="div"
      class="body"
    >
      <div
        v-for="(row, index) in rows"
        ref="rows"
        :key="`row-${row.id || index}`"
        :class="rowStyles[index].classes"
        :style="rowStyles[index].styles"
        @click="$emit('row-click', row)"
      >
        <div
          v-for="(_, key, i) in columns"
          :key="`item-${key}`"
          :class="[ 'column', { '-collapsible': collapsible } ]"
          :style="{ flex: getColumnSize(key) }"
        >
          <div :class="[ 'content', columnStyles[i] ]">
            <slot :name="`cell-${key}`" :row="row" :item="row[key]" :index="index">
              <span v-if="enableEllipsis" v-tooltip.ellipsis.dark :class="row.text">
                {{ row[key] }}
              </span>

              <span v-else :class="row.text">
                {{ row[key] }}
              </span>
            </slot>
          </div>
        </div>

        <div
          v-if="!noActions"
          :class="[ 'column', '-actions', { '-collapsible': collapsible } ]"
        >
          <div ref="actions-content" class="content">
            <slot name="actions" :row="row" :index="index">
              <c-button
                v-for="(icon, name) in enabledActions"
                :key="name"
                flat
                :class="getButtonClasses(name, index)"
                :icon="icon"
                :error="name == 'remove'"
                :size="buttonSize"
                :icon-size="iconSize"
                v-tooltip.dark="{ value: actionsTooltip[name], hide: !actionsTooltip[name] }"
                @click.stop="onActionClick(name, row, index)"
              />
            </slot>
          </div>
        </div>
      </div>
    </c-transition>
  </div>
</template>

<script>
import { debounce } from '@convenia/helpers'

const ACTIONS_MARGIN = 10
const ACTIONS_PADDING = 10
const ROW_VERTICAL_PADDING = 15

export default {
  name: 'CGridTable',

  props: {
    columnsSize: {
      type: Array,
      default: () => ([])
    },

    /** Custom table field alignment
     * example: () => ([ 'left', 'left', 'right', 'right' ])
     */
    columnsAlignment: {
      type: Array,
      default: () => ([]),
      validator: value => [ 'left', 'right' ].includes(value) || []
    },

    columns: {
      type: Object,
      default: () => ({})
      // example: () => ({ manager: 'Departamento(s)', linked: 'Atrelados' })
    },

    rows: {
      type: Array,
      default: () => ([])
      // example: () => ([ { manager: 'Administração', linked: '9 pessoas' } ])
    },

    actions: {
      type: Object,
      default: () => ({})
    },

    actionsTests: {
      type: Object,
      default: () => ({})
    },

    actionsLabel: {
      type: String,
      default: ''
    },

    actionsSize: {
      type: String,
      default: 'sm',
      validator: value => [ 'sm', 'md' ].includes(value)
    },

    actionsTooltip: {
      type: Object,
      default: () => ({})
    },

    addText: {
      type: String,
      default: ''
    },

    editable: Boolean,

    noActions: Boolean,

    rowHeight: {
      type: [ String, Number ],
      default: 70
    },

    columnGap: {
      type: [ String, Number ],
      default: 20
    },

    collapsible: Boolean,

    /**
     * Allows the component to receive a especial property in the rows
     * array called 'customClass', which defines a custom class for that row
     */
    useRowCustomClass: Boolean,

    enableEllipsis: Boolean,

    showMobileHeader: Boolean,

    noHover: Boolean
  },

  data: () => ({
    observers: [],
    contentHeights: null,
    hideContents: null,
    actionsWidth: 0,
  }),

  mounted () {
    this.$nextTick(() => {
      if (this.collapsible) {
        this.initObservers()
        this.setCollapsibleData()
      }

      this.setActionsWidth()
    })
  },

  beforeDestroy () {
    if (this.observers.length)
      this.observers.forEach(obs => obs.disconnect())
  },

  computed: {
    styles () {
      return {
        '--column-gap': `${this.columnGap}px`,
        '--column-padding': `${this.columnGap / 2}px`
      }
    },

    emptyTable () {
      return this.rows.length === 0
    },

    enabledActions () {
      const { collapsible, hasCollapsibleRows, actionsTests } = this

      const actions = {
        ...this.actions,
        ...(collapsible && hasCollapsibleRows ? { collapse: 'arrow-down' } : {})
      }

      const entries = Object.entries(actions).filter(([ name ]) => {
        return actionsTests[name] !== undefined ? actionsTests[name] : true
      })

      return Object.fromEntries(entries)
    },

    hasCollapsibleRows () {
      return (this.contentHeights || []).some(height => height > this.rowHeight)
    },

    buttonSize () {
      if (this.actionsSize === 'sm') return 30
      if (this.actionsSize === 'md') return 40
      return 30
    },

    iconSize () {
      if (this.actionsSize === 'sm') return 18
      if (this.actionsSize === 'md') return 22
      return 18
    },

    rowStyles () {
      const { rows, hideContents, contentHeights, rowHeight, actionsWidth, noHover } = this

      return (rows || []).map(({ customClass, fade }, index) => {
        const isExpanded = !(hideContents || {})[index]
        const height = !hideContents || !contentHeights
          ? rowHeight
          : hideContents[index] ? rowHeight : contentHeights[index]

        return {
          styles: {
            '--row-height': `${height}px`,
            '--min-row-height': `${rowHeight}px`,
            '--row-vertical-padding': `${ROW_VERTICAL_PADDING}px`,
            '--actions-width': `${actionsWidth}px`
          },
          classes: [
            'row',
            ...(fade ? [ '-fade' ] : []),
            ...(this.useRowCustomClass ? [ customClass ] : []),
            { '-expanded': isExpanded },
            { '-no-hover': noHover }
          ]
        }
      })
    },

    columnStyles () {
      return (this.columnsAlignment || []).map((value) => ({
        '-has-alignment': value,
        '-text-left': value === 'left' || value,
        '-text-right': value === 'right'
      }))
    }
  },

  methods: {
    getColumnSize (key) {
      const index = Object.keys(this.columns).indexOf(key)

      if (index === -1) return null

      return this.columnsSize[index] || null
    },

    getButtonClasses (name, index) {
      const conditional = {
        '-collapsed': (this.hideContents || [])[index] && name === 'collapse',
        '-hidden': (this.contentHeights || [])[index] === this.rowHeight
      }

      return [ `c-button-${name}`, conditional ]
    },

    onActionClick (name, row, index) {
      if (name === 'collapse') return this.toggleCollapse(index)

      this.$emit(`action:${name}`, row)
    },

    setCollapsibleData () {
      const { rows } = this.$refs

      if (!(this.$refs && rows)) return

      this.hideContents = Array.from(rows, () => true)

      this.contentHeights = rows.map(row => {
        const columns = [ ...row.children ].map(({ scrollHeight }) => scrollHeight)
        return Math.max.apply(0, columns) + (ROW_VERTICAL_PADDING * 2)
      })
    },

    debouncedSetCollapsibleData: debounce(function debounce () {
      this.setCollapsibleData()
    }, 100),

    toggleCollapse (index) {
      this.$set(this.hideContents, index, !this.hideContents[index])
    },

    initObservers () {
      (this.$refs.rows || []).forEach((row, index) => {
        this.observers[index] = new MutationObserver(this.debouncedSetCollapsibleData)
        this.observers[index].observe(row, { childList: true, subtree: true })
      })
    },

    setActionsWidth () {
      if (this.$scopedSlots?.actions) {
        this.actionsWidth = this.$refs['actions-content']?.[0]?.offsetWidth || 'auto'
        return
      }

      const amount = Object.keys(this.enabledActions)?.length
      const buttonsWidth = this.buttonSize * amount
      const marginWidth = ACTIONS_MARGIN * amount

      this.actionsWidth = buttonsWidth + marginWidth + ACTIONS_PADDING
    }
  }
}
</script>

<style lang="scss">
.c-grid-table {
  & > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid color-var(disabled);
    padding: 10px 15px;

    @include responsive (xs-mobile, mobile) {
      padding: { left: 10px; right: 10px; }

      &:not(.-show-mobile-header) { display: none; }
    }

    & > .column {
      flex: 1;
      min-width: 0;
      padding: 0 var(--column-padding);
      &:first-child { padding-left: 0px; }

      &.-has-alignment {
        &.-text-left { text-align: left; }
        &.-text-right { text-align: right; }
      }

      & > .text {
        text-transform: uppercase;
        line-height: 14px;
        display: block;
        white-space: pre-line;

        @include typo(h5, base-50);
      }

      &:not(:first-child) { text-align: right; }
      &.-actions { flex: initial; padding-right: 0; }
    }
  }

  & > .add > .c-add {
    margin-top: 20px;
    height: 70px;
  }

  & > .body > .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid color-var(disabled);
    padding: var(--row-vertical-padding) 15px;
    height: var(--row-height);
    transition: height 0.2s;

    &.-fade {
      opacity: .2;
      user-select: none;
      pointer-events: none;
    }

    &:first-child { border-top: 0; }

    @include responsive (xs-mobile, mobile) {
      padding: { left: 10px; right: 10px; }
    }

    &.-expanded > .column.-collapsible:not(.-actions) { overflow: visible; }

    & > .column {
      position: relative;
      height: 100%;
      min-width: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 var(--column-padding);
      &:first-child { padding-left: 0px; }

      &:first-child > .content {
        justify-content: flex-start;

        & > .text { text-align: left; }
      }

      & > .content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include typo(body-1);

        &.-has-alignment {
          &.-text-left { justify-content: start; }
          &.-text-right { justify-content: flex-end; }
        }

        & > .text { text-align: right; }
      }

      &.-collapsible  {
        &:not(.-actions) { overflow: hidden; }

        & > .content {
          position: absolute;
          top: 0;
          min-height: calc(var(--min-row-height) - (2 * var(--row-vertical-padding)));
          width: calc(100% - var(--column-gap));
        }
      }
    }

    & > .-actions {
      flex: 0;
      min-width: var(--actions-width);
      align-items: flex-end;
      justify-content: center;
      padding-right: 0;

      & > .content {
        & > .c-button {
          opacity: 0;
          @include responsive (xs-mobile, tablet) { opacity: 1; }
        }

        & > .c-button-collapse {
          opacity: 1;

          &.-hidden { visibility: hidden; }
          & > .icon { transition: transform 0.2s; }
          &:not(.-collapsed) > .icon { transform: rotate(180deg); }
        }

        & > .c-button-share > svg { transform: translateX(-5%); }
      }
    }

    @include desktop {
      &:hover {
        background-color: var(--color-text-base-02);
        & > .column.-actions > .content > .c-button { opacity: 1; }
      }

      &.-no-hover:hover {
        background-color: inherit;
      }
    }
  }
}
</style>
