import middleware from '@graphql/middleware'
import { GetEmployeeContracts } from '@graphql/middleware/queries'

export const getContracts = async (getters) => {
  const employeeId = getters.getEmployeeUuid

  const [ err, response ] = await middleware(GetEmployeeContracts, { employeeId })

  return [ err, response?.contracts ]
}

export const getContractsDownloadURL = (getters) => {
  const employeeId = getters.getEmployeeUuid

  const companyId = getters.getCompanyUuid

  return import.meta.env.VITE_REST_URL + `/companies/${companyId}/employees/${employeeId}/contracts/download/all`
}
