<template>
  <form-field
    class="c-form-field-row"
    is-row
    :value="value"
    :field-schema="rowSchema"
    :field-name="fieldName"
    :form-schema="formSchema"
    :form-value="formValue"
    :has-row-labels="hasRowLabels"
    :style="{ '--rows': fieldSchema.schemas.length }"
    v-bind="$attrs"
    @list:add="$emit('list:add')"
    @list:remove="$emit('list:remove')"
  >
    <form-field
      v-for="(schema, i) in fieldSchema.schemas"
      ref="fields"
      :key="`${schema.label}-${i}`"
      :field-schema="schema"
      :form-schema="formSchema"
      :value="value[i]"
      :form-value="formValue"
      :field-name="`${fieldName}-${i}`"
      :validation-field-name="`${fieldName}-${i}`"
      :field-options="getOptions(schema, `${fieldName}-${i}`)"
      :has-validation="hasValidation"
      @input="onInputItem($event, i)"
      @pre-validate="$emit('pre-validate')"
    />

    <c-divider v-if="!(rowSchema || {}).disableDivider" />
  </form-field>
</template>

<script>
import SubmitFormat from '../mixins/SubmitFormat'
import FormField from './FormField'

export default {
  mixins: [ SubmitFormat({ schemaRef: 'fieldSchema', childRefsName: 'fields' }) ],

  components: {
    FormField
  },

  props: {
    fieldSchema: {
      type: Object,
      default: () => ({}),
    },

    fieldName: {
      type: String,
      default: '',
    },

    fieldOptions: {
      type: Array,
      default: () => [],
    },

    formSchema: {
      type: Object,
      default: () => ({}),
    },

    hasValidation: Boolean,

    hasRowLabels: Boolean,

    formValue: {
      type: Object,
      default: () => ({}),
    },

    value: {
      required: true,
      validator: () => true
    },

    rowFieldOptions: {
      type: Object,
      default: () => ({})
    },
  },

  computed: {
    rowSchema () {
      return {
        ...this.fieldSchema,
        type: 'slot',
      }
    }
  },
  methods: {
    onInputItem (newItemValue, index) {
      const newValue = [ ...this.value ]
      newValue[index] = newItemValue
      this.$emit('input', newValue)
    },

    getOptions (schema, fieldName) {
      return this.rowFieldOptions[schema?.optionsSrc || fieldName] || []
    }
  }
}
</script>

<style lang="scss">
.c-form-field-row {
  position: relative;

  & > .input-slot > .c-divider {
    --offset: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  @include responsive (tablet, desktop) {
    & > .input-slot {
      display: grid;
      grid-template-columns: repeat(var(--rows), minmax(0, 1fr));
      grid-column-gap: 20px;

      & > .c-form-field {
        margin: 0;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: min-content min-content 1fr;
      }

      & > .c-divider { display: none; }
    }
  }
}
</style>
