<template>
  <div :class="classes" :ref-cy="id" :data-testid="id">
    <label v-if="label || labelIcon" class="label">
      <c-icon
        v-if="labelIcon"
        :size="labelIconSize"
        :icon="labelIcon"
        class="icon"
      />

      <span v-else class="text">{{ label }}</span>

      <span v-if="required" class="required">*</span>
    </label>

    <!-- @slot The default slot for the form field. -->
    <slot />

    <div v-if="after" class="after">
      <span class="text" v-html="after" />
    </div>

    <div class="validation" :style="{ height: validationHeight }">
      <transition name="fade" @enter="onEnter" @leave="onLeave">
        <div v-if="!!validation" class="wrapper">
          <c-flag class="flag" validation />

          <span class="text" v-html="validation" />
        </div>
      </transition>
    </div>

    <div v-if="hint" class="hint">
      <span class="text" v-html="hint" />
    </div>
  </div>
</template>

<script>
import { tooltip } from '@convenia/components/directives'

/**
 * The container component for form fields, anything from select fields, input
 * or checkboxes. Adds standard styles for label, validations and etc.
 */
export default {
  name: 'CInputContainer',

  directives: { tooltip },

  props: {
    /**
     * The id of the field.
     */
    id: {
      type: [ String, Number ],
      default: ''
    },
    /**
     * The label of the field.
     */
    label: {
      type: String,
      default: ''
    },

    /**
     * Uses a icon a for the field label.
     */
    labelIcon: {
      type: String,
      default: '',
    },

    /**
     *
     */
    labelIconSize: {
      type: [ String, Number ],
      default: 24
    },

    /**
     * Displays the label on the left-side instead of in the top.
     */
    labelLeft: Boolean,

    /**
     * The field validation message.
     */
    validation: {
      type: String,
      default: '',
    },

    /**
     * The field hint message.
     */
    hint: {
      type: String,
      default: '',
    },

    /**
     * Custom after content
     */
    after: {
      type: String,
      default: '',
    },

    /**
     * Whether the hint should be displayed.
     */
    showHint: Boolean,

    /**
     * Adds a visual markes to mark the field as required.
     */
    required: Boolean,

    /*
     * Flag to manipulate the upload type
     */
    isUploadType: Boolean
  },

  computed: {
    classes () {
      return [ 'c-input-container', {
        '-label': this.label,
        '-label-left': this.labelLeft,
        '-validation': this.validation,
        '-show-hint': this.showHint
      } ]
    }
  },

  data: () => ({
    validationHeight: '0px'
  }),

  methods: {
    onEnter (element) {
      this.$nextTick(() => {
        this.validationHeight = element.clientHeight + 'px'
      })
    },

    onLeave () {
      setTimeout(() => {
        this.validationHeight = '0px'
      }, 100)
    }
  }
}
</script>

<style lang="scss">
%validation {
  position: relative;
  transition: height .3s .1s;

  & > .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;

    margin-top: 5px;
    margin-bottom: 15px;

    font-weight: 600;
    color: color-var(negative);
    -webkit-font-smoothing: antialiased;

    & > .flag { margin-right: 6px; margin-top: 1px; }

    & > .text {
      font-size: 14px;

      > b { -webkit-font-smoothing: antialiased; }
    }
  }

  & > .fade-enter-active {
    transition: opacity .3s .1s !important;
  }

  & > .fade-leave-active {
    transition: opacity .3s !important;
  }

  & > .fade-enter,
  & > .fade-leave-to {
    opacity: 0 !important;
  }
}

.c-input-container:not(.c-upload) {
  position: relative;

  &.-label:not(.-label-left) {
    margin-top: 27px;
    @include responsive (xs-mobile, mobile) { margin-top: 22px; }
  }

  &.c-radio-button.-label-left > .label { align-items: flex-start }

  &.-label-left > .label {
    display: flex;
    align-items: center;
    height: 40px;
    top: 0px;
    right: 100%;
    width: 210px;
    text-align: right;
    justify-content: flex-end;
    padding: { left: 20px; right: 20px; }
  }

  & > .label {
    position: absolute;
    top: -27px;
    font-size: 11px;
    margin-bottom: 15px;
    padding-right: 10px;
    text-transform: uppercase;
    font-weight: 500;
    font-family: var(--title-font-family);
    color: color-var(text, base-50);

    @include responsive (xs-mobile, mobile) { top: -22px; margin-bottom: 10px; }

    & > .required {
      font-size: 10px;
      margin-left: 3px;
      color: color-var(negative);
    }

    & > .icon { @include icon-color(text, base-30); }
  }

  & > .validation {
    @extend %validation;
  }

  & > .hint {
    position: absolute;
    width: 999px;
    max-width: 330px;
    left: calc(100% + 22px);
    top: -16px;
    padding-left: 12px;
    visibility: hidden;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 2px;
      height: 0%;
      background: color-var(text, base-10);
      transform: translate(-100%, -50%);
      transition: .3s ease-out;
    }

    &:after {
      content: "";
      position: absolute;
      left: -1px;
      top: 50%;
      width: 6px;
      height: 6px;
      transform: translate(-50%, -50%);
      border-radius: 6px;
      opacity: 0;
      background-color: color-var(text, base);
      transition: .3s ease-out;
    }

    > .text {
      color: color-var(text, base);
      font-size: 14px;
      line-height: 19px;
      display: block;
      transform: translateX(-4px);
      opacity: 0;
      transition: .3s ease-out;
    }
  }

  & > .after {
    margin-top: 8px;
    display: flex;

    & > .text {
      color: color-var(text, base-30);
      font-family: var(--title-font-family);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 14px;
    }
  }

  &.-show-hint {
    @include responsive (tablet, desktop) {
      & > .hint {
        visibility: visible;

        &:before { height: 100%; }
        &:after { opacity: .5; }
        > .text{
          opacity: .5;
          transform: translateX(0px);
        }
      }
    }
  }
}

.c-input-container.c-upload {
  & > .validation {
    right: 50%;
    position: absolute;
    bottom: 15px;
    @extend %validation;

    & > .wrapper {
      white-space: nowrap;
      transform: translateX(-50%);
    }
  }

  &.-is-small {
    &:has(.form-field-validation.validation) { padding-bottom: 10px; }

    > .validation { bottom: auto; top: calc(100% - 5px); }
  }
}

.c-input-container.c-radio-button > .label {
  @include responsive (tablet, desktop) {
    line-height: 25px;
  }
}
</style>
