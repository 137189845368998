<template>
  <div :class="classes">
    <transition :name="countTransition" @after-leave="toggleShow">
      <span v-if="show" class="value">
        {{ displayedValue }}
      </span>
    </transition>
  </div>
</template>

<script>

/**
 * A very simple yet beautiful counter.
 */
export default {
  name: 'CCounter',
  props: {
    /**
     * The value of the counter.
     */
    value: {
      type: [ String, Number ],
      required: true
    },

    /**
     * The color of the text of the counter.
     */
    white: Boolean,

    /**
     * Make zero always darker.
     */
    darkZero: Boolean,

    /**
     * The makes the counter's background darker when in zero state.
     */
    darkZeroState: Boolean,

    /**
     * The makes the counter's background lighter when in zero state.
     */
    lightZeroState: Boolean,

    /**
     * A smaller version of the same counter
     */
    small: Boolean,

    /**
     * Makes the text and bg colors grayish
     */
    muted: Boolean
  },

  data () {
    return {
      show: true,
      valueHistory: [ this.value ],
      countTransition: null,
      displayedValue: null
    }
  },

  computed: {
    classes () {
      return [ 'c-counter', {
        '-zero': this.value === 0 && !this.darkZero,
        '-white': this.white,
        '-muted': this.muted,
        '-dark-zero-state': this.darkZeroState,
        '-light-zero-state': this.lightZeroState,
        '-small': this.small
      } ]
    }
  },

  watch: {
    value (val) {
      if (this.valueHistory.length === 2) this.valueHistory.shift()
      this.valueHistory.push(val)
    },
    valueHistory () {
      const h = this.valueHistory
      this.countTransition = h[1] > h[0] ? 'count-up' : 'count-down'
      this.$nextTick(() => {
        this.toggleShow()
        this.displayedValue = this.value
      })
    }
  },

  methods: {
    toggleShow () {
      this.show = !this.show
    }
  },

  mounted () {
    this.displayedValue = this.value
  }
}
</script>

<style lang="scss">
.c-counter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  height: 25px;
  min-width: 32px;
  padding: 0 8px;

  border-radius: 12.5px;
  background: color-var(positive);
  background-repeat: no-repeat;
  transition: all .2s;

  & > .value {
   @include typo(h3, base-80);
    line-height: 19px;
    z-index: 2;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &.-zero {
    box-shadow: unset;

    &::before { opacity: 0.3; }

    & > .value {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &.-white > .value { color: #fff; }

  &.-dark-zero-state { background: color-var(disabled); }

  &.-small {
    height: 20px;
    min-width: 25px;

    & > .value {
      font-size: 10px;
      line-height: 10px;
    }
  }

  &.-muted {
    background: color-var(text, base-10);

    & > .value { color: color-var(text, base-30); }
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12.5px;
    background: mix($base-text-color, #FFF, 20%);
    z-index: 1;
    opacity: 0;
    transform: scale(1.04);
    transition: all 150ms;
  }

  &:not(.-zero)::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    width: 68.75%;
    height: 60%;
    border-radius: 50px;
    background: $positive-color;
    transform: translateX(-50%);
    z-index: -$z-index-1;
    opacity: 0.5;
    filter: blur(7px) brightness(1);
  }

  &.-light-zero-state::after {
    background: rgba-to-rgb(map-get($text-color, base-10));
  }

  // Transitions
  .count-up-enter,
  .count-down-leave-active {
    opacity: 0;
    transform: translateY(8px);
  }

  .count-down-enter,
  .count-up-leave-active {
    opacity: 0;
    transform: translateY(-8px);
  }

  .count-up-enter-active,
  .count-up-leave-active,
  .count-down-enter-active,
  .count-down-leave-active {
    transition: all 150ms;
  }

}
</style>
