<template>
  <apex-charts
    ref="apex"
    class="c-graph-box"
    type="boxPlot"
    :width="width"
    :height="height"
    :options="options"
    :series="series"
  />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

import CommonOptions from './CommonOptions'

const getPercent = (w, n, x) => {
  const percentWidth = Math.round((x / (w / n)) * 100)
  return `${percentWidth}%`
}

export default {
  name: 'CGraphBox',

  mixins: [ CommonOptions ],

  components: {
    ApexCharts: VueApexCharts
  },

  props: {
    width: {
      type: Number,
      default: undefined
    },

    height: {
      type: Number,
      default: undefined
    },

    value: {
      type: Array,
      default: () => ([])
    },

    categories: {
      type: Array,
      default: () => ([])
    },

    tooltip: {
      type: Function,
      default: ({ name, data, dataPointIndex }) => `${name}: ${data[dataPointIndex]}`
    },

    legends: {
      type: Array,
      default: undefined
    },

    xOptions: {
      type: Object,
      default: () => ({
        title: undefined,
        min: undefined,
        max: undefined,
        tickAmount: undefined,
        formatter: undefined,
        trimLabels: undefined,
      })
    },

    yOptions: {
      type: Object,
      default: () => ({
        title: undefined,
        min: undefined,
        max: undefined,
        width: undefined,
        tickAmount: undefined,
        formatter: undefined,
        trimLabels: undefined,
      })
    },

    colors: {
      type: Array,
      default: () => ([ '#4BA9FF' ])
    }
  },

  watch: {
    value (value) {
      this.series = value
    },
  },

  computed: {
    options () {
      return {
        chart: {
          foreColor: 'rgba(18,30,72,0.8)',
          dropShadow: {
            enabled: true,
            color: [ '#63E1A5', '#FF4B8C' ],
            opacity: 0.2,
            top: 0,
            left: 0,
            blur: 3,
          },
          animations: { enabled: false },
          toolbar: { show: false },
        },
        fill: { opacity: 1.0, },
        states: {
          normal: { filter: { type: 'none' } },
          hover: { filter: this.hoverFilter },
          active: { filter: { type: 'none' } },
        },
        grid: { borderColor: 'rgba(18,30,72,0.1)' },
        colors: this.colors,
        stroke: {
          width: 1,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: getPercent(this.gridHeight, this.categories.length, 18),
            borderRadius: 2,
          },
          boxPlot: {
            colors: {
              upper: '#63E1A5',
              lower: '#FF4B8C'
            }
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          showForSingleSeries: true,
          fontSize: '10px',
          fontWeight: 400,
          fontFamily: 'Ubuntu',
          customLegendItems: this.legends,
          markers: {
            width: 8,
            height: 8,
            radius: 8,
          },
          itemMargin: {
            vertical: 5
          },
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: false
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          shared: false,

          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const { data, name } = w.globals.initialSeries[seriesIndex]
            const category = this.categories[dataPointIndex]

            const content = this.tooltip({
              series, data, name, category, seriesIndex, dataPointIndex, w
            })

            const { left, top } = this.calculateTooltipPosition(dataPointIndex)

            return `
              <div class="c-tooltip">
                <div
                  class="c-popover popover -dark"
                  style="
                    left: ${left}px;
                    top: ${top}px;
                    transform: translate(-50%, -120%);
                    --left: calc(50% - 5px);
                  "
                >
                  <div
                    class="card"
                    style="
                      --tooltip-font-size: 11px;
                      --tooltip-max-width: 200px;
                      --tooltip-horizontal-padding: 10px;
                      text-align: left
                    "
                  >
                    ${content}
                  </div>
                </div>
              </div>
            `
          }
        },
        xaxis: {
          categories: this.categories,
          min: this.xOptions.min,
          max: this.xOptions.max,
          tickPlacement: 'on',
          tickAmount: this.xOptions.tickAmount,
          crosshairs: { show: false },
          title: {
            text: this.xOptions.title, // Define title so it "ocuppies" space
            style: { color: 'transparent' }
          },
          labels: {
            rotate: 0,
            trim: this.xOptions.trimLabels,
            hideOverlappingLabels: false,
            formatter: this.xOptions.formatter || (value => `${value}`),
            style: {
              fontSize: '11px',
              fontWeight: 500,
              fontFamily: 'Nunito Sans',
              colors: 'rgba(55,61,63,0.5)'
            }
          }
        },
        yaxis: {
          min: this.yOptions.min,
          max: this.yOptions.max,
          tickAmount: this.yOptions.tickAmount,
          labels: {
            minWidth: this.yOptions.width || 70,
            maxWidth: this.yOptions.width || 70,
            formatter: this.yOptions.formatter || (value => `${value}`),
            style: {
              fontSize: '11px',
              fontWeight: 500,
              fontFamily: 'Nunito Sans',
              colors: 'rgba(55,61,63,0.5)'
            }
          }
        }
      }
    }
  },

  data: (vm) => ({
    series: vm.value,
  }),

  mounted () {
    this.updateHeight()
  },

  methods: {
    calculateTooltipPosition (dataPointIndex) {
      const areas = this.$el.querySelectorAll('.apexcharts-boxPlot-area')
      const area = areas[dataPointIndex * 2]

      if (!area) return { left: 0, top: 0 }

      const { left, width, top } = area.getBoundingClientRect()

      return { left: left + width, top }
    },

    updateHeight () {
      const el = this.$el.querySelector('.apexcharts-grid') || this.$el
      const { height } = el.getBoundingClientRect()

      this.gridHeight = height
    }
  }
}
</script>

<style lang="scss">
.c-graph-box {
  .apexcharts-legend {
    .apexcharts-legend-marker { margin-right: 5px; }
    .apexcharts-legend-text { text-transform: uppercase; }
    .apexcharts-legend-series { display: inline-flex !important; }
    .apexcharts-inactive-legend { opacity: 1 !important; }
  }
  .c-tooltip > .popover::before {
    top: initial;
    bottom: -5px;
  }
  .apexcharts-xaxis-label { font-family: Nunito Sans !important; }
  .apexcharts-boxPlot-area{
    &:nth-child(odd) {
      stroke: color-var(negative);
    }
    &:nth-child(even) {
      stroke: color-var(positive);
    }
  }
}
</style>
