export const formatDate = value => {
  if (!value) return ''

  const [ year, month, day ] = value.split(' ')[0].split('-')

  if (typeof value === 'string') {
    const date = new Date(year, month - 1, day)

    return date.toLocaleDateString('pt-BR')
  }

  return new Date(value).toLocaleDateString('pt-BR')
}

export const formatDateUS = date => {
  if (!date) return ''

  return date.substr(0, 10).split('/').reverse().join('-')
}

export const calculateAge = (birthday) => {
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);

  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const calcDays = (x, y, z) => {
  if (!x || !y) return 0

  const diff = +(z || y) - +x

  return Math.round(diff / (1000 * 3600 * 24))
}

export const getTimestamp = date => {
  return (new Date(formatDateUS(date))).getTime()
}
