<template>
  <transition v-if="isOpened" name="c-preview-fade" appear>
    <div :class="classes" @touchmove="$event.preventDefault()">
      <c-overlay v-if="isOpened" show dark class="overlay" @close="$emitClose" />

      <div class="content" @keyup.right="handleNext">
        <div class="controls">
          <!-- @slot The header section's controls (buttons). -->
          <slot name="controls">
            <div class="left">
              <c-button
                v-if="download"
                translucent
                class="control download"
                icon="download-arrow"
                icon-size="16"
                type="button"
                @click="$emit('download', currentImageData)"
              >
                <template v-if="!isMobile">Baixar</template>
              </c-button>

              <c-button
                v-if="remove"
                translucent
                icon="trash"
                class="control remove"
                icon-size="16"
                type="button"
                @click="$emit('delete', currentImageData)"
              >
                <template v-if="!isMobile">Excluir</template>
              </c-button>
            </div>

            <div class="right">
              <c-button
                translucent
                icon="delete-disabled"
                :size="!isMobile ? 60 : 40"
                :icon-size="isMobile ? 16 : 30"
                class="control"
                type="button"
                @click="$emitClose"
              />
            </div>
          </slot>
        </div>

        <c-transition v-if='isPreviewable(currentImageData || {})' duration="200" mode="out-in">
          <span :key="(currentImageData || {}).label || ''" class="label">
            {{ (currentImageData || {}).label || '' }}
            <span class="separator">•</span>
            Documento {{ +currentImage + 1 }} de {{ images.length }}
          </span>
        </c-transition>

        <div class="preview">
          <div class="action-container">
            <c-button
              translucent
              class="action -left"
              icon="arrow-left"
              :size="!isMobile ? 60 : 40"
              :icon-size="isMobile ? 16 : 30"
              :disabled="currentImage <= 0"
              :style="{ opacity: currentImage <= 0 ? '0' : '' }"
              @click="handlePrevious"
            />
          </div>

          <div class="main" :style="{ transform: `scale(${zoomLevel})` }">
            <div v-if="isPDF(currentImageData || {})" class="pdf-wrapper">
              <c-pdf-wrapper
                :key="JSON.stringify(currentImageData || {})"
                :file="currentImageData || {}"
                :page-limit="pdfPreviewPageLimit"
              />
            </div>

            <div v-else-if="isImg(currentImageData || {})" class="img-wrapper">
              <c-image
                spinner-loader
                class="img"
                :carousel="carouselDirection"
                :src="(currentImageData || {}).src || ''"
                :alt="(currentImageData || {}).label || ''"
                @error="$emit('image-error', currentImageData)"
              />
            </div>

            <div v-else class="img-wrapper">
              <c-empty class="empty">
                <c-character
                  :size="isMobile ? 130 : 150"
                  type="paper-sad"
                  class="character"
                />

                <c-title red class="title">Não é possível visualizar o arquivo</c-title>

                <p class="message">
                  O formato não permite visualização. Baixe o arquivo
                  para conseguir visualizar.
                </p>
              </c-empty>
            </div>

          </div>

          <div class="action-container">
            <c-button
              translucent
              class="action -right"
              icon="arrow-right"
              type="button"
              :size="!isMobile ? 60 : 40"
              :icon-size="isMobile ? 16 : 30"
              :disabled="currentImage >= images.length - 1"
              :style="{ opacity: currentImage >= images.length - 1 ? '0' : '' }"
              @click="handleNext"
            />
          </div>
        </div>
      </div>

      <div class="scale-btn-controller">
        <c-scale-controller
          ref="btnScale"
          :maxZoomClicks="!isMobile ? 7 : 10"
          @zoom-in="zoomIn"
          @zoom-out="zoomOut"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { MediaQuery, CloseOnEsc } from '@convenia/mixins'
import CPdfWrapper from './CPdfWrapper'

/**
 * A component for previewing a collection of images.
 */
export default {
  name: 'CPreview',

  mixins: [ CloseOnEsc, MediaQuery ],

  components: { CPdfWrapper },

  props: {
    /**
     * An array of image objects <{ name: string, label: string, src: string }>
     */
    images: {
      type: Array,
      required: true
    },

    /**
     * The index of the image to be displayed.
     */
    currentImage: {
      type: [ String, Number ],
      required: true
    },

    /**
     * Whether to show the remove button
     */
    remove: {
      type: Boolean,
      default: true
    },

    /**
     * Whether to show the download button
     */
    download: {
      type: Boolean,
      default: true
    },

    /**
     * The page limit for PDF documents with more than one page.
     */
    pdfPreviewPageLimit: {
      type: Number,
      default: 30
    }
  },

  data () {
    return {
      carouselDirection: '',
      zoomLevel: 1
    }
  },

  created () {
    window.addEventListener('keydown', this.handlers)
  },

  watch: {
    currentImageData () {
      this.resetZoom()
    }
  },

  computed: {
    classes () {
      return [
        'c-preview',
        {
          '-enable-scroll': this.isPdfPrevew,
          '-enable-scroll-zomm': this.zoomLevel > 1
        }
      ]
    },
    isPdfPrevew () {
      return this.isPDF(this.currentImageData || {})
    },
    currentImageData () {
      return (this.images || [])[+this.currentImage]
    }
  },

  methods: {
    handlers ({ key }) {
      if (key !== 'ArrowLeft' && key !== 'ArrowRight') return
      if (key === 'ArrowLeft' && !this.currentImage) return
      if (key === 'ArrowRight' && this.currentImage >= this.images.length - 1) return

      key === 'ArrowLeft' ? this.handlePrevious() : this.handleNext()
    },

    handlePrevious () {
      this.carouselDirection = 'left'
      this.$emit('previous')
    },

    handleNext () {
      this.carouselDirection = 'right'
      this.$emit('next')
    },

    getFileExtension (file) {
      if (!(file || {}).name) return false
      const index = file.name.lastIndexOf('.')
      const ext = index < 1 ? '' : file.name.substr(index + 1)
      return ext.toLowerCase()
    },
    isPreviewable (file) {
      const { isPDF, isImg } = this
      return isPDF(file) || isImg(file)
    },

    isPDF (file) {
      const ext = this.getFileExtension(file)
      return [ 'pdf' ].includes(ext)
    },
    isImg (file) {
      const ext = this.getFileExtension(file)
      const imageExtensionsSupported = [ 'img', 'jpg', 'jpeg', 'png' ]
      return imageExtensionsSupported.includes(ext)
    },
    zoomIn ({ zoomLevel }) {
      this.zoomLevel = zoomLevel
      this.updateResolution()
    },
    zoomOut ({ zoomLevel }) {
      this.zoomLevel = zoomLevel
      this.updateResolution()
    },
    resetZoom () {
      this.$refs.btnScale?.resetZoom()
      this.zoomLevel = 1
      this.updateResolution()
    },
    updateResolution () {
      if (this.isPDF(this.currentImageData)) {
        this.updatePdfResolution()
      } else if (this.isImg(this.currentImageData)) {
        this.updateImageResolution()
      }
    },
    updatePdfResolution () {
      const resolution = this.zoomLevel > 2 ? 'high' : 'standard'
      if (this.$refs.pdfWrapper) {
        this.$refs.pdfWrapper.setResolution(resolution)
      }
    },
    updateImageResolution () {
      const newSrc = this.zoomLevel > 2
        ? this.currentImageData.highResSrc
        : this.currentImageData.src

      this.currentImageData.src = newSrc
    }
  },

  beforeDestroy () {
    window.removeEventListener('keydown', this.handlers)
  }
}
</script>

<style lang="scss">
.c-preview {
  position: fixed;
  z-index: var(--z-index-5);
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  & > .c-overlay.overlay { z-index: 3; }

  &.-enable-scroll {
    & > .content {
      overflow: auto;

      & > .preview > .main {
        justify-content: flex-start;
        margin: 0 40px;
        padding-top: 75px;

        @include responsive(xs-mobile, mobile) {
          margin: 0 5px;
          padding-top: 20px;
        }
      }
    }
  }

  &.-enable-scroll-zomm {
    & > .content {
      overflow: auto;

      & > .preview > .main {
        justify-content: flex-start;
        margin: 0 40px;
        padding-top: 75px;
        transition: padding-top 0.6s ease-in-out, transform 0.3s ease-in-out;

        @include responsive(xs-mobile, mobile) {
          margin: 0 5px;
          padding-top: 20px;
          transition: padding-top 0.6s ease-in-out, transform 0.3s ease-in-out;
        }
      }
    }
  }

  & > .scale-btn-controller {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: var(--z-index-6);
  }

  & > .content {
    transform: translate3d(0, 0, 0); // fixes ios positioning bugs
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;

    position: relative;
    z-index: var(--z-index-5);

    & > .controls {
      display: flex;
      justify-content: space-between;
      width: 100%;

      & > .left, & > .right {
        position: absolute;
        top: 40px;
        display: flex;
        z-index: var(--z-index-1);
      }
      & > .left { left: 40px; }
      & > .right { right: 40px; }

      @include responsive(xs-mobile, tablet) {
        & > .left, & > .right { top: 10px; }
        & > .left { left: 10px; }
        & > .right { right: 10px; }
      }

      & > * > .control {
        &.remove > .icon { width: 17px; height: 16px; }
        &:not(.-icon-only) { width: 120px; }
        &:not(:last-child) { margin-right: 10px; }
      }
    }

    & > .label {
      position: absolute;
      top: 90px;
      text-align: center;
      font-family: var(--title-font-family);
      font-size: 11px;
      font-weight: 500;
      color: rgba(#FFF, .8);

      text-transform: uppercase;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      & > .separator { margin: 0 3px; }

      @include responsive(xs-mobile, tablet) {
        top: 70px;
        left: 15px;
        text-align: left;
      }
    }

    & > .preview {
      display: flex;
      justify-content: space-around;

      position: absolute;
      top: 80px;
      bottom: 80px;
      left: 0;
      right: 0;
      width: 100%;

      @include responsive(xs-mobile, tablet) {
        width: 100%;
        & .action { margin: 10px; }
      }

      & > .action-container {
        height: 100%;
        max-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        & > .action.-left > .icon {transform: translateX(-2px); }
        & > .action.-right > .icon { transform: translateX(2px); }
      }

      & > .action.-left > .icon {transform: translateX(-2px); }
      & > .action.-right > .icon { transform: translateX(2px); }

      & > .main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: padding-top 0.6s ease-in-out, transform 0.3s ease-in-out;

        @include responsive(xs-mobile, mobile) {
          transition: padding-top 0.6s ease-in-out, transform 0.3s ease-in-out;
        }

        & > .pdf-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 100%;
          max-width: 670px;

          @include responsive(mobile, tablet) {
            max-width: 400px;
          }

          @include xs-mobile {
            max-width: 220px
          }
        }

        & > .img-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          margin: 50px 60px;
          width: 564px;
          height: 564px;

          & > .c-loader { opacity: 0.3; }

          & > .img {
            border-radius: 10px;
            max-height: 100%;
            max-width: 100%;
          }

          & > .empty {
            display: flex;
            justify-content: center;
            align-items: center;

            & > .message { color: rgba(255, 255, 255, 0.8); }

            & > .title {
              display: flex;
              justify-content: center;
              align-items: center;

              & > .text { color: rgba(255, 255, 255, 0.8); }
              & > .character { height: 130px; }
            }
          }
        }

        @include responsive(xs-mobile, tablet) {
          width: 100%;

          & > .img-wrapper {
            width: 100%;
            & > .c-loader { width: 60px !important; }
          }
        }
      }
    }
  }
}

.c-preview-fade {
  &-enter-active,
  &-leave-active {
    transition: opacity .4s;

    & > .content { transition: opacity 350ms; }
  }

  &-enter,
  &-leave-to {
    opacity: 0;

    & > .content { opacity: 0; }
  }
}
</style>
