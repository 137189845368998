<template>
  <c-modal
    is-opened
    no-header
    class="dialog-modal"
    :no-close="!isMobile"
    @close="onCancel"
  >
    <div class="body">
      <c-character
        class="character"
        :type="character"
        :size="isMobile ? 130 : 150"
      />

      <c-title
        v-if="title"
        dark-text
        center
        class="title"
        :red="theme == 'error'"
        :green="theme == 'success'"
      >
        {{ title }}
      </c-title>

      <p
        v-if="message"
        class="message"
        v-html="message"
      />
    </div>

    <slot />

    <div class="actions">
      <slot name="actions">
        <c-button
          v-if="!isMobile && showCancelButton"
          flat
          :disabled="loading"
          @click="onCancel"
        >
          {{ cancel }}
        </c-button>

        <c-button
          class="confirm"
          :error="theme == 'error'"
          :primary="theme == 'primary'"
          :success="theme == 'success'"
          :loading="loading"
          @click="onConfirm"
        >
          {{ confirm }}
        </c-button>
      </slot>
    </div>
  </c-modal>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'DialogModal',

  mixins: [ MediaQuery ],

  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    character: {
      type: String,
      default: 'trash'
    },
    theme: {
      type: String,
      default: 'primary' // primary | error | success
    },
    confirm: {
      type: String,
      default: 'Confirmar'
    },
    cancel: {
      type: String,
      default: 'Cancelar'
    },
    loading: {
      type: Boolean,
      default: false
    },
    showCancelButton: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    onCancel () {
      this.$emit('cancel')
    },

    onConfirm () {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss">
.dialog-modal {
  & > .wrapper > .modal > .content {
    & > .body {
      display: flex;
      align-items: center;
      flex-flow: column;
      margin-bottom: 40px;

      & > .character {
        margin-bottom: 30px;
      }
      & > .title {
        margin-bottom: 20px;
      }
      & > .message {
        max-width: 480px;
        text-align: center;
        font-size: 14px;
        letter-spacing: -.14px;
        line-height: 19px;
        color: rgba(#121E48, .8);
      }
    }
  }

  @include responsive (tablet, desktop) {
    & > .wrapper > .modal > .content {
      & > .body {
        margin-top: 40px;
      }
      & > .actions {
        display: flex;
        align-items: center;
        justify-content: center;

        & > .c-button {
          margin: 0 10px;
          width: 180px;
        }
      }
    }
  }

  @include responsive (xs-mobile, mobile) {
    & > .wrapper > .modal {
      display: flex;
      flex-flow: column;
      & > .header {
        padding: 10px;
        flex-shrink: 0;
      }
      & > .content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 100px;
        & > .body {
          & > .title { text-align: center; }
        }
        & > .actions {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          & > .confirm {
            width: 100%;
            height: 60px !important;
            border-radius: 0 !important;
          }
        }
      }
    }
  }
}
</style>
