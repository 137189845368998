import { toFormData } from '@convenia/helpers'
import cookie from 'js-cookie'

// Constants
// ---------

// By default we assume that these two variables are present in the browser's
// cookies and use them as values for the default HTTP Headers, if that's not the
// case, you can override them by passing the options.headers parameter to the
// exported function.
const TOKEN = cookie.get('token') || ''
const COMPANY_UUID = cookie.get('active_company_uuid') || ''

const SUCCESS_CODES = [ 200, 201, 202, 204 ]

export const HEADERS = {
  'Authorization': `Bearer ${TOKEN}`,
  'Active-Company': COMPANY_UUID
}

export const LISTENERS = {
  abort: ({ files = [], reject, req: { status, statusText } = {} }) => {
    files.forEach(file => {
      file.done = true
      file.uploading = false
    })

    reject({ status, statusText })
  },
  error: ({ files = [], reject, req: { status } }) => {
    const errorMsg = 'Houve um erro ao realizar o upload'

    files.forEach(file => {
      file.done = true
      file.uploading = false
      file.error = errorMsg
    })

    reject({ status, statusText: errorMsg })
  },
  load: ({ files = [], resolve, req }) => {
    files.forEach(file => {
      file.done = true
      file.uploading = false
    })

    resolve(req.response)
  },
  progress: ({ files = [] }, event) => {
    if (!event.lengthComputable) return

    files.forEach(file => {
      file.progress = ((event.loaded / event.total) * 100).toFixed(0)
    })
  },
  loadstart: ({ files = [] }) => {
    files.forEach(file => {
      file.uploading = true
    })
  }
}

// Helpers
// -------

export const setHeaders = (req, headers) => Object
  .entries(headers)
  .forEach(([ key, value ]) => req.setRequestHeader(key, value))

export const captureErrors = (req, files = [], { error: errorFn } = {}, ctx) => {
  req.onreadystatechange = () => {
    if (req.readyState !== 4 || SUCCESS_CODES.includes(req.status)) return

    errorFn({ files, req, ...ctx }, req.readyState)
  }
}

export const registerListeners = ({
  req,
  files = [],
  listeners = {},
  ...ctx
}) => {
  Object
    .entries(listeners)
    .forEach(([ evName, fn ]) => {
      const once = evName !== 'progress'
      const target = evName === 'progress' ? req.upload : req
      const handler = fn.bind(null, { files, req, ...ctx })

      if (fn) target.addEventListener(evName, handler, { once })
    })

  if (listeners.error) captureErrors(req, files, listeners, ctx)
}

const openRequest = ({
  files,
  endpoint,
  listeners,
  headers,
  payload
} = {}) => {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()
    const formData = toFormData(payload || { file: (files[0] || {}).data })

    registerListeners({
      req: request,
      files,
      listeners,
      resolve,
      reject
    })

    request.open('post', endpoint, true)
    setHeaders(request, headers)
    request.send(formData)
  })
}

const getXHR = ({ listeners, headers, ...args }) => openRequest({
  ...args,
  headers: { ...HEADERS, ...(headers || {}) },
  listeners: { ...LISTENERS, ...(listeners || {}) }
})

// Function export
// ---------------

/**
 * @param {Array} options.files - The array files to upload and to bind event listeners
 * to, unless options.payload is present, if so only the data inside options.payload
 * is sent to the backend in a FormData, but the event listeners are still bound
 * to the objects inside the options.files array
 *
 * @param {String} options.endpoint - The full URL of the API endpoint to upload the files to
 *
 * @param {Object|Array} options.payload? - An object containing the files which will
 * be uploaded to the backend, if this object is present, a single request will be sent
 * containing all of the files in this object in a FormData, instead of an individual
 * request for each file.
 *
 * @param {Object} options.listeners? - An object containing XMLHTTPRequest event
 * listeners, will be merged and take precedence over the default LISTENERS object
 *
 * @param {Object} options.headers? - An object containing HTTP Headers to use in the
 * request, by default we define an object containg the 'Authorization' and
 * 'Active-Company' headers, which use the values of the'token' and
 * 'active_company_uuid' variables from the browser's cookies respectively, but you
 * can override these headers if the default headers don't work for your specific
 * scenario.
 *
 * @returns {Promise} - A promise representing the request to the backend, will be
 * resolved once all files have been sucessfully uploaded or rejected of any of
 * the requests fail
 */

export default ({ files = [], endpoint, ...options } = {}) => {
  if (!files || !endpoint) return

  // If payload is present, we create a single FormData object
  // from the payload argument, and open a single request to send all
  // files to the backend. Both the 'files' and the 'payload' arguments
  // are lists containing files, the reason we need two of them instead of
  // just using one in this type of scenario, is that the 'files' array
  // contains the files to which we bind all of the request's event
  // listeners, and the 'payload' contains the files that will actually go
  // to the backend, and these two lists may differ in structure, or it
  // could happen that there files that you want to send to the backend
  // but don't want any listeners bound to
  if ((options || {}).payload)
    return getXHR({ files, endpoint, ...(options || {}) })

  return Promise.all((files || []).map(file => getXHR({
    files: [ file ],
    endpoint,
    ...(options || {})
  })))
}
