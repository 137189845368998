<template>
  <table class="c-table">
    <thead class="header" :style="{ borderRight: `${tableScrollWidth} solid transparent` }">
      <tr class="row">
        <!-- @slot The table's header section slot. -->
        <slot name="header">
          <th
            v-for="(col, index) in cols"
            :key="index"
            :class="getClasses(index, 'header')"
            :style="getStyle(col)"
            :width="col.width || null"
          >
            <span class="text">{{ getText(col, 'label') }}</span>
          </th>
        </slot>
      </tr>
    </thead>

    <div :class="[ 'body-wrapper', { '-grey-shadow': greyShadow } ]">
      <component
        :is="skipTransition ? 'table-body' : 'c-transition'"
        ref="tableBody"
        ref-cy="table-body"
        group
        class="body"
        tag="tbody"
        @scroll.native="emitPaginate"
      >
        <!-- @slot The table row slot, contains a default row
             filled with the row's content.
        -->
        <slot name="row">
          <tr
            v-for="(row, rowIndex) in rows"
            :key="rowIndex"
            class="row"
          >
            <td
              v-for="(col, colIndex) in cols"
              :key="colIndex"
              :class="getClasses(colIndex, 'content')"
              :style="getStyle(col)"
              :width="col.width || null"
            >
              <span class="text">{{ getText(row, col.field) }}</span>
            </td>
          </tr>
        </slot>
      </component>
    </div>

    <!-- @slot A slot for the table's footer section. -->
    <slot name="footer" />
  </table>
</template>

<script>
import { isContent, get, isAlignment, getProperty, debounce } from '@convenia/helpers'
import { Shadowed as _Shadowed } from '@convenia/mixins'
import TableBody from './fragments/TableBody'

// A little hack necessary circumvent one of vue-docgen-api bugs:
// https://github.com/vue-styleguidist/vue-docgen-api/issues/53
const Shadowed = _Shadowed || function derp () {}

/**
 * An hybrid model/template -driven table component.
 */
export default {
  name: 'CTable',
  components: { TableBody },

  mixins: [ Shadowed({ refName: 'tableBody' }) ],

  props: {
    /**
     * Table columns.
     */
    cols: {
      type: Array,
      validator: isContent,
      default: () => [],
    },

    /**
     * Table rows.
     */
    rows: {
      type: Array,
      validator: isContent,
      default: () => [],
    },

    /**
     * Wheter the table scroll shadow is greyish.
     */
    greyShadow: Boolean,

    /**
     * The table alignment.
     */
    align: {
      type: String,
      default: 'left',
      validator: isAlignment
    },

    /**
     * Skips list transitions
     */
    skipTransition: Boolean,
  },

  data () {
    return {
      tableScrollWidth: '0',
      observer: null
    }
  },

  methods: {
    getText: get,

    getElement (refName) {
      const ref = this.$refs[refName] || {}
      return ref.$el || ref
    },

    emitPaginate () {
      const el = this.getElement('tableBody')

      const { scrollTop } = el
      const { scrollHeight } = el
      const { clientHeight } = el
      const paginationThreshold = scrollHeight - 140
      const gap = 70
      this.lastPosition = this.position
      this.position = scrollTop

      /**
       * Emited when the user scrolls down to the end of the table's content.
       * @event paginate
       * @type {null}
       */
      if ((scrollTop + clientHeight) >= paginationThreshold) { this.$emit('paginate') }

      /**
       * Emited with true value, when user scrolls up, or false, when user scrolls down
       * @event scrollDirection
       * @type Boolean
       */
      if (scrollHeight <= window.innerHeight + this.headerHeight) {
        this.$emit('scrollDirection', 'up')
      } else if (this.position < this.headerHeight) {
        this.$emit('scrollDirection', 'up')
      } else if (this.position >= scrollHeight - clientHeight - this.headerHeight) {
        this.$emit('scrollDirection', 'down')
      } else if ((scrollTop + clientHeight) < paginationThreshold) {
        this.$emit('scrollDirection', (this.position > this.lastPosition) && (this.position > gap) ? 'down' : 'up')
      }
    },

    getClasses (index, type) {
      const custom = this.cols[index][type + 'Class']
      const classes = [
        custom,
        '-' + type,
        'cell',
        '-' + this.getAlignment(index)
      ]

      return classes
    },

    getStyle (col) {
      const style = {
        textAlign: col.align || this.align,
        display: col.hidden ? 'none' : undefined
      }

      return style
    },

    getAlignment (index) {
      const col = this.cols[index]
      const alignment = getProperty('align', [ col, this.props ], isAlignment)
      return alignment
    },

    adjustTableScrollWidth: debounce(function debounced () {
      const element = this.getElement('tableBody')

      this.tableScrollWidth = `${element.offsetWidth - element.scrollWidth}px`

      this.$emit('table-scroll-width', this.tableScrollWidth)
    }, 100)
  },

  mounted () {
    this.observer = new MutationObserver(this.adjustTableScrollWidth)
    this.observer.observe(this.getElement('tableBody'), { childList: true })
    window.addEventListener('resize', this.adjustTableScrollWidth)
    this.$nextTick(this.adjustTableScrollWidth)
    setTimeout(this.adjustTableScrollWidth, 200)
  },

  beforeDestroy () {
    this.observer.disconnect()
    window.removeEventListener('resize', this.adjustTableScrollWidth)
  }
}
</script>

<style lang="scss">
.c-table {
  display: flex;
  flex-direction: column;
  position: relative;

  & > .header {
    display: table;
    table-layout: fixed;

    & > .row {
      table-layout: fixed;
      display: table;
      width: 100%;

      & > .cell {
        @include typo(h5, base-50);
        padding: 0 20px 10px 20px;

        &.-left { text-align: left; }
        &.-right { text-align: right; }
      }
    }
  }

  & > .body-wrapper {
    display: flex;
    flex-direction: column;
    @include shadowed(100px, #FFF);

    // In this particular case, we cannot use a CSS var here because of the
    // rgba() function being used in the shadowed mixin
    &.-grey-shadow { @include shadowed(100px, $base-background); }

    & > .body {
      flex: 1;
      display: block;
      overflow-y: auto;

      & > .row {
        display: table;
        width: 100%;
        table-layout: fixed;
        border-top: var(--base-border);

        & > .cell {
          @include typo(body-2, base-50);
          text-transform: uppercase;
          padding: 20px 20px;

          &.-left { text-align: left; }
          &.-right { text-align: right; }
        }
      }
    }
  }

  & > tfoot, & > .footer {
    position: relative;

    width: auto;
    display: table;
    table-layout: fixed;
  }
}
</style>
