<template>
  <div
    v-show="scriptLoaded"
    class="CTextEditor"
  >
    <div
      class="ck-root"
      :id="ckId"
    />
  </div>
</template>

<script>
import { generateUUID } from '@convenia/helpers'

const CK_SCRIPT_ID = 'ckeditor-script'

/**
 * A TextEditor component which uses CKEditor lib. It can handle any
 * CKEditor event and call methods using the ckListeners prop. See
 * the storybook example and read the documentation
 * (https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_editor.html)
 * to better understand its advanced usage.
 */
export default {
  name: 'CTextEditor',

  data: () => ({
    scriptLoaded: false,
    ckId: `ck-${generateUUID()}`
  }),

  props: {
    value: {
      type: String,
      default: '',
    },

    manualDestroyInstance: Boolean,

    config: {
      type: Object,
      default: () => ({})
    },

    ckListeners: {
      type: Object,
      default: () => ({})
    }
  },

  mounted () {
    if ((window.CKEDITOR || {}).status === 'loaded') return this.startCk()

    const siblingScript = (document.getElementsByTagName('script'))[0]
    const element = document.createElement('script')

    element.id = CK_SCRIPT_ID
    element.src = '/libs/ckeditor/ckeditor.js'
    element.type = 'text/javascript'

    element.onload = this.startCk

    siblingScript.parentNode.insertBefore(element, siblingScript)
  },

  methods: {
    startCk () {
      this.updateCkInstance()
      this.instanceCall('on', 'instanceReady', this.setCkInitialState)
    },

    setCkInitialState () {
      this.scriptLoaded = true

      this.updateCkValue(this.value)

      this.instanceCall('on', 'change', this.updateModelValue)
    },

    instanceCall (method, ...args) {
      const instance = this.getInstance()
      if (instance && typeof instance[method] === 'function')
        return instance[method](...args)
    },

    getInstance () {
      return ((window.CKEDITOR || {}).instances || {})[this.ckId]
    },

    updateCkInstance () {
      this.destroyCkInstance()

      window.CKEDITOR.replace(this.ckId, this.config)
      this.setCkListeners(this.ckListeners)
    },

    destroyCkInstance () {
      try {
        this.instanceCall('destroy')
      } catch (e) {
        return e
      }
    },

    updateModelValue () {
      const value = this.instanceCall('getData')
      this.$emit('input', value)
    },

    updateCkValue (value) {
      this.instanceCall('setData', value)
      this.instanceCall('once', 'dataReady', this.updateModelValue)
    },

    setCkListeners (ckListeners) {
      const { instanceCall, updateCkValue, getInstance } = this

      Object.entries(ckListeners).forEach(([ event, handler ]) => {
        const scopedHandler = e => handler(e, { instanceCall, updateCkValue, getInstance })
        this.instanceCall('on', event, scopedHandler)
      })
    }
  },

  beforeDestroy () {
    const { destroyCkInstance, manualDestroyInstance } = this
    if (!manualDestroyInstance) return destroyCkInstance()

    this.$emit('destroy', { destroyInstance: destroyCkInstance })
  },

  watch: {
    config: 'startCk',
    ckListeners: 'startCk'
  }
}
</script>
