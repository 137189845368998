import { is } from '@convenia/helpers'

let observer
let globalImmediate = true

/**
 * Binding value options:
 *
 * @param {boolean} overlay - Defines if the direct child node will have a
 *  negative marginRight to implement a scroll overlay effect.
 * @param {number} scrollWidth - Defines the size of negative marginRight
 *  to be applied.
 * @param {function} callback - Defines a callback function to be called
 *  when the function update() is called.
 * @param {boolean} emitEvent - Defines if the event 'scrollbar-actions'
 *  will be emitted when the function update() is called.
 * @param {boolean} observe - Defines if the directive will use
 *  a MutationObserver as the method for calling the function update().
 * @param {boolean} asyncUpdate - Defines if the element in which the directive
 *  has been applied will emmit the event 'scrollbar-async-update' with a
 *  function next() as the payload to update the directive asynchronously.
 * @param {boolean} immediate - Defines if the directive will be updated
 *  immediately, even if the 'asyncUpdate' param is set to 'true'.
 */
const DEFAULT_OPTIONS = {
  overlay: false,
  scrollWidth: 17,
  callback: null,
  emitEvent: false,
  observe: false,
  asyncUpdate: false,
  immediate: false
}

/**
 * Update function that is called from the MutationObserver callback, if
 * the options 'observe' is set to true, or from the custom directive hook
 * 'componentUpdated'.
 *
 * @param {MutationRecord} mutationRecord this param is only available when
 * the function update() is called from the MutationObserver callback
 */

function update (mutationRecord) {
  const { el, binding } = this

  const {
    scrollWidth = DEFAULT_OPTIONS.scrollWidth,
    overlay = DEFAULT_OPTIONS.overlay,
    callback = DEFAULT_OPTIONS.callback,
    emitEvent = DEFAULT_OPTIONS.emitEvent,
    observe = DEFAULT_OPTIONS.observe,
    asyncUpdate = DEFAULT_OPTIONS.asyncUpdate,
    immediate = DEFAULT_OPTIONS.immediate
  } = binding.value || {}

  const next = () => {
    const hasScroll = el.offsetHeight < el.scrollHeight
    const children = [ ...el.children ]

    if (overlay && hasScroll) {
      el.style.overflowX = 'hidden'
      children.forEach(child => {
        child.style.marginRight = -scrollWidth + 'px'
      })
    } else if (overlay && !hasScroll) {
      el.style.overflowX = 'unset'
      children.forEach(child => { child.style.marginRight = '0px' })
    }

    if (callback && is(callback, 'Function')) {
      const args = {
        el,
        mutationRecord,
        overlay: overlay && hasScroll
      }

      callback(args)
    }

    if (emitEvent) {
      const scrollbarActionsEvent = new CustomEvent('scrollbar-actions', {
        detail: overlay && hasScroll
      })

      el.dispatchEvent(scrollbarActionsEvent)
    }
  }

  if (immediate && globalImmediate) {
    if (observe && !mutationRecord) return

    globalImmediate = false
  } else if (asyncUpdate) {
    const asyncUpdateEvent = new CustomEvent('scrollbar-async-update', {
      detail: { next }
    })

    el.dispatchEvent(asyncUpdateEvent)
  }

  next()
}

function nextTickUpdate (mutationList) {
  const { vnode } = this

  vnode.context.$nextTick(() => { update.apply(this, mutationList) })
}

export default {
  bind (el, binding, vnode) {
    const { observe = DEFAULT_OPTIONS.observe, overlay } = binding.value || {}
    const params = { el, binding, vnode }
    const observerConfig = { childList: true, subtree: true }

    vnode.context.$nextTick(() => {
      if (observe) {
        observer = new MutationObserver(nextTickUpdate.bind(params))
        observer.observe(el, observerConfig)
      }

      if (overlay) {
        const children = [ ...el.children ]
        children.forEach(child => { child.style.transition = 'margin-right .2s' })
      }

      update.apply(params)
    })
  },

  componentUpdated (el, binding, vnode) {
    if (observer) return

    const params = { el, binding, vnode }

    update.apply(params)
  },

  unbind () {
    if (observer) observer.disconnect()
  }
}
