import { generateUUID, is } from '@convenia/helpers'
import ItemList from './ItemList'

export default {
  name: 'CMultiInput',

  components: { ItemList },

  props: {
    /**
     * The validation message
     */
    validation: String,

    /**
     * The value of the field
     */
    value: Array,

    /**
     * Whether to display the field's label on the left side
     */
    labelLeft: Boolean,

    /**
     * The property to be used as the label in the item list for
     * each item
     */
    displayBy: {
      type: String,
      default: 'value'
    },

    /**
     * Intermediary function that will receive each value inputed by
     * the user before it is actually added to the list, may be used
     * to manipulate incoming user input.
     */
    onAddMultiInput: Function,

    /**
     * Validates user input whenever a new item is added to the list,
     * if the return of this function is false or a string, the item
     * will not be added and a flash validation message may optionally
     * appear to the user.
     */
    multiInputValidator: Function,

    /**
     * Emits just an array with the typed values
     */
    emitTrackedValue: Boolean,
  },

  data: () => ({ internalValue: '' }),

  methods: {
    onInputEnter () {
      if (!this.internalValue) return

      const duplicated = !!this.value.find(i => is(i, 'Object')
        ? (i || {}).value === this.internalValue
        : i === this.internalValue)

      const isValid = !this.multiInputValidator
        || this.multiInputValidator(this.internalValue, this)

      if (duplicated || !isValid) {
        this.internalValue = ''
        return
      }

      if (is(isValid, 'String'))
        return this.$emit('flash-validation', isValid || this.validation)

      const baseVal = { value: this.internalValue, id: generateUUID() }
      const val = this.onAddMultiInput ? this.onAddMultiInput(baseVal, this) : baseVal
      const emitVal = this.emitTrackedValue ? this.internalValue : val

      if (emitVal) this.$emit('add', emitVal)

      this.internalValue = ''
    },

    onRemove ({ index }) {
      this.$emit('remove', index)
    }
  }
}
