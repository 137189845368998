import { dayjs } from '@convenia/utils'
import { is } from '@convenia/helpers'

const dateFilter = (
  value = '',
  format = 'DD/MM/YYYY',
  inputFormat = 'YYYY-MM-DD'
) => is(value, 'String')
  ? dayjs(value, inputFormat).format(format)
  : dayjs(value).format(format)

export default dateFilter
