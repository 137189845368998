<template>
  <div :class="classes" :style="styles">
    <info-icon v-if="info" class="icon" />

    <warning-icon v-else class="icon" :error="error" />

    <p v-if="message" class="message">
      <span class="text" v-html="message" />

      <a
        class="link"
        :target="target"
        :href="(link || {}).to"
      >
        {{(link || {}).message}}
      </a>
    </p>

    <slot />

    <c-button
      v-if="closable"
      type="button"
      class="close-btn"
      flat
      :info="info"
      :error="error"
      :alert="!info && !error"
      size="30"
      icon-size="18"
      icon="delete-disabled"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
/**
 * A simple warning card component.
 */
export default {
  name: 'CWarning',

  components: {
    WarningIcon: () => import('./fragments/WarningIcon'),
    InfoIcon: () => import('./fragments/InfoIcon')
  },

  props: {
    fullWidth: Boolean,

    error: Boolean,

    info: Boolean,

    paddingLeft: {
      type: [ Number, String ],
      default: 60
    },

    paddingRight: {
      type: [ Number, String ],
      default: 30
    },

    message: {
      type: String,
      default: '',
    },

    link: {
      type: Object,
      default: () => ({}),
    },

    closable: Boolean,

    target: {
      type: String,
      default: '_self',
      validator: value => [ '_self', '_blank' ].includes(value) || ''
    }
  },

  computed: {
    classes () {
      return [ 'c-warning', {
        '-error': this.error,
        '-info': this.info,
        '-full-width': this.fullWidth
      } ]
    },

    styles () {
      return {
        '--padding-left': `${this.paddingLeft}px`,
        '--padding-right': `${this.calcPaddingRight}px`
      }
    },

    calcPaddingRight () {
      return this.closable
        ? 45 + +this.paddingRight
        : this.paddingRight
    }
  }
}
</script>

<style lang="scss">
.c-warning {
  padding: {
    left: #{var(--padding-left)};
    right: #{var(--padding-right)};
    top: 10px;
    bottom: 10px;
  }

  border-radius: 5px;
  min-height: 60px;
  position: relative;
  display: flex;
  align-items: center;

  @include typo(body-1, base);

  background: rgba(color-var(alert, base-rgb), 0.2);

  &.-info { background: rgba(color-var(info, base-rgb), 0.2); }

  &.-error { background: rgba(color-var(negative, base-rgb), 0.2); }

  & > .message {
    color: color-var(text, base);
    font-size: 14px;
    font-family: var(--base-font-family);

    & > .link {
      border-bottom: dashed 1.5px;
      text-decoration: none;
      font-size: 11px;
      font-weight: var(--base-font-weight);
      color: var(--link-font-color);
      outline: 0;
      text-transform: uppercase;

      &:hover { cursor: pointer; }
    }
  }

  &.-full-width {
    width: 100%;
    border-radius: 0;

    @include desktop { height: 70px; }

    & > .icon {
      height: 50px;
      width: 50px;

      @include responsive (xs-mobile, mobile) {
        height: 45px;
        width: 45px;
      }
    }
  }

  & > .icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  & > .close-btn.c-button {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    & > .icon.c-icon { stroke: color-var(text, base-50); }
  }
}
</style>
