<template>
  <c-transition>
    <c-button
      v-if="show && !scrollingToTop"
      :class="[ 'c-scroll-top', { '-fixed': fixed } ]"
      :style="{ '--width': `${evenButtonWidth}px` }"
      icon="download-save-upload"
      @click="scrollTop"
    >
      <span class="span">
        {{ text || 'voltar ao topo'}}
      </span>
    </c-button>
  </c-transition>
</template>

<script>
import { MediaQueryRoot } from '@convenia/mixins'
import { debounce } from '@convenia/helpers'

const isElement = element => element instanceof Element || element instanceof HTMLDocument

/**
 * Aligned 'scroll to top' button
 */
export default {
  name: 'CScrollTop',

  mixins: [ MediaQueryRoot ],

  props: {
    /**
     * The target to listen the scrolling
     * @type { Element }
     */
    target: {
      type: [ Object, Element ],
      default: () => ({}),
    },

    /**
     * The text of scroll top button
     * @type { String }
     */
    text: {
      type: String,
      default: '',
    },

    /**
     * The width of the button
     * @type { Number }
     */
    width: {
      type: Number,
      default: 160
    },

    /**
     * Wheter to set the position of the button to fixed,
     * relative positioning is the default
     * @type { Boolean }
     */
    fixed: {
      type: Boolean,
      default: false
    },

    /**
     * Whether to use the capture option to the scroll event listener
     */
    capture: Boolean
  },

  data: () => ({ show: false, scrollingToTop: false }),

  computed: {
    // The width value must be even, otherwise the button will get blurry on webkit browsers
    evenButtonWidth () {
      const { width } = this

      return width % 2 === 0
        ? width
        : width + 1
    },
  },

  methods: {
    setVisible (show) {
      this.show = show
    },

    scrollTop () {
      Object.assign(this.$data, {
        scrollingToTop: true,
        show: false
      })
    },

    scrollElListener: debounce(function debounced (event) {
      this._scrollTarget = event.target
      if (!this.scrollingToTop) {
        /**
         * Debounced event of target scroll, has the set visible method
         * to display the button
         * @event target:scroll
         * @property { Object.<string, { event: Event, setVisible: function }}
         */
        this.$emit('target:scroll', { event, setVisible: this.setVisible })
      } else if (!this.target.scrollTop) this.scrollingToTop = false
    }, 50),

    addListener (el) {
      if (isElement(el)) el.addEventListener('scroll', this.scrollElListener, this.capture)
    },

    removeListener (el) {
      this._scrollTarget = null
      if (isElement(el)) el.removeEventListener('scroll', this.scrollElListener, this.capture)
    },
  },

  mounted () {
    this.addListener(this.target)
  },

  beforeDestroy () {
    this.removeListener(this.target)
  },

  watch: {
    target (newEl, oldEl) {
      this.removeListener(oldEl)
      this.addListener(newEl)
    },

    scrollingToTop (isScrolling) {
      if (!this._scrollTarget) return

      if (this.isMobile) {
        const overflowY = isScrolling ? 'hidden' : 'auto'

        // eslint-disable-next-line vue/no-mutating-props
        this._scrollTarget.style['overflow-y'] = overflowY
      }

      if (isScrolling) {
        this._scrollTarget.scroll({
          behavior: 'smooth',
          left: 0,
          top: 0,
        })

        // in case of the user manually stop the scroll animation with some input
        setTimeout(() => { this.scrollingToTop = false }, 1000)
      }
    }
  }
}
</script>

<style lang="scss">
.c-scroll-top {
  width: var(--width);
  margin: auto;
  transform: translateY(var(--offset));
  padding: 0 25px;
  justify-content: flex-start;

  &.-fixed {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: var(--z-index-1);
    transition: bottom 300ms ease, right 300ms ease, top 300ms ease, left 300ms ease;
  }

  & > .icon {
    transform: rotate(180deg);
    @include icon-color(rgba(#FFF, .5));
  }

  & > .text {
    margin-bottom: 2px;
  }

  & > .text > .svg {
    margin-right: 6px;
    fill: rgba(0, 0, 0, 0.2);
  }

  & > .text > .svg, & > .text > .span {
    vertical-align: middle;
  }
}
</style>
