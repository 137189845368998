<template>
  <div class="c-graph">
    <span v-if="($attrs['xOptions'] || $attrs['x-options'] || {}).title" class="title -x">
      ({{ ($attrs['xOptions'] || $attrs['x-options']).title }})
    </span>

    <span v-if="($attrs['yOptions'] || $attrs['y-options'] || {}).title" class="title -y">
      ({{ ($attrs['yOptions'] || $attrs['y-options']).title }})
    </span>

    <component
      :is="component"
      ref="child"
      title=""
      class="c-graph-fragment"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import CGraphBar from './fragments/CGraphBar'
import CGraphLine from './fragments/CGraphLine'
import CGraphColumn from './fragments/CGraphColumn'
import CGraphBox from './fragments/CGraphBox'
import CGraphRadial from './fragments/CGraphRadial'
import CGraphRadar from './fragments/CGraphRadar'

export default {
  name: 'CGraph',

  components: {
    CGraphBar,
    CGraphLine,
    CGraphColumn,
    CGraphBox,
    CGraphRadial,
    CGraphRadar,
  },

  props: {
    type: {
      type: String,
      validation: value => [ 'line', 'bar', 'column', 'box', 'radialBar', 'radar' ].includes(value),
      required: true,
    }
  },

  computed: {
    component () {
      const components = {
        line: 'CGraphLine',
        bar: 'CGraphBar',
        column: 'CGraphColumn',
        box: 'CGraphBox',
        radialBar: 'CGraphRadial',
        radar: 'CGraphRadar',
      }

      return components[this.type]
    },

    apex () {
      return this.$refs.child?.$refs.apex
    }
  }
}
</script>

<style lang="scss">
.c-graph {
  position: relative;

  .apexcharts-canvas, svg {
    max-width: 100%;
  }

  & > .title {
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Nunito Sans';
    color: rgba(55, 61, 63, 0.5);
    font-style: italic;

    &.-x {
      bottom: 35px;
      right: 10px;
    }

    &.-y {
      top: 25px;
      right: 98%;
      text-align: right;
      min-width: 250px;
      transform: rotateZ(270deg);
      transform-origin: right top;
    }
  }

  // Global apexcharts css settings
  & > .c-graph-fragment .apexcharts-tooltip {
    border: 0px;
    transition: opacity 0.15s ease;
    & > .c-tooltip > .popover {
      white-space: nowrap;
    }
  }
}
</style>
