<template>
  <div
    class="c-file-list"
    :class="{ '-bottom-add': addPosition === 'bottom' }"
  >
    <c-add
      v-if="add"
      :grey="grey"
      :class="[ 'add', { '-has-selectors': !!selectedFiles, '-hover': !addText } ]"
      :squared="addSize == 'small'"
      :disabled="disabled"
      :text="addText"
      @click.native="!addText ? $emit('add') : null "
      @add="$emit('add')"
    />

    <slot />

    <div v-if="files.length" class="files">
      <c-file
        v-for="file in files"
        :key="file.id"
        :disabled="disabled && disabledFiles.includes(file)"
        :name="file.name"
        :size="file.size"
        :done="file.done"
        :uploading="file.uploading"
        :progress="file.progress"
        :label="file.label"
        :nestable="file.nestable"
        :nested-file="file.nestedFile"
        :upload-text="nestedUploadText"
        :extensions="extensions"
        :remove="removable"
        :downloadable="downloadable"
        :transparent="transparentFiles"
        :error="getErrorMessage(file)"
        :has-selector="!!selectedFiles"
        :is-selected="!!(selectedFiles || []).find((selectedFile) => selectedFile.id == file.id)"
        :is-removing="!!(removingFiles || []).find((removingFile) => removingFile.id == file.id)"
        :remove-nested="removeNested"
        :download-nested="downloadNested"
        @download="onDownload(file, $event)"
        @click="onClick(file, $event)"
        @remove="onRemove(file)"
        @nested-remove="onNestedRemove({ parentFile: file, file: $event })"
        @toggle="$emit('toggle', { selected: $event, file })"
        @nested-add="$emit('nested-add', file)"
        @nested-drag="$emit('nested-drag', { parentFile: file, event: $event })"
      />
    </div>

    <c-preview
      :is-opened="showGallery"
      :images="images"
      :remove="removable && !disabled"
      :current-image="current"
      :pdf-preview-page-limit="pdfPreviewPageLimit"
      @next="current += 1"
      @previous="current -= 1"
      @close="onClosePreview"
      @download="onDownload(currentImage)"
      @delete="onRemove(currentImage)"
      @click.native.prevent
    />
  </div>
</template>

<script>
export default {
  name: 'CFileList',

  props: {
    files: {
      type: Array,
      default: () => ([]),
      validator: files => files.every(({ id }) => id)
    },

    noPreview: {
      type: Boolean,
      default: false
    },

    /**
     * The page limit for PDF documents with more than one page.
     */
    pdfPreviewPageLimit: {
      type: Number,
      default: 30,
    },

    /**
     * Disables the add button.
     */
    disabled: {
      type: Boolean,
      default: false
    },

    /**
     * When component is disabled, specify which files should be disabled as well.
     */
    disabledFiles: {
      type: Array,
      default: () => ([])
    },

    add: {
      type: Boolean,
      default: true
    },

    addSize: {
      type: String,
      default: 'default'
    },

    addPosition: {
      type: String,
      default: 'top',
      validator: pos => [ 'top', 'bottom' ].includes(pos)
    },

    removable: {
      type: Boolean,
      default: true
    },

    downloadable: {
      type: Boolean,
      default: false
    },

    addText: {
      type: String,
      default: '',
    },

    grey: Boolean,

    /**
     * Makes it possible to selected files through a checkbox placed beside the file
     */
    selectedFiles: {
      type: Array,
      default: () => [],
    },

    /**
     * A list of files being asynchronally removed
     */
    removingFiles: {
      type: Array,
      default: () => []
    },

    /**
     * Makes the files have a transparent background
     */
    transparentFiles: Boolean,

    /**
     * Allowed file extensions for nested files (preferably in lowercase)
     */
    extensions: {
      type: Array,
      default: () => [],
    },

    /**
     * The text shown at the top of the nested upload box (when it is available)
     */
    nestedUploadText: {
      type: String,
      default: 'Arraste seu arquivo e solte aqui',
    },

    /**
     * Whether to show the remove option only for nested files
     */
    removeNested: Boolean,

    /**
     * Whether to show the download option only for nested files
     */
    downloadNested: Boolean,
  },

  data: () => ({
    nestedGallery: false,
    showGallery: false,
    current: 0
  }),

  computed: {
    images () {
      const files = !this.nestedGallery
        ? this.files
        : this.files.reduce((acc, file) => [
          ...acc,
          ...(file.nestedFile ? [ file.nestedFile ] : [])
        ], [])

      return files.map(file => ({
        ...file,
        src: file.preview,
        label: file.name
      }))
    },

    currentImage () {
      return this.images[this.current]
    }
  },

  watch: {
    files () {
      this.$nextTick(this.checkScroll)
    },
    showGallery (value) {
      this.$emit('toggle-preview', value)
    }
  },

  methods: {
    onClick (file, { nested } = {}) {
      let currentFile = file
      if (this.noPreview) return

      if (nested) {
        this.nestedGallery = true
        currentFile = file.nestedFile
      }

      const index = this.images.findIndex(image => currentFile.preview === image.src
      )

      if (index === -1) return

      this.showGallery = true
      this.current = index
    },

    async onDownload (file = {}, { nested = false } = {}) {
      const currentFile = nested ? file.nestedFile : file
      const url = await this.resolve(currentFile)
      url && this.open({ ...currentFile, url })
    },

    async resolve ({ url }) {
      try {
        return typeof url === 'function'
          ? await url() : url
      } catch (e) { this.$emit('file-error') }
    },

    async open ({ openMethod, ...file }) {
      try {
        openMethod
          ? openMethod(file)
          : window.open(file.url, '_blank')
        this.$emit('download', file)
      } catch (e) { this.$emit('file-error') }
    },

    onRemove (file) {
      this.showGallery = false
      this.$emit('remove', file)
    },

    onNestedRemove ({ parentFile, file }) {
      this.showGallery = false
      this.$emit('nested-remove', { parentFile, file })
    },

    getErrorMessage (file) {
      return file.unsupported ? 'Formato não suportado.'
        : file.overLimit ? 'Tamanho máximo excedido.'
          : file.error || null
    },

    onClosePreview () {
      this.nestedGallery = false
      this.showGallery = false
    }
  }
}
</script>

<style lang="scss">
.c-file-list {
  width: 100%;
  display: grid;
  grid-template-areas: "add" "slot" "files";

  &.-bottom-add { grid-template-areas: "files" "add"; }

  & > .c-add.add {
    width: 100%;
    height: 80px;
    margin-bottom: 10px;
    grid-area: add;

    &.-hover:hover {
      background-color: map-get($text-color, base-02);
      cursor: pointer;
    }

    &.-hover:hover {
      background-color: color-var(text, base-02);
      cursor: pointer;
    }

    &.-has-selectors {
      margin-left: 45px;
      width: calc(100% - 45px);
    }
  }

  & > .files {
    grid-area: files;
    display: grid;
    grid-row-gap: 10px;
  }

  @include responsive(xs-mobile, mobile) {
    & > .add.-has-selectors {
      margin-left: unset;
      width: unset;
    }
  }
}
</style>
