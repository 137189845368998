<template>
  <div class="app" id="app">
    <transition name="fade" mode="out-in">
      <c-loader v-if="loading" :size="isMobile ? 79 : 99" />

      <router-view v-else />
    </transition>

    <c-feedbacks
      :feedbacks="$feedback.list"
      :style="{ top: isMobile ? '10px' : null }"
      @close="feedback => $feedback.remove(feedback)"
    />
  </div>
</template>

<script>
import { MediaQueryRoot } from '@convenia/mixins'
import { mapActions } from 'vuex'
import * as types from '@types'
import * as middlewareRequests from '@graphql/middleware'

export default {
  mixins: [ MediaQueryRoot ],

  data: () => ({
    loading: true
  }),

  mounted () {
    this.init()
  },

  methods: {
    ...mapActions({
      authenticate: types.AUTH_AUTHENTICATE
    }),

    async init () {
      const [ err, token ] = await this.authenticate()

      if (!err && token) middlewareRequests.setToken(token)
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.app {
  height: 100%;
  display: flex;
  flex-flow: column;
  background: #F3F4F6;

  & > .c-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include responsive (tablet, desktop) {
    & > .coe-feedbacks {
      bottom: 100px !important;
    }
  }
}

// Transitions
.view-enter-active, .view-leave-active {
  transition: opacity .3s, transform .3s;
}
.view-enter, .view-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}

.stamp-enter-active, .stamp-leave-active {
  transition: opacity .3s, transform .3s;
}
.stamp-enter, .stamp-leave-to {
  opacity: 0;
  transform: scale(1.5);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

// Animations
@keyframes shake {
  10%, 90% { transform: translate(-1px, 0); }
  20%, 80% { transform: translate(2px, 0); }
  30%, 50%, 70% { transform: translate(-4px, 0); }
  40%, 60% { transform: translate(4px, 0); }
}

// SHAME Overrides
.c-button {
  &.-disabled:not(.-flat) {
    background: white;
    &:before {
      width: 100%;
      transform: translate3d(-50%, -30%, 1px);
      background: #121E48;
      opacity: .05;
      transition: none;
    }
    & > .text { color: rgba(#121E48, .3); }
    &:after {
      background: rgba(#121E48, .13);
      opacity: 1;
    }
  }
}
</style>

<style src="./styles/index" lang="scss" />
