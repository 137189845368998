<template>
  <div ref="kanban" class="c-kanban" :style="gridVariables">
    <div
      v-for="{ title, theme, category, emptyState } in columns"
      :key="category"
      class="column"
    >
      <div class="title-wrapper">
        <c-title v-bind="themeProps(theme)">
          {{ title }}
        </c-title>
      </div>

      <c-shadowed
        class="cards"
        :class="{ '-has-scrollbar': hasScrollbar[category] }"
        :shadow-color="shadowColor"
        :has-bottom-shadow="false"
        @has-scrollbar="has => hasScrollbar[category] = has"
      >
        <c-card
          v-for="card in categoryCards(category)"
          :key="card.id"
          :class="{ '-remaining': card.remaining }"
          :no-hover="!hasClickCallback"
          :style="{ cursor: hasClickCallback ? 'pointer' : 'auto' }"
          v-bind="themeProps(theme)"
          @click="hasClickCallback && onCardClick(card)"
        >
          <slot :card="card" />
        </c-card>

        <div
          v-if="categoryCards(category).length === 0"
          class="empty-state"
        >
          <span>{{ emptyState || defaultEmptyState }}</span>
        </div>
      </c-shadowed>
    </div>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

const rulesValidator = rules => items => items.every(item => {
  return rules.every(({ rule, error }) => {
    if (!rule(item)) console.error('CKanban:', error)
    return rule(item)
  })
})

const validThemes = {
  primary: '',
  positive: 'green',
  negative: 'red',
  alert: 'yellow',
  info: 'blue'
}

const columnsRules = [
  {
    rule: item => typeof item === 'object',
    warning: 'All array items in the \'columns\' prop must be objects.'
  },
  {
    rule: item => typeof item.category === 'string',
    error: 'Every property \'category\' in the \'columns\' prop array must be a string.'
  },
  {
    rule: item => typeof item.title === 'string',
    error: 'Every property \'title\' in the \'columns\' prop array must be a string.'
  },
  {
    rule: item => typeof item.theme === 'string',
    error: 'Every property \'theme\' in the \'columns\' prop array must be a string.'
  },
  {
    rule: item => item.emptyState ? typeof item.emptyState === 'string' : true,
    error: 'Every property \'empty-state\' in the \'columns\' prop array must be a string, if included.'
  },
  {
    rule: item => Object.keys(validThemes).some(t => t === item.theme),
    error: `The property 'theme' in the 'columns' prop array must be one of the following: ${Object.keys(validThemes).join(', ')}.`
  }
]

const cardsRules = [
  {
    rule: item => typeof item === 'object',
    warning: 'All array items in the \'cards\' prop must be objects.'
  },
  {
    rule: item => (typeof item.id === 'string') || (typeof item.id === 'number'),
    error: 'Every property \'id\' in the \'cards\' prop array must be a string.'
  },
  {
    rule: item => typeof item.category === 'string',
    error: 'Every property \'category\' in the \'cards\' prop array must be a string.'
  }
]

const defaultEmptyState = 'Não há itens nesta coluna'

/**
 * A Kanban abstract component.
 */
export default {
  name: 'CKanban',

  mixins: [ MediaQuery ],

  props: {
    /**
     * Defines the columns titles, their colors and categories.
     * It must be an array of objects with the following properties:
     *  - category: An identifier string used to place the cards in the right columns.
        - title: The title of the column.
        - theme: The color theme for the title and cards. Should be one of the following:
          'primary', 'positive', 'negative', 'alert' or 'info'.
     */
    columns: {
      type: Array,
      required: true,
      validator: rulesValidator(columnsRules)
    },

    /**
     * Defines the data that will be provided to the kanban cards via scoped-slot
     * (<template #default="{ card }">).
     * It must be an array of objects with the following properties:
     *  - id: An unique id for each card.
        - category: The card category that matches its correct columns.
        - remaining: Takes down the card opacity
        - ...other-props: Any other data that you would like to provide the cards via scoped-slot.
     */
    cards: {
      type: Array,
      required: true,
      validator: rulesValidator(cardsRules)
    },

    /**
     * Column min width
     */
    minWidth: {
      type: [ String, Number ],
      default: 260
    },

    /**
     * Cards height
     */
    cardsHeight: {
      type: [ String, Number ],
      default: 'auto'
    },

    /**
     * Column gap
     */
    columnGap: {
      type: [ String, Number ],
      default: 20
    },

    /**
     * Row gap
     */
    rowGap: {
      type: [ String, Number ],
      default: 10
    },

    /**
     * Mobile row gap
     */
    mobileRowGap: {
      type: [ String, Number ],
      default: 10
    },

    /**
     * Mobile row gap
     */
    mobileColumnGap: {
      type: [ String, Number ],
      default: 10
    },

    /**
     * The callback function to be executed on card clicks.
     */
    onCardClick: {
      type: Function,
      default: null,
    },

    /**
     * The shadow color for overflowing columns.
     */
    shadowColor: {
      type: String,
      default: '#F3F4F6'
    },

    /**
     * The side padding to be applied to the Kanban.
     */
    lateralPadding: {
      type: [ String, Number ],
      default: 40
    },

    /**
     * The mobile side padding to be applied to the Kanban.
     */
    mobileLateralPadding: {
      type: [ String, Number ],
      default: 20
    }
  },

  data () {
    return {
      hasScrollbar: this.columns.reduce((acc, cur) => {
        return { [cur.category]: false, ...acc }
      }, {}),
      mobileWidth: this.minWidth,
      timer: null
    }
  },

  computed: {
    gridVariables () {
      return {
        '--grid-columns': this.columns.length,
        '--grid-column-gap': `${this.columnGap}px`,
        '--grid-row-gap': `${this.rowGap}px`,
        '--grid-row-height': Number.isNaN(this.cardsHeight)
          ? this.cardsHeight
          : `${this.cardsHeight}px`,
        '--grid-mobile-row-gap': `${this.mobileRowGap}px`,
        '--grid-mobile-column-gap': `${this.mobileColumnGap}px`,
        '--grid-column-min-width': `${this.isMobile ? this.mobileWidth : this.minWidth}px`,
        '--lateral-padding': `${this.lateralPadding}px`,
        '--mobile-lateral-padding': `${this.mobileLateralPadding}px`
      }
    },

    hasClickCallback () {
      return typeof this.onCardClick === 'function'
    }
  },

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    themeProps (theme) {
      return validThemes[theme] ? { [validThemes[theme]]: true } : {}
    },

    categoryCards (category) {
      return this.cards.filter(c => c.category === category)
    },

    onResize () {
      if (!this.isMobile) return

      clearTimeout(this.timer)

      const updateMobileSize = () => {
        const padding = 20
        const right = 40
        const width = window.innerWidth

        this.mobileWidth = width - padding - right
      }

      this.timer = setTimeout(updateMobileSize, 30)
    }
  },

  created () {
    this.defaultEmptyState = defaultEmptyState
  }
}
</script>

<style lang="scss">
.c-kanban {
  --title-height: 32px;
  --title-gap: 15px;
  --min-width-calc:
    calc(var(--grid-column-min-width)
    + var(--grid-mobile-column-gap));
  --half-column-gap: calc(var(--grid-mobile-column-gap) / 2);
  --half-row-gap: calc(var(--grid-mobile-row-gap) / 2);
  --title-font-size: 14px;
  --kanban-padding:
    calc(var(--mobile-lateral-padding)
    - var(--grid-mobile-row-gap));

  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns:
    // uses ::before and ::after pseudo-elements
    // to set the outer paddings of the component
    var(--kanban-padding)
    repeat(var(--grid-columns), minmax(var(--min-width-calc), 1fr))
    var(--kanban-padding);

   &::before, &::after { content: ''; }

  & > .column {
    scroll-snap-align: center;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    & > .title-wrapper {
      height: var(--title-height);
      margin-bottom: var(--title-gap);
      padding: 0 var(--half-column-gap);

      & > .c-title > .text {
        font-size: var(--title-font-size);
        color: color-var(text, base-50);
      }
    }

    & > .cards { flex: 1; }
  }

  & > .column > .cards > .wrapper {
    display: grid;
    grid-auto-rows: calc(var(--grid-row-height) + var(--grid-mobile-row-gap));
    grid-template-columns: minmax(0, 1fr);

    & > * {
      margin: var(--half-row-gap) var(--half-column-gap);
      margin-right: 10px;
      &.-remaining { opacity: .2; pointer-events: none; }
      &:not(.empty-state):last-child > * { margin-bottom: 20px; }
    }

    & > .empty-state {
      padding: 15px;
      border-radius: 5px;
      border: 1px dashed color-var(text, base-20);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      @include typo(h3, base-30);
    }

    &:after {
      content: "";
      display: block;
      height: 20px;
    }
  }

  & > .column > .cards.-has-scrollbar > .wrapper > * {
    margin-right: 5px;
  }

  @include responsive (tablet, desktop) {
    --min-width-calc:
      calc(var(--grid-column-min-width)
      + var(--grid-column-gap));
    --half-column-gap: calc(var(--grid-column-gap) / 2);
    --half-row-gap: calc(var(--grid-row-gap) / 2);
    --title-font-size: 16px;
    --kanban-padding: calc(var(--lateral-padding) - var(--grid-row-gap));
  }
}
</style>
