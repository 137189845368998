import isAfter from '@modules/isAfter'

const isNumeric = value => {
  return value.length > 0 ? /^\d+$/.test(value) : true
}

export default ctx => ({
  rg: {
    number: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Número',
      label: 'Número',
    },
    emission_date: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Data de emissão',
      label: 'Data de emissão',
      validation: {
        date: true
      },
      mask: '##/##/####',
    },
    issuing_agency: {
      type: 'text',
      placeholder: 'Órgão emissor',
      label: 'Órgão emissor',
    },
    issuing_state: {
      type: 'select',
      placeholder: 'UF Emissor',
      label: 'UF Emissor',
      displayBy: 'abbreviation',
      trackBy: 'id',
      options: [],
      optionsSrc: 'states'
    }
  },

  cpf: {
    cpf: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Número',
      label: 'Número',
      mask: '###.###.###-##',
      validation: { cpf: true },
    }
  },

  driver_license: {
    number: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Número',
      label: 'Número',
    },
    emission_date: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Data de emissão',
      label: 'Data de emissão',
      validation: { date: true },
      mask: '##/##/####',
    },
    validate_date: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Data de validade',
      label: 'Data de validade',
      validation: {
        custom: ({ formValue, fieldValue }) => isAfter({
          start: fieldValue,
          end: formValue.emission_date
        }) || 'Validade não pode ser anterior a data de emissão'
      },
      mask: '##/##/####',
    },
    category: {
      type: 'text',
      placeholder: 'Categoria',
      label: 'Categoria',
    }
  },

  ctps: {
    number: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Número',
      label: 'Número',
      mask: '#'.repeat(20),
      validation: {
        numeric: true,
        maxLength: 20,
        custom: (_, value) => {
          return !isNumeric(value) ? 'Formato inválido' : true
        }
      }
    },
    serial_number: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Série',
      label: 'Série',
      value: '',
      validation: {
        custom: (_, value) => {
          return !isNumeric(value) ? 'Formato inválido' : true
        }
      }
    },
    emission_date: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Data de emissão',
      label: 'Data de emissão',
      validation: { date: true },
      mask: '##/##/####',
    },
    issuing_state: {
      type: 'select',
      placeholder: 'UF Emissor',
      label: 'UF Emissor',
      displayBy: 'abbreviation',
      trackBy: 'id',
      options: [],
      optionsSrc: 'states'
    },
    pis: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'PIS',
      label: 'PIS',
      mask: '###.#####.##-#',
      validation: 'pis',
      removeSpecialCharacter: true,
    }
  },

  electoral_card: {
    number: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Número',
      label: 'Número',
      validation: 'numeric',
    },
    electoral_ward: {
      type: 'text',
      placeholder: 'Zona eleitoral',
      label: 'Zona eleitoral',
      validation: 'numeric',
    },
    section: {
      type: 'text',
      placeholder: 'Seção eleitoral',
      label: 'Seção eleitoral',
      validation: 'numeric',
    },
    state: {
      type: 'select',
      label: 'UF',
      displayBy: 'abbreviation',
      placeholder: 'UF',
      trackBy: 'id',
      options: [],
      optionsSrc: 'states',
      onInput: async formData => {
        if (formData.city) formData.city = undefined

        ctx.cities = await ctx.getCities(formData.state)
      },
    },
    city: {
      type: 'select',
      placeholder: 'Município',
      label: 'Município',
      displayBy: 'name',
      trackBy: 'id',
      options: [],
      optionsSrc: 'cities',
    }
  },

  reservist: {
    reservist: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Número',
      label: 'Número',
      mask: '#'.repeat(50),
      validation: {
        max: 50
      }
    },
    ra_number: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'RA',
      label: 'RA',
      mask: '#'.repeat(50),
      validation: {
        max: 50
      }
    },
    series: {
      type: 'text',
      placeholder: 'Categoria',
      label: 'Categoria',
      validation: {
        max: 1
      }
    }
  },
})
