<template>
  <transition appear :name="hasTransition ? 'c-modal-fade' : undefined">
    <div v-if="isOpened" :class="[ 'c-modal', { '-open': isOpened } ]">
      <c-overlay v-if="!noOverlay" key="overlay" show @close="emit" />

      <div
        key="c-modal"
        ref="wrapper"
        :class="classes"
        :style="styles"
      >
        <div
          v-click-outside="{ ev: 'mousedown', fn: onClickOutside }"
          :class="[ 'modal',
                    {
                      '-large': large,
                      '-extra-large': extraLarge,
                      '-xx-large': xxLarge,
                      '-fullscreen-width': fullscreenWidth
                    }
          ]"
        >
          <c-loader v-if="isLoading" class="loader" :size="79" />

          <template v-else>
            <div
              v-if="hasHeader"
              :class="[ 'header', { '-img-header': imgHeader } ]"
              :style="imgHeaderValue"
            >
              <!-- @slot The content of the modal's header. Displays a CTitle
                  element by default
                -->
              <slot v-if="!noHeader" name="header">
                <div class="title">
                  <c-title v-bind="titleProps">
                    {{ title }}
                  </c-title>
                </div>
              </slot>

              <div v-if="hasActions" class="actions">
                <!-- @slot The actions (buttons) to display in the modal's header section.
                    These are not removed when the prop `noHeader` is present, or
                    when the `header` slot is used.
                -->
                <slot name="actions" />

                <c-button
                  v-if="!noClose"
                  :disabled="disabled"
                  type="button"
                  icon-size="20"
                  icon="delete-disabled"
                  flat
                  @click="$emit('close')"
                />
              </div>
            </div>

            <div class="content">
              <slot />
            </div>
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { isAlignment } from '@convenia/helpers'
import { disableBodyScroll, enableBodyScroll } from '@convenia/mixins'
import { clickOutside } from '../directives'

/**
 * Our standard modal component.
 */
export default {
  name: 'CModal',

  directives: {
    'click-outside': clickOutside
  },

  props: {
    /**
     * Whether to show or not the modal.
     */
    isOpened: {
      type: Boolean,
      required: true
    },

    hasTransition: {
      type: Boolean,
      default: true
    },

    /**
     * Handles whether to display content or CLoader
     */
    isLoading: Boolean,

    /**
     * The title of the modal (diplayed in the header section by default).
     */
    title: {
      type: String,
      default: ''
    },

    /**
     * Positioning of the modal on the screen,
     * one of: ['right', 'left', 'center', 'bottom].
     */
    position: {
      type: String,
      default: 'center',
      validator: isAlignment
    },

    /**
     * Props to pass to the default CTitle component displayed
     * in the header of the modal.
     */
    titleProps: {
      type: Object,
      default: () => ({ })
    },

    /**
     * Disables the modal actions.
     */
    disabled: Boolean,

    /**
     * Removes the close button.
     */
    noClose: Boolean,

    /**
     * Removes the header section from the modal.
     */
    noHeader: Boolean,

    /**
     * Makes the modal fullscreen
     */
    fullscreen: Boolean,

    /**
     * Whether to show the background overlay or not.
     */
    noOverlay: Boolean,

    // By default, we set `overflow: hidden` in the body element
    // when the modal is open, but in some instances, when the modal
    // is inside a fixed element for example, this won't work.
    // In this case we need to pass the id of the element that should
    // be targeted instead.

    /**
     * The element to set the property { overflow: hidden } to,
     * by default it targets the body element on the page, but in
     * some cases it is necessary to block the overflow of another
     * parent element.
     */
    scrollEl: {
      type: String,
      default: '',
    },

    /**
     * The element which will lock the scroll, this prevents other
     * elements being scrolled at the same time.
     */
    lockEl: {
      type: String,
      default: ''
    },

    /**
     * Sets modal's max width to 960px.
     */
    large: Boolean,

    /**
     * Sets modal's max width to 1000px.
     */
    extraLarge: Boolean,

    /**
     * Sets modal's max width to 1440px (and full screen when width is smaller).
     */
    xxLarge: Boolean,

    /**
     * Set modal's max width to 100vw
     */
    fullscreenWidth: Boolean,

    /**
     * Sets outside click
     */
    isOutsideClick: Boolean,

    /**
     * Enable header image
     */
    imgHeader: {
      type: String,
      default: '',
    },

    /** Sets margin bottom when mnecessary
     * (e.g. when a select element positioned at the bottom of the modal
     * has its contents cut off).
     */
    marginBottom: {
      type: [ Number, String ],
      default: 0
    }
  },

  computed: {
    classes () {
      return [ 'wrapper',
        `-${this.position}`, {
          '-fullscreen': this.fullscreen,
        } ]
    },

    styles () {
      return {
        '--margin-bottom': `${this.marginBottom}px`
      }
    },

    hasActions () {
      return !this.noClose || this.$slots.actions
    },

    hasHeader () {
      return this.hasActions || (!this.noHeader || this.$slots.header)
    },

    imgHeaderValue () {
      if (!this.imgHeader) return

      return {
        '--img': `url(${this.imgHeader})`
      }
    }
  },

  watch: {
    isOpened (newValue, oldValue) {
      if (newValue === oldValue) return
      this.checkOverflow()
    },
    scrollEl (newValue, oldValue) {
      if (newValue === oldValue) return
      this.checkOverflow()
    }
  },

  methods: {
    checkOverflow () {
      this.isOpened ? this.open() : this.close()
    },

    open () {
      const styles = { overflow: 'hidden' }
      const el = this.scrollEl
        ? document.getElementById(this.scrollEl)
        : document.body

      if (this.lockEl) this.$nextTick(() =>
        disableBodyScroll(this.$el.querySelector(this.lockEl)))

      Object.assign(el.style, styles)
      window.addEventListener('keydown', this.closeOnEsc)
    },

    close () {
      const styles = { overflow: '' }
      const el = this.scrollEl
        ? document.getElementById(this.scrollEl)
        : document.body

      if (this.lockEl && this.$el?.children?.length)
        enableBodyScroll(this.$el.querySelector(this.lockEl))

      Object.assign(el.style, styles)
      window.removeEventListener('keydown', this.closeOnEsc)
    },

    emit () {
      /**
       * Emitted when the user either presses the close button, clicks
       * outside the modal window, or presses the ESC key.
       * @event
       */
      this.$emit('close')
      this.close()
    },

    closeOnEsc (ev) {
      if (ev.keyCode === 27) this.emit()
    },

    emptyStateText () {
      return this.hasAccountancy
        ? this.$options.emptyMessage
        : this.$options.noAccountancyEmptyMessage
    },

    onClickOutside (event) {
      const scrollGapSize = 20

      if (window.innerWidth <= (event.clientX + scrollGapSize)) return

      if (this.noOverlay || this.isOutsideClick || this.fullscreen) this.emit()
    }
  },

  mounted () {
    this.checkOverflow()
    this.$root.$emit('c-modal:mounted')
  },

  beforeDestroy () {
    this.close()
    this.$root.$emit('c-modal:destroy')
    window.removeEventListener('keydown', this.closeOnEsc)
  }
}
</script>

<style lang="scss">
.c-modal.-open {
  position: fixed;
  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100%;

  z-index: var(--z-index-5);
}

.c-modal > .wrapper {
  position: fixed;
  z-index: var(--z-index-5);
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;

  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: hidden;

  &.-right > .modal { align-self: flex-end; }
  &.-left > .modal { align-self: flex-start; }
  &.-center, &.-bottom { align-items: center; }

  &.-fullscreen {
    overflow-y: auto;
    overflow-x: hidden;

    & > .modal {
      width: calc(1024px - 130px);
      max-width: calc(100% - 130px);

      min-height: 100vh;
      top: 0px;

      &, &:before { border-radius: 0; }
      margin: 0;

      @include responsive (xs-mobile, mobile) {
        max-width: 100vw;
        height: unset;
      }
    }
  }

  &:not(.-fullscreen) {
    @include responsive (tablet, desktop) {
      &:before,
      &:after {
        content: "";
        flex: 0 0 80px;
      }
    }
  }

  & > .modal {
    display: flex;
    position: relative;
    flex-shrink: 0;
    flex-direction: column;
    z-index: var(--z-index-3);
    opacity: 1;
    max-width: 100%;
    box-shadow: var(--box-shadow);
    margin-bottom: var(--margin-bottom);

    & > * {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: '';
      display: inline-flex;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: linear-gradient(180deg, #FFF, rgba(255, 255, 255, .83) 100%);
      backdrop-filter: blur(4px);
    }

    @include responsive (xs-mobile, mobile) {
      width: 100vw;
      top: 0;
      bottom: 0;
      position: absolute;
      transform: translate3d(0, 0, 0);
      margin-bottom: 0;
    }

    &.-fullscreen-width {
      width: 100vw;
      max-width: 100vw;
    }

    &.-xx-large {
      max-width: map-get($breakpoints, large-desktop);

      @include responsive-interval(tablet, large-desktop) {
        &, &:before { border-radius: unset; }
        width: 100vw;
        top: 0;
        bottom: 0;
        position: absolute;
        transform: translate3d(0, 0, 0);

        min-height: 100vh;
        overflow-y: hidden;

        & > .content {
          overflow-y: auto;
        }
      }
    }

    @include responsive(tablet, desktop) {
      width: 100%;
      min-height: 250px;
      max-width: 580px;

      &, &:before { border-radius: 5px; }
      &.-large { max-width: 960px; }
      &.-extra-large { max-width: 1000px !important; }
    }

    & > .loader {
      flex: 1;
      display: flex;
      align-self: center;
      align-items: center;
    }

    & > .header {
      display: flex;
      align-items: flex-start;
      flex-shrink: 0;

      padding: 20px 20px 15px;

      &.-img-header {
        height: 200px;
        background-image: var(--img);
        background-repeat: no-repeat;
        background-size: cover;

        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      & > .title {
        margin-right: auto;
        @include responsive(xs-mobile, mobile) { display: inline-grid; }

        & > .c-title > .text { color: color-var(text, base-80); }
      }

      & > .actions {
        display: flex;
        align-items: center;

        margin-left: auto;

        & > a:not(:last-child),
        & > button:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    & > .content {
      min-height: 0;
      width: 100%;
      padding: 5px 20px 50px;
      min-height: 0;
    }
  }
}

/**
 * Transition
 * ----------
 */

.c-modal-fade {
  &-enter-active,
  &-leave-active {
    transition: opacity .5s;

    & > .wrapper > .modal { transition: transform .5s, opacity 350ms; }
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    & > .wrapper {
      & > .modal { opacity: 0; }
      &.-right > .modal { transform: translateX(100%); }
      &.-left > .modal { transform: translateX(-100%); }
      &.-bottom > .modal { transform: translateY(100%); }
      &:not(.-right, .-left) > .modal { transform: scale(0.3); }
    }
  }
}
</style>
