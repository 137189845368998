<template>
  <div class="c-auto-complete-list-wrapper">
    <div class="list">
      <!-- I have to insert an extra 's' here in the search
           prop for whatever reason
      -->
      <item
        v-if="showEmptyState"
        :item="emptyItem"
        :pointer="-1"
        :index="0"
        search="encontradoss"
        search-prop="label"
        normalize-prop="normalized"
        class="item"
      />

      <template v-else>
        <item
          v-for="(item, index) in items"

          :key="index"
          :item="item"
          :index="index"
          :pointer="pointer"
          class="item"

          v-bind="$attrs"
          v-on="$listeners"
        >
          <slot slot="before" slot-scope="{ item }" name="before" :item="item" />

          <slot slot="after" slot-scope="{ item }" name="after" :item="item" />
        </item>
      </template>
    </div>
  </div>
</template>

<script>
import Item from './Item.vue'

export default {
  name: 'CAutoCompleteList',

  components: { Item },

  props: {
    items: {
      type: Array,
      required: true
    },

    showEmptyState: Boolean,

    pointer: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    emptyItem () {
      return {
        area: '',
        key: '',
        label: 'Sem resultados encontrados',
        normalized: 'sem resultados',
        route: '',
        selector: '',
        subArea: ''
      }
    }
  }
}
</script>

<style lang="scss">
.c-auto-complete-list-wrapper {
  display: flex;

  position: absolute;
  left: 0;
  top: 100%;

  width: 100%;
  z-index: 10;
  background: white;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.2);
  max-height: calc(285px - 40px); // 40 = input size

  & > .list {
    width: 100%;
    font-size: 14px;
    overflow-y: auto;
  }
}
</style>
