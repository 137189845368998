import { toHours } from '@convenia/helpers'
import { formatDate } from '@modules/dateHelpers'

export const toMoney = (value) => {
  const options = { style: 'currency', currency: 'BRL' }
  return (value || 0).toLocaleString('pt-BR', options)
}

export const toPrice = (price) => {
  const value = price.toString().replace(/[^\d,-]/g, '').replace(/,/g, '.')
  return parseFloat(value).toFixed(2)
}

export const toMilliseconds = (value) => {
  if (Number.isInteger(value)) return value

  const parsedValue = value.replace('hs', '').split(':')
  const hours = +(parsedValue[0] || 0) * 3600000
  const minutes = (parsedValue[1] || '').length < 2
    ? +(`${parsedValue[1] || 0}0`) * 60000
    : +(parsedValue[1] || 0) * 60000

  return hours + minutes
}

export const mapToNumeric = value => ((value || '') + '').replace(/\D/g, '')

/**
 *  Source: https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
 */
export const camelize = (input) => input
  .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
    index === 0 ? word.toLowerCase() : word.toUpperCase())
  .replace(/\s+/g, '')

export const formatDateToUS = (date) => {
  if (!date) return ''

  return date.substr(0, 10).split('/').reverse().join('-')
}

export const masker = (mask, value, TOKEN_CHAR = '#') => {
  if (!value) return ''

  const inputLeft = String(value).replace(/\D/g, '').split('')

  return mask.split('')
    .map(char => char === TOKEN_CHAR ? inputLeft.shift() : char)
    .join('')
}

/**
 * Payroll-specific event formatters
 */

const eventTypes = {
  Valor: {
    format: (value) => (Number.isFinite(value) ? toMoney(value) : '–'),
    parse: toPrice
  },
  Dias: {
    format: (value) => value,
    parse: (value) => value
  },
  Automático: {
    format: (value) => value,
    parse: (value) => value
  },
  Percentual: {
    format: (value) => value,
    parse: (value) => value
  },
  Horas: {
    format: (value) => (value ? `${toHours(value)}hs` : '–'),
    parse: toMilliseconds
  },
  Data: {
    format: (value) => (value ? formatDate(value) : '–'),
    parse: (value) => value
  }
}

export const formatValue = (value, format) => (eventTypes[format] || eventTypes['Automático']).format(value)

export const parseValue = (value, format) => (eventTypes[format] || eventTypes['Automático']).parse(value)

export const formatToBRL = value => {
  const formattedNumber = Number(value)
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

  return `R$ ${formattedNumber}`
}

export const parseDate = (date) => {
  const yyyy = date.getFullYear()
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const dd = String(date.getDate()).padStart(2, '0')

  return `${yyyy}-${mm}-${dd}`
}
