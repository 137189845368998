import { headers } from '@modules/rest'
import cookie from 'js-cookie'

const companyId = cookie.get('active_company_uuid')

export const getUploadEndpoint = (employeeId, entityId, type) =>
  `${import.meta.env.VITE_EMPLOYEE_REST_API}/company/${companyId}/employee/${employeeId}/document/${entityId}/type/${type}/upload`

export const getFileUrl = (file, employeeId) => {
  const fileId = file.id
  const url = `${import.meta.env.VITE_EMPLOYEE_REST_API}/employee/${employeeId}/file/${fileId}/previewFile`

  return fetch(url, {
    headers: headers([ 'Authorization' ])
  })
    .then(response => response.blob())
    .then(blob => (window.URL || window.webkitURL).createObjectURL(blob))
}

export const mapFiles = (files, employeeId) => files?.map(file => ({
  id: file.id,
  name: file.fileName,
  size: file.size,
  url: () => getFileUrl(file, employeeId),
  preview: () => getFileUrl(file, employeeId)
}))

export const deleteFile = async (variables) => {
  const { file, employeeId } = variables

  if (!file?.id) return [ false, file ]

  const response = await fetch(`${import.meta.env.VITE_EMPLOYEE_REST_API}/employee/${employeeId}/document/file/${file.id}`, {
    method: 'DELETE',
    headers: headers([ 'Authorization' ])
  })

  if (!response.ok) return [ true, null ]

  const data = await response.json()

  return [ false, data ]
}
