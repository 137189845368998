<template>
  <div class="c-sortable">
    <template v-for="(item, i) in value">
      <div
        v-show="!(item || {}).hide"
        :key="`divider-${i}`"
        :style="{ order: (i * 2) + 1 }"
        class="divider"
      >
        <!-- @slot Divider between each item -->
        <slot
          name="divider"
          :item="getItemScopeData(item, i)"
        />
      </div>

      <div
        v-show="!(item || {}).hide"
        :key="item[trackBy]"
        :sort-key="item[trackBy]"
        :sort-index="i"
        :style="{ order: (i * 2) + 2 }"
        :class="getItemClasses(i)"
        v-on="rootDragHandlers ? getDragHandlers(i) : {}"
      >
        <!-- @slot Content item -->
        <slot
          name="content"
          :item="getItemScopeData(item, i)"
        />
      </div>
    </template>
  </div>
</template>

<script>
/**
 * A sortable vertical list using drag n' drop.
 *
 * You can style the drag item being dragged with --selected (placeholder)
 * and --drag (element being dragged) class modifiers inside the unique class item definition
 * (see storybook example)
 *
 * You can lock the parent drag behaviour on children elements which has
 * the @pointerdown.stop handler definition
 *
 * You can also set the drag handlers on item children, allowing to only drag items when touching
 * on those sub elements.
 */

export default {
  name: 'CSortable',

  props: {
    /**
     * The values of the sorted items data.
     */
    value: {
      type: Array,
      required: true,
    },

    /**
     * The track string of each element
     */
    trackBy: {
      type: String,
      default: 'id'
    },

    /**
     * The element which will define the drag area
     */
    dragZoneElement: {
      type: Element,
      default: () => document.body
    },

    /**
     * Scrolling element
     */
    scrollElement: {
      type: Element,
      default: null,
    },

    /**
     * Whether the drag is active
     */
    active: {
      type: Boolean,
    },

    /**
     * Unique class name for items of the sortable list
     */
    itemUniqueClass: {
      type: String,
      required: true
    },

    /**
     * Auto set the drag handlers on item root
     */
    rootDragHandlers: Boolean,
  },

  computed: {
    usedScrollElement () {
      return this.scrollElement || this.dragZoneElement
    }
  },

  methods: {
    initializeDragHandlers () {
      this._targetElement.addEventListener('pointerup', this.dragEndHandler, { passive: true })
      this._targetElement.addEventListener('pointercancel', this.dragEndHandler, { passive: true })
      this._targetElement.addEventListener('pointermove', this.dragHandler, { passive: true })
      this._targetElement.addEventListener('pointerdown', this.dragHandler, { passive: true })
      window.addEventListener('mouseup', this.dragEndHandler)
    },

    removeDragHandlers () {
      this._targetElement.removeEventListener('pointerup', this.dragEndHandler)
      this._targetElement.removeEventListener('pointercancel', this.dragEndHandler)
      this._targetElement.removeEventListener('pointermove', this.dragHandler)
      this._targetElement.removeEventListener('pointerdown', this.dragHandler)
      window.removeEventListener('mouseup', this.dragEndHandler)
    },

    touchHandler (e) { e.preventDefault() },

    dragStartHandler (index, event) {
      if (this._dragItem) return
      event.stopPropagation()
      if (this.itemIsLocked(index)) return

      this._draggingValue = [ ...this.value ]
      const itemElement = this.findItemEl(index)
      this._itemElement = itemElement
      this._targetElement = event.target
      event.target.setPointerCapture(event.pointerId)

      if (!this.active) return this.inactiveDragStartHandler(index, event)

      const { clientX, clientY } = event
      const { x, y, width } = itemElement.getBoundingClientRect()

      this._dx = clientX - x
      this._dy = clientY - y

      this._dragItemEl = itemElement.cloneNode(true)
      this._dragItemEl.setAttribute('aria-hidden', 'true')
      this._dragItemEl.classList.add('--drag')
      this._dragItemEl.id = `${this.dragItemId}-c-sortable-drag-item`
      this._dragItemEl.style.width = `${width}px`
      this._itemElement.classList.add('--selected')
      this.moveDragItemEl(event)()

      this.dragZoneElement.appendChild(this._dragItemEl)

      this.dragHandler(event)
      this.$nextTick(() => {
        this._dragItem = Number(this._itemElement.getAttribute('sort-index'))
      })
      this.initializeDragHandlers()
    },

    inactiveDragStartHandler (index, event) {
      this.$emit('inactive-dragstart', { index, rawEvent: event })

      let checkInactiveUpdate = null
      const dragEndHandler = event => {
        cancelAnimationFrame(this._raf)
        this._raf = null
        this._targetElement.removeEventListener('pointerup', dragEndHandler)

        this.releasePointerCapture(this._targetElement, event)

        if (!this.active) this.$emit('inactive-dragend', { index, rawEvent: event })
      }
      this._targetElement.addEventListener('pointerup', dragEndHandler)

      checkInactiveUpdate = this.checkInactiveUpdate(
        index, event, () => dragEndHandler(event)
      )
      this._raf = window.requestAnimationFrame(checkInactiveUpdate)
    },

    checkInactiveUpdate (index, event, inactiveDragEndHandler) {
      const handler = () => {
        if (this.active) {
          inactiveDragEndHandler()
          return this.dragStartHandler(index, event)
        }

        this._raf = requestAnimationFrame(handler)
      }

      return handler
    },

    dragHandler (e) {
      e.stopPropagation()
      if (!this._raf) {
        this._raf = requestAnimationFrame(this.moveDragItemEl(e))
      }
    },

    moveDragItemEl (event) {
      return () => {
        setTimeout(() => {
          this.checkScrollBoundary(event)
          this.checkDragOver(event)
        }, 0)

        const { clientX, clientY } = event
        const dragItemX = `${clientX - this._dx}px`
        const dragItemY = `${clientY - this._dy}px`

        this._dragItemEl.style.transform = `translate3d(${dragItemX}, ${dragItemY}, 0)`
        this._raf = null
      }
    },

    checkScrollBoundary (event) {
      const { clientY } = event || {}
      const { height: scrollElementHeight } = this.usedScrollElement.getBoundingClientRect()
      const { height } = this._dragItemEl.getBoundingClientRect()
      const boundary = height / 2

      this._scrollSpeed = 1

      if (this._scrollRaf) {
        cancelAnimationFrame(this._scrollRaf)
        this._scrollRaf = null
      }

      if (clientY < boundary && !this._scrollRaf) {
        this._scrollRaf = requestAnimationFrame(this.scroll(-1))
        return
      }

      if (clientY > (scrollElementHeight - boundary) && !this._scrollRaf) {
        this._scrollRaf = requestAnimationFrame(this.scroll(1))
      }
    },

    scroll (direction) {
      const handler = () => {
        if (!this._scrollRaf) return
        const { scrollTop } = this.usedScrollElement
        this.usedScrollElement.scrollTo({ top: scrollTop + this._scrollSpeed * direction })

        this._scrollSpeed = Math.min(10, this._scrollSpeed + 1)
        this._scrollRaf = requestAnimationFrame(handler)
      }

      return handler
    },

    checkDragOver (event) {
      event.stopPropagation()
      if (this._scrollRaf) return

      const { _dragItem: dragItem, itemIsLocked } = this
      const dragItemPosition = this.getPosition(this._dragItemEl)

      const overItemElement = Object.values(this._intersectingElements).find(element => {
        const index = Number(element.getAttribute('sort-index'))
        if (index === dragItem) return
        if (itemIsLocked(index)) return

        const itemPosition = this.getPosition(element)
        const xDiff = itemPosition.x - dragItemPosition.x
        const yDiff = itemPosition.y - dragItemPosition.y
        const { height, width } = element.getBoundingClientRect()

        if ((dragItem < index && yDiff > 0) || (dragItem > index && yDiff < 0)) return

        return (
          Math.abs(xDiff) < width / 2
          && Math.abs(yDiff) < height
        )
      })

      if (!overItemElement) return

      const overItem = Number(overItemElement?.getAttribute('sort-index'))

      this.updateValue(overItem, overItemElement)
    },

    getPosition (element) {
      const { left, top, width, height } = element.getBoundingClientRect()
      const offsetLeft = left + window.scrollX
      const offsetTop = top + window.scrollY
      return { x: offsetLeft + width / 2, y: offsetTop + height / 2 }
    },

    dragEndHandler (event) {
      this.checkScrollBoundary(event)

      this.releasePointerCapture(this._targetElement, event)

      this._itemElement.classList.remove('--selected')
      this._itemElement = null
      this._dragItemEl.remove()
      this._dragItemEL = null
      this._dragItem = null
      this._dx = null
      this._dy = null
      this._scrollSpeed = 1

      if (this._scrollRaf) {
        cancelAnimationFrame(this._scrollRaf)
        this._scrollRaf = null
      }

      this.removeDragHandlers()

      setTimeout(() => {
        this.$emit('input', this._draggingValue)
        this.$nextTick(() => {
          this._dragItem = null

          /**
          * When finishes dragging
          */
          this.$emit('dragend')
        })
      }, 100)
    },

    updateValue (overItem, overItemElement) {
      const { _dragItem: dragItem, _draggingValue: value } = this

      /**
      * When moves in some direction and oder the list
      */
      this._draggingValue = this.arrayMove(value, dragItem, overItem)
      this.updateElements(dragItem, overItem, overItemElement)
    },

    updateElements (dragItem, overItem, overItemElement) {
      const diff = overItem - dragItem

      const dragItemElement = this._itemElement

      dragItemElement.setAttribute('sort-index', overItemElement.getAttribute('sort-index'))
      dragItemElement.style.order = overItemElement.style.order

      const accumulator = diff > 0 ? -1 : 1
      const siblingReference = accumulator === 1 ? 'nextElementSibling' : 'previousElementSibling'
      const limit = Math.abs(diff)

      let currentElement = overItemElement
      for (let i = 0; i < limit; i++) {
        const newIndex = ((i * accumulator) + overItem) + accumulator

        currentElement.setAttribute('sort-index', newIndex)
        currentElement.style.order = (newIndex * 2) + 2
        currentElement = currentElement?.[siblingReference]?.[siblingReference]
      }

      this._dragItem = overItem
    },

    arrayMove (arr, fromIndex, toIndex) {
      const newArr = [ ...arr ]
      const element = arr[fromIndex]
      newArr.splice(fromIndex, 1)
      newArr.splice(toIndex, 0, element)
      return newArr
    },

    /**
     * Refs in v-for loop are not being properly updated even using nextTick. So the good n' old
     * query selector is being used instead.
     */
    findItemEl (index) {
      return this.$el.querySelectorAll(':scope > .c-sortable-item-root')[index]
    },

    getDragHandlers (index) {
      return {
        pointerdown: event => this.dragStartHandler(index, event),
        touchstart: event => {
          event.stopPropagation()
          event.preventDefault()
        }
      }
    },

    getItemScopeData (item, index) {
      return {
        ...item,
        dragHandlers: this.getDragHandlers(index),
        onViewport: !!this._intersectingElements?.[item?.[this.trackBy]],
        index
      }
    },

    getItemClasses () {
      return [
        'c-sortable-item-root',
        this.itemUniqueClass,
        { '--active': this.active }
      ]
    },

    itemIsLocked (index) {
      return ((this.value || [])[index] || {}).locked
    },

    releasePointerCapture (element, event) {
      const { pointerId } = event || {}

      if (pointerId !== undefined && element.hasPointerCapture(pointerId))
        element.releasePointerCapture(pointerId)
    },

    onIntersect (entries) {
      entries.forEach((entry) => {
        const { target, isIntersecting } = entry || {}
        const key = target?.getAttribute('sort-key')
        if (!key) return

        if (isIntersecting && !this._intersectingElements[key]) {
          this._intersectingElements[key] = target
          return
        }

        if (this._intersectingElements[key]) {
          delete this._intersectingElements[key]
        }
      }, {})
    }
  },

  mounted () {
    this._intersectingElements = {}
    this._intersectionObserver = new IntersectionObserver(this.onIntersect, {
      root: null
    })

    this.$nextTick(() => {
      this.value.forEach((_, i) => {
        this._intersectionObserver.observe(this.findItemEl(i))
      })
    })
  },

  beforeDestroy () {
    this._intersectionObserver.disconnect()
  },

  watch: {
    value: {
      immediate: true,
      handler (newValue) {
        this.$nextTick(() => {
          newValue.forEach((_, i) => {
            this._intersectionObserver.observe(this.findItemEl(i))
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.c-sortable {
  user-select: none;
  -webkit-user-select: none;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  position: relative;
}

.c-sortable-item-root {
  width: 100%;
  will-change: transform, left, top;
  backface-visibility: hidden;

  &.--drag {
    z-index: 99999;
    position: absolute;
    cursor: grabbing;
    top: 0;
    left: 0;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
    user-select: none;
    contain: layout;
    pointer-events: none;
  }
}
</style>
