/**
 * Checks whether an array has greater length than the minimum value.
 * @param {Array} value - The input value
 * @param {Array} params - The validation params
 */
export const minLength = (value, params) => {
  const [ min ] = params

  return value.length > min || `Necessário minimo de ${min} ${min === 1 ? 'item' : 'itens'}`
}
