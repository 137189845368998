import Vue from 'vue'
import VueRouter from 'vue-router'
import Application from '@views/Application'
import Authentication from '@views/Authentication'
import Message from '@views/Message'
import Contracts from '@views/Contracts'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'application',
    component: Application
  },
  {
    path: '/step/:step',
    name: 'step',
    component: Application
  },
  {
    path: '/colaborador/start/:token',
    name: 'authentication',
    component: Authentication
  },
  {
    path: '/complete',
    name: 'complete',
    component: Contracts,
    props: {
      title: 'Formulário enviado!',
      text: 'A seguir você consegue acessar os links dos Termos e Contratos enviados pela empresa. Aguarde o recebimento dos próximos passos no seu e-mail!',
      character: 'paper'
    },
  },
  {
    path: '/done',
    name: 'done',
    component: Message,
    props: {
      title: 'O formulário já foi enviado',
      text: 'Você não tem mais acesso ao formulário pois sua admissão já foi enviada. Qualquer dúvida entre em contato com a empresa!',
      character: 'success'
    }
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: Message,
    props: {
      title: 'Permissão negada',
      text: 'Acesse o link enviado para seu e-mail.',
      character: 'cactus'
    }
  },
  {
    path: '*',
    name: 'not-found',
    component: Message,
    props: {
      title: 'Página não encontrada',
      text: 'Acesse o link enviado para seu e-mail.',
      character: 'cactus'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes
})

export default router
