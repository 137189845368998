<template>
  <c-input-container
    class="c-input"
    v-bind="$attrs"
  >
    <c-input-raw
      v-bind="$attrs"
      v-on="$listeners"
    />
  </c-input-container>
</template>

<script>
import CInputRaw from './fragments/CInputRaw'

export default {
  name: 'CInput',
  components: { CInputRaw }
}
</script>
