<template>
  <component
    :is="item.separator || item.loading ? 'span' : 'c-link'"
    :external="!item.loading && item.external"
    :class="classes"
    :data-testid="item['data-testid']"
    :to="!item.loading && item.to"
    target="_self"
    exact
    @click.native="$emit('click')"
    @mouseenter.native="mouseOver = true"
    @mouseleave.native="mouseOver = false"
  >
    <template v-if="!item.separator">
      <component
        v-if="item.icon || item.loading"
        :is="item.loading ? 'div' : 'c-icon'"
        :icon="!item.loading && item.icon"
        :size="!item.loading && '24'"
        class="icon"
        v-on="$listeners"
      />

      <span
        :style="{ width: item.loading && item.width }"
        class="full-name"
      >
        {{ item.name }}
      </span>

      <span
        :style="{ width: item.loading && item.width }"
        class="short-name"
      >
        {{ item.short }}
      </span>

      <div v-if="collapse" class="highlight">
        <span class="text">
          {{ item.name }}
        </span>
      </div>
    </template>
  </component>
</template>

<script>
/**
 * Individual CSidebar item component.
 */
export default {
  name: 'CSidebarItem',

  props: {
    /**
     * The item object.
     */
    item: {
      type: Object,
      required: true
    },

    persistMobile: Boolean,

    collapse: Boolean
  },

  data: () => ({
    collapseHover: false,
    timeoutId: null,
    mouseOver: false
  }),

  watch: {
    mouseOver (isOver) {
      if (isOver && !this.collapseHover && !this.timeoutId) {
        this.timeoutId = setTimeout(() => { this.collapseHover = true }, 300)
        return
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }

      this.collapseHover = false
    }
  },

  computed: {
    classes () {
      return [
        (this.item.separator ? 'c-sidebar-separator' : 'c-sidebar-item'),
        {
          '-collapse': this.collapse,
          '-collapse-hover': this.collapseHover,
          '-active': this.item.active,
          '-mobile-only': this.persistMobile,
          '-beta': this.item.beta,
        }
      ]
    }
  }
}
</script>

<style lang="scss">
$c-sidebar-item-text-color: #FFF;
$c-sidebar-item-selected-bg: #FDFDFD;
$c-sidebara-separator-bg: #121E48;

@keyframes pulse {
  0% { opacity: 0.1 }
  50% { opacity: 0.3 }
  100% { opacity: 0.1 }
}

@mixin add-mobile-styles {
  margin: 0;
  padding: 0;
  height: calc((100vw - 50px) / 3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.05);
  border-radius: 5px;

  &.c-sidebar-separator { display: none; }

  &.-active { background-color: rgba(253,253,253,0.2); }

  & > .full-name { display: none; }

  & > .short-name {
    display: inline;
    margin-left: 0;
    margin-top: 10px;
  }
}

.c-sidebar-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;

  padding: 11px 20px;
  margin: 5px 0;

  font-size: 11px;
  font-weight: var(--title-font-weight);
  font-family: var(--title-font-family);
  color: rgba($c-sidebar-item-text-color, .8);
  background: transparent;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-transform: uppercase;
  text-decoration: none;
  line-height: 16px;
  text-align: left;

  transition: background ease 200ms, color ease 300ms;

  cursor: pointer;

  &.-collapse {
    position: relative;
    background: transparent;
    background-color: transparent !important;
    justify-content: center;

    & > .full-name, & > .short-name {
      display: none;
      z-index: 2;
      flex-shrink: 0;
    }

    & > .icon { position: relative; z-index: 2; }

    &.-beta {
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 45px;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: color-var(negative);
      }
    }

    &.c-sidebar-item.-collapse-hover > .icon {
      stroke: white;
      opacity: 1;
    }

    &.-collapse-hover > .highlight {
      max-width: 240px;
      opacity: 0.9;
      z-index: 1;

      padding-left: 40px;
      padding-right: 20px;

      transition: max-width 400ms ease, opacity 300ms ease, padding-left 300ms, padding-right 300ms;

      & > .text {
        white-space: nowrap;
        opacity: 1;
        transition: opacity 600ms;
      }
    }

    & > .highlight {
      opacity: 0;
      z-index: -1;
      box-sizing: content-box;

      background: linear-gradient(to right, color-var(text, base-90), color-var(text));
      box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
      border-radius: 30px;
      position: absolute;

      display: inline-flex;
      align-items: center;
      justify-content: center;

      padding-left: 0px;
      padding-right: 0px;

      max-width: 40px;
      height: 40px;
      left: 11px;

      & > .text { opacity: 0; }

      transition: max-width 400ms ease, opacity 300ms ease, padding-left 300ms, padding-right 300ms;
    }
  }

  &.-beta {
    & > .full-name,
    & > .short-name,
    & > .highlight > .text {
      &:after {
        display: inline-block;
        content: "Beta";
        background: color-var(negative);
        color: white;
        font-size: 9px;
        font-family: Ubuntu;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0px 5px;
        margin-left: 5px;
        margin-right: -5px;
        border-radius: 20px;
        transform: translateY(-1px);
        box-shadow: 0 1px 4px 1px rgba($negative-color, .3);
      }
    }
    & > .short-name {
      &:after {
        position: absolute;
        top: 5px;
        right: 5px;
        transform: initial;
        margin: 0;
      }
    }
  }

  & > .icon {
    stroke: $c-sidebar-item-text-color;
    transition: stroke ease 300ms, opacity ease 300ms;
    flex-shrink: 0;
    opacity: 0.5;
  }

  & > .full-name { display: inline; flex-shrink: 0; }

  & > .short-name { display: none; }

  & > .full-name:not(:only-child) { margin-left: 20px; }

  &:hover {
    color: rgba($c-sidebar-item-text-color, 1);

    & > .icon {
      stroke: $c-sidebar-item-text-color;
      opacity: 0.8;
    }
  }

  &.-active,
  &.router-link-active {
    background-color: rgba($c-sidebar-item-selected-bg, .2);
    border-radius: 5px;

    color: rgba($c-sidebar-item-text-color, 1);

    & > .icon {
      @include icon-color($c-sidebar-item-text-color);
      opacity: 1;
    }
  }

  @include xs-mobile { @include add-mobile-styles }

  &.-mobile-only { @include add-mobile-styles }
}

.c-sidebar-separator {
  display: inline-block;
  width: 100%;
  height: 1px;
  margin: 9px 0;
  background-color: rgba($c-sidebara-separator-bg, 0.1);

  @include xs-mobile { display: none; }
}
</style>
