/**
 * Creates a GraphQL error handler to capture client request errors on sentry
 * @param {function} captureException - The capture function of the sentry lib
 * @returns { (gqlError: object, context: object) => void } - The error handler
 */

export const captureGqlClientError = captureException => (error, context) => {
  const { message, extensions, ...additionalErrorInfo } = error || {}
  if (!message) return

  const { status } = extensions || {}

  // Bad Request, Forbidden and Not Found errors
  if (status === 400 || status === 404 || status === 403)
    captureException(new Error(message), { extra: { ...context, ...additionalErrorInfo } })
}

/**
 * Initializes the sentry object with a custom configuration.
 * @typedef {object} Configuration
 * @property {RegExp[]} validUrlPatterns - A list of valid urls to capture errors
 * @property {function} beforeSend - A function to run before sending the error
 * @property {...object} remainingConfiguration - The remaining configuration. Identical
 * to the sentry configuration object
 * @param {*} sentry - The sentry object
 * @param {Configuration} configuration - The configuration object
 */
export const sentryInit = (sentry, configuration) => {
  const { validUrlPatterns, beforeSend, ...remainingConfiguration } = configuration || {}

  sentry.init({
    ...remainingConfiguration,
    beforeSend: (event) => {
      if ((validUrlPatterns || []).length) {
        const { url } = (event || {}).request || {}

        if (!validUrlPatterns.some(validUrlPattern => {
          return validUrlPattern.test(url)
        })) return null
      }

      if (beforeSend) return beforeSend(event)
      return event
    }
  })
}
