<template>
  <div
    v-click-outside="reset"
    :style="styles"
    :class="classes"
    @keyup.esc="reset"
  >
    <div class="search-wrapper" @click="showItems = !showItems">
      <c-search-box
        ref="searchBox"
        class="search"
        :value="value"
        :placeholder="placeholder"
        :class="{ '--is-opened': hasFocus && !Object.keys(selected).length }"
        :alternative-focus="alternativeFocus"
        @keydown.up.prevent="up"
        @keydown.down.prevent="down"
        @keydown.enter.stop.prevent.self="select"
        @click.native="$emit('toggle-focus', !hasFocus)"
        @input="ev => onSearch(ev)"
      />
    </div>

    <item-list
      v-show="visibleList"
      :items="filteredItems"
      :search="computedSearch"
      :pointer="pointer"
      :search-prop="searchProp"
      :normalize-prop="normalizeProp"
      :show-empty-state="showEmptyState"
      @item-list:click="select({ key: 'Click' })"
      @item-list:mouseenter="index => pointer = index"
    >
      <slot slot="before" slot-scope="{ item }" name="before" :item="item" />

      <slot slot="after" slot-scope="{ item }" name="after" :item="item" />
    </item-list>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import { setDiacritic, normalizeString } from '@convenia/helpers'
import { clickOutside } from '@convenia/components/directives'

import ItemList from './ItemList'

export default {
  name: 'CAutoCompleteInput',

  components: { ItemList },

  mixins: [ MediaQuery ],

  props: {
    value: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    options: {
      type: Array,
      default: () => []
    },

    searchProp: {
      type: String,
      default: 'key'
    },

    normalizeProp: {
      type: String,
      default: 'normalized'
    },

    minToSearch: {
      type: Number,
      default: 2
    },

    showList: Boolean,

    hasFocus: Boolean,

    alternativeFocus: Boolean,

    selected: {
      type: Object,
      default: () => ({}),
    },

    loading: Boolean,
  },

  directives: { clickOutside },

  data: () => ({
    search: '',
    pointer: -1,
    showItems: false
  }),

  watch: {
    value: {
      immediate: true,
      handler (value) { this.search = (value || '').toLowerCase() }
    },

    search: {
      immediate: true,
      handler () { this.$emit('autocomplete:items', this.filteredItems) }
    }
  },

  mounted () {
    if (!this.$refs.searchBox) return
    this.isMobile && this.hasFocus && this.$refs.searchBox.$refs.input.focus()
  },

  computed: {
    styles () {
      const border = {
        'border-radius': this.visibleList
          ? '20px 20px 0 0'
          : '20px'
      }

      return {
        ...border,
        background: this.hasFocus ? '' : 'none'
      }
    },

    visibleList () {
      return (this.isOpened || this.showList) && !this.loading
    },

    showEmptyState () {
      return !!this.search
        && this.search.length >= this.minToSearch
        && !this.filteredItems.length
    },

    classes () {
      return [ 'c-auto-complete-input', {
        '--is-opened': this.visibleList,
        '--is-closed': !this.visibleList && !this.showEmptyState,
        '--has-shadow': this.hasShadow
      } ]
    },

    hasSlots () {
      return !!Object.keys(this.$scopedSlots).length
    },

    hasShadow () {
      return this.showItems && !this.filteredItems.length
    },

    isOpened () {
      return this.showEmptyState || (this.showItems && this.filteredItems.length)
    },

    /**
     * This computed property is used to replace the search query
     * in specific cases, like when the user types a month number and
     * its name is highlighted instead.
     *
     * The replaceQuery property should be set in the options array of objects.
     */
    computedSearch () {
      if (this.items?.every(item => item?.replaceQuery))
        return this.items[0]?.replaceQuery

      return this.search
    },

    items () {
      if (!this.search || this.search.length < this.minToSearch)
        return []

      return setDiacritic(this.options, this.normalizeProp, this.searchProp)
    },

    filteredItems () {
      const query = normalizeString(this.search).toLowerCase()

      return this.items.filter(option => {
        const parsedQuery = option?.replaceQuery || query
        return normalizeString(option[this.normalizeProp].toLowerCase()).includes(parsedQuery)
      })
    }
  },

  methods: {
    reset () {
      this.showItems = false
      this.pointer = -1 // reset pointer
    },

    down () {
      if (this.pointer < this.filteredItems.length - 1) this.pointer += 1
    },

    up () {
      if (this.pointer > 0) this.pointer -= 1
    },

    select ({ key } = 'Enter') {
      if (key !== 'Enter' && key !== 'Click') return

      const item = this.filteredItems[this.pointer]

      this.$nextTick(this.reset)

      if (!this.hasSlots) {
        const value = item[this.searchProp]

        this.search = value
        this.onSearch(value)
      }

      this.$emit('select', item)
    },

    onSearch (value) {
      this.search = value
      this.showItems = true
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
.c-auto-complete-input {
  display: flex;
  flex-direction: column;

  position: relative;
  transition: box-shadow .3s;

  &.--has-shadow {
    border-radius: 20px;
    box-shadow: var(--base-shadow);
  }

  &.--is-opened > .search-wrapper {
    border-radius: 20px 20px 0 0;
    background: white;

    @include responsive (tablet, desktop) {
      box-shadow: var(--base-shadow);
    }

    @include responsive (xs-mobile, mobile) {
      border-radius: 20px;
    }

    & > .search {
      position: relative;

      &::after {
        content: '';

        position: absolute;
        bottom: 0;
        left: 10px;
        right: 10px;

        height: 1px;
        background-color: var(--base-border-color);
      }
    }
  }

  & > .search-wrapper {
    display: flex;
    min-height: 40px;
    position: relative;

    & .search {
      width: 100%;

      @include responsive (tablet, desktop) { & > .input { box-shadow: none; } }

      @include responsive (xs-mobile, mobile) {
        // Sorry felas, have to use the !important here
        & > .--is-focused { box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2) !important; }
      }
    }
  }
}
</style>
