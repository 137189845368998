import { formatToTimeZone } from 'date-fns-timezone'
import dayjs from 'dayjs'
import { is } from './is'

export const MONTHS = {
  '01': 'janeiro',
  '02': 'fevereiro',
  '03': 'março',
  '04': 'abril',
  '05': 'maio',
  '06': 'junho',
  '07': 'julho',
  '08': 'agosto',
  '09': 'setembro',
  '10': 'outubro',
  '11': 'novembro',
  '12': 'dezembro'
}

export const toHours = (ms) => {
  const hours = Math.floor(ms / (1000 * 60 * 60))
  const minutes = Math.floor((ms / (1000 * 60)) % 60)
  const minutsStr = `${minutes}`.padStart(2, '0')

  return `${hours}:${minutsStr}`
}

/**
 * Formats a date to the brazilian date and time notation.
 * @deprecated AVOID USING THIS HELPER FOR NEW FEATURES - Use dayjs instead
 * e.g. dayjs().format('DD/MM/YYYY') / this.$date.format('DD/MM/YYYY')
 *
 * @param {String|Number|Date} value - The date either as an unix timestamp, a Date
 * instance, or a string following the ISO-8601 standard date format.
 */
export const formatDateBR = value => {
  if (!value) return ''

  if (is(value, 'Number')) return new Date(value).toLocaleDateString('pt-BR')

  if (is(value, 'Date')) return value.toLocaleDateString('pt-BR')

  if (is(value, 'String')) {
    const [ year, month, day ] = value.replace(/T.*/, '').split(' ')[0].split('-')
    return (new Date(year, month - 1, day)).toLocaleDateString('pt-BR')
  }
}

export const formatDateUS = date => {
  const dateStr = is(date, 'Date')
    ? date.toISOString()
    : date

  return date
    ? dateStr.substr(0, 10).split('/').reverse().join('-')
    : ''
}

/**
 * Receives a date string the format DD/MM/YYYY and returns
 * the correspodent timestamp for that date, note that we
 * have to manually set the time in here in the string
 * we receive from formatDateUS to avoid problems with
 * timezone.
 *
 * @param {String} date - The date string in the format DD/MM/YYYY
 * @returns {Number} Exact timestamp representation of given date string
 */
export const getTimestamp = date => (new Date(`${formatDateUS(date)}T00:00:00`)).getTime()

/**
 * Receives a date string in brazilian format and creates and returns
 * a new date object from that. Why? Because the native Date constructor
 * sucks.
 *
 * @param {String} value
 * @returns {Date} A new object corresponding to the given date string
 */
export const newDate = value => new Date(getTimestamp(value))

/**
 * Very simple method to check if a given date is valid
 *
 * @param {String} value - The date string in brazillian format only.
 * @returns {Boolean} Whether it is valid or not.
 */
export const isDateValid = value => newDate(value).toString() !== 'Invalid Date'

/**
 * Calculates the age given a Date
 * @param {Date} birthday
 * @returns {Number} - the age
 */
export const calculateAge = birthday => {
  const ageDifMs = Date.now() - birthday.getTime()
  const ageDate = new Date(ageDifMs)

  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

/**
 * Normalizes the date, ignoring the user's timezone
 * converting the date to 'America/Sao_Paulo' timezone
 * and returning the date in 'YYYY-MM-DD' format
 *
 * @param {Date} date
 * @returns {String} - the normalized date
 */
export const normalizeDate = date => {
  const dateString = date.toISOString()
  const format = 'YYYY-MM-DD'
  const options = { timeZone: 'America/Sao_Paulo', convertTimeZone: false }

  return formatToTimeZone(dateString, format, options)
}

/**
 * Extracts month and year of a given date string and returns it
 * in a nice legible string (used mostly for visual porpuses in some
 * components)
 *
 * @param {String} date - Date as a string in ISO-8601 format
 * @returns {String}
 */
export const getDateLabel = date => {
  if (!date) return ''
  const [ year, month, day ] = (date || '').split('-')
  return `${day} de ${MONTHS[month].toLowerCase()} de ${year}`
}

export const isAfter = ({ start, end }) => {
  if (!start || !isDateValid(start)) return true
  const startDate = getTimestamp(start)

  const endDate = (end && isDateValid(end)) ? getTimestamp(end) : +new Date()
  return startDate >= endDate
}

/**
 * Generates an array of time intervals between a specified start and end time.
 *
 * @param {string} start - The start time in HH:mm format. Defaults to '08:00'.
 * @param {string} end - The end time in HH:mm format. Defaults to '17:00'.
 * @param {number} intervalMinutes - The interval between each time in minutes. Defaults to 30.
 * @returns {Array<Object>} - An array of objects with each object containing a formatted time.
 *
 * @example
 *
 * generateTimeIntervals('08:00', '12:00', 60);
 * // Returns: [{ value: '08:00' }, { value: '09:00' }, ..., { value: '12:00' }]
 */
export const generateTimeIntervals = (start = '08:00', end = '17:00', intervalMinutes = 30) => {
  const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/
  if (!timeRegex.test(start)) throw new Error('Invalid start time format. Expected HH:mm.')
  if (!timeRegex.test(end)) throw new Error('Invalid end time format. Expected HH:mm.')

  const startTime = dayjs(start, 'HH:mm')
  const endTime = dayjs(end, 'HH:mm')
  const intervals = []

  for (let currentTime = startTime; currentTime.isBefore(endTime) || currentTime.isSame(endTime); currentTime = currentTime.add(intervalMinutes, 'minute')) {
    intervals.push({ value: currentTime.format('HH:mm') })
  }

  return intervals
}
