<template>
  <div class="c-progress" :class="{ '-inline': inline }">
    <span
      :class="classes"
      :style="{ width: `${percent}%` }"
    />

    <span :class="[ 'percent', { '-opacity': percent === 0 } ]">
      {{ percent }}%
    </span>
  </div>
</template>

<script>
export default {
  name: 'CProgress',

  props: {
    /**
    * Current value of progress
    */
    value: {
      type: [ Number, String ],
      default: 0
    },

    /**
    * Indicates whether the value should be on alongside
    */
    inline: {
      type: Boolean,
      default: false
    },

    /**
    * Indicates that the progress bar will have different colors for some intervals
    */
    useAlternativeColors: {
      type: Boolean,
      default: false
    },

    /**
    * Indicates if the progress need to show the alert color for some reason
    */
    alert: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    percent () {
      if (this.value < 0) return 0
      if (this.value > 100) return 100

      return this.value
    },

    alternativeColors () {
      return {
        '-yellow': this.value > 0 && this.value < 80
      }
    },

    classes () {
      return [
        'bar',
        this.alert ? '-red' : '',
        this.useAlternativeColors ? this.alternativeColors : ''
      ]
    }
  }
}
</script>

<style lang="scss">
.c-progress {
  position: relative;
  display: flex;
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background-color: color-var(text, base-10);

  & > .bar {
    background: color-var(positive);
    transition: width .3s ease-out;
    box-shadow: 0 0 15px -2px rgba(#37CFCC, .5);
    border-radius: 10px;

    &.-yellow {
      background: color-var(alert);
      box-shadow: 0 0 15px -2px rgba(#FFC24B, .5);
    }

    &.-red {
      background: color-var(negative);
      box-shadow: 0 0 15px -2px rgba(#FF4B8C, .5);
    }
  }

  & > .percent {
    font-size: 12px;
    line-height: 29px;
    color: color-var(text, base-80);
    position: absolute;
    top: 100%;
    right: 0;
    -webkit-font-smoothing: antialiased;

    &.-opacity { color: color-var(text, base-30); }
  }

  &.-inline {
    width: calc(100% - 40px);

    & > .percent {
      top: 50%;
      left: 100%;
      transform: translate(10px, -50%);
    }
  }
}
</style>
