<template>
  <div
    class="c-info-tag"
    :style="{
      '--icon-size': icon ? iconSize + 'px' : '0px',
      '--icon-gap': icon ? iconGap + 'px' : '0px'
    }"
  >
    <c-icon
      v-if="icon"
      class="icon"
      :icon="icon"
      :size="iconSize"
    />

    <span v-tooltip.ellipsis.dark class="text">
      <slot />
    </span>
  </div>
</template>

<script>
/**
 * A simple information tag with an icon
 */
export default {
  name: 'CInfoTag',

  props: {
    /**
     * The icon to show in the left side of the tag. Optional.
     */
    icon: {
      type: String,
      default: ''
    },

    /**
     * The size of the icon.
     */
    iconSize: {
      type: [ String, Number ],
      default: '20'
    },

    /**
     * The space between the icon and the text.
     */
    iconGap: {
      type: [ String, Number ],
      default: '8'
    }
  }
}
</script>

<style lang="scss">
.c-info-tag {
  padding: 6px;
  padding-right: 14px;
  height: 25px;
  border-radius: 12.5px;
  background-color: color-var(text, base-05);
  display: inline-flex;
  align-items: center;

  & > .icon {
    @include icon-color(text, base-30);
    margin-right: var(--icon-gap);
  }

  & > .text {
    @include typo(h5, base-50);
    @include truncate(100%);
    line-height: 11px;
    width: calc(100% - var(--icon-size) - var(--icon-gap));
  }

  & > .icon + .text {
    // avoid text overgrowing and diminishing icon
    display: inline-block;
  }
}
</style>
