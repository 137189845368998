<template>
  <svg
    class="c-spinner"
    viewBox="0 0 100 100"
    :style="{
      '--size': `${size}px`
    }"
  >
    <circle
      cx="50"
      cy="50"
      r="45"
      fill="none"
      :stroke="color"
      :stroke-width="stroke"
      :stroke-dasharray="300"
    />
  </svg>
</template>

<script>
export default {
  name: 'CSpinner',

  props: {
    /**
     * The diameter of the circle.
     */
    size: {
      type: Number,
      default: 50
    },

    /**
     * The stroke width, this is relative to the size.
     */
    stroke: {
      type: Number,
      default: 3
    },

    /**
     * The color of the fill and glow.
     */
    color: {
      type: String,
      default: '#940DFF'
    }
  }
}
</script>

<style lang="scss">
.c-spinner {
  width: var(--size);
  max-width: 100px;
  animation: 1.2s linear infinite svg-animation;
  circle {
    stroke-linecap: round;
    transform-origin: 50% 50%;
    transform: translate(-50%, -50%);
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-name: circle-animation;
  }

  @keyframes circle-animation {
    0%,
    25% {
      stroke-dashoffset: 270;
      transform: rotate(0);
    }

    50%,
    75% {
      stroke-dashoffset: 60;
      transform: rotate(45deg);
    }

    100% {
      stroke-dashoffset: 270;
      transform: rotate(360deg);
    }
  }

  @keyframes svg-animation {
    0% { transform: rotateZ(0deg); }
    100% { transform: rotateZ(360deg) }
  }
}
</style>
