<template>
  <div class="admission-document-upload">
    <c-add
      v-if="add"
      thin
      text="Adicionar arquivos"
      @add="onSelectFile(value.length)"
    />

    <div class="files">
      <document-file
        v-for="(file, index) in value"
        ref="files"
        :key="index"
        :value="file"
        :extensions="extensions"
        :placeholder="file.placeholder"
        :disabled="disabled"
        @click="onPreviewOpen(index)"
        @remove="onRemoveFile(index)"
        @change="onSelectFile(index)"
        @drag="files => onDragFiles(index, files)"
      />
    </div>

    <c-preview
      :is-opened="showPreview"
      :images="images"
      :remove="false"
      :current-image="current"
      @next="onPreviewNext"
      @previous="onPreviewPrevious"
      @download="onDownload"
      @close="onPreviewClose"
      @click.native.prevent
    />

    <input
      ref="input"
      name="admission-document-upload"
      class="input"
      type="file"
      :accept="extensions.map(ext => '.' + ext)"
      @change.prevent="onAddFiles"
    >
  </div>
</template>

<script>
import DocumentFile from './DocumentFile'

export default {
  name: 'DocumentUpload',

  components: {
    DocumentFile
  },

  data: () => ({
    showPreview: false,
    active: null,
    current: 0,
  }),

  props: {
    value: {
      type: Array,
      default: () => ([])
    },

    extensions: {
      type: Array,
      default: () => ([ 'pdf', 'png', 'jpg', 'jpeg' ])
    },

    disabled: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: true
    },

    add: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    selected () {
      if (!this.value[this.active]) return {}

      return this.value[this.active]
    },

    images () {
      return this.value
        .filter(({ name, preview }) => preview && this.isImage(name))
        .map(file => ({
          ...file,
          src: file.preview,
          label: file.name
        }))
    }
  },

  methods: {
    validate () {
      if (!this.required) return true

      return this.$refs.files.every(
        ({ validate }) => validate()
      )
    },

    getExtension (filename) {
      const index = filename.lastIndexOf('.')

      return (index < 1) ? '' : filename.substr(index + 1).toLowerCase()
    },

    isImage (filename) {
      const ext = this.getExtension(filename)

      return [ 'pdf', 'jpg', 'jpeg', 'gif', 'bmp', 'png' ].includes(ext)
    },

    onRemoveFile (index) {
      this.$emit('remove', index)
    },

    onSelectFile (index) {
      this.active = index
      this.$refs['input'].click()
    },

    onDragFiles (index, files) {
      if (!files.length) return

      const file = files[0]
      this.active = index
      this.processFile(file)
    },

    onAddFiles ({ target }) {
      if (!target.files.length) return

      const file = target.files[0]
      this.processFile(file)
      this.$refs.input.value = null
    },

    onPreviewOpen (index) {
      this.active = index
      this.current = this.images
        .findIndex(({ name }) => name === this.selected.name)
      this.showPreview = true
    },

    onPreviewClose () {
      this.current = 0
      this.showPreview = false
    },

    onPreviewNext () {
      this.current += 1
    },

    onPreviewPrevious () {
      this.current -= 1
    },

    async onDownload () {
      const image = this.images[this.current]

      if (!image) return

      const url = (typeof image.preview === 'function') ?
        await image.preview() : image.preview

      const link = document.createElement('a')
      link.href = url
      link.download = image.name

      document.body.appendChild(link)

      link.dispatchEvent(
        new MouseEvent('click', { bubbles: true, cancelable: true, view: window, })
      )

      document.body.removeChild(link)
    },

    processFile (file) {
      if (!this.extensions.includes(this.getExtension(file.name)))
        return

      const defaults = {
        id: null,
        progress: 0,
        uploading: false,
        preview: null,
        data: file,
        name: file.name,
        size: file.size,
        error: ''
      }

      if (![ 'image/jpg', 'image/jpeg', 'image/png', 'application/pdf' ].includes(file.type))
        return this.$emit('add', {
          index: this.active,
          file: {
            ...this.selected,
            ...defaults
          }
        })

      const reader = new FileReader()

      reader.onloadend = () =>
        this.$emit('add', {
          index: this.active,
          file: {
            ...this.selected,
            ...defaults,
            preview: reader.result
          }
        }
        )

      reader.readAsDataURL(file)
    }
  }
}
</script>

<style lang="scss">
.admission-document-upload {
  & > .c-add { margin: 0 0 10px; }
  & > .files {
    & > .admission-document-file {
      margin-bottom: 10px;
    }
  }

  & > .input { display: none; }

  & > .c-preview {
    & > .content {
      & > .label {
        width: 75%;
        left: 50%;
        transform: translate(-50%, 30px);
        text-align: center;
      }
    }
  }
}
</style>
