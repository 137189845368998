import * as services from '@services/dependent'
import * as types from '@types'

export default {
  state: {
    dependents: [],
    options: {
      dependent: {
        dependentRelations: []
      }
    },
    customFields: {}
  },

  getters: {
    hasDependent: (state) => {
      return state.dependents.some(
        ({ id }) => typeof id === 'string'
      )
    }
  },

  mutations: {
    [types.DEPENDENT_RELATIONS_OPTIONS]: (state, options) => {
      state.options = { ...options }
    },

    [types.EMPLOYEE_SET_DEPENDENTS]: (state, dependents) => {
      state.dependents = [ ...dependents ]
    },

    [types.EMPLOYEE_ADD_DEPENDENT]: (state) => {
      state.dependents = [
        ...state.dependents,
        { id: new Date().getUTCMilliseconds(), files: [] }
      ]
    },

    [types.EMPLOYEE_SET_DEPENDENT]: (state, { data, id }) => {
      const index = state.dependent.findIndex(dependent => dependent.id === id)

      if (index >= 0)
        state.dependent.splice(index, 1, { files: [], ...data })
      else
        state.dependent.push({ ...data })
    },

    [types.EMPLOYEE_DELETE_DEPENDENT]: (state, index) => {
      state.dependents.splice(index, 1)
    },

    [types.EMPLOYEE_DEPENDENT_CUSTOM_FIELDS]: (state, data) => {
      state.customFields = { ...data }

      state.dependents = state.dependents.map(dependent => ({
        ...dependent,
        ...(state.customFields.dependent.values[dependent.id] || {})
      }))
    }
  },

  actions: {
    [types.DEPENDENT_RELATIONS_OPTIONS]: async ({ state, commit }) => {
      if (state.options.dependent.dependentRelations.length)
        return [ false, state.options ]

      const [ err, options ] = await services.getOptions()

      if (!err) commit(types.DEPENDENT_RELATIONS_OPTIONS, options)

      return [ err, options ]
    },

    [types.EMPLOYEE_DEPENDENTS]: async ({ state, getters, commit }) => {
      if (state.dependents.length) return [ false, state.dependents ]

      const [ err, dependents ] = await services.getDependents(getters)

      if (!dependents.length) commit(types.EMPLOYEE_ADD_DEPENDENT)
      else if (!err) commit(types.EMPLOYEE_SET_DEPENDENTS, dependents)

      return [ err, dependents ]
    },

    [types.EMPLOYEE_DEPENDENT_CUSTOM_FIELDS]: async ({ commit, getters }) => {
      const [ err, data ] = await services.getCustomFields(getters)

      if (!err) commit(types.EMPLOYEE_DEPENDENT_CUSTOM_FIELDS, data)

      return [ err, data ]
    },

    [types.EMPLOYEE_SET_DEPENDENT]: async ({ getters }, variables) => {
      const [ err, dependent, endpoint ] = await services.setDependent(getters, variables)

      return [ err, dependent, endpoint ]
    },

    [types.EMPLOYEE_SET_DEPENDENTS]: async ({ state, getters, commit }, dependents) => {
      if (!dependents.length) return [ false, state.dependents ]

      const submited = []
      const errors = []

      await Promise.all((dependents || []).map(dep => services.setDependent(getters, dep)
        .then(([ err, dependent ]) => err ? errors.push(err) : submited.push(dependent))
        .catch(err => errors.push(err))))

      if (!errors.length) commit(types.EMPLOYEE_SET_DEPENDENTS, submited)

      return [ errors.length, submited ]
    },

    [types.EMPLOYEE_DELETE_DEPENDENT]: async ({ state, getters, commit }, dependent) => {
      const index = state.dependents.indexOf(dependent)
      const [ err ] = await services.deleteDependent(getters, dependent.id)

      if (!err) commit(types.EMPLOYEE_DELETE_DEPENDENT, index)

      return [ err, null ]
    },
  }
}
