import { formatDate, formatDateUS } from '@modules/dateHelpers'
import { PERSONAL_AREA } from '@content/consts/Personal'
import { getAdditionalFields, getEmployeeInfoSchema } from '@services/systemField'
import * as get from '@graphql/middleware/queries'
import * as set from '@graphql/mutations'
import middleware from '@graphql/middleware'

const sortNationalities = nationalities =>
  (nationalities || []).sort((a, b) => {
    const first = a.name.toLowerCase()
    const second = b.name.toLowerCase()

    return (first < second) ? -1 : (first > second) ? 1 : 0
  })

const mappers = {
  foreign: data => ({
    ...data,
    visa: data.visa?.id,
    arrivalDate: formatDate(data.arrivalDate),
    naturalizationDate: formatDate(data.naturalizationDate),
    marriedToBrazilian: !!data.marriedToBrazilian,
    hasBrazilianOffspring: !!data.hasBraziliianOffspring,
    country: data.country?.id,
    livesInBrazil: !!data.livesInBrazil,
    residenceTime: data.residenceTime?.id,
    entryCondition: data.entryCondition?.id,
    zipCodeForeign: data.zipCodeForeign,
    addressDescription: data.addressDescription?.id,
    foreignAddress: data.foreignAddress,
    number: data.number,
    complement: data.complement,
    district: data.district,
    foreignCity: data.foreignCity
  })
}

export async function getOptions () {
  const [ err, { employeeOptions: data = {} } ] = await middleware(get.GetPersonalOptions)

  const options = {
    personal: {
      visaTypes: data.visaTypes,
      nationalities: sortNationalities(data.nationalities),
      ethnicities: data.ethnicities,
      genders: data.genders,
      genderIdentities: data.genderIdentities,
      civilStatus: data.maritalStatuses,
      residenceTimes: data.residenceTimes,
      entryConditions: data.entryConditions,
      addressDescription: data.addressDescription
    },
    handicap: {
      disabilities: data.disabilities
    },
    realEstate: {}
  }

  return [ err, options ]
}

export async function getPersonal (getters) {
  const employeeId = getters.getEmployeeUuid

  const [ err, employee ] = await middleware(get.GetPersonalInfo, { id: employeeId }) || []

  const personalInfo = {
    fullName: `${employee?.employee?.name} ${employee?.employee?.lastName}`,
    ...employee?.personalInfo || {}
  }
  const nationalities = employee?.nationalities || []

  const personal = {
    ...personalInfo,
    state: personalInfo.state?.id,
    city: personalInfo.city?.id,
    ethnicity: personalInfo.ethnicity?.id,
    civilStatus: personalInfo.civilStatus?.id,
    nationalities: nationalities.map(({ nationality }) => nationality?.id),
    birthday: formatDate(personalInfo.birthday),
    gender: personalInfo.gender?.id ? +personalInfo.gender.id : null,
    genderIdentities: personalInfo.genderIdentities?.id ? +personalInfo.genderIdentities.id : null
  }

  const foreign = mappers.foreign(employee.foreignInfo || {})

  const handicap = {
    ...(employee.handicapInfo || {}),
    hasDeficiency: !!employee?.handicapInfo,
    disability: employee?.handicapInfo?.disabilityId,
  }

  const realEstate = {
    ...(employee?.realEstate || {}),
    hasRealEstate: !!employee?.realEstate,
  }

  return [ err, { personal, foreign, handicap, realEstate } ]
}

export async function setPersonal (getters, variables) {
  const employeeId = getters.getEmployeeUuid
  const isCreating = !variables.id
  const mutation = isCreating ? 'CreatePersonalInfo' : 'UpdatePersonalInfo'

  const [ err, data ] = await middleware(set[mutation], {
    employeeInput: {
      employee_id: employeeId,
      name: (variables.fullName || '').split(' ')[0],
      last_name: (variables.fullName || '').split(' ').slice(1).join(' '),
    },
    personalInfoInput: {
      ...(isCreating ? {} : { id: variables.id }),
      employee_id: employeeId,
      mother_name: variables.motherName,
      father_name: variables.fatherName,
      birth_date: formatDateUS(variables.birthday),
      birth_state_id: +variables.state || null,
      birth_city_id: +variables.city || null,
      ethnicity_id: +variables.ethnicity || null,
      marital_status_id: +variables.civilStatus || null,
      gender_id: +variables.gender || null,
      gender_identity_id: +variables.genderIdentities || null,
      social_name: variables.socialName,
      additional: {
        sectionId: PERSONAL_AREA.PERSONAL,
        fields: getAdditionalFields(variables.customFields, variables)
      }
    },
    nationalitiesInput: {
      employee_id: employeeId,
      nationalities: variables.nationalities
    }
  })

  const personal = isCreating ? data.personal : variables

  return [ err, personal ]
}

export async function setForeign (getters, variables) {
  const employeeId = getters.getEmployeeUuid
  const [ err, data ] = await middleware(set.UpdateForeignInfo, {
    input: {
      id: variables.id,
      employee_id: employeeId,
      visa_id: +variables.visa || null,
      country_id: +variables.country || null,
      arrival_date: formatDateUS(variables.arrivalDate),
      naturalization_date: formatDateUS(variables.naturalizationDate),
      married_to_brazilian: variables.marriedToBrazilian,
      has_brazilian_offspring: variables.hasBrazilianOffspring,
      additional: {
        sectionId: PERSONAL_AREA.FOREIGN,
        fields: getAdditionalFields(variables.customFields, variables)
      },
      lives_in_brazil: variables.livesInBrazil,
      residence_time_id: variables.residenceTime || null,
      entry_condition_id: variables.entryCondition || null,
      zip_code_foreign: variables.zipCodeForeign,
      address_description_id: variables.addressDescription || null,
      foreign_address: variables.foreignAddress,
      number: variables.number,
      complement: variables.complement,
      district: variables.district,
      city: variables.foreignCity
    }
  })

  return [ err, (data || {}).status ]
}

export async function setHandicap (getters, variables) {
  const defaults = {
    id: '',
    hasDeficiency: false,
    disability: {},
    observations: ''
  }

  if (!variables.id && !variables.hasDeficiency)
    return [ false, defaults ]

  const employeeId = getters.getEmployeeUuid
  const mutation = !variables.hasDeficiency ? 'DeleteHandicap' :
    variables.id ? 'UpdateHandicap' : 'CreateHandicap'

  const [ err, data ] = await middleware(set[mutation], {
    handicapInput: {
      employee_id: employeeId,
      ...(variables.id ? { id: variables.id } : {}),
      ...(mutation !== 'DeleteHandicap' ? {
        observations: variables.observations,
        disability_id: +variables.disability || null,
        additional: {
          sectionId: PERSONAL_AREA.HANDICAP,
          fields: getAdditionalFields(variables.customFields, variables)
        }
      } : {})
    }
  })

  return [ err,
    mutation === 'DeleteHandicap' ? defaults :
      { ...variables, ...data.handicapInfo }
  ]
}

export async function setRealEstate (getters, variables) {
  const defaults = {
    id: '',
    hasRealEstate: false,
    acquiredWithFGTS: undefined
  }

  if (!variables.id && !variables.hasRealEstate)
    return [ false, defaults ]

  const employeeId = getters.getEmployeeUuid
  const mutation = !variables.hasRealEstate ? 'DeleteRealEstate' :
    variables.id ? 'UpdateRealEstate' : 'CreateRealEstate'

  const [ err, data ] = await middleware(set[mutation], {
    input: {
      employeeId,
      ...(variables.id ? { id: variables.id } : {}),
      ...(mutation !== 'DeleteRealEstate' ? {
        acquiredWithFgts: !!variables.acquiredWithFGTS,
        additional: {
          sectionId: PERSONAL_AREA.REAL_ESTATE,
          fields: getAdditionalFields(variables.customFields, variables)
        }
      } : {})
    }
  })

  return [ err,
    mutation === 'DeleteRealEstate' ? defaults :
      { ...variables, ...data.realEstate }
  ]
}

export const mapSection = data => ({
  schema: data?.schema || {},
  values: data?.multipleValues || {}
})

export const mapCustomFields = data => ({
  personal: mapSection(data.find(({ id }) => id === PERSONAL_AREA.PERSONAL)),
  foreign: mapSection(data.find(({ id }) => id === PERSONAL_AREA.FOREIGN)),
  bankAccounts: mapSection(data.find(({ id }) => id === PERSONAL_AREA.BANK_ACCOUNTS)),
  educations: mapSection(data.find(({ id }) => id === PERSONAL_AREA.EDUCATIONS)),
  handicap: mapSection(data.find(({ id }) => id === PERSONAL_AREA.HANDICAP)),
  realEstate: mapSection(data.find(({ id }) => id === PERSONAL_AREA.REAL_ESTATE)),
})

export const getCustomFields = async (getters) => {
  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await getEmployeeInfoSchema({
    employeeId,
    area: PERSONAL_AREA.LABEL
  })

  const customFields = mapCustomFields(data)

  return [ err, customFields ]
}
