<template>
  <apex-charts
    ref="apex"
    class="c-graph-radar"
    type="radar"
    :width="width"
    :height="height"
    :options="options"
    :series="series"
  />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { getScrollParent } from '@convenia/helpers'
import CommonOptions from './CommonOptions'

export default {
  name: 'CGraphRadar',

  mixins: [ CommonOptions ],

  components: {
    ApexCharts: VueApexCharts
  },

  props: {
    width: {
      type: Number,
      default: undefined
    },

    height: {
      type: Number,
      default: undefined
    },

    value: {
      type: Array,
      default: () => ([])
    },

    categories: {
      type: Array,
      default: () => ([])
    },

    tooltip: {
      type: Function,
      default: ({ name, data, dataPointIndex }) => `${name}: ${data[dataPointIndex]}`
    },

    xOptions: {
      type: Object,
      default: () => ({
        title: undefined,
        min: undefined,
        max: undefined,
        tickAmount: undefined,
        formatter: undefined,
        trimLabels: undefined,
      })
    },

    yOptions: {
      type: Object,
      default: () => ({
        title: undefined,
        min: undefined,
        max: undefined,
        width: undefined,
        tickAmount: undefined,
        formatter: undefined,
        trimLabels: undefined,
      })
    },

    colors: {
      type: Array,
      default: () => ([ '#4BA9FF' ])
    },
  },

  watch: {
    value (value) {
      this.series = value
    },
  },

  computed: {
    options () {
      return {
        chart: {
          animations: { enabled: true },
          toolbar: { show: false },
          events: {
            mounted: this.onUpdate,
            updated: this.onUpdate
          }
        },
        fill: { opacity: 0, },
        states: {
          normal: { filter: { type: 'none' } },
          hover: { filter: this.hoverFilter },
          active: { filter: { type: 'none' } },
        },
        grid: { borderColor: 'rgba(18,30,72,0.1)' },
        colors: this.colors,
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          showForSingleSeries: true,
          fontSize: '10px',
          fontWeight: 400,
          fontFamily: 'Ubuntu',
          markers: {
            width: 8,
            height: 8,
            radius: 8,
          },
          itemMargin: {
            horizontal: 0,
            vertical: 0
          },
          onItemClick: {
            toggleDataSeries: false
          }
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radar: {
            polygons: {
              strokeWidth: 1,
            },
          }
        },
        stroke: {
          width: 2,
        },
        markers: {
          colors: 'white',
          strokeColors: this.colors,
          strokeWidth: 2,
          radius: 4,
        },
        tooltip: {
          custom: ({ seriesIndex, dataPointIndex, w }) => {
            const { data, name } = w.globals.initialSeries[seriesIndex]
            const category = this.categories[dataPointIndex]

            const content = this.tooltip({ data, name, category, seriesIndex, dataPointIndex, w })
            const scrollParent = getScrollParent(this.$el) || {}
            const scrollTop = scrollParent.scrollTop || 0

            return `<div class="c-tooltip">
            <div class="c-popover popover -dark" style="transform: translate(-50%, calc(-120% - ${scrollTop}px)); --left: calc(50% - 5px);">
              <div class="card" style="--tooltip-font-size:11px; --tooltip-max-width:200px; --tooltip-horizontal-padding:10px; text-align: left">
                ${content}
              </div>
            </div>
          </div>`
          }
        },
        xaxis: {
          categories: this.categories,
          min: this.xOptions.min,
          max: this.xOptions.max,
          tickPlacement: 'on',
          tickAmount: this.xOptions.tickAmount,
          axisBorder: { show: false },
          crosshairs: { show: false },
          labels: {
            rotate: 0,
            trim: this.xOptions.trimLabels,
            hideOverlappingLabels: false,
            formatter: this.xOptions.formatter || (value => `${value}`),
            style: {
              fontSize: '11px',
              fontWeight: 500,
              fontFamily: 'Nunito Sans',
              colors: 'rgba(55,61,63,0.5)'
            }
          }
        },
        yaxis: {
          min: this.yOptions.min,
          max: this.yOptions.max,
          tickAmount: this.yOptions.tickAmount,
          labels: {
            minWidth: this.yOptions.width || 70,
            maxWidth: this.yOptions.width || 70,
            formatter: this.yOptions.formatter || (value => `${value}`),
            style: {
              fontSize: '10px',
              fontWeight: 800,
              fontFamily: 'Nunito Sans',
              colors: 'rgba(55,61,63,0.8)'
            }
          }
        }
      }
    },
  },

  data: (vm) => ({
    series: vm.value,
  }),

  methods: {
    onUpdate (event) {
      const ctx = event.el.querySelector('svg')
      const texts = [ ...ctx.querySelectorAll('.apexcharts-yaxis text') ]

      // Creates a rounded rect background for the texts
      texts.forEach(text => {
        const { x, y, width, height } = text.getBBox()
        const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        const parent = text.parentNode
        const paddingLeft = 7
        const paddingTop = 2

        rect.setAttribute('x', x - paddingLeft)
        rect.setAttribute('y', y - paddingTop)
        rect.setAttribute('rx', 10)
        rect.setAttribute('width', width + paddingLeft * 2)
        rect.setAttribute('height', height + paddingTop * 2)
        rect.setAttribute('fill', '#e7e7ec')
        rect.setAttribute('fill-opacity', 0.5)

        parent.insertBefore(rect, text)
      })
    }
  }
}
</script>

<style lang="scss">
.c-graph-radar {
  .apexcharts-yaxis { pointer-events: none; }
  .apexcharts-datalabel { transform: scale(1.05); }

  .apexcharts-legend {
    display: inline-flex;
    gap: 5px 30px;
    padding: 0;
    .apexcharts-legend-marker { margin-right: 5px; }
    .apexcharts-legend-text { text-transform: uppercase; }
    .apexcharts-legend-series { display: inline-flex !important; }
  }

  .c-tooltip > .popover::before {
    top: initial;
    bottom: -5px;
  }
}
</style>
