<template>
  <c-modal
    :class="[
      'c-form-builder-modal',
      {
        '-alternative-header': alternativeHeader,
        '-read-only': readOnly
      }
    ]"
    is-opened
    lock-el=".c-form-builder .c-shadowed .wrapper"
    :title="title"
    :actions-color="actionsColor"
    :large="large"
    :is-loading="isModalLoading"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot slot="header" name="header">
      <div v-if="alternativeHeader" class="title">
        <c-flag v-bind="{ [headerTheme]: true }" :icon="headerIcon" class="flag" />

        <span class="text">{{ title }}</span>
      </div>
    </slot>

    <c-button
      v-if="readOnly && showEditButton"
      slot="actions"
      ref="more"
      flat
      icon="pen-edit-17"
      icon-size="20"
      :style="{ marginRight: '10px' }"
      @click="$emit('edit')"
    />

    <slot name="extra" />

    <c-form-builder
      v-if="!readOnly"
      ref="form"
      :class="[ 'form', { '-short': !large, '-small-action-spacing': smallActionSpacing } ]"
      shadow-color="#FEFEFE"
      :label-left="!isMobile && labelLeft"
      :schema="schema"
      :value="value"
      :fields-options="fieldsOptions"
      :emit-tracked-value="emitTrackedValue"
      :disabled="isDisabled"
      :required-fields="requiredFields"
      :no-bottom-shadow="noBottomShadow"
      :submit-format="submitFormat"
      :revalidate-on-input="revalidateOnInput"
      :allow-null="allowNull"
      :emit-file-remove="emitFileRemove"
      :errors="errors"
      :show-errors-when-changed="showErrorsWhenChanged"
      v-on="$listeners"
    >
      <template
        v-for="slot in scopedSlots"
        :slot="slot"
        slot-scope="fieldProps"
      >
        <slot :name="slot" v-bind="fieldProps" />
      </template>

      <template v-for="(fieldSchema, fieldName) in schema">
        <slot :slot="`${fieldName}-divider`" :name="`${fieldName}-divider`" />
      </template>

      <slot />

      <slot slot="footer" name="footer" />

      <slot slot="actions" name="actions">
        <c-button
          v-if="!isMobile && showCancelButton"
          class="action"
          flat
          type="button"
          ref-cy="form-cancel"
          :disabled="isLoading || isDisabled"
          :icon="cancelButtonIcon"
          :icon-size="20"
          v-bind="{ [actionsColor]: true }"
          @click.stop.prevent="$emit('close')"
        >
          {{ cancelButtonText }}
        </c-button>

        <c-button
          type="submit"
          class="action"
          primary
          :size="isMobile ? 60 : 40"
          :full-width="isMobile"
          :disabled="isDisabled || disableSubmitButton"
          :loading="isLoading"
          :icon="submitButtonIcon"
          :icon-size="20"
          v-bind="{ [actionsColor]: true }"
        >
          {{ submitButtonText }}
        </c-button>
      </slot>
    </c-form-builder>

    <c-shadowed
      v-else-if="readOnly"
      class="read-only"
      shadow-color="#FEFEFE"
      :has-bottom-shadow="false"
    >
      <slot name="preview:before-fields" />

      <c-info :fields="previewFields" />

      <slot name="preview:after-fields" />

      <c-title v-if="hasUpload" dark-text>
        Anexos
      </c-title>

      <c-upload
        v-if="hasUpload"
        :hideAddBox="readOnly"
        :removable="!readOnly"
        character="paper"
        :disabled="isLoading"
        :multiple="true"
        :class="[ 'field-file' ]"
        :value="files"
        @file:add="onAddFile"
        @file:remove="onRemoveFile"
      />
    </c-shadowed>
  </c-modal>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CFormBuilderModal',

  mixins: [ MediaQuery ],

  props: {
    name: {
      type: String,
      required: true
    },

    title: {
      type: String,
      default: '',
    },

    value: {
      type: Object,
      default: () => ({})
    },

    schema: {
      type: Object,
      required: true
    },

    fieldsOptions: {
      type: Object,
      default: () => ({})
    },

    cancelButtonText: {
      type: String,
      default: 'Cancelar'
    },

    submitButtonText: {
      type: String,
      default: 'Salvar'
    },

    showCancelButton: {
      type: Boolean,
      default: true
    },

    showEditButton: {
      type: Boolean,
      default: true
    },

    submitButtonIcon: {
      type: String,
      default: '',
    },

    cancelButtonIcon: {
      type: String,
      default: '',
    },

    labelLeft: Boolean,

    isLoading: Boolean,

    isModalLoading: Boolean,

    isDisabled: Boolean,

    headerIcon: {
      type: String,
      default: '',
    },

    alternativeHeader: Boolean,

    noBottomShadow: Boolean,

    allowNull: Boolean,

    emitTrackedValue: {
      type: Boolean,
      default: true
    },

    actionsColor: {
      type: String,
      validator: color => [ 'primary', 'success', 'error' ].includes(color),
      default: () => 'primary'
    },

    headerTheme: {
      type: String,
      default: 'primary'
    },

    large: {
      type: Boolean,
      default: true
    },

    smallActionSpacing: {
      type: Boolean,
      default: false
    },

    requiredFields: {
      type: Array,
      default: () => ([])
    },

    readOnly: {
      type: Boolean,
      default: false
    },

    files: {
      type: Array,
      default: () => ([])
    },

    readonlyOptions: {
      type: Array,
      default: () => ([])
    },

    submitFormat: {
      type: Function,
      default: null,
    },

    emitFileRemove: {
      type: Boolean,
      default: false
    },

    revalidateOnInput: {
      type: Boolean,
    },

    disableSubmitButton: {
      type: Boolean
    },

    /**
    * List of fields errors
    */
    errors: {
      type: Object,
      default: () => ({})
    },

    /**
     * Show errors even if has not submitted
     */
    showErrorsWhenChanged: {
      type: Boolean,
    },
  },

  data () {
    return {
      showOptions: false
    }
  },

  computed: {
    scopedSlots () {
      return Object.keys(this.schema || {}).filter(key => this.schema[key].type === 'slot'
      ).map(name => `${name}-slot`)
    },

    previewFields () {
      const { value, schema } = this

      return Object.entries(schema || {}).reduce((acc, [ fieldName, fieldSchema ]) => ({
        ...acc,
        [fieldName]: {
          ...fieldSchema,
          value: value?.[fieldName] || fieldSchema.value
        }
      }), {})
    },

    hasUpload () {
      return Object.entries(this.schema || {})
        .some(([ , field ]) => field.type === 'file' && this.files.length >= 1)
    }
  },

  methods: {
    forceInput (fieldName, value) {
      this.$refs['form'].forceInput(fieldName, value)
    },

    resetValidations () {
      this.$refs['form'].resetValidations()
    },

    onAddFile (files = []) {
      this.$emit('file:add', files)
    },

    onRemoveFile (file) {
      this.$emit('file:remove', file)
    },
  }
}
</script>

<style lang="scss">
.c-form-builder-modal {
  &.c-modal.-alternative-header > .wrapper > .modal > .header > .title {
    display: flex;
    align-items: center;

    & > .text {
      @include typo (h2);
      margin-left: 15px;
    }
  }

  &.c-modal > .wrapper {
    overflow: hidden;

    & > .modal {
      height: 100% ;
      border-radius: 0;
      overflow: initial;

      & > .header {
        padding: 10px 10px 20px 20px;

        & > .title { margin-top: 10px; }
      }

      & > .content {
        display: flex;
        flex: 1;
        min-height: 0;
        padding-top: 0;
        height: 100%;

        & > .form {
          width: 100%;

          & > .c-shadowed { flex: 1; }

          & > .c-shadowed > .wrapper > .form-fields {
            padding-bottom: 40px;
          }

          & > .actions {
            margin-top: 0;
            justify-content: center;
            display: block;
            height: 60px;

            & > .action { max-width: 100%; }
          }
        }
      }
    }

    @include responsive (xs-mobile, mobile) {
      & > .modal > .content { padding: 0; }
    }

    @include responsive (tablet, desktop) {
      overflow: auto;

      & > .modal {
        height: auto;
        min-height: 490px;
        border-radius: 5px;
      }

      & > .modal > .header {
        padding: 20px 20px 0 20px;

        & > .title { margin-top: 0; }
      }

      & > .modal > .content { padding: { bottom: 50px; left: 20px; right: 20px; } }

      & > .modal > .content > .form {
        min-height: 305px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > .c-shadowed,
        & > .c-shadowed > .wrapper {
          overflow: visible;
        }

        & > .actions {
          display: flex;
          height: auto;
        }

        & > .actions > .action {
          // CButton has imposed inline styles
          // I couldn't avoid this !important.
          // May the css gods forgive me
          max-width: 180px !important;
        }

        &.-small-action-spacing > .c-shadowed > .wrapper > .form-fields {
          padding-bottom: 20px;
        }

        &.-short {
          min-height: 120px;
          padding: 0 20px;

          & > .c-shadowed > .wrapper > .form-fields > .field.-label-left {
            grid-template-columns: 82px 360px
          }
        }
      }
    }
  }

  &.-read-only > .wrapper > .modal > .content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 100%;

    & > .read-only { flex: 1; }

    & > .read-only > .wrapper {
      @include responsive (tablet, desktop) {
        margin-top: 30px;
      }

      @include responsive (xs-mobile, mobile) {
        padding: 0 20px;
      }

      & > .c-title { margin: 20px 0 10px; }
    }
  }
}
</style>
