<template>
  <message class="contracts" v-bind="$attrs">
    <div class="list">
      <div
        v-for="contract in contracts"
        class="contract"
        :key="contract.id"
      >
        <span class="name">
          {{ contract.name }}
        </span>

        <button
          v-if="!isMobile"
          class="preview"
          type="button"
          @click="onDownloadContract(contract)"
        >
          <c-icon icon="eye" />
          Visualizar documento
        </button>

        <c-button
          v-else
          flat
          icon="eye"
          :size="30"
          :icon-size="17"
          @click="onDownloadContract(contract)"
        />
      </div>
    </div>

    <c-button
      v-if="contracts.length"
      flat
      icon="eye"
      icon-size="14"
      class="download-all"
      @click="onDownloadAll"
    >
      Baixar todos
    </c-button>
  </message>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { MediaQuery } from '@convenia/mixins'
import * as types from '@types'
import Message from './Message'

export default {
  name: 'ContractsView',

  mixins: [ MediaQuery ],

  components: {
    Message
  },

  mounted () {
    this.init()
  },

  computed: {
    ...mapState({
      token: state => state.auth.token,
      contracts: state => state.contract.contracts
    })
  },

  methods: {
    ...mapActions({
      getAdmission: types.ADMISSION_INFO,
      getContracts: types.EMPLOYEE_GET_CONTRACTS,
      getContractsDownloadURL: types.EMPLOYEE_GET_CONTRACTS_DOWNLOAD_URL
    }),

    ...mapMutations({
      logout: types.AUTH_LOGOUT
    }),

    async init () {
      if (!this.token) return this.$router.push({ name: 'done' })

      await this.getContracts()

      document.cookie = `token=${this.token}; domain=${import.meta.env.VITE_COOKIE_DOMAIN}; path=/`

      this.logout()
    },

    onDownloadContract (contract) {
      window.open(contract.file)
    },

    async onDownloadAll () {
      const url = await this.getContractsDownloadURL()

      window.open(url)
    }
  }
}
</script>

<style lang="scss">
.contracts {
  & > .list {
    margin-top: 80px;

    & > .contract {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: transparent;
      border-bottom: 1px solid rgba($base-text-color, .1);
      transition: background .3s;
      padding: 0 15px;

      &:last-child { border-bottom: 0; }

      & > .name {
        padding: 26px 0;
        margin-left: 15px;
        display: list-item;
        list-style: disc;
        @include typo(body-1);
      }

      & > .preview {
        display: flex;
        align-items: center;
        padding: 15px 0;
        border: 0;
        background: transparent;
        cursor: pointer;
        opacity: 0;
        transition: opacity .3s;
        @include typo(h5, base-50);
        & > .c-icon {
          margin-right: 10px;
          fill: rgba($base-text-color, .5);
        }
      }
    }
  }

  & > .download-all {
    width: 160px;
    margin: 40px auto;
  }

  @include responsive (tablet, desktop) {
    & > .list {
      & > .contract {
        &:hover {
          background: rgba($base-text-color, .02);
          & > .preview {
            opacity: 1;
          }
        }
      }
    }
  }

  @include responsive (xs-mobile, mobile) {
    & > .list {
      margin-top: 20px;
      padding: 0 20px;
      text-align: left;
    }
    & > .download-all {
      margin-top: 20px;
    }
  }
}
</style>
