export const debounce = function (func, wait, immediate) {
  let timeout
  let result

  return function debounced (...args) {
    const later = () => {
      timeout = null
      if (!immediate) result = func.apply(this, args)
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) result = func.apply(this, args)

    return result
  }
}
