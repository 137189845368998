// Receive an file object with name and url and generates
// a dynamic link for that file which initiates a download upon calling the function
export const fileOpenMethod = ({ name, url }) => {
  if (!url) return false

  const link = document.createElement('a')

  link.href = url
  link.download = name
  link.target = '_blank'

  document.body.appendChild(link)

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  )

  document.body.removeChild(link)
  return true
}
