<template>
  <c-modal
    no-header
    class="feedback-modal"
    :class="{ '-jumbo': jumbo }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <c-character
      v-if="character"
      :size="isMobile ? 130 : 150"
      class="character"
      :type="character"
    />

    <c-title class="title" center v-bind="titleBind">
      <slot name="title">{{ title }}</slot>
    </c-title>

    <slot>
      <p v-if="message" class="message">
        {{ message }}
      </p>
    </slot>

    <div class="actions">
      <slot name="actions" />

      <c-button
        v-if="!noCancelButton"
        flat
        v-bind="buttonBind"
        :jumbo="jumbo"
        :disabled="isLoading"
        @click="$emit('cancel')"
      >
        {{ cancelButtonText }}
      </c-button>

      <c-button
        v-if="!noSubmitButton"
        v-bind="buttonBind"
        :jumbo="jumbo"
        :loading="isLoading"
        @click="$emit('submit')"
      >
        {{ submitButtonText }}
      </c-button>
    </div>
  </c-modal>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

const THEME_MAP = {
  'primary': 'primary',
  'success': 'green',
  'error': 'red'
}

export default {
  name: 'CFeedbackModal',

  mixins: [ MediaQuery ],

  props: {
    title: {
      type: String,
      default: '',
    },

    message: {
      type: String,
      default: '',
    },

    character: {
      type: String,
      default: '',
    },

    theme: {
      type: String,
      default: 'primary',
      validator: th => Object.keys(THEME_MAP).includes(th)
    },

    jumbo: Boolean,

    cancelButtonText: {
      type: String,
      default: 'Não'
    },

    submitButtonText: {
      type: String,
      default: 'Sim'
    },

    noCancelButton: Boolean,

    noSubmitButton: Boolean,

    isLoading: Boolean
  },

  computed: {
    titleBind () {
      return { [THEME_MAP[this.theme]]: true }
    },

    buttonBind () {
      return { [this.theme]: true }
    }
  }
}
</script>

<style lang="scss">
.c-modal.feedback-modal > .wrapper {
  @include responsive (tablet, desktop) {
    justify-content: center;
  }
}

.feedback-modal > .wrapper > .modal {
  @include responsive (xs-mobile, mobile) {
    height: 100vh;
    justify-content: center;
  }

  position: relative;
  margin: 0;
  top: 0;

  & > .header {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 10;
  }

  & > .content {
    padding: 25px 20px 50px;

    & > .character { margin: 15px auto 30px; }

    & > .title {
      display: flex;
      text-align: center;

      & > .text {
        @include typo(h5);

        margin: 0 auto;
      }
    }

    & > .message {
      font-size: 14px;
      font-weight: 500;
      font-family: var(--base-font-family);
      color: color-var(text, base-80);
      letter-spacing: -0.14px;

      margin: 20px auto 0;
      max-width: 480px;
      text-align: center;
    }

    & > .actions {
      display: flex;
      padding: 40px 60px 0;
      justify-content: center;

      & > .c-button {
        flex: 1 1;
        max-width: 180px;

        // Perhaps add that to CButton
        & > .text { white-space: nowrap; }
        &:not(:last-child) { margin-right: 20px; }
      }
    }
  }
}

.feedback-modal.-jumbo > .wrapper > .modal {
  max-width: 740px;

  & > .content {
    padding-bottom: 70px;
  }

  & > .content > .character {
    margin-top: 35px;
  }

  & > .content > .title > .text {
    font-size: 20px;
    font-weight: bold;
  }

  & > .content > .message {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.17px;
    line-height: 22px;
  }

  & > .content > .actions {
    padding-top: 30px;
  }
}
</style>
