<template>
  <div class="c-multi-input-item-list">
    <c-tag
      v-for="(item, index) in items"
      :key="item.id"
      class="item"
      :item="item"
      :display-by="displayBy"
      @remove="$emit('remove', { index, event: $event })"
    />
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },

    displayBy: {
      type: String,
      default: 'value'
    }
  }
}
</script>

<style lang="scss">
.c-multi-input-item-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -5px 10px;

  & > .item {
    margin: 10px 5px 0;
    max-width: calc(100% - 10px);

    & > .text { user-select: none; margin-right: 30px; }
  }
}
</style>
