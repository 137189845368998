<template>
  <c-input-abstract
    v-bind="$attrs"
    class="c-table-input"
    v-on="$listeners"
  />
</template>

<script>
import autosizeInput from 'autosize-input'

export default {
  name: 'CTableInput',

  props: {
    /**
     * Force the table input to have a fixed size defined via css
     */
    noAutoSize: Boolean,

    /**
     * Do not force auto focus when the component is mounted
     */
    noAutoFocus: Boolean
  },

  methods: {
    emitValue (ev) {
      if (ev.key && ev.key.toLowerCase() !== 'enter') return
      this.toggleListeners(false)

      /**
       * This is basically a blur event, we had to create
       * a 'lost-focus' so it would't conflict with the 'blur'
       * event of the span element, long story. Just use this when
       * you need a input component on a table row.
       * @event lost-focus
       * @type {string}
       */
      const { value } = ev.target || {}
      this.$emit('lost-focus', value)
      this.$nextTick(this.toggleListeners)
    },

    toggleListeners (add = true) {
      const method = `${add ? 'add' : 'remove'}EventListener`

      this.$el[method]('blur', this.emitValue)
      this.$el[method]('keyup', this.emitValue)
      this.$el[method]('focusout', this.emitValue)
    },

    /**
     * This method programmatically emits an input event in order
     * to fix wrong initial width on loading
     */
    forceInputEvent () {
      setTimeout(() => {
        const event = new Event('input', { bubbles: true })
        this.$el.dispatchEvent(event)
      }, 100)
    }
  },

  mounted () {
    if (!this.noAutoSize) {
      this.__removeAutosizeListener = autosizeInput(this.$el)
    }
    if (!this.noAutoFocus) this.$el.focus()

    this.toggleListeners()
    this.forceInputEvent()
  },

  beforeDestroy () {
    if (this.__removeAutosizeListener) this.__removeAutosizeListener()
  }
}
</script>

<style lang="scss">
.c-table-input {
  display: inline-flex;
  all: inherit;
  width: 100%;

  @extend %reset-input-appearance;
}

%reset-input-appearance {
  box-shadow: none;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}
</style>
