<template>
  <div :style="{ top: topPos }" :class="classes">
    <div :style="{ height: computedHeight }" class="content">
      <!-- @slot The main header content. -->
      <slot />
    </div>

    <div
      v-if="$slots.subMenu"
      class="sub-menu"
      :style="{ height: computedSubMenuHeight }"
    >
      <!-- @slot The sub-menu section content. -->
      <slot name="subMenu" />
    </div>
  </div>
</template>

<script>

/**
 * Our main header/sub-header component.
 */
export default {
  name: 'CHeader',

  props: {
    /**
     * The height of the main header section.
     */
    height: {
      type: [ String, Number ],
      default: 70
    },

    /**
     * The height of the sub menu section.
     */
    subMenuHeight: {
      type: [ String, Number ],
      default: 0,
    },

    /**
     * Whether to show or hide the main section of the header
     * (doesn't hide the sub-menu section).
     */
    hide: {
      type: Boolean,
      default: false
    },

    /**
     * Whether to show or hide the sub-menu section of the header
     * (doesn't hide the main section).
     */
    hideSubMenu: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return [ 'c-header', { '-hide-sub-menu': this.hideSubMenu } ]
    },
    computedHeight () {
      return `${+this.height}px`
    },
    computedSubMenuHeight () {
      return this.hideSubMenu ? '0px' : `${+this.subMenuHeight}px`
    },
    topPos () {
      if (!this.hide) return 0
      return `-${+this.height}px`
    }
  }
}
</script>

<style lang="scss">

%blur-background {
  position: relative;
  background: transparent;
  & > * { z-index: 1; }

  &:before {
    position: absolute;
    content: '';
    display: inline-flex;

    width: 100%;
    height: 100%;

    background-color: hsla(0,0%,100%,.55);
    backdrop-filter: blur(17px);
    z-index: 0;

    // Used to update backdrop filter when Trial warning is loaded
    transform: translate3d(0, 0, 0);
  }
}

.c-header {
  display: flex;
  flex-direction: column;
  width: 100%;

  position: absolute;
  top: 0;

  will-change: top;
  z-index: var(--z-index-3);
  transition: top .3s ease;

  box-shadow: var(--base-shadow);
  @extend %blur-background;

  &.-hide-sub-menu > .sub-menu { height: 0px; }

  & > .content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;
    background: transparent;
    padding: 10px;
  }

  & > .sub-menu {
    flex-shrink: 0;
    width: 100%;
    background: transparent;
    height: 50px;
    transition: height 200ms ease;
    overflow: hidden;
    z-index: 0;

    @include responsive (xs-mobile, mobile) { height: 40px; }
  }
}
</style>
