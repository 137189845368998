<template>
  <div
    class="c-radio-raw"
    :class="classes"
    :style="style"
  >
    <p v-if="description" class="text">{{ description }}</p>

    <div class="inner">
      <div
        v-for="(option, index) in options"
        :key="index"
        :class="[ 'radio-option', { '-disabled': option.disabled } ]"
        :data-testid="`option-${fieldName}-${getLabel(option)}`"
        @click="emitInput(option)"
        @mouseenter="onMouseEnter(option)"
        @mouseleave="onMouseLeave(option)"
      >
        <div class="borderline" :style="sizes">
          <div :class="isSelected(option)" />
        </div>

        <p class="label">
          <slot :name="`label-index-${index}`" :label="getLabel(option)">
            {{ getLabel(option) }}
          </slot>
        </p>
      </div>
    </div>

    <div class="native">
      <input
        v-for="(option, index) in options"
        :key="index"
        type="radio"
        :disabled="option.disabled"
        :name="name"
        :value="getLabel(option)"
        :checked="isSelectedNative(option)"
        @click="emitInput(option)"
      >
    </div>
  </div>
</template>

<script>
import { isEmptyObject } from '@convenia/helpers'

/**
 * A radio button component. When an option is selected, it emits an 'input'
 * event with the chosen option.
 */
export default {
  name: 'CRadioRaw',

  props: {
    /**
     * Array of strings or objects
     */
    options: {
      type: Array,
      default: () => ([])
    },

    /**
     * The input name.
     */
    name: {
      type: String,
      default: 'radioGroup'
    },

    /**
     * The input field name.
     */
    fieldName: {
      type: String,
      default: ''
    },

    /**
     * If `options` is an array of objects, CRadioButon will use this prop
     * to compare the currently selected item.
     */
    trackBy: {
      type: String,
      default: ''
    },

    /**
     * If `options` is an array of objects, CRadioButton will use this prop
     * to get the label of the option/value to be displayed for the user.
     */
    displayBy: {
      type: String,
      default: ''
    },

    /**
     * Number of columns
     */
    columns: {
      type: Number,
      default: 1
    },

    /**
     * Selected option's value
     */
    value: {
      type: [ Object, String, Boolean, Number ],
      default: ''
    },

    /**
     * Radio button description
     */
    description: {
      type: String,
      default: ''
    },

    /**
     * Disables the RadioButton
     */
    disabled: Boolean,

    /**
     * Set hidden native radio inputs for native forms compatibility.
     */
    nativeCompatible: Boolean,

    /**
     * Radio button size
     */
    buttonSize: {
      type: [ String, Number, Object ],
      default: () => ({ desktop: 25, mobile: 20 }),
      validator: val => {
        if (typeof val === 'object')
          return [ 'desktop', 'mobile' ].every(x => val.hasOwnProperty(x))

        return !!val
      }
    },

    /**
     * Radio button point size
     */
    pointSize: {
      type: [ String, Number, Object ],
      default: () => ({ desktop: 15, mobile: 12 }),
      validator: val => {
        if (typeof val === 'object')
          return [ 'desktop', 'mobile' ].every(x => val.hasOwnProperty(x))

        return !!val
      }
    }
  },

  computed: {
    hasSlots () {
      return !isEmptyObject(this.$scopedSlots)
    },

    classes () {
      return {
        '-disabled': this.disabled,
        '-has-slots': this.hasSlots
      }
    },

    style () {
      return {
        '--radio-columns-count': this.columns
      }
    },

    sizes () {
      const desktopButton = (this.buttonSize.desktop || this.buttonSize)
      const desktopPoint = (this.pointSize.desktop || this.pointSize)
      const mobileButton = (this.buttonSize.mobile || this.buttonSize)
      const mobilePoint = (this.pointSize.mobile || this.pointSize)

      return {
        '--desktop-button-size': `${desktopButton}px`,
        '--desktop-point-size': `${desktopPoint}px`,
        '--mobile-button-size': `${mobileButton}px`,
        '--mobile-point-size': `${mobilePoint}px`
      }
    }
  },

  methods: {
    isSelected (option) {
      if (this.trackBy && option instanceof Object) {
        return this.value instanceof Object
          ? this.value[this.trackBy] === option[this.trackBy] ? 'selected' : 'unselected'
          : this.value === option[this.trackBy] ? 'selected' : 'unselected'
      }

      return this.value === option
        ? 'selected'
        : 'unselected'
    },

    isSelectedNative (option) {
      return this.isSelected(option) === 'selected'
    },

    emitInput (option) {
      const value = this.trackBy && option instanceof Object && !(this.value instanceof Object)
        ? option[this.trackBy]
        : option
      /**
      * Return the value of option when selected
      */
      this.$emit('input', value)
    },

    getLabel (option) {
      if (this.displayBy && option instanceof Object) {
        return option[this.displayBy]
      }
      return option
    },

    onMouseEnter (option) {
      if (!option.hint) return

      this.$emit('hint:show', option.hint)
    },

    onMouseLeave (option) {
      if (!option.hint) return

      this.$emit('hint:hide')
    }
  }
}
</script>

<style lang="scss">
%select {
  border-radius: 50%;
  transform: scale(1);
  min-width: var(--desktop-point-size);
  max-width: var(--desktop-point-size);
  min-height: var(--desktop-point-size);
  max-height: var(--desktop-point-size);
  transition: opacity .3s ease, transform .3s;
  background-color: color-var(disabled, dark);
}

.c-radio-raw {
  &.-disabled {
    pointer-events: none;
    user-select: none;

    & > .inner > .radio-option {
      & > .borderline {
        background: rgba_to_rgb(map-get($text-color, base-10));
        border-color: transparent;

        & > .selected { opacity: 0.3; }
      }

      & > .label { color: color-var(text, base-30); }
    }
  }

  & > .text { margin-bottom: 20px; }

  & > .inner {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(var(--radio-columns-count), 1fr);

    grid-row-gap: 10px;

    justify-content: space-around;

    & > .radio-option {
      display: flex;
      flex-flow: row;
      align-items: flex-start;
      cursor: pointer;
      min-height: 0;

      &.-disabled {
        user-select: none;
        pointer-events: none;
        cursor: normal;

        & > .label { color: color-var(text, base-30); }
        & > .borderline {
          background-color: color-var(text, base-10);
          border: 0;
        }
      }

      & > .borderline {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        min-height: var(--desktop-button-size);
        max-height: var(--desktop-button-size);
        min-width: var(--desktop-button-size);
        max-width: var(--desktop-button-size);
        border: 1px solid $base-border-color;
        border-radius: 50%;
        background-color: white;

        & > .selected { @extend %select; opacity: 1; }
        & > .unselected {
          @extend %select;
          opacity: 0;
          @include responsive (xs-mobile, mobile) {
            min-height: var(--mobile-point-size);
            max-height: var(--mobile-point-size);
            min-width: var(--mobile-point-size);
            max-width: var(--mobile-point-size);
          }
        }

        @include responsive (xs-mobile, mobile) {
          min-height: var(--mobile-button-size);
          max-height: var(--mobile-button-size);
          min-width: var(--mobile-button-size);
          max-width: var(--mobile-button-size);

          & > .selected {
            min-height: var(--mobile-point-size);
            max-height: var(--mobile-point-size);
            min-width: var(--mobile-point-size);
            max-width: var(--mobile-point-size);
          }
        }
      }

      & > .label {
        font-size: 14px;
        color: color-var(text, base-80);

        margin-left: 15px;

        html.ios.iphone & {
          line-height: 19px;
          min-height: 19px;
        }

        line-height: 20px;
        min-height: 20px;

        @include responsive (tablet, desktop) {
          line-height: 19px;
          margin-left: 20px;
          margin-top: 4px;
        }
      }
    }
  }

  & > .native {
    position: absolute;
    visibility: hidden;
  }

  & > .jumbo-validation { display: none; }

  &.-has-slots > .inner > .radio-option {
    align-items: center;

    & > .label {
      display: flex;
      align-items: center;
      margin-top: 0;
      flex: 1;
    }
  }
}
</style>
