<template>
  <div :class="[ 'c-multi-select', { '-validation': !!validation, '-label-left': labelLeft } ]">
    <c-select
      class="select"
      :value="computedValue"
      :validation="validation"
      :options="availableOptions"
      :track-by="trackBy"
      :display-by="displayBy"
      :label-left="labelLeft"
      v-bind="getAttrs($attrs)"
      @input="onInput"
    />

    <c-multi-select-item-list
      v-if="(computedValue || []).length"
      class="selected"
      :items="computedValue"
      :display-by="displayBy"
      v-bind="$attrs"
      @remove="onRemove"
    />
  </div>
</template>

<script>
import CommonMixin from './fragments/CommonMixin'

export default {
  name: 'CMultiSelect',

  mixins: [ CommonMixin ],

  methods: {
    getAttrs (attrs) {
      return {
        ...attrs,
        disabled: attrs.disabled || !this.availableOptions.length
      }
    }
  }
}
</script>

<style lang="scss">

.c-multi-select {
  display: flex;
  flex-direction: column;
}
</style>
