const DOCUMENT_EXTENSIONS = {
  PDF: [ 'pdf' ],
  IMAGE: [ 'jpg', 'jpeg', 'png', 'gif' ],
  DOCUMENT: [ 'doc', 'docx', 'txt' ],
  SPREADSHEET: [ 'xls', 'xlsx', 'csv' ]
}

const TYPES = {
  PDF: 'PDF',
  IMAGE: 'Imagem',
  DOCUMENT: 'Documento',
  SPREADSHEET: 'Planilha'
}

const ICONS = {
  PDF: 'documents-file-pdf',
  IMAGE: 'documents-file-image-picture',
  DOCUMENT: 'documents-file-text-1',
  SPREADSHEET: 'documents-file-3'
}

export default Object
  .entries(DOCUMENT_EXTENSIONS)
  .reduce((acc, [ key, extensions ]) => {
    extensions.forEach(ext => {
      acc[ext] = { type: TYPES[key], icon: ICONS[key] }
    })

    return acc
  }, {})
