<template>
  <component
    :is="componentType"
    v-bind="inputAttrs"
    ref="input"
    v-mask="mask"
    class="c-input-abstract"
    :value="value"
    :data-testid="`input-abstract-${fieldName}`"
    v-on="computedListeners"
    @keydown.native="checkKey"
    @input="emitInput"
  >{{ textArea ? value : '' }}</component>
</template>

<script>
import { maska as mask } from 'maska'
import { Money } from 'v-money'

/**
 * A reusable component that abstracts all of the
 * essential input logic.
 */
export default {
  name: 'CInputAbstract',

  components: { Money },

  directives: { mask },

  props: {
    /**
     * The value of the input, durr.
     * @model
     */
    value: {
      type: [ String, Number, null ],
      default: ''
    },

    /**
     * Whether it is a textarea or not.
     */
    textArea: Boolean,

    /**
     * Whether to apply the money mask.
     */
    isMoney: Boolean,

    /**
     * Money mask options.
     */
    moneyMask: {
      type: Object,
      default: () => ({
        prefix: 'R$ ',
        decimal: ',',
        thousands: '.'
      })
    },

    /**
     * Value mask (not to be confunded with moneyMask)
     */
    mask: {
      type: [ String, Array ],
      default: ''
    },

    /**
     * Allow only positive numbers to be inserted on the input.
     */
    positiveOnly: Boolean,

    /**
     * The input name
     */
    fieldName: {
      type: String,
      default: ''
    },

  },

  watch: {
    // This forces the two-way binding to update the input value when necessary
    value (val) {
      const el = this.$refs['input'].$el || this.$refs['input']
      if (el.value !== val) el.value = val
    },

    componentType () {
      const isFocused = document.activeElement === this.$el
      if (isFocused) this.$nextTick(() => {
        if (this.$el) this.$el.focus()
      })
    }
  },

  computed: {
    componentType () {
      if (this.useMoney) return 'money'

      return this.textArea ? 'textarea' : 'input'
    },
    inputAttrs () {
      return { ...this.$attrs, ...(this.isMoney ? this.moneyMask : {}) }
    },
    computedListeners () {
      const { input, ...remaining } = this.$listeners

      return remaining
    },
    useMoney () {
      return this.isMoney
        && (this.$attrs.placeholder ? this.value !== null : true)
    }
  },

  methods: {
    emitInput (e) {
      const value = typeof e === 'object' ? e.target.value : e

      // Fix mask cursor position
      if (this.$el && this.$el.oninput && !this.isMoney) {
        this.$el.oninput(e)
      }

      // fix infinite update loop
      if (value !== this.value) {
        this.$emit('input', value)
      }
    },

    checkKey (event) {
      if (this.isMoney && this.positiveOnly && event.key === '-') {
        event.preventDefault() && event.stopPropagation()
      }
    }
  }
}
</script>

<style lang="scss">
.c-input-abstract { resize: none; margin: 0; }
</style>
