<template>
  <div
    :class="[ 'c-auto-complete-item', { '-active': index === pointer } ]"
    @click="$emit('item-list:click')"
    @mouseenter.self="$emit('item-list:mouseenter', index)"
  >
    <span class="before-wrapper">
      <slot name="before" :item="item" />
    </span>

    <span :ref="index" class="text">
      <component
        v-for="(chunk, key) in chunks"
        :is="chunk.bold ? 'b' : 'span'"
        :key="key"
      >{{ chunk.content }}</component>
    </span>

    <span class="after-wrapper">
      <slot name="after" :item="item" />
    </span>
  </div>
</template>

<script>
import { normalizeString, getDiacritic } from '@convenia/helpers'

export default {
  name: 'CAutoCompleteItem',

  props: {
    searchProp: {
      type: String,
      default: '',
    },

    normalizeProp: {
      type: String,
      default: '',
    },

    item: {
      type: Object,
      required: true
    },

    index: {
      type: Number,
      required: true,
    },

    pointer: {
      type: Number,
      required: true,
    },

    search: {
      type: String,
      default: '',
    }
  },

  computed: {
    chunks () {
      const typed = getDiacritic(
        this.item,
        this.searchProp,
        normalizeString(this.search),
        this.item[this.normalizeProp]
      )

      const chunks = this.item[this.searchProp]
        .split(typed)
        .reduce((chunks, split, i, splits) => {
          const hasAfter = !!splits[i + 1]
          const hasBefore = !!splits[i - 1]
          const splitChunk = { content: split, bold: false }
          const typedChunk = { content: typed, bold: true }
          const initialChunks = !split ? [ ...chunks, typedChunk ] : [ ...chunks ]
          const preChunks = (!split && !hasBefore && !hasAfter)
            ? [ typedChunk ]
            : initialChunks

          return [
            ...preChunks,
            splitChunk,
            split && hasAfter ? typedChunk : {}
          ]
        }, [])

      return chunks
    }
  }
}
</script>

<style lang="scss">
.c-auto-complete-item {
  opacity: 0.8;
  color: color-var(text, base);
  padding: 0 15px;
  font-size: 14px;
  line-height: 40px;

  cursor: pointer;

  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.-active { background-color: color-var(text, base-05); }

  & > .text {
    font-size: 14px;
    line-height: 19px;
    font-family: var(--base-font-family);
  }
}
</style>
