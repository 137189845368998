<template>
  <div class="c-floating-counter">
    <div v-if="(actions || []).length" class="actions">
      <div
        v-for="{ name, icon, tooltip } in actions"
        :key="name"
        v-tooltip:17.dark="{ value: tooltip, hide: !showTooltip || isMobile }"
        class="action"
        @click.stop="$emit(`click:${name}`)"
      >
        <c-icon
          class="icon"
          :icon="icon"
        />
      </div>
    </div>

    <div class="info">
      <c-counter
        class="counter"
        :value="count"
      />

      <c-icon
        v-if="icon"
        class="icon"
        :icon="icon"
      />
    </div>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CFloatingCounter',

  mixins: [ MediaQuery ],

  props: {
    count: {
      type: Number,
      default: 0
    },

    showTooltip: Boolean,

    actions: {
      type: Array,
      default: () => []
    },

    icon: {
      type: String,
      default: 'user-profile-3'
    },

    height: {
      type: String,
      default: '70px'
    },
  },

  mounted () {
    this.$el.style.setProperty('--height', this.height)
  }
}
</script>

<style lang="scss">
.c-floating-counter {
  $secondary-background: #485271;
  --height: 70px;

  %divider {
    content: "";
    position: absolute;
    width: 1px;
    height: 30px;
    background-color: $secondary-background;
  }

  height: var(--height);
  border-radius: calc(var(--height) / 2);
  background: linear-gradient(135deg, #3A4467, #121E48 100%);
  box-shadow: -1px -1px 4px 0 rgba(0,0,0,0.2);
  padding: 10px;
  position: fixed;
  bottom: 20px;
  left: calc(50% + 35px);
  transform: translate(-50%);
  z-index: var(--z-index-2);

  @include responsive (tablet, desktop) {
    bottom: 40px;
  }

  &, & > .actions, & > .actions > .action {
    display: flex;
    align-items: center;
  }

  & > .actions { margin-right: 8px; }

  & > .actions > .action {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $secondary-background;
    justify-content: center;
    position: relative;

    &:not(:last-of-type) {
      &::after {
        @extend %divider;
        right: -7px;
      }

      margin-right: 15px;
    }

    &:hover {
      @include responsive(tablet, desktop) {
        cursor: pointer;
        background-color: #707790;

        & > .icon { @include icon-color(#FFF); }
      }
    }

    & > .icon {
      @include icon-color(rgba(#FFF, .8));
    }
  }

  & > .info {
    margin: 7px;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 7px;

    & > .icon {
      @include icon-color(rgba(#FFF, .5));
    }
  }
}
</style>
