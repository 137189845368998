<template>
  <transition-group name="feedback" class="c-feedbacks" tag="ul">
    <c-card
      v-for="(feedback, i) in feedbackList"
      :key="feedback.fbId"
      :yellow="feedback.type === 'info'"
      :green="feedback.type === 'success'"
      :red="feedback.type === 'error'"
      ref-cy="feedback"
      class="feedback"
      :data-testid="`feedback-${feedback.type}-${i}`"
    >
      <div class="message-container">
        <span class="message">{{ feedback.message }}</span>

        <span :style="{ color: getColor(feedback.type) }" class="highlighted">
          {{ feedback.highlighted }}
        </span>
      </div>

      <div class="actions">
        <c-button
          v-if="feedback.action"
          size="30"
          v-bind="feedback.action.props"
          @click="feedback.action.handler"
        >
          {{ feedback.action.label || '' }}
        </c-button>

        <c-button
          class="button"
          icon="delete-disabled"
          size="30"
          grey
          @click="$emit('close', feedback)"
        />
      </div>
    </c-card>
  </transition-group>
</template>

<script>
import { isHex, is } from '@convenia/helpers'

export default {
  name: 'CFeedbacks',

  props: {
    feedbacks: {
      type: Array,
      default: () => [],
      validator: feedbacks => (feedbacks || [])
        .every(f => is(f, 'Object') && [ 'type', 'message' ]
          .every(k => Object.keys(f).includes(k) && f[k]))
    },
    info: {
      type: String,
      default: '#FFC24B',
      validator: isHex
    },
    success: {
      type: String,
      default: '#63E1A5',
      validator: isHex
    },
    error: {
      type: String,
      default: '#FF4B8C',
      validator: isHex
    }
  },

  computed: {
    feedbackList () {
      return this.feedbacks || []
    }
  },

  methods: {
    getColor (type) {
      if (type === 'success')
        return this.success
      if (type === 'error')
        return this.error

      return this.info
    }
  }
}
</script>

<style lang="scss">
$feedback-colors: (
  info: var(--info),
  success: var(--success),
  error: var(--error)
);

.c-feedbacks {
  position: fixed;
  top: 10px;
  left: 0px;
  z-index: 999;

  width: 100%;
  max-width: calc(100% - 20px);
  margin: 0;
  padding: 0;

  @include responsive(tablet, desktop) {
    bottom: 20px;
    top: unset;

    width: 365px;
  }

  & > .feedback {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    opacity: 0.9;
    border-radius: 5px;
    margin-bottom: 10px;

    min-height: 60px;
    margin-left: 10px;

    box-shadow: -1px -1px 4px 0 rgba(0,0,0,0.2);
    background: linear-gradient(
      135deg,
      color-var(text, base-90) 0%,
      color-var(text, base) 100%
    ) !important;

    &:last-child { margin-bottom: 20px; }
    @include responsive(tablet, desktop) { margin-left: 20px; }

    & > .message-container {
      font-family: var(--base-font-family);
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      word-break: break-word;
      text-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
      color: #FFF;

      width: fit-content;
      margin: { left: 20px; top: 20px; bottom: 20px; right: 15px; }
    }

    & > .actions {
      display: flex;
      align-items: center;

      & > .button {
        height: 30px;
        width: 30px;
        height: 30px;
        min-width: unset;
        min-height: unset;
        flex-shrink: 0;
        margin-right: 5px;

        border: 1px solid rgba(#FFF, .3);

        & > .text { color: rgba(#FFF, .5); }

        & > .icon {
          @include icon-color(rgba(#FFF, 0.5));
          margin: 0;

          width: 14px;
          height: 14px;
        }

        &:hover {
          border-color: rgba(#FFF, .8);
          background: transparent;
          &::after { opacity: 0; }

          & > .icon { @include icon-color(rgba(#FFF, 0.8)) }
          & > .text { color: rgba(#FFF, 0.8); }
        }

        &:last-child {
          width: 30px;
          margin-right: 15px;
          border-radius: 50%;
          margin-left: 5px;
        }
      }
    }

    @each $type, $color in $feedback-colors {
      &.-#{$type} { background-color: $color; }
    }
  }
}

.feedback-enter-active,
.feedback-leave-active { transition: all 0.3s; }
.feedback-enter,
.feedback-leave-to {
  opacity: 0;
  transform: translate(0, -100%);
  @include responsive(tablet, desktop) { transform: translate(0, 100%); }
}
</style>
