<template>
  <div :class="[ 'c-tag', { '-disabled': disabled } ]">
    <span class="text">{{ text }}</span>

    <span
      v-if="!disabled"
      class="icon-wrapper"
      @click.stop="$emit('remove', item)"
    >
      <c-icon class="icon" size="13" icon="delete-disabled" />
    </span>
  </div>
</template>

<script>
import { is } from '@convenia/helpers'

/**
 * A removable tag component
 */
export default {
  name: 'CTag',

  props: {
    /**
     * The item to be displayed.
     * Can be either a simple string or an object.
     */
    item: {
      type: [ Object, String ],
      required: true
    },

    /**
     * If the item property is an object, the displayBy
     * prop is required to indicate which property of the
     * object should be displayed.
     */
    displayBy: {
      type: String,
      default: ''
    },

    /**
     * Makes the tag "unremovable" and sets its style
     * to a gray background
     */
    disabled: Boolean
  },

  computed: {
    text () {
      return is(this.item, 'String') ? this.item : this.item[this.displayBy]
    }
  }
}
</script>

<style lang="scss">
.c-tag {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 19px;

  height: 30px;
  opacity: 0.9;
  border-radius: 15px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
  background: linear-gradient(135deg, color-var(text, base-90) 0%, color-var(text) 100%);

  & > .text {
    margin-right: 30px;
    opacity: 0.8;
    font-size: 14px;
    color: #FFFFFF;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > .icon-wrapper {
    cursor: pointer;

    & > .icon {
      opacity: 0.8;
      @include icon-color(#FFF);
      flex-shrink: 0;
      vertical-align: middle;
    }
  }

  &.-disabled {
    cursor: default;
    box-shadow: 0 1px 2px 0 var(--color-disabled-light);
    background: var(--color-disabled-light);

    & > .text {
      margin-right: 10px;
      color: color-var(text, base-30);
    }
  }
}
</style>
