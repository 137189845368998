<template>
  <div class="message-box">
    <c-character
      :type="character"
      :class="character"
      :size="isMobile ? 130 : 150"
    />

    <span class="title">
      {{ title }}
    </span>

    <span
      class="text"
      v-html="text"
    />

    <slot />
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'MessageView',

  mixins: [ MediaQuery ],

  props: {
    title: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: ''
    },

    character: {
      type: String,
      default: 'cactus'
    },
  }
}
</script>

<style lang="scss">
.message-box {
  margin: auto;
  text-align: center;

  & > .c-character {
    margin: 0 auto 30px;
    display: inline-block;
    &.paper { margin-left: 10px; }
  }

  & > .title {
    display: block;
    margin-bottom: 30px;
    font-size: 24px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 28px;
    color: rgba(#121E48, .8);
    -webkit-font-smoothing: antialiased;
  }

  & > .text {
    font-size: 16px;
    line-height: 22px;
    color: rgba(#121E48, .8);
    display: inline-block;
    max-width: 560px;
  }

  @include responsive (tablet, desktop) {
    width: 700px;
    padding: 70px;
    border-radius: 5px;
  }

  @include responsive (xs-mobile, mobile) {
    & > .text { padding: 0 20px; }
  }
}
</style>
