export default () => {
  return {
    bankAccount: {
      title: 'Dados Bancários',
      singleTitle: 'Dado Bancário',
      singleIcon: 'piggy-bank-coin',
      addLabel: 'Adicionar novo dado bancário',
      multiple: true,
      files: true,
      filesText: 'Deve ser anexado <b>o comprovante de conta bancária</b>.',
      fields: {
        id: { type: 'text', hide: true },
        accountType: {
          type: 'select',
          options: [],
          optionsSrc: 'bankAccountTypes',
          displayBy: 'name',
          trackBy: 'id',
          label: 'Tipo de conta',
          placeholder: 'Tipo de conta',
          value: '',
        },
        modality: {
          type: 'select',
          options: [
            { label: 'Pessoa Física', value: 'PF' },
            { label: 'Pessoa Jurídica', value: 'PJ' },
          ],
          displayBy: 'label',
          trackBy: 'value',
          label: 'Modalidade',
          placeholder: 'Modalidade',
          value: '',
        },
        bank: {
          type: 'select',
          label: 'Banco',
          placeholder: 'Banco',
          options: [],
          optionsSrc: 'bankList',
          displayBy: 'name',
          trackBy: 'id',
          value: '',
        },
        agency: {
          type: 'text',
          label: 'Agência',
          inputmode: 'numeric',
          placeholder: 'Agência',
          validation: 'max:10|min:3',
          mask: '##########',
          validationMsg: 'Agência deve ter de 3 a 10 dígitos',
          value: '',
        },
        account: {
          type: 'text',
          label: 'Conta',
          inputmode: 'numeric',
          placeholder: 'Conta',
          validation: 'min:4|max:20',
          mask: '####################',
          validationMsg: 'Conta deve ter de 4 a 20 dígitos',
          value: '',
        },
        accountDigit: {
          type: 'text',
          label: 'Dígito',
          placeholder: '00000',
          validation: 'max:4',
          mask: 'XXXX',
          validationMsg: 'Dígito da conta deve ter no máximo 4 dígitos',
          value: '',
        },
        pix: {
          type: 'text',
          label: 'Chave Pix',
          placeholder: 'Chave PIX',
          value: '',
          validationMsg: 'Chave PIX deve ter de 7 a 106 caracteres',
          validation: 'min:7|max:106',
          nullable: true,
        },
        files: {
          type: 'file',
          label: '',
          showLabel: false,
          multiple: true,
          labelLeft: false,
          fullWidth: true,
          extensions: [ 'jpg', 'jpeg', 'gif', 'bmp', 'png', 'pdf' ],
          infoCardProps: { hide: true },
          fileLimit: 4,
          validation: { maxLength: 4 },
          sizeLimit: 8,
        }
      }
    }
  }
}
