<template>
  <component
    :is="type"
    :target="external && '_blank'"
    class="c-link"
    v-bind="bindAttrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>

/**
 * Generic link component.
 */
export default {
  name: 'CLink',
  props: {
    /**
     * The link's path, can be either an external URL or the path to an
     * internal route in the app (must follow the vue-router api).
     */
    to: {
      type: [ String, Object ],
      default: () => null
    },
    /**
     * Whether the link is external or not
     */
    external: {
      type: Boolean,
      default: false
    },

    /**
     * Whether to use the nuxt component for route links.
     */
    nuxt: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    type () {
      return !this.to
        ? 'button' : this.external
          ? 'a' : this.nuxt
            ? 'nuxt-link' : 'router-link'
    },

    bindAttrs () {
      const link = this.type === 'a' || this.type === 'button'
        ? { href: this.external && this.to }
        : { to: this.to || null }

      return { ...this.$attrs, ...link }
    }
  }
}
</script>
