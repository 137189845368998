/**
 * This file should be discontinued when the entire system starts
 * using the new nomenclatures and illustrations with the standard webp extension
 */
export const typeMapper = {
  computed: {
    mappedType () {
      /**
       * typeMap is used to support old places
       * that still use the old illustration nomenclature,
       * new places must use the new nomenclature
       */
      const typeMap = {
        warning: 'alert',
        calculator: 'calculator',
        cactus: 'cactus',
        clock: 'clock',
        convite: 'letter-heart',
        'envio_contabilidade': 'letter-heart',
        invite: 'letter-heart',
        'desligar_folha': 'alert',
        'empty_upload': 'photo',
        'excluir_folha': 'paper-cut',
        'paper-edit': 'alert',
        'paper-sad': 'alert',
        paper: 'paper-happy',
        senha: 'key',
        success: 'check',
        upload: 'photo'
      }
      return typeMap[this.type] || this.type
    },
    isSvg () {
      return this.type === 'cactus-vacation' || this.type === 'tablet'
    }
  }
}
