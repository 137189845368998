<template>
  <div class="admission-form-files">
    <c-title dark-text>
      {{ title }}
    </c-title>

    <span v-if="text" class="text" v-html="text" />

    <c-file-list
      grey
      add-size="small"
      :selected-files="null"
      :files="checkedFiles"
      :add="files.length < schema.fileLimit"
      @add="$emit('add')"
      @remove="$emit('remove', $event)"
    />

    <form-field-validation
      v-if="!isValid && showErrors"
      class="validation"
      message="O anexo é obrigatório."
    />
  </div>
</template>

<script>
import FormFieldValidation from '@convenia/components/CFormBuilder/fragments/FormFieldValidation'

export default {
  name: 'AdmissionFormFiles',

  components: {
    FormFieldValidation
  },

  props: {
    files: {
      type: Array,
      default: () => ([])
    },
    title: {
      type: String,
      default: 'Anexo'
    },
    schema: {
      type: Object,
      default: () => ({ fileLimit: 4 })
    },
    required: Boolean,
    text: {
      type: String,
      default: ''
    },
  },

  computed: {
    isValid () {
      if (this.required)
        return this.files.length >= 1

      return true
    },

    checkedFiles () {
      return this.files.map(file => ({
        ...file,
        uploaded: true,
        progress: 100
      }))
    }
  },

  data: () => ({
    showErrors: false
  }),

  methods: {
    validate () {
      this.showErrors = true

      return this.isValid
    }
  }
}
</script>

<style lang="scss">
.admission-form-files {
  width: auto;
  & > .c-title { margin-bottom: 10px; }
  & > .text { @include typo(body-1); display: block; margin-bottom: 20px; }
  & > .validation { width: 100%; justify-content: center; display: flex; margin-top: -5px; }
  & > .c-file-list > .c-add { width: 100%; }
}
</style>
