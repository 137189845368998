<template>
  <!-- eslint-disable vuejs-accessibility/form-control-has-label -->
  <div class="c-cropper-range">
    <c-button
      size="25"
      icon="minus"
      type="button"
      class="btn"
      icon-size="13"
      @click="onDecrease"
    />

    <input
      step="1"
      type="range"
      class="input"
      :value="value"
      :min="min"
      :max="max"
      @input="onChange"
    >

    <c-button
      size="25"
      icon="plus"
      type="button"
      class="btn"
      icon-size="13"
      @click="onIncrease"
    />
  </div>
</template>

<script>
export default {
  name: 'CCropperRange',

  props: {
    value: {
      type: Number,
      default: 0,
    },

    min: {
      type: Number,
      default: 0,
    },

    max: {
      type: Number,
      default: 100,
    }
  },

  methods: {
    onChange (event) {
      this.$emit('input', +event.target.value)
    },

    onDecrease () {
      const value = this.value - this.max / 4

      this.$emit('input', Math.max(value, this.min))
    },

    onIncrease () {
      const value = this.value + this.max / 4

      this.$emit('input', Math.min(value, this.max))
    }
  }
}
</script>

<style lang="scss">
.c-cropper-range {
  margin-top: 40px;
  display: flex;
  align-items: center;

  & > .input {
    height: 8px;
    width: 180px;
    background: transparent;
    -webkit-appearance: none;

    &:focus { outline: none; }

    // bar
    &::-webkit-slider-runnable-track {
      height: 8px;
      width: 180px;
      cursor: pointer;
      border-radius: 25px;
      background-color: #d2d4db;
    }

    // ball
    &::-webkit-slider-thumb {
      width: 14px;
      height: 14px;
      cursor: pointer;
      margin-top: -4px;
      border-radius: 15px;
      background: #FFFFFF;
      border: 0;
      box-shadow: 0 0 11px 3px color-var(text, base-10);
      -webkit-appearance: none;
    }
  }

  & > .btn {
    margin: 0 15px;

    // Fixes blurry icons
    & > .icon { transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1); }
  }
}
</style>
