<template>
  <date-picker
    ref="veeCalendar"
    class="c-calendar"
    :class="{ '-alert': alert, '-wide': wide, '-narrow': !wide }"
    :style="{ '--columns': computedColumns }"
    :value="value"
    :mode="mode"
    :max-date="computedMaxDate"
    :min-date="computedMinDate"
    :max-page="computedMaxPage"
    :min-page="computedMinPage"
    :disabled-dates="disabledDates"
    :available-dates="computedAvailableDates"
    :columns="computedColumns"
    :rows="computedRows"
    :attributes="attributes"
    :drag-attribute="dragAttr"
    :from-page="fromPage"
    nav-visibility="hidden"
    is-inline
    is-expanded
    v-bind="datePickerAttrs"
    @input="onInput"
    @drag="$emit('drag', $event)"
    @dayclick="onDayClick"
  >
    <div
      slot-scope="page"
      class="header"
      slot="header"
    >
      <button
        type="button"
        class="nav-left"
        :class="{ '-disabled': !page.canMove(page.prevMonthComps) }"
        @click.stop="page.move(-panes)"
      >
        <c-icon size="16" icon="arrow-left" />

        <span class="tag">
          {{ months[page.prevMonthComps.month - 1] }}
        </span>
      </button>

      <span class="title">
        {{ page.title }}
      </span>

      <button
        type="button"
        class="nav-right"
        :class="{ '-disabled': !page.canMove(page.nextMonthComps) }"
        @click.stop="page.move(panes)"
      >
        <span class="tag">
          {{ months[page.nextMonthComps.month - 1] }}
        </span>

        <c-icon size="16" icon="arrow-right" />
      </button>
    </div>
  </date-picker>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

/**
 * A simple but felxible datepicker.
 */
export default {
  name: 'CCalendar',

  components: { DatePicker },

  props: {
    /**
     * The value of the field.
     * set to a Date type when mode is set to 'single'
     * @type { Date } }
     *
     * set to an Array of Dates when mode is set to 'multiple':
     * @type { Date[] }
     *
     * set to an Object with the following format when mode is 'range':
     * @type { { start: Date, end: Date } }
     */
    value: {
      type: [ Date, Array, Object, String ],
      required: true
    },

    /**
    * The month and the year to open
    * example: { month: 3, year: 2018 }
    * @type { Object }
    */
    fromPage: {
      type: Object,
      default: null,
    },

    /**
     * The maximum date available to be picked.
     */
    maxDate: Date,

    /**
     * The minimum date available to be picked.
     */
    minDate: Date,

    /**
     * The selection mode.
     * @values 'single', 'multiple', 'range'
     *
     * 'single' for a single date output.
     * 'multiple' for an array of dates output.
     * 'range' for an Object with the following format output:
     * @type { { start: Date, end: Date } }
     */

    mode: {
      type: String,
      default: 'single',
      validator: mode => [ 'single', 'multiple', 'range' ].includes(mode)
    },

    /**
     * The number of panes to be displayed
     * @values 1, 2, 3
     */
    panes: {
      type: Number,
      default: 1,
      validator: c => [ 1, 2, 3 ].includes(c)
    },

    /**
     * Display the panes vertically when set to true
     */
    vertical: Boolean,

    /**
     * The list of events to be displayed as 'dots' in the calendar.
     * e.g. - holidays, scheduled meetings, etc.
     * @type { { date: Date, label: string, color: string }[] }
     */
    events: {
      type: Array,
      validator: events => events.every(({ date, label }) => {
        const hasDateAndLabel = !!date && !!label
        const nameIsString = typeof label === 'string'
        const dateIsDate = date instanceof Date

        return hasDateAndLabel && nameIsString && dateIsDate
      }),
      default: () => [],
    },

    /**
     * A date expression of available dates in the calendar
     * Check https://vcalendar.io/date-expressions.html
     */
    availableDates: {
      type: Object,
      default: () => null,
    },

    /**
     * A date expression of disabled dates in the calendar
     * Check https://vcalendar.io/date-expressions.html
     */
    disabledDates: {
      type: [ Object, Array ],
      default: () => [],
    },

    /**
     * Set the days highlight colors as the alert color map (usually yellow)
     */
    alert: Boolean,

    /**
     * Set the default pane width (218px - narrow) to (256px - wide)
     */
    wide: Boolean,

  },

  data: () => ({ preselectStart: null }),

  computed: {
    controller () {
      const [ controller ] = (this.$refs.veeCalendar || {}).$children || []
      return controller
    },

    attributes () {
      return (this.events || []).map(({ date, label }) => ({
        dot: true,
        dates: [ date ],
        popover: { hideIndicator: true, label },
      }))
    },

    computedColumns () {
      return this.vertical ? 1 : this.panes
    },

    computedRows () {
      return this.vertical ? this.panes : 1
    },

    // minDate and maxDate don't work together with availableDates ಠ_ಠ
    computedAvailableDates () {
      return this.availableDates
        && {
          ...this.availableDates,
          ...(this.minDate ? { start: this.minDate } : {}),
          ...(this.maxDate ? { end: this.maxDate } : {})
        }
    },

    computedMaxDate () {
      return !this.availableDates ? this.maxDate : null
    },

    computedMinDate () {
      return !this.availableDates ? this.minDate : null
    },

    computedMaxPage () {
      if (!this.availableDates || !this.maxDate) return null

      const date = this.$date(this.maxDate)
      return { month: date.month() + 1, year: date.year() }
    },

    computedMinPage () {
      if (!this.availableDates || !this.minDate) return null

      const date = this.$date(this.minDate)
      return { month: date.month() + 1, year: date.year() }
    }
  },

  methods: {
    onInput (event) {
      if (event !== null) this.$emit('input', event)
    },

    onDayClick ({ date }) {
      if (this.preselectStart) {
        this.$emit('preselect', { start: this.preselectStart, end: date })
        this.preselectStart = null
      } else {
        this.preselectStart = date
        this.$emit('preselect', { start: date })
      }
    },

    checkDisabledMonth () {
      const picker = this.$refs.veeCalendar
      const days = this.controller.pages.flatMap(({ days }) => days)
      const anyValidDays = picker && days.some(({ date }) => picker.dateIsValid(date))

      if (!anyValidDays) this.controller.moveNext()
    }
  },

  mounted () {
    this.checkDisabledMonth()
  },

  created () {
    this.months = [ 'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez' ]

    this.datePickerAttrs = {
      locale: 'pt-BR',
      firstDayOfWeek: 1,
      masks: {
        weekdays: 'WWW',
        navMonths: 'MMM',
        dayPopover: 'DD/MM/YYYY'
      },
      theme: { container: { light: '' } }
    }

    // Keep the text white when selecting a date range
    // (I don't know why it works, but it does... anyways)
    this.dragAttr = { highlight: true }
  }
}
</script>

<style lang="scss">
%default-style-changes {
  .vc-arrows-container { display: none; }

  // set the background gradient color to main highlights
  .vc-highlights { overflow: visible !important; }

  .vc-highlights > .vc-day-layer > .vc-highlight {
    &.vc-rounded-full {
      background: color-var();

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: calc(50% + 4px);
        width: 60%;
        height: 60%;
        border-radius: inherit;
        filter: blur(4px);
        opacity: 0.8;
        transform: translateX(-50%) translateY(-50%) translateZ(-1px);
        transition: filter .3s, opacity .3s;
        flex: 1;
        background: color-var()
      }

      &:hover {
        opacity: 1 !important;

        &::before {
          filter: blur(7px);
          opacity: 1;
        }
      }

      &.vc-border-2,
      &.vc-border-purple-700 { border: none; }
    }

    &.vc-highlight-base-start,
    &.vc-highlight-base-middle,
    &.vc-highlight-base-end { background: color-var(text, base-05); }
  }

  // Set styles for wide/narrow pane
  &.-narrow {
    .vc-grid-container { justify-items: end; }
    .vc-grid-container.grid {
      grid-template-columns: repeat(2, minmax(218px, 1fr)) !important;
      width: fit-content;
    }

    .vc-grid-container.vc-weeks {
      grid-template-columns: repeat(7, 25px) !important;
      grid-template-rows: repeat(7, 25px) !important;

      grid-row-gap: 5px !important;
      grid-column-gap: 5px !important;
    }

    .vc-highlights > .vc-day-layer > .vc-highlight {
      &.vc-highlight-base-start,
      &.vc-highlight-base-end {
        width: calc(50% + 5px) !important;
        margin: 0 -1px !important;
      }
      &.vc-highlight-base-middle {
        width: calc(100% + 10px) !important;
        margin: 0 -1px;
      }
    }
  }

  &.-wide .vc-grid-container.vc-weeks { padding: 10px 0 0; }

  // set the alert highlight color
  &.-alert .vc-highlights > .vc-day-layer > .vc-highlight {
    &.vc-rounded-full {
      background: color-var(alert);
      &::before { background: color-var(alert); }
    }
  }

  // add border radius to gray highlights in 'range' mode
  .weekday-position-1 > .vc-h-full > .vc-highlights,
  .is-first-day > .vc-h-full > .vc-highlights {
    & > .vc-day-layer > .vc-highlight:not(.vc-rounded-full)  {
      border-top-left-radius: 15px !important;
      border-bottom-left-radius: 15px !important;
    }
  }

  .weekday-position-7 > .vc-h-full > .vc-highlights,
  .is-last-day > .vc-h-full > .vc-highlights {
    & > .vc-day-layer > .vc-highlight:not(.vc-rounded-full) {
      border-top-right-radius: 15px !important;
      border-bottom-right-radius: 15px !important;
    }
  }

  // hide the middle navigation buttons when there are more than one column
  .vc-grid-container {
    & > .vc-grid-cell > .vc-pane > .header > .nav-left,
    & > .vc-grid-cell > .vc-pane > .header > .nav-right {
      visibility: hidden;
    }

    & > .vc-grid-cell:first-child > .vc-pane > .header > .nav-left,
    & > .vc-grid-cell:last-child > .vc-pane > .header > .nav-right {
      visibility: visible;
    }
  }

  .vc-weekday {
    text-transform: uppercase;
    color: color-var(text, base-50);
    font-size: 11px;
    font-family: var(--base-font-family);
    font-weight: var(--base-font-weight) !important;
    padding-bottom: 20px !important;
  }

  .vc-day-content {
    color: color-var(text, base-50);
    font-family: var(--base-font-family);
    font-weight: var(--base-font-weight) !important;
    font-size: 14px;
    padding-top: 1px;

    // disabled days style
    &.vc-text-gray-400 {
      color: color-var(text, base-20);
      cursor: default;
      pointer-events: none;

      &:focus, &:hover { background-color: transparent; }
    }

    &.vc-text-white {
      color: #fff;

      // removes the day hover grey background for selected days
      --day-content-bg-color-hover: transparent;
      --day-content-bg-color-focus: transparent;
    }
  }

  .vc-font-bold { font-weight: 600; }

  .vc-popover-content {
    background: color-var(text, base-90);
    box-shadow: 2px 8px 16px 0 rgba(24,50,115,0.06);
    padding: 10px;
    border-style: none;
    border-radius: 5px;
    max-width: 200px;

    & > .vc-popover-caret {
      border-radius: 4px 0 4px 0;
      background: linear-gradient(135deg, color-var(text, base-90) 0 50%, transparent 50% 100%);

      // Fix caret positioning
      &.direction-bottom { margin-top: 1px; }
    }

    & > div > .vc-text-center,
    & > div > .vc-day-popover-row > .vc-day-popover-row-content {
      @include typo(body-1);
      color: rgba(#FFF, 0.8);
      text-shadow: var(--base-text-shadow);
      line-height: 15px !important;
    }

    &.fade-enter-active,
    &.fade-leave-active,
    &.slide-fade-enter-active,
    &.slide-fade-leave-active {
      transition: opacity !important;
    }
  }

  .vc-dot {
    background: color-var(negative)
  }
}

.c-calendar.vc-container {
  @extend %default-style-changes;

  --column-gap: 20px;
  --row-gap: 20px;

  &.-wide { --calendar-max-width: calc(256px + var(--column-gap)); }
  &.-narrow { --calendar-max-width: calc(218px + var(--column-gap)); }

  max-width: calc((var(--columns) * var(--calendar-max-width)) - var(--column-gap));
  min-width: 0;
  width: 100%;

  & > .vc-w-full > .vc-grid-container {
    column-gap: var(--column-gap) !important;
    row-gap: var(--row-gap) !important;
  }

  & > .vc-w-full > .vc-grid-container > .vc-grid-cell > .vc-pane > .header {
    margin: 0 6px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .title {
      text-transform: uppercase;
      font-size: 14px;
      font-family: var(--title-font-family);
      font-weight: var(--title-font-weight);
      color: var(--title-font-color);
      cursor: default;
      user-select: none;
    }

    & > .nav-left,
    & > .nav-right {
      font-family: var(--title-font-family);
      font-weight: var(--title-font-weight);
      color: color-var(text, base-50);
      text-transform: uppercase;
      font-size: 10px;
      background: transparent;
      border: none;
      padding: { right: 0; left: 0; }
      user-select: none;

      & > .c-icon,
      & > .tag { vertical-align: middle; }

      & > .c-icon { @include icon-color(text, base-50); }

      &:hover {
        color: color-var(text, base-80);

        & > .c-icon { @include icon-color(text, base-80); }
      }

      &.-disabled {
        pointer-events: none;
        visibility: hidden;
      }
    }
  }
}
</style>
