<template>
  <c-modal
    :class="[ 'form-modal', { '-alternative-header': alternativeHeader } ]"
    is-opened
    lock-el=".c-form .c-shadowed .wrapper"
    :title="title"
    :actions-color="actionsColor"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div v-if="alternativeHeader" slot="header" class="title">
      <c-flag primary :icon="headerIcon" class="flag" />

      <span class="text">{{ title }}</span>
    </div>

    <c-form
      class="form"
      :no-listeners="noListeners"
      :label-left="labelLeft"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />

      <slot slot="actions" name="actions">
        <c-button
          v-if="!isMobile && showCancelButton"
          class="action"
          flat
          type="button"
          :disabled="isLoading"
          v-bind="{ [actionsColor]: true }"
          @click.stop.prevent="$emit('close')"
        >
          {{ cancelButtonText }}
        </c-button>

        <c-button
          type="submit"
          class="action"
          primary
          :size="isMobile ? 60 : 40"
          :full-width="isMobile"
          :loading="isLoading"
          v-bind="{ [actionsColor]: true }"
        >
          {{ submitButtonText }}
        </c-button>
      </slot>
    </c-form>
  </c-modal>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CFormModal',

  mixins: [ MediaQuery ],

  props: {
    name: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    cancelButtonText: {
      type: String,
      default: 'Cancelar'
    },

    submitButtonText: {
      type: String,
      default: 'Salvar'
    },

    showCancelButton: {
      type: Boolean,
      default: true
    },

    labelLeft: Boolean,

    isLoading: Boolean,

    headerIcon: {
      type: String,
      default: '',
    },

    alternativeHeader: Boolean,

    actionsColor: {
      type: String,
      validator: color => [ 'primary', 'success', 'error' ].includes(color),
      default: () => 'primary'
    },

    noListeners: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.form-modal.-alternative-header > .wrapper > .modal > .header > .title {
  display: flex;
  align-items: center;

  & > .flag { padding: 10px; }

  & > .text {
    @include typo(h2);
    margin-left: 15px;
  }
}

.form-modal > .wrapper {
  overflow: hidden;
  @include responsive (tablet, desktop) { overflow: auto; }

  & > .modal {
    border-radius: 0;

    @include responsive (tablet, desktop) {
      min-height: 490px;
      border-radius: 5px;
      max-width: 960px;
    }

    @include responsive (xs-mobile, mobile) {
      overflow: initial;
    }

    & > .header {
      padding: 10px 10px 20px 20px;
      @include responsive (tablet, desktop) { padding: 20px; }

      & > .title {
        margin-top: 10px;
        @include responsive (tablet, desktop) { margin-top: 0; }
      }
    }

    & > .content {
      display: flex;
      flex: 1;
      padding: 0;
      height: 100%;
      display: flex;

      @include shadowed(100px, #fdfdfd, true, false);
      @include responsive (tablet, desktop) { padding-bottom: 50px; }

      & > .form {
        width: 100%;

        @include responsive (tablet, desktop) {
          min-height: 305px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        & > .c-shadowed {
          & > .wrapper {
            // this avoids first input field highlight cutting
            padding-top: 2px;

            -webkit-overflow-scrolling: touch;
            @include responsive (xs-mobile, mobile) {
              & > .fields { padding-bottom: 60px; }
              & > .field > .selectable { height: 20px; width: 20px; }
            }

            & > .fields{
              @include responsive (tablet, desktop) {
                margin-left: 210px;
              }

              & .field.radio {
                & > .inner { grid-row-gap: 10px; }
                & > .inner > .option > .borderline { height: 20px; width: 20px; }
                & > .inner > .option > .borderline > .selected { height: 10px; width: 10px; }
                & > .inner > .option > .label { font-size: 12px; margin-left: 15px; }
              }
            }
          }
        }

        & > .actions {
          margin-top: 30px;
          justify-content: center;

          & > .action:not(:last-child) { margin-right: 20px }

          @include responsive (xs-mobile, mobile) {
            margin-top: 0;
            height: 60px;
            display: block;

            & > .action {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
