export const MediaQuery = {
  inject: [ 'getIsXsMobile', 'getIsMobile', 'getIsTablet', 'getIsSafari', 'getIsIOS' ],

  computed: {
    isXsMobile () {
      return this.getIsXsMobile()
    },

    isMobile () {
      return this.getIsMobile()
    },

    isTablet () {
      return this.getIsTablet()
    },

    isSafari () {
      return this.getIsSafari()
    },

    isIOS () {
      return this.getIsIOS()
    }
  }
}
