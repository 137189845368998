<template functional>
  <span class="historical-change-update-value">
    <div class="old">
      <span class="span" v-html="props.value.oldValue || 'campo vazio'" />
    </div>

    <div class="new">
      <span class="span" v-html="props.value.newValue || 'campo vazio'" />
    </div>
  </span>
</template>

<script>
export default {
  name: 'HistoricalChangeUpdateValue',

  props: { value: { type: Object, default: () => ({}) } }
}
</script>

<style lang="scss">
.historical-change-update-value {
  & > .old, & > .new {
    display: inline;

    @include responsive(xs-mobile, mobile) {
      display: block;
    }
  }

  & > .old {
    margin-right: 20px;
    color: color-var(text, base-50);
    text-decoration: line-through;

    @include responsive(xs-mobile, mobile) {
      margin-right: 0;
      margin-bottom: 6px;
    }
  }
}
</style>
