<template functional>
  <span v-html="props.value.newValue || 'campo vazio'" />
</template>

<script>
export default {
  name: 'HistoricalChangeInclusionValue',

  props: { value: { type: Object, default: () => ({}) } }
}
</script>
