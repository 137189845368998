<template>
  <div class="c-color-picker">
    <canvas
      ref="canvas"
      class="canvas"
      @touchstart="onCursorDown"
      @touchmove="onCursorMove"
      @mousedown="onCursorDown"
      @mousemove="onCursorMove"
    />

    <div class="footer">
      <span class="preview" :style="{ backgroundColor: value }" />

      <div class="hue">
        <input
          class="slider"
          type="range"
          min="0"
          max="360"
          v-model="hue"
          @input="updateColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { HSLtoRGB, RGBtoHex, HextoHSL, isHex } from '@convenia/helpers'

export default {
  name: 'CColorPicker',

  data: () => ({
    timer: null,
    canvas: null,
    hue: 0,
    width: 0,
    height: 0,
    dragging: false,
    position: { x: 1, y: 0 }
  }),

  props: {
    value: {
      type: String,
      default: '#FF0000'
    },
    pointerSize: {
      type: Number,
      default: 8
    }
  },

  computed: {
    context () { return this.canvas.getContext('2d') }
  },

  mounted () {
    this.canvas = this.$refs.canvas
    this.setColor(this.value)

    document.addEventListener('mouseup', this.onCursorUp)
    document.addEventListener('touchend', this.onCursorUp)
    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy () {
    document.removeEventListener('mouseup', this.onCursorUp)
    document.removeEventListener('touchend', this.onCursorUp)
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    draw () {
      const { context, width, height } = this

      context.fillStyle = HSLtoRGB(this.hue)
      context.fillRect(0, 0, width, height)

      const gradients = {
        white: context.createLinearGradient(0, 0, width, 0),
        black: context.createLinearGradient(0, 0, 0, height)
      }

      gradients.white.addColorStop(0, 'rgba(255, 255, 255, 1)')
      gradients.white.addColorStop(1, 'rgba(255, 255, 255, 0)')
      gradients.black.addColorStop(0, 'rgba(0, 0, 0, 0)')
      gradients.black.addColorStop(1, 'rgba(0, 0, 0, 1)')

      context.fillStyle = gradients.white
      context.fillRect(0, 0, width, height)

      context.fillStyle = gradients.black
      context.fillRect(0, 0, width, height)
    },

    drawPointer () {
      const { context, width, height } = this
      const { x, y } = this.position

      context.beginPath()
      context.arc(x * width, y * height, this.pointerSize, 2 * Math.PI, false)
      context.fillStyle = 'rgba(255, 255, 255, .1)'
      context.fill()
      context.lineWidth = 1.5
      context.strokeStyle = 'white'
      context.stroke()
    },

    update () {
      const { offsetWidth: width, offsetHeight: height } = this.canvas

      this.setSize(width, height)
      this.setHue(this.value)
      this.updatePosition()

      this.draw()
      this.drawPointer()
    },

    updateColor () {
      this.draw()
      this.$emit('input', this.getCurrentColor())
      this.drawPointer()
    },

    updatePosition () {
      const { width, height } = this
      const [ , s, l ] = HextoHSL(this.value)

      const saturation = s / 100
      const luminance = l / 100

      // https://en.wikipedia.org/wiki/HSL_and_HSV#HSL_to_HSV
      const v = luminance + saturation * Math.min(luminance, 1 - luminance)
      const sv = v === 0 ? 0 : 2 * (1 - luminance / v)

      const x = sv * width
      const y = (1 - v) * height

      this.setPosition(x, y)
    },

    getCurrentColor () {
      const { context, width, height } = this
      const { x, y } = this.position

      const deltaX = (x * width) - 1
      const deltaY = (y * height) + 1

      const { data } = context.getImageData(
        deltaX < 0 ? 0 : deltaX,
        deltaY < 0 ? 0 : deltaY,
        1,
        1
      )

      return RGBtoHex(`rgb(${data[0]},${data[1]},${data[2]})`)
    },

    setHue (value) {
      const color = isHex(value) ? value : RGBtoHex(value)
      const [ h ] = HextoHSL(color)

      this.hue = h
    },

    setColor (value) {
      const color = isHex(value) ? value : RGBtoHex(value)

      this.$emit('input', color)
      this.$nextTick(this.update)
    },

    setPosition (x, y) {
      const { width, height } = this

      this.position.x = x / width
      this.position.y = y / height
    },

    setSize (width, height) {
      const { x, y } = this.position

      this.width = width
      this.height = width * 0.75

      this.$refs.canvas.width = width
      this.$refs.canvas.height = width * 0.75

      return { width, height, x, y }
    },

    getPointPosition (e) {
      const isMobile = !!e.touches
      const rect = this.$el.getBoundingClientRect()

      const x = isMobile ? e.touches[0].clientX - rect.left : e.offsetX
      const y = isMobile ? e.touches[0].clientY - rect.top : e.offsetY

      return { x, y }
    },

    onCursorDown (e) {
      const { x, y } = this.getPointPosition(e)

      this.dragging = true
      this.setPosition(x, y)
      this.updateColor()
    },

    onCursorMove (e) {
      const { x, y } = this.getPointPosition(e)

      if (!this.dragging) return

      this.setPosition(x, y)
      this.updateColor()

      e.preventDefault()
    },

    onCursorUp () {
      this.dragging = false
    },

    onResize () {
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        const { width, height, x, y } = this.setSize(
          this.canvas.offsetWidth,
          this.canvas.offsetHeight
        )

        this.setPosition(x * width, y * height)
        this.draw()
        this.drawPointer()
      }, 20)
    }
  },

  watch: {
    value () {
      this.setHue(this.value)
      this.updatePosition()

      this.draw()
      this.drawPointer()
    }
  }
}
</script>

<style lang="scss">
.c-color-picker {
  width: 100%;
  & > .canvas {
    width: 100%;
    min-height: 100px;
    display: block;
    margin-bottom: 10px;
    border-radius: 4px;
    &:hover { cursor: crosshair; }
  }

  & > .footer {
    display: flex;
    align-items: center;

    & > .preview {
      width: 40px;
      height: 40px;
      margin-right: 20px;
      border-radius: 40px;
      display: inline-block;
      flex-shrink: 0;
      box-shadow: 0 0 8px rgba(black, .2);
    }

    & > .hue {
      display: flex;
      flex: 1;
      align-items: center;
      border-radius: 8px;
      background: linear-gradient(
        to right,
        #F00 0%,
        #FF0 16.66%,
        #0F0 33.33%,
        #0FF 50%,
        #00F 66.66%,
        #F0F 83.33%,
        #F00 100%
      );

      & > .slider {
        flex: 1;
        height: 10px;
        border-radius: 5px;
        background: transparent;
        outline: none;
        cursor: pointer;
        -webkit-appearance: none;

        &::-webkit-slider-thumb {
          background: white;
          border-radius: 14px;
          width: 14px;
          height: 14px;
          box-shadow: 0 0 5px rgba(black, .2);
          -webkit-appearance: none;
        }
      }
    }
  }
}
</style>
