import * as types from '@types'
import * as services from '@services/systemField'

const state = {
  companyId: null,
  requiredFields: {}
}

const getters = {
  requiredFields: state => state.requiredFields,

  [types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS]: ({ requiredFields }) =>
    (key, filterOut = []) => requiredFields[key]
      ?.filter(field => !filterOut.includes(field))
        || []
}

const mutations = {
  [types.EMPLOYEE_SET_REQUIRED_SYSTEM_FIELDS]: (state, { companyId, data }) => {
    const reducer = (acc, { sectionKey, fields }) => ({ ...acc, [sectionKey]: fields })

    state.companyId = companyId

    state.requiredFields = {
      ...(state.company === companyId ? state.requiredFields : {}),
      ...data.reduce(reducer, {}),
    }
  },

  [types.EMPLOYEE_REFRESH_REQUIRED_SYSTEM_FIELDS]: (state) => {
    state.companyId = null
    state.requiredFields = {}
  }
}

const actions = {
  [types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS]: async ({ state, commit, getters }, variables) => {
    const employeeId = getters.getEmployeeUuid
    const companyId = getters.getCompanyUuid

    const { areas } = variables || {}

    const [ err, data ] = await services.getRequiredSystemFields(employeeId, companyId)

    if (!err) commit(types.EMPLOYEE_SET_REQUIRED_SYSTEM_FIELDS, { companyId, data, areas })

    return [ err, state.requiredFields ]
  },

  [types.EMPLOYEE_REFRESH_REQUIRED_SYSTEM_FIELDS]: ({ commit, dispatch }, variables) => {
    commit(types.EMPLOYEE_REFRESH_REQUIRED_SYSTEM_FIELDS)

    return dispatch(types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS, variables)
  }
}

export default { state, getters, mutations, actions }
