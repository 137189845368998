import { createGqlRequest } from '@convenia/utils'

export default config => {
  let request = () => {}

  return {
    setToken (token) {
      const headers = {
        'Content-Type': 'application/json',
        'BCV': true,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      }

      request = createGqlRequest({
        headers,
        ...config
      })
    },

    request: (...args) => request(...args)
  }
}
