<template>
  <div :style="backgroundStyle" class="c-bottom-bar">
    <div class="bottom-bar-content">
      <div class="information">
        <!-- @slot Information slot. -->
        <slot name="information">
          <div class="step">
            <c-step v-bind="$attrs" />
          </div>

          <div class="description">
            <!-- @slot The information description slot. -->
            <slot name="description" />
          </div>
        </slot>
      </div>

      <div class="actions">
        <!-- @slot The actions slot. -->
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * A dinamic 'footer' component, mainly used in the Convenia SAAS project.
 */
export default {
  name: 'CBottomBar',

  props: {
    /**
     * The background color.
     */
    color: {
      type: [ String, Array ],
      validator: color => (typeof color === 'string' || Array.isArray(color)) && !!color.length,
      required: true,
    }
  },

  computed: {
    backgroundStyle () {
      const isValidArray = Array.isArray(this.color) && !!this.color.length

      const background = (isValidArray && 'linear-gradient(90deg, ' + this.color.join(', ') + ')') || this.color

      return { background }
    }
  }
}
</script>

<style lang="scss">
.c-bottom-bar {
  position: fixed;
  z-index: var(--z-index-4);
  left: 0;
  bottom: 0;
  width: 100%;
  background: color-var(text, base-90);
  box-shadow: -1px -1px 16px 0 rgba(var(--box-shadow-color), var(--box-shadow-alpha));

  & > .bottom-bar-content {
    display: flex;
    padding: 5px 40px;
    justify-content: space-between;

    & > * + * { margin-left: auto; }

    & > .information {
      display: flex;
      align-items: center;

      & > .step {
        display: flex;
        align-items: center;
        margin-right: 5px;
      }

      & > .description {
        font-family: var(--base-font-family);
        font-size: 14px;
        max-width: 550px;
        color: rgba(#FFF, .8);

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        & > .strong {
          font-weight: 600;
          color: #FFF;
        }
      }
    }

    & > .actions {
      display: flex;
      align-items: center;
      position: relative;
      flex: 1;
      width: fit-content;
      justify-content: flex-end;

      margin-left: 10px;
      padding-left: 40px;
      flex-grow: 1;

      @include responsive (tablet, desktop) {
        margin-left: 20px;

        & > .c-button {
          width: 100%;
          max-width: 180px;
          flex-grow: 1;
          flex-shrink: 0;
        }
      }

      & > :first-child {
        position: relative;

        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          top: 50%;
          left: -40px;
          transform: translateY(-50%);
          height: 30px;
          border-left: 1px solid rgba(#FFF, .1);
          filter: unset;
        }
      }

      & > *:not(:last-child) { margin-right: 20px; }
    }
  }
}
</style>
