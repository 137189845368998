<template>
  <div class="c-grid" :style="gridVariables">
    <c-add
      v-if="hasAction"
      class="action"
      :text="actionText"
      :squared="isMobile && displayMobileAction"
      v-on="$listeners"
    />

    <!-- @slot The grid items -->
    <slot />
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CGrid',

  mixins: [ MediaQuery ],

  props: {
    /**
     * Number of columns
     */
    cols: {
      type: [ String, Number ],
      default: null,
    },

    /**
     * Column min width
     */
    minWidth: {
      type: [ String, Number ],
      default: 353
    },

    /**
     * Elements height
     */
    height: {
      type: [ String, Number ],
      default: 'auto'
    },

    /**
     * Column gap
     */
    columnGap: {
      type: [ String, Number ],
      default: 20
    },

    /**
     * Row gap
     */
    rowGap: {
      type: [ String, Number ],
      default: 20
    },

    /**
     * Mobile row gap
     */
    mobileRowGap: {
      type: [ String, Number ],
      default: 10
    },

    /**
     * Whether the last child element is expandable
     */
    isExpandable: Boolean,

    /**
     * Whether the first child is an action
     */
    hasAction: Boolean,

    /**
     * The text of the action button
     */
    actionText: {
      type: String,
      default: '',
    },

    /**
     * Wait time before updating on resize
     */
    resizeDelay: {
      type: [ String, Number ],
      default: 0
    },

    /**
     * Display action when is mobile
     */
    displayMobileAction: Boolean
  },

  data () {
    return {
      elements: 0,
      columns: 1,
      observer: null
    }
  },

  computed: {
    gridVariables () {
      return {
        '--grid-columns': this.columns,
        '--grid-column-gap': `${this.columnGap}px`,
        '--grid-row-gap': `${this.rowGap}px`,
        '--grid-row-height': Number.isNaN(Number(this.height)) ? this.height : `${this.height}px`,
        '--grid-mobile-row-gap': `${this.mobileRowGap}px`,
        '--grid-expandable-column-start': (this.elements % this.columns),
        '--display-mobile-action': this.displayMobileAction ? 'flex' : 'none'
      }
    }
  },

  methods: {
    getElementCount () {
      if (this.$el) this.elements = this.$el.childElementCount
    },

    setColumns (delay) {
      setTimeout(() => {
        if (this.cols) {
          this.columns = this.cols
          return
        }

        if (this.$el && this.minWidth) {
          const margin = +this.columnGap
          const cardSpace = +this.minWidth + margin
          const width = this.$el.getBoundingClientRect().width + margin

          this.columns = (Math.floor(width / cardSpace) || 1)
        }
      }, !delay ? 0 : this.resizeDelay)
    }
  },

  updated () {
    this.setColumns(0)
  },

  mounted () {
    this.setColumns(0)
    this.getElementCount()

    if (this.isExpandable) this.$el.lastChild.classList.add('expandable')

    if (!this.cols) {
      window.addEventListener('resize', this.setColumns)

      this.observer = new MutationObserver(() => this.getElementCount())
      this.observer.observe(this.$el, { childList: true })
    }
  },

  beforeDestroy () {
    if (!this.cols) {
      this.observer.disconnect()
      window.removeEventListener('resize', this.setColumns)
    }
  }
}
</script>

<style lang="scss">
.c-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: var(--grid-mobile-row-gap);
  width: 100%;

  & > * {
    min-width: 0;
    height: var(--grid-row-height);
  }

  & > .action {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px dashed color-var(text, base-20);

    @include responsive (xs-mobile, mobile) {
      display: var(--display-mobile-action);
    }
  }

  @include responsive (tablet, desktop) {
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    grid-column-gap: var(--grid-column-gap);
    grid-row-gap: var(--grid-row-gap);

    & > .expandable {
      grid-column-start: var(--grid-expandable-column-start);
      grid-column-end: calc(var(--grid-columns) + 1);
    }
  }
}
</style>
