<template>
  <c-info-header
    class="historical-changes-default-header"

    title-component="c-employee-info"
    v-bind="{ ...infoHeaderProps, ...$attrs }"

    :opened="active"
    :collapse="!isMobile"

    v-on="$listeners"
  >
    <template #before-actions>
      <slot name="header-before-actions" :headerContent="{ ...$attrs, active }" />
    </template>

    <template #content>
      <slot name="header-content" :headerContent="{ ...$attrs, active }" />
    </template>
  </c-info-header>
</template>

<script>
import { MediaQueryRoot } from '@convenia/mixins'

export default {
  name: 'HistoricalChangesBaseHeader',

  mixins: [ MediaQueryRoot ],

  props: {
    headerData: {
      required: true,
      type: Object
    },
    active: Boolean,
    highlight: Boolean
  },

  computed: {
    infoHeaderProps () {
      return this.active || this.highlight
        ? this.headerData
        : { ...this.headerData, theme: 'default' }
    },
  }
}
</script>

<style lang="scss">
.historical-changes-default-header {
  padding: 15px 20px;
}
</style>
