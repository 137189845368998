<template>
  <c-selectable
    position="left"
    class="c-checkbox"
    :selected="value"
    :disabled="disabled"
    :data-testid="`checkbox-${fieldName}`"
    v-bind="$attrs"
    @toggle="$emit('input', !value)"
  >
    <p v-if="label" class="label" v-html="label" />

    <div
      v-if="tooltip"
      v-tooltip.dark="tooltip"
      class="tooltip-target"
    />
  </c-selectable>
</template>

<script>
export default {
  name: 'CCheckbox',

  props: {
    /**
     * The label (basically the text that goes inside) of the checkbox.
     * The value for the label will be used as raw HTML, that is, you
     * can use <b>, <em> or <i> tags to stylize the content.
     */
    label: {
      type: String,
      default: '',
    },

    /**
     * The value of the checbox, can be either `true` or `false`.
     */
    value: {
      required: true,
      validator: prop => typeof prop === 'boolean' || !prop
    },

    /**
     * Tooltip directive value
     */
    tooltip: {
      type: [ Object, String ],
      default: null,
    },

    /**
     * The name of the field.
     */
    fieldName: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.c-checkbox {
  display: flex;

  &.-disabled > .label { color: unset; }

  & > .tooltip-target {
    position: absolute;
    left: 0;
    height: 100%;
    width: var(--icon-size);
  }

  & > .label {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 400;
    color: color-var(text, base-80);

    html.ios.iphone & {
      line-height: 19px;
      min-height: 19px;
    }

    line-height: 20px;
    min-height: 20px;

    @include responsive (tablet, desktop) {
       margin-left: 20px;

       line-height: 25px;
       min-height: 25px;
    }

    & > b {
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
    }
  }
}
</style>
