/**
 * @mixin
 */
export const CloseOnEsc = {
  props: {
    /**
     * Whether to display the component or not.
     */
    isOpened: {
      type: Boolean,
      required: true
    },

    // By default, we set `overflow: hidden` in the body element
    // when the modal is open, but in some instances, when the modal
    // is inside a fixed element for example, this won't work.
    // In this case we need to pass the id of the element that should
    // be targeted instead.

    /**
     * The element to set the property { overflow: hidden } to,
     * by default it targets the body element on the page, but in
     * some cases it is necessary to block the overflow of another
     * parent element.
     */
    scrollEl: {
      type: String,
      default: 'body'
    }
  },

  watch: {
    isOpened (newValue, oldValue) {
      if (newValue === oldValue) return
      this.$checkOverflow()
    }
  },

  methods: {
    /**
     * Checks the overflow... I guess.
     */
    $checkOverflow () {
      this.isOpened ? this.$open() : this.$close()
    },

    /**
     * Adds { overflow: hidden } `scrollEl` | body and binds closeOnEsc.
     */
    $open () {
      const styles = { overflow: 'hidden' }
      const el = document.querySelector(this.scrollEl)

      Object.assign(el.style, styles)
      window.addEventListener('keydown', this.$closeOnEsc)
    },

    /**
     * Removes { overflow: hidden } from `scrollEl` | body and binds closeOnEsc.
     */
    $close () {
      const styles = { overflow: '' }
      const el = document.querySelector(this.scrollEl)

      Object.assign(el.style, styles)
      window.removeEventListener('keydown', this.$closeOnEsc)
    },

    /**
     * Emits the close event and calls the $close method.
     */
    $emitClose () {
      /**
       * Emitted when the user either presses the close button,
       * clicks outisde the element, or presses the ESC key.
       * @event close
       * @type {null}
       */
      this.$emit('close')
      this.$close()
    },

    /**
     * Checks if user has pressed the ESC key, and closes
     * the component if so.
     * @param {KeyboardEvent} ev - The user keyboard event.
     */
    $closeOnEsc (ev) { if (ev.keyCode === 27) this.$emitClose() }
  },

  mounted () {
    this.$checkOverflow()
  },

  beforeDestroy () { this.$close() }
}

export default CloseOnEsc
