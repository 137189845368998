<template>
  <div class="documents-container">
    <transition name="view" mode="out-in">
      <c-loader v-if="loading" :size="isMobile ? 79 : 99" />

      <div v-else class="wrapper">
        <c-title dark-text>
          Documentos
        </c-title>

        <div class="documents">
          <document-card
            v-for="(document, key) in documents"
            :key="`document-card-${document.id || key}`"
            :title="document.title"
            :files="(document.files || []).length"
            :error="document.status == 'error'"
            :success="document.status == 'success'"
            @click="onEdit(key)"
          />
        </div>

        <personal-documents
          v-if="editing && !isCustomDocument"
          :value="active"
          @close="onClose"
        />

        <custom-documents
          v-if="editing && isCustomDocument"
          :value="active"
          @close="onClose"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import { mapActions, mapState, mapGetters } from 'vuex'
import * as types from '@types'

import DocumentCard from '@components/DocumentCard'
import PersonalDocuments from './PersonalDocuments'
import CustomDocuments from './CustomDocuments'

export default {
  name: 'DocumentsStepper',

  mixins: [ MediaQuery ],

  components: {
    PersonalDocuments,
    CustomDocuments,
    DocumentCard,
  },

  computed: {
    ...mapState({
      requiredDocuments: state => state.admission.requiredDocuments,
      personal: state => state.personalInfo.personal,
      employeeId: state => state.admission.employeeUuid,
      customDocuments: state => state.document.customDocuments
    }),

    ...mapGetters({
      documents: types.DOCUMENT_GET_DOCUMENTS,
    }),

    isCustomDocument () {
      return Object.keys(this.customDocuments)
        .findIndex(id => id === this.active) >= 0
    },

    isSubmitting: {
      get () { return this.$store.state.admission.submiting },
      set (value) { this.$store.commit(types.ADMISSION_SUBMITING, value) }
    },
  },

  data: () => ({
    loading: true,
    editing: false,
    active: null,
  }),

  mounted () {
    this.$root.$on('next', this.onNext)
    this.$root.$on('previous', this.onSave)
    this.init()
  },

  beforeDestroy () {
    this.$root.$off('next', this.onNext)
    this.$root.$off('previous', this.onSave)
  },

  methods: {
    ...mapActions({
      getDocuments: types.DOCUMENT_GET_DOCUMENTS,
      getCustomDocuments: types.DOCUMENT_CUSTOM_DOCUMENTS,
      getCustomFields: types.DOCUMENT_CUSTOM_FIELDS,
      getPersonal: types.EMPLOYEE_PERSONAL_INFO,
      validateDocuments: types.DOCUMENT_VALIDATE_DOCUMENTS,
      verifyDocuments: types.DOCUMENT_VERIFY_DOCUMENTS,
    }),

    async init () {
      await this.getDocuments()
      await this.getCustomDocuments()
      await this.getCustomFields()
      await this.getPersonal()
      await this.verifyDocuments()

      this.loading = false
    },

    async onEdit (key) {
      this.active = key
      this.editing = true
    },

    async onNext () {
      const valid = await this.validateDocuments()

      if (!valid) return

      this.$emit('next')
    },

    onSave () {
      this.$emit('previous')
    },

    onClose () {
      this.clear()
    },

    clear () {
      this.active = null
      this.editing = false
    }
  }
}
</script>

<style lang="scss">
.documents-container {
  position: relative;

  & > .wrapper {
    & > .c-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & > .c-title {
      padding: 30px 0 20px;
    }

    & > .documents {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 20px;
      row-gap: 20px;
      & > .document-card { min-width: 0; }
    }
  }

  @include responsive (tablet, desktop) {
    & > .wrapper {
      & > .documents {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @include responsive (xs-mobile, mobile) {
    & > .wrapper {
      & > .c-title {
        display: none;
      }

      & > .documents {
        padding: 20px 10px;
        row-gap: 10px;
      }
    }
  }
}
</style>
