<template>
  <c-input-container
    class="c-select"
    v-bind="containerAttributes"
  >
    <c-select-raw
      ref="select"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </c-input-container>
</template>

<script>
import CSelectRaw from './fragments/CSelectRaw'

export default {
  name: 'CSelect',

  components: { CSelectRaw },

  computed: {
    containerAttributes () {
      return {
        ...this.$attrs,
        name: this.name
      }
    }
  }
}
</script>
