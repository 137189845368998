import cookie from 'js-cookie'

/**
 * Clears storage data (cookies, localStorage,
 * sessionStorage) from the brwoser

 * @param {String} options.domain - A String indicating a
 * valid domain where the cookie should be visible.
 * The cookie will also be visible to all subdomains.
 * Default: Cookie is visible only to the domain or
 * subdomain of the page where the cookie was created.

 * @param {String} options.path - A String indicating the
 * path where the cookie is visible. Default value is "/"
 * if nothing is provided

 * @returns {void}
 */
export const clearBrowserData = (options) => {
  localStorage.clear()
  sessionStorage.clear()

  document.cookie.split(';').forEach(appCookie =>
    cookie.remove((appCookie.split('=')[0] || '')
      .trim(), options))
}
