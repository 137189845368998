const regex = /(auto|scroll)/

const style = (node, prop) => getComputedStyle(node, null).getPropertyValue(prop)

const scroll = (node) => regex.test(
  style(node, 'overflow')
    + style(node, 'overflow-y')
    + style(node, 'overflow-x'))

export const getScrollParent = (node) => !node || node === document.body
  ? document.scrollingElement
  : scroll(node)
    ? node
    : getScrollParent(node.parentNode)
