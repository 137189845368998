import { PERSONAL_AREA } from '@content/consts/Personal'
import { getAdditionalFields } from '@services/systemField'
import { mapFiles, getUploadEndpoint } from '@services/upload'
import * as set from '@graphql/mutations'

import { is } from '@convenia/helpers'
import * as middlewareGet from '@graphql/middleware/queries'
import middleware from '@graphql/middleware'

const mapBankAccount = (account, employeeId) => ({
  ...account,
  bank: account?.bankId,
  accountType: account?.accountTypeId,
  modality: account?.modality,
  files: mapFiles(account.files || [], employeeId)
})

export async function getOptions () {
  const [ err, data ] = await middleware(middlewareGet.GetBankAccountOptions)

  const mapOption = option => ({ ...option, id: +option.id })
  const options = {
    bankAccount: {
      bankList: (data.bankList || []).map(mapOption),
      bankAccountTypes: (data.bankAccountTypes || []).map(mapOption),
      modality: data.modality
    }
  }

  return [ err, options ]
}

export async function getBankAccount (getters) {
  const employeeId = getters.getEmployeeUuid
  const [ err, employee ] = await middleware(middlewareGet.GetBankAccount, { employeeId })

  const data = (employee?.bankAccounts || [])
    .map(bankAccount => mapBankAccount(bankAccount, employeeId))

  return [ err, data ]
}

export async function setBankAccount (getters, variables) {
  const isCreating = is(variables.id, 'Number')
  const mutationName = isCreating ? 'CreateBankAccount' : 'UpdateBankAccount'

  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await middleware(set[mutationName], {
    input: {
      ...(!isCreating
        ? { id: variables.id }
        : {}),

      employee_id: employeeId,
      bank_id: +variables.bank || null,
      agency: variables.agency,
      account: variables.account,
      digit: variables.accountDigit,
      account_type_id: +variables.accountType || null,
      modality: variables.modality,
      pix: variables.pix,
      additional: {
        sectionId: PERSONAL_AREA.BANK_ACCOUNTS,
        fields: getAdditionalFields(variables.customFields, variables)
      }
    }
  })

  const bankAccount = isCreating ?
    mapBankAccount(data?.bankAccount || {}, employeeId) :
    variables
  const endpoint = getUploadEndpoint(employeeId, bankAccount.id, 'bank_account')

  return [ err, bankAccount, endpoint ]
}

export async function deleteBankAccount (getters, variables) {
  if (typeof variables.id !== 'string') return [ false, null ]

  const employeeId = getters.getEmployeeUuid
  const [ err ] = await middleware(set.DeleteBankAccount, {
    input: {
      id: variables.id,
      employee_id: employeeId
    }
  })

  return [ err, null ]
}
