<template>
  <div class="personal-info-container">
    <transition name="view" mode="out-in">
      <c-loader v-if="loading" :size="isMobile ? 79 : 99" />

      <div v-else class="forms">
        <form-group
          ref="formGroup"
          class="form"
          :label-left="!isMobile"
          :schemas="personalInfo"
          :data="data"
          :options="options"
          :disabled="submiting"
          :required-fields="requiredFields"
          @sync:personal="onSyncPersonal"
        />

        <form-group
          v-if="!isNotForeign"
          ref="formGroupForeign"
          class="form"
          :label-left="!isMobile"
          :schemas="personalInfoForeign"
          :data="dataPersonalForeign"
          :options="options"
          :disabled="submiting"
          :required-fields="requiredFields"
          @sync:foreign="onSyncPersonalForeign"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import * as types from '@types'
import FormGroup from '@components/FormGroup'
import personalSchemas, { isNotForeign } from '@forms/personalInfo'
import { MediaQuery } from '@convenia/mixins'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PersonalInfo',

  mixins: [ MediaQuery ],

  components: {
    FormGroup
  },

  computed: {
    ...mapState({
      personal: state => state.personalInfo.personal,
      foreign: state => state.personalInfo.foreign,
      handicap: state => state.personalInfo.handicap,
      realEstate: state => state.personalInfo.realEstate,
      countries: state => state.location.countries,
      states: state => state.location.states,
      customFields: state => state.personalInfo.customFields
    }),

    ...mapGetters({
      getRequiredFields: types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS
    }),

    options () {
      const { options } = this.$store.state.personalInfo || {}
      const { residenceTime } = (this.foreignData || {})

      const undetermined = [ 1, 2, 3, 8 ]
      const determined = [ 4, 5, 2, 6, 7, 8 ]
      const { entryConditions } = options?.personal || {}

      const entryOptions = entryConditions?.reduce((acc, cur) => {
        if (residenceTime === 1 && undetermined.includes(cur.id))
          acc.push(cur)
        if (residenceTime === 2 && determined.includes(cur.id))
          acc.push(cur)
        return acc
      }, [])

      return {
        ...options,
        foreign: {
          ...options.personal,
          countries: this.countries,
          entryConditions: entryOptions
        },
        personal: {
          ...options.personal,
          countries: this.countries,
          states: this.states,
          cities: this.cities
        }
      }
    },

    data () {
      return {
        personal: { ...this.personal },
        handicap: this.handicap,
        realEstate: this.realEstate,
      }
    },

    dataPersonalForeign () {
      return {
        foreign: { ...this.foreign },
      }
    },

    personalInfo () {
      const { personal, handicap, realEstate } = personalSchemas(this)

      // Handicap dynamic disable
      const customHandicapFields = Object.entries(this.customFields.handicap.schema)
        .reduce((acc, [ key, value ]) => ({
          ...acc,
          [key]: { ...value, isDisabled: formData => !formData.hasDeficiency }
        }), {})

      // Real estate dynamic disable
      const customRealEstateFields = Object.entries(this.customFields.realEstate.schema)
        .reduce((acc, [ key, value ]) => ({
          ...acc,
          [key]: { ...value, isDisabled: formData => !formData.hasRealEstate }
        }), {})

      const personalFields = { ...personal.fields, ...this.customFields.personal.schema }
      const handicapFields = { ...handicap.fields, ...customHandicapFields }
      const realEstateFields = { ...realEstate.fields, ...customRealEstateFields }

      return {
        personal: { ...personal, fields: personalFields },
        handicap: { ...handicap, fields: handicapFields },
        realEstate: { ...realEstate, fields: realEstateFields },
      }
    },

    personalInfoForeign () {
      const { foreign } = personalSchemas(this)

      // Foreign dynamic hide
      const customForeignFields = Object.entries(this.customFields.foreign.schema)
        .reduce((acc, [ key, value ]) => ({
          ...acc,
          [key]: { ...value, hide: isNotForeign }
        }), {})

      const foreignFields = { ...foreign.fields, ...customForeignFields }

      return {
        foreign: { ...foreign, fields: foreignFields }
      }
    },

    submiting: {
      get () { return this.$store.state.admission.submiting },
      set (value) { this.$store.commit(types.ADMISSION_SUBMITING, value) }
    },

    requiredFields () {
      return [
        ...this.personalInfoRequiredFields,
        ...this.getRequiredFields('foreignInfo'),
        ...this.getRequiredFields('bankAccount'),
        ...this.getRequiredFields('academicFormation'),
        ...this.getRequiredFields('handicapInfo'),
        ...this.getRequiredFields('realEstate'),
      ]
    },

    personalInfoRequiredFields () {
      const filterOut = this.isNotForeign ? [] : [ 'city', 'state' ]

      return this.getRequiredFields('personalInformation', filterOut)
    }
  },

  data: () => ({
    loading: true,
    cities: [],
    isNotForeign: true,
    foreignData: null
  }),

  mounted () {
    this.$root.$on('next', this.onSubmit)
    this.$root.$on('previous', this.onSave)
    this.init()
  },

  beforeDestroy () {
    this.$root.$off('next', this.onSubmit)
    this.$root.$off('previous', this.onSave)
  },

  methods: {
    ...mapMutations({
      savePersonal: types.EMPLOYEE_UPDATE_PERSONAL,
      saveForeign: types.EMPLOYEE_UPDATE_FOREIGN,
      saveHandicap: types.EMPLOYEE_UPDATE_HANDICAP,
      saveRealEstate: types.EMPLOYEE_UPDATE_REAL_ESTATE
    }),

    ...mapActions({
      getCustomFields: types.EMPLOYEE_PERSONAL_CUSTOM_FIELDS,
      getOptions: types.EMPLOYEE_PERSONAL_OPTIONS,
      getPersonal: types.EMPLOYEE_PERSONAL_INFO,
      getCountries: types.LOCATION_COUNTRIES,
      getStates: types.LOCATION_STATES,
      getCities: types.LOCATION_CITIES,
      setPersonal: types.EMPLOYEE_UPDATE_PERSONAL,
      setForeign: types.EMPLOYEE_UPDATE_FOREIGN,
      setHandicap: types.EMPLOYEE_UPDATE_HANDICAP,
      setRealEstate: types.EMPLOYEE_UPDATE_REAL_ESTATE,
      getRequiredSystemFields: types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS,
    }),

    async init () {
      await this.getOptions()
      await this.getPersonal()
      await this.getCountries()
      await this.getStates()
      await this.getCustomFields()

      if (this.personal.state)
        this.cities = await this.getCities(this.personal.state)

      this.loading = false
    },

    async validate () {
      await this.getRequiredSystemFields()

      if (!this.$refs.formGroup)
        return false

      if (!await this.$refs.formGroup.validate())
        return false

      return true
    },

    async onSubmit () {
      if (!await this.validate()) return

      const data = this.$refs.formGroup.formsData
      const dataPersonalForeign = this.$refs.formGroupForeign?.formsData || { foreign: {} }

      this.submiting = true

      const [ personalErr ] = await this.setPersonal({
        ...this.personal,
        ...data.personal,
        customFields: Object.keys(this.customFields.personal.schema)
      })

      const [ handicapErr ] = await this.setHandicap({
        ...this.handicap,
        ...data.handicap,
        customFields: Object.keys(this.customFields.handicap.schema)
      })

      const [ realEstateErr ] = await this.setRealEstate({
        ...this.realEstate,
        ...data.realEstate,
        customFields: Object.keys(this.customFields.realEstate.schema)
      })

      if (!isNotForeign(data.personal))
        await this.setForeign({
          ...this.foreign,
          ...dataPersonalForeign.foreign,
          customFields: Object.keys(this.customFields.foreign.schema)
        })

      this.submiting = false

      if (personalErr || handicapErr || realEstateErr)
        return this.$feedback.add({
          type: 'error',
          message: 'Erro',
          highlighted: 'ao salvar Dados Pessoais. Tente novamente.'
        })

      this.$feedback.add({
        type: 'success',
        message: 'Informações pessoais',
        highlighted: 'atualizadas com sucesso'
      })

      this.$emit('next')
    },

    async onSave () {
      if (!this.$refs.formGroup) return

      const data = this.$refs.formGroup.formsData

      await this.savePersonal({ ...this.personal, ...data.personal })

      this.saveForeign({ ...this.foreign, ...data.foreign })
      this.saveHandicap({ ...this.handicap, ...data.handicap })
      this.saveRealEstate({ ...this.realEstate, ...data.realEstate })

      this.$emit('previous')
    },

    onSyncPersonal (personal) {
      this.isNotForeign = isNotForeign(personal)
    },

    onSyncPersonalForeign (foreign) {
      this.foreignData = foreign
    }
  },

  watch: {
    data (data) {
      this.onSyncPersonal(data.personal)
    }
  }
}
</script>

<style lang="scss">
.personal-info-container {
  position: relative;
  & > .forms > .form {
    @include responsive (tablet, desktop) {
      margin-top: 10px;
      background: white;
      border-radius: 5px;
      box-shadow: 2px 8px 16px 0px rgba(0, 0, 0, 0.06);
    }

    @include responsive (xs-mobile, mobile) {
      & > .c-form-group {
        & > .form:first-child {
          padding-top: 15px;
          & > .c-title { display: none; }
        }
      }
    }
  }

}
</style>
