<template>
  <div class="other-info-container">
    <transition name="view" mode="out-in">
      <c-loader v-if="loading" :size="isMobile ? 79 : 99" />

      <div v-else class="body">
        <c-title v-if="!isMobile" dark-text>
          Outras Informações
        </c-title>

        <c-form-builder
          ref="form"
          :schema="schema"
          :disabled="submiting"
          :label-left="!isMobile"
          v-model="formData"
          @input="$emit('input', $event)"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { MediaQuery } from '@convenia/mixins'
import * as types from '@types'

export default {
  name: 'OtherInfo',

  mixins: [ MediaQuery ],

  computed: {
    ...mapState({
      schema: state => state.otherInfo.schema,
      data: state => state.otherInfo.data
    }),

    submiting: {
      get () { return this.$store.state.admission.submiting },
      set (value) { this.$store.commit(types.ADMISSION_SUBMITING, value) }
    }
  },

  data: () => ({
    loading: true,
    formData: {}
  }),

  mounted () {
    this.$root.$on('next', this.onSubmit)
    this.$root.$on('previous', this.onSave)
    this.init()
  },

  beforeDestroy () {
    this.$root.$off('next', this.onSubmit)
    this.$root.$off('previous', this.onSave)
  },

  methods: {
    ...mapMutations({
      saveOtherInfo: types.EMPLOYEE_SET_OTHER_INFO
    }),

    ...mapActions({
      getOtherInfo: types.EMPLOYEE_OTHER_INFO,
      setOtherInfo: types.EMPLOYEE_SET_OTHER_INFO
    }),

    async init () {
      await this.getOtherInfo()

      this.formData = { ...this.data }

      this.loading = false
    },

    async validateForm () {
      await this.getOtherInfo()

      const { form } = this.$refs || {}
      form.hasValidation = true

      const isValid = await form.$refs.veeObserver.validate()

      if (!isValid) form.focusFirstError()

      return isValid
    },

    async onSubmit () {
      const valid = await this.validateForm()

      if (!valid) return

      this.submiting = true
      const [ err ] = await this.setOtherInfo(this.formData)
      this.submiting = false

      if (err)
        return this.$feedback.add({
          type: 'error',
          message: 'Erro',
          highlighted: 'ao salvar Outras Informações. Tente novamente.'
        })

      this.$feedback.add({
        type: 'success',
        message: 'Outras Informações',
        highlighted: 'atualizadas com sucesso'
      })

      this.$emit('next')
    },

    async onSave () {
      await this.saveOtherInfo(this.formData)

      this.$emit('previous')
    }
  }
}
</script>

<style lang="scss">
.other-info-container {
  position: relative;
  padding-bottom: 40px;
  $input-height: 40px;
  $label-width: 210px;

  & > .body {
    & > .c-title { padding: 20px; }

    & > .c-form-builder {
      margin-bottom: 10px;
      & > .c-shadowed, & > .c-shadowed > .wrapper { overflow: initial; }
      & > .actions { display: none; }

      .c-form-field.-label-left {
        grid-template-columns: 190px 360px;

        & > .input-wrapper > .hint {
          @include desktop { max-width: 190px; }
          @include tablet { display: none; }
        }
      }
    }
  }

  @include responsive (xs-mobile, mobile) {
    & > .body {
      & > .c-form-builder { margin-bottom: 0; }
    }
  }

  @include responsive (tablet, desktop) {
    margin-top: 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 2px 8px 16px 0px rgba(0, 0, 0, 0.06);

    & > .c-loader { margin-top: 60px !important; }
  }
}
</style>
