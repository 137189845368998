<template>
  <div class="personal-documents-container">
    <document-modal
      v-if="showModal"
      ref="modal"
      :value="selected"
      :fields="schema"
      :options="options"
      :title="selected.title"
      :text="selected.text"
      :loading="isSubmitting"
      :disabled="isSubmitting"
      @close="onClose"
      @submit="onSubmit"
    />

    <dialog-modal
      v-if="showRemoveFileModal"
      theme="error"
      title="Deseja prosseguir?"
      message="Ao prosseguir os arquivos apagados serão permanentemente excluídos,
        esta ação não pode ser desfeita."
      confirm="Excluir"
      cancel="Não, mudei de ideia"
      @cancel="showRemoveFileModal = false"
      @confirm="onSubmit(filesFormData)"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import * as types from '@types'

import DocumentModal from '@components/DocumentModal'
import DialogModal from '@components/DialogModal'
import schemas from '@forms/document'
import upload from '@modules/upload'
import { INVALID_CPF_ERROR } from '@content/consts/Documents'

export default {
  name: 'PersonalDocuments',

  components: {
    DocumentModal,
    DialogModal,
  },

  data: () => ({
    setActions: {
      rg: types.DOCUMENT_SET_RG,
      driver_license: types.DOCUMENT_SET_CNH,
      cpf: types.DOCUMENT_SET_CPF,
      electoral_card: types.DOCUMENT_SET_ELECTORAL,
      ctps: types.DOCUMENT_SET_CTPS,
      residence_proof: types.DOCUMENT_SET_RESIDENCE,
      reservist: types.DOCUMENT_SET_RESERVIST,
    },
    showModal: true,
    showRemoveFileModal: false,
    filesFormData: {},
    cities: [],
  }),

  props: {
    value: {
      type: String,
      default: null
    }
  },

  computed: {
    ...mapState({
      states: state => state.location.states,
      employeeId: state => state.admission.employeeUuid,
      personalInfo: state => state.personalInfo.personal,
    }),

    ...mapGetters({
      documents: types.DOCUMENT_GET_DOCUMENTS,
      getRequiredFields: types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS,
    }),

    isSubmitting: {
      get () { return this.$store.state.admission.submiting },
      set (value) { this.$store.commit(types.ADMISSION_SUBMITING, value) }
    },

    schema () {
      const fields = schemas(this)[this.value] || {}
      const customFields = this.selected.customFields || {}

      return {
        ...fields,
        ...customFields
      }
    },

    selected () {
      if (!this.documents[this.value]) return {}

      const { gender, genderIdentities } = this.personalInfo || {}
      const isFemale = (gender === 2) || (genderIdentities === 2)
      if (this.value === 'reservist' && isFemale) {
        return {
          ...this.documents[this.value],
          requiredFields: [],
          requiredFiles: false
        }
      }

      return this.documents[this.value]
    },

    options () {
      return {
        states: this.states,
        cities: this.cities,
      }
    },
  },

  async mounted () {
    await this.getStates()

    if ([ 'electoral_card' ].includes(this.value) && this.selected.data.state)
      this.cities = await this.getCities(this.selected.data.state)
  },

  methods: {
    ...mapMutations({
      setFiles: types.DOCUMENT_SET_FILES,
    }),

    ...mapActions({
      getStates: types.LOCATION_STATES,
      getCities: types.LOCATION_CITIES,
      getCustomFields: types.DOCUMENT_CUSTOM_FIELDS,
      deleteFile: types.ADMISSION_DELETE_FILE,
      verifyDocuments: types.DOCUMENT_VERIFY_DOCUMENTS,
    }),

    onClose () {
      this.clear()
      this.showModal = false

      setTimeout(() => this.$emit('close'), 300)
    },

    async onSubmit ({ data, files, replaced }) {
      if (replaced.length > 0 && !this.showRemoveFileModal) {
        this.filesFormData = { data, files, replaced }
        this.showRemoveFileModal = true

        return
      }

      this.showRemoveFileModal = false
      this.isSubmitting = true

      const { id } = this.selected || {}
      const customFields = Object.keys(this.selected.customFields) || []

      const [ err, , endpoint ] = await this.$store.dispatch(
        this.setActions[this.value], { id, customFields, ...data }
      )
      await this.getCustomFields()

      if (err) {
        const uniqueCpfErr = Array.isArray(err) && err
          .some(({ message }) => message.includes(INVALID_CPF_ERROR))

        this.$feedback.add({
          type: 'error',
          message: uniqueCpfErr ? 'CPF' : 'Erro',
          highlighted: uniqueCpfErr
            ? 'deve ser único dentro da empresa. Por favor, converse com o RH'
            : 'ao salvar o documento. Tente novamente mais tarde.'
        })

        this.isSubmitting = false
        return
      }

      const initialFiles = this.selected.files || []
      const addedFiles = files.filter(file =>
        !!file.name && !initialFiles.find(({ id }) => file.id === id)
      )
      await this.deleteFiles(replaced)

      this.uploadFiles(addedFiles, endpoint)
        .then(async () => {
          const removedFiles = replaced.map(file => file.id)
          const updatedFiles = [ ...initialFiles, ...addedFiles ]
            .filter(file => !removedFiles.includes(file.id))
            .map(file => ({ ...file, progress: 0 }))

          this.setFiles({ type: this.value, files: updatedFiles })
          this.verifyDocuments()
          this.onClose()
        })
        .catch(() => {
          this.isSubmitting = false
        })
    },

    async uploadFiles (files, endpoint) {
      const uploads = await upload({ files, endpoint })

      return Promise.all(uploads.map(({ request }) => request))
    },

    async deleteFiles (files) {
      const { employeeId } = this

      await Promise.all(files.map(
        file => this.deleteFile({ file, employeeId }))
      )
    },

    clear () {
      this.filesFormData = {}
      this.cities = []
      this.isSubmitting = false
    },
  }
}
</script>
