<template>
  <div ref="container" :style="style" class="c-loader">
    <img :src="loaderSrc">
  </div>
</template>

<script>

/**
 * Very simple and beautiful animated loader.
 */
export default {
  name: 'CLoader',

  props: {
    /**
     * The size (diameter) of the loader in px.
     */
    size: {
      type: [ String, Number ],
      default: null,
    },
  },

  data: () => ({
    // this state is used to enable the LGBTQIA+ pride version of the logo
    usePrideLogo: false,

    loaderSrc: '/img/loader.gif'
  }),

  mounted () {
    this.$nextTick(this.setLoaderSrc)
  },

  computed: {
    style () {
      return {
        width: `${this.computedSize}px`,
        height: `${this.computedSize}px`
      }
    },

    computedSize () {
      if (this.size) return this.size

      return this.isMobile ? 80 : 100
    }
  },

  methods: {
    setLoaderSrc () {
      if (!this.usePrideLogo) return

      const { container } = this.$refs
      const hasRect = typeof (container || {}).getBoundingClientRect === 'function'
      const rect = hasRect ? container.getBoundingClientRect() : {}

      if (rect.height > 80)
        this.loaderSrc = '/img/loader_pride.gif'
    }
  }
}
</script>

<style lang="scss">
.c-loader {
  overflow: visible;
  img { width: 100%; height: auto; }
}
</style>
