<template>
  <span :class="classes" :style="styles">
    <c-icon v-bind="iconAttrs" />
  </span>
</template>

<script>
import { theme } from '@convenia/mixins'

/**
 * A very simple component meant to be used similarly to a tag.
 * Accepts all the same props as CIcon.
 */

export default {
  name: 'CFlag',

  mixins: [ theme ],

  props: {
    /**
     * The size of the flag.
     */
    size: {
      type: [ String, Number ],
      default: 40,
      validator: size => typeof +size === 'number' && +size > 0
    },
    /**
     * The icon of the flag.
     */
    icon: {
      type: String,
      default: ''
    },
    /**
     * The size of the icon.
     */
    iconSize: {
      type: [ String, Number ],
      validator: size => typeof +size === 'number' && +size > 0,
      default: 24,
    },

    /**
     * Make the button to have a border circled around it
     */
    circledBorder: Boolean,

    /**
     * Sets shadow blur value (only works with validation, grey
     * and customColor styles)
     */
    shadowBlur: {
      type: String,
      default: '4px'
    },

    /**
     * Applies a red bg-color to the flag, and a blurred shadow,
     * it will also ignore the icon, size and icon-size props,
     * and use the standards defined by the Design System for
     * the validation messages flag
     */
    validation: Boolean,

    /**
     * With the use of mixin for themes it is no longer necessary
     * to use these color props, they can be deleted in the future
     */

    /**
     * Props that will no longer be used
     *
     *
     * @deprecated Please use `positive` instead.
     * Changes the bg-color to our success color (usually green).
     */
    success: Boolean,
    /**
     * @deprecated Please use `negative` instead.
     * Changes the bg-color to our error color (usually red).
     */
    error: Boolean,
    /**
     * @deprecated Please use `support` instead.
     * Changes the bg-color to our info color (usually blue).
     */
    info: Boolean,

    /**
     * @deprecated Please use `complementary` instead.
     * Changes the bg-color to our alert color (usually orange).
     */
    alertDark: Boolean,
    /**
     * @deprecated Please use `disabled` instead.
     * Changes the bg-color to a gray(ish).
     */
    grey: Boolean,

    white: Boolean,

    /**
     * Sets a custom Hex code color for the flag background
     */
    customColor: {
      type: String,
      default: null,
    },

    alert: Boolean,

    primary: Boolean,

    /**
     * Fills with primary color
     */
    accent: Boolean,
  },

  computed: {
    classes () {
      /**
       * Remove else when all old code is refactored
       */
      const theme = this.theme ? { [`-${this.theme}`]: true } : {
        '-primary': this.primary,
        '-positive': this.success,
        '-negative': this.error,
        '-support': this.info,
        '-alert': this.alert,
        '-complementary': this.alertDark,
        '-disabled': this.grey,
        '-white': this.white,
        '-accent': this.accent,
        '-bullet': !this.icon,
      }

      return [ 'c-flag',
        {
          ...theme,
          '-validation': this.validation,
          '-circled-border': this.circledBorder,
          '-custom-color': !!this.customColor
        }
      ]
    },

    styles () {
      const size = this.validation ? '15px' : this.size + 'px'

      return {
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        '--custom-color': this.customColor,
        '--custom-color-alpha': this.customColor + '66', // === 40% opacity
        '--shadow-blur': this.shadowBlur || '4px'
      }
    },

    iconAttrs () {
      return {
        ...(this.$attrs || {}),
        ...(this.validation ? {
          icon: 'warning-2',
          size: 13
        } : {
          size: this.iconSize,
          icon: this.icon
        })
      }
    },
  }
}
</script>

<style lang="scss">
.c-flag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 6px;
  border-radius: 100%;
  transition: background ease 400ms, box-shadow ease 400ms;

  & > .c-icon {
    @include icon-color(#FFF);
    z-index: $z-index-1;
  }

  &.-primary {
    background: color-var(default, lighter);
    box-shadow: 0 2px 20px -2px rgba(color-var(default, base-rgb), .01);
    &.-bullet { background: color-var(default) }

    & > .c-icon {
      @include icon-color(color-var(default));
      &.-convenia { opacity: 0.5; }
    }
  }

  &.-positive {
    background: color-var(positive, lighter);
    box-shadow: 0 2px 20px -2px rgba(color-var(positive, base-rgb), .01);
    &.-bullet { background: color-var(positive) }

    & > .c-icon {
      @include icon-color(color-var(positive))
    }
  }

  &.-negative {
    background: color-var(negative, lighter);
    box-shadow: 0 2px 20px -2px rgba(color-var(negative, base-rgb), .01);
    &.-bullet { background: color-var(negative) }

    & > .c-icon {
      @include icon-color(color-var(negative));
      &.-convenia { opacity: 0.6; }
    }
  }

  &.-support {
    background: color-var(info, lighter);
    box-shadow: 0 2px 20px -2px rgba(color-var(info, base-rgb), .01);
    &.-bullet { background: color-var(support) }

    & > .c-icon {
      @include icon-color(color-var(info));
      &.-convenia { opacity: 0.7; }
    }
  }

  &.-alert {
    background: color-var(alert, lighter);
    box-shadow: 0 2px 20px -2px rgba(color-var(alert, base-rgb), .01);
    &.-bullet { background: color-var(alert) }

    & > .c-icon {
      @include icon-color(color-var(alert))
    }
  }

  &.-complementary {
    background: color-var(alert, extraLight);
    box-shadow: 0 2px 20px -2px rgba(color-var(alert, base-rgb), .01);

    & > .c-icon {
      @include icon-color(color-var(alert, dark));
      &.-convenia { opacity: 0.8; }
    }
  }

  &.-validation {
    background: color-var(negative);
    box-shadow: 0px 3px var(--shadow-blur) 0px rgba(color-var(negative, base-rgb), .01);
    padding: 0px;

    &.-alert { @extend .-alert; }
  }

  &.-disabled {
    z-index: 0;
    position: relative;
    background: color-var(text, base-10);
    box-shadow: 0px 3px var(--shadow-blur) 0px color-var(text, base-05);

    // grey flag shade
    @include add-shadow(before) {
      border-radius: 50%;
      opacity: 0.1;
    }

    & > .c-icon { filter: unset; }
  }

  &.-white {
    box-shadow: unset;
    background: rgba(255,255,255,0.3);
    box-shadow: 0 2px 15px 0px color-var(text, base-10);
  }

  &.-accent {
    background: color-var(primary, base);
    box-shadow: 0 2px 20px -2px rgba(color-var(primary, base-rgb), .01);
  }

  &.-custom-color {
    background: var(--custom-color);
    box-shadow: 0px 3px var(--shadow-blur) 0px var(--custom-color-alpha);
  }

  &.-circled-border {
    &::after {
      content: "";
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      border-radius: 50px;
      border: 1px solid color-var(text, base-10);

      // reset
      width: unset;
      filter: unset;
      height: unset;
      transform: unset;
      background: unset;
      opacity: 1;
    }
  }
}
</style>
