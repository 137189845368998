<template>
  <div class="profile-photo-container">
    <transition name="view" mode="out-in">
      <c-loader v-if="loading" :size="isMobile ? 79 : 99" />

      <div v-else class="wrapper">
        <c-title v-if="!isMobile" dark-text>
          Foto de Perfil
        </c-title>

        <photo-upload
          :value="photo"
          :validation="error"
          @upload="onUpload"
          @remove="onRemove"
          @submit="onSubmit"
        />

        <crop-modal
          v-if="cropping"
          :value="cropping"
          @close="cropping = null"
          @remove="onRemove"
          @submit="onCrop"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { MediaQuery } from '@convenia/mixins'
import * as types from '@types'
import PhotoUpload from '@components/PhotoUpload'
import CropModal from '@components/CropModal'

export default {
  name: 'ProfilePhoto',

  mixins: [ MediaQuery ],

  components: {
    PhotoUpload,
    CropModal
  },

  data: () => ({
    loading: true,
    dirty: false,
    error: '',
    photo: null,
    cropping: null
  }),

  computed: {
    ...mapGetters({
      getRequiredFields: types.EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS
    }),

    submiting: {
      get () { return this.$store.state.admission.submiting },
      set (value) { this.$store.commit(types.ADMISSION_SUBMITING, value) }
    },

    requiredFields () {
      return this.getRequiredFields('personalInformation')
    }
  },

  mounted () {
    this.$root.$on('next', this.onSubmit)
    this.init()
  },

  beforeDestroy () {
    this.$root.$off('next', this.onSubmit)
  },

  methods: {
    ...mapActions({
      getPhoto: types.EMPLOYEE_PHOTO,
      setPhoto: types.EMPLOYEE_PHOTO_SET
    }),

    async init () {
      const [ , photo ] = await this.getPhoto()

      this.photo = photo
      this.loading = false
    },

    onUpload (image) {
      this.cropping = image
      this.error = ''
    },

    onCrop (image) {
      this.photo = image
      this.cropping = null
      this.dirty = true
    },

    onRemove () {
      this.photo = null
      this.cropping = null
      this.dirty = false
    },

    async onSubmit () {
      if (!this.photo && this.requiredFields.includes('profilePicture')) {
        this.error = 'Campo obrigatório.'
        return
      }

      if (!this.photo && !this.requiredFields.includes('profilePicture'))
        return this.$emit('next')

      if (!this.dirty && this.photo)
        return this.$emit('next')

      this.submiting = true
      const [ err ] = await this.setPhoto(this.photo)
      this.submiting = false

      if (err)
        this.$feedback.add({
          type: 'error',
          message: 'Erro',
          highlighted: 'ao salvar Foto de Perfil. Tente novamente.'
        })

      this.$feedback.add({
        type: 'success',
        message: 'Foto de Perfil',
        highlighted: 'salva com sucesso.'
      })

      this.$emit('next')
    }
  }
}
</script>

<style lang="scss">
.profile-photo-container {
  padding-bottom: 20px;
  position: relative;

  & > .wrapper {
    & > .c-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    & > .c-title { padding: 20px; }
  }

  @include responsive (tablet, desktop) {
    margin-top: 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 2px 8px 16px 0px rgba(0, 0, 0, 0.06);
  }
}
</style>
