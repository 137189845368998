/**
 * Checks whether a given full name has a first and last name.
 */
export const fullName = (data) => {
  const value = data.value || data

  return (value || '').length === 0
    || (value || '').trim().split(' ').length > 1
    || 'Nome e sobrenome obrigatórios'
}
