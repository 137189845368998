<template>
  <div :class="classes">
    <aside v-if="!noSidebar" :class="[ 'sidebar', { '-collapse': collapseSidebar } ]">
      <c-sidebar
        :company-name="companyName"
        :show="showSidebar"
        :items="sidebarItems"
        :options="sidebarOptions"
        :popover-items="sidebarPopoverItems"
        :value="sidebarCurrentOption"
        :employee="user"
        :theme="sidebarTheme"
        :logo-src="sidebarLogoSrc"
        :symbol-src="sidebarSymbolSrc"
        :persist-mobile="persistMobile"
        :no-select="noSidebarSelect"
        :collapse="collapseSidebar"
        :alternative-tablet-style="alternativeTabletSidebar"
        :alternative-mobile-style="alternativeMobileSidebar"
        :alternative-tablet-logo="alternativeSidebarLogo"
        v-on="$listeners"
      >
        <slot
          name="mobile-header"
          slot="header"
        />
      </c-sidebar>
    </aside>

    <section class="main">
      <c-overlay
        :show="showSidebar"
        class="overlay"
        @close="$emit('toggle-sidebar')"
      />

      <template>
        <slot name="modal" />
      </template>

      <c-warning
        v-if="$slots.cwarning && warningOptions.show"
        ref="cwarning"
        v-bind="warningOptions"
      >
        <slot name="cwarning" />
      </c-warning>

      <c-header
        class="header"
        :hide="hideHeader"
        :hide-sub-menu="!showSubHeader || hideSubHeader"
        :height="headerHeight"
        :sub-menu-height="subMenuHeight"
        :style="{ marginTop: contentMarginTop }"
      >
        <slot name="header" />

        <slot slot="subMenu" name="subMenu" />
      </c-header>

      <div
        ref="appContent"
        v-scrollbar-actions="scrollbarActions"
        :style="{ paddingTop: contentPaddingTop, marginTop: trialMarginTop }"
        class="content"
        id="app-content"
        @scroll="handleScroll"
        v-on="$listeners"
      >
        <slot name="content" />
      </div>

      <c-scroll-top
        v-if="!hideScrollTop"
        fixed
        text="Voltar ao topo"
        class="scroll-top"
        :style="{ bottom: `${scrollTopPos || 20}px` }"
        :target="$refs.appContent"
        :width="192"
        @target:scroll="handleScrollTop"
      />
    </section>
  </div>
</template>

<script>
import { MediaQueryRoot } from '@convenia/mixins'
import { scrollbarActions } from '@convenia/components/directives'

/**
 * The application's layout wrapper.
 */
export default {
  name: 'CApp',

  mixins: [ MediaQueryRoot ],

  directives: { scrollbarActions },

  props: {
    /**
     * Wheter tho show the sidebar or not.
     */
    showSidebar: {
      type: Boolean,
      required: true,
    },

    /**
     * The items of the CSidebar
     */
    sidebarItems: {
      type: [ Array, Object ],
      default: () => []
    },

    collapseSidebar: {
      type: Boolean,
      default: false
    },

    /**
     * The sidebar popover items.
     */
    sidebarPopoverItems: {
      type: [ Array, Object ],
      default: () => ({})
    },

    /**
     * The options available in the select field.
     * e.g. the companies list
     */
    sidebarOptions: {
      type: Array,
      default: () => []
    },

    /**
     * The value of the select field
     * e.g. the current company, by default.
     */
    sidebarCurrentOption: {
      type: [ String, Object ],
      default: () => ({})
    },

    /**
     * The logged user info to be displayed in the
     * app sidebar
     */
    user: {
      type: Object,
      default: () => ({ name: '', role: '' }),
      validator: x => 'name' in x
    },

    /**
     * The companyName to be used when company have no logo and flatlogo (symbol)
    */
    companyName: {
      type: String,
      default: ''
    },

    /**
     * The height of the header.
     */
    headerHeight: {
      type: [ String, Number ],
      default: 70
    },

    /**
     * The height of header's subMenu section.
     */
    subMenuHeight: {
      type: [ String, Number ],
      default: 50
    },

    /**
     * Whether to hide the sub header
     */
    hideSubHeader: Boolean,

    /**
     * Whether to hide the header
     */
    hideHeader: Boolean,

    /**
     * The sidebar color theme
     */
    sidebarTheme: {
      type: String,
      default: 'default'
    },

    /**
     * The sidebar logo src
     * if it should not be the default Convenia logo
     */
    /* eslint-disable-next-line vue/require-default-prop */
    sidebarLogoSrc: String,

    /**
     * The sidebar Symbol src
     * if it should not be the default Convenia symbol
     */
    /* eslint-disable-next-line vue/require-default-prop */
    sidebarSymbolSrc: String,

    /**
     * Sets an automatic scrollbar overlay effect
     */
    scrollbarActions: {
      type: Object,
      default: () => ({}),
    },

    /**
     * Completely removes the sidebar
     */
    noSidebar: Boolean,

    /**
     * Persists the mobile sidebar styles until desktop breakpoint
     */
    persistMobile: Boolean,

    /**
     * Removes the select field from the sidebar on mobile screens
     */
    noSidebarSelect: Boolean,

    /**
     * Applies the alternative styling for the sidebar in tablet screens
     */
    alternativeTabletSidebar: Boolean,

    /**
     * Applies the alternative styling for the sidebar in mobile screens
     */
    alternativeMobileSidebar: Boolean,

    /**
     * Logo to be used in the sidebar when the alternative style is active
     */
    alternativeSidebarLogo: {
      type: String,
      default: null,
    },

    /**
     * Removes display fixed
     */
    isNotFixedMobile: Boolean,

    /**
     * Hides CScrollTop on content scroll
     */
    hideScrollTop: Boolean,

    /**
     * Enables you to dynamically control the bottom position of
     * the CScrollTop component, in case it conflicts with other
     * elements in the page
     */
    scrollTopPos: {
      type: Number,
      default: 20
    },

    /**
     * Defines how far the user has to scroll down before the scroll top
     * button shows up
     */
    scrollThreshold: {
      type: Number,
      default: 200
    },

    /**
     * Options to configure the cwarning component if available
     */
    warningOptions: {
      type: [ Array, Object ],
      default: () => ({})
    },
  },

  data () {
    return {
      position: 0,
      lastPosition: 0,
      showSubHeader: true,
      contentMarginTop: '0px'
    }
  },

  computed: {
    classes () {
      return [ 'c-app', {
        '-fixed-mobile': !this.isNotFixedMobile,
        '-expanded-sidebar': !this.isTablet && !this.noSidebar && !this.collapseSidebar,
        '-hidden-sidebar': this.isTablet || this.noSidebar,
        '-has-warning': this.$slots.cwarning && this.warningOptions.show,
      } ]
    },
    contentPaddingTop () {
      const menuHeight = +(this.hideHeader ? 0 : this.headerHeight || 0)
      + +(this.hideSubHeader ? 0 : this.subMenuHeight || 0)

      return menuHeight + 'px'
    },
    trialMarginTop () {
      return `${(this.$slots.cwarning && this.warningOptions.show ? 60 : 0)}px`
    }
  },

  created () {
    if (this.$slots.cwarning && this.warningOptions.show)
      this.contentMarginTop = this.$refs.cwarning.$el.clientHeight + 'px'
  },

  mounted () {
    if (this.$slots.cwarning && this.$refs.cwarning && this.warningOptions.text) {
      setTimeout(() => {
        this.contentMarginTop = this.$refs.cwarning.$el.clientHeight + 'px'
      }, 250)
    }
  },

  methods: {
    handleScrollTop ({ event, setVisible }) {
      const scrollEl = event.target
      const { scrollTop, clientHeight, scrollHeight } = scrollEl
      const showBtn = (scrollHeight > (clientHeight + 200)) && scrollTop >= this.scrollThreshold

      setVisible(showBtn)
    },
    handleScroll () {
      const { scrollTop, clientHeight, scrollHeight } = this.$refs.appContent
      this.lastPosition = this.position
      this.position = scrollTop

      if (scrollHeight <= window.innerHeight + this.headerHeight) {
        this.showSubHeader = true
      } else if (this.position < this.headerHeight) {
        this.showSubHeader = true
      } else if (this.position >= scrollHeight - clientHeight - this.headerHeight) {
        this.showSubHeader = false
      } else {
        this.showSubHeader = this.lastPosition > this.position
      }
    }
  },

  updated () {
    this.contentMarginTop = this.$slots.cwarning && this.warningOptions.show
      ? this.$refs.cwarning.$el.clientHeight + 'px'
      : '0px'
  }
}
</script>

<style lang="scss">
.c-app {
  position: relative;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;

  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 0;

  &.-hidden-sidebar > .main > .scroll-top {
    left: calc(50%);
    transform: translateX(-50%);
  }

  &.-hidden-sidebar > .main > .header {
    z-index: var(--z-index-2);
  }

  &.-expanded-sidebar > .main > .scroll-top {
    // 130px = 260px (full sidebar width) / 2
    left: calc(50% + 130px);
    transform: translateX(-50%);
  }

  &.-has-warning > .main > .content { height: calc(100% - 60px) }

  & > .sidebar {
    width: 260px;
    flex-shrink: 0;
    transition: width .5s;
    z-index: var(--z-index-4);

    &.-collapse { width: 70px; }

    @include responsive (xs-mobile, tablet) { width: 0 !important; }
  }

  & > .main {
    position: relative;
    flex-grow: 1;
    max-height: 100vh;
    min-width: 0;
    max-width: 100%;

    & > .c-warning {
      position: absolute;
      width: 100%;
      z-index: var(--z-index-3);
    }

    & > .scroll-top {
      // 35px = 70px (collapsed sidebar width) / 2
      left: calc(50% + 35px);
      transform: translateX(-50%);
      margin: unset;
    }

    & > .content {
      padding-top: var(--content-margin-top);
      height: 100%;
      z-index: var(--z-index-1);

      transition:
        margin-top .3s ease,
        height .3s ease;

      will-change: margin-top, height;
      width: 100%;
      overflow-y: auto;
    }
  }

  // Fix for iOS body sliding issue.
  .-fixed-mobile {
    @include responsive(xs-mobile, mobile) { position: fixed; }
  }
}
</style>
