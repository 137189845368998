<template>
  <transition name="c-dropdown-slide" appear>
    <div class="c-dropdown">
      <c-popover
        align="left"
        class="popover"
        :selector="scrollSelector"
        v-bind="$listeners"
        @handler="$emit('close')"
      >
        <c-card class="actions" no-border no-hover>
          <slot>
            <div
              v-for="(item, index) in items"
              :key="index"
              :class="[ 'action', item.highlight && `-highlight-${item.highlight}` ]"
              @click="item.action"
            >
              <c-icon
                v-if="item.icon"
                class="icon"
                size="20"
                :icon="item.icon"
              />

              <span class="text">{{ item.label }}</span>
            </div>
          </slot>
        </c-card>
      </c-popover>

      <c-overlay class="overlay" show @close="$emit('close')" />
    </div>
  </transition>
</template>

<script>
/**
 * A more traditional Dropdown component.
 */
export default {
  name: 'CDropdown',

  props: {
    /**
     * The element to set the scroll listener to, the popover closes when
     * scroll is triggered in this element. Defaults to the CApp's #app-content div.
     */
    scrollSelector: {
      type: String,
      default: '#app-content'
    },

    /**
     * An array of dropdown items.
     */
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">

.c-dropdown {

  & > .overlay { cursor: default; }

  @include responsive (tablet, desktop) { & > .overlay { display: none; } }

  @include responsive (xs-mobile, mobile) {
    position: fixed;
    width: 100vw;
    height: 100vh;

    z-index: var(--z-index-5);
  }

  & > .popover {
    @include responsive (xs-mobile, mobile) {
      bottom: 10px;
      z-index: var(--z-index-4);
      left: 50% !important;
      top: unset !important;
      transform: translateX(-50%);

      width: calc(100vw - 20px);

      &::before { display: none; }
    }

    & > .actions {
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      min-width: 200px;

      background-color: transparent !important;
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0.75) 100%) !important;

      & > .action {
        display: flex;
        flex-basis: 50%;
        align-items: center;

        cursor: pointer;
        padding: 10px 20px 10px;
        border-bottom: var(--base-border);
        background-color: transparent;
        color: color-var(text, base-80);

        transition: background-color .15s;

        &:hover { background-color: color-var(text, base-02); }

        &.-highlight-red { color: color-var(negative); }
        &.-highlight-red > .icon { @include icon-color(negative); }

        & > .text { padding-left: 10px; font-size: 14px; }
        & > .icon { @include icon-color(text, base-80); }

        @include responsive (xs-mobile, mobile) { height: 60px; }
      }
    }
  }
}

@include responsive (xs-mobile, mobile) {
  .c-dropdown-slide {
    &-enter-active,
    &-leave-active {
      transition: opacity .5s;

      & > .popover { transition: bottom .4s; }
    }

    &-enter,
    &-leave-to {
      opacity: 0;

      & > .popover { bottom: -100% !important; }
    }
  }
}
</style>
