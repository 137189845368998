<!-- eslint-disable max-len -->
<template>
  <svg class="c-selectable-border" width="29px" height="29px" viewBox="0 0 29 29" version="1.1">
    <defs>
      <filter x="-12.0%" y="-12.0%" width="124.0%" height="124.0%" filterUnits="objectBoundingBox" id="c-selectable-filter-2">
        <feGaussianBlur stdDeviation="1" in="SourceGraphic" />
      </filter>
    </defs>

    <g class="border" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-211.000000, -20.000000)">
        <g transform="translate(213.000000, 22.000000)">
          <circle stroke="#121E48" opacity="0.1" cx="12.5" cy="12.5" r="13.5" />

          <circle stroke="var(--selectable-color)" opacity="0.9" filter="url(#c-selectable-filter-2)" cx="12.5" cy="12.5" r="13.5" />

          <circle stroke="var(--selectable-color)" cx="12.5" cy="12.5" r="13.5" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CSelectableBorder',

  props: {
    selected: Boolean,
  }
}
</script>

<style lang="scss">
.c-selectable-border {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
