<template>
  <div class="subhead">
    <c-progress
      v-if="!isMobile"
      inline
      :value="percentage"
    />

    <template v-else>
      <c-button
        flat
        class="menu"
        icon="menu-burger-square-6"
        icon-size="24"
        @click="$emit('menu')"
      />

      <div class="wrapper">
        <span class="title">
          {{ title }}
        </span>

        <span class="subheading">
          {{ `Passo ${currentStep}/${steps}` }}
        </span>
      </div>

      <c-icon
        v-if="icon"
        slot="actions"
        :size="80"
        :icon="icon"
      />
    </template>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'SubheadMenu',

  mixins: [ MediaQuery ],

  props: {
    title: {
      type: String,
      default: null
    },

    icon: {
      type: String,
      default: ''
    },

    steps: {
      type: Number,
      default: 0
    },

    currentStep: {
      type: Number,
      default: 0
    }
  },

  computed: {
    percentage () {
      return Math.round(((this.currentStep - 1) / this.steps) * 100)
    }
  }
}
</script>

<style lang="scss">
.subhead {
  & > .menu {
    margin-right: 10px;
  }

  & > .wrapper {
    & > .title {
      font-size: 16px;
      font-family: Ubuntu;
      font-weight: 500;
      line-height: 22px;
      display: block;
      color: rgba(#121E48, .8);
    }

    & > .subheading {
      font-size: 14px;
      line-height: 19px;
      color: rgba(#121E48, .8);
    }
  }

  & > .c-icon {
    opacity: .05;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0%, -40%);
  }

  @include responsive (xs-mobile, mobile) {
    height: 60px;
    background: white;
    box-shadow: 2px 8px 16px rgba(24,50,115, .06);
    position: relative;
    overflow: hidden;
    padding: 10px;
    display: flex;
    align-items: center;
  }
}
</style>
