<template>
  <div class="c-stars" :class="{ '-values': displayValues }" @mouseleave="onMouseLeave">
    <template v-if="!compact">
      <template v-if="isCustomStar">
        <span
          v-for="item in max"
          :key="item.id"
          v-tooltip.dark="{ value: item.message }"
          :class="classes(item.id)"
          @mouseenter="onMouseEnter(item.id, item.status)"
          @click="onChange(item.id)"
        >
          <c-icon
            :icon="getIcon(item.id)"
            :class="customStar"
          />

          <span v-if="displayValues" class="value">{{ item.id }}</span>
        </span>
      </template>

      <template v-else>
        <span
          v-for="i in max"
          :key="i"
          :class="classes(i)"
          @mouseenter="onMouseEnter(i)"
          @click="onChange(i)"
        >
          <c-icon :icon="getIcon(i)" />

          <span v-if="displayValues" class="value">{{ i }}</span>
        </span>
      </template>
    </template>

    <template v-else>
      <span :class="[ 'compact-star', { '-active': value > 0 } ]">
        {{ value }} / {{ total }} <c-icon :icon="getIcon(value)" />
      </span>
    </template>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import CIcon from '../CIcon'

export default {
  name: 'CStars',

  mixins: [ MediaQuery ],

  components: {
    CIcon
  },

  props: {
    value: {
      type: Number,
      default: 0
    },

    max: {
      type: [ Number, Array ],
      default: 5
    },

    displayValues: Boolean,
    compact: Boolean,
    readyOnly: Boolean,
    disabled: Boolean
  },

  data: () => ({
    hovering: -1,
    hoveredStatus: null
  }),

  mounted () {
    this.verifyStatus()
  },

  computed: {
    customStar () {
      return [
        {
          '-error': [ 'error' ].includes(this.hoveredStatus),
          '-alert': [ 'alert' ].includes(this.hoveredStatus),
          '-success': [ 'success' ].includes(this.hoveredStatus),
        }
      ]
    },

    total () {
      return this.isCustomStar ? this.max.length : this.max
    },

    isCustomStar () {
      return Array.isArray(this.max)
    }
  },

  methods: {
    classes (i) {
      return [
        'star',
        {
          '-hovering': this.hovering >= i,
          '-active': this.value >= i,
          '-no-cursor': this.readyOnly,
          '-disabled': this.disabled,
          '-error': [ 'error' ].includes(this.hoveredStatus),
          '-alert': [ 'alert' ].includes(this.hoveredStatus),
          '-success': [ 'success' ].includes(this.hoveredStatus),
        }
      ]
    },

    onMouseEnter (value, status) {
      this.hovering = !this.readyOnly ? value : -1
      this.hoveredStatus = status
    },

    onMouseLeave () {
      this.hovering = -1
      this.verifyStatus()
    },

    onChange (value) {
      this.$emit('input', value)
    },

    getIcon (value) {
      if (this.disabled)
        return 'star-favorite-filled'

      if (this.value >= value)
        return 'star-favorite-filled'

      if (this.hovering < value)
        return 'star-favorite'

      return 'star-favorite-filled'
    },

    verifyStatus () {
      if (this.isCustomStar) {
        const item = this.max.find(item => item.id === this.value)

        this.hoveredStatus = item?.status
      }
    }
  }
}
</script>

<style lang="scss">
.c-stars {
  display: flex;
  flex-flow: row wrap;

  & > .star {
    cursor: pointer;
    position: relative;
    margin: 0 5px;
    height: 40px;
    display: flex;
    align-items: center;

    &.-no-cursor {
      cursor: none;
      pointer-events: none;
    }

    & > .c-icon {
      stroke: color-var(text, base-30);
      fill: color-var(text, base-30);
    }

    & > .value {
      @include typo(body-1);
    }

    &.-active {
      &:after {
        content: "";
        position: absolute;
        width: 60px;
        height: 60px;
        border-radius: 60px;
        transform: translateX(-50%);
        top: -10px;
        left: 50%;
        pointer-events: none;
      }

      & > .c-icon {
        stroke: orange;
        fill: orange;

        &.-error {
          stroke: color-var(negative);
          fill: color-var(negative);
        }

        &.-alert {
          stroke: color-var(alert);
          fill: color-var(alert);
        }

        &.-success {
          stroke: color-var(positive);
          fill: color-var(positive);
        }
      }
    }

    &:after {
      opacity: 0.1;
    }

    &.-active {
      &:after {
        background: radial-gradient(circle at center, orange 0%, rgba(orange, 0) 60%);
      }
    }

    &.-error:after {
      background: radial-gradient(circle at center,
        color-var(negative) 0%, transparent 60%);
    }

    &.-alert:after {
      background: radial-gradient(circle at center,
        color-var(alert) 0%, transparent 60%);
    }

    &.-success:after {
      background: radial-gradient(circle at center,
        color-var(positive) 0%, transparent 60%);
    }

    &.-hovering:not(.-active) {
      & > .c-icon {
        stroke: orange;
        fill: orange;
        opacity: .5;

        &.-error {
          stroke: color-var(negative);
          fill: color-var(negative);
        }

        &.-alert {
          stroke: color-var(alert);
          fill: color-var(alert);
        }

        &.-success {
          stroke: color-var(positive);
          fill: color-var(positive);
        }
      }
    }

    &.-hovering, &.-active {
      & > .c-icon { transform: scale(1.2); }
    }

    &.-disabled {
      cursor: none;
      pointer-events: none;

      & > .c-icon {
        stroke: color-var(text, base-10);
        fill: color-var(text, base-10);
      }
    }
  }

  & > .compact-star {
    display: flex;
    justify-content: center;
    align-items: center;

    &.-active > .c-icon {
      stroke: orange;
      fill: orange;
    }
  }

  &.-values {
    margin-bottom: 10px;
    & > .star {
      margin: 0 10px;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      flex-flow: column;
    }
  }
}
</style>
