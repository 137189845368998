import * as types from '@types'
import * as services from '@services/auth'

export default {
  state: {
    token: ''
  },

  getters: {
    getToken (state) {
      return state.token
    }
  },

  mutations: {
    [types.AUTH_AUTHENTICATE]: (state, token) => {
      window.localStorage.setItem('token', token || '')

      state.token = token || ''
    },

    [types.AUTH_LOGOUT]: (state) => {
      window.localStorage.removeItem('token')
      state.token = ''
    }
  },

  actions: {
    [types.AUTH_AUTHENTICATE]: async ({ commit }, code) => {
      if (!code) {
        const token = window.localStorage.getItem('token')
        if (token) commit(types.AUTH_AUTHENTICATE, token)
        return [ null, token ]
      }

      const [ err, token ] = await services.authenticate(code)

      if (!err) commit(types.AUTH_AUTHENTICATE, token)

      return [ err, token ]
    }
  }
}
