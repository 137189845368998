import * as set from '@graphql/mutations'
import { formatDateUS } from '@modules/dateHelpers'
import { mapToNumeric } from '@modules/formatters'
import { getEmployeeInfoSchema, getAdditionalFields } from '@services/systemField'
import { PERSONAL_DOCUMENTS_AREA } from '@content/consts/Documents'

import * as middlewareGet from '@graphql/middleware/queries'
import middleware from '@graphql/middleware'
import { mappers, documentsMapper, mapCustomFields, mapCustomDocument } from './mappers'
import { getUploadEndpoint } from '../upload'

export async function getDocuments (getters) {
  const employeeId = getters.getEmployeeUuid

  const [ err, { employee: data = {} } = {} ] =
    await middleware(middlewareGet.GetDocuments, { employeeId }) || {}

  const documents = documentsMapper(data, getters)

  return [ err, documents ]
}

export async function getCustomDocuments (getters) {
  const employeeId = getters.getEmployeeUuid
  const companyId = getters.getCompanyUuid

  const [ err, data ] = await middleware(middlewareGet.GetCustomDocuments, ({
    employeeId,
    companyId,
    isAdmission: true
  }))

  const customDocuments = data?.customDocuments?.map(
    document => mapCustomDocument(document, getters)
  ) || []

  return [ err, customDocuments ]
}

export async function getCustomFields (getters) {
  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await getEmployeeInfoSchema({
    employeeId,
    area: PERSONAL_DOCUMENTS_AREA.LABEL
  })

  const customFields = mapCustomFields(data)

  return [ err, customFields ]
}

export async function setRg (getters, variables) {
  const isCreating = !variables.id
  const mutation = isCreating ? 'CreateRg' : 'UpdateRg'
  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await middleware(set[mutation], {
    input: {
      ...(!isCreating ? { id: variables.id } : {}),
      employee_id: employeeId,
      number: variables.number,
      emission_date: formatDateUS(variables.emission_date),
      issuing_agency: variables.issuing_agency,
      issuing_state_id: +variables.issuing_state || null,
      additional: {
        sectionId: PERSONAL_DOCUMENTS_AREA.RG,
        fields: getAdditionalFields(variables.customFields, variables)
      }
    }
  })

  const document = isCreating
    ? mappers.rg(data.rg, getters)
    : variables
  const endpoint = getUploadEndpoint(employeeId, document.id, 'rg')

  return [ err, document, endpoint ]
}

export async function setDriverLicense (getters, variables) {
  const isCreating = !variables.id
  const mutation = isCreating ? 'CreateDriverLicense' : 'UpdateDriverLicense'
  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await middleware(set[mutation], {
    input: {
      ...(!isCreating ? { id: variables.id } : {}),
      employee_id: employeeId,
      number: variables.number,
      emission_date: formatDateUS(variables.emission_date),
      validate_date: formatDateUS(variables.validate_date),
      category: variables.category,
      additional: {
        sectionId: PERSONAL_DOCUMENTS_AREA.CNH,
        fields: getAdditionalFields(variables.customFields, variables)
      }
    }
  })

  const document = isCreating
    ? mappers.driver_license(data.driver_license, getters)
    : variables
  const endpoint = getUploadEndpoint(employeeId, document.id, 'driver_license')

  return [ err, document, endpoint ]
}

export async function setCpf (getters, variables) {
  const isCreating = !variables.id
  const mutation = isCreating ? 'CreateCpf' : 'UpdateCpf'
  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await middleware(set[mutation], {
    input: {
      ...(!isCreating ? { id: variables.id } : {}),
      employee_id: employeeId,
      cpf: mapToNumeric(variables.cpf),
      additional: {
        sectionId: PERSONAL_DOCUMENTS_AREA.CPF,
        fields: getAdditionalFields(variables.customFields, variables)
      }
    }
  })

  const document = isCreating
    ? mappers.cpf(data.cpf, getters)
    : variables
  const endpoint = getUploadEndpoint(employeeId, document.id, 'cpf')

  return [ err, document, endpoint ]
}

export async function setReservist (getters, variables) {
  const isCreating = !variables.id
  const mutation = isCreating ? 'CreateReservist' : 'UpdateReservist'
  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await middleware(set[mutation], {
    input: {
      ...(!isCreating ? { id: variables.id } : {}),
      employee_id: employeeId,
      reservist: variables.reservist,
      ra_number: variables.ra_number,
      series: variables.series,
      additional: {
        sectionId: PERSONAL_DOCUMENTS_AREA.RESERVIST,
        fields: getAdditionalFields(variables.customFields, variables)
      }
    }
  })

  const document = isCreating
    ? mappers.reservist(data.reservist, getters)
    : variables
  const endpoint = getUploadEndpoint(employeeId, document.id, 'reservist')

  return [ err, document, endpoint ]
}

export async function setElectoralCard (getters, variables) {
  const isCreating = !variables.id
  const mutation = isCreating ? 'CreateElectoralCard' : 'UpdateElectoralCard'
  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await middleware(set[mutation], {
    input: {
      ...(!isCreating ? { id: variables.id } : {}),
      employee_id: employeeId,
      number: variables.number,
      electoral_ward: mapToNumeric(variables.electoral_ward),
      section: mapToNumeric(variables.section),
      state_id: +variables.state || null,
      city_id: +variables.city || null,
      additional: {
        sectionId: PERSONAL_DOCUMENTS_AREA.ELECTORAL,
        fields: getAdditionalFields(variables.customFields, variables)
      }
    }
  })

  const document = isCreating
    ? mappers.electoral_card(data.electoral_card, getters)
    : variables
  const endpoint = getUploadEndpoint(employeeId, document.id, 'electoral_card')

  return [ err, document, endpoint ]
}

export async function setCtps (getters, variables) {
  const isCreating = !variables.id
  const mutation = isCreating ? 'CreateCtps' : 'UpdateCtps'
  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await middleware(set[mutation], {
    input: {
      ...(!isCreating ? { id: variables.id } : {}),
      employee_id: employeeId,
      emission_date: formatDateUS(variables.emission_date),
      number: variables.number,
      pis: variables.pis,
      serial_number: variables.serial_number,
      issuing_state_id: +variables.issuing_state || null,
      additional: {
        sectionId: PERSONAL_DOCUMENTS_AREA.CTPS,
        fields: getAdditionalFields(variables.customFields, variables)
      }
    }
  })

  const document = isCreating
    ? mappers.ctps(data.ctps, getters)
    : variables
  const endpoint = getUploadEndpoint(employeeId, document.id, 'ctps')

  return [ err, document, endpoint ]
}

export async function setCustomDocument (getters, variables) {
  const employeeId = getters.getEmployeeUuid
  const sectionId = variables.id
  const input = {
    employeeId,
    hasAttachment: true,
    additional: {
      sectionId,
      fields: getAdditionalFields(variables.customFields, variables)
    }
  }

  const [ err, data ] = await middleware(set.UpdateCustomForm, { input })
  const { values } = data || {}
  const { attachmentId } = variables
  const { id: documentId } = values?.find(value => value.customFieldId === attachmentId) || {}

  const endpoint = getUploadEndpoint(employeeId, documentId, 'custom_field_value')

  return [ err, data, endpoint ]
}

export function validateDocument (document) {
  let valid = true

  if (document.requiredFiles && !document.files.length)
    valid = false

  if (document.requiredFields.some(key => !document.data[key]))
    valid = false

  Object.values(document.customFields)
    .forEach(field => {
      if (field.type === 'file') return

      if (field.required && !field.value)
        valid = false
    })

  return valid
}
