const parseName = (value) => {
  if (!value) return ''

  const preps = [ 'da', 'das', 'de', 'do', 'dos' ]

  const formatter = txt => preps.indexOf(txt) > -1
    ? txt : txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()

  return value.toLowerCase().split(' ').map(formatter).join(' ')
}

export default parseName
