import feedbackFactory from './feedbackFactory'
import registerStore from './registerStore'

export default {
  install (Vue, store, { delay = 5000, storeName = 'cfeedbacks' } = {}) {
    if (!store) {
      console.error('ConveniaFeedbacks: Vuex store argument missing')
      return false
    }

    registerStore(store, storeName, delay)
    store.dispatch('FEEDBACK_DELAY', delay)

    Object.defineProperty(Vue.prototype, '$feedback', {
      get () {
        return feedbackFactory(this)
      }
    })
  }
}
