import Vue from 'vue'
import { install as ConveniaPkg } from '@convenia/components'
import { initSentry } from './sentry'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.use(ConveniaPkg, store)
Vue.config.productionTip = false

if (import.meta.env.VITE_SENTRY_DSN) initSentry()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
