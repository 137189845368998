<template>
  <picture v-if="type" class="c-character" :style="styles">
    <source
      v-if="size === 'auto'"
      media="(min-width: 1024px)"
      class="source"
      :srcset="`/img/characters/${mappedType}.${isSvg ? 'svg' : 'webp'}`"
      :alt="description"
      :title="description"
    >

    <img
      class="img"
      :src="`/img/characters/${mappedType}.${isSvg ? 'svg' : 'webp'}`"
      :alt="description"
      :title="description"
      :style="styles"
    >
  </picture>
</template>

<script>
/**
 * A component for displaying characters (illustrations).
*/
import { typeMapper } from '@convenia/mixins'

export default {
  name: 'CCharacter',

  mixins: [ typeMapper ],

  props: {
    /**
     * The type (name) of the character. One of:
     * alert
     * calculator
     * cactus
     * clock
     * letter-heart
     * photo
     * paper-cut
     * paper-happy
     * key
     * check
     * photo
     */
    type: {
      type: String,
      default: ''
    },

    /**
     * The character's size.
     */
    size: {
      type: [ String, Number ],
      default: 'auto'
    },

    /**
     * The title of the image tag, basically.
     */
    description: {
      type: String,
      default: '',
    },
  },

  computed: {
    styles () {
      return {
        width: `${(+this.size)}px`,
        height: `${(+this.size)}px`
      }
    }
  }
}
</script>

<style lang="scss">
.c-character { display: block; }

.c-character > * {
  box-sizing: border-box;
  display: block;

  background: {
    size: contain;
    repeat: no-repeat;
    position: center;
  }
}
</style>
