import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import localeData from 'dayjs/plugin/localeData'

import 'dayjs/locale/pt-br'

dayjs.locale('pt-br')
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)
dayjs.extend(localeData)

/**
 * This extension broke several features when enabled. Only enable it if those broken areas
 * have already been fixed using this extension.
 */
// dayjs.extend(objectSupport)

dayjs.tz.setDefault('America/Sao_Paulo')

const dayjsTz = dayjs.tz
const tz = (date, format, strict) => dayjs(date, format, strict).tz('America/Sao_Paulo')

export { dayjs, dayjsTz as tz }

export const dayjsPlugin = {
  install (Vue) {
    Object.defineProperty(Vue.prototype, '$date', {
      get () {
        return dayjs
      }
    })

    Object.defineProperty(Vue.prototype, '$tz', {
      get () {
        return tz
      }
    })
  }
}

export default dayjsPlugin
