import * as types from '@types'
import * as services from '@services/otherInfo'

const state = {
  data: {},
  section: {},
  schema: {}
}

const getters = { }

const mutations = {
  [types.EMPLOYEE_OTHER_INFO]: (state, section) => {
    state.section = section
    state.schema = section.schema
  },

  [types.EMPLOYEE_SET_OTHER_INFO]: (state, data) => {
    state.data = { ...data }
  }
}

const actions = {
  [types.EMPLOYEE_OTHER_INFO]: async ({ commit, getters }) => {
    const employeeId = getters.getEmployeeUuid
    const [ err, section ] = await services.getAdditionalSection(employeeId)
    const schema = section?.schema || {}

    if (!err) {
      const data = Object.entries(schema)
        .reduce((acc, [ key, field ]) => ({
          ...acc, [key]: field.value
        }), {})

      commit(types.EMPLOYEE_SET_OTHER_INFO, data)
      commit(types.EMPLOYEE_OTHER_INFO, section)
    }

    return [ err, schema ]
  },

  [types.EMPLOYEE_SET_OTHER_INFO]: async ({ state, getters, dispatch }, data) => {
    const employeeId = getters.getEmployeeUuid
    const [ err ] = await services.updateAdditionalInfo(employeeId, state.section.id, data)

    if (!err) await dispatch(types.EMPLOYEE_OTHER_INFO, employeeId)

    return [ err ]
  }
}

export default { state, getters, mutations, actions }
