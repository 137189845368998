<template>
  <c-input-container v-bind="$attrs" :class="classes">
    <div
      class="inner"
      @drop.prevent="onAddFiles"
      @dragover.prevent.stop
      @dragenter.prevent.stop
      @dragleave.prevent.stop
    >
      <empty-list
        v-if="!hasFiles"
        class="empty"
        :add-size="addSize"
        :add-text="addText"
        :size-unit="sizeUnit"
        :is-small="isSmall"
        :character="character"
        :multiple="multiple"
        :extensions="extensions"
        :grey="greyDropArea"
        :primary-button="primaryAddButton"
        @add="onSelectFiles"
      >
        <slot name="placeholder" slot="placeholder" />

        <slot
          v-if="!noDropAreaText"
          slot="info"
          name="info"
          v-bind:info="{
            allowedExtensions,
            sizeLimit,
            sizeUnit
          }"
        >
          <p class="text">
            Nós aceitamos os arquivos que estão no formato {{ allowedExtensions }}
          </p>
        </slot>
      </empty-list>

      <c-file-list
        v-else
        class="list"
        :class="{ '-full-width': fullWidth }"
        :disabled="disabled"
        :disabled-files="disabledFiles"
        :files="files"
        :add="!hideAddBox && (multiple || !value.length)"
        :add-position="addPosition"
        :add-size="isMobile ? 'small' : addSize"
        :size-limit="sizeLimit"
        :size-unit="sizeUnit"
        :add-text="addText"
        :selected-files="selectedFiles"
        :transparent-files="transparentFiles"
        :removing-files="removingFiles"
        :removable="removable"
        :downloadable="downloadable"
        :extensions="extensions"
        :nested-upload-text="nestedUploadText"
        :remove-nested="removeNested"
        :download-nested="downloadNested"
        @add="onSelectFiles"
        @remove="onRemoveFile"
        @toggle="$emit('file:toggle', $event)"
        @download="$emit('file:download', $event)"
        @nested-add="onSelectFiles"
        @nested-drag="onAddNestedFile"
        @nested-remove="$emit('nested-file:remove', $event)"
        @toggle-preview="$emit('toggle-preview', $event)"
      >
        <form-field-validation
          v-if="errorMessage"
          class="validation"
          :message="errorMessage"
          :style="{ margin: '-5px auto 10px' }"
        />
      </c-file-list>

      <!-- Here we need to set the value of the native input to null,
        -- otherwise, if we add a file and then remove it, even though
        -- we emit the remove event and the value array we're receiving
        -- as a prop changes, the removed file will remain in the native
        -- input's internal value attribute, and we won't be able to add
        -- the same file again if we need to. So the value here must
        -- be always empty.
        -->
      <input
        ref="input"
        class="input"
        name="upload-file-input"
        type="file"
        :value="null"
        :accept="extensions.map(ext => '.' + ext)"
        :multiple="multiple && !addingNestedFile"
        id="upload-file-input"
        @change.prevent="onAddFiles"
      >
    </div>

    <form-field-validation
      v-if="errorMessage"
      class="validation"
      :message="errorMessage"
    />
  </c-input-container>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

/* eslint-disable no-alert */
import EmptyList from './EmptyList'
import FormFieldValidation from '../CFormBuilder/fragments/FormFieldValidation.vue'
import CUploadFile from './fragments/CUploadFile'

// Default, system-wide accepted extensions
const DEFAULT_EXTENSIONS = [
  'pdf',
  'doc',
  'docx',
  'xlsx',
  'xls',
  'ppt',
  'pptx',
  'txt',
  'gif',
  'jpeg',
  'jpg',
  'png',
  'zip',
  'rar',
  'gz'
]

const unitMultipliers = {
  'B': 1,
  'KB': 1000,
  'MB': 1000000,
  'GB': 1000000000
}

export default {
  name: 'CUpload',

  mixins: [ MediaQuery ],

  components: { EmptyList, FormFieldValidation },

  props: {
    /**
     * List of files.
     */
    value: {
      type: Array,
      required: true
    },

    /**
     * Allowed file extensions (preferably in lowercase)
     */
    extensions: {
      type: Array,
      default: () => DEFAULT_EXTENSIONS
    },

    /**
     * File size limit in the selected unit (megabyte by default)
     */
    sizeLimit: {
      type: [ Number, String ],
      default: 10,
      validator: v => !Number.isNaN(v) && +v > 0
    },

    /**
     * File size limit unit (MB, KB etc)
     */
    sizeUnit: {
      type: String,
      default: 'MB'
    },

    /**
     * Whether the component should support multiple files or not
     */
    multiple: Boolean,

    /**
     * Limits the amount of files that the component should accept.
     */
    fileLimit: {
      type: [ Number, String ],
      default: 0,
    },

    /**
     * Disables the field.
     */
    disabled: {
      type: Boolean,
      default: false
    },

    /**
     * When component is disabled, specify which files should be disabled as well.
     */
    disabledFiles: {
      type: Array,
      default: () => ([])
    },

    /**
     * Change the character image for empty state.
     */
    character: {
      type: String,
      default: undefined
    },

    /**
     * Indicates the position where the Add button from the
     * file-list will be positioned - 'top' or 'bottom'
     */
    addPosition: {
      type: String,
      default: 'top',
      validator: pos => [ 'top', 'bottom' ].includes(pos)
    },

    /**
     * The text that will be displayed in the Add button
     */
    addText: {
      type: String,
      default: 'Procure um arquivo'
    },

    /**
     * The add button size
     */
    addSize: {
      type: String,
      default: 'default'
    },

    /**
     * Whether files in the file list can be downloaded
     */
    downloadable: {
      type: Boolean,
      default: false
    },

    /**
     * Whether files in the file list can be removed or not
     */
    removable: {
      type: Boolean,
      default: true
    },

    /**
     * Hides character and decrease height
     */
    isSmall: Boolean,

    /**
     * Makes it possible to selected files through a checkbox placed beside the file
     */
    selectedFiles: {
      type: Array,
      default: null,
    },

    /**
     * A list of files being asynchronally removed
     */
    removingFiles: {
      type: Array,
      default: () => ([]),
    },

    /**
     * Makes the file list grow to fill the entire width of the container.
     */
    fullWidth: Boolean,

    /**
     * Makes the files have a transparent background
     */
    transparentFiles: Boolean,

    /**
     * The text shown at the top of the nested upload box (when it is available)
     */
    nestedUploadText: {
      type: String,
      default: 'Arraste seu arquivo e solte aqui',
    },

    /**
     * Forces the add-file box to hide when set to true
     */
    hideAddBox: Boolean,

    /**
     * Whether to show the remove option only for nested files
     */
    removeNested: Boolean,

    /**
     * Whether to show the download option only for nested files
     */
    downloadNested: Boolean,

    /**
     * When set to true, hides the default info slot text that
     * goes into the EmptyList fragment, visually that is the
     * informative text which displays what file formats are
     * supported by the component.
     */
    noDropAreaText: Boolean,

    /**
     * Gives a muted/grayish look to the file drop area
     */
    greyDropArea: Boolean,

    /**
     * Keeps file add action button color as primary
     */
    primaryAddButton: Boolean,

    /**
     * Keeps dashed border on mobile
     */
    mobileDashed: Boolean,

    /**
     * The error messages list
     */
    errors: {
      type: Array,
      default: () => ([])
    },
  },

  data: () => ({
    initialFiles: [],
    addingNestedFile: null
  }),

  computed: {
    classes () {
      return [ 'c-upload', {
        '-empty': !this.hasFiles,
        '-is-small': this.isSmall,
        '-mobile-dashed': this.mobileDashed,
      } ]
    },

    files () {
      const files = (this.value || []).map(file => ({
        ...file,
        ...(this.initialFiles.includes(file.id) ? { uploaded: true, progress: 100 } : {})
      }))

      return files || []
    },

    allowedExtensions () {
      return this.extensions.reduce((acc, ext, i) => i === (this.extensions.length - 1)
        ? `${acc} e .${ext.toUpperCase()}`
        : acc.length ? `${acc}, .${ext.toUpperCase()}` : '.' + ext.toUpperCase()
      , '')
    },

    hasFiles () {
      return (this.value || []).length
        && this.value.some(f => f && Object.keys(f).length)
    },

    limitInBytes () {
      return +this.sizeLimit * unitMultipliers[this.sizeUnit]
    },

    errorMessage () {
      if (this.fileLimit && this.value.length > this.fileLimit)
        return `Atingido limite de ${this.fileLimit} ${this.fileLimit === 1 ? 'item' : 'itens'}`

      if (this.errors.length) {
        const [ error ] = this.errors

        return error === 'O campo é obrigatório.' ? 'O anexo é obrigatório.' : error
      }

      return null
    }
  },

  created () {
    this.initialFiles = (this.value || []).map(file => file.id)
  },

  methods: {
    onAddNestedFile ({ parentFile, event: { dataTransfer, target } }) {
      const { files } = dataTransfer || target

      if (files.length > 1)
        return alert('Não é possível adicionar múltiplos arquivos.')

      const file = this.mapFiles(files)[0]

      this.$emit('nested-file:add', { parentFile, file })
      this.addingNestedFile = null
    },

    onAddFiles ({ dataTransfer, target }) {
      if (this.disabled) return

      if (this.addingNestedFile) {
        return this.onAddNestedFile({
          parentFile: this.addingNestedFile,
          event: { dataTransfer, target }
        })
      }

      const { files } = dataTransfer || target

      if (!this.multiple && files.length > 1)
        return alert('Não é possível adicionar múltiplos arquivos.')

      this.$emit('file:add', this.mapFiles(files))
    },

    onRemoveFile (file) {
      const removedFile = (this.value || [])
        .find(({ id }) => file.id === id) || file

      this.$emit('file:remove', removedFile)
    },

    mapFiles (files) {
      return Array.from(files).map(file => ({
        ...(new CUploadFile(file)),
        error: this.getErrorMessage(file),
      }))
    },

    getFileExtension (file) {
      const index = file.name.lastIndexOf('.')

      return (index < 1) ? '' : file.name.substr(index + 1).toLowerCase()
    },

    getErrorMessage (file) {
      const ext = this.getFileExtension(file)

      if (!this.extensions.includes(ext))
        return 'Formato de arquivo não suportado.'

      if (file.size > this.limitInBytes)
        return `Tamanho limite de ${this.sizeLimit}${this.sizeUnit} excedido.`

      return null
    },

    onSelectFiles (file) {
      // 'file' is only available when the event comes from a nested file upload
      if (file) this.addingNestedFile = file
      this.$refs['input'].click()
    }
  }
}
</script>

<style lang="scss">
.c-upload {
  position: relative;
  width: 100%;

  &.-mobile-dashed > .inner > .empty {
    border-radius: 5px;
    border: 1px dashed color-var(text, base-20);
  }

  & > .inner {
    & > .empty {
      padding: 40px 0;

      @include responsive(tablet, desktop) {
        border-radius: 5px;
        border: 1px dashed color-var(text, base-20);
      }
    }

    & > .input { display: none; }

    & > .list {
      width: 100%;
      max-width: 360px;

      @include responsive (tablet, desktop) { max-width: 570px; }
      &.-full-width { max-width: initial; }
    }
  }

  & > .text {
    color: color-var(text, base-30);
    width: 100%;
    display: block;
    text-align: center;
    font-family: var(--base-font-family);
    text-align: center;
    letter-spacing: -0.14px;
    font-size: 14px;
    max-width: 265px;
    margin-top: 20px;
  }

  &:not(.-empty) > .validation { display: none; }

  &.-is-small > .inner > .empty { padding: 20px 0; }

  &.-error > .inner > .c-upload-empty-list > .bottom > .c-button,
  &.-error > .inner > .c-upload-list > .files > .file.-error {
    @extend %animation;
  }
}

@keyframes shakeAnim {
  0% { left: 0 }
  1% { left: -3px }
  2% { left: 5px }
  3% { left: -8px }
  4% { left: 8px }
  5% { left: -5px }
  6% { left: -3px }
  7% { left: 0 }
}

%animation {
  animation-name: shakeAnim;
  animation-duration: 5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in
}
</style>
