<template>
  <div class="custom-documents-container">
    <document-modal
      v-if="showModal"
      ref="modal"
      :value="selected"
      :fields="schema"
      :title="selected.title"
      :text="selected.text"
      :loading="isSubmitting"
      :disabled="isSubmitting"
      @close="onClose"
      @submit="onSubmit"
    />

    <dialog-modal
      v-if="showRemoveFileModal"
      theme="error"
      title="Deseja prosseguir?"
      message="Ao prosseguir os arquivos apagados serão permanentemente excluídos,
        esta ação não pode ser desfeita."
      confirm="Excluir"
      cancel="Não, mudei de ideia"
      @cancel="showRemoveFileModal = false"
      @confirm="onSubmit(filesFormData)"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import * as types from '@types'

import DocumentModal from '@components/DocumentModal'
import DialogModal from '@components/DialogModal'
import schemas from '@forms/document'
import upload from '@modules/upload'

export default {
  name: 'CustomDocuments',

  components: {
    DocumentModal,
    DialogModal,
  },

  data: () => ({
    attachmentId: null,
    showModal: true,
    showRemoveFileModal: false,
    filesFormData: {},
  }),

  props: {
    value: {
      type: String,
      default: null
    }
  },

  computed: {
    ...mapState({
      employeeId: state => state.admission.employeeUuid,
    }),

    ...mapGetters({
      documents: types.DOCUMENT_GET_DOCUMENTS,
    }),

    isSubmitting: {
      get () { return this.$store.state.admission.submiting },
      set (value) { this.$store.commit(types.ADMISSION_SUBMITING, value) }
    },

    schema () {
      const fields = schemas(this)[this.value] || {}
      const { customFields } = this

      return {
        ...fields,
        ...customFields
      }
    },

    customFields () {
      return this.selected?.customFields || {}
    },

    attachment () {
      return this.customFields[this.attachmentId]
    },

    selected () {
      if (!this.documents[this.value]) return {}

      return this.documents[this.value]
    },
  },

  created () {
    Object.keys(this.customFields).forEach(key => {
      if (this.customFields[key].type !== 'file') return

      this.customFields[key].hide = true
      this.attachmentId = key
    })
  },

  methods: {
    ...mapMutations({
      setFiles: types.DOCUMENT_SET_FILES,
    }),

    ...mapActions({
      deleteFile: types.ADMISSION_DELETE_FILE,
      verifyDocuments: types.DOCUMENT_VERIFY_DOCUMENTS,
      setCustomDocument: types.DOCUMENT_SET_CUSTOM,
      getCustomFields: types.DOCUMENT_CUSTOM_FIELDS,
    }),

    onClose () {
      this.clear()
      this.showModal = false

      setTimeout(() => this.$emit('close'), 300)
    },

    async onSubmit ({ data, files, replaced }) {
      if (replaced.length > 0 && !this.showRemoveFileModal) {
        this.filesFormData = { data, files, replaced }
        this.showRemoveFileModal = true

        return
      }

      this.showRemoveFileModal = false
      this.isSubmitting = true

      const { id } = this.selected || {}
      const { attachmentId } = this
      const customFields = Object.keys(this.selected.customFields) || []

      const [ err, , endpoint ] = await this.setCustomDocument({
        id, attachmentId, customFields, ...data
      })

      if (err) {
        this.$feedback.add({
          type: 'error',
          message: 'Erro',
          highlighted: 'ao salvar o documento. Tente novamente mais tarde.'
        })

        this.isSubmitting = false
        return
      }

      const initialFiles = this.selected.files || []
      const addedFiles = files.filter(file =>
        !!file.name && !initialFiles.find(({ id }) => file.id === id)
      )
      await this.deleteFiles(replaced)

      this.uploadFiles(addedFiles, endpoint)
        .then(async () => {
          const removedFiles = replaced.map(file => file.id)
          const updatedFiles = [ ...initialFiles, ...addedFiles ]
            .filter(file => !removedFiles.includes(file.id))
            .map(file => ({ ...file, progress: 0 }))

          this.setFiles({ type: this.value, files: updatedFiles })
          await this.getCustomFields()
          this.verifyDocuments()
          this.onClose()
        })
        .catch(() => {
          this.isSubmitting = false
        })
    },

    async uploadFiles (files, endpoint) {
      const uploads = await upload({ files, endpoint })

      return Promise.all(uploads.map(({ request }) => request))
    },

    async deleteFiles (files) {
      const { employeeId } = this

      await Promise.all(files.map(
        file => this.deleteFile({ file, employeeId }))
      )
    },

    clear () {
      this.filesFormData = {}
      this.isSubmitting = false
    },
  }
}
</script>
