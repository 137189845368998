import { fullNameValidation, fullNameValidationMsg } from '@modules/validate/fullName'

export const isNotForeign = ({ nationalities }) =>
  !nationalities?.length || nationalities?.findIndex((id) => [ 10 ].includes(id)) >= 0

export default (ctx) => ({
  personal: {
    title: 'Dados Pessoais',
    fields: {
      fullName: {
        type: 'text',
        label: 'Nome completo',
        placeholder: 'Nome completo',
        validation: {
          required: true,
          custom: fullNameValidation
        },
        validationMsg: fullNameValidationMsg,
        required: true,
        value: ''
      },
      nationalities: {
        type: 'multi-select',
        label: 'Nacionalidade (s)',
        placeholder: 'Selecione uma ou mais nacionalidades',
        trackBy: 'id',
        displayBy: 'name',
        optionsSrc: 'nationalities'
      },
      state: {
        type: 'select',
        label: 'UF Natal',
        placeholder: 'UF Natal',
        value: '',
        displayBy: 'name',
        trackBy: 'id',
        nullable: true,
        options: [],
        optionsSrc: 'states',
        onInput: async (formData) => {
          if (formData.city) formData.city = undefined

          ctx.cities = await ctx.getCities(formData.state)
        }
      },
      city: {
        type: 'select',
        label: 'Cidade Natal',
        placeholder: 'Cidade Natal',
        value: '',
        nullable: true,
        displayBy: 'name',
        trackBy: 'id',
        optionsSrc: 'cities',
        options: [],
      },
      ethnicity: {
        type: 'select',
        label: 'Cor/Raça',
        placeholder: 'Cor/Raça',
        value: '',
        displayBy: 'name',
        trackBy: 'id',
        options: [],
        optionsSrc: 'ethnicities',
        nullable: true
      },
      genderIdentities: {
        type: 'select',
        label: 'Gênero',
        placeholder: 'Gênero',
        value: undefined,
        nullable: true,
        displayBy: 'name',
        trackBy: 'id',
        options: [],
        optionsSrc: 'genderIdentities',
        onInput: (formData) => {
          if (formData.genderIdentities === 1) formData.gender = 1
          else if (formData.genderIdentities === 2) formData.gender = 2
        }
      },
      gender: {
        type: 'select',
        label: 'Gênero no Documento',
        placeholder: 'Gênero no Documento',
        nullable: true,
        displayBy: 'name',
        trackBy: 'id',
        options: [],
        optionsSrc: 'genders',
        validation: 'required',
        required: true,
        hint:
          'Infelizmente o e-social não contempla as opções de gênero da plataforma. Não se preocupe que esta informação servirá apenas para nível de e-social e ninguém além de você e o RH terá acesso a ela :)',
        hide: (formData) => {
          return !formData.genderIdentities
            || formData.genderIdentities === 1
            || formData.genderIdentities === 2
        }
      },
      socialName: {
        type: 'text',
        label: 'Nome Social',
        placeholder: 'Nome Social',
        hint: 'Nome social é o nome que pessoas transgêneros e travestis preferem ser chamadas, e possui a mesma proteção concedida ao nome de registro. É assegurado pelo Decreto 8727/16.',
        value: ''
      },
      civilStatus: {
        type: 'select',
        label: 'Estado Civil',
        placeholder: 'Estado Civil',
        value: '',
        displayBy: 'name',
        trackBy: 'id',
        options: [],
        optionsSrc: 'civilStatus'
      },
      birthday: {
        type: 'text',
        inputmode: 'numeric',
        label: 'Data de nascimento',
        placeholder: 'Data de nascimento',
        validation: {
          date: true
        },
        mask: '##/##/####',
        value: ''
      },
      motherName: {
        type: 'text',
        label: 'Nome da mãe',
        placeholder: 'Nome da mãe',
        validation: {
          custom: fullNameValidation
        },
        validationMsg: fullNameValidationMsg,
        value: ''
      },
      fatherName: {
        type: 'text',
        label: 'Nome do pai',
        placeholder: 'Nome do pai',
        validation: {
          custom: fullNameValidation
        },
        validationMsg: fullNameValidationMsg,
        value: ''
      }
    }
  },

  foreign: {
    title: 'Estrangeiro',
    fields: {
      livesInBrazil: {
        type: 'radio',
        label: 'Reside no Brasil?',
        value: false,
        displayBy: 'label',
        trackBy: 'value',
        options: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false }
        ]
      },
      visa: {
        type: 'select',
        label: 'Tipo de visto',
        placeholder: 'Tipo de visto',
        options: [],
        optionsSrc: 'visaTypes',
        displayBy: 'name',
        trackBy: 'id',
        value: '',
        hide: ({ livesInBrazil }) => !livesInBrazil
      },
      arrivalDate: {
        type: 'text',
        inputmode: 'numeric',
        label: 'Data de chegada',
        placeholder: 'Data de chegada',
        mask: '##/##/####',
        validation: {
          date: true
        },
        validationMsg: 'Data inválida.',
        hide: ({ livesInBrazil }) => !livesInBrazil
      },
      naturalizationDate: {
        type: 'text',
        inputmode: 'numeric',
        label: 'Data de naturalização',
        placeholder: 'Data de naturalização',
        mask: '##/##/####',
        validation: {
          date: true
        },
        validationMsg: 'Data inválida.',
        hide: ({ livesInBrazil }) => !livesInBrazil
      },
      residenceTime: {
        type: 'radio',
        label: 'Tempo de residência no brasil',
        value: '',
        displayBy: 'name',
        trackBy: 'id',
        options: [],
        optionsSrc: 'residenceTimes',
        hide: ({ livesInBrazil }) => !livesInBrazil
      },
      entryCondition: {
        type: 'select',
        label: 'Condição de ingresso',
        placeholder: 'Condição de ingresso',
        options: [],
        optionsSrc: 'entryConditions',
        displayBy: 'name',
        trackBy: 'id',
        value: '',
        hide: ({ livesInBrazil }) => !livesInBrazil
      },
      country: {
        type: 'select',
        label: 'País de origem',
        placeholder: 'País de origem',
        options: [],
        optionsSrc: 'countries',
        displayBy: 'name',
        trackBy: 'id',
        value: '',
        nullable: true,
      },
      zipCodeForeign: {
        type: 'text',
        label: 'CEP no exterior',
        placeholder: 'Insira o CEP',
        validation: '',
        maxLength: 67,
        validationMsg: 'Informe um CEP válido.',
        value: '',
        hide: ({ livesInBrazil }) => livesInBrazil
      },
      foreignAddress: {
        type: 'text',
        label: 'Endereço no exterior',
        placeholder: 'Insira o endereço',
        value: '',
        validation: '',
        maxLength: 67,
        hide: ({ livesInBrazil }) => livesInBrazil
      },
      addressDescription: {
        type: 'select',
        label: 'Descrição do logradouro',
        placeholder: 'Insira a descrição do logradouro',
        options: [],
        optionsSrc: 'addressDescription',
        displayBy: 'name',
        trackBy: 'id',
        value: '',
        maxLength: 67,
        hide: ({ livesInBrazil }) => livesInBrazil
      },
      number: {
        type: 'text',
        label: 'Número no exterior',
        placeholder: 'Insira o número',
        value: '',
        maxLength: 7,
        validation: '',
        hide: ({ livesInBrazil }) => livesInBrazil
      },
      complement: {
        type: 'text',
        label: 'Complemento no exterior',
        placeholder: 'Insira o complemento',
        validation: '',
        value: '',
        maxLength: 67,
        hide: ({ livesInBrazil }) => livesInBrazil
      },
      district: {
        type: 'text',
        label: 'Bairro/Distrito no exterior',
        placeholder: 'Insira o bairro',
        validation: '',
        value: '',
        maxLength: 67,
        hide: ({ livesInBrazil }) => livesInBrazil
      },
      foreignCity: {
        type: 'text',
        label: 'Cidade no exterior',
        placeholder: 'Insira a cidade',
        validation: '',
        value: '',
        maxLength: 67,
        hide: ({ livesInBrazil }) => livesInBrazil
      },
      marriedToBrazilian: {
        type: 'radio',
        label: 'Casado(a) com brasileiro(a)?',
        value: undefined,
        displayBy: 'label',
        trackBy: 'value',
        options: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false },
        ]
      },
      hasBrazilianOffspring: {
        type: 'radio',
        label: 'Tem filho(a) brasileiro(a)?',
        value: undefined,
        displayBy: 'label',
        trackBy: 'value',
        options: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false }
        ]
      }
    }
  },

  handicap: {
    fields: {
      hasDeficiency: {
        type: 'radio',
        label: 'Possui alguma deficiência?',
        validation: 'required',
        required: true,
        displayBy: 'label',
        trackBy: 'value',
        options: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false }
        ]
      },
      disability: {
        type: 'select',
        options: [],
        optionsSrc: 'disabilities',
        displayBy: 'name',
        trackBy: 'id',
        label: 'Tipo',
        placeholder: 'Selecione um tipo',
        nullable: true,
        isDisabled: formData => !formData.hasDeficiency,
      },
      observations: {
        type: 'text',
        label: 'Observações',
        placeholder: 'Escreva aqui',
        textArea: true,
        rows: '2',
        isDisabled: formData => !formData.hasDeficiency,
      }
    }
  },

  realEstate: {
    fields: {
      hasRealEstate: {
        type: 'radio',
        label: 'Possui imóvel próprio',
        validation: 'required',
        required: true,
        value: false,
        displayBy: 'label',
        trackBy: 'value',
        options: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false }
        ]
      },
      acquiredWithFGTS: {
        type: 'radio',
        label: 'Adquirido pelo FGTS',
        value: undefined,
        displayBy: 'label',
        trackBy: 'value',
        options: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false },
        ],
        isDisabled: formData => !formData.hasRealEstate
      }
    }
  }
})
