import * as get from '@graphql/middleware/queries'
import middleware from '@graphql/middleware'

export const getCountries = async () => {
  const [ err, { employeeOptions: { countries } = {} } ] =
    await middleware(get.GetCountries) || {}

  return [ err, countries ]
}

export const getStates = async () => {
  const [ err, { employeeOptions } ] = await middleware(get.GetStates)
  const states = ((employeeOptions || {}).states || []).map(state => ({ ...state, id: +state.id }))

  return [ err, states ]
}

export const getCities = async stateId => {
  if (!stateId) return [ null, [] ]

  const [ err, { state: { id, cities = [] } = {} } = {} ] =
    await middleware(get.GetCities, { stateId: +stateId }) || {}
  const mappedCities = cities.map(city => ({ ...city, state_id: id }))

  return [ err, mappedCities ]
}
