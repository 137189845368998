import * as services from '@services/bankAccount'
import * as types from '@types'
import { getCustomFields } from '@services/personalInfo'

export default {
  state: {
    bankAccount: [
      { id: new Date().getUTCMilliseconds(), files: [] }
    ],
    options: {
      bankAccount: {
        bank: [],
        accountType: []
      }
    },
    customFields: {}
  },

  getters: {
    [types.EMPLOYEE_BANK_OPTIONS]: ({ options = {} }) => {
      const bankAccount = options.bankAccount || {}
      const bankOptions = ((bankAccount || {}).bank || [])
        .map(bank => {
          const { name = '', code = '' } = bank
          return { ...bank, name: `${name} - ${code}` }
        })

      return {
        bankAccount: {
          ...bankAccount,
          bank: bankOptions
        }
      }
    }
  },

  mutations: {
    [types.EMPLOYEE_BANK_OPTIONS]: (state, options) => {
      state.options = { ...options }
    },

    [types.EMPLOYEE_BANK]: (state, data) => {
      state.bankAccount = data.length ? data : state.bankAccount
    },

    [types.EMPLOYEE_ADD_BANK]: (state) => {
      state.bankAccount = [
        ...state.bankAccount,
        { id: new Date().getUTCMilliseconds(), files: [] }
      ]
    },

    [types.EMPLOYEE_SET_BANK]: (state, { data, id }) => {
      const index = state.bankAccount.findIndex(bank => bank.id === id)

      if (index >= 0)
        state.bankAccount.splice(index, 1, { files: [], ...data })
      else
        state.bankAccount.push({ ...data })
    },

    [types.EMPLOYEE_DELETE_BANK]: (state, index) => {
      state.bankAccount.splice(index, 1)
    },

    [types.EMPLOYEE_BANK_CUSTOM_FIELDS]: (state, data) => {
      state.customFields = { ...data }

      state.bankAccount = state.bankAccount.map(values => ({
        ...values,
        ...(state.customFields.bankAccounts.values[values.id] || {})
      }))
    }
  },

  actions: {
    [types.EMPLOYEE_BANK_OPTIONS]: async ({ state, commit }) => {
      if (state?.options?.bankAccount?.bank?.length) return [ false, state.options ]
      const [ err, options ] = await services.getOptions()

      if (!err) commit(types.EMPLOYEE_BANK_OPTIONS, options)

      return [ err, options ]
    },

    [types.EMPLOYEE_BANK_CUSTOM_FIELDS]: async ({ commit, getters }) => {
      const [ err, data ] = await getCustomFields(getters)

      if (!err) commit(types.EMPLOYEE_BANK_CUSTOM_FIELDS, data)

      return [ err, data ]
    },

    [types.EMPLOYEE_BANK]: async ({ getters, commit }) => {
      const [ err, data ] = await services.getBankAccount(getters)

      if (!err) commit(types.EMPLOYEE_BANK, data)

      return [ err, data ]
    },

    [types.EMPLOYEE_SET_BANK]: async ({ getters, commit }, variables) => {
      const [ err, bankAccount, endpoint ] = await services.setBankAccount(getters, variables)

      if (!err) commit(types.EMPLOYEE_SET_EDUCATION, { data: bankAccount, id: variables.id })

      return [ err, bankAccount, endpoint ]
    },

    [types.EMPLOYEE_SET_BANKS]: async ({ commit, getters }, bankAccounts) => {
      const submitted = []
      const errors = []

      await Promise.all((bankAccounts || []).map(ban => services.setBankAccount(getters, ban)
        .then(([ err, bank ]) => err ? errors.push(err) : submitted.push(bank))
        .catch(err => errors.push(err))))

      if (!errors.length) commit(types.EMPLOYEE_BANK, submitted)

      return [ errors.length, submitted ]
    },

    [types.EMPLOYEE_DELETE_BANK]: async ({ state, getters, commit }, bankAccount) => {
      const index = state.bankAccount.indexOf(bankAccount)
      const [ err ] = await services.deleteBankAccount(getters, bankAccount)

      if (!err) commit(types.EMPLOYEE_DELETE_BANK, index)

      return [ err, null ]
    }
  }
}
