import { masker } from '@modules/formatters'
import { formatDate } from '@modules/dateHelpers'
import { headers } from '@modules/rest'
import { PERSONAL_DOCUMENTS_AREA } from '@content/consts/Documents'

export const getFileName = (name = '', extension) =>
  !name.includes('.') && extension
    ? name + '.' + extension
    : name

export const getPreviewUrl = (file, getters) => {
  const employeeId = getters.getEmployeeUuid
  const fileId = file.id
  const url = `${import.meta.env.VITE_EMPLOYEE_REST_API}/employee/${employeeId}/file/${fileId}/previewFile`

  return fetch(url, {
    headers: headers([ 'Authorization' ])
  })
    .then(response => response.blob())
    .then(blob => (window.URL || window.webkitURL).createObjectURL(blob))
}

export const mapFiles = (files, getters) => (files || []).map(file => ({
  id: file.id,
  name: getFileName(file.file_name, file.extension),
  size: file.size,
  url: file.download_url,
  preview: () => getPreviewUrl(file, getters)
}))

export const mappers = {
  rg: (data = {}, getters) => ({
    ...data,
    emission_date: formatDate(data.emission_date),
    issuing_state: +data.issuing_state_id || null,
    files: mapFiles(data.files, getters)
  }),

  driver_license: (data = {}, getters) => ({
    ...data,
    emission_date: formatDate(data.emission_date),
    validate_date: formatDate(data.validate_date),
    files: mapFiles(data.files, getters)
  }),

  cpf: (data = {}, getters) => ({
    ...data,
    files: mapFiles((data || []).files, getters)
  }),

  electoral_card: (data = {}, getters) => ({
    ...data,
    state: data.state?.id ? +data.state.id : null,
    city: data.city?.id ? +data.city.id : null,
    files: mapFiles(data.files, getters)
  }),

  ctps: (data = {}, getters) => ({
    ...data,
    emission_date: formatDate(data.emission_date),
    issuing_state: +data.issuing_state_id || null,
    files: mapFiles(data.files, getters)
  }),

  residence_proof: (data = {}, getters) => ({
    ...data,
    zip_code: masker('#####-###', data.zip_code),
    state: +data.state_id || null,
    files: mapFiles(data.files, getters)
  }),

  reservist: ({ files, employeeId, ...data } = {}) => ({
    ...(data || {}),
    files: mapFiles(files, employeeId) || []
  }),

  other_document: (data = {}, getters) => ({
    ...data,
    files: mapFiles(data.files, getters)
  })
}

export const documentsMapper = (data, getters) => ({
  rg: mappers.rg(data.rg || {}, getters),
  driver_license: mappers.driver_license(data.driver_license || {}, getters),
  cpf: mappers.cpf(data.cpf || {}, getters),
  electoral_card: mappers.electoral_card(data.electoral_card || {}, getters),
  ctps: mappers.ctps(data.ctps || {}, getters),
  residence_proof: mappers.residence_proof(data.residence_proof || {}, getters),
  reservist: mappers.reservist(data.reservist || {}, getters),
  other_document: mappers.other_document(data.other_document || {}, getters)
})

export const mapCustomFields = (data) => {
  const rg = data.find(({ id }) => id === PERSONAL_DOCUMENTS_AREA.RG)
  const driver_license = data.find(({ id }) => id === PERSONAL_DOCUMENTS_AREA.CNH)
  const cpf = data.find(({ id }) => id === PERSONAL_DOCUMENTS_AREA.CPF)
  const reservist = data.find(({ id }) => id === PERSONAL_DOCUMENTS_AREA.RESERVIST)
  const electoral_card = data.find(({ id }) => id === PERSONAL_DOCUMENTS_AREA.ELECTORAL)
  const ctps = data.find(({ id }) => id === PERSONAL_DOCUMENTS_AREA.CTPS)

  const customDocuments = data
    .filter(({ id }) => !Object.values(PERSONAL_DOCUMENTS_AREA).includes(id))
    .reduce((acc, { id, schema }) => ({ ...acc, [id]: schema }), {})

  return {
    rg: rg?.schema || {},
    driver_license: driver_license?.schema || {},
    cpf: cpf?.schema || {},
    reservist: reservist?.schema || {},
    electoral_card: electoral_card?.schema || {},
    ctps: ctps?.schema || {},
    ...customDocuments
  }
}

export const mapCustomDocument = (data, getters) => ({
  ...data,
  files: mapFiles(data.documentFiles, getters)
})
