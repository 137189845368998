<template>
  <div class="c-form-group">
    <div v-if="mainSection" class="main">
      <c-title v-if="mainSection.title" dark-text>
        {{ mainSection.title }}
      </c-title>

      <c-form-builder
        ref="mainForm"
        class="form"
        no-actions
        show-overflow
        no-bottom-shadow
        no-upper-shadow
        :disabled="disabled"
        :label-left="labelLeft"
        :schema="mainSection.fields"
        :value="mainForm"
        @input="setMainForm"
        v-on="{ ...$listeners, submit: () => {} }"
      >
        <slot name="main-section" />

        <slot
          slot="footer"
          name="main-footer"
        />

        <slot
          slot="actions"
          name="main-actions"
        />
      </c-form-builder>
    </div>

    <div class="groups">
      <div
        v-for="(group) in groups"
        :key="group.groupName"
        class="group"
      >
        <c-title
          v-if="group.groupLabel"
          class="title"
          dark-text
        >
          {{ group.groupLabel }}
        </c-title>

        <div class="forms">
          <div
            v-for="(form, index) in group.forms"
            :key="`${group.groupName}-form-${index}`"
            class="form-wrapper"
          >
            <div class="heading">
              <c-flag
                :icon="group.headerIcon"
                class="flag"
                size="40"
                icon-size="18"
                grey
              />

              <span class="label">
                {{ index + 1 }}ª {{ group.headerLabel }}
              </span>

              <template v-if="isItemDeletable(group, index)">
                <c-button
                  class="btn-delete"
                  flat
                  error
                  icon="trash"
                  icon-size="18"
                  size="30"
                  @click="handleDeleteGroupItem(group, index)"
                />

                <slot
                  :name="`${group.groupName}-delete-modal`"
                  :attrs="{ isOpened: !!(isDeletingGroupItem || [])[1] }"
                  :listeners="{
                    cancel: () => { isDeletingGroupItem = null },
                    delete: () => { deleteGroupItem(...isDeletingGroupItem) },
                  }"
                />
              </template>
            </div>

            <c-form-builder
              ref="groupForms"
              class="form"
              no-actions
              show-overflow
              no-bottom-shadow
              no-upper-shadow
              :label-left="labelLeft"
              :schema="group.fields"
              :fields-options="fieldsOptions"
              :disabled="disabled || group.disabled"
              :value="form"
              @input="setGroupForm(group, index, $event)"
            >
              <template
                v-for="slot in group.slots"
                :slot="slot"
                slot-scope="fieldProps"
              >
                <slot
                  :name="`${group.groupName}-${slot}`"
                  v-bind="fieldProps"
                />
              </template>

              <template v-for="(_, fieldName) in group.fields">
                <slot
                  :slot="`${fieldName}-divider`"
                  :name="`${group.groupName}-${fieldName}-divider`"
                />
              </template>

              <slot :name="group.groupName" />

              <slot
                slot="footer"
                :name="`${group.groupName}-footer`"
              />

              <slot
                slot="actions"
                :name="`${group.groupName}-actions`"
              />
            </c-form-builder>
          </div>
        </div>

        <c-add
          class="add-box"
          :text="group.addBtnLabel"
          :icon-only="!group.addBtnLabel"
          :mobile-icon-only="!group.addBtnLabel"
          @add="addFormToGroup(group)"
        />
      </div>
    </div>

    <slot name="after-groups" />

    <div v-if="!noActions" class="actions">
      <slot name="actions">
        <c-button primary class="action">
          Salvar
        </c-button>
      </slot>
    </div>
  </div>
</template>

<script>
import { get, setObjProp } from '@convenia/helpers'

import CFormBuilder from '../CFormBuilder/index.vue'
import CTitle from '../CTitle/index.vue'
import CFlag from '../CFlag/index.vue'
import CAdd from '../CAdd/index.vue'

export default {
  name: 'CFormGroup',

  components: {
    CFormBuilder,
    CTitle,
    CFlag,
    CAdd
  },

  props: {
    schema: {
      type: Object,
      required: true
    },

    fieldsOptions: {
      type: Object,
      default: () => ({})
    },

    labelLeft: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false
    },

    noActions: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    mainForm: {},

    groupsData: {},

    isDeletingGroupItem: null,
  }),

  watch: {
    schema: {
      immediate: true,
      deep: true,
      handler () {
        this.groupsData = this.initializeGroups()
      }
    }
  },

  computed: {
    mainSection () {
      return (this.schema || {}).main || null
    },

    groups () {
      const groups = (this.schema || {}).groups || []

      return groups.map(group => ({
        ...group,
        valuePath: `groupsData[${group.groupName}]`,
        forms: this.groupsData[group.groupName],
        slots: Object
          .entries((group || {}).fields)
          .filter(([ , field ]) => field.type === 'slot')
          .map(([ fieldName ]) => fieldName + '-slot')
      }))
    },

    mappedFormData () {
      return {
        ...(this.mainForm || {}),
        ...(this.groupsData || {})
      }
    }
  },

  methods: {
    get,

    async onSubmit () {
      const { mainForm, groupForms } = this.$refs
      const allForms = [
        ...(this.mainSection && mainForm ? [ mainForm ] : []),
        ...groupForms
      ]

      const isValid = (await Promise.all(allForms
        .map(form => form.onSubmit()))).every(Boolean)

      if (isValid) this.$emit('submit', this.mappedFormData)

      return this.mappedFormData
    },

    handleDeleteGroupItem (group, index) {
      if (!this.$scopedSlots[`${group.groupName}-delete-modal`]) {
        this.deleteGroupItem(group, index)
        return
      }

      this.isDeletingGroupItem = [ group, index ]
    },

    deleteGroupItem (group = {}, index) {
      const { [group.groupName]: groupData } = this.groupsData
      if (!groupData || !groupData[index]) return

      groupData.splice(index, 1)
      this.isDeletingGroupItem = null
    },

    addFormToGroup (group) {
      this.groupsData[group.groupName].push({})
    },

    setMainForm (data) {
      this.mainForm = { ...data }
    },

    isItemDeletable ({ requiredItems } = {}, index) {
      return !requiredItems || (index + 1) > requiredItems
    },

    setGroupForm (group, index, data) {
      setObjProp(this.groupsData, [ group.groupName, index ], data)
    },

    initializeGroups () {
      const formData = {}

      const groups = (this.schema || {}).groups || []
      groups?.forEach(group => {
        group.forms?.forEach(form => {
          if (!formData[group.groupName])
            formData[group.groupName] = []

          formData[group.groupName].push(form)
        })
      })

      this.groups?.forEach(group => {
        if (!formData[group.groupName])
          formData[group.groupName] = []

        if (group.forms) {
          group.forms.forEach((form, index) => {
            if (!formData[group.groupName])
              formData[group.groupName] = []

            if (!formData[group.groupName][index])
              formData[group.groupName].push(form)
          })
        }
      })

      this.groups?.forEach(group => {
        if (!formData[group.groupName].length)
          formData[group.groupName].push({})
      })

      return formData
    }
  }
}
</script>

<style lang="scss">

.c-form-group {
  @include responsive (xs-mobile, mobile) {
    & > .main { margin-top: -10px; }
  }

  & > .groups > .group {
    @include responsive (xs-mobile, mobile) { margin-top: 10px; }

    & > .title {
      margin-bottom: 20px;

      @include responsive (tablet, desktop) { margin-bottom: 30px; }
    }

    & > .forms > .form-wrapper {
      padding-bottom: 10px;
      margin-bottom: 30px;
      border-bottom: 1px solid color-var(text, base-10);

      @include responsive (tablet, desktop) {
        margin-bottom: 50px;
        padding-bottom: 40px;
      }
    }

    & > .forms > .form-wrapper > .heading {
      display: flex;
      align-items: center;

      padding-bottom: 10px;

      // The heading area has to occupy the same visual width
      // as the other fields, ideally this shouldn't be a
      // hardcoded value but there's no way around it currently
      @include responsive (tablet, desktop) { width: 570px; }

      & > .label { @include typo(h2); margin-left: 10px; }
      & > .btn-delete { margin-left: auto; }
    }

    & > .add-box {
      height: auto;
      padding: 30px 0;
    }
  }

  & > .actions {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 40px 0 50px 0;

    & > .action {
      width: 100%;
      max-width: 180px;
    }
  }
}
</style>
