const characters = [
  'paper',
  'paper-sad',
  'paper-edit',
  'cactus',
  'cactus-vacation',
  'calculator',
  'clock',
  'convite',
  'desligar_folha',
  'empty_upload',
  'envio_contabilidade',
  'error',
  'excluir_folha',
  'invite',
  'key',
  'senha',
  'lou',
  'success',
  'tablet',
  'trash',
  'upload',
  'warning',
  'lou2'
]

export { characters }
