<template>
  <component
    :is="`h${size}`"
    class="c-title"
    :class="titleClasses"
  >
    <span
      v-if="truncate"
      v-tooltip.ellipsis.dark="{ maxWidth: 150, position: tooltipPosition }"
      class="text"
    >
      <!-- @slot The content slot. -->
      <slot />
    </span>

    <span v-else class="text">
      <slot />
    </span>

    <span
      v-if="!noBorder"
      :class="borderClasses"
      :style="borderStyles"
    />
  </component>
</template>

<script>
/**
 * A title component.
 */
export default {
  name: 'CTitle',

  props: {
    /**
     * Size of the title from 1 to 6, corresponds to: h1, h2, h3, h4, h5, h6.
     */
    size: {
      type: [ String, Number ],
      default: 3
    },

    /**
     * The color of the bottom border of the title. Overrides
     * the `red`, `green` and `yellow` props.
     */
    borderColor: {
      type: [ String, Array ],
      validator: (color) => typeof color === 'string' || color.length,
      default: '',
    },

    /**
     * Paints the border with the default red gradient.
     */
    red: Boolean,

    /**
     * Paints the border with the default green gradient.
     */
    green: Boolean,

    /**
     * Centers the border relative to the text.
     */
    center: Boolean,

    /**
     * Paints the border with the default yellow gradient.
     */
    yellow: Boolean,

    /**
     * Paints the border with the default blue gradient.
     */
    blue: Boolean,

    /**
     * Paints the border with a grayish color map-get($text-color, base-10)
     */
    grey: Boolean,

    /*
     * Paints the border with solid white.
     */
    white: Boolean,

    /**
     * Removes the title border.
     */
    noBorder: Boolean,

    /**
     * Makes the text opacity darker (map-get($text-color, base-50))
     */
    darkText: {
      type: Boolean,
      default: true
    },

    /**
     * Creates a highlight box
     */
    highlight: Boolean,

    /**
     * Truncate title text
     */
    truncate: Boolean,

    /**
     * Tooltip position
     */
    tooltipPosition: {
      type: String,
      default: 'top'
    }
  },

  computed: {
    borderClasses () {
      return [ 'border',
        {
          '-yellow': !this.noBorder && this.yellow,
          '-green': !this.noBorder && this.green,
          '-blue': !this.noBorder && this.blue,
          '-grey': !this.noBorder && this.grey,
          '-red': !this.noBorder && this.red,
          '-white': !this.noBorder && this.white
        }
      ]
    },

    titleClasses () {
      return {
        '-border': !this.noBorder,
        '-center': this.center,
        '-dark-text': this.darkText,
        '-truncate': this.truncate,
        '-highlight': this.highlight
      }
    },

    borderStyles () {
      const background = Array.isArray(this.borderColor) && !!this.borderColor.length
        ? `linear-gradient(90deg, ${this.borderColor.join(', ')})`
        : this.borderColor

      return { background }
    }
  }
}
</script>

<style lang="scss">
$c-title-bar-size: 90px 3px;

.c-title {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;

  & > .text {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: color-var(text, base-50);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  & > .border {
    margin-top: 8px;
    width: 90px;
    height: 4px;
    border-radius: 4px;

    background: color-var();
    transition: background .3s;

    &.-green { background: color-var(positive); }
    &.-yellow { background: color-var(alert); }
    &.-blue { background: color-var(info); }
    &.-red { background: color-var(negative); }
    &.-grey { background: color-var(text, base-10); }
    &.-white { background: #fff; }
  }

  &.-truncate { overflow: hidden; }

  &.-center { align-items: center; }

  &.-dark-text > .text { color: color-var(text, base-80); }

  &.-highlight {
    position: relative;
    z-index: 1;
    &:before {
      content: "";
      z-index: -1;

      position: absolute;
      top: -2px;
      left: -4px;
      right: -4px;
      bottom: 10px;

      border-radius: 4px;
      background: rgba(color-var(alert, base-rgb), .2);
    }
  }
}
</style>
