import * as types from '@types'
import * as services from '@services/personalInfo'

export default {
  state: {
    personal: {},
    foreign: {},
    handicap: {},
    realEstate: {},
    options: {},
    customFields: {}
  },

  getters: {
    [types.EMPLOYEE_PERSONAL_INFO]: state => ({ ...state.personal })
  },

  mutations: {
    [types.EMPLOYEE_UPDATE_PERSONAL]: (state, data) => {
      state.personal = { ...state.personal, ...data }
    },
    [types.EMPLOYEE_UPDATE_FOREIGN]: (state, data) => {
      state.foreign = { ...state.foreign, ...data }
    },
    [types.EMPLOYEE_UPDATE_HANDICAP]: (state, data) => {
      state.handicap = { ...state.handicap, ...data }
    },
    [types.EMPLOYEE_UPDATE_REAL_ESTATE]: (state, data) => {
      state.realEstate = { ...state.realEstate, ...data }
    },
    [types.EMPLOYEE_PERSONAL_OPTIONS]: (state, options) => {
      state.options = { ...options }
    },

    [types.EMPLOYEE_PERSONAL_CUSTOM_FIELDS]: (state, data) => {
      state.customFields = { ...data }
    }
  },

  actions: {
    [types.EMPLOYEE_PERSONAL_INFO]: async ({ commit, getters }) => {
      const [ err, data ] = await services.getPersonal(getters)

      if (!err) {
        commit(types.EMPLOYEE_UPDATE_PERSONAL, data.personal)
        commit(types.EMPLOYEE_UPDATE_FOREIGN, data.foreign)
        commit(types.EMPLOYEE_UPDATE_HANDICAP, data.handicap)
        commit(types.EMPLOYEE_UPDATE_REAL_ESTATE, data.realEstate)
      }

      return [ err, data ]
    },

    [types.EMPLOYEE_PERSONAL_CUSTOM_FIELDS]: async ({ commit, getters }) => {
      const [ err, data ] = await services.getCustomFields(getters)

      if (!err) commit(types.EMPLOYEE_PERSONAL_CUSTOM_FIELDS, data)

      return [ err, data ]
    },

    [types.EMPLOYEE_PERSONAL_OPTIONS]: async ({ state, commit }) => {
      if (Object.keys(state.options).length) return

      const [ err, options ] = await services.getOptions()

      if (!err) commit(types.EMPLOYEE_PERSONAL_OPTIONS, options)

      return [ err, options ]
    },

    [types.EMPLOYEE_UPDATE_PERSONAL]: async ({ getters, commit }, data) => {
      const [ err, personal ] = await services.setPersonal(getters, data)

      if (!err) commit(types.EMPLOYEE_UPDATE_PERSONAL, personal)

      return [ err, personal ]
    },

    [types.EMPLOYEE_UPDATE_FOREIGN]: async ({ state, getters, commit, dispatch }, data) => {
      if (!state.foreign.id) await dispatch(types.EMPLOYEE_PERSONAL_INFO)

      const variables = { id: state.foreign.id, ...data }
      const [ err, foreign ] = await services.setForeign(getters, variables)

      if (!err) commit(types.EMPLOYEE_UPDATE_FOREIGN, foreign)

      return [ err, data ]
    },

    [types.EMPLOYEE_UPDATE_HANDICAP]: async ({ commit, getters }, data) => {
      const [ err, handicap ] = await services.setHandicap(getters, data)

      if (!err) commit(types.EMPLOYEE_UPDATE_HANDICAP, handicap)

      return [ err, data ]
    },

    [types.EMPLOYEE_UPDATE_REAL_ESTATE]: async ({ getters, commit }, data) => {
      const [ err, realEstate ] = await services.setRealEstate(getters, data)

      if (!err) commit(types.EMPLOYEE_UPDATE_REAL_ESTATE, realEstate)

      return [ err, data ]
    },
  }
}
