<template>
  <popover
    class="historical-change-info-popover"
    :target="popoverTarget"
    :scroll-target="scrollTarget"
    @close="$emit('close')"
  >
    <div class="header">
      <BaseHeader
        close
        active
        class="header"
        action-size="md"
        :header-data="headerTemplates[action] || {}"
        v-bind="{ additionalInfo, action }"
        @close="$emit('close')"
      >
        <template #header-content="{ headerContent }">
          <slot name="header-content" :header-content="headerContent" />
        </template>
      </BaseHeader>
    </div>

    <c-shadowed ref="scrollTargetParent" has-upper-shadow shadow-color="#ffffff">
      <info :format-field="formatField" v-bind="$attrs" />
    </c-shadowed>
  </popover>
</template>

<script>
import Popover from './Popover'
import BaseHeader from './Headers/Base'
import Info from './Info'

export default {
  name: 'HistoricalChangeInfoPopover',

  components: {
    Info,
    BaseHeader,
    Popover
  },

  data () {
    return { scrollTarget: null }
  },

  props: {
    action: {
      type: String,
      required: true
    },

    popoverTarget: {
      type: [ Object, Element ],
      default: null,
    },

    headerTemplates: {
      type: Object,
      default: () => ({})
    },

    additionalInfo: {
      type: Object,
      default: () => ({})
    },

    formatField: {
      type: Function,
      default: null,
    },
  },

  mounted () {
    this.scrollTarget = ((this.$refs.scrollTargetParent || {}).$refs || {}).shadowed || {}
  }
}
</script>

<style lang="scss">
.historical-change-info-popover > .popover {
  max-height: calc(100% - 20px);
  max-width: unset;
  display: flex;
  flex-direction: column;

  & > .actions {
    overflow: hidden;

    & > .c-shadowed > .wrapper {
      overflow-y: auto;
      display: block;
      padding-bottom: 20px;
    }

    & > .c-shadowed::after {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}
</style>
