import * as types from '@types'
import * as services from '@services/contact'

export default {
  state: {
    address: { },
    contact: { },
    emergency: [
      { id: new Date().getUTCMilliseconds() }
    ],
    options: {},
    customFields: {}
  },

  getters: { },

  mutations: {
    [types.EMPLOYEE_ADDRESS_SET]: (state, address) => {
      state.address = {
        ...state.address,
        ...address
      }
    },

    [types.EMPLOYEE_CONTACT_SET]: (state, contact) => {
      state.contact = {
        ...state.contact,
        ...contact
      }
    },

    [types.EMPLOYEE_EMERGENCY_CONTACT_ADD]: (state) => {
      state.emergency = [
        ...state.emergency,
        { id: new Date().getUTCMilliseconds() }
      ]
    },

    [types.EMPLOYEE_EMERGENCY_CONTACT_SET]: (state, emergency) => {
      state.emergency = [ ...emergency ]
    },

    [types.EMPLOYEE_EMERGENCY_CONTACT_DELETE]: (state, index) => {
      state.emergency.splice(index, 1)
    },

    [types.EMPLOYEE_ADDRESS_CONTACTS_OPTIONS]: (state, options) => {
      state.options = { ...options }
    },

    [types.EMPLOYEE_ADDRESS_CUSTOM_FIELDS]: (state, data) => {
      state.customFields = { ...data }

      state.emergency = state.emergency.map(contact => ({
        ...contact,
        ...(state.customFields.emergencyContact.values[contact.id] || {})
      }))
    }
  },

  actions: {
    [types.EMPLOYEE_ADDRESS_CONTACTS_OPTIONS]: async ({ commit }) => {
      const [ err, options ] = await services.getOptions()

      if (!err) commit(types.EMPLOYEE_ADDRESS_CONTACTS_OPTIONS, options)

      return [ err, options ]
    },

    [types.EMPLOYEE_ADDRESS]: async ({ commit, getters }) => {
      const [ err, data ] = await services.getAddressContacts(getters)

      if (!err) {
        commit(types.EMPLOYEE_ADDRESS_SET, data.address)
        commit(types.EMPLOYEE_CONTACT_SET, data.contact)

        if (data.emergency.length)
          commit(types.EMPLOYEE_EMERGENCY_CONTACT_SET, data.emergency)
      }

      return [ err, data ]
    },

    [types.EMPLOYEE_ADDRESS_CUSTOM_FIELDS]: async ({ commit, getters }) => {
      const [ err, data ] = await services.getCustomFields(getters)

      if (!err) commit(types.EMPLOYEE_ADDRESS_CUSTOM_FIELDS, data)

      return [ err, data ]
    },

    [types.EMPLOYEE_ADDRESS_SET]: async ({ dispatch, getters }, address) => {
      const [ err, data, endpoint ] = await services.setAddress(getters, address)

      if (!err) dispatch(types.EMPLOYEE_ADDRESS)

      return [ err, data, endpoint ]
    },

    [types.EMPLOYEE_CONTACT_SET]: async ({ getters, dispatch }, contact) => {
      const [ err, data ] = await services.setContact(getters, contact)

      if (!err) dispatch(types.EMPLOYEE_ADDRESS)

      return [ err, data ]
    },

    [types.EMPLOYEE_EMERGENCY_CONTACT_SET]: async ({ dispatch, getters }, contacts) => {
      const submited = []
      const errors = []

      await Promise.all((contacts || []).map(con => services.setEmergencyContact(getters, con)
        .then(([ err, contact ]) => err ? errors.push(err) : submited.push(contact))
        .catch(err => errors.push(err))))

      if (!errors.length) dispatch(types.EMPLOYEE_ADDRESS)

      return [ errors.length, submited ]
    },

    [types.EMPLOYEE_EMERGENCY_CONTACT_DELETE]: async ({ state, getters, commit }, emergency) => {
      const index = state.emergency.indexOf(emergency)
      const [ err ] = await services.deleteEmergencyContact(getters, emergency)

      if (!err) commit(types.EMPLOYEE_EMERGENCY_CONTACT_DELETE, index)

      return [ err, null ]
    },
  }
}
