<template>
  <c-transition
    mode="out-in"
    :duration="200"
    v-bind="$attrs"
  >
    <c-loader
      v-if="status === 'fetching' || (spinnerLoader && status === 'loading')"
      :key="`${_uid}-loader`"
      class="c-image-loader"
    />

    <template v-else-if="!!$slots.default">
      <slot />
    </template>

    <component
      v-else
      :is="isBackground ? 'div' : 'img'"
      :key="_uid"
      :src="image"
      :style="{ backgroundImage: isBackground ? `url(${image})` : 'none' }"
      v-bind="!isBackground && $attrs"
      class="c-image"
    >
      <img
        v-if="isBackground"
        v-bind="$attrs"
        :src="image"
        class="invisible"
      >
    </component>
  </c-transition>
</template>

<script>
/**
 * Generic and simple image component.
 */
export default {
  name: 'CImage',

  props: {
    /**
     * The path to the image, could be an URL or the path to a file in the
     * hard-drive.
     */
    src: {
      type: [ String, Function ],
      default: '',
    },

    /**
     * The placeholder image.
     */
    placeholder: {
      type: String,
      default: '/img/characters/desktop/cactus.svg'
    },

    /**
     * Whether the image should have the convenia spinner as a loader.
     */
    spinnerLoader: Boolean,

    /**
     * Renders the image as a background-image instead of insidea a <img /> tag.
     * Why? I don't know, but it works.
     */
    isBackground: Boolean,

    /**
     * Whether an image should be shown when loading.
     */
    noLoader: Boolean
  },

  data () {
    return {
      image: null,
      virtualImage: null,
      status: null,
      resolvedSrc: this.src
    }
  },

  watch: {
    async src () { this.resolvedSrc = await this.getImage() },
    resolvedSrc () {
      this.status = ''
      this.$nextTick(() => { this.status = 'loading' })
    },
    status (val) {
      switch (val) {
        case 'loading':
          this.setImage()
          break
        case 'loaded':
          this.image = this.virtualImage.src
          this.$emit('loaded')
          break
        case 'error':
          if (this.placeholder !== 'initials')
            this.image = this.placeholder

          this.$emit('error')
          break
        default: break
      }
    }
  },

  methods: {
    setImage () {
      this.virtualImage = new Image()
      this.virtualImage.src = this.resolvedSrc || this.placeholder

      this.virtualImage.onload = () => { this.status = 'loaded' }
      this.virtualImage.onerror = () => { this.status = 'error' }
    },

    async getImage () {
      try {
        const { src } = this

        if (typeof src === 'function') {
          this.status = 'fetching'
          const result = await src()
          return result
        }

        return src
      } catch (e) { this.status = 'error' }
    }
  },

  async created () {
    this.status = 'loading'
    this.resolvedSrc = await this.getImage()
  }
}
</script>

<style lang="scss">
.c-image {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  max-width: 100%;

  & > .invisible {
    visibility: hidden;
    max-width: 100%;
  }
}

.c-image-loader { transform: none !important; }
</style>
