<template>
  <component
    :is="cross ? 'cross' : 'check'"
    :class="[ 'c-selectable-icon', { '-selected': selected } ]"
    :selected="selected"
    width="29px"
    height="29px"
    viewBox="0 0 29 29"
    version="1.1"
  />
</template>

<script>
export default {
  name: 'CSelectableIcon',

  components: {
    Check: () => import('./Check.vue'),
    Cross: () => import('./Cross.vue')
  },

  props: {
    cross: Boolean,
    selected: Boolean,
  }
}
</script>

<style lang="scss">
.c-selectable-icon,
.c-selectable-icon-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
}

.c-selectable-icon {
  fill: var(--selectable-color);
}

.c-selectable-icon-active {
  fill: #FFF;
}

</style>
